import { DeliverySubscription } from '../../../types/deliverySubscription/DeliverySubscription'
import {
  ADD_DELIVERY_SUBSCRIPTION,
  DELETE_DELIVERY_SUBSCRIPTION,
  DeliverySubscriptionsActionTypes,
  SET_DELIVERY_SUBSCRIPTIONS,
  UPDATE_DELIVERY_SUBSCRIPTION
} from './types'

const initialState: DeliverySubscription[] = []

const deliverySubscriptionsReducer = (state = initialState, action: DeliverySubscriptionsActionTypes) => {
  switch (action.type) {
    case SET_DELIVERY_SUBSCRIPTIONS:
      return [...(action.payload || [])]
    case ADD_DELIVERY_SUBSCRIPTION:
      return [...state, action.payload]
    case UPDATE_DELIVERY_SUBSCRIPTION:
      return state.map((deliverySubscription: DeliverySubscription) =>
        deliverySubscription.id !== action.payload.id
          ? deliverySubscription
          : { ...deliverySubscription, ...action.payload }
      )
    case DELETE_DELIVERY_SUBSCRIPTION:
      return state.filter((deliverySubscription: DeliverySubscription) => deliverySubscription.id !== action.payload.id)
    default:
      return state
  }
}

export default deliverySubscriptionsReducer
