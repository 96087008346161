import React from 'react'
import styled from 'styled-components'
import { Button, Form, Popconfirm, Space } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { StatementRow } from '../../../types/statementRow/StatementRow'
import { Company } from '../../../types/company/Company'
import AccountRuleInput from './AccountRuleInput'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

interface AccountMapEditorProps {
  statementRow: StatementRow
  companies: Company[]
}

const StyledContainer = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.cols ?? 1}, 228px)`};
  gap: 1rem;
  padding-block: 16px;
  overflow-x: scroll;
`

const AccountMapEditor: React.FC<AccountMapEditorProps> = ({ statementRow, companies }) => {
  const { t } = useTranslation()
  const checkRule = async (__: any, value: AccountMapRule) => {
    if (value?.to < value?.from) {
      throw new Error('')
    }
    if (value?.to === null || value?.from === null) {
      throw new Error('')
    }
    if (value?.to === undefined || value?.from === undefined) {
      throw new Error('')
    }
  }

  return (
    <StyledContainer cols={companies?.length}>
      {companies.map(c => (
        <div key={c.id}>
          {companies.length > 1 ? <h4>{c.name}</h4> : null}
          <Form.List name={[statementRow.id, c.id]}>
            {(fields, { add, remove }) => (
              <>
                {fields?.map(field => (
                  <Space key={field.key} align="start">
                    <Form.Item
                      {...field}
                      hasFeedback={false}
                      rules={[
                        {
                          validator: checkRule
                        }
                      ]}
                    >
                      <AccountRuleInput companyId={c.id} statementRowId={statementRow.id} />
                    </Form.Item>
                    <Popconfirm
                      placement="topRight"
                      title={t('global:delete-confirm')}
                      onConfirm={() => remove(field.name)}
                    >
                      <Button danger type="text" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Space>
                ))}
                <Button icon={<PlusOutlined />} type="dashed" block onClick={() => add()}>
                  {t('global:add')}
                </Button>
              </>
            )}
          </Form.List>
        </div>
      ))}
    </StyledContainer>
  )
}

export default AccountMapEditor
