import { createSelector } from 'reselect'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { Store } from '../../types'

const personnelData = (state: Store) => state.context.personnel || []

export const getSortedPersonnelData = createSelector(personnelData, personnel => {
  const sortedPersonnelArray: KeyFigureFact[] = personnel.sort(
    (n1: KeyFigureFact, n2: KeyFigureFact) => n1.year - n2.year
  )
  return sortedPersonnelArray
})
