import { Badge, Popover } from 'antd'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import KatexComponent from '../../../../components/KatexComponent/KatexComponent'
import { translateKatex } from '../../../../components/KatexComponent/utils'
import ReportTable from '../../../../components/Table/ReportTable'
import { ReportTableRow } from '../../../../components/Table/types'
import { drillDownExpandable } from '../../../../components/Table/utils'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { dimensionQuerySelector, filtersSelector } from '../../../../redux/context/filters/selectors'
import { getKeyFigureExplainerRequest } from '../../../../redux/context/reports/actions'
import { Report } from '../../../../redux/context/reports/types'
import { errorSelector } from '../../../../redux/error/selectors'
import { loadingSelector } from '../../../../redux/loading/selectors'
import { KeyFigure } from '../../../../types/keyFigure/KeyFigure'
import { formatValueByType } from '../../../../utils/helpers'
import { getBadgeColor, format } from '../utils'
import ReferenceValueTable from './referenceValueTable/ReferenceValueTable'
import { ReferenceValue } from './referenceValueTable/types'
import { AppDispatch } from '../../../../redux/store'
import { appLanguageSelector } from '../../../../redux/app/selectors'

interface KeyFigureTableProps {
  report: Report
  enableCumulatives?: boolean
  referenceValues?: ReferenceValue[]
}

const KeyFigureTable: React.FC<KeyFigureTableProps> = ({ report, referenceValues, enableCumulatives }) => {
  const { t } = useTranslation()
  const errors = useSelector(errorSelector)
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const loading = useSelector(loadingSelector)
  const { periodGroups, budgetingScenario } = useSelector(filtersSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const language = useSelector(appLanguageSelector)

  const renderCell = (text: any, reportTableRow: ReportTableRow) => {
    if (!text) return undefined
    const value = formatValueByType(text, reportTableRow.variableType)

    const badgeColor = getBadgeColor(reportTableRow, format(reportTableRow, text))

    if (badgeColor) {
      return <Badge color={badgeColor} text={value} />
    }
    return value
  }

  const renderKatex = (reportTableRow: ReportTableRow): ReactNode => {
    return reportTableRow.katex ? (
      <span>
        <Popover
          content={
            <KatexComponent key={reportTableRow.id} displayMode math={translateKatex(reportTableRow.katex) || ''} />
          }
        >
          {t(reportTableRow.translation || `keyFigureStatement:${reportTableRow.id}`)}
        </Popover>
      </span>
    ) : (
      reportTableRow.title
    )
  }

  const expandable = drillDownExpandable(
    record =>
      dispatch(
        getKeyFigureExplainerRequest(
          companyId,
          record as unknown as KeyFigure,
          language,
          dimensionQuery,
          periodGroups,
          budgetingScenario?.id
        )
      ),
    id => loading[`keyFigureExplainer${id}`]
  )

  return (
    <ReportTable
      report={report?.map(row => ({
        ...row,
        children: [...(row.children || [])]
      }))}
      renderCell={renderCell}
      enableCumulatives={enableCumulatives}
      toolbar={{ csvExport: true }}
      renderTitle={renderKatex}
      expandable={expandable}
      error={errors?.keyFigureStatement}
      footer={referenceValues?.length && <ReferenceValueTable referenceValues={referenceValues} />}
    />
  )
}

export default KeyFigureTable
