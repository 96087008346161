import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Modal, Popover, Table, Tag, Typography } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Delivery, DeliveryStatus } from '../../../../../../types/deliverySubscription/Delivery'
import { useBackend } from '../../../../../../services/backend'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { DeliverySubscription } from '../../../../../../types/deliverySubscription/DeliverySubscription'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import { AppDispatch } from '../../../../../../redux/store'

interface DeliveriesProps {
  deliverySubscription?: DeliverySubscription
  setDeliverySubscription: (deliverySubscription?: DeliverySubscription) => void
  setDeliveryModalVisibility: (visible: boolean) => void
  visible: boolean
}

const Deliveries: React.FC<DeliveriesProps> = ({
  deliverySubscription,
  setDeliverySubscription,
  setDeliveryModalVisibility,
  visible
}: DeliveriesProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const downloadRequest = useBackend(`/deliveries/{uuid}/download`, process.env.REACT_APP_DELIVERY_URL, 'blob')
  const deliveriesRequest = useBackend(
    `/contracts/{contractId}/delivery-subscriptions/{deliverySubscriptionId}`,
    process.env.REACT_APP_DELIVERY_URL
  )

  const [deliveries, setDeliveries] = useState<Delivery[]>([])

  const download = (id: string) => {
    downloadRequest
      .get({ urlParams: { uuid: id } })
      .then((response: BlobPart) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${id}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
      })
  }

  const getDeliveries = () => {
    deliverySubscription &&
      deliveriesRequest
        .get({
          urlParams: { contractId: deliverySubscription.contractId, deliverySubscriptionId: deliverySubscription.id }
        })
        .then(setDeliveries)
        .catch(() => {
          dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
        })
  }

  const handleClose = () => {
    setDeliverySubscription(undefined)
    setDeliveryModalVisibility(false)
  }

  useEffect(() => {
    getDeliveries()
  }, [deliverySubscription])

  const columns: ColumnProps<Delivery>[] = [
    {
      title: t('status:status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        const tr = (s: string) => {
          return t(`status:${s.toLowerCase()}`)
        }
        switch (text) {
          case DeliveryStatus.FAILED:
            return <Tag color="red">{tr(text)}</Tag>
          case DeliveryStatus.FINISHED:
            return <Tag color="green">{tr(text)}</Tag>
          case DeliveryStatus.PENDING:
          default:
            return <Tag>{tr(text)}</Tag>
        }
      }
    },
    {
      title: t('subscriptionsPage:deliveryEndTime'),
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text: string) => {
        return dayjs(text).format('lll')
      }
    },
    {
      title: t('subscriptionsPage:reported-month'), // :D
      key: 'date',
      render: (delivery: Delivery) => {
        if (delivery.meta && delivery.meta.periodGroups) {
          const { startDate } = delivery.meta.periodGroups[0]
          return dayjs(startDate).format('MM/YYYY')
        }
        return undefined
      }
    },
    {
      title: t('error:error'),
      dataIndex: 'errors',
      key: 'errors',
      ellipsis: true,
      render: (text: string) =>
        text && (
          <Popover
            content={
              <Typography.Text code copyable>
                {text}
              </Typography.Text>
            }
          >
            <Typography.Text code>{text}</Typography.Text>
          </Popover>
        )
    },
    {
      title: t('global:actions'),
      key: 'actions',
      align: 'right',
      render: ({ uuid }: Delivery) =>
        uuid && (
          <Button icon={<DownloadOutlined />} type="link" onClick={() => download(uuid)}>
            {t(`global:download`)}
          </Button>
        )
    }
  ]

  return (
    <Modal
      title={t('subscriptionsPage:deliveries')}
      open={visible}
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
      afterClose={() => handleClose()}
      cancelButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
      width="50%"
    >
      <LoadingWrapper loading={deliveriesRequest.loading}>
        <Table
          rowKey={record => record.id.toString()}
          pagination={{ pageSize: 10 }}
          size="small"
          dataSource={deliveries.sort(
            (a, b) => (dayjs(b?.endTime).valueOf() || 0) - (dayjs(a?.endTime).valueOf() || 0)
          )}
          columns={columns}
        />
      </LoadingWrapper>
    </Modal>
  )
}

export default Deliveries
