import { presetPalettes } from '@ant-design/colors'
import { ReferenceValue, ReferenceValueRuleOperator } from './types'

export const keyFigureReferenceColors = {
  excellent: presetPalettes.green[6],
  good: presetPalettes.green[2],
  satisfying: presetPalettes.yellow[6],
  passable: presetPalettes.red[6]
}

export const keyFigureReferenceValues: ReferenceValue[] = [
  {
    keyFigureId: 8,
    excellent: { operator: ReferenceValueRuleOperator.gt, value: 30, color: keyFigureReferenceColors.excellent },
    good: { operator: ReferenceValueRuleOperator.gt, value: 10, color: keyFigureReferenceColors.good },
    satisfying: { operator: ReferenceValueRuleOperator.gt, value: 5, color: keyFigureReferenceColors.satisfying },
    passable: { operator: ReferenceValueRuleOperator.gt, value: -25, color: keyFigureReferenceColors.passable }
  },
  {
    keyFigureId: 44,
    excellent: { operator: ReferenceValueRuleOperator.gte, value: 1.5, color: keyFigureReferenceColors.excellent },
    good: { operator: ReferenceValueRuleOperator.gte, value: 1, color: keyFigureReferenceColors.good },
    satisfying: { operator: ReferenceValueRuleOperator.gte, value: 0.6, color: keyFigureReferenceColors.satisfying },
    passable: { operator: ReferenceValueRuleOperator.gte, value: 0.3, color: keyFigureReferenceColors.passable }
  },
  {
    keyFigureId: 45,
    excellent: { operator: ReferenceValueRuleOperator.gte, value: 1.5, color: keyFigureReferenceColors.excellent },
    good: { operator: ReferenceValueRuleOperator.gte, value: 1, color: keyFigureReferenceColors.good },
    satisfying: { operator: ReferenceValueRuleOperator.gte, value: 0.6, color: keyFigureReferenceColors.satisfying },
    passable: { operator: ReferenceValueRuleOperator.gte, value: 0.3, color: keyFigureReferenceColors.passable }
  },
  {
    keyFigureId: 46,
    excellent: { operator: ReferenceValueRuleOperator.lte, value: 3, color: keyFigureReferenceColors.excellent },
    good: { operator: ReferenceValueRuleOperator.lte, value: 4, color: keyFigureReferenceColors.good },
    satisfying: { operator: ReferenceValueRuleOperator.lte, value: 5, color: keyFigureReferenceColors.satisfying },
    passable: { operator: ReferenceValueRuleOperator.gte, value: 6, color: keyFigureReferenceColors.passable }
  },
  {
    keyFigureId: 49,
    excellent: { operator: ReferenceValueRuleOperator.gt, value: 50, color: keyFigureReferenceColors.excellent },
    good: { operator: ReferenceValueRuleOperator.gt, value: 35, color: keyFigureReferenceColors.good },
    satisfying: { operator: ReferenceValueRuleOperator.gt, value: 25, color: keyFigureReferenceColors.satisfying },
    passable: { operator: ReferenceValueRuleOperator.gt, value: 10, color: keyFigureReferenceColors.passable }
  }
]
