import React, { useContext } from 'react'
import { Result, Descriptions, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'

interface ResultStepProps {
  closeModal: () => void
}

const CreateResultStep: React.FC<ResultStepProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const { form, company, error } = useContext(ContractFormContext) as ContractFormContextType

  const information = !error && company && (
    <div>
      <Descriptions title={t('contractsPage:company')} column={1}>
        <Descriptions.Item label={t('contractsPage:name')}>{company.name}</Descriptions.Item>
        <Descriptions.Item label={t('contractsPage:product')}>
          {t(`contractProduct:${form.getFieldValue(['contract', 'product'])}`)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )

  const extra = <Button onClick={closeModal}>{t('contractsPage:close')}</Button>

  return (
    <Result
      status={!error ? 'success' : 'error'}
      title={!error ? t('contractsPage:contract-updated-succesfully') : t('contractsPage:something-went-wrong')}
      extra={extra}
    >
      {information}
    </Result>
  )
}

export default CreateResultStep
