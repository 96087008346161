import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'

export const companyInformationSelector = createSelector(contextSelector, context => context.companyInformation)

export const ownersAndExecutiveBoardSelector = createSelector(companyInformationSelector, ({ stakeholders }) => {
  return stakeholders || {}
})

export const companyInformationLoadingSelector = createSelector(loadingSelector, loading => loading.companyInformation)
