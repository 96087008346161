import { LiabilityMappingActionTypes, LIABILITY_MAPPING, LiabilityMapping } from './types'

const initialState: LiabilityMapping = {}

const LiabilityMappingReducer = (state = initialState, action: LiabilityMappingActionTypes) => {
  switch (action.type) {
    case LIABILITY_MAPPING:
      return action.payload
    default:
      return state
  }
}

export default LiabilityMappingReducer
