import { InputNumber, Segmented, Select } from 'antd'
import i18next from 'i18next'
import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { FormItem } from '../../../../../components/Form/FormItemContext'
import LineIcon from '../../../../../components/Icons/LineIcons/LineIcon'
import ColorPicker from '../categorySelection/ColorPicker'
import ChartTypeSelect from './ChartTypeSelect'
import { ChartYAxis } from './types'
import { DashStyle } from '../../../../../redux/context/customReports/typesCategory'

const SeriesStyleFields = () => {
  const dashStyles = Object.values(DashStyle)

  return (
    <>
      <FormItem key="type" name="type" label={i18next.t('customReportPage:graphType')}>
        <ChartTypeSelect style={{ minWidth: '130px' }} allowClear />
      </FormItem>
      <FormItem key="dashStyle" name="dashStyle" label={i18next.t('customReportPage:dashStyle')}>
        <Select
          allowClear
          options={dashStyles.map(dashStyle => ({
            label: (
              <span>
                <LineIcon type={dashStyle} />
              </span>
            ),
            value: dashStyle
          }))}
        />
      </FormItem>
      <FormItem key="zIndex" name="zIndex" label={i18next.t('customReportPage:zIndex')}>
        <InputNumber />
      </FormItem>
      <FormItem key="color" name="color" label={i18next.t('global:color')}>
        <ColorPicker />
      </FormItem>
      <FormItem initialValue={ChartYAxis.left} label={i18next.t('global:yAxis')} key="yAxis" name="yAxis">
        <Segmented
          size="small"
          options={[
            {
              label: <LeftOutlined />,
              value: ChartYAxis.left
            },
            {
              label: <RightOutlined />,
              value: ChartYAxis.right
            }
          ]}
        />
      </FormItem>
    </>
  )
}

export default SeriesStyleFields
