import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { KeyPerformanceIndicatorsActionTypes, SET_KEY_PERFORMANCE_INDICATORS } from './types'

const initialState: KeyFigure[] = []

const keyPerformanceIndicatorsReducer = (state = initialState, action: KeyPerformanceIndicatorsActionTypes) => {
  switch (action.type) {
    case SET_KEY_PERFORMANCE_INDICATORS:
      return action.payload
    default:
      return state
  }
}

export default keyPerformanceIndicatorsReducer
