import { LoanSummaryReport } from '../../../pages/budgeting/loans/types'
import { Loan } from '../../../types/loan/Loan'

export const SET_EXISTING_LOANS = 'SET_EXISTING_LOANS'
export const SET_NEW_LOANS = 'SET_NEW_LOANS'
export const UPDATE_LOAN = 'UPDATE_EXISTING_LOAN'
export const SET_EXISTING_LOANS_SUMMARY = 'SET_EXISTING_LOANS_SUMMARY'
export const SET_NEW_LOANS_SUMMARY = 'SET_NEW_LOANS_SUMMARY'
export const RESET_LOANS = 'RESET_LOANS'

export interface UpdateLoanAction {
  type: typeof UPDATE_LOAN
  payload: Loan
}

export interface ResetLoansAction {
  type: typeof RESET_LOANS
  payload: any
}

export interface SetLoansAction {
  type: typeof SET_EXISTING_LOANS | typeof SET_NEW_LOANS
  payload: Loan[]
}

export interface SetLoansSummaryAction {
  type: typeof SET_EXISTING_LOANS_SUMMARY | typeof SET_NEW_LOANS_SUMMARY
  payload: LoanSummaryReport
}

export type LoansActionTypes = SetLoansAction | UpdateLoanAction | SetLoansSummaryAction | ResetLoansAction
