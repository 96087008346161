import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider, Drawer, theme } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined, ControlOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import DataTypeFilter from './components/DataTypeFilter'
import EmptyRowFilter from './components/EmptyRowFilter'
import ContextFilter from './components/ContextFilter'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import './_ContextDrawer.sass'
import { Route } from '../../routes/Route'
import { ContextFiltersSections } from './types'
import { checkIfDisabled, getDisabledContextdrawerSectionRoutes } from './utils'

interface ContextDrawerProps {
  routes: Route['routes']
}

export const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5
}

const { useToken } = theme

const DRAWER_WIDTH = 420

export const ContextDrawer: React.FC<ContextDrawerProps> = ({ routes }: ContextDrawerProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [visible, setVisibility] = useState(true)
  const disabledContextdrawPages = getDisabledContextdrawerSectionRoutes(routes)
  const { token } = useToken()
  const companyId = useSelector(contextCompanyIdSelector)

  useEffect(() => {
    setVisibility(false)
  }, [])

  const thisLocationDisabledPages = () => {
    return disabledContextdrawPages?.find(page => page.path === location.pathname)?.disabledContextdrawerSections
  }

  return (
    <>
      <div
        className="context-drawer-handle"
        style={{
          backgroundColor: token.colorPrimary,
          transform: `translate(${visible ? 0 : DRAWER_WIDTH}px`,
          zIndex: 10000
        }}
      >
        <Button
          style={{ color: 'white' }}
          type="text"
          size="large"
          onClick={() => setVisibility(!visible)}
          icon={visible ? <CloseOutlined style={{ fontSize: 20 }} /> : <ControlOutlined style={{ fontSize: 20 }} />}
        />
      </div>
      <Drawer
        rootClassName="context-drawer"
        title={t('contextDrawer:title')}
        placement="right"
        closable
        width={DRAWER_WIDTH}
        onClose={() => setVisibility(false)}
        open={visible}
      >
        {companyId && <ContextFilter disableActions={thisLocationDisabledPages()} />}
        <Divider />
        <DataTypeFilter
          disableActions={
            !!thisLocationDisabledPages()?.find((disabled: any) => disabled === ContextFiltersSections.dataTypes)
          }
        />
        <Divider />
        <div style={checkIfDisabled(ContextFiltersSections.emptyRows, thisLocationDisabledPages()) && disabledStyle}>
          <EmptyRowFilter />
        </div>
      </Drawer>
    </>
  )
}
