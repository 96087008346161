import { TableProps } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { ReactNode } from 'react'
import { MenuItemType } from 'antd/es/menu/interface'
import { BudgetingMethod } from '../../pages/budgeting/financialStatements/components/budget/types'
import { BudgetComment } from '../../pages/budgeting/financialStatements/components/comment/BudgetCommentItem'
import { Column } from '../../redux/context/periodGroups/hooks'
import { Balance, Report, ReportRowType } from '../../redux/context/reports/types'
import { RequestError } from '../../redux/error/types'
import { DeprecationPlan } from '../../types/deprecation/DeprecationPlan'
import { Formula } from '../../types/formula/Formula'

export interface ReportTableProps {
  report: Report
  fiscalYearClosingLabel?: boolean
  yScroll?: number | true | 'max-content'
  columnWidth?: number
  nameColumnWidth?: number
  footer?: React.ReactNode
  toolbar?: {
    csvExport?: boolean
    hidden?: boolean
    enablePercents?: boolean
    leftContent?: ReactNode
  }
  error?: RequestError
  enableCumulatives?: boolean
  expandable?: ExpandableConfig<ReportTableRow>
  rowKey?: TableProps<ReportTableRow>['rowKey']
  summary?: React.ReactNode
  toggleAllRows?: (allKeys: string[]) => void
  renderCell?: (text: any, reportTableRow: ReportTableRow, column: Column) => any
  renderTitle?: (reportTableRow: ReportTableRow, props: any) => React.ReactNode
  contextMenuItems?: (row: ReportTableRow, column: Column) => MenuItemType[]
  rowClassName?: (reportTableRow: ReportTableRow) => string
  onCell?: (
    reportTableRow: ReportTableRow,
    rowIndex?: number
  ) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>
}

export interface ReportTableRow {
  [key: string]: any
  key: string
  budget?: Balance[]
  budgets?: Balance[]
  actuals?: Balance[]
  budgetComments?: BudgetComment[]
  code: number | string
  style?: any
  rowType?: ReportRowType
  variableType?: VariableType
  budgetingMethod?: RowBudgetingMethod
  depreciable?: boolean
  deprecationPlan?: DeprecationPlan
}

export interface RowBudgetingMethodDimension {
  id: number
  dimensionId: string | null
  source: string
  drivers: Formula[]
}
export interface RowBudgetingMethod {
  accountCode: number
  incomeStatementRowId: number
  method: BudgetingMethod
  dimensions?: RowBudgetingMethodDimension[]
  source?: string
  drivers?: Formula[]
}

export interface RowBudgetingMethodPut {
  accountCode: number
  incomeStatementRowId: number
  method: BudgetingMethod
  dimensionIds?: (string | null)[]
  source?: string
  drivers?: Formula[]
}

export interface RowBudgetingMethodFormValues {
  accountCode: number
  incomeStatementRowId: number
  method: BudgetingMethod
  dimensionIds?: string[]
  sourceIds: string[]
  drivers?: Formula[]
  source?: string
}

export enum VariableType {
  absolute = 'absolute',
  percentage = 'percentage',
  ratio = 'ratio'
}
