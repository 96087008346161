import { useTranslation } from 'react-i18next'
import { Modal, Button, Tabs } from 'antd'
import React from 'react'
import FormulaHelpHelp from './FormulaHelpHelp'
import FormulaHelpExamples from './FormulaHelpExamples'
import FormulaHelpTips from './FormulaHelpTips'

const FormulaHelp = ({ style }: { style?: React.CSSProperties }) => {
  const { t } = useTranslation()

  const items = [
    { label: t('formulaHelp:help'), key: 'help', children: <FormulaHelpHelp /> }, // remember to pass the key prop
    { label: t('formulaHelp:examples'), key: 'examples', children: <FormulaHelpExamples /> },
    { label: t('formulaHelp:tips'), key: 'tips', children: <FormulaHelpTips /> }
  ]

  const modal = () => {
    Modal.info({
      title: t('formulaHelp:instructions'),
      width: 1000,
      content: <Tabs defaultActiveKey="1" items={items} />
    })
  }

  return (
    <Button style={style} type="link" onClick={() => modal()}>
      {t('header:manual')}
    </Button>
  )
}

export default FormulaHelp
