import React from 'react'
import { DatabaseOutlined, FileSearchOutlined, FundOutlined, GoldOutlined, RiseOutlined } from '@ant-design/icons'
import { Route } from './Route'
import BasicLayout from '../layouts/BasicLayout'
import ForgotPassword from '../pages/user/forgotPassword/ForgotPassword'
import IndustryAndCompetitorComparison from '../pages/industryAndCompetitorComparison/IndustryAndCompetitorComparison'
import Login from '../pages/user/login/Login'
import ResetPassword from '../pages/user/resetPassword/ResetPassword'
import UserLayout from '../layouts/UserLayout'
import ValueEvaluation from '../pages/valuation/Valuation'
import Welcome from '../pages/welcome/Welcome'
import FundingApplications from '../pages/fundingApplications/FundingApplications'
import CompanyInformation from '../pages/companyInformation/CompanyInformation'
import BlankLayout from '../layouts/BlankLayout'
import DeliveryDownload from '../components/Delivery/DeliveryDownload'
import dashboardRoutes from './subRoutes/dashboardRoutes'
import budgetingRoutes from './subRoutes/budgetingRoutes'
import reportingRoutes from './subRoutes/reportingRoutes'
import superuserRoutes from './subRoutes/superuserRoutes'
import settingRoutes from './subRoutes/settingRoutes'
import investmentRoutes from './subRoutes/investmentRoutes'
import { ContractProduct } from '../types/contract/Contract'
import { Country } from '../types/company/Company'
import { isEliminationCompany } from './utils'
import { voucherAccountingSoftware } from '../config/integrations/vouchers'
import Vouchers from '../pages/reporting/vouchers/Vouchers'

const routes: Route[] = [
  {
    path: '/',
    // exact: false,
    component: BlankLayout,
    routes: [
      {
        path: '/user',
        hideInMenu: true,
        component: UserLayout,
        routes: [
          {
            path: '/user/login',
            name: 'login',
            hideInMenu: true,
            component: Login
          },
          {
            path: '/user/forgot-password',
            name: 'forgot-password',
            hideInMenu: true,
            component: ForgotPassword
          },
          {
            path: '/user/reset-password',
            name: 'reset-password',
            hideInMenu: true,
            component: ResetPassword
          },
          {
            path: '/user/activate',
            name: 'activate',
            hideInMenu: true,
            component: () => <ResetPassword extra="usersPage:contact-finadeck" />
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    authorized: true,
    routes: [
      {
        path: '/welcome',
        component: Welcome
      },
      // /dashboard
      dashboardRoutes,
      // /reporting
      reportingRoutes,
      // /budgeting
      budgetingRoutes,
      {
        path: '/vouchers',
        name: 'vouchers',
        icon: <FileSearchOutlined />,
        component: Vouchers,
        authority: [
          {
            permission: 'read-vouchers'
          },
          {
            role: ['company-admin', 'accountant-admin', 'accountant-user'],
            accountingSoftware: voucherAccountingSoftware
          }
        ]
      },
      // /investments
      investmentRoutes,
      {
        path: '/financing',
        name: 'financing',
        icon: <FundOutlined />,
        authority: [
          {
            role: ['company-admin', 'accountant-admin'],
            product: [ContractProduct.BASIC, ContractProduct.PRO]
          },
          {
            custom: () => !isEliminationCompany()
          }
        ],
        routes: [
          {
            path: '/financing/funding-applications',
            name: 'funding-applications',
            icon: <FundOutlined />,
            component: FundingApplications
          }
        ]
      },
      {
        path: '/comparison',
        name: 'comparison',
        icon: <RiseOutlined />,
        component: IndustryAndCompetitorComparison,
        authority: [
          {
            role: [
              'company-admin',
              'company-user',
              'accountant-admin',
              'accountant-user',
              'financier-admin',
              'financier-user'
            ],
            country: Country.fi
          },
          {
            custom: () => !isEliminationCompany()
          }
        ]
      },
      {
        path: '/valuation',
        name: 'valuation',
        icon: <GoldOutlined />,
        component: ValueEvaluation,
        authority: [
          {
            role: [
              'company-admin',
              'company-user',
              'accountant-admin',
              'accountant-user',
              'financier-admin',
              'financier-user'
            ],
            product: [ContractProduct.BASIC, ContractProduct.PRO]
          },
          {
            custom: () => !isEliminationCompany()
          }
        ]
      },
      {
        path: '/company-information',
        name: 'companyInformation',
        icon: <DatabaseOutlined />,
        component: CompanyInformation,
        authority: {
          custom: () => !isEliminationCompany()
        }
      },
      // /settings
      settingRoutes,
      {
        path: '/deliveries',
        name: 'deliveries',
        urlParam: 'uuid',
        component: DeliveryDownload,
        hideInMenu: true
      },
      // /superuser
      superuserRoutes
    ]
  }
]

export default routes as Route[]
