import { Dispatch, Middleware } from 'redux'
import { notification } from 'antd'
import i18next from 'i18next'
import { NotificationAction } from './types'

const notificationMiddleware: Middleware = () => (next: Dispatch) => (action: NotificationAction) => {
  if (action.type !== 'NOTIFICATION') {
    return next(action)
  }
  const { type, message, description, duration } = action.payload
  notification[type]({
    message: `${i18next.t(`message:${message}`, message)}!`,
    description: description && i18next.t(`description:${description}`, description),
    duration
  })
  return next(action)
}

export default notificationMiddleware
