/* eslint-disable no-case-declarations */
import { Company } from '../../../types/company/Company'
import { UserSetting } from '../../../types/user/User'
import {
  SET_CURRENT_USER,
  ADD_CURRENT_USER_COMPANY,
  CurrentUserActionTypes,
  UPDATE_TERMS_AND_CONDITIONS,
  UPDATE_TOUR,
  UPDATE_USER_SETTINGS,
  UPDATE_CURRENT_USER_COMPANY
} from './types'

const initialState: any = {}

const currentUserReducer = (state: any = initialState, action: any): CurrentUserActionTypes => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, ...action.payload }
    case ADD_CURRENT_USER_COMPANY:
      return { ...state, companies: [...state.companies, action.payload] }
    case UPDATE_USER_SETTINGS:
      const index = state.settings.findIndex((s: UserSetting) => s.settingId === action.payload.settingId)
      if (index >= 0) {
        return {
          ...state,
          settings: [...state.settings.slice(0, index), action.payload, ...state.settings.slice(index + 1)]
        }
      }
      return { ...state, settings: [...state.settings, action.payload] }
    case UPDATE_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionsAgreed: true
      }
    case UPDATE_TOUR:
      return {
        ...state,
        tourCompleted: true
      }
    case UPDATE_CURRENT_USER_COMPANY:
      return {
        ...state,
        companies: state.companies.map((c: Company) => (c.id === action.payload.id ? action.payload : c))
      }
    default:
      return state
  }
}

export default currentUserReducer
