import React, { useEffect, useState } from 'react'
import { Modal, Input, Form, Cascader, Radio, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { addCustomReport, updateCustomReport } from '../../../../redux/context/customReports/actions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { mapObjectToFieldData } from '../../../../utils/form'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../../services/backend'
import { contextRequest } from '../../../../redux/context/actions'
import { getFormulasRequest } from '../../../../redux/context/formulas/actions'
import { AppDispatch } from '../../../../redux/store'

interface CustomReportFormProps {
  report?: CustomReport | null
  visible: boolean
  handleReportChange: (report: CustomReport) => void
  onClose: () => void
}

const CustomReportModal: React.FC<CustomReportFormProps> = ({
  visible,
  report,
  handleReportChange,
  onClose
}: CustomReportFormProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const userCompanies = useSelector(currentUserSortedCompaniesSelector)
  const [companies, setCompanies] = useState(userCompanies.map(({ id, name }) => ({ id, title: name, isLeaf: false })))
  const reportRequest = useBackend(`/api/companies/{companyId}/reporting/custom/reports`)
  const copyReportRequest = useBackend('/api/companies/{companyId}/reporting/custom/reports/copy/{reportId}')
  const createReportRequest = useBackend(`/api/companies/${companyId}/reporting/custom/reports`)
  const updateReportRequest = useBackend(`/api/companies/${companyId}/reporting/custom/reports/{id}`)
  const [reportForm] = useForm()
  const [newReportMode, setNewReportMode] = useState<'new' | 'copy'>('new')
  const [copySelection, setCopySelection] = useState<(string | number)[]>([])
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (report) {
      const fields = mapObjectToFieldData(report)
      reportForm.setFields(fields)
    }
  }, [report])

  const handleCancel = () => {
    reportForm.resetFields()
    onClose()
    setNewReportMode('new')
  }

  const handleSave = async () => {
    setConfirmLoading(true)
    switch (newReportMode) {
      case 'copy':
        try {
          const [, reportId] = copySelection
          const response = await copyReportRequest.post({ urlParams: { companyId, reportId } })
          dispatch(addCustomReport(response))
          handleReportChange(response)
          dispatch(contextRequest(getFormulasRequest))
          onClose()
        } catch (error) {
          console.log(error)
        }
        break

      case 'new':
        try {
          const values = await reportForm.validateFields()
          const { id, ...restValues } = values
          if (id) {
            const response = await updateReportRequest.put({
              urlParams: { id },
              body: { data: restValues }
            })
            dispatch(updateCustomReport(response))
          } else {
            const response = await createReportRequest.post({
              body: { data: values }
            })
            dispatch(addCustomReport(response))
            handleReportChange(response)
          }
          reportForm.resetFields()
          onClose()
        } catch (error) {
          console.log('Validate Failed:', error)
        }
        break

      default:
        break
    }
    setConfirmLoading(false)
    setNewReportMode('new')
  }

  const loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    try {
      const response = await reportRequest.get({ urlParams: { companyId: targetOption.id } })
      targetOption.loading = false

      targetOption.children =
        response.length === 0 ? [{ title: t('global:no-results'), id: null, isLeaf: true, disabled: true }] : response
      setCompanies([...companies])
    } catch (error) {
      targetOption.loading = false
      console.log(error)
    }
  }

  const onChange = (value: any) => {
    setCopySelection(value)
  }

  const filter = (inputValue: any, path: any) => {
    if (
      path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase())) &&
      inputValue.length > 2
    ) {
      const selectedCompany: any = companies?.find(
        c => c.id === path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase()))?.id
      )
      if (selectedCompany && (!selectedCompany.children || selectedCompany.children.length < 1)) {
        loadData([path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase()))])
      }
    }
    return path.some((option: any) => option?.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1)
  }

  return (
    <Modal
      destroyOnClose
      open={visible}
      title={report?.id ? t('customReportPage:edit-report') : t('customReportPage:new-report')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleSave}
      okButtonProps={{
        disabled: copySelection && copySelection.length < 2 && newReportMode === 'copy'
      }}
      confirmLoading={confirmLoading}
    >
      {!report?.id && (
        <Radio.Group
          onChange={e => {
            e.preventDefault()
            setNewReportMode(e.target.value)
          }}
          value={newReportMode}
          style={{ marginBottom: 16 }}
        >
          <Radio value="new">{t('contractsPage:add-new')}</Radio>
          <Radio value="copy">{t('global:copy')}</Radio>
        </Radio.Group>
      )}
      {newReportMode === 'new' && (
        <Form form={reportForm} layout="vertical" initialValues={{ style: { orientation: 'landscape' } }}>
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <Form.Item
            name="title"
            label={t('global:name')}
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['style', 'orientation']}
            label={t('customReportPage:print-orientation')}
            initialValue="landscape"
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <Select
              defaultValue="landscape"
              style={{ width: 120 }}
              options={[
                { value: 'landscape', label: t('customReportPage:landscape') },
                { value: 'portrait', label: t('customReportPage:portrait') }
              ]}
            />
          </Form.Item>
        </Form>
      )}
      {!report?.id && newReportMode === 'copy' && (
        <Form layout="vertical">
          <Form.Item name="reportId" label={t('global:copy')}>
            <Cascader<any>
              value={copySelection}
              fieldNames={{ label: 'title', value: 'id', children: 'children' }}
              options={companies}
              placeholder={t('global:choose-here')}
              loadData={loadData}
              onChange={onChange}
              showSearch={{ filter }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default CustomReportModal
