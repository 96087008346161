import { ReportTableRow, VariableType } from '../../../components/Table/types'
import { Balance, ReportRow } from '../../../redux/context/reports/types'
import { keyFigureReferenceValues } from './components/referenceValueTable/config'
import { ReferenceValueRule, ReferenceValueRuleOperator } from './components/referenceValueTable/types'

/** Graafille */
export const formatValue = (reportRow: ReportRow, b?: Balance) => {
  if (!b?.value) return 0
  switch (reportRow.type) {
    case VariableType.absolute:
      return Math.round(b.value)
    case VariableType.percentage:
      return Math.round(b.value * 100 * 10) / 10
    case VariableType.ratio:
      return Math.round(b.value * 10) / 10
    default:
      return Math.round(b.value)
  }
}

export const format = (reportTableRow: ReportTableRow, value: string): number => {
  switch (reportTableRow.variableType) {
    case VariableType.absolute:
      return Math.round(+value)
    case VariableType.percentage:
      return Math.round(+value * 100 * 10) / 10
    case VariableType.ratio:
      return Math.round(+value * 10) / 10
    default:
      return +value
  }
}

export const evaluateReferenceValueRule = (rule: ReferenceValueRule, value?: number): boolean => {
  if (!value) return false
  switch (rule.operator) {
    case ReferenceValueRuleOperator.gt:
      if (value > rule.value) return true
      break
    case ReferenceValueRuleOperator.gte:
      if (value >= rule.value) return true
      break
    case ReferenceValueRuleOperator.lt:
      if (value < rule.value) return true
      break
    case ReferenceValueRuleOperator.lte:
      if (value <= rule.value) return true
      break
    case ReferenceValueRuleOperator.btw:
      if (value >= rule.value[0] && value <= rule.value[1]) return true
      break
    default:
      return false
  }
  return false
}

export const getBadgeColor = (reportTableRow: ReportTableRow, value: number): string | undefined => {
  const referenceValues = keyFigureReferenceValues.find(ref => ref.keyFigureId === reportTableRow.id)
  if (value && referenceValues) {
    if (evaluateReferenceValueRule(referenceValues.excellent, value)) return referenceValues.excellent.color
    if (evaluateReferenceValueRule(referenceValues.good, value)) return referenceValues.good.color
    if (evaluateReferenceValueRule(referenceValues.satisfying, value)) return referenceValues.satisfying.color
    return referenceValues.passable.color
  }
  return undefined
}
