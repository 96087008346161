import React from 'react'
import classNames from 'classnames'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  oderColumnDescending,
  oderColumnAscending,
  oderColumnDefault
} from '../../../../redux/context/customReports/actions'
import { customReportSortSelector } from '../../../../redux/context/customReports/selectors'
import { AppDispatch } from '../../../../redux/store'
import { CustomReportCategory } from '../../../../redux/context/customReports/typesCategory'
import { CustomReportVariable } from '../../../../redux/context/customReports/typesVariable'

export enum CustomReportSortOrder {
  default,
  ascending,
  descending
}

interface SortButtonProps {
  sectionId: number
  row: CustomReportVariable
  column: CustomReportCategory
}

const StyledSortButton = styled.span`
  flex-direction: column;
  align-items: center;
  color: #b0b0b0;
  display: none;
  margin: -3px;
  &.active {
    display: flex;
  }
`

const SortButton = ({ sectionId, row, column }: SortButtonProps) => {
  const dispatch: AppDispatch = useDispatch()

  const sort = useSelector(customReportSortSelector)

  const getSortState = () => {
    const rowSort = sort?.[sectionId.toString()]?.[row.id!]
    return rowSort?.columnId === column.id ? rowSort.sortOrder : CustomReportSortOrder.default
  }

  const getNextState = (): CustomReportSortOrder => {
    const order = getSortState()
    if (order === CustomReportSortOrder.descending) {
      return CustomReportSortOrder.default
    }
    return order + 1
  }

  const handleSort = (sortDirection: CustomReportSortOrder) => {
    switch (sortDirection) {
      case CustomReportSortOrder.ascending:
        dispatch(oderColumnAscending(sectionId, row, column))
        break
      case CustomReportSortOrder.descending:
        dispatch(oderColumnDescending(sectionId, row, column))
        break

      default:
        dispatch(oderColumnDefault(sectionId, row, column))
        break
    }
  }

  const toggleState = () => {
    const nextState = getNextState()
    handleSort(nextState)
  }

  return (
    <StyledSortButton
      role="button"
      tabIndex={0}
      onKeyDown={toggleState}
      onClick={toggleState}
      className={classNames(['sort-button', { active: getSortState() !== CustomReportSortOrder.default }])}
      style={{ float: 'left' }}
    >
      <CaretUpOutlined
        className={classNames([
          'ant-table-column-sorter-up',
          { active: getSortState() === CustomReportSortOrder.ascending }
        ])}
      />
      <CaretDownOutlined
        className={classNames([
          'ant-table-column-sorter-down',
          { active: getSortState() === CustomReportSortOrder.descending }
        ])}
      />
    </StyledSortButton>
  )
}

export default SortButton
