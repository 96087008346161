import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DatePicker, Dropdown, Menu, Space, Tooltip, Upload } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import dayjs from 'dayjs'
import { RcFile } from 'antd/lib/upload'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import InvestmentGroup from './components/InvestmentGroup'
import { Route } from '../../../routes/Route'
import InvestmentModal from './components/InvestmentModal'
import { getInvestmentsSummaryRequest, setInvestmentPageDateAction } from '../../../redux/pages/investments/actions'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { loadingSelector } from '../../../redux/loading/selectors'
import InvestmentSummary from './components/InvestmentSummary'
import investmentRoutes from '../../../routes/subRoutes/investmentRoutes'
import {
  createInvestmentPageInvestmentsRequest,
  deleteInvestmentPageInvestmentsRequest,
  updateInvestmentPageInvestmentsRequest
} from '../../../redux/context/investments/actions'
import { InvestmentsPageReport } from '../../../redux/pages/investments/types'
import InvestmentFormContextProvider from './InvestmentFormContextProvider'
import { authTokenSelector } from '../../../redux/session/authentication/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { notificationAction } from '../../../redux/middleware/actions'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { budgetGroupSettingsSourceSelector } from '../../../redux/context/groupSettings/selectors'
import { GroupSettingDataSource } from '../../settings/company/group/types'
import { notLocked } from '../../settings/company/budgeting/budgetingScenarios/utils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { AppDispatch } from '../../../redux/store'

const generateTabsFromRoutes = (routes?: Route[]) => {
  return routes?.map(r => ({ key: r.path, label: i18next.t(`menu:${r.path}`) })) || []
}

interface InvestmentPageProps {
  investmentsData: InvestmentsPageReport | null
  isExisting?: boolean
}

const InvestmentsPage: React.FC<InvestmentPageProps> = ({ investmentsData, isExisting }) => {
  const dispatch: AppDispatch = useDispatch()

  const location = useLocation()
  const pagePath = location.pathname
  const { t } = useTranslation()
  const history = useHistory()
  const { newInvestments: investmentsLoading } = useSelector(loadingSelector)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [csvLoading, setCsvLoading] = useState<boolean>(false)
  const token = useSelector(authTokenSelector)
  const companyId = useSelector(contextCompanyIdSelector)!
  const tabList = generateTabsFromRoutes(investmentRoutes?.routes)
  const { budgetingScenario } = useSelector(filtersSelector)
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const { date } = useSelector(investmentsPageSelector)

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const getCsvTemplate = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/templates/investment-import`,
      method: 'GET',
      headers: {
        'X-Token': token ?? ''
      },
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'import-template.xlsx')
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleSubmit = (file: RcFile) => {
    const formData = new FormData()
    formData.append('file', file)

    axios({
      method: 'post',
      headers: {
        'X-Token': token ?? '',
        'Content-Type': 'multipart/form-data'
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/api/companies/${companyId}/budgeting/investments/import`,
      params: { budgetingScenarioId: budgetingScenario?.id },
      data: formData
    })
      .then(() => {
        dispatch(notificationAction({ type: 'success', message: 'IMPORT_SUCCESS' }))
        setCsvLoading(false)
        dispatch(
          getInvestmentsSummaryRequest(
            companyId,
            dayjs(date).endOf('month').format('YYYY-MM-DD'),
            budgetingScenario && budgetingScenario.id,
            isExisting === true
          )
        )
      })
      .catch(error => {
        setCsvLoading(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'IMPORT_ERROR',
            description: error?.response?.data?.message
          })
        )
      })
  }

  const props = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: (file: RcFile) => {
      setCsvLoading(true)
      handleSubmit(file)
      return false
    }
  }

  return (
    <InvestmentFormContextProvider isDepreciable isExisting={!!isExisting}>
      <InvestmentModal
        visible={modalVisible}
        onFinish={toggleModal}
        updateInvestmentsRequest={updateInvestmentPageInvestmentsRequest}
        createInvestmentsRequest={createInvestmentPageInvestmentsRequest}
        deleteInvestmentsRequest={deleteInvestmentPageInvestmentsRequest}
        formType="investment"
      />
      <PageHeaderWrapper
        title={t('investmentsPage:investment')}
        subTitle={
          <Space>
            {t(`menu:${pagePath}`)}
            <Tooltip placement="right" title="info">
              <DocumentationLink
                route={{ path: '/budgeting' }}
                id={{
                  fi: 'poistot-professional-versio',
                  en: 'depreciation-professional-version',
                  sv: 'avskrivning-professionell-version'
                }}
              />
            </Tooltip>
          </Space>
        }
        onTabChange={(key: string) => {
          history.push(key)
        }}
        extra={[
          <DatePicker
            value={date ? dayjs(date) : dayjs()}
            format="MM/YYYY"
            picker="month"
            key="month"
            onChange={val => dispatch(setInvestmentPageDateAction(val?.toString()))}
          />,
          isExisting === true ? (
            <Dropdown.Button
              icon={csvLoading ? <LoadingOutlined /> : undefined}
              onClick={toggleModal}
              disabled={
                !notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries
              }
              overlay={
                <Menu>
                  <Menu.Item key="import">
                    <Upload {...props}>{t('investmentsPage:csvImport')}</Upload>
                  </Menu.Item>
                  <Menu.Item key="download" onClick={() => getCsvTemplate()}>
                    {t('investmentsPage:csvDownload')}
                  </Menu.Item>
                </Menu>
              }
            >
              <PlusOutlined />
              {t('global:add-new')}
            </Dropdown.Button>
          ) : (
            <Button
              key="add"
              disabled={
                !notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries
              }
              icon={<PlusOutlined />}
              onClick={toggleModal}
            >
              {t('global:add-new')}
            </Button>
          )
        ]}
        content={<InvestmentSummary summary={investmentsData?.summary} />}
        tabActiveKey={pagePath}
        tabList={tabList}
        ghost={false}
      >
        <LoadingWrapper loading={date ? investmentsLoading : false}>
          <Space style={{ width: '100%' }} direction="vertical">
            {investmentsData?.balanceSheetRows.map(balanceSheetRow => (
              <InvestmentGroup key={balanceSheetRow.balanceSheetRowId} {...balanceSheetRow} isExisting={isExisting} />
            ))}
          </Space>
        </LoadingWrapper>
      </PageHeaderWrapper>
    </InvestmentFormContextProvider>
  )
}

export default InvestmentsPage
