import React, { useState } from 'react'
import { Card, Col, Row, theme } from 'antd'
import { ReadOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Financier } from './FundingApplications'

const { useToken } = theme

interface DimensionInputProps {
  value?: string[]
  financiers: Financier[]
  onChange?: (value: string[]) => void
}

const CheckableCards: React.FC<DimensionInputProps> = ({ value = [], onChange, financiers }) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(value || [])
  const { token } = useToken()
  const { t } = useTranslation()

  const triggerChange = (changedValue: any) => {
    const newValue = _.xor(checkedValues, [changedValue])
    setCheckedValues(newValue)
    onChange?.(newValue)
  }

  const cardAction = (financier: Financier) => (
    <a href={financier?.url} target="_blank" rel="noopener noreferrer">
      <ReadOutlined
        style={{
          marginRight: 8
        }}
      />
      {t('fundingApplicationsPage:info')}
    </a>
  )

  const checkedStyle = {
    border: `1px solid ${token.colorPrimary}`,
    boxShadow: `0px 0px 15px -5px ${token.colorPrimary}`
  }

  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{
          width: '100%',
          marginBottom: 16
        }}
      >
        {financiers.map(financier => (
          <Col span={8} key={financier.name}>
            <Card
              onClick={() => triggerChange(financier.name)}
              hoverable
              style={checkedValues.includes(financier.name) ? checkedStyle : {}}
              cover={
                <div
                  style={{
                    height: 100,
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {typeof financier.img === 'object' ? (
                    <figure
                      style={{
                        maxWidth: 200,
                        maxHeight: 80
                      }}
                    >
                      {financier?.img}
                    </figure>
                  ) : (
                    <img
                      className={_.camelCase(financier.name)}
                      alt="example"
                      src={financier.img as string}
                      style={{
                        maxWidth: 200,
                        maxHeight: 80
                      }}
                    />
                  )}
                </div>
              }
              actions={financier.url ? [cardAction(financier)] : undefined}
            >
              <Card.Meta title={financier.name} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default CheckableCards
