import Highcharts from 'highcharts'
import { ContextRequestParams } from '../actions'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  CompanySettingsActionTypes,
  SET_COMPANY_CHART_COLORS,
  SET_COMPANY_SETTINGS,
  DELETE_COMPANY_CHART_COLORS
} from './types'
import { CompanySettings } from '../../../types/companySettings/CompanySettings'
import { ChartColorPalette, initialColors } from '../../../pages/settings/company/general/Appearance/GraphColorSettings'
import { Company } from '../../../types/company/Company'

export const setCompanySettingsAction = (companySettings: CompanySettings): CompanySettingsActionTypes => ({
  type: SET_COMPANY_SETTINGS,
  payload: companySettings
})

export const setCompanyChartColorsAction = (chartColors: string[]): CompanySettingsActionTypes => ({
  type: SET_COMPANY_CHART_COLORS,
  payload: chartColors
})

export const deleteCompanyChartColorsAction = (chartColors: string[]): CompanySettingsActionTypes => ({
  type: DELETE_COMPANY_CHART_COLORS,
  payload: chartColors
})

const updateHightchartColors = (colorPalette: string[]) => {
  Highcharts.setOptions({
    colors: colorPalette
  })
}

export const getCompanyChartColorsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/chart-color-palette`,
    success: (response: ChartColorPalette) => {
      if (response?.companyId) {
        updateHightchartColors(response.colors)
        return setCompanyChartColorsAction(response.colors)
      }
      updateHightchartColors(initialColors)
      return setCompanyChartColorsAction(initialColors)
    }
  },
  meta: {
    type: 'COMPANY_CHART_COLORS'
  }
})

export const updateCompanyChartColorsRequest = (
  { companyId }: ContextRequestParams,
  data: ChartColorPalette
): PutRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: PUT,
      data,
      url: `/api/companies/${companyId}/settings/chart-color-palette`,
      success: (response: ChartColorPalette) => {
        updateHightchartColors(response.colors)
        return setCompanyChartColorsAction(response.colors)
      }
    },
    meta: {
      type: 'COMPANY_CHART_COLORS',
      notification: true
    }
  }
}

export const deleteCompanyChartColorsRequest = (
  { companyId }: ContextRequestParams,
  colors: string[]
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/settings/chart-color-palette`,
    success: () => {
      updateHightchartColors(colors)
      return setCompanyChartColorsAction(colors)
    }
  },
  meta: {
    type: 'DELETE_COMPANY_CHART_COLORS',
    notification: true
  }
})

export const getCompanySettingsRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/budgeting/input`,
    params,
    success: (response: CompanySettings) => setCompanySettingsAction(response)
  },
  meta: {
    type: 'COMPANY_SETTINGS'
  }
})

export const updateCompanySettingsRequest = (
  { companyId, ...params }: ContextRequestParams,
  data: any
): PutRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: PUT,
      data,
      url: `/api/companies/${companyId}/settings/budgeting/input`,
      params,
      success: (response: CompanySettings) => setCompanySettingsAction(response)
    },
    meta: {
      type: 'COMPANY_SETTINGS',
      notification: true
    }
  }
}
