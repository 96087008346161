import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Language } from '../../types/user/User'
import { useDocsURL } from '../../utils/docs'

interface DocumentationLinkProps {
  route?: { path?: string }
  id?: { [key in Language]: string }
}

const DocumentationLink = ({ route, id }: DocumentationLinkProps) => {
  const {
    i18n: { language }
  } = useTranslation()

  const location = useLocation()
  const docsURL = useDocsURL()
  const { t } = useTranslation()

  const generateDocsUrl = (path: string) => {
    let searchParam = ''
    const langId = id?.[language as Language]

    if (langId) searchParam = `?id=${id?.[language as Language]}`

    if (path.includes('accountant')) {
      return `${docsURL}manual/accountant${path}${searchParam}`
    }
    if (path.includes('financier')) {
      return `${docsURL}manual/financier${path}${searchParam}`
    }
    return `${docsURL}manual/businesses${path}${searchParam}`
  }

  return (
    <a rel="noopener noreferrer" target="_blank" href={generateDocsUrl(route?.path || location.pathname).toString()}>
      {t('header:manual')}
    </a>
  )
}

export default DocumentationLink
