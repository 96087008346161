import { SET_STATEMENT_ROWS, SetStatementRowsAction } from './types'
import { StatementRow } from '../../../types/statementRow/StatementRow'

const initialState: StatementRow[] = []

const statementRowsReducer = (state = initialState, action: SetStatementRowsAction) => {
  switch (action.type) {
    case SET_STATEMENT_ROWS:
      return action.payload
    default:
      return state
  }
}

export default statementRowsReducer
