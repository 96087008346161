import { Col, Row, Select, Form } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { investmenFundingSourceSelector } from '../../../../../redux/entities/investmentFundingSources/selectors'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { LoanFormFields } from '../../../loans/components/LoanFormFields'
import { InvestmentFundingSource } from '../../../../../types/investment/InvestmentFundingSource'
import { debtFundingSourceIds } from './utils'

interface InvestmentFundingFormFieldsProps {
  formType: 'investment' | 'divestment' | null
  fields: any
}

const InvestmentFundingFormFields: React.FC<InvestmentFundingFormFieldsProps> = ({
  formType,
  fields: { key, name, ...restField }
}: InvestmentFundingFormFieldsProps) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const investmentFundingSources = useSelector(investmenFundingSourceSelector)
  const ref = React.createRef<any>()

  const handleInvestmentSourceSelectChange = (value: number, field: any) => {
    const { balanceSheetRowId } = investmentFundingSources.find(s => s.id === value) || ({} as InvestmentFundingSource)
    // Asetetaan tase rivin id lainan alle rahoituksenlahteen arvoista
    form.setFieldValue(['fundings', field, 'loan', 'balanceSheetRowId'], balanceSheetRowId)
    // Tyhjätään accountit jos rahoituksen lähde muutttuu, eri lähteen alla voi olla eri tilejä.
    form.setFieldValue(['fundings', field, 'loan', 'longTermAccountCode'], undefined)
    form.setFieldValue(['fundings', field, 'loan', 'shortTermAccountCode'], undefined)
  }

  const sourceSelectOptions = () => {
    const sources = investmentFundingSources.filter(ifs => {
      if (formType === 'investment') return true
      return ifs.id === 15
    })

    return Object.entries(_.groupBy(sources, 'investmentFundingSourceTypeId'))
      .reverse()
      .map(([groupId, investmentFundingSourceGroup]) => (
        <Select.OptGroup key={groupId} label={t(`investmentFundingSourceTypes:${groupId}`)}>
          {investmentFundingSourceGroup.map(investmentFundingSource => [
            <Select.Option key={investmentFundingSource.id} value={investmentFundingSource.id}>
              {t(`investmentFundingSources:${investmentFundingSource.id}`)}
            </Select.Option>
          ])}
        </Select.OptGroup>
      ))
  }

  return (
    <>
      <Form.Item {...restField} name={[name, 'id']} style={{ display: 'none' }}>
        <FormattedInputNumber />
      </Form.Item>
      <Row className="loan-form-row" gutter={16}>
        <Col span={12}>
          <Form.Item
            {...restField}
            name={[name, 'investmentFundingSourceId']}
            rules={[{ required: true, message: ' ' }]}
            label={
              formType === 'investment'
                ? t('financialStatementsPage:funding-with')
                : t('financialStatementsPage:directing-to')
            }
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={value => handleInvestmentSourceSelectChange(value, name)}
            >
              {sourceSelectOptions()}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...restField}
            name={[name, 'value']}
            rules={[{ required: true, message: ' ' }]}
            label={
              formType === 'investment'
                ? `${t('financialStatementsPage:funding-amount')}`
                : `${t('financialStatementsPage:counter-entry')}`
            }
          >
            <FormattedInputNumber
              style={{ width: '100%' }}
              ref={ref}
              min={formType === 'investment' ? 0 : undefined}
              max={formType === 'divestment' ? 0 : undefined}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return (
            prevValues?.fundings?.[key]?.investmentFundingSourceId !==
            currentValues?.fundings?.[key]?.investmentFundingSourceId
          )
        }}
      >
        {({ getFieldValue }) =>
          debtFundingSourceIds.includes(getFieldValue(['fundings', key, 'investmentFundingSourceId'])) && (
            <LoanFormFields key="LoanFormFields" loanType="investment" fieldDecoratorPrefix={[name, 'loan']} />
          )
        }
      </Form.Item>
    </>
  )
}

export default InvestmentFundingFormFields
