export const isPrimitive = (arg: any) => {
  const type = typeof arg
  return arg === null || (type !== 'object' && type !== 'function')
}

export const mapObjectToFieldData = (object: any, config: { [key: string]: Function } = {}) => {
  const fields: any = []

  const traverse = (o: any, fieldPrefix = '') => {
    for (const [key, value] of Object.entries(o)) {
      if (isPrimitive(value) || config[key]) {
        fields.push({
          name: `${fieldPrefix}${key}`.split('.'),
          value: config[key] ? config[key](value) : value
        })
      } else if (Array.isArray(value)) {
        for (const [i, item] of Object.entries(value)) {
          traverse(item, `${fieldPrefix}${key}[${i}]`)
        }
      } else if (Number.isSafeInteger(+key)) traverse(value, `${fieldPrefix}`)
      else traverse(value, `${fieldPrefix}${key}.`)
    }
  }

  traverse(object)

  return fields
}

export const transformFieldData = (object: any, config: { [key: string]: Function } = {}) => {
  if (!(typeof object === 'object') || object === null) return null
  const traverse = (d: any): any => {
    if (typeof d === 'object') {
      if (Array.isArray(d)) {
        return d.map((child: any) => traverse(child))
      }
      const entries = Object.entries(d).map((obj: any) => {
        const [key, value] = obj
        if (isPrimitive(value) || config[key]) {
          const newValue = config[key] ? config[key](value) : value
          return [key, newValue]
        }
        return traverse(obj)
      })

      return Object.fromEntries(entries)
    }
    return d
  }

  return traverse(object)
}
