import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Tag, TreeSelect } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setContextFilter, setPeriodGroups, setPeriodGroupFilter } from '../../../redux/context/filters/actions'
import { budgetingScenariosAndForecastsSelector } from '../../../redux/context/budgetingScenarios/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import ScenarioSelect from '../../Scenario/ScenarioSelect'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import PeriodGroupsModal from './PeriodGroups/PeriodGroupsModal'
import { ContextFiltersSections } from '../types'
import { checkIfDisabled } from '../utils'
import { disabledStyle } from '../ContextDrawer'
import DimensionSelect from '../../Dimension/DimensionSelect'
import { AppDispatch } from '../../../redux/store'
import { ContractProduct } from '../../../types/contract/Contract'
import { useAuthority } from '../../../utils/Authorizable/authorize'

interface DataTypeFilterProps {
  disableActions?: []
}

interface FilterFormValues {
  dimensions: string[]
  budgetingScenario: number
}

const ContextFilterForm = ({ disableActions }: DataTypeFilterProps) => {
  const [form] = Form.useForm<Partial<FilterFormValues>>()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const filters = useSelector(filtersSelector)
  const [periodGroupModalOpen, setperiodGroupModalOpen] = useState(false)
  const isPro = useAuthority({ product: ContractProduct.PRO })

  useEffect(() => {
    const resetForm = () => {
      form.resetFields()
    }
    resetForm()
  }, [companyId])

  const handleSubmit = ({ budgetingScenario }: Partial<FilterFormValues>) => {
    if (budgetingScenario !== filters.budgetingScenario?.id) {
      const contextFilterValues = {
        budgetingScenario: budgetingScenariosAndForecasts.find(bs => bs.id === budgetingScenario)
      }
      dispatch(setPeriodGroupFilter(undefined))
      dispatch(setContextFilter(contextFilterValues))
    }
  }

  const handleDimensionsChange = (value?: string | string[]) => {
    const dimensionValue = value ? [value].flatMap(val => val) : undefined

    dispatch(setPeriodGroupFilter(undefined))
    dispatch(setContextFilter({ dimensions: dimensionValue }))
  }

  return (
    <>
      <PeriodGroupsModal
        visible={periodGroupModalOpen}
        onCancel={() => {
          setperiodGroupModalOpen(false)
        }}
      />
      <div>
        <Form layout="horizontal" form={form} onValuesChange={(changed, allValues) => handleSubmit(allValues)}>
          <div style={checkIfDisabled(ContextFiltersSections.dimension, disableActions) && disabledStyle}>
            {t('global:dimensions')}
            <Form.Item name="dimensions" initialValue={filters?.dimensions}>
              <DimensionSelect
                onBlur={handleDimensionsChange}
                treeSelectProps={{
                  treeCheckable: isPro,
                  showCheckedStrategy: TreeSelect.SHOW_PARENT,
                  tagRender(props) {
                    return <Tag>{props.label}</Tag>
                  }
                }}
              />
            </Form.Item>
          </div>
          <Divider />
          <div style={checkIfDisabled(ContextFiltersSections.budgetScenario, disableActions) && disabledStyle}>
            {t('contextDrawer:budgeting-scenario')}
            <ScenarioSelect initialValue={filters?.budgetingScenario?.id} />
          </div>
          <Divider />
        </Form>
        <div style={checkIfDisabled(ContextFiltersSections.periodGroups, disableActions) && disabledStyle}>
          <div style={{ width: '100%' }}>
            <div key="period-group" style={{ marginTop: 8, width: '100%' }}>
              {filters.periodGroups?.length ? (
                <Button
                  style={{ width: '100%' }}
                  danger
                  onClick={() => {
                    dispatch(setPeriodGroups())
                    dispatch(setPeriodGroupFilter(undefined))
                  }}
                >
                  {t('contextDrawer:clear-period-group-selection')}
                </Button>
              ) : (
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    setperiodGroupModalOpen(true)
                  }}
                >
                  {t('contextDrawer:period-group-selection')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContextFilterForm
