import React, { useContext } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import DashboardItemFields from '../DasboardItemFieds'
import SelectionTableInput from '../SelectionTableInput'
import { getContextByPropertySelector } from '../../../../../../../redux/context/selectors'
import { loadingSelector } from '../../../../../../../redux/loading/selectors'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from '../../DashboardItemProvider'
import { Context } from '../../../../../../../redux/context/types'
import { BaseSalesItem } from './SalesChartFields'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'

const SalesPieFields = () => {
  const { t } = useTranslation()
  const { companyDashboardItemPreferenceSelection } = useContext(
    CompanyDashboardItemContext
  ) as CompanyDashboardContextType

  const record = companyDashboardItemPreferenceSelection?.params.record
  const salesItems: BaseSalesItem[] = useSelector(getContextByPropertySelector(record as keyof Context))
  const loading = useSelector(loadingSelector)

  const columns: ColumnProps<BaseSalesItem>[] = [
    {
      title: t(`global:name`),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('name')
    }
  ]

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t(`global:${record?.slice(0, -1)}`)} name={['params', 'ids']}>
        <SelectionTableInput loading={!!record && loading[record]} dataSource={salesItems} columns={columns} />
      </Form.Item>
    </>
  )
}

export default SalesPieFields
