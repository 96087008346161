import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  GetRequestAction,
  REQUEST,
  GET,
  PUT,
  PutRequestAction,
  POST,
  PostRequestAction,
  DeleteRequestAction,
  DELETE
} from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import {
  AddReallocationAction,
  AddReallocationTargetAction,
  ADD_REALLOCATION,
  ADD_REALLOCATION_TARGET,
  DeleteReallocationAction,
  DeleteReallocationTargetAction,
  DELETE_REALLOCATION,
  DELETE_REALLOCATION_TARGET,
  SetReallocationsAction,
  SET_REALLOCATIONS,
  UpdateReallocationAction,
  UpdateReallocationTargetAction,
  UPDATE_REALLOCATION,
  UPDATE_REALLOCATION_TARGET
} from './types'
import { ReallocationSource, ReallocationTarget } from '../../../types/reallocation/Reallocation'
import { addReallocationsPageRowKey } from '../../pages/settings/reallocations/reallocations/actions'

export const setReallocations = (reallocations: ReallocationSource[]): SetReallocationsAction => ({
  type: SET_REALLOCATIONS,
  payload: reallocations
})

export const updateReallocation = (reallocation: ReallocationSource): UpdateReallocationAction => ({
  type: UPDATE_REALLOCATION,
  payload: reallocation
})

export const addReallocation = (reallocation: ReallocationSource): AddReallocationAction => ({
  type: ADD_REALLOCATION,
  payload: reallocation
})

export const deleteReallocation = (reallocation: ReallocationSource): DeleteReallocationAction => ({
  type: DELETE_REALLOCATION,
  payload: reallocation
})

export const updateReallocationTarget = (
  target: ReallocationTarget,
  source: ReallocationSource
): UpdateReallocationTargetAction => ({
  type: UPDATE_REALLOCATION_TARGET,
  payload: { target, source }
})

export const addReallocationTarget = (
  target: ReallocationTarget,
  source: ReallocationSource
): AddReallocationTargetAction => ({
  type: ADD_REALLOCATION_TARGET,
  payload: { target, source }
})

export const deleteReallocationTarget = (
  target: ReallocationTarget,
  source: ReallocationSource
): DeleteReallocationTargetAction => ({
  type: DELETE_REALLOCATION_TARGET,
  payload: { target, source }
})

export const addReallocationAction = (
  reallocation: ReallocationSource
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(addReallocation(reallocation))
    dispatch(addReallocationsPageRowKey(reallocation.id))
  }
}

export const getReallocationsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/reallocations`,
    success: setReallocations
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const createReallocationRequest = (
  companyId: Company['id'],
  reallocation: ReallocationSource
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: reallocation,
    url: `/api/companies/${companyId}/accounting/reallocations`,
    success: addReallocationAction
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const updateReallocationRequest = (
  companyId: Company['id'],
  reallocation: ReallocationSource
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: reallocation,
    url: `/api/companies/${companyId}/accounting/reallocations/${reallocation.id}`,
    success: updateReallocation
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const deleteReallocationRequest = (
  companyId: Company['id'],
  reallocation: ReallocationSource
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/accounting/reallocations/${reallocation.id}`,
    success: deleteReallocation
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const createReallocationTargetRequest = (
  companyId: Company['id'],
  reallocationTarget: ReallocationTarget,
  reallocation: ReallocationSource
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: reallocationTarget,
    url: `/api/companies/${companyId}/accounting/reallocations/${reallocation.id}/targets`,
    success: (target: ReallocationTarget) => addReallocationTarget(target, reallocation)
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const updateReallocationTargetRequest = (
  companyId: Company['id'],
  reallocationTarget: ReallocationTarget,
  reallocation: ReallocationSource
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: reallocationTarget,
    url: `/api/companies/${companyId}/accounting/reallocations/${reallocation.id}/targets/${reallocationTarget.id}`,
    success: (target: ReallocationTarget) => updateReallocationTarget(target, reallocation)
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})

export const deleteReallocationTargetRequest = (
  companyId: Company['id'],
  reallocationTarget: ReallocationTarget,
  reallocation: ReallocationSource
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/accounting/reallocations/${reallocation.id}/targets/${reallocationTarget.id}`,
    success: (target: ReallocationTarget) => deleteReallocationTarget(target, reallocation)
  },
  meta: {
    type: 'REALLOCATIONS'
  }
})
