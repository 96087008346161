import { ReportDataType } from '../../../../redux/context/reports/types'
import { Company } from '../../../../types/company/Company'
import { Currency } from '../../../../types/currency/Currency'

export enum GroupSettingDataSource {
  group = 'group',
  subsidiaries = 'subsidiaries'
}

export interface GroupSettingData {
  [ReportDataType.actuals]: {
    source: GroupSettingDataSource
  }
  [ReportDataType.budget]: {
    source: GroupSettingDataSource
  }
}

export interface CustomExchangeRateSeriesWithCompany extends CustomExchangeRateSeries {
  company?: Company
}

export interface CustomExchangeRateSeries {
  id: number

  /** Ryhmän y-tunnus */
  groupId: string

  /** Yrityksen y-tunnus */
  companyId: string

  /** Kussauskäytäntö */
  type: CustomExchangeRateSeriesType

  /** Perusvaluutta, arvo suhteessa noteerattuun valuuttaan (currency) on aina 1 */
  baseCurrency: Currency

  /** Noteerattu valuutta */
  currency: Currency

  /** Valuuttakurssi */
  rates: CustomExchangeRate[]
}

export enum CustomExchangeRateSeriesType {
  automatic = 'automatic',
  manual = 'manual'
}

export interface CustomExchangeRate {
  id?: number

  /** Tuloksen vai taseen kurssi */
  statementType: string

  /** Arvon ajanhetki */
  date: string

  month?: number
  year?: number

  /** Arvo suhteessa perusvaluuttaan */
  value: number
}

export enum StatementType {
  pl = 'pl',
  bs = 'bs'
}
