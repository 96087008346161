/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import { Company } from '../company/Company'
import { Dimension } from '../dimension/Dimension'
import { Permission } from './Permission'
import { Role } from './Role'

export enum Language {
  fi = 'fi',
  sv = 'sv',
  en = 'en'
}
export interface User {
  id: number
  contractId: number
  passwordHash: string
  email: string
  firstName: string | null
  lastName: string | null
  language: Language
  phone: string | null
  position: string
  termsAndConditionsAgreed: boolean
  loginCount: number
  lastLoginAt: string
  tourCompleted: boolean
  displayName: string
  companies: Company[]
  role: Role
  roles: Role[]
  permissions: Permission[]
  settings: UserSetting[]
  dimensions: Dimension[]
}

export interface UserPasswordCredentials {
  password: string
  confirm_password: string
}

export interface UserSetting {
  settingId: string
  value?: string | number
}
