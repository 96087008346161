import i18next from 'i18next'
import { Company } from '../../types/company/Company'
import { ComparisonData, CompanyData, IndustryData, IndustryCompany } from '../../types/industry/Industry'
import { formatValueToNumberByType } from '../../utils/helpers'

export const generateCompareChartCategories = () => {
  const categories = []
  for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear(); i += 1) {
    categories.push(i)
  }
  return categories
}

export const generateCompareChartSeries = (
  keyFigureId: number,
  myCompany: Company,
  selectedCompanies: IndustryCompany[],
  companyData: CompanyData[],
  comparisonData: ComparisonData[]
) => {
  const series: any = []

  // Make series
  const mySerie = { name: myCompany.name, type: 'column', data: [] as (number | null)[] }
  for (const year of generateCompareChartCategories()) {
    const foundData =
      companyData &&
      companyData.find(
        (dataRow: CompanyData) => dataRow.key_figure_fact_year === year && dataRow.keyFigureId === keyFigureId
      )
    mySerie.data.push(
      foundData ? formatValueToNumberByType(foundData.key_figure_fact_value, foundData.key_figure_type) : 0
    )
  }
  series.push(mySerie)

  for (const company of selectedCompanies) {
    const serie = { name: company.name, type: 'column', data: [] as (number | null)[] }

    for (const year of generateCompareChartCategories()) {
      const foundData =
        comparisonData &&
        comparisonData.find(
          (dataRow: ComparisonData) =>
            dataRow.key_figure_fact_year === year &&
            dataRow.keyFigureId === keyFigureId &&
            dataRow.companyId === company.id
        )
      serie.data.push(
        foundData ? formatValueToNumberByType(foundData.key_figure_fact_value, foundData.key_figure_type) : 0
      )
    }
    series.push(serie)
  }

  return series
}

export const generateIndustryChartSeries = (
  keyFigureId: number,
  companyData: CompanyData[],
  industryData: IndustryData[]
) => {
  const series: any = [
    { name: i18next.t('comparisonPage:own'), type: 'column', data: [] as number[] },
    { name: i18next.t('comparisonPage:other'), type: 'column', data: [] as number[] }
  ]

  for (const year of generateCompareChartCategories()) {
    const foundRowCompany =
      companyData &&
      companyData.find((d: CompanyData) => d.key_figure_fact_year === year && d.keyFigureId === keyFigureId)
    series[0].data.push(
      foundRowCompany
        ? formatValueToNumberByType(foundRowCompany.key_figure_fact_value, foundRowCompany.key_figure_type)
        : 0
    )

    const foundRowIndustry =
      industryData &&
      industryData.find((d: IndustryData) => d.key_figure_fact_year === year && d.keyFigureId === keyFigureId)
    series[1].data.push(
      foundRowIndustry ? formatValueToNumberByType(foundRowIndustry.avg, foundRowIndustry.key_figure_type) : 0
    )
  }

  return series
}
