import { Button, Tree, TreeProps } from 'antd'
import React, { Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DataNode } from 'antd/es/tree'
import { dimensionTreeSelector } from '../../../../../redux/context/dimensions/selectors'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { getDimensionsRequest, updateDimensions } from '../../../../../redux/context/dimensions/actions'
import { AppDispatch } from '../../../../../redux/store'

interface DimensionsDraggableProps {
  cancel: () => void
}

interface DimensionData {
  id: number
  companyId: string
  dimensionId: string
  name: string
  parentDimensionId?: string
  order?: number
  key?: string
  title?: string
  children?: DimensionData[]
}

const DimensionsDraggable: React.FC<DimensionsDraggableProps> = ({ cancel }: DimensionsDraggableProps) => {
  const dimensionList = useSelector(dimensionTreeSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const [gData, setGData] = useState(dimensionList as DimensionData[])
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [changedRows, setChangedRows] = useState<string[]>([])
  const updateDimensionsRequest = useBackend('/api/companies/{companyId}/accounting/dimensions')

  const onDragEnter: TreeProps['onDragEnter'] = info => {
    setExpandedKeys(info.expandedKeys)
  }

  const generateTreeDataNodes = (cats: Dimension[], path?: string) => {
    const treeNodes: DimensionData[] = []

    cats.forEach((cat, index) => {
      const currentPath = `${path || 0}-${index}`
      treeNodes.push({
        key: currentPath,
        title: cat.name,
        id: cat.id,
        companyId: cat.companyId,
        name: cat.name,
        dimensionId: cat.dimensionId,
        children: cat.children && generateTreeDataNodes(cat.children, currentPath)
      })
    })

    return treeNodes
  }

  useEffect(() => {
    companyId && dispatch(getDimensionsRequest(companyId))
  }, [companyId])

  useEffect(() => {
    dimensionList && setGData(generateTreeDataNodes(dimensionList))
    return () => {
      setGData([])
    }
  }, [dimensionList])

  const onDrop: TreeProps['onDrop'] = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: DimensionData[],
      key: React.Key,
      callback: (node: DimensionData, i: number, data: DimensionData[]) => void
    ) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
      return true
    }
    const data = [...gData]

    let dragObj: DimensionData
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else if (
      ((info.node as any).props.children || []).length > 0 &&
      (info.node as any).props.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else {
      let ar: DimensionData[] = []
      let i: number
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }
    const changedRow: any = info.dragNode
    setChangedRows([...changedRows, changedRow.dimensionId])
    setGData(data)
  }

  const save = () => {
    const changed: any[] = []
    const loop = (data: any[], parentId?: string) => {
      data.forEach((cat, index) => {
        if (data.find(d => changedRows.includes(d.dimensionId))) {
          companyId &&
            changed.push({
              id: cat.id,
              // companyId,
              // dimensionId: cat.dimensionId,
              order: index,
              // name: cat.name,
              parentDimensionId: parentId || null
            })
        }
        if (cat.children) {
          loop(cat.children, cat.dimensionId)
        }
      })
    }
    loop(gData)

    updateDimensionsRequest
      .put({
        urlParams: { companyId },
        body: {
          data: changed
        }
      })
      .then((response: Dimension[]) => {
        companyId && dispatch(updateDimensions(response))
        dispatch(
          notificationAction({
            type: 'success',
            message: 'UPDATE_DIMENSIONS_SUCCESS'
          })
        )
        cancel()
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR'
          })
        )
      })
  }

  return (
    <>
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={expandedKeys}
        draggable
        blockNode
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        selectable={false}
        treeData={gData as DataNode[]}
      />
      <div style={{ marginTop: 20 }}>
        <Button type="primary" loading={updateDimensionsRequest.loading} onClick={save}>
          {t('global:save')}
        </Button>
        <Button style={{ marginLeft: 8 }} onClick={cancel}>
          {t('global:cancel')}
        </Button>
      </div>
    </>
  )
}

export default DimensionsDraggable
