import { Middleware, configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { logger } from 'redux-logger'
import {
  persistReducer,
  persistStore,
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage
import localforage from 'localforage'
import thunkMiddleware from 'redux-thunk'
import i18n from 'i18next'
import socket from './middleware/socket'
import token from './middleware/token'
import rootReducer from './rootReducer'
import notification from './middleware/notification'
import migrations from './migrations'
import { request } from './middleware/request'
import { backendRequestConfig, deliveryRequestConfig, integrationRequestConfig } from './middleware/types'
import { Language } from '../types/user/User'

export const history = createBrowserHistory()

const persistConfig = {
  version: 7,
  key: 'root',
  storage: localforage,
  blacklist: ['socket'],
  migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [
  thunkMiddleware,
  token,
  request(backendRequestConfig),
  request(deliveryRequestConfig),
  request(integrationRequestConfig),
  notification,
  socket,
  routerMiddleware(history)
] as Middleware[]

// Removed getDefaultMiddleware from middleware, if something brokes add it back
// with values down below and you dont get serializing errors
// ...getDefaultMiddleware({
//   serializableCheck: {
//     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
//   }
// })

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger)
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'REQUEST', 'DELIVERY_REQUEST']
      }
    }).prepend(...middlewares)
})

const changeLanguage = (state: any) => {
  i18n.changeLanguage(state?.session?.currentUser?.language ?? Language.fi)
}

export const persistor = persistStore(store, null, () => {
  if (process.env.NODE_ENV !== `test`) {
    changeLanguage(store.getState())
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
