import i18next from 'i18next'
import dayjs from 'dayjs'
import {
  DailyDelivery,
  DeliverySubscription,
  ManualDelivery,
  MonthlyDelivery,
  PeriodLockDelivery,
  WeeklyDelivery
} from '../../../../../types/deliverySubscription/DeliverySubscription'

export const renderDeliverySubscriptionInitiator = (deliverySubscription: DeliverySubscription) => {
  const { initiator } = deliverySubscription
  if ((initiator as MonthlyDelivery)?.monthly as { day: number }) {
    const { day } = (initiator as MonthlyDelivery).monthly as { day: number }
    return i18next.t('subscriptionsPage:iMonthly', { day })
  }
  if ((initiator as WeeklyDelivery)?.weekly as { dow: number }) {
    const { dow } = (initiator as WeeklyDelivery).weekly as { dow: number }
    return `${i18next.t('global:every')} ${dayjs().day(dow).format('dddd')}`
  }
  if ((initiator as DailyDelivery)?.daily) {
    return i18next.t('subscriptionsPage:daily')
  }
  if ((initiator as PeriodLockDelivery)?.periodLock) {
    return i18next.t('subscriptionsPage:periodLock')
  }
  if ((initiator as ManualDelivery)?.manual) {
    return i18next.t('subscriptionsPage:manual')
  }
  return null
}
