import React, { useEffect, useRef, useState } from 'react'
import Highcharts, { PointOptionsObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dayjs } from 'dayjs'
import { ReportRow } from '../../redux/context/reports/types'
import { getDrilldownData } from './utils'
import { useBackend } from '../../services/backend'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { FiscalYear } from '../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'
import { formatter } from '../../pages/reporting/custom/components/utils'
import { DrillData } from '../../pages/reporting/custom/components/chart/chartUtils'

interface PieChartProps {
  titleText: string
  report: ReportRow[]
  seriesData: PointOptionsObject[]
  seriesName?: string
  currentPeriodGroup?: FiscalYear | PeriodGroup
  height?: number
  width?: number | null
  percentage?: boolean
  date?: Dayjs | null
  drilldown?: boolean
}

export interface ReportRowExplanation extends ReportRow {
  explanation?: true
  account?: boolean
  period?: InterpreterPeriod
  translation?: string
  source: string
  children?: ReportRowExplanation[]
}

export enum InterpreterPeriod {
  current = 'current',
  previous = 'previous',
  cumulative = 'cumulative',
  pastyear = 'pastyear'
}

export const PieChart: React.FC<PieChartProps> = ({
  report,
  titleText,
  seriesData,
  seriesName,
  height,
  width,
  percentage,
  date,
  drilldown,
  currentPeriodGroup
}: PieChartProps) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const { periodGroups, dimensions, budgetingScenario } = useSelector(filtersSelector)
  const [explainerData, setExplainerData] = useState<DrillData>({})
  const explainFormulaRequest = useBackend('/api/companies/{companyId}/reporting/key-figure-statement/internal/explain')

  useEffect(() => {
    // Tyhjää explainer datas jos vaihtuu dimensio tai scenario
    setExplainerData({})
  }, [dimensions, budgetingScenario])

  const handleExplainRequest = async (formulaId: number | string) => {
    return explainFormulaRequest.get({
      urlParams: { companyId },
      body: { params: { formulaId, periodGroups } }
    }) as Promise<{ data: ReportRowExplanation[] }[]>
  }

  const drilldownHanlder: Highcharts.DrilldownCallbackFunction = async e => {
    const chart = chartRef.current?.chart
    if (!e.seriesOptions && chart) {
      const formulaId: string = (e.point as any).drilldown.toString()
      let data: Highcharts.PointOptionsObject[]

      // Jos on eka id, ja explainia ei ole vielä haetaan data asynccinä,
      // muuten otetaan explain statesta
      if (formulaId.split('-').length === 1 && !(formulaId in explainerData)) {
        chart.showLoading('Loading ...')
        const explain = await handleExplainRequest(formulaId)

        const drillData = getDrilldownData(formulaId, explain[0].data, currentPeriodGroup, date)
        setExplainerData({ ...explainerData, ...drillData })
        data = drillData[formulaId]

        chart.hideLoading()
      } else {
        data = explainerData[formulaId]
      }
      const series = {
        name: e.point.name,
        id: formulaId,
        type: 'pie',
        data: data?.filter(d => d?.y !== null)
      } satisfies Highcharts.SeriesOptionsType

      chart.addSeriesAsDrilldown(e.point, series)
    }
  }

  const options = {
    chart: {
      plotShadow: false,
      type: 'pie',
      height,
      width,
      events: drilldown && {
        drilldown: drilldownHanlder
      }
    },
    title: {
      text: titleText
    },
    tooltip: {
      formatter
    },
    plotOptions: {
      pie: {
        animation: false,
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: 'pie',
        name: seriesName,
        data: seriesData.map(s => ({ ...s, percent: percentage }))
      }
    ],
    exporting: {
      enabled: false
    }
  } as Highcharts.Options

  useEffect(() => {
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.drillUp()
    chartRef.current?.chart?.update(options)
  }, [report.length, date, seriesData])

  if (seriesData.length < 1) {
    return <Result status={404} subTitle={t('global:no-data-available')} />
  }

  return <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
}

PieChart.defaultProps = {
  height: 400,
  width: null
}
