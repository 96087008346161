import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Space } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { DimensionTreeSelect } from './DimensionTreeSelect'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface DimensionCategoryProps {
  path: string
  category: CustomReportCategory
}

export const DimensionCategory: React.FC<DimensionCategoryProps> = ({ path, category }: DimensionCategoryProps) => {
  const { addPeriodGroup, addFunction, deleteObject, getPathContext } = useContext(
    CategoryContext
  ) as CategoryContextType
  const { t } = useTranslation()

  const { companyId } = getPathContext(path)

  return (
    <>
      <DimensionTreeSelect path={path} category={category} companyId={companyId} />
      <Divider type="vertical" />
      <Space>
        <Button onClick={() => addFunction(path)} icon={<PlusOutlined />}>
          {t(`customReportCategoryType:function`)}
        </Button>
        <Button onClick={() => addPeriodGroup(path)} icon={<PlusOutlined />}>
          {t(`customReportCategoryType:periodGroup`)}
        </Button>
      </Space>
      <Divider type="vertical" />
      <Popconfirm
        title={t('global:delete-confirm')}
        onConfirm={() => deleteObject(path)}
        okText={t('global:yes')}
        cancelText={t('global:no')}
      >
        <Button icon={<DeleteOutlined />} />
      </Popconfirm>
    </>
  )
}
