/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createNodeSelection,
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  // eslint-disable-next-line camelcase
  // DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  NodeKey,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from 'lexical'
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text'
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType
} from '@lexical/selection'
import { $isListNode, ListNode, insertList, removeList } from '@lexical/list'
import { $isCodeNode, CODE_LANGUAGE_MAP } from '@lexical/code'
import { useTranslation } from 'react-i18next'
import { $createAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { InsertTableCommandPayload, $createTableNodeWithDimensions } from '@lexical/table'
import { Button, Dropdown, Input, MenuProps } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'
import DropDown, { DropDownItem } from '../DropDown'
import { getSelectedNode } from '../nodes/getSelectedNode'
import { sanitizeUrl } from '../url'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'

export function FillColumns() {
  const columns = prompt('Enter the number of columns:', '')

  if (columns != null) {
    return columns
  }
  return String(0)
}

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check list', // TURHA, MUTTA ÄLÄ POISTA
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote'
}

const blockTypeToBlockNameTranslation = {
  bullet: 'editor-bulleted-list',
  check: 'Check list', // TURHA, MUTTA ÄLÄ POISTA
  h1: 'editor-header1',
  h2: 'editor-header2',
  h3: 'editor-header3',
  number: 'editor-numbered-list',
  paragraph: 'editor-normal',
  quote: 'editor-quote'
}

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana']
]

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
  ['21px', '21px'],
  ['22px', '22px'],
  ['23px', '23px'],
  ['24px', '24px'],
  ['25px', '25px'],
  ['26px', '26px'],
  ['27px', '27px'],
  ['28px', '28px'],
  ['29px', '29px'],
  ['30px', '30px'],
  ['31px', '31px'],
  ['32px', '32px'],
  ['33px', '33px'],
  ['34px', '34px'],
  ['35px', '35px'],
  ['36px', '36px'],
  ['37px', '37px'],
  ['38px', '38px'],
  ['39px', '39px'],
  ['40px', '40px'],
  ['41px', '41px'],
  ['42px', '42px'],
  ['43px', '43px'],
  ['44px', '44px'],
  ['45px', '45px'],
  ['46px', '46px'],
  ['47px', '47px'],
  ['48px', '48px'],
  ['49px', '49px'],
  ['50px', '50px']
]

function dropDownActiveClass(active: boolean) {
  if (active) return 'active dropdown-item-active'
  return ''
}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false
}: {
  blockType: keyof typeof blockTypeToBlockName
  editor: LexicalEditor
  disabled?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      insertList(editor, 'bullet')
    } else {
      removeList(editor)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      insertList(editor, 'number')
    } else {
      removeList(editor)
    }
  }

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode())
        }
      })
    }
  }

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonLabel={t(`customReportPage:${blockTypeToBlockNameTranslation[blockType]}`)}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'paragraph')}`} onClick={formatParagraph}>
        <i className="icon paragraph" />
        <span className="text">{t('customReportPage:editor-normal')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h1')}`} onClick={() => formatHeading('h1')}>
        <i className="icon h1" />
        <span className="text">{t('customReportPage:editor-header1')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h2')}`} onClick={() => formatHeading('h2')}>
        <i className="icon h2" />
        <span className="text">{t('customReportPage:editor-header2')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'h3')}`} onClick={() => formatHeading('h3')}>
        <i className="icon h3" />
        <span className="text">{t('customReportPage:editor-header3')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'bullet')}`} onClick={formatBulletList}>
        <i className="icon bullet-list" />
        <span className="text">{t('customReportPage:editor-bulleted-list')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'number')}`} onClick={formatNumberedList}>
        <i className="icon numbered-list" />
        <span className="text">{t('customReportPage:editor-numbered-list')}</span>
      </DropDownItem>
      <DropDownItem className={`item ${dropDownActiveClass(blockType === 'quote')}`} onClick={formatQuote}>
        <i className="icon quote" />
        <span className="text">{t('customReportPage:editor-quote')}</span>
      </DropDownItem>
    </DropDown>
  )
}

function Divider(): JSX.Element {
  return <div className="divider" />
}

function FontDropDown({
  editor,
  value,
  type,
  disabled = false
}: {
  editor: LexicalEditor
  value: string
  type: string
  disabled?: boolean
}): JSX.Element {
  const style = type
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option
          })
        }
      })
    },
    [editor, style]
  )

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`toolbar-item ${style}`}
      buttonLabel={value}
      buttonAriaLabel="Formatting options for font size"
    >
      {FONT_SIZE_OPTIONS.map(([option, text]) => (
        <DropDownItem
          className={`item ${dropDownActiveClass(value === option)} ${style === 'font-size' ? 'fontsize-item' : ''}`}
          onClick={() => handleClick(option)}
          key={option}
        >
          <span className="text">{text}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}

export default function ToolbarPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph')
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null)
  const [fontSize, setFontSize] = useState<string>('15px')
  const [fontColor, setFontColor] = useState<string>('#000')
  const [bgColor, setBgColor] = useState<string>('#fff')
  const [fontFamily, setFontFamily] = useState<string>('Arial')
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isSubscript, setIsSubscript] = useState(false)
  const [isSuperscript, setIsSuperscript] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isRTL, setIsRTL] = useState(false)
  const [codeLanguage, setCodeLanguage] = useState<string>('')
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())
  const [rows, setRows] = useState<valueType | null>(null)
  const [columns, setColumns] = useState<valueType | null>(null)
  const IS_APPLE = false
  const { t } = useTranslation()

  const onClick = (payload: InsertTableCommandPayload) => {
    editor.update(() => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection)) {
        return true
      }

      const { focus } = selection
      const focusNode = focus.getNode()

      if (focusNode !== null) {
        const tableNode = $createTableNodeWithDimensions(Number(payload.rows), Number(payload.columns), {
          rows: true,
          columns: false
        })

        if ($isRootOrShadowRoot(focusNode)) {
          const target = focusNode.getChildAtIndex(focus.offset)

          if (target !== null) {
            target.insertBefore(tableNode)
          } else {
            focusNode.append(tableNode)
          }

          tableNode.insertBefore($createParagraphNode())
        } else {
          const topLevelNode = focusNode.getTopLevelElementOrThrow()
          topLevelNode.insertAfter(tableNode)
        }

        tableNode.insertAfter($createParagraphNode())
        const nodeSelection = $createNodeSelection()
        nodeSelection.add(tableNode.getKey())
        $setSelection(nodeSelection)
      }
      return null
    })
    return null
  }

  const Fill = () => {
    const selectedRows = String(rows)
    const selectedColumns = String(columns)

    if (selectedColumns == null || selectedRows == null || selectedColumns === '' || selectedRows === '') {
      return
    }

    onClick({ columns: selectedColumns, rows: selectedRows })
    setRows(null)
    setColumns(null)
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsSubscript(selection.hasFormat('subscript'))
      setIsSuperscript(selection.hasFormat('superscript'))
      setIsCode(selection.hasFormat('code'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP
            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '')
            return
          }
        }
      }
      // Handle buttons
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'))
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'))
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'))
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'))
    }
  }, [activeEditor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor, updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [activeEditor, editor, updateToolbar])

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () =>
            $createAutoLinkNode(sanitizeUrl(selection.getTextContent()), { target: '_blank' })
          )
        }
      })
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const items: MenuProps['items'] = [
    {
      label: (
        <span onClick={e => e.stopPropagation()}>
          <p>{t('customReportPage:editor-rows')}</p>
          <FormattedInputNumber
            value={rows}
            min={1}
            onChange={e => setRows(e)}
            placeholder={t('customReportPage:editor-rows')}
          />
        </span>
      ),
      key: '0'
    },
    {
      label: (
        <span onClick={e => e.stopPropagation()}>
          <p>{t('customReportPage:editor-columns')}</p>
          <FormattedInputNumber
            value={columns}
            min={1}
            onChange={e => setColumns(e)}
            placeholder={t('customReportPage:editor-columns')}
          />
        </span>
      ),
      key: '1'
    },
    {
      type: 'divider'
    },
    {
      label: <Button onClick={() => Fill()}> + {t('global:add')}</Button>,
      key: '3'
    }
  ]

  return (
    <div className="toolbar">
      <Button
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
        title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </Button>
      <Button
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined)
        }}
        title={IS_APPLE ? 'Redo (⌘Y)' : 'Redo (Ctrl+Y)'}
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </Button>
      <Divider />
      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown disabled={!isEditable} blockType={blockType} editor={editor} />
          <Divider />
        </>
      )}
      <>
        <FontDropDown disabled={!isEditable} type="font-size" value={fontSize} editor={editor} />
        <Button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
          className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
          title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
          aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
        >
          <i className="format bold" />
        </Button>
        <Button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
          className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
          title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
          aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
        >
          <i className="format italic" />
        </Button>
        <Button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
          className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
          title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
          aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'}`}
        >
          <i className="format underline" />
        </Button>
        <Button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')
          }}
          className={`toolbar-item spaced ${isCode ? 'active' : ''}`}
          title="Insert code block"
          aria-label="Insert code block"
        >
          <i className="format code" />
        </Button>
        <Divider />
        <DropDown
          disabled={!isEditable}
          buttonLabel={t('customReportPage:editor-align')}
          buttonClassName="toolbar-item spaced alignment"
          buttonAriaLabel="Formatting options for text alignment"
        >
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
            }}
            className="item"
          >
            <i className="icon left-align" />
            <span className="text">{t('customReportPage:editor-left-align')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
            }}
            className="item"
          >
            <i className="icon center-align" />
            <span className="text">{t('customReportPage:editor-center-align')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
            }}
            className="item"
          >
            <i className="icon right-align" />
            <span className="text">{t('customReportPage:editor-right-align')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
            }}
            className="item"
          >
            <i className="icon justify-align" />
            <span className="text">{t('customReportPage:editor-justify-align')}</span>
          </DropDownItem>
          <Divider />
        </DropDown>
        <Dropdown menu={{ items }} trigger={['click']}>
          <span className="text" onClick={e => e.stopPropagation()}>
            <Button className="toolbar-item spaced ">
              <span className="text">+ {t('customReportPage:editor-table')}</span>
            </Button>
          </span>
        </Dropdown>
      </>
    </div>
  )
}
