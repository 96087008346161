import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DimensionSelect from '../../../../../../components/Dimension/DimensionSelect'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import EditableCellTable from '../../../../../../components/Table/EditableCellTable'
import { EditableColumnProps } from '../../../../../../components/Table/EditableRowTable'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../../../redux/store'
import { useBackend } from '../../../../../../services/backend'
import { ExternalDimension } from './types'
import { useColumnSearchProps } from '../../../../../../components/Table/useColumSearchProps'

interface DimensionMapProps {
  getDimensionsUrl: string
  updateDimensionsUrl: string
  dataKey: string
}

const DimensionMap = ({ getDimensionsUrl, updateDimensionsUrl, dataKey }: DimensionMapProps) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const getdimensions = useBackend(getDimensionsUrl, process.env.REACT_APP_INTEGRATION_URL)
  const updatedimensions = useBackend(updateDimensionsUrl, process.env.REACT_APP_INTEGRATION_URL)
  const [dimensions, setdimensions] = useState<ExternalDimension[]>([])

  const fetchdimensions = async () => {
    try {
      const response = await getdimensions.get({
        urlParams: { companyId }
      })

      setdimensions(
        response.map((r: any) => ({
          ...r,
          updateId: r[dataKey],
          name: r?.name || r?.Name
        }))
      )
    } catch (error) {
      setdimensions([])
    }
  }

  useEffect(() => {
    fetchdimensions()
  }, [companyId])

  const onUpdate = (formFields: ExternalDimension) => {
    const { dimensionId, updateId } = formFields
    updatedimensions
      .put({
        urlParams: { companyId, id: updateId },
        body: { data: { dimensionId: dimensionId || null } }
      })
      .then((response: ExternalDimension) => {
        setdimensions(
          dimensions.map(r => {
            if (r.updateId === updateId) {
              return { ...r, ...response, dimension: response?.dimension }
            }
            return r
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'UPDATE_INTEGRATION_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  const columns: EditableColumnProps<ExternalDimension>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      ...useColumnSearchProps('name')
    },
    {
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      render: (text: any, record: ExternalDimension) => record?.dimension?.name,
      editable: true,
      inputField: DimensionSelect
    }
  ]

  return (
    <LoadingWrapper loading={getdimensions.loading || updatedimensions.loading}>
      <EditableCellTable<ExternalDimension>
        size="middle"
        rowKey={(val: any) => val[dataKey]}
        dataSource={dimensions}
        columns={columns}
        onUpdate={onUpdate}
        pagination={false}
      />
    </LoadingWrapper>
  )
}

export default DimensionMap
