import { Button, Select, Form } from 'antd'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { keyPerformanceIndicatorsSelector } from '../../../../../../redux/context/performanceIndicators/selectors'
import { PerformanceIndicator } from '../../../../../../redux/context/performanceIndicators/types'
import { subscribableKeyFiguresSelector } from '../../../../../../redux/entities/keyFigures/selectors'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { updateKeyPerformanceIndicatorsRequest } from '../../../../../../redux/context/performanceIndicators/actions'
import { AppDispatch } from '../../../../../../redux/store'

const { Option, OptGroup } = Select

const KeyPerformanceIndicatorsForm = () => {
  const dispatch: AppDispatch = useDispatch()

  const keyPerformanceIndicators = useSelector(keyPerformanceIndicatorsSelector)
  const [form] = Form.useForm<{ list: PerformanceIndicator[] }>()
  const keyFigures = useSelector(subscribableKeyFiguresSelector)
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)

  useEffect(() => {
    form.setFieldsValue({ list: keyPerformanceIndicators })
  }, [keyPerformanceIndicators])

  const handleSave = (selectedKeyFigures: PerformanceIndicator[]) => {
    companyId && dispatch(updateKeyPerformanceIndicatorsRequest(companyId, selectedKeyFigures))
  }

  const handleSubmit = async () => {
    try {
      const { list } = await form.validateFields()
      handleSave(list.filter(item => !!item.id))
    } catch (error) {
      console.log(error)
    }
  }

  const selectOptions = () => {
    return Object.entries(_.groupBy(keyFigures, 'group')).map(([keyFigureGroupName, keyFigureGroup]) => (
      <OptGroup key={keyFigureGroupName} label={t(`keyFigurePage:${keyFigureGroupName}`)}>
        {keyFigureGroup.map(keyFigure => [
          <Option key={keyFigure.id} value={keyFigure.id}>
            {t(`keyFigureStatement:${keyFigure.id}`)}
          </Option>
        ])}
      </OptGroup>
    ))
  }

  return (
    <Form name="selectedPerformanceIndicators" onFinish={handleSubmit} form={form}>
      {[0, 1, 2].map(i => (
        <Form.Item name={['list', i, 'id']} rules={[{ required: false }]} key={i}>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={t('global:choose-here')}
            allowClear
            style={{ maxWidth: 300 }}
          >
            {selectOptions()}
          </Select>
        </Form.Item>
      ))}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('global:save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default KeyPerformanceIndicatorsForm
