import { Dictionary } from 'lodash'
import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SET_CONTRACT_PRODUCTS, SetContractProductsAction } from './types'

export const setContractProducts = (contractProducts: Dictionary<string>): SetContractProductsAction => ({
  type: SET_CONTRACT_PRODUCTS,
  payload: contractProducts
})

export const getContractProductsRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: '/api/contract-products',
    success: (payload: Dictionary<string>) => setContractProducts(payload)
  },
  meta: {
    type: 'CONTRACT_PRODUCTS'
  }
})
