import React from 'react'
import { Divider, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import Group from './Group'
import { MemberType } from '../factories/GroupMemberFactory'

interface StakeholdersConfig {
  owners: {
    privates: MemberType
    companies: MemberType
  }
  beneficiaries: MemberType
  executiveBoardMembers: MemberType
}

const config: StakeholdersConfig = {
  owners: {
    privates: 'PersonOwner',
    companies: 'CompanyOwner'
  },
  beneficiaries: 'PersonOwner',
  executiveBoardMembers: 'Person'
}

const Stakeholders = () => {
  const { t } = useTranslation()

  const recurse = (cfg: StakeholdersConfig, path: string[] = []) => {
    return Object.entries(cfg).map(data => {
      const [key, value] = data
      const currentPath = [...path, key]
      if (typeof value === 'string' && value !== null) {
        return <Group key={key} data={data} path={currentPath} />
      }
      if (typeof value === 'object' && value !== null) {
        return (
          <div style={{ marginBottom: 32 }} key={key}>
            <Typography.Title style={{ marginBottom: 16 }} level={4}>
              {t(`companyInformation:${key}`, { count: 2 })}
            </Typography.Title>
            <Divider />
            {recurse(value, currentPath)}
          </div>
        )
      }
      return null
    })
  }

  return <div>{recurse(config)}</div>
}

export default Stakeholders
