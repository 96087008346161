import { ConfigProvider, Select, theme, Typography } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TimePeriodOptions } from '../../../../../redux/context/customReports/types'
import { KeyfigureCategory } from './KeyfigureCategory'
import { CompanyCategory } from './CompanyCategory'
import { DimensionCategory } from './DimensionCategory'
import { FunctionCategory } from './FunctionCategory'
import { PeriodGroupCategory } from './PeriodGroupCategory'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { ReferenceCategory } from './ReferenceCategory'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

export interface CategoryNodeRenderProps {
  category: CustomReportCategory
  path: string
  hideActions?: boolean
  multiple?: boolean
}

const { Text } = Typography

export const CategoryNodeRender = ({ path, category, hideActions, multiple }: CategoryNodeRenderProps) => {
  const { t } = useTranslation()
  const {
    token: { colorBgLayout, paddingXS, borderRadius, colorBorder }
  } = theme.useToken()
  const { editObject, getPathContext } = useContext(CategoryContext) as CategoryContextType
  const previousCategoryContext = getPathContext(path)
  const renderNode = () => {
    switch (category.type) {
      case 'company':
        return <CompanyCategory path={path} category={category} />
      case 'dimension':
        return <DimensionCategory path={path} category={category} />
      case 'periodGroup':
        return <PeriodGroupCategory path={path} category={category} hideActions={hideActions} />
      case 'function':
        return <FunctionCategory path={path} category={category} />
      case 'reference':
        return <ReferenceCategory path={path} category={category} />
      case 'formulaId':
      case 'keyFigureId':
        return <KeyfigureCategory path={path} category={category} hideActions={hideActions} multiple={multiple} />

      default:
        return null
    }
  }

  const styles: React.CSSProperties = {
    borderRadius,
    border: '1px solid',
    borderColor: colorBorder,
    padding: paddingXS,
    backgroundColor: colorBgLayout
  }

  const changeType = (c: Partial<CustomReportCategory>) => {
    if (c) {
      editObject(path, {
        ...category,
        ...c,
        value: c.type === 'reference' ? undefined : TimePeriodOptions.CurrentMonth
      })
    }
  }

  return (
    <div style={styles}>
      {(category.type === 'periodGroup' || category.type === 'reference') &&
      previousCategoryContext.function === 'diff' ? (
        <Select
          size="small"
          defaultValue="periodGroup"
          style={{ width: 120 }}
          onChange={type =>
            changeType({
              type: type as
                | 'function'
                | 'company'
                | 'dimension'
                | 'periodGroup'
                | 'keyFigureId'
                | 'formulaId'
                | 'reference'
                | undefined
            })
          }
          value={category.type}
          options={[
            {
              value: 'periodGroup',
              label: t(`customReportCategoryType:periodGroup`)
            },
            { value: 'reference', label: t(`customReportCategoryType:reference`) }
          ]}
        />
      ) : (
        <Text underline>{t(`customReportCategoryType:${category.type}`)}</Text>
      )}
      <ConfigProvider componentSize="small">
        <div>{renderNode()}</div>
      </ConfigProvider>
    </div>
  )
}
