import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddBudgetingScenarioAction,
  ADD_BUDGETING_SCENARIO,
  DeleteBudgetingScenarioAction,
  DELETE_BUDGETING_SCENARIO,
  SetBudgetingScenariosAction,
  SET_BUDGETING_SCENARIOS,
  UpdateBudgetingScenarioAction,
  UPDATE_BUDGETING_SCENARIO
} from './types'

export const setBudgetingScenarios = (budgetingScenarios: BudgetingScenario[]): SetBudgetingScenariosAction => ({
  type: SET_BUDGETING_SCENARIOS,
  payload: budgetingScenarios
})

export const addBudgetingScenario = (budgetingScenario: BudgetingScenario): AddBudgetingScenarioAction => ({
  type: ADD_BUDGETING_SCENARIO,
  payload: budgetingScenario
})

export const updateBudgetingScenario = (budgetingScenario: BudgetingScenario): UpdateBudgetingScenarioAction => ({
  type: UPDATE_BUDGETING_SCENARIO,
  payload: budgetingScenario
})

export const deleteBudgetingScenario = (budgetingScenario: BudgetingScenario): DeleteBudgetingScenarioAction => ({
  type: DELETE_BUDGETING_SCENARIO,
  payload: budgetingScenario
})

export const getBudgetingScenariosRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/budgeting/budgeting-scenarios`,
    success: (payload: BudgetingScenario[]) => setBudgetingScenarios(payload)
  },
  meta: {
    type: 'BUDGETING_SCENARIOS'
  }
})

export const createBudgetingScenarioRequest = (
  companyId: Company['id'],
  payload: BudgetingScenario
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/budgeting-scenarios`,
    success: (response: BudgetingScenario) => addBudgetingScenario(response)
  },
  meta: {
    type: 'CREATE_BUDGETING_SCENARIO',
    notification: true
  }
})

export const updateBudgetingScenarioRequest = (
  companyId: Company['id'],
  payload: BudgetingScenario
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/budgeting-scenarios/${payload.id}`,
    success: (response: BudgetingScenario) => updateBudgetingScenario(response)
  },
  meta: {
    type: 'UPDATE_BUDGETING_SCENARIO',
    notification: true
  }
})

export const deleteBudgetingScenarioRequest = (
  companyId: Company['id'],
  budgetingScenarioId: BudgetingScenario['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/budgeting-scenarios/${budgetingScenarioId}`,
    success: (response: BudgetingScenario) => deleteBudgetingScenario(response)
  },
  meta: {
    type: 'DELETE_BUDGETING_SCENARIO',
    notification: true
  }
})
