import { createSelector } from 'reselect'
import _ from 'lodash'
import { Store } from '../../types'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

const sessionAccountMapRulesSelector = (store: Store): AccountMapRule[] => store.session.accountMapRules || []

export const sessionAccountMapRulesLoadingSelector = (store: Store): boolean => store.loading.sessionAccountMapRules

export const sessionAccountMapRulesByStatementRowIdSelector = createSelector(
  [sessionAccountMapRulesSelector],
  sessionAccountMapRules => {
    return _.groupBy(sessionAccountMapRules, 'statementRowId')
  }
)
