import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Divider, Popconfirm, Button, FormInstance } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import AmendmentForm from './AmendmentForm'
import { handleCancel, handleOk } from './service'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import { dispatchFinancialStatementRequests } from '../../service'
import { AppDispatch } from '../../../../../redux/store'

interface AmendmentProps {
  reportTableRow: ReportTableRow
}

const Amendment: React.FC<AmendmentProps> = (props: AmendmentProps) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [modalVisible, setModalVisible] = useState(false)
  const amendmentRequest = useBackend('/api/companies/{companyId}/amendments/{amendmentId}')
  const dispatch: AppDispatch = useDispatch()

  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const { reportTableRow } = props

  const handleDelete = () => {
    if (reportTableRow?.amendment?.id) {
      amendmentRequest
        .delete({
          urlParams: { companyId, amendmentId: reportTableRow?.amendment?.id }
        })
        .then(() => {
          dispatchFinancialStatementRequests()
          dispatch(
            notificationAction({
              type: 'success',
              message: 'DELETE_AMENDMENT_SUCCESS'
            })
          )
        })
        .catch(() => {
          dispatch(
            notificationAction({
              type: 'error',
              message: 'DELETE_AMENDMENT_ERROR'
            })
          )
        })
    }
  }

  return (
    <>
      {modalVisible && (
        <AmendmentForm
          balanceSheetRow={reportTableRow}
          loading={amendmentRequest.loading}
          handleOk={(form: FormInstance) => companyId && handleOk(amendmentRequest, companyId, setModalVisible, form)}
          handleCancel={(form: FormInstance) => handleCancel(form, setModalVisible)}
          modalVisible={modalVisible}
        />
      )}
      <span>{reportTableRow.name}</span>
      <span style={{ float: 'right' }}>
        <Button
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          icon={<EditOutlined />}
          type="link"
          size="small"
          onClick={() => setModalVisible(true)}
        />
        <Divider type="vertical" />
        <Popconfirm
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          title={t('global:delete-confirm')}
          onConfirm={handleDelete}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button
            disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
            icon={<DeleteOutlined />}
            type="link"
            size="small"
          />
        </Popconfirm>
      </span>
    </>
  )
}

export default React.memo(Amendment)
