import _ from 'lodash'
import { ReportTableRow } from '../../../components/Table/types'
import {
  EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA,
  IncomeStatementDimensionsDataActionTypes,
  SET_ALL_DIMENSIONS_ROW_DATA,
  SET_SELECTED_ROW_DIMENSION_KEY
} from './types'
import {
  ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT,
  ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT,
  DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT,
  DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT,
  UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT,
  UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT
} from '../budgetComment/types'

export interface IncomeStatementDimensionsDataType {
  keys: (string | number)[]
  data: {
    [key: string]: {
      [key: string]: Partial<ReportTableRow>
    }
  }
}

const initialState: IncomeStatementDimensionsDataType = {
  keys: [],
  data: {}
}

const parseData = (data: any) => {
  const parsedData = {}
  for (const p of data) {
    Object.assign(parsedData, { [p.dimensionId]: p })
  }
  return parsedData
}

const incomeStatementDimensionsDataReducer = (
  state = initialState,
  action: IncomeStatementDimensionsDataActionTypes
) => {
  switch (action.type) {
    case SET_SELECTED_ROW_DIMENSION_KEY:
      return {
        ...state,
        keys: _.xor(state.keys, [action.payload.accountCode || action.payload.statementRowId])
      }
    case SET_ALL_DIMENSIONS_ROW_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.key}`]: {
            ...state.data[`${action.payload?.key}`],
            ...parseData(action.payload.data)
          }
        }
      }
    case ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.accountCode}`]: {
            ...state.data[`${action.payload?.accountCode}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.accountCode}`]?.[`${action.payload?.dimensionId}`],
              budgetComments: [
                ...(state.data?.[`${action.payload?.accountCode}`]?.[`${action.payload.dimensionId}`]?.budgetComments ||
                  []),
                action.payload
              ]
            }
          }
        }
      }
    case ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.incomeStatementRowId}`]: {
            ...state.data[`${action.payload?.incomeStatementRowId}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.incomeStatementRowId}`][`${action.payload?.dimensionId}`],
              budgetComments: [
                ...(state.data?.[`${action.payload?.incomeStatementRowId}`]?.[`${action.payload.dimensionId}`]
                  ?.budgetComments || []),
                action.payload
              ]
            }
          }
        }
      }
    case DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.accountCode}`]: {
            ...state.data[`${action.payload?.accountCode}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.accountCode}`]?.[`${action.payload?.dimensionId}`],
              budgetComments: state.data?.[`${action.payload?.accountCode}`]?.[
                `${action.payload.dimensionId}`
              ]?.budgetComments?.filter(comment => comment.id !== action.payload.id)
            }
          }
        }
      }
    case DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.incomeStatementRowId}`]: {
            ...state.data[`${action.payload?.incomeStatementRowId}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.incomeStatementRowId}`][`${action.payload?.dimensionId}`],
              budgetComments: state.data?.[`${action.payload?.incomeStatementRowId}`]?.[
                `${action.payload.dimensionId}`
              ]?.budgetComments?.filter(comment => comment.id !== action.payload.id)
            }
          }
        }
      }
    case UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.accountCode}`]: {
            ...state.data[`${action.payload?.accountCode}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.accountCode}`][`${action.payload?.dimensionId}`],
              budgetComments: state.data?.[`${action.payload?.accountCode}`]?.[
                `${action.payload.dimensionId}`
              ]?.budgetComments?.map(comment => {
                if (comment.id === action.payload.id) {
                  return action.payload
                }
                return comment
              })
            }
          }
        }
      }
    case UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload?.incomeStatementRowId}`]: {
            ...state.data[`${action.payload?.incomeStatementRowId}`],
            [`${action.payload?.dimensionId}`]: {
              ...state.data[`${action.payload?.incomeStatementRowId}`][`${action.payload?.dimensionId}`],
              budgetComments: state.data?.[`${action.payload?.incomeStatementRowId}`]?.[
                `${action.payload.dimensionId}`
              ]?.budgetComments?.map(comment => {
                if (comment.id === action.payload.id) {
                  return action.payload
                }
                return comment
              })
            }
          }
        }
      }
    case EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA:
      return {
        data: {},
        keys: []
      }
    default:
      return state
  }
}

export default incomeStatementDimensionsDataReducer
