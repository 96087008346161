import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { getNewLoansSummaryRequest, resetNewLoans } from '../../../redux/context/loans/new/actions'
import { getNewLoans } from '../../../redux/context/loans/selectors'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import LoansPage from './LoansPage'
import { AppDispatch } from '../../../redux/store'

const NewLoans = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const newLoans = useSelector(getNewLoans)
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const newInvestments = useSelector(investmentsPageSelector)

  useEffect(() => {
    if (date !== null && companyId) {
      dispatch(
        getNewLoansSummaryRequest(
          companyId,
          dayjs(date).endOf('month').format('YYYY-MM-DD'),
          budgetingScenario && budgetingScenario.id
        )
      )
    }
    return () => {
      dispatch(resetNewLoans())
    }
  }, [companyId, budgetingScenario, date, newInvestments])

  return <LoansPage loansData={newLoans} />
}

export default NewLoans
