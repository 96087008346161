import { GET, GetRequestAction, REQUEST } from '../../middleware/types'
import {
  FinancialStatement,
  PurchaseInvoices,
  Report,
  RESET_REPORTS,
  SalesInvoices,
  SET_CASH_FLOW_STATEMENT,
  SET_FINANCIAL_STATEMENT,
  SET_INTERNAL_KEY_FIGURE_STATEMENT,
  SET_KEY_FIGURE_STATEMENT,
  SET_KEY_PERFORMANCE_INDICATOR_STATEMENT,
  SET_PURCHASE_INVOICES,
  SET_SALES_CUSTOMERS,
  SET_SALES_INVOICES,
  SET_SALES_PRODUCT_GROUPS,
  SET_SALES_PRODUCTS,
  SET_VALUATION,
  SetCashFlowStatementAction,
  SetFinancialStatementAction,
  SetInternalKeyFigureStatementAction,
  SetKeyFigureStatementAction,
  SetKeyPerformanceIndicatorStatementAction,
  SetPurchaseInvoicesAction,
  SetSalesCustomersAction,
  SetSalesInvoicesAction,
  SetSalesProductGroupsAction,
  SetSalesProductsAction,
  SetValuationAction,
  Valuation,
  ResetReportsAction,
  ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER,
  ReportRow,
  AddInternalKeyfigureVariableExplainerAction,
  AddKeyFigureExplainerAction,
  ADD_KEY_FIGURE_EXPLAINER,
  ADD_CASH_FLOW_EXPLAINER,
  AddCashFlowExplainerAction,
  SetSalesCustomerGroupsAction,
  SET_SALES_CUSTOMER_GROUPS,
  AddInternalKeyFigureStatementAction,
  ADD_INTERNAL_KEY_FIGURE_STATEMENT,
  ReportResponse
} from './types'
import { ContextRequestParams } from '../actions'
import { Company } from '../../../types/company/Company'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { CustomReportVariable } from '../customReports/typesVariable'
import { Language } from '../../../types/user/User'

export const setFinancialStatement = (payload: FinancialStatement): SetFinancialStatementAction => ({
  type: SET_FINANCIAL_STATEMENT,
  payload
})

export const resetReports = (): ResetReportsAction => ({
  type: RESET_REPORTS
})

export const setKeyFigureStatement = (payload: Report): SetKeyFigureStatementAction => ({
  type: SET_KEY_FIGURE_STATEMENT,
  payload
})

export const setSalesProducts = (payload: Report): SetSalesProductsAction => ({
  type: SET_SALES_PRODUCTS,
  payload
})

export const setSalesProductGroups = (payload: Report): SetSalesProductGroupsAction => ({
  type: SET_SALES_PRODUCT_GROUPS,
  payload
})

export const setSalesCustomerGroups = (payload: Report): SetSalesCustomerGroupsAction => ({
  type: SET_SALES_CUSTOMER_GROUPS,
  payload
})

export const setSalesCustomers = (payload: Report): SetSalesCustomersAction => ({
  type: SET_SALES_CUSTOMERS,
  payload
})

export const setInternalKeyFigureStatement = (payload: Report): SetInternalKeyFigureStatementAction => ({
  type: SET_INTERNAL_KEY_FIGURE_STATEMENT,
  payload
})

export const addInternalKeyFigureStatement = (payload: Report): AddInternalKeyFigureStatementAction => ({
  type: ADD_INTERNAL_KEY_FIGURE_STATEMENT,
  payload
})

export const setKeyPerformanceIndicatorStatement = (payload: Report): SetKeyPerformanceIndicatorStatementAction => ({
  type: SET_KEY_PERFORMANCE_INDICATOR_STATEMENT,
  payload
})

export const setCashFlowStatement = (payload: Report): SetCashFlowStatementAction => ({
  type: SET_CASH_FLOW_STATEMENT,
  payload
})

export const setValuation = (payload: Valuation): SetValuationAction => ({
  type: SET_VALUATION,
  payload
})

export const setSalesInvoices = (payload: SalesInvoices): SetSalesInvoicesAction => ({
  type: SET_SALES_INVOICES,
  payload
})

export const setPurchaseInvoices = (payload: PurchaseInvoices): SetPurchaseInvoicesAction => ({
  type: SET_PURCHASE_INVOICES,
  payload
})

export const addInternalKeyfigureVariableExplainer = (
  explainer: ReportRow[],
  variable: ReportRow
): AddInternalKeyfigureVariableExplainerAction => ({
  type: ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER,
  payload: { explainer, variable }
})

export const addKeyFigureExplainer = (
  explainer: CustomReportVariable[],
  keyFigure: KeyFigure
): AddKeyFigureExplainerAction => ({
  type: ADD_KEY_FIGURE_EXPLAINER,
  payload: { explainer, keyFigure }
})

export const addCashFlowExplainer = (explainer: ReportRow[], keyFigure: KeyFigure): AddCashFlowExplainerAction => ({
  type: ADD_CASH_FLOW_EXPLAINER,
  payload: { explainer, keyFigure }
})

export const getFinancialStatementRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/financial-statement`,
    params,
    success: (payload: ReportResponse<FinancialStatement>) => setFinancialStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'FINANCIAL_STATEMENT'
  }
})

export const getKeyFigureStatementRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/key-figure-statement`,
    params,
    success: (payload: ReportResponse<Report>) => setKeyFigureStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'KEY_FIGURE_STATEMENT'
  }
})

export const getKeyFigureExplainerRequest = (
  companyId: Company['id'],
  keyFigure: KeyFigure,
  lng: Language,
  dimensionQuery?: string,
  periodGroups?: PeriodGroup[],
  budgetingScenario?: BudgetingScenario['id']
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: {
      lng,
      periodGroups,
      keyFigureId: keyFigure.id,
      dimensions: dimensionQuery,
      budgetingScenarioId: budgetingScenario
    },
    url: `/api/companies/${companyId}/reporting/key-figure-statement/explain`,
    success: (explainer: ReportResponse<CustomReportVariable[]>) =>
      addKeyFigureExplainer(explainer?.[0]?.data, keyFigure)
  },
  meta: {
    type: `KEY_FIGURE_EXPLAINER_${keyFigure.id}`
  }
})

export const getKeyPerformanceIndicatorStatmentRequest = ({
  companyId,
  ...params
}: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/key-performance-indicators`,
    params,
    success: (payload: ReportResponse<Report>) => setKeyPerformanceIndicatorStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'KEY_PERFORMANCE_INDICATOR_STATEMENT'
  }
})

export const getCashFlowStatementRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/cash-flow-statement`,
    params,
    success: (payload: ReportResponse<Report>) => setCashFlowStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'CASH_FLOW_STATEMENT'
  }
})

export const getCashFlowExplainerRequest = (
  { companyId, ...params }: ContextRequestParams,
  cashFlowRow: KeyFigure
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: {
      cashFlowStatementRowId: cashFlowRow.id,
      ...params
    },
    url: `/api/companies/${companyId}/reporting/cash-flow-statement/explain`,
    success: (explainer: ReportResponse<Report>) => addCashFlowExplainer(explainer?.[0]?.data, cashFlowRow)
  },
  meta: {
    type: `CASH_FLOW_EXPLAINER_${cashFlowRow.id}`
  }
})

export const getValuationRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params,
    url: `/api/companies/${companyId}/reporting/valuation`,
    success: (payload: Valuation) => setValuation(payload)
  },
  meta: {
    type: 'VALUATION'
  }
})

export const getSalesInvoicesRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params,
    url: `/api/companies/${companyId}/reporting/invoices/sales`,
    success: (payload: SalesInvoices) => setSalesInvoices(payload)
  },
  meta: {
    type: 'SALES_INVOICES'
  }
})

export const getPurchaseInvoicesRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params,
    url: `/api/companies/${companyId}/reporting/invoices/purchases`,
    success: (payload: PurchaseInvoices) => setPurchaseInvoices(payload)
  },
  meta: {
    type: 'PURCHASE_INVOICES'
  }
})

export const getInternalKeyFigureStatementRequest = (
  { companyId, ...params }: ContextRequestParams,
  formulaIds?: number[]
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/custom`,
    params: { ...params, formulaIds },
    success: (payload: ReportResponse<Report>) => setInternalKeyFigureStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'INTERNAL_KEY_FIGURE_STATEMENT'
  }
})

export const updateInternalKeyFigureStatementRequest = (
  { companyId, ...params }: ContextRequestParams,
  formulaIds?: number[]
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/custom`,
    params: { ...params, formulaIds },
    success: (payload: ReportResponse<Report>) => addInternalKeyFigureStatement(payload?.[0]?.data)
  },
  meta: {
    type: 'INTERNAL_KEY_FIGURE_STATEMENT'
  }
})

export const getInternalKeyFigureVariableExplainerRequest = (
  companyId: Company['id'],
  variable: ReportRow,
  lng: Language,
  dimensions?: string,
  periodGroups?: PeriodGroup[]
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: {
      formulaId: variable.id,
      lng,
      dimensions,
      periodGroups
    },
    url: `/api/companies/${companyId}/reporting/key-figure-statement/internal/explain`,
    success: (explainer: ReportResponse<Report>) =>
      addInternalKeyfigureVariableExplainer(explainer?.[0]?.data, variable)
  },
  meta: {
    type: `INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER_${variable.id}`
  }
})

export const getCustomStatementRequest = ({ companyId, ...params }: any): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/custom`,
    params
  },
  meta: {
    type: 'CUSTOM_STATEMENT'
  }
})

// Sales
export const getSalesProductsStatementRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: GET,
      url: `/api/companies/${companyId}/reporting/sales/products`,
      params,
      success: setSalesProducts
    },
    meta: {
      type: 'SALES_PRODUCTS'
    }
  }
}

export const getSalesProductGroupsStatementRequest = ({
  companyId,
  ...params
}: ContextRequestParams): GetRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: GET,
      url: `/api/companies/${companyId}/reporting/sales/productGroups`,
      params,
      success: setSalesProductGroups
    },
    meta: {
      type: 'SALES_PRODUCT_GROUPS'
    }
  }
}

export const getSalesCustomerGroupsStatementRequest = ({
  companyId,
  ...params
}: ContextRequestParams): GetRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: GET,
      url: `/api/companies/${companyId}/reporting/sales/customerGroups`,
      params,
      success: setSalesCustomerGroups
    },
    meta: {
      type: 'SALES_CUSTOMER_GROUPS'
    }
  }
}

export const getSalesCustomersStatementRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: GET,
      url: `/api/companies/${companyId}/reporting/sales/customers`,
      params,
      success: setSalesCustomers
    },
    meta: {
      type: 'SALES_CUSTOMERS'
    }
  }
}
