import { Divider, Form, Table, TreeSelect } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BudgetingMethod } from '../../pages/budgeting/financialStatements/components/budget/types'
import FormulaAddFunction from '../../pages/settings/company/keyFigures/formulas/components/FormulaAddFunction'
import FormulaSource from '../../pages/settings/company/keyFigures/formulas/components/FormulaSource'
import { contextRequest } from '../../redux/context/actions'
import { getBudgetDriversRequest } from '../../redux/context/budgetDrivers/actions'
import { budgetDriversSelector } from '../../redux/context/budgetDrivers/selectors'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { dimensionTreeWithRootSelector } from '../../redux/context/dimensions/selectors'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { getFormulasRequest } from '../../redux/context/formulas/actions'
import { useAuthorizedData } from '../../utils/Authorizable/authorize'
import { useTextAreaCursor } from '../../utils/hooks'
import { ReportTableRow } from '../Table/types'
import { functions as functionsConfig } from '../../pages/settings/company/keyFigures/formulas/config'
import { useColumnSearchProps } from '../Table/useColumSearchProps'
import { AppDispatch } from '../../redux/store'

interface BudgetingMethodBudgetDriverProps {
  row?: ReportTableRow
  form: FormInstance
  requestError?: any
}

export const isBudgetDriverMethod = (reportTableRow: ReportTableRow) => {
  if (
    reportTableRow.budgetingMethod?.method === BudgetingMethod.driver ||
    reportTableRow.method === BudgetingMethod.driver
  ) {
    return true
  }
  return false
}

export const BudgetingMethodBudgetDriver: React.FC<BudgetingMethodBudgetDriverProps> = ({
  row,
  form,
  requestError
}) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const budgetDrivers = useSelector(budgetDriversSelector)
  const dispatch: AppDispatch = useDispatch()

  const functions = useAuthorizedData(functionsConfig)
  const [tableSelectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const dimensionTree = useSelector(dimensionTreeWithRootSelector)
  const { dimensions } = useSelector(filtersSelector)
  const [textAreaRef, setTextAreaRef] = useState<TextAreaRef | null>(null)
  const cursor = useTextAreaCursor(textAreaRef)

  useEffect(() => {
    companyId && dispatch(getBudgetDriversRequest(companyId))
    companyId && dispatch(contextRequest(getFormulasRequest))
    const initialDimensions = row?.dimensionId || dimensions?.[0]
    const initialData = initialDimensions || 'root'

    form.setFieldsValue({
      dimensionIds: [initialData]
    })
    row && setSelectedRowKeys(row?.budgetingMethod?.drivers?.map(d => d.id) || [])
  }, [companyId])

  const columns = [
    {
      title: t('global:name'),
      key: 'name',
      dataIndex: 'name',
      ...useColumnSearchProps('name')
    }
  ]

  const rowSelection = {
    selectedRowKeys: tableSelectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys)
      form.setFieldsValue({ drivers: selectedRows })
    }
  }

  const getSourceFunctions = () => {
    const filteredFunctions = {}
    for (const func of Object.keys(functions)) {
      if (func === 'ref' || func === 'variable' || func === 'accumulate') {
        Object.assign(filteredFunctions, { [func]: functions[func] })
      }
    }
    return filteredFunctions
  }

  return (
    <>
      <Form.Item name="drivers" preserve label={t('financialStatementsPage:budget-driver-choose')}>
        <Table
          rowKey="id"
          columns={columns}
          rowSelection={{ type: 'checkbox', ...rowSelection }}
          dataSource={budgetDrivers}
          size="small"
          scroll={{ y: 300 }}
          pagination={false}
        />
      </Form.Item>
      <Divider />
      <FormulaAddFunction
        form={form}
        cursor={cursor}
        translation={{
          formItem: 'financialStatementsPage:budget-driver-formula',
          select: 'financialStatementsPage:add-budget-driver'
        }}
        functions={
          budgetDrivers?.map(fn => ({
            name: fn.name,
            source: fn.formula.source
          })) || []
        }
        requestError={requestError}
      />
      <FormulaSource
        cursor={cursor}
        form={form}
        functions={getSourceFunctions()}
        textAreaRef={textAreaRef}
        setTextAreaRef={setTextAreaRef}
      />

      <Divider />
      <Form.Item name="dimensionIds" preserve label={t('financialStatementsPage:budget-driver-choose-dimensions')}>
        <TreeSelect
          allowClear
          multiple
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          treeDefaultExpandedKeys={[dimensions?.[0] || 'root']}
          showSearch
          maxTagCount="responsive"
          fieldNames={{
            label: 'name',
            value: 'dimensionId',
            children: 'children'
          }}
          treeNodeFilterProp="name"
          treeData={[dimensionTree]}
        />
      </Form.Item>
    </>
  )
}
