import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, InputNumber, message, Popconfirm, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DefaultAccountMapRule } from '../../../../types/accountMapRule/AccountMapRule'
import { StatementRow } from '../../../../types/statementRow/StatementRow'
import { AppDispatch } from '../../../../redux/store'

interface AccountRulesTableProps {
  statementRow: StatementRow
  deleteAccountMapRule: Function
  createAccountMapRule: Function
  updateAccountMapRule: Function
  accountMapRulesByStatementRowIdSelector: _.Dictionary<DefaultAccountMapRule[]>
}

const DefaultAccountRulesTable: React.FC<AccountRulesTableProps> = ({
  statementRow: { id: statementRowId },
  deleteAccountMapRule,
  createAccountMapRule,
  updateAccountMapRule,
  accountMapRulesByStatementRowIdSelector
}) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()

  const rulesByStatementRowId = accountMapRulesByStatementRowIdSelector
  const [accountRules, setAccountRules] = useState<DefaultAccountMapRule[]>([])
  const isEditing = accountRules ? accountRules.some(r => r.isEditing) : false

  useEffect(() => {
    setAccountRules(rulesByStatementRowId[statementRowId])
  }, [rulesByStatementRowId, statementRowId])

  const newRule = () => {
    const data = accountRules || []
    const newData = data.map(item => ({ ...item }))
    newData.push({
      statementRowId,
      id: `NEW_RULE`,
      from: 0,
      to: 0,
      isEditing: true
    })
    setAccountRules(newData)
  }

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault()

    setAccountRules(rulesByStatementRowId[statementRowId])
  }

  const getRowById = (id: string, newData?: DefaultAccountMapRule[]) => {
    return (newData || accountRules).filter(item => item.id === id)[0]
  }

  const toggleEditable = (e: React.MouseEvent | React.KeyboardEvent, key: string) => {
    e.preventDefault()
    const data = accountRules || []
    const newData = data.map(item => ({ ...item }))
    const target = getRowById(key, newData)
    if (target) {
      target.isEditing = !target.isEditing
      setAccountRules(newData)
    }
  }

  const handleFieldChange = (value: number | null | undefined, fieldName: 'from' | 'to', id: string) => {
    const newRules = [...accountRules]
    const target = getRowById(id, newRules)
    if (target && value) {
      target[fieldName] = value

      setAccountRules(newRules)
    }
  }

  const ruleIsValid = (rule: DefaultAccountMapRule) => {
    if (!rule.from || !rule.to || !rule.statementRowId) return false
    if (rule.to < 1000 || rule.from < 1000) return false

    return true
  }

  const handleSave = (e: React.MouseEvent | React.KeyboardEvent, id: string) => {
    const rule = getRowById(id) || {}
    if (!ruleIsValid(rule)) {
      message.error(t('global:check-fields'))
      const input = e.target as HTMLInputElement
      input.focus()
      return
    }
    delete rule.isEditing
    console.log(e, id)
    toggleEditable(e, id)
    if (id === 'NEW_RULE') {
      dispatch(createAccountMapRule(rule))
    } else {
      dispatch(updateAccountMapRule(rule))
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent, id: string) => {
    if (e.key === 'Enter') {
      handleSave(e, id)
    }
  }

  const columns = [
    {
      title: 'from',
      width: 65,
      dataIndex: 'from',
      key: 'from',
      align: 'center' as const,
      render: (text: string, rule: DefaultAccountMapRule) => {
        if (rule.isEditing) {
          return (
            <InputNumber
              size="small"
              className="rule-field rule-field-from"
              value={rule.from}
              autoFocus
              formatter={value => (value === 0 ? '' : `${value}`)}
              onChange={value => rule.id && handleFieldChange(value, 'from', rule.id)}
              onPressEnter={e => rule.id && handleKeyPress(e, rule.id)}
              placeholder={t('accountMapPage:from')}
            />
          )
        }
        return <span className="rule-field rule-field-from text">{text}</span>
      }
    },
    {
      title: 'dash',
      align: 'center' as const,
      width: 10,
      className: 'account-map-dash',
      render: () => '-'
    },
    {
      title: 'to',
      dataIndex: 'to',
      key: 'to',
      align: 'center' as const,
      width: 65,
      render: (text: string, rule: DefaultAccountMapRule) => {
        if (rule.isEditing) {
          return (
            <InputNumber
              size="small"
              className="rule-field rule-field-to"
              value={rule.to}
              formatter={value => (value === 0 ? '' : `${value}`)}
              onChange={e => rule.id && handleFieldChange(e, 'to', rule.id)}
              onPressEnter={e => rule.id && handleKeyPress(e, rule.id)}
              placeholder={t('accountMapPage:to')}
            />
          )
        }
        return <span className="rule-field rule-field-to text">{text}</span>
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      align: 'right' as const,
      render: (text: string, rule: DefaultAccountMapRule) => {
        if (rule.isEditing) {
          return (
            <span>
              <Tooltip placement="topRight" title={t('global:save')}>
                <CheckOutlined onClick={e => rule.id && handleSave(e, rule.id)} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip placement="topRight" title={t('global:cancel')}>
                <CloseOutlined onClick={e => handleCancel(e)} />
              </Tooltip>
            </span>
          )
        }
        if (isEditing) {
          return null
        }
        return (
          <span>
            <Tooltip placement="topRight" title={t('global:edit')}>
              <EditOutlined onClick={e => rule.id && toggleEditable(e, rule.id)} />
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              placement="bottomRight"
              title={t('global:delete-confirm')}
              onConfirm={() => dispatch(deleteAccountMapRule(rule.id))}
              okText={t('global:yes')}
              cancelText={t('global:no')}
            >
              <Tooltip placement="topRight" title={t('global:delete')}>
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </span>
        )
      }
    }
  ]

  return (
    <div>
      {accountRules && (
        <div>
          <Table
            size="small"
            rowKey={rule => rule?.id?.toString() || ''}
            columns={columns}
            showHeader={false}
            dataSource={accountRules}
            pagination={false}
          />
        </div>
      )}
      <div style={{ padding: '8px 0px' }}>
        <Button disabled={isEditing} type="dashed" style={{ width: '100%' }} icon={<PlusOutlined />} onClick={newRule}>
          {t('accountMapPage:add-new-rule')}
        </Button>
      </div>
    </div>
  )
}

export default DefaultAccountRulesTable
