import { SeriesPieOptions } from 'highcharts'
import i18next from 'i18next'
import _ from 'lodash'
import { ReactText } from 'react'
import { VariableType } from '../../../../../components/Table/types'
import { getCustomReportChartTitle } from '../../../../../redux/context/customReports/utils'
import { formatValueByType, formatValueToNumberByType } from '../../../../../utils/helpers'
import { FormatterType, getCommonSizeValue, getRight } from '../utils'
import { ChartYAxis } from './types'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import {
  CustomReportChartSection,
  CustomReportPieSection
} from '../../../../../redux/context/customReports/typesSection'
import {
  CustomReportVariable,
  CustomReportVariableExplainer,
  CustomReportVariableRow
} from '../../../../../redux/context/customReports/typesVariable'
import { getTitleByLanguage } from '../customReportTable/utils'

export const getDataSerie = (variable: CustomReportVariable, categories: CustomReportCategory[]): (number | null)[] => {
  return categories.map(c => {
    const { balances } = variable
    const balance = balances?.find(b => b.groupId === c.id)
    const variableType = ['growth', 'commonSize'].includes(c.value as string) ? VariableType.percentage : variable.type

    return formatValueToNumberByType(balance?.value, variableType, variable?.style?.decimals)
  })
}

export const getLeafCategories = (categories: CustomReportCategory[]) => {
  const leafCategories: CustomReportCategory[] = []

  function recurse(data: CustomReportCategory[]) {
    if (!data) return
    data.forEach(category => {
      if (!category.children || category.type === 'function') leafCategories.push({ ...category })

      if (category.children && category.type !== 'function') {
        recurse(category.children)
      }
    })
  }

  recurse(categories)

  return leafCategories
}

type PathContext = {
  [key in CustomReportCategory['type']]?: ReactText | ReactText[]
} & {
  companyId?: ReactText | ReactText[]
  dimensionId?: ReactText | ReactText[]
}

const getPathContext = (path?: string, categories?: CustomReportCategory[]): PathContext => {
  if (!path) return {}
  const context: PathContext = {}
  path.split('-')?.reduce((cats: CustomReportCategory[], key) => {
    const cat = cats?.[+key]
    if (!cat) return cats
    if (cat?.type === 'company' || cat?.type === 'dimension') {
      context[`${cat.type}Id`] = cat.value
      context[`${cat.type}`] = cat.title
    } else {
      context[`${cat.type}`] = cat.value
    }

    return cat?.children || []
  }, categories ?? [])
  return context
}

export const getDataColumns = (section: CustomReportChartSection) => {
  const { categories, variables } = section
  const leafs = getLeafCategories(categories)

  const allCategoryCompanies = _.uniq(leafs.map(leaf => getPathContext(leaf.id, categories).companyId))

  const zeroWidhSpace = '​'
  const nameRow = [
    null,
    ...leafs.map(cat => {
      const ctx = getPathContext(cat.id, categories)

      if (allCategoryCompanies.length > 1) {
        return (
          `${getCustomReportChartTitle(cat, categories)}${zeroWidhSpace} </br> ${ctx?.company} </br> ${
            ctx?.dimension ?? ''
          }` ?? ''
        )
      }
      return `${getCustomReportChartTitle(cat, categories)}${zeroWidhSpace} </br> ${ctx?.dimension ?? ''}` ?? ''
    })
  ]

  const series = variables.map(v => {
    return [getTitleByLanguage(v as CustomReportVariableRow), ...getDataSerie(v, leafs)]
  })

  return [nameRow, ...series]
}

export function dataLabelformatter(this: FormatterType) {
  return this.point.y?.toLocaleString(i18next.language)
}

const seriesMap = (item: CustomReportVariable | CustomReportCategory) => {
  return {
    ...item.style,
    yAxis: item?.style?.yAxis || 0,
    dataLabels: { formatter: dataLabelformatter }
  } as Highcharts.SeriesOptionsType
}

export const getSeries = (sec: CustomReportChartSection): Highcharts.SeriesOptionsType[] => {
  const { categories, variables } = sec
  const leafs = getLeafCategories(categories)

  if (!!sec?.style?.switchRowsAndColumns === false) {
    return variables.map(seriesMap)
  }

  if (!!sec?.style?.switchRowsAndColumns === true) {
    return leafs.map(seriesMap)
  }
  return []
}

export const variablesToSeries = (
  variables: CustomReportVariable[],
  referenceValue: number,
  category: CustomReportCategory,
  forceDrillDown?: boolean,
  onlyPercentage?: boolean
): Highcharts.PointOptionsObject[] => {
  return variables.map(variable => {
    const { balances } = variable
    const { value } = balances?.find(b => b.groupId === category.id) || {}
    const { decimals = 0, color } = variable.style || {}
    const variableValue = value ? value * referenceValue : 0
    const formattedValue = formatValueByType(variableValue, variable.type, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    })

    return {
      name: getTitleByLanguage(variable as CustomReportVariableRow) || '',
      drilldown: forceDrillDown || 'children' in variable ? variable.id?.toString() : undefined,
      y: variableValue ? Math.abs(variableValue) : null,
      negative: variableValue ? variableValue < 0 : false,
      color: forceDrillDown ? color : undefined,
      ...(!onlyPercentage && {
        dataLabels: {
          format: `{point.name}: ${formattedValue}`
        }
      })
    }
  })
}

export const getPieSeries = ({
  title,
  categories,
  variables,
  commonSizeVariables
}: CustomReportPieSection): SeriesPieOptions => {
  const leafs = getLeafCategories(categories)
  const category = leafs[0]
  const right = getRight(categories)
  let onlyPercentage = false
  if (
    commonSizeVariables?.every((row: CustomReportVariable) => row.type === VariableType.percentage) &&
    variables?.every((row: CustomReportVariable) => row.type === VariableType.percentage)
  ) {
    onlyPercentage = true
  }
  const referenceValue = getCommonSizeValue(right?.id, commonSizeVariables)
  const data = variablesToSeries(variables, referenceValue, category, true, onlyPercentage)
  let othersValue = referenceValue - data.reduce((acc, cur) => acc + (cur?.y || 0), 0)
  if (!onlyPercentage) {
    othersValue = formatValueToNumberByType(othersValue) || 0
  }
  const others = {
    name: i18next.t('dashboardPage:others') ?? 'Others',
    y: Math.abs(othersValue),
    negative: othersValue ? othersValue < 0 : false
  }
  const pieSerie: SeriesPieOptions = {
    name: title,
    type: 'pie',
    data: commonSizeVariables && commonSizeVariables?.length > 0 ? [others, ...data] : [...data]
  }
  return pieSerie
}

export type DrillData = { [key: string]: Highcharts.PointOptionsObject[] }

export const getDrillData = (
  variableId: string,
  explainer: CustomReportVariableExplainer,
  categories: CustomReportCategory[]
): DrillData => {
  const leafs = getLeafCategories(categories)
  const category = leafs[0]
  const explanations: DrillData = {}
  const right = getRight(categories)
  const referenceValue = getCommonSizeValue(right?.id, explainer.commonSizeVariables)

  const traverse = (varId: string, variables: CustomReportVariable[], parentValue: number) => {
    if (!variables) return

    // Jos rivien määrä 1kpl silloin skipataan sarjan teko ja passataan edellinen id eteenpäin
    if (variables?.length === 1 && 'children' in variables[0]) {
      variables.forEach((row: CustomReportVariable) => {
        row.children && row.id && traverse(varId, row.children, referenceValue)
      })
    } else {
      const series = variablesToSeries(variables, parentValue, category)
      explanations[varId.toString()] = series
      variables.forEach((row: CustomReportVariable) => {
        row.children && row.id && traverse(row.id.toString(), row.children, referenceValue)
      })
    }
  }

  traverse(variableId, explainer.variables, referenceValue)

  return explanations
}

export const getMaxSectionSeriesValue = (section: CustomReportChartSection, yAxis = ChartYAxis.left) => {
  const dataColumns = section.variables
    .reduce((acc, curr, i) => {
      if ((curr.style?.yAxis || ChartYAxis.left) === yAxis) acc.push(i + 1)
      return acc
    }, [] as number[])
    .flatMap(i => getDataColumns(section)[i])
    .filter(Number)

  const maxVal = Math.max(...(dataColumns.flatMap(row => row) as number[]))
  return Math.abs(maxVal * 1.3)
}
