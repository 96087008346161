import { GlobalOutlined, LogoutOutlined, MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Menu, Popover } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SubMenuType } from 'antd/es/menu/interface'
import { logout } from '../../redux/session/authentication/actions'
import { currentUserSelector } from '../../redux/session/currentUser/selectors'
import { Route } from '../../routes/Route'
import './_HeaderContent.sass'
import HelpMenu from './HelpMenu'
import { AppDispatch } from '../../redux/store'

export type SiderTheme = 'light' | 'dark'

interface HeaderContentProps {
  routes: Route[]
}

const HeaderRightMenu: React.FC<HeaderContentProps> = ({ routes = [] }: HeaderContentProps) => {
  const { t, i18n } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { displayName } = useSelector(currentUserSelector)
  const history = useHistory()
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const settingRoutes = routes
    .find((route: Route) => route.path === '/settings')
    ?.routes?.filter((route: Route) => route.path !== '/settings/user')

  useEffect(() => {
    const handleResize = _.throttle(() => {
      setMenuCollapsed(window.innerWidth < 558)
    }, 500)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getMenuItems = () => {
    return [
      {
        key: 'settings-menu',
        className: 'settings-menu',
        icon: <SettingOutlined />,
        label: menuCollapsed && t('header:settings'),
        children: settingRoutes
          ?.filter(s => !s.hideInMenu)
          ?.map((route: Route) => {
            return {
              key: route.path,
              label: t(`header:${route.path}`),
              onClick: () => history.push(route.path)
            }
          })
      }
    ]
  }

  const handleLocaleClick = ({ key }: { key: string }) => {
    i18n.changeLanguage(key)
  }

  const avatarMenu = () => {
    return [
      {
        key: 'avatar-menu',
        className: 'avatar-menu',
        icon: menuCollapsed ? <UserOutlined /> : <Avatar className="avatar" icon={<UserOutlined />} size="default" />,
        label: menuCollapsed && displayName,
        children: [
          ...(!menuCollapsed
            ? [
                {
                  key: 'displayName',
                  label: displayName,
                  icon: <UserOutlined />
                }
              ]
            : []),
          {
            type: 'divider'
          },
          {
            key: 'profile',
            label: t('header:profile'),
            icon: <UserOutlined />,
            onClick: () => history.push('/settings/user/profile')
          },
          {
            key: 'locale',
            label: t('header:locale'),
            icon: <GlobalOutlined />,
            children: [
              {
                key: 'fi',
                label: t('header:finnish'),
                icon: '🇫🇮  ',
                onClick: handleLocaleClick
              },
              {
                key: 'en',
                label: t('header:english'),
                icon: '🇬🇧  ',
                onClick: handleLocaleClick
              },
              {
                key: 'sv',
                label: t('header:swedish'),
                icon: '🇸🇪  ',
                onClick: handleLocaleClick
              }
            ]
          },
          {
            key: 'logout',
            label: t('header:logout'),
            icon: <LogoutOutlined />,
            onClick: () => dispatch(logout())
          }
        ]
      }
    ] as SubMenuType[]
  }

  const RightMenu = () => (
    <Menu
      className="header-menu"
      selectedKeys={[]}
      mode={menuCollapsed ? 'inline' : 'horizontal'}
      items={[...getMenuItems(), ...avatarMenu()]}
    />
  )

  return (
    <>
      <HelpMenu />
      {menuCollapsed ? (
        <Popover className="burger-menu-icon" content={RightMenu()} placement="bottomRight">
          <Button type="text" icon={<MenuOutlined />} />
        </Popover>
      ) : (
        RightMenu()
      )}
    </>
  )
}

export default HeaderRightMenu
