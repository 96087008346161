import React from 'react'

interface ConditionalRenderProps {
  render: boolean
}

const ConditionalRender: React.FC<ConditionalRenderProps> = ({ children, render }) => {
  if (!render) return null
  return <>{children}</>
}

export default ConditionalRender
