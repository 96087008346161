import { Switch, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplayEmptyRows } from '../../../redux/context/filters/actions'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../redux/store'

const EmptyRowFilter = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const filters = useSelector(filtersSelector)

  const onChangeRows = () => {
    dispatch(toggleDisplayEmptyRows())
  }

  return (
    <div style={{ width: '100%' }}>
      <div key="hide-empty-rows" style={{ marginTop: 8, width: '100%' }}>
        <Switch checked={!filters.displayEmptyRows} onChange={onChangeRows} />
        <Typography.Text style={{ marginLeft: 8 }}>{t('contextDrawer:hide-empty-rows')}</Typography.Text>
      </div>
    </div>
  )
}

export default EmptyRowFilter
