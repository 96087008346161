import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Space, Table } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { VList } from 'virtuallist-antd'

const VoucherCustomerFilterDropDown = ({
  filters,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters
}: FilterDropdownProps) => {
  const { t } = useTranslation()
  const [filterData, setFilterData] = useState(filters)
  const [searchWord, setSearchWord] = useState('')
  const cc = [{ title: t('global:name'), dataIndex: 'text' }]

  const vComponents = useMemo(() => {
    return VList({
      height: 1000 //  (required).  same value for scrolly
    })
  }, [])

  useEffect(() => {
    searchWord
      ? setFilterData(filters?.filter((f: any) => f.text?.toLowerCase().includes(searchWord?.toLowerCase())))
      : setFilterData(filters)
  }, [searchWord])

  return (
    <div style={{ padding: 8, width: 300, maxHeight: 443 }}>
      <Space direction="vertical">
        <Input
          placeholder={t('global:search')}
          addonAfter={<SearchOutlined />}
          onChange={e => {
            setSearchWord(e.currentTarget.value)
          }}
          value={searchWord}
          style={{ width: '100%' }}
        />

        <Table
          scroll={{ y: 300 }}
          components={vComponents}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedKeys,
            onChange: (selectedRowKeys: React.Key[]) => {
              setSelectedKeys(selectedRowKeys)
            },
            getCheckboxProps: (record: any) => ({
              name: record.name
            })
          }}
          dataSource={filterData}
          pagination={false}
          columns={cc}
          rowKey="value"
          size="small"
        />
        <Row justify="space-between">
          <Col>
            <Button
              type="link"
              onClick={() => {
                setSearchWord('')
                clearFilters && clearFilters()
              }}
            >
              {t('global:reset')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => confirm()}>
              OK
            </Button>
          </Col>
        </Row>
      </Space>
    </div>
  )
}

export default VoucherCustomerFilterDropDown
