import React from 'react'
import { Form, Space, Button, Typography, Row } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { VatCategory, VatRecord } from '../../../../../../types/companySettings/CompanySettings'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'

const { Title } = Typography

const VatInputFields = () => {
  const vatCategories: VatCategory[] = ['sales', 'purchases']
  const { t } = useTranslation()

  const getFieldsAvg = (values: VatRecord[]): string => {
    const avg = values
      ?.reduce((sum, curr) => {
        if (curr?.vat && curr?.value) {
          const val = curr.vat * curr.value
          return sum + val
        }
        return sum
      }, 0)
      .toFixed(1)
    return avg
  }

  const getPercentageSum = (vatCategory: VatRecord[] = []) => {
    const sum = vatCategory.reduce((percentageSum, record) => {
      return percentageSum + (record?.value || 0)
    }, 0)
    return sum
  }

  return (
    <Space direction="vertical" size="middle" style={{ maxWidth: 800 }}>
      <Typography.Text>{t('budgetingInputPage:sales-text')}</Typography.Text>

      <Title level={4}>{t('budgetingInputPage:VAT-distribution')}</Title>
      {vatCategories.map(vatCategory => {
        return (
          <div key={vatCategory} style={{ maxWidth: 400, marginBottom: 32 }}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  <Row justify="space-between">
                    <Title level={5}>{t(`global:${vatCategory}`)}</Title>
                    <span>
                      {` ${t('budgetingInputPage:VAT-avg')} ${getFieldsAvg(getFieldValue(['vat', vatCategory]))}%`}
                    </span>
                  </Row>
                )
              }}
            </Form.Item>
            <Form.List
              name={['vat', vatCategory]}
              rules={[
                {
                  validator: async (_, vatCat) => {
                    const pSum = getPercentageSum(vatCat)
                    if (!vatCat || pSum !== 1) {
                      return Promise.reject(
                        new Error(t('budgetingInputPage:VAT-error', { sum: pSum * 100, requiredSum: 100 }))
                      )
                    }
                    return Promise.resolve()
                  }
                }
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'vat']}
                        rules={[{ required: true, message: t('budgetingInputPage:VAT-missing') }]}
                      >
                        <FormattedInputNumber
                          min={0}
                          max={100}
                          addonBefore={t('budgetingInputPage:VAT')}
                          step={1}
                          required={false}
                        />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'value']}>
                        <FormattedInputNumber
                          percentage
                          width={32}
                          min={0}
                          max={100}
                          addonAfter="%"
                          step={1}
                          required={false}
                          style={{ textAlign: 'right' }}
                        />
                      </Form.Item>
                      {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                    </Space>
                  ))}

                  <Form.ErrorList errors={errors} />

                  <Row>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('global:add-new')}
                    </Button>
                  </Row>
                </div>
              )}
            </Form.List>
          </div>
        )
      })}
    </Space>
  )
}

export default VatInputFields
