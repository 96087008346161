import React, { useState, useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SheetRow } from './ExchangeRateSheet'
import { reportNames } from './ExchangeRate'

interface ExchangeRateChartProps {
  periods: Dayjs[]
  data: SheetRow[]
}

const ExchangeRateChart: React.FC<ExchangeRateChartProps> = ({ data, periods }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation()
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'spline'
    },
    title: {
      text: null
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: t('exchangeRatesPage:exchangeRate')
      }
    },
    series: [{ data: [] }],
    tooltip: {
      valueDecimals: 3,
      shared: true
    }
  })

  const updateSeries = () => {
    const categories = periods.map(d => d.format('MM/YYYY'))
    const series = data.map(row => {
      const values = categories.map(cat => {
        const val = parseFloat(row[cat]?.replace(',', '.') || '') || null
        return val
      })
      return {
        name: t(`reportTypes:${reportNames[row.report as keyof typeof reportNames]}`),
        data: values
      } as Highcharts.PointOptionsType
    })

    setChartOptions({
      xAxis: {
        categories
      },
      yAxis: {
        title: {
          text: t('exchangeRatesPage:exchangeRate')
        }
      },
      series
    } as any)
  }

  useEffect(() => {
    updateSeries()
  }, [data, periods, language])

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}

export default ExchangeRateChart
