import dayjs from 'dayjs'
import { TimePeriodOptions } from '../../../../../redux/context/customReports/types'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { getPreviousFiscalYear } from '../../../../../redux/context/fiscalYears/utils'

export const getStartDate = (c: string, currentFiscalYear: FiscalYear | undefined, fiscalYears: FiscalYear[]) => {
  switch (c) {
    case TimePeriodOptions.Past12months:
      return dayjs().add(-12, 'month').format('YYYY-MM-DD')
    case TimePeriodOptions.FiscalyearToDate:
    case TimePeriodOptions.Currentfiscalyear:
      return dayjs(currentFiscalYear?.startDate).format('YYYY-MM-DD')
    case TimePeriodOptions.PreviousFiscalyear:
      return (
        currentFiscalYear &&
        dayjs(getPreviousFiscalYear(currentFiscalYear, fiscalYears)?.startDate).format('YYYY-MM-DD')
      )
    case TimePeriodOptions.PreviousFiscalyearToDate:
      return (
        currentFiscalYear &&
        dayjs(getPreviousFiscalYear(currentFiscalYear, fiscalYears)?.startDate).format('YYYY-MM-DD')
      )
    case TimePeriodOptions.PreviousYear:
    case TimePeriodOptions.Previousyeartodate:
      return dayjs().add(-12, 'month').startOf('year').format('YYYY-MM-DD')
    case TimePeriodOptions.YearToDate:
      return dayjs().startOf('year').format('YYYY-MM-DD')
    case TimePeriodOptions.Quarter:
      return dayjs().add(-3, 'month').format('YYYY-MM-DD')
    case TimePeriodOptions.CumulativeFiscalyear:
      return dayjs(currentFiscalYear?.startDate).format('YYYY-MM-DD')
    case TimePeriodOptions.FiscalyearFirstMonth:
      return dayjs(currentFiscalYear?.startDate).format('YYYY-MM-DD')
    case TimePeriodOptions.CurrentMonth:
    case TimePeriodOptions.CurrentYear:
    case TimePeriodOptions.CurrentDay:
    case TimePeriodOptions.CurrentWeek:
      return dayjs().format('YYYY-MM-DD')
    default:
      return dayjs().format('YYYY-MM-DD')
  }
}

export const getEndDate = (c: string, currentFiscalYear: FiscalYear | undefined, fiscalYears: FiscalYear[]) => {
  switch (c) {
    case TimePeriodOptions.Past12months:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.FiscalyearToDate:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.Currentfiscalyear:
      return dayjs(currentFiscalYear?.endDate).endOf('month').format('YYYY-MM-DD')
    case TimePeriodOptions.PreviousFiscalyear:
      return (
        currentFiscalYear &&
        dayjs(getPreviousFiscalYear(currentFiscalYear, fiscalYears)?.endDate).endOf('month').format('YYYY-MM-DD')
      )
    case TimePeriodOptions.PreviousFiscalyearToDate:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.PreviousYear:
      return dayjs().add(-12, 'month').endOf('year').format('YYYY-MM-DD')
    case TimePeriodOptions.Previousyeartodate:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.YearToDate:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.Quarter:
      return dayjs().format('YYYY-MM-DD')
    case TimePeriodOptions.CumulativeFiscalyear:
      return dayjs(currentFiscalYear?.startDate).endOf('month').format('YYYY-MM-DD')
    case TimePeriodOptions.FiscalyearFirstMonth:
      return dayjs(currentFiscalYear?.startDate).endOf('month').format('YYYY-MM-DD')
    case TimePeriodOptions.CurrentMonth:
    case TimePeriodOptions.CurrentYear:
    case TimePeriodOptions.CurrentDay:
    case TimePeriodOptions.CurrentWeek:
      return dayjs().format('YYYY-MM-DD')
    default:
      return dayjs().endOf('month').format('YYYY-MM-DD')
  }
}
