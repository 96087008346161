import { createSelector } from 'reselect'
import { contextCompanySelector } from '../../context/company/selectors'
import { Store } from '../../types'
import { incomeStatementSelector } from '../../context/reports/selectors'
import { BudgetSimulationFormItem, FinancialStatementConfig } from './types'
import { normalizedConfigSelector } from '../selectors'
import { statementRowSelector } from '../../entities/statementRows/selectors'

const financialStatementConfigSelector = (store: Store) => store.config.financialStatement

// TODO: refaktoa selectorit parametrisoituun versioon
export const financialStatementConfigSettingSelector = (key: keyof FinancialStatementConfig) =>
  createSelector(
    contextCompanySelector,
    financialStatementConfigSelector,
    (company, config) => company && config?.[key][company.country][company.form]
  )

// TODO: voiko parametrisoida
export const revenueRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.revenueRowId[company.country][company.form]
)

export const revenueRowSelector = createSelector(revenueRowIdSelector, incomeStatementSelector, (id, statement) => {
  return statement.find(row => row.id === id)
})

export const wagesRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.wagesRowId[company.country][company.form]
)

export const pensionExpensesRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.pensionExpensesRowId[company.country][company.form]
)

export const otherSocialSecurityExpensesRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.otherSocialSecurityExpensesRowId[company.country][company.form]
)

export const plannedDeprecationRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  statementRowSelector,
  (company, config, statementRows) => {
    const plannedDeprecationRowId = company && config?.plannedDeprecationRowId[company.country][company.form]
    const statementRowIds = statementRows.filter(s => s.parentId === plannedDeprecationRowId)?.map(s => s.id)
    return plannedDeprecationRowId && [plannedDeprecationRowId, ...statementRowIds]
  }
)

export const acceleratedDeprecationRowIdSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.acceleratedDeprecationRowId[company.country][company.form]
)

export const acceleratedDeprecationDeltaRowIdsSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.acceleratedDeprecationDeltaRowIds[company.country][company.form]
)

export const longTermLiabilityRowIdsSelector = createSelector(
  contextCompanySelector,
  financialStatementConfigSelector,
  (company, config) => company && config?.longTermLiabilityRowIds[company.country][company.form]
)

export const tulosSimulationsConfigSlector = createSelector(normalizedConfigSelector, ({ financialStatement }) => {
  const tulosSimulationsConfig = [{ name: 'taxes', configKey: 'taxRowId' }]
  const taseSimulationsConfig = [
    { name: 'accountsReceivable', configKey: 'shortTermAccountsReceivableRowId' },
    { name: 'accountsPayable', configKey: 'shortTermAccountsPayableRowId' },
    { name: 'vatReceivable', configKey: 'VATReceivableRowId' },
    { name: 'vatPayable', configKey: 'VATPayableRowId' },
    { name: 'holidayPayReserve', configKey: 'holidayPayReserveRowId' },
    { name: 'pensionReserve', configKey: 'pensionReserveRowId' },
    { name: 'cash', configKey: 'cashRowId' },
    {
      name: 'previousFiscalYearProfit',
      configKey: 'previousFiscalYearProfitRowId'
    },
    { name: 'fiscalYearProfit', configKey: 'fiscalYearProfitRowId' },
    { name: 'wagesWitholdingTax', configKey: 'wagesWitholdingTaxRowId' },
    {
      name: 'socialSecurityLiabilities',
      configKey: 'socialSecurityLiabilitiesRowId'
    }
  ]

  const isRowEditable = ({ configKey }: BudgetSimulationFormItem) => {
    const ids = [financialStatement[configKey]].flat().filter(Boolean)
    return ids?.length > 0
  }

  const taseSimulations = taseSimulationsConfig.filter(isRowEditable)
  const tulosSimulations = tulosSimulationsConfig.filter(isRowEditable)

  return { taseSimulations, tulosSimulations }
})
