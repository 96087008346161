/* eslint-disable prettier/prettier */
import { Cascader, Form, Tabs } from 'antd'
import _, { Dictionary } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextRequest } from '../../redux/context/actions'
import { updateCompanyRequest } from '../../redux/context/company/actions'
import {
  contextCompanyCountrySelector,
  contextCompanyFormSelector,
  contextCompanySelector
} from '../../redux/context/company/selectors'
import { companySettingsLoadingSelector } from '../../redux/context/companySettings/selectors'
import { groupedStatementRowsSelector, statementRowSelector } from '../../redux/entities/statementRows/selectors'
import { CompanyForm, Country } from '../../types/company/Company'
import { StatementRow } from '../../types/statementRow/StatementRow'
import LoadingWrapper from '../Misc/LoadingWrapper'
import AccountMapTable from './components/AccountMapTable'
import './_AccountMap.sass'
import { AppDispatch } from '../../redux/store'

interface AccountMapProps {
  loading: boolean
  handleAccountantCascaderAction?: Function
}

// Account map page
const AccountMap: React.FC<AccountMapProps> = ({
  loading,
  handleAccountantCascaderAction
}) => {
  const { t } = useTranslation()
  const [cascaderValues, setCascaderValues] = useState<[Country, CompanyForm]>([Country.fi, CompanyForm.limited])
  const statementRowsForAccountantSelection = useSelector(statementRowSelector)
  const statementRowsForCompanySelection = useSelector(groupedStatementRowsSelector)
  const groupedStatementRows: Dictionary<StatementRow[]> = handleAccountantCascaderAction
    ? _.groupBy(
      statementRowsForAccountantSelection.filter(
        (row: any) => row.country === cascaderValues[0] && row.companyForm === cascaderValues[1]
      ),
      'report'
    )
    : statementRowsForCompanySelection
  const { INCOME_STATEMENT: incomeStatement, ASSETS: assets, LIABILITIES: liabilities } = groupedStatementRows
  const updateCompanyLoading = useSelector(companySettingsLoadingSelector)
    const dispatch: AppDispatch = useDispatch()

  const company = useSelector(contextCompanySelector)
  const country = useSelector(contextCompanyCountrySelector)
  const companyForm = useSelector(contextCompanyFormSelector)

  const handleCascaderChange = (values?: any[]) => {
    if (!values) return
    const [newCountry, newCompanyForm] = values as [Country, CompanyForm]
    const newValues = {
      ...company,
      country: newCountry,
      form: newCompanyForm
    }
    dispatch(contextRequest(updateCompanyRequest, newValues))
  }

  const accountantCascader = (values: any[]) => {
    const selectedValues: [Country, CompanyForm] = values as [Country, CompanyForm]
    values && setCascaderValues(selectedValues)
    handleAccountantCascaderAction && handleAccountantCascaderAction(values)
  }

  interface CountryOption {
    value: Country
    label?: React.ReactNode
    disabled?: boolean
    children?: CompanyFormOption[]
  }
  interface CompanyFormOption {
    value: CompanyForm
    label?: React.ReactNode
    disabled?: boolean
  }

  const options: CountryOption[] = [
    {
      value: Country.fi,
      label: t(`country:${Country.fi}`),
      children: [
        {
          value: CompanyForm.limited,
          label: t(`accountMapPage:${CompanyForm.limited}`)
        },
        {
          value: CompanyForm.association,
          label: t(`accountMapPage:${CompanyForm.association}`)
        },
        {
          value: CompanyForm.realEstate,
          label: t(`accountMapPage:${CompanyForm.realEstate}`)
        }
      ]
    },
    {
      value: Country.se,
      label: t(`country:${Country.se}`),
      children: [
        {
          value: CompanyForm.limited,
          label: t(`accountMapPage:${CompanyForm.limited}`)
        }
      ]
    }
  ]

  const items = [
    {
      label: t('financialStatementsPage:incomeStatement'),
      key: 'item-1',
      children: <AccountMapTable
        translation="incomeStatement"
        loading={loading}
        statementRows={incomeStatement}
      />
    },
    {
      label: t('financialStatementsPage:assets'),
      key: 'item-2',
      children: <AccountMapTable
        translation="balanceSheet"
        statementRows={assets}
        loading={loading}
      />
    },
    {
      label: t('financialStatementsPage:liabilities'),
      key: 'item-3',
      children: <AccountMapTable
        translation="balanceSheet"
        statementRows={liabilities}
        loading={loading}
      />
    },
  ];

  return (
    <LoadingWrapper loading={!handleAccountantCascaderAction && updateCompanyLoading}>
      <Form.Item label={t('global:company-form')}>
        <Cascader<any>
          allowClear={false}
          defaultValue={handleAccountantCascaderAction ? cascaderValues : [country, companyForm]}
          options={options}
          onChange={handleAccountantCascaderAction ? accountantCascader : handleCascaderChange}
          placeholder="Please select"
        />
      </Form.Item>
      <Tabs defaultActiveKey="1" items={items} />
    </LoadingWrapper>
  )
}

export default AccountMap
