import React, { useContext } from 'react'
import { Button, Typography, Checkbox, Divider, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocsURL } from '../../../utils/docs'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'

const OrderStep = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const { form, setStep, company, loading } = useContext(ContractFormContext) as ContractFormContextType

  return (
    <>
      <Form.Item label={t('contractsPage:subscriber')}>
        <span className="ant-form-text">{company?.name || form.getFieldValue(['contract', 'company', 'name'])}</span>
      </Form.Item>
      <Form.Item label={t('contractsPage:product')}>
        <span className="ant-form-text">{t(`contractProduct:${form.getFieldValue(['contract', 'product'])}`)}</span>
      </Form.Item>
      <Form.Item label={t('contractsPage:supplier')}>
        <span className="ant-form-text">Finadeck Oy, y-tunnus 2909157-5, Yliopistonkatu 4, 00100 Helsinki</span>
      </Form.Item>
      <Form.Item label={t('contractsPage:terms-and-conditions')}>
        <a href={`${docsURL}service_description/`} target="_blank" rel="noopener noreferrer">
          1.
          {t('contractsPage:service-description')}
        </a>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <a href="https://finadeck.fi/hinnasto/" target="_blank" rel="noopener noreferrer">
          2.
          {t('contractsPage:price-list')}
        </a>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <a href={`${docsURL}terms/general`} target="_blank" rel="noopener noreferrer">
          3.
          {t('contractsPage:terms')}
        </a>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <a href={`${docsURL}terms/data-protection`} target="_blank" rel="noopener noreferrer">
          4.
          {t('contractsPage:privacy-statement')}
        </a>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <a href={`${docsURL}terms/data-usage`} target="_blank" rel="noopener noreferrer">
          5.
          {t('contractsPage:data-processing-description')}
        </a>
      </Form.Item>
      <Form.Item
        name={['contract', 'signed']}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        label={t('contractsPage:signature')}
      >
        <Checkbox>{t('contractsPage:signature-text')}</Checkbox>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {!loading ? t('contractsPage:confirm') : t('contractsPage:sending')}
        </Button>
        <Button type="default" onClick={() => setStep(-1)} style={{ marginLeft: 8 }}>
          {t('contractsPage:back')}
        </Button>
      </Form.Item>
      <Divider style={{ margin: '40px 0 24px' }} />
      <div>
        <Typography.Title level={3}>{t('contractsPage:order-information')}</Typography.Title>
        <Typography.Title level={4}>{t('contractsPage:terms-and-conditions')}</Typography.Title>
        <Typography.Text>{t('contractsPage:terms-and-conditions-text')}</Typography.Text>
        <Typography.Title level={4}>{t('contractsPage:signature')}</Typography.Title>
        <Typography.Text>{t('contractsPage:signature-info-text')}</Typography.Text>
      </div>
    </>
  )
}

export default OrderStep
