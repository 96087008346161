import React from 'react'
import { Button, Popconfirm } from 'antd'
import { useDispatch } from 'react-redux'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { Contract } from '../../../../types/contract/Contract'
import { AppDispatch } from '../../../../redux/store'

interface DeleteContractProps {
  contract: Contract
}

export const SuperUserContractDelete: React.FC<DeleteContractProps> = ({ contract }: DeleteContractProps) => {
  const deleteRequest = useBackend(`/api/superuser/contracts/{contractId}`)
  const { loading } = deleteRequest
  const dispatch: AppDispatch = useDispatch()

  const run = () => {
    deleteRequest
      .delete({ urlParams: { contractId: contract.id } })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'OK'
          })
        )
      })
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACTS_ERROR',
            description: e.message
          })
        )
      })
  }

  return (
    <Popconfirm placement="bottomRight" title="Oletko Varma" onConfirm={() => run()} okText="Kyllä" cancelText="Ei">
      <Button type="primary" danger loading={loading}>
        Poista
      </Button>
    </Popconfirm>
  )
}
