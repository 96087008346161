/* eslint-disable prettier/prettier */
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Col, List, Result, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loading from '../../pages/loading/Loading'
import { notificationAction } from '../../redux/middleware/actions'
import { useBackend } from '../../services/backend'
import { Delivery, DeliveryStatus } from '../../types/deliverySubscription/Delivery'
import { AppDispatch } from '../../redux/store'

const DeliveryDownload = () => {
  const { t } = useTranslation()
  const { uuid } = useParams() as any
  const deliveryRequest = useBackend(`/deliveries/{uuid}`, process.env.REACT_APP_DELIVERY_URL)
  const downloadRequest = useBackend(`/deliveries/{uuid}/download`, process.env.REACT_APP_DELIVERY_URL, 'blob')

  const [delivery, setDelivery] = useState<Delivery>()
    const dispatch: AppDispatch = useDispatch()


  const request = () => {
    deliveryRequest
      .get({ urlParams: { uuid } })
      .then(setDelivery)
      .catch(() => setDelivery(undefined))
  }
  useEffect(request, [])

  const latestDeliveries = delivery
    ? delivery.deliverySubscription.deliveries
      ?.filter(d => d.uuid && d.id !== delivery.id)
      .filter(d => d.status === DeliveryStatus.FINISHED)
      .sort((a, b) => (dayjs(b?.endTime).valueOf() || 0) - (dayjs(a?.endTime).valueOf() || 0))
    : []
  const download = (id: string) => {
    id &&
      downloadRequest
        .get({ urlParams: { uuid: id } })
        .then((response: BlobPart) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
        })
  }

  if (deliveryRequest.loading) {
    return <Loading />
  }
  return (
    <Card>
      {delivery ? (
        <>
          <Result
            status="success"
            title={t(`subscriptionsPage:downloadTitle`)}
            subTitle={dayjs(delivery.endTime)?.format('lll')}
            extra={[
              <Button icon={<DownloadOutlined />} onClick={() => delivery && download(delivery.uuid)}>
                {t(`global:download`)}
              </Button>
            ]}
          />
          {!!latestDeliveries?.length && (
            <List
              header={<h3>{t(`subscriptionsPage:latestDeliveries`)}</h3>}
              itemLayout="horizontal"
              dataSource={latestDeliveries}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Row>
                        <Col span={4}>{dayjs(item.endTime)?.format('lll')}</Col>
                        {item.meta?.periodGroups?.[0].endDate && (
                          <Col span={20}>
                            <Typography.Text type="secondary">
                              {dayjs(item.meta.periodGroups[0].endDate)?.format('MM/YYYY')}
                            </Typography.Text>
                          </Col>
                        )}
                      </Row>
                    }
                  />
                  <Button icon={<DownloadOutlined />} type="link" onClick={() => delivery && download(item.uuid)}>
                    {t(`global:download`)}
                  </Button>
                </List.Item>
              )}
            />
          )}
        </>
      ) : (
        <Result
          status="warning"
          title={t(`subscriptionsPage:fileNotFound`)}
          subTitle={t(`description:CONTACT_SUPPORT`)}
        />
      )}
    </Card>
  )
}

export default DeliveryDownload
