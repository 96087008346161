/* eslint-disable camelcase */
import { Divider, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useSelector } from 'react-redux'
import Highcharts from 'highcharts'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import HC_exporting from 'highcharts/modules/exporting'
import { CashFlowStatementRepresentationType } from '../../../redux/context/reports/types'
import CashFlowChart from './CashFlowChart'
import { formatValue } from './utils'
import { RequestError } from '../../../redux/error/types'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { cashFlowStatementGraphConfigSelector } from '../../../redux/config/cashFlowStatement/selectors'

HC_exporting(Highcharts)

interface CashFlowChartContainerProps {
  representationType: CashFlowStatementRepresentationType
  error?: RequestError
}

const CashFlowChartContainer: React.FC<CashFlowChartContainerProps> = ({
  representationType,
  error
}: CashFlowChartContainerProps) => {
  const { t } = useTranslation()
  const cashFlowGraphConfig = useSelector(cashFlowStatementGraphConfigSelector)
  const [selectedGraph, setSelectedGraph] = useState<string | undefined>(
    cashFlowGraphConfig && Object.keys(cashFlowGraphConfig[representationType])[0]
  )

  return (
    <>
      {error ? (
        <div style={{ padding: 16 }}>
          <ErrorComponent error={error} />
        </div>
      ) : (
        selectedGraph && (
          <CashFlowChart
            representationType={representationType}
            formatValue={formatValue}
            selectedGraph={selectedGraph}
          />
        )
      )}
      {cashFlowGraphConfig && Object.keys(cashFlowGraphConfig[representationType]).length > 1 && (
        <>
          <Radio.Group
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}
            onChange={(e: RadioChangeEvent) => setSelectedGraph(e.target.value)}
            defaultValue="DEFAULT"
          >
            {Object.keys(cashFlowGraphConfig[representationType]).map(key => (
              <Radio.Button key={key} value={key}>
                {t(`cashFlowPage:${key}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
          <Divider />
        </>
      )}
    </>
  )
}

export default CashFlowChartContainer
