import { TranslationFields } from '../../components/Form/TranslateFields'
import { VariableType } from '../../components/Table/types'
import { ReportRow } from '../../redux/context/reports/types'

export type Formula = {
  id: number
  name: string
  group?: string
  code?: string
  source: string
  type: VariableType
  formula?: ReportRow
  isVariable?: boolean
  aggregationType?: AggregationType
  order: number
  style?: { fontWeight: 'normal' | 'bold'; decimals?: number }
} & TranslationFields

export interface FormulaErrorType {
  type: 'LEXICAL_ERROR' | 'SYNTAX_ERROR' | 'SYMBOL_NOT_FOUND_ERROR' | 'TYPE_ERROR' | 'ARGUMENT_ERROR'
  position: { start: number; end: number }
  expected: []
  received: number | string
}

type AggregationType = 'sum' | 'average' | 'lastValue' | 'firstValue'

export enum AggregationTypes {
  sum = 'sum',
  average = 'average',
  lastValue = 'lastValue',
  firstValue = 'firstValue'
}
