import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FunctionOutlined,
  PercentageOutlined
} from '@ant-design/icons'
import { Divider, Popconfirm, Button, Tooltip, Space, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { financialStatementsPageSelector } from '../../../../../redux/pages/budgeting/financialStatements/selectors'
import { BudgetingMethod } from './types'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { ReportTableRow } from '../../../../../components/Table/types'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import { notLocked } from '../../../../settings/company/budgeting/budgetingScenarios/utils'
import { ReportRowType } from '../../../../../redux/context/reports/types'
import { BudgetingContext, BudgetingContextType } from './BudgetingProvider'
import { isBudgetDriverMethod } from '../../../../../components/Budgeting/BudgetingMethodBudgetDriver'

const { Text } = Typography
interface BudgetMenuProps {
  reportTableRow: ReportTableRow
}

const BudgetMenu: React.FC<BudgetMenuProps> = ({ reportTableRow }: BudgetMenuProps) => {
  const { t } = useTranslation()
  const { budgetsInEdit, driversInEdit } = useSelector(financialStatementsPageSelector)
  const { periodGroup, budgetingScenario } = useSelector(filtersSelector)
  const [editLoading, setEditLoading] = useState(false)
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)

  const { handleRowAction } = useContext(BudgetingContext) as BudgetingContextType

  const showBudgetDelete = () => {
    if (
      reportTableRow?.budgetingMethod?.method === BudgetingMethod.driver &&
      reportTableRow.rowType === ReportRowType.budgetableRow
    ) {
      return false
    }
    return true
  }

  const getIcon = (method?: BudgetingMethod) => {
    switch (method) {
      case BudgetingMethod.absolute:
        return t('global:currency-symbol')
      case BudgetingMethod.reference:
        return <PercentageOutlined />
      case BudgetingMethod.driver:
        return <FunctionOutlined style={{ color: 'black' }} />
      default:
        return null
    }
  }

  if (
    reportTableRow?.budgetingMethod?.method !== BudgetingMethod.driver &&
    reportTableRow.rowType === ReportRowType.budgetMenu
  ) {
    return <span>{reportTableRow.title || t('financialStatementsPage:budget')}</span>
  }

  const load = async (type: 'cancel' | 'edit') => {
    setEditLoading(true)
    setTimeout(() => {
      handleRowAction(type, reportTableRow)
      setEditLoading(false)
    }, 100)
  }

  const rowKey = reportTableRow.key.split('-budget-menu')[0]

  const isEditing = Object.entries({ ...budgetsInEdit, ...driversInEdit })
    .filter(([, val]) => val)
    .map(([key]) => key)
    .some(key => key === reportTableRow.key || key === rowKey)

  if (isEditing) {
    return (
      <>
        <span>{reportTableRow.title || t('financialStatementsPage:budget')}</span>
        <span style={{ float: 'right' }}>
          <Space split={<Divider type="vertical" />} size={[4, 4]}>
            {!(isBudgetDriverMethod(reportTableRow) && reportTableRow.rowType === ReportRowType.budgetableRow) ? (
              <Tooltip placement="topRight" title={t('global:save')}>
                <Button
                  icon={<CheckOutlined />}
                  type="link"
                  size="small"
                  style={{ color: 'green' }}
                  onClick={() => handleRowAction('save', reportTableRow)}
                />
              </Tooltip>
            ) : null}
            <Tooltip placement="topRight" title={t('global:cancel')}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                loading={editLoading}
                style={{ color: 'red' }}
                size="small"
                onClick={() => load('cancel')}
              />
            </Tooltip>
          </Space>
        </span>
      </>
    )
  }

  return (
    <>
      <Text ellipsis style={{ width: 260 }}>
        {reportTableRow.title || t('financialStatementsPage:budget')}
      </Text>
      <span style={{ float: 'right', margin: -2 }}>
        <Space split={<Divider type="vertical" />} size={[4, 4]}>
          {showBudgetDelete() && (
            <Popconfirm
              disabled={!periodGroup?.id}
              title={t('global:delete-confirm')}
              onConfirm={() => handleRowAction('delete', reportTableRow)}
              okText={t('global:yes')}
              cancelText={t('global:no')}
            >
              <Tooltip placement="topRight" title={t('global:delete')}>
                <Button
                  disabled={
                    !notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries
                  }
                  onClick={() => !periodGroup?.id && handleRowAction('delete', reportTableRow)}
                  icon={<DeleteOutlined />}
                  type="link"
                  size="small"
                />
              </Tooltip>
            </Popconfirm>
          )}
          <Button
            disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
            onClick={() => handleRowAction('editRow', reportTableRow)}
            icon={getIcon(reportTableRow?.budgetingMethod?.method)}
            type="link"
            size="small"
          />
          <Tooltip placement="topRight" title={t('global:edit')}>
            <Button
              icon={<EditOutlined />}
              disabled={
                !notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries
              }
              type="link"
              size="small"
              loading={editLoading}
              onClick={() => load('edit')}
            />
          </Tooltip>
        </Space>
      </span>
    </>
  )
}

export default BudgetMenu
