import { Modal, Steps, Form } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractProductsSelector } from '../../../redux/entities/contractProducts/selectors'
import { ContractFormStep, ContractProduct } from '../../../types/contract/Contract'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'
import OrderStep from './OrderStep'
import PartiesStep from './PartiesStep'
import ProductStep from './ProductStep'
import CreateResultStep from './CreateResultStep'
import '../_Contract.sass'

const { Step } = Steps

interface ContractFormProps {
  modalVisible: boolean
  handleCancel: () => void
}

const ContractForm: React.FC<ContractFormProps> = ({ handleCancel, modalVisible }: ContractFormProps) => {
  const { t } = useTranslation()
  const contractProducts = useSelector(contractProductsSelector)
  const { form, currentStep, resetForm, handleCreateContract } = useContext(
    ContractFormContext
  ) as ContractFormContextType

  const closeModal = () => {
    resetForm()
    handleCancel()
  }

  let stepComponent
  switch (currentStep) {
    case ContractFormStep.Parties:
      stepComponent = <PartiesStep />
      break
    case ContractFormStep.Product:
      stepComponent = <ProductStep />
      break
    case ContractFormStep.Order:
      stepComponent = <OrderStep />
      break
    case ContractFormStep.Result:
      stepComponent = <CreateResultStep closeModal={closeModal} />
      break
    default:
      stepComponent = <div />
      break
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }

  return (
    <Modal
      width="unset"
      style={{ maxWidth: '900px' }}
      title={t('contractsPage:new-contract')}
      open={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      footer={false}
      destroyOnClose
    >
      <div className="contract">
        <Steps style={{ marginBottom: '16px' }} current={currentStep}>
          <Step title={t('contractsPage:parties')} />
          <Step title={t('contractsPage:product')} />
          <Step title={t('contractsPage:order')} />
          <Step title={t('contractsPage:summary')} />
        </Steps>
        <Form
          {...formItemLayout}
          initialValues={{ contract: { product: contractProducts[ContractProduct.BASIC] } }}
          form={form}
          layout="horizontal"
          name="contract"
          onFinish={handleCreateContract}
        >
          {stepComponent}
        </Form>
      </div>
    </Modal>
  )
}

export default ContractForm
