import { FiscalYear, Period } from '../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { ReportDataType, ReportDataTypeCumulatives } from '../reports/types'

/** Huono nimi, mutta palauttaa tilikauden ekan ja vikan periodin */
export const getPeriods = (fiscalYear: FiscalYear) => {
  const startPeriod = fiscalYear.periods[0]
  const endPeriod = fiscalYear.periods[fiscalYear.periods.length - 1]

  return { startPeriod, endPeriod }
}

export const getPreviousFiscalYear = (fiscalYear: FiscalYear, fiscalYears?: FiscalYear[]): FiscalYear | undefined => {
  if (!fiscalYear || !fiscalYears) return undefined
  const currentIndex = fiscalYears.findIndex(f => f.id === fiscalYear.id)
  if (currentIndex === 0) return undefined
  return fiscalYears[currentIndex - 1]
}

export const fiscalYearLabel = (fiscalYear: FiscalYear, closing = false): string => {
  const { startPeriod, endPeriod } = getPeriods(fiscalYear)

  if (closing) {
    return `${endPeriod.month} / ${endPeriod.year}`
  }

  if (startPeriod.year === endPeriod.year) {
    return `${startPeriod.month} - ${endPeriod.month} / ${endPeriod.year}`
  }
  if (startPeriod.year !== endPeriod.year) {
    return `${startPeriod.month} / ${startPeriod.year} - ${endPeriod.month} / ${endPeriod.year}`
  }
  return ''
}

export const periodGroupLabel = (periodGroup?: PeriodGroup | FiscalYear, dataType = ReportDataType.actuals): string => {
  // tsekkaa closing
  if (periodGroup) {
    const startYear = new Date(periodGroup.startDate).getFullYear()
    const startMonth = new Date(periodGroup.startDate).getMonth() + 1
    const endYear = new Date(periodGroup.endDate).getFullYear()
    const endMonth = new Date(periodGroup.endDate).getMonth() + 1
    let label = ''
    if (startYear === endYear) {
      label = `${startMonth} - ${endMonth} / ${endYear}`
    } else {
      label = `${startMonth} / ${startYear} - ${endMonth} / ${endYear}`
    }

    if (dataType === ReportDataType.forecast) {
      label += ` F`
    }
    if (dataType === ReportDataType.budget) {
      label += ` B`
    }
    if (dataType === ReportDataType.comparativePeriod) {
      label += ` ${dataType}`
    }

    return label
  }

  return ''
}

export const periodLabel = (
  period: Period,
  dataType?: ReportDataType,
  cumulative?: 'cumulative' | 'diff' | 'comparativeDiff'
): string => {
  let { year } = period
  const { month } = period

  if (dataType === ReportDataType.comparativePeriod) {
    year -= 1
  }
  let label = ''
  if (cumulative) {
    label += 'cum. '
  }
  label += `${month} / ${year}`
  if (dataType === ReportDataType.forecast) {
    label += ` F`
  }
  if (dataType === ReportDataType.budget) {
    label += ` B`
  }
  if (cumulative === 'diff' || cumulative === 'comparativeDiff') {
    label += ` diff.`
  }

  return label
}

export const cumulativePeriodLabel = (period: Period, dataType: ReportDataTypeCumulatives): string => {
  let { year } = period
  const { month } = period

  if (
    dataType === ReportDataTypeCumulatives.cumulativeComparativePeriod ||
    dataType === ReportDataTypeCumulatives.cumulativeComparativeDifferencePeriod
  ) {
    year -= 1
  }

  let label = `cum. ${month} / ${year}`
  if (dataType === ReportDataTypeCumulatives.cumulativeBudget) label += ` budget`
  if (dataType === ReportDataTypeCumulatives.cumulativeComparativeDifferencePeriod) label += ` diff.`
  if (dataType === ReportDataTypeCumulatives.cumulativeDifference) label += ` diff.`
  if (dataType === ReportDataTypeCumulatives.cumulativeForecast) label += ` forecast`

  return label
}
