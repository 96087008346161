import React, { useEffect, useMemo, useState } from 'react'
import { Form, DatePicker, Space, Card, Button } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import dayjs, { Dayjs } from 'dayjs'
import { useHistory, useLocation } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-components'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useForm } from 'antd/es/form/Form'
import ExchangeRateChart from './ExchangeRateChart'
import { DateRangeValue } from '../../../../../types/date/types'
import { enumerateDaysBetweenDates } from '../../../../../utils/periodGroups/utils'
import ExchangeRateSheet, { SheetRow } from './ExchangeRateSheet'
import { exchangeRatesSelector } from '../../../../../redux/context/exchangeRates/selectors'
import {
  CustomExchangeRateSeries,
  CustomExchangeRateSeriesType,
  CustomExchangeRateSeriesWithCompany,
  StatementType
} from '../types'
import { useBackend } from '../../../../../services/backend'
import { notificationAction } from '../../../../../redux/middleware/actions'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { parseRates } from './utils'
import { updateExchangeRate } from '../../../../../redux/context/exchangeRates/actions'
import ExchangeAverageRates from './ExchangeAverageRates'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import ExchangeRateDownloadButton from './ExchangeRateDownloadButton'

const { RangePicker } = DatePicker

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ExchangeRateEdit = () => {
  const { t } = useTranslation()

  const { state: { record: { company } = {} } = {} } = useLocation<{
    record?: CustomExchangeRateSeriesWithCompany
  }>()
  const history = useHistory()
  const [form] = useForm()
  const dispatch = useDispatch()
  const groupId = useSelector(contextCompanyIdSelector)!

  const [data, setData] = useState<SheetRow[]>([])
  const [dateRange, setDateRange] = useState<DateRangeValue<Dayjs>>([dayjs().subtract(1, 'year'), dayjs()])
  const exchangeRates = useSelector(exchangeRatesSelector)
  const dates = useMemo(() => enumerateDaysBetweenDates(dateRange?.[0] || null, dateRange?.[1] || null), [dateRange])
  const exchangeRateEditRequest = useBackend('api/companies/{groupId}/group/exchange-rates/{seriesId}')
  const exchangeRateCreateRequest = useBackend('api/companies/{groupId}/group/exchange-rates')
  const companyData = useMemo(() => exchangeRates.find(r => r.companyId === company?.id), [exchangeRates])

  useEffect(() => {
    const grouped = _.groupBy(companyData?.rates, 'statementType')
    const newData = Object.values(StatementType).map(name => {
      const reportData = grouped[name] || []
      const dataObject = reportData.reduce((acc, record) => {
        const { month = 0, year } = record
        const dateString = dayjs({ month: month - 1, year }).format('MM/YYYY')

        return {
          ...acc,
          [dateString]: record.value.toString()
        }
      }, {})
      return {
        report: name,
        ...dataObject
      }
    })
    setData(newData)
  }, [company])

  const handleSave = async (newData: CustomExchangeRateSeries) => {
    try {
      const requestData = {
        urlParams: { groupId, seriesId: newData?.id },
        body: { data: newData }
      }

      const updatedData = newData?.id
        ? await exchangeRateEditRequest.put(requestData)
        : await exchangeRateCreateRequest.post(requestData)

      dispatch(updateExchangeRate(updatedData))

      dispatch(
        notificationAction({
          type: 'success',
          message: t('exchangeRatesPage:updateSuccess')
        })
      )
    } catch (error) {
      dispatch(
        notificationAction({
          type: 'error',
          message: t('exchangeRatesPage:updateError')
        })
      )
    }
  }

  const onFinish = (values: any) => {
    const newData = parseRates(data, companyData, values)
    if (newData === null) return
    handleSave(newData)
  }

  if (!company) return null

  return (
    <LoadingWrapper loading={exchangeRateEditRequest.loading || exchangeRateCreateRequest.loading}>
      <StyledContainer>
        <PageHeader
          title={`${company.name}`}
          onBack={history.goBack}
          extra={<div>{`${companyData?.currency}/${companyData?.baseCurrency}`}</div>}
        />
        <Card style={{ backgroundColor: 'rgba(175, 175, 175, 0.04)' }}>
          <ExchangeRateChart data={data} periods={dates} />
        </Card>
        <Form form={form} initialValues={companyData} onFinish={onFinish} layout="vertical">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <FormItem label={t('global:time-period')}>
              <RangePicker value={dateRange} picker="month" onChange={range => setDateRange(range)} />
            </FormItem>
            <Space.Compact>
              <Button
                disabled={companyData?.type === CustomExchangeRateSeriesType.automatic}
                htmlType="submit"
                type="primary"
              >
                {t('global:save')}
              </Button>
              <ExchangeRateDownloadButton data={data} />
            </Space.Compact>
          </div>
        </Form>
        <ExchangeRateSheet
          companyData={companyData}
          editable={companyData?.type === CustomExchangeRateSeriesType.manual}
          data={data}
          columns={dates}
          onChange={setData}
        />
        <ExchangeAverageRates data={data} dates={dates} />
      </StyledContainer>
    </LoadingWrapper>
  )
}

export default ExchangeRateEdit
