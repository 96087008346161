import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Button, List } from 'antd'
import React from 'react'
import KatexComponent from '../../../../components/KatexComponent/KatexComponent'
import { ReportRow } from '../../../../redux/context/reports/types'
import { translateKatex } from '../../../../components/KatexComponent/utils'

interface KatexModalProps {
  reportRows: ReportRow[]
}

const KatexModal: React.FC<KatexModalProps> = ({ reportRows }: KatexModalProps) => {
  const { t } = useTranslation()

  const infoModal = (keyFigureGroup: ReportRow[]) => {
    Modal.info({
      title: t('keyFigurePage:formulas'),
      width: '80%',
      content: (
        <List itemLayout="horizontal">
          {keyFigureGroup
            .filter((keyFigure: ReportRow) => keyFigure.katex)
            .map((keyFigure: ReportRow) => {
              return (
                <List.Item key={keyFigure.id}>
                  <List.Item.Meta
                    title={t(`keyFigureStatement:${keyFigure.id}`)}
                    description={<KatexComponent key={keyFigure.id} math={`= ${translateKatex(keyFigure.katex)}`} />}
                  />
                </List.Item>
              )
            })}
        </List>
      )
    })
  }

  return <Button type="link" icon={<InfoCircleOutlined />} onClick={() => infoModal(reportRows)} />
}

export default KatexModal
