import React from 'react'
import { useSelector } from 'react-redux'
import MultiPurposeDashboard from '../multiPurposeDashboard/MultiPurposeDashboard'
import {
  multiPurposeActiveDashboardSelector,
  multiPurposeDashboardPageSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { budgetingScenariosAndForecastsSelector } from '../../../redux/context/budgetingScenarios/selectors'

const ScenarioDashboard: React.FC = () => {
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const { keyfigureStatements } = useSelector(multiPurposeDashboardPageSelector)
  const activeDashboardData = useSelector(multiPurposeActiveDashboardSelector)

  return (
    <MultiPurposeDashboard
      keyfigureStatements={keyfigureStatements}
      selectedKeyfigureIds={activeDashboardData?.keyFigures || []}
      selectedInternalKeyfigureIds={activeDashboardData?.formulas || []}
      allRows={budgetingScenariosAndForecasts}
      selectedRows={activeDashboardData?.budgetingScenarios}
    />
  )
}
export default ScenarioDashboard
