import React, { useContext, useEffect, useState } from 'react'
import { Modal, Input, Space, Button, Divider, Col, Row, Typography } from 'antd'
import { CheckOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CategoryContext, CategoryContextType } from './categorySelection/CategoryContext'
import { CustomReportCategory } from '../../../../redux/context/customReports/typesCategory'
import { getCustomReportTableCategoryTitle, transformTitleReverse } from '../../../../redux/context/customReports/utils'
import { budgetingScenariosAndForecastsSelector } from '../../../../redux/context/budgetingScenarios/selectors'
import { companyFormBasedKeyFigureSelector } from '../../../../redux/entities/keyFigures/selectors'

const { Text } = Typography

const { TextArea } = Input

interface RowSettingsModalProps {
  path: string
  category: CustomReportCategory
  onChange: Function
  showKeyValue?: boolean
}

const RowSettingsModal: React.FC<RowSettingsModalProps> = ({
  path,
  category,
  onChange,
  showKeyValue
}: RowSettingsModalProps) => {
  const [visible, setVisible] = useState(false)
  const [segmentKey, setSegmentKey] = useState('')
  const [segmentValue, setSegmentValue] = useState('')
  const [updatedCategory, setUpdatedCategory] = useState<CustomReportCategory>(category)
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const { t } = useTranslation()
  const keyFigures = useSelector(companyFormBasedKeyFigureSelector)
  const { editObject, categories } = useContext(CategoryContext) as CategoryContextType

  useEffect(() => {
    setUpdatedCategory(category)
  }, [category])

  const saveKeyAndValue = () => {
    const contextValues = category.context || {}
    Object.assign(contextValues, { [segmentKey]: segmentValue })
    editObject(path, {
      ...category,
      context: contextValues
    })
    setSegmentKey('')
    setSegmentValue('')
  }

  const deleteKeyAndValue = (keyToDelete: string) => {
    const contextValues = {}
    Object.entries(category.context || []).map(([key, value]) => {
      if (keyToDelete !== key) {
        Object.assign(contextValues, { [key]: value })
      }
      return ''
    })

    editObject(path, {
      ...category,
      context: contextValues
    })
  }

  const guide = {
    '{mm/yy}': 'monthYearShort',
    '{mm/yyyy}': 'monthYearLong',
    '{mm/yy} - {mm/yy}': 'monthYearGapShort',
    '{mm/yyyy} - {mm/yyyy}': 'monthYearGapLong',
    '{dd/mm/yyyy}': 'dayWeekYear',
    '{yyyy}': 'onlyYear',
    '{dd}': 'dayShort',
    '{dddd}': 'dayLong',
    '{dd} - {dd}': 'dayGapShort',
    '{dddd} - {dddd}': 'dayGapLong',
    '{w/yyyy}': 'weekYear',
    '{w}': 'week',
    '{w} - {w}': 'weekGap',
    '{dataType}': 'dataTypeGuide',
    '{function}': 'functionGuide',
    '{keyFigure}': 'keyfigureGuide',
    '{scenarioName}': 'scenarioNameGuide'
  }

  const cancel = () => {
    setVisible(false)
    setUpdatedCategory(category)
  }

  const setTitle = (title: string) => {
    const updatedCat = { ...category, title }
    setUpdatedCategory(updatedCat)
  }

  const onOk = () => {
    onChange({ title: updatedCategory.title })
    setVisible(false)
  }

  return (
    <>
      <Button onClick={() => setVisible(!visible)} icon={<SettingOutlined />} />
      {visible && (
        <Modal
          destroyOnClose
          open={visible}
          title={t('customReportPage:columnSettings')}
          onCancel={() => cancel()}
          maskClosable={false}
          footer={[
            <Button danger onClick={() => cancel()}>
              {t('global:cancel')}
            </Button>,
            <Button type="primary" onClick={() => onOk()}>
              {t('global:save')}
            </Button>
          ]}
        >
          <TextArea
            rows={5}
            spellCheck={false}
            onChange={title => setTitle(title.target.value)}
            defaultValue={
              category.title ||
              transformTitleReverse(
                getCustomReportTableCategoryTitle(
                  {
                    ...category,
                    budgetingScenarioName:
                      budgetingScenariosAndForecasts?.find(b => b.id === category?.budgetingScenarioId)?.name ||
                      undefined
                  },
                  categories
                ),
                keyFigures
              )
            }
            placeholder={t('global:title')}
          />
          <Row style={{ marginTop: 8 }}>
            <Col span={6}>
              <b>{t('customReportPage:generatedTitle')}:</b>
            </Col>
            <Col span={18}>
              <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
                {getCustomReportTableCategoryTitle(
                  {
                    ...updatedCategory,
                    budgetingScenarioName:
                      budgetingScenariosAndForecasts?.find(b => b.id === category?.budgetingScenarioId)?.name ||
                      undefined
                  },
                  categories
                )}
              </Typography.Text>
            </Col>
          </Row>

          <Divider />
          <Row>
            <b>{t('customReportPage:guideForTranslator')}:</b>
          </Row>
          {Object.entries(guide).map(([key, value]) => (
            <Row style={{ margin: 8 }}>
              <Col span={8}>
                <Text copyable code>
                  {key}
                </Text>
              </Col>
              <Col span={16}>{t(`customReportPage:${value}`)}</Col>
            </Row>
          ))}
          <Row style={{ margin: 8 }}>
            <Col span={8}>
              <Text copyable code>
                {t('customReportPage:freeText')}
              </Text>
            </Col>
            <Col span={16}>{t('customReportPage:freeTextInfo')}</Col>
          </Row>

          {showKeyValue && (
            <>
              <Divider />
              <Space direction="horizontal" size="small" wrap>
                <Space.Compact>
                  <Input
                    style={{ width: '40%' }}
                    value={segmentKey}
                    placeholder="Key"
                    onChange={e => setSegmentKey(e.target.value)}
                  />
                  <Input
                    style={{ width: '40%' }}
                    value={segmentValue}
                    placeholder="Value"
                    onChange={e => setSegmentValue(e.target.value)}
                  />
                  <Button type="primary" icon={<CheckOutlined />} onClick={saveKeyAndValue} />
                </Space.Compact>
                <Space.Compact direction="vertical">
                  {Object.entries(category.context || []).map(([key, value]) => (
                    <Space.Compact direction="horizontal">
                      <Button size="small">
                        {key}: {value}
                      </Button>
                      <Button icon={<DeleteOutlined />} onClick={() => deleteKeyAndValue(key)} type="text" danger />
                    </Space.Compact>
                  ))}
                </Space.Compact>
              </Space>
            </>
          )}
        </Modal>
      )}
    </>
  )
}

export default RowSettingsModal
