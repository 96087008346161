import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import { useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { authTokenSelector } from '../redux/session/authentication/selectors'
import { dimensionQuerySelector, filtersSelector } from '../redux/context/filters/selectors'

export interface BackendRequest {
  get: Function
  post: Function
  put: Function
  delete: Function
  loading?: boolean
  error?: {
    current?: any
  }
}

export interface RequestData {
  urlParams?: { [key: string]: string }
  body?: RequestBody
}

export interface RequestBody {
  params?: { [key: string]: any }
  data?: { [key: string]: any }
}

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export const useBackend = (
  endPoint: string,
  baseURL?: string,
  responseType?: AxiosRequestConfig['responseType'],
  signal?: any
) => {
  const authToken = useSelector(authTokenSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const [loading, setLoading] = useState<boolean>(false)
  // const [error, setError] = useState<{}>()
  const error = useRef<any>()

  const api = axios.create({
    signal,
    baseURL: baseURL || process.env.REACT_APP_BACKEND_URL,
    headers: { 'X-Token': authToken || '' },
    responseType
  })

  const makeFetch = useCallback(
    (method: HTTPMethod) => {
      const doFetch = async (request: RequestData): Promise<any> => {
        setLoading(true)
        error.current = undefined

        const build = () => {
          let built = endPoint
          const matches = endPoint.match(/{(.*?)}/g)
          if (request && request.urlParams && matches) {
            for (const match of matches) {
              const param = request.urlParams[match.replace(/{|}/g, '')]
              built = built.replace(match, param)
            }
          }
          return built
        }

        const url = build()

        const params = {
          dimensions: dimensionQuery || undefined,
          budgetingScenarioId: budgetingScenario?.id,
          ...(request?.body && request.body.params)
        }
        const body: RequestBody = {
          data: request?.body?.data,
          params
        }

        let theRes
        let res: any = {}

        try {
          theRes = await api({
            method,
            url,
            ...body
          })
          res = _.clone(theRes)
        } catch (err) {
          error.current = (err as any).response
          Bugsnag.notify(new Error('Error'), event => {
            // eslint-disable-next-line no-param-reassign
            event.context = url
            if (request?.body?.data) {
              event.addMetadata('requestBody', request?.body?.data)
            }
            if (params) {
              event.addMetadata('requestParams', params)
            }
          })
          throw new Error(err as any)
        } finally {
          setLoading(false)
        }
        return res.data
      }

      return doFetch
    },
    [api, budgetingScenario, dimensionQuery, endPoint]
  )
  const get = useCallback(makeFetch(HTTPMethod.GET), [makeFetch])
  const post = useCallback(makeFetch(HTTPMethod.POST), [makeFetch])
  const put = useCallback(makeFetch(HTTPMethod.PUT), [makeFetch])
  const del = useCallback(makeFetch(HTTPMethod.DELETE), [makeFetch])

  const request: BackendRequest = Object.defineProperties(
    {
      get,
      post,
      put,
      delete: del
    },
    {
      loading: { get: () => loading },
      error: { get: () => error.current }
    }
  )

  return Object.assign([request, loading, error], {
    request,
    ...request,
    loading,
    error
  })
}
