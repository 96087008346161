import React from 'react'
import VehicleBasedIntegrations from '../vehicleBasedIntegrations/VehicleBasedIntegrations'

const AcPanther: React.FC = () => {
  return (
    <VehicleBasedIntegrations
      allowAuthentication
      credentialsRequestUrl="/ac-panther/authentication/{companyId}"
      getVehiclesUrl="/ac-panther/vehicles/{companyId}"
      updateVehicleUrl="/ac-panther/vehicles/{companyId}/{id}"
      pageDocsUrl="manual/businesses/settings/company#ac-panther"
      name="acpanther"
    />
  )
}

export default AcPanther
