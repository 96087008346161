import { SET_CONTRACT_PRODUCTS, ContractProductsActionTypes } from './types'

const initialState = {}

const contractProductsReducer = (state = initialState, action: ContractProductsActionTypes) => {
  switch (action.type) {
    case SET_CONTRACT_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export default contractProductsReducer
