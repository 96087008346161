import { Thread } from '../../types/threads/Thread'
import { SET_THREADS } from './types'

const initialState: Thread[] = []

const threadReducer = (state: Thread[] = initialState, action: any) => {
  switch (action.type) {
    case SET_THREADS:
      return action.payload
    default:
      return state
  }
}

export default threadReducer
