import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'

export const keyPerformanceIndicatorsSelector = createSelector(
  contextSelector,
  context => context.keyPerformanceIndicators || []
)

export const keyPerformanceIndicatorsLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.keyPerformanceIndicators
)
