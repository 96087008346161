import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import {
  ADD_FISCAL_YEAR,
  DELETE_FISCAL_YEAR,
  FiscalYearActionTypes,
  SET_FISCAL_YEARS,
  UPDATE_FISCAL_YEAR
} from './types'

const initialState: FiscalYear[] = []

const fiscalYearsReducer = (state = initialState, action: FiscalYearActionTypes) => {
  switch (action.type) {
    case SET_FISCAL_YEARS:
      return [...action.payload]
    case ADD_FISCAL_YEAR:
      return [...state, action.payload]
    case UPDATE_FISCAL_YEAR:
      return state.map((item: FiscalYear) => (item.id !== action.payload.id ? item : { ...item, ...action.payload }))
    case DELETE_FISCAL_YEAR:
      return state.filter((item: FiscalYear) => item.id !== action.payload.id)
    default:
      return state
  }
}

export default fiscalYearsReducer
