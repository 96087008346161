import { LoanSummaryReport } from '../../../../pages/budgeting/loans/types'
import { LoansActionTypes, RESET_LOANS, SET_NEW_LOANS_SUMMARY } from '../types'

const initialState: LoanSummaryReport = {
  summary: {
    actualBalance: 0,
    budgetBalance: 0,
    difference: 0
  },
  balanceSheetRows: [
    { balanceSheetRowId: 0, summary: { actualBalance: 0, budgetBalance: 0, difference: 0 }, loans: [] }
  ]
}

const newLoansReducer = (state = initialState, action: LoansActionTypes) => {
  switch (action.type) {
    case SET_NEW_LOANS_SUMMARY:
      return action.payload
    case RESET_LOANS:
      return null
    default:
      return state
  }
}

export default newLoansReducer
