import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'
import { AccountMapActionTypes, SET_CONTEXT_ACCOUNT_MAP_RULES, UPDATE_ACCOUNT_MAP_RULE } from './types'

const initialState: AccountMapRule[] = []

const contextAccountMapRulesReducer = (state = initialState, action: AccountMapActionTypes) => {
  switch (action.type) {
    case SET_CONTEXT_ACCOUNT_MAP_RULES:
      return action.payload
    case UPDATE_ACCOUNT_MAP_RULE:
      return state.map((rule: AccountMapRule) => {
        if (rule.id === action.payload.id) {
          return action.payload
        }
        return rule
      })
    default:
      return state
  }
}

export default contextAccountMapRulesReducer
