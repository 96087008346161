import _ from 'lodash'
import { createSelector } from 'reselect'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { loadingSelector } from '../../loading/selectors'
import { Store } from '../../types'
import { contextContractSelector } from '../contract/selectors'
import { subscribedKeyFiguresSelector } from '../subscribedKeyFigures/selectors'
import { ReportRow, Report, ReportType, ReportRowType } from './types'
import { budgetableBalanceSheet, budgetableIncomeStatement } from './utils'
import { cashFlowKeysSelector } from '../../session/cashFlow/selectors'
import { companySettingsSelector } from '../companySettings/selectors'
import { CompanySettings } from '../../../types/companySettings/CompanySettings'
import { filtersSelector, selectedDimensionFilterSelector } from '../filters/selectors'
import { dimensionListSelector, dimensionTreeSelector } from '../dimensions/selectors'
import {
  getIncomeStatementDimensionsDataSelectedKeys,
  getIncomeStatementDimensionsRowData
} from '../incomeStatenentDimensions/selectors'
import {
  financialStatementsPageSelector,
  financialStatementsReallocationsSelector
} from '../../pages/budgeting/financialStatements/selectors'

export const financialStatementErrorSelector = (store: Store) => store.error && store.error.financialStatement

export const reportSelector = (store: Store) => store.context.reports

/** Financial Statement */
export const financialStatementLoadingSelector = createSelector(loadingSelector, loading => loading.financialStatement)
export const financialStatementSelector = createSelector(reportSelector, reports => reports.financialStatement)

/** Income Statement */
export const incomeStatementSelector = createSelector(financialStatementSelector, ({ incomeStatement = [] }) => {
  return incomeStatement.map(row => ({
    ...row,
    report: ReportType.incomeStatement
  }))
})

/** Role based Income Statement */
export const roleBasedIncomeStatementSelector = createSelector(
  incomeStatementSelector,
  financialStatementsReallocationsSelector,
  (incomeStatement, reallocations) => {
    const roleBasedIncomeStatement = incomeStatement

    const reportRows = []
    for (const reportRow of roleBasedIncomeStatement) {
      const { accounts, ...r } = reportRow

      r.children = accounts?.length
        ? accounts?.map(account => ({
            ...account,
            rowType: ReportRowType.budgetableRow,
            ...reallocations?.[account.code]
          }))
        : undefined

      reportRows.push(r)
    }
    return reportRows
  }
)

/** Budgetable Income Statement
 * financialStatementSelector -> incomeStatementSelector -> roleBasedIncomeStatementSelector -> budgetableIncomeStatementSelector
 */
export const budgetableIncomeStatementSelector = createSelector(
  roleBasedIncomeStatementSelector,
  contextContractSelector,
  companySettingsSelector,
  selectedDimensionFilterSelector,
  dimensionListSelector,
  dimensionTreeSelector,
  getIncomeStatementDimensionsRowData,
  getIncomeStatementDimensionsDataSelectedKeys,
  financialStatementsPageSelector,
  filtersSelector,
  (
    incomeStatement,
    contract,
    settings: CompanySettings,
    dimension,
    dimensionList,
    dimensionTree,
    dimensionData,
    dimensionKeys,
    { budgetsInEdit, driversInEdit },
    { dimensions }
  ) => {
    if (!contract) return []
    const dimensionRowsEnabled = (dimensions?.length || 0) < 2
    return budgetableIncomeStatement(
      incomeStatement,
      contract,
      settings,
      dimensionList,
      dimensionTree,
      dimension,
      dimensionData,
      dimensionKeys,
      budgetsInEdit,
      driversInEdit,
      dimensionRowsEnabled
    )
  }
)

/** Assets */
export const assetsSelector = createSelector(financialStatementSelector, ({ assets = [] }) => {
  return assets.map(row => ({ ...row, report: ReportType.balanceSheet, children: row.accounts }))
})

/** Role based Assets */
export const roleBasedAssetsSelector = createSelector(assetsSelector, assets => {
  return assets
})

/** Budgetable Assets */
export const budgetableAssetsSelector = createSelector(
  roleBasedAssetsSelector,
  contextContractSelector,
  (assets, contract) => {
    // TODO: write-budgets?
    return contract && budgetableBalanceSheet(_.cloneDeep(assets), contract)
  }
)

/** Liabilities */
export const liabilitiesSelector = createSelector(financialStatementSelector, ({ liabilities = [] }) => {
  return liabilities.map(row => ({ ...row, report: ReportType.balanceSheet, children: row.accounts }))
})

/** Role based Liabilities */
export const roleBasedLiabilitiesSelector = createSelector(liabilitiesSelector, liabilities => {
  return liabilities
})

/** Budgetable Liabilities */
export const budgetableLiabilitiesSelector = createSelector(
  roleBasedLiabilitiesSelector,
  contextContractSelector,
  (liabilities, contract) => {
    return contract && budgetableBalanceSheet(_.cloneDeep(liabilities), contract)
  }
)

/** Sales */
export const salesSelector = createSelector(reportSelector, ({ sales }) => sales)

export const salesProductGroupsSelector = createSelector(salesSelector, ({ productGroups }) => {
  return productGroups || []
})

export const salesProductsSelector = createSelector(salesSelector, ({ products }) => {
  return products || []
})

export const salesCustomersSelector = createSelector(salesSelector, ({ customers }) => {
  return customers || []
})

/** Key Figures statement */
export const keyFigureStatementLoadingSelector = createSelector(loadingSelector, loading => loading.keyFigureStatement)

export const keyFigureStatementSelector = createSelector(reportSelector, ({ keyFigureStatement }) => {
  const map = ({ children, ...o }: ReportRow): ReportRow => ({
    ...o,
    report: ReportType.keyFigureStatement,
    children: children?.map(map) // recursive call
  })
  return keyFigureStatement?.map(map) || []
})

export const customKeyFigureStatementSelector = (ids: number[]) =>
  createSelector(keyFigureStatementSelector, keyFigureStatement => {
    return keyFigureStatement.filter(row => ids.includes(row.id))
  })

export const subscribedKeyFigureStatementSelector = createSelector(
  subscribedKeyFiguresSelector,
  keyFigureStatementSelector,
  (subscribedKeyFigures, keyFigureStatement) => {
    return (
      keyFigureStatement &&
      keyFigureStatement.filter((reportRow: ReportRow) => {
        return subscribedKeyFigures.map((keyFigure: KeyFigure) => keyFigure.id).includes(reportRow.id)
      })
    )
  }
)

export const groupedKeyFigureStatementSelector = createSelector(keyFigureStatementSelector, keyFigureStatement =>
  _.groupBy(keyFigureStatement, 'group')
)

/** Internal Key Figures */
export const internalKeyFigureStatementSelector = createSelector(
  reportSelector,
  ({ internalKeyFigureStatement = [] }) => {
    return internalKeyFigureStatement.map(row => ({ ...row, report: ReportType.internalKeyFigureStatement }))
  }
)

/** Key Performance Indicators */
export const keyPerformanceIndicatorStatementLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.keyPerformanceIndicatorStatement
)
export const keyPerformanceIndicatorStatementSelector = createSelector(
  reportSelector,
  ({ keyPerformanceIndicatorStatement }) => {
    return keyPerformanceIndicatorStatement?.map(row => ({
      ...row,
      report: ReportType.keyFigureStatement
    }))
  }
)

/** Cash Flow */
export const cashFlowStatementLoadingSelector = createSelector(loadingSelector, loading => loading.cashFlowStatement)
export const cashFlowStatementSelector = createSelector(reportSelector, reports => {
  const statemenRows = reports.cashFlowStatement
  const map = ({ children, ...o }: ReportRow): ReportRow => ({
    ...o,
    report: ReportType.cashFlowStatement,
    children: children?.map(map) // recursive call
  })
  return statemenRows?.map(map) || []
})

export const cashFlowByKeyStatementSelector = createSelector(
  cashFlowKeysSelector,
  cashFlowStatementSelector,
  (cashFlowSelectedKeys, cashFlowStatement) => {
    const cashFlowReport = cashFlowStatement && _.cloneDeep(cashFlowStatement)

    function recurse(cashFlowReportStatementRows: Report) {
      const finalReport = []
      for (const child of cashFlowReportStatementRows) {
        if (cashFlowSelectedKeys.includes(child.id) && child.children) {
          recurse(child.children)
        } else if (child.children && child.children.length > 0) {
          child.children = []
          child.noExplainer = true
        } else if (!child.explanation) {
          child.children = []
        }
        finalReport.push(child)
      }
      return finalReport
    }

    return cashFlowReport && recurse(cashFlowReport)
  }
)

/** Valuation */
export const valuationSelector = createSelector(reportSelector, reports => reports.valuation)
export const valuationLoadingSelector = createSelector(loadingSelector, loading => loading.valuation)

/** Valuation NAV */
export const valuationNAVSelector = createSelector(valuationSelector, valuation => {
  return (valuation && valuation.NAV) || []
})

/** Valuation PM */
export const valuationPMSelector = createSelector(valuationSelector, valuation => {
  return (valuation && valuation.PM) || []
})

/** Valuation EBITDAF */
export const valuationEBITDAFSelector = createSelector(valuationSelector, valuation => {
  return (valuation && valuation.EBITDAF) || []
})

/** Invoices */
export const invoicesSelector = createSelector(reportSelector, reports => reports.invoices)
export const salesInvoicesSelector = createSelector(invoicesSelector, invoices => invoices && invoices.sales)
export const purchaseInvoicesSelector = createSelector(invoicesSelector, invoices => invoices && invoices.purchases)
export const salesInvoicesLoadingSelector = createSelector(loadingSelector, loading => loading.salesInvoices)
export const purchaseInvoicesLoadingSelector = createSelector(loadingSelector, loading => loading.purchaseInvoices)
