import { RecordType } from '../../../types/sales/sales'
import {
  ADD_ACCOUNT_BUDGET_COMMENT,
  ADD_STATEMENTROW_BUDGET_COMMENT,
  BudgetCommentActionTypes,
  DELETE_ACCOUNT_BUDGET_COMMENT,
  DELETE_STATEMENTROW_BUDGET_COMMENT,
  UPDATE_ACCOUNT_BUDGET_COMMENT,
  UPDATE_STATEMENTROW_BUDGET_COMMENT
} from '../budgetComment/types'
import {
  ReportActionTypes,
  Reports,
  SET_CASH_FLOW_STATEMENT,
  SET_FINANCIAL_STATEMENT,
  SET_KEY_FIGURE_STATEMENT,
  SET_KEY_PERFORMANCE_INDICATOR_STATEMENT,
  SET_VALUATION,
  SET_SALES_INVOICES,
  SET_PURCHASE_INVOICES,
  SET_INTERNAL_KEY_FIGURE_STATEMENT,
  SET_SALES_PRODUCTS,
  SET_SALES_CUSTOMERS,
  SET_SALES_PRODUCT_GROUPS,
  RESET_REPORTS,
  ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER,
  ADD_KEY_FIGURE_EXPLAINER,
  ReportRow,
  ADD_CASH_FLOW_EXPLAINER,
  SET_SALES_CUSTOMER_GROUPS,
  ADD_INTERNAL_KEY_FIGURE_STATEMENT,
  Report
} from './types'
import { cashFlowRecurseExplainer } from './utils'

const initialState: Reports = {
  keyFigureStatement: [],
  cashFlowStatement: [],
  internalKeyFigureStatement: [],
  sales: {
    [RecordType.products]: [],
    [RecordType.productGroups]: [],
    [RecordType.customerGroups]: [],
    [RecordType.customers]: []
  },
  financialStatement: {
    incomeStatement: [],
    assets: [],
    liabilities: []
  }
}

const reportsReducer = (state = initialState, action: ReportActionTypes | BudgetCommentActionTypes): Reports => {
  switch (action.type) {
    case SET_FINANCIAL_STATEMENT:
      return { ...state, financialStatement: action.payload }
    case ADD_STATEMENTROW_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            if (action.payload.incomeStatementRowId === row.id) {
              return {
                ...row,
                budgetComments: [...(row?.budgetComments || []), action.payload]
              }
            }
            return row
          })
        }
      }
    case UPDATE_STATEMENTROW_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            if (action.payload.incomeStatementRowId === row.id) {
              return {
                ...row,
                budgetComments: row?.budgetComments?.map(bg => {
                  if (bg.id === action.payload.id) {
                    return { ...bg, ...action.payload }
                  }
                  return bg
                })
              }
            }
            return row
          })
        }
      }
    case DELETE_STATEMENTROW_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            if (action.payload.incomeStatementRowId === row.id) {
              return {
                ...row,
                budgetComments: row?.budgetComments?.filter(bg => bg.id !== action.payload.id)
              }
            }
            return row
          })
        }
      }
    case ADD_ACCOUNT_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            return {
              ...row,
              accounts: row.accounts?.map(account => {
                if (action.payload.accountCode === account.code) {
                  return {
                    ...account,
                    budgetComments: [...(account?.budgetComments || []), action.payload]
                  }
                }
                return account
              })
            }
          })
        }
      }
    case UPDATE_ACCOUNT_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            return {
              ...row,
              accounts: row.accounts?.map(account => {
                if (action.payload.accountCode === account.code) {
                  return {
                    ...account,
                    budgetComments: account?.budgetComments?.map(bg => {
                      if (bg.id === action.payload.id) {
                        return { ...bg, ...action.payload }
                      }
                      return bg
                    })
                  }
                }
                return account
              })
            }
          })
        }
      }
    case DELETE_ACCOUNT_BUDGET_COMMENT:
      return {
        ...state,
        financialStatement: {
          ...state.financialStatement,
          incomeStatement: state.financialStatement.incomeStatement.map(row => {
            return {
              ...row,
              accounts: row.accounts?.map(account => {
                if (action.payload.accountCode === account.code) {
                  return {
                    ...account,
                    budgetComments: account?.budgetComments?.filter(bg => bg.id !== action.payload.id)
                  }
                }
                return account
              })
            }
          })
        }
      }
    case SET_KEY_FIGURE_STATEMENT:
      return { ...state, keyFigureStatement: action.payload }
    case ADD_KEY_FIGURE_EXPLAINER:
      return {
        ...state,
        keyFigureStatement: state?.keyFigureStatement?.map(keyFigure => {
          if (keyFigure.id === action.payload.keyFigure.id)
            return {
              ...keyFigure,
              children: action.payload.explainer as unknown as ReportRow[]
            }
          return keyFigure
        })
      }

    case ADD_CASH_FLOW_EXPLAINER:
      return {
        ...state,
        cashFlowStatement: cashFlowRecurseExplainer(state?.cashFlowStatement, action.payload)
      }
    case SET_INTERNAL_KEY_FIGURE_STATEMENT:
      return { ...state, internalKeyFigureStatement: action.payload }
    case ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER:
      return {
        ...state,
        internalKeyFigureStatement: state.internalKeyFigureStatement?.map(variable => {
          if (variable.id === action.payload.variable.id) return { ...variable, children: action.payload.explainer }
          return variable
        })
      }
    case ADD_INTERNAL_KEY_FIGURE_STATEMENT:
      return {
        ...state,
        internalKeyFigureStatement: [...(state?.internalKeyFigureStatement || []), ...action.payload]?.reduce(
          (res, data) => {
            if (res.findIndex(variable => variable.id === data.id) < 0) {
              res.push(data)
            }
            return res
          },
          [] as Report
        )
      }
    case SET_KEY_PERFORMANCE_INDICATOR_STATEMENT:
      return { ...state, keyPerformanceIndicatorStatement: action.payload }
    case SET_CASH_FLOW_STATEMENT:
      return { ...state, cashFlowStatement: action.payload }
    case SET_VALUATION:
      return { ...state, valuation: action.payload }
    case SET_SALES_INVOICES:
      return {
        ...state,
        invoices: { ...state.invoices, sales: action.payload }
      }
    case SET_PURCHASE_INVOICES:
      return {
        ...state,
        invoices: { ...state.invoices, purchases: action.payload }
      }
    case SET_SALES_PRODUCTS:
      return { ...state, sales: { ...state.sales, products: action.payload } }
    case SET_SALES_PRODUCT_GROUPS:
      return {
        ...state,
        sales: { ...state.sales, productGroups: action.payload }
      }
    case SET_SALES_CUSTOMER_GROUPS:
      return {
        ...state,
        sales: { ...state.sales, customerGroups: action.payload }
      }
    case SET_SALES_CUSTOMERS:
      return { ...state, sales: { ...state.sales, customers: action.payload } }
    case RESET_REPORTS:
      return initialState
    default:
      return state
  }
}

export default reportsReducer
