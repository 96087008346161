import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DeliverySubscription,
  DeliverySubscriptionType
} from '../../../../../types/deliverySubscription/DeliverySubscription'
import DeliverySubscriptionModal from './components/DeliverySubscriptionModal'
import DeliverySubscriptionTable from './components/DeliverySubscriptionTable'

const CompanyDashboardSubscriptions: React.FC = () => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [deliverySubscription, setDeliverySubscription] = useState<DeliverySubscription>()

  const toggleModalVisible = () => {
    setModalVisible(!modalVisible)
    if (!modalVisible) setDeliverySubscription(undefined)
  }

  const toggleDeliverySubscription = (deliverySubscriptionToUpdate: DeliverySubscription) => {
    setModalVisible(true)
    setDeliverySubscription(deliverySubscriptionToUpdate)
  }

  return (
    <>
      <Button onClick={toggleModalVisible} style={{ marginBottom: 8 }}>
        <PlusOutlined />
        {t('global:add-new')}
      </Button>
      <DeliverySubscriptionModal
        subscriptionType={DeliverySubscriptionType.COMPANY_DASHBOARD}
        initiatorOptions={['monthly', 'periodLock', 'manual']}
        paramsOptions={[
          'dataTypes',
          'dataincomeStatementRepresentationTypes',
          'dataBalanceSheetRepresentationTypes',
          'recipients'
        ]}
        fieldOptions={{
          recipients: true
        }}
        visible={modalVisible}
        deliverySubscription={deliverySubscription}
        toggleModalVisible={toggleModalVisible}
      />
      <DeliverySubscriptionTable
        subscriptionType={DeliverySubscriptionType.COMPANY_DASHBOARD}
        toggleDeliverySubscription={toggleDeliverySubscription}
      />
    </>
  )
}

export default CompanyDashboardSubscriptions
