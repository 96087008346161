import { Company } from '../../../types/company/Company'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { GET, GetRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { SetSubscribedKeyFiguresAction, SET_SUBSCRIBED_KEY_FIGURES, SubscribedKeyFigure } from './types'
import { ContextRequestParams } from '../actions'

export const setSubscribedKeyFigures = (statementRows: KeyFigure[]): SetSubscribedKeyFiguresAction => ({
  type: SET_SUBSCRIBED_KEY_FIGURES,
  payload: statementRows
})

export const getSubscribedKeyFiguresRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/key-figures/subscribed`,
    success: (payload: KeyFigure[]) => setSubscribedKeyFigures(payload)
  },
  meta: {
    type: 'SUBSCRIBED_KEY_FIGURES'
  }
})

export const updateSubscribedKeyFiguresRequest = (
  companyId: Company['id'],
  data: SubscribedKeyFigure[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/settings/key-figures/subscribed`,
    success: (payload: KeyFigure[]) => setSubscribedKeyFigures(payload)
  },
  meta: {
    type: 'UPDATE_SUBSCRIBED_KEY_FIGURES',
    notification: true
  }
})
