import React, { useState } from 'react'
import { Card, Typography, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Loan } from '../../../../types/loan/Loan'
import RepaymentScheduleModal from './RepaymentSceduleModal'
import LoanSummary from './LoanSummary'
import LoansTable from './LoansTable'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { getExistingLoansSummaryRequest } from '../../../../redux/context/loans/existing/actions'
import { investmentsPageSelector } from '../../../../redux/pages/investments/selectors'
import { AppDispatch } from '../../../../redux/store'
import { getNewLoansSummaryRequest } from '../../../../redux/context/loans/new/actions'
import LoanInterestModal from './LoanInterestModal'

type LoanGroupProps = any // kato typet

const LoanGroup: React.FC<LoanGroupProps> = ({ balanceSheetRowId, loansByBalansheetRow, isExisting }) => {
  const [loanForRepaymentScheduleModal, setLoanForRepaymentScheduleModal] = useState<Loan | null>(null)
  const [loanInterestRatesModal, setLoanInterestRatesModal] = useState<Loan | null>(null)

  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const location = useLocation()
  const pagePath = location.pathname

  const reloadLoans = () => {
    if (date !== null && companyId) {
      if (pagePath.includes('existing')) {
        dispatch(
          getExistingLoansSummaryRequest(
            companyId,
            dayjs(date).endOf('month').format('YYYY-MM-DD'),
            budgetingScenario && budgetingScenario.id
          )
        )
      } else {
        dispatch(
          getNewLoansSummaryRequest(
            companyId,
            dayjs(date).endOf('month').format('YYYY-MM-DD'),
            budgetingScenario && budgetingScenario.id
          )
        )
      }
    }
  }

  const repaymentHandleOk = (dataUpdated?: boolean) => {
    setLoanForRepaymentScheduleModal(null)
    if (dataUpdated) {
      reloadLoans()
    }
  }

  const toggleRepaymentSchedule = (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => {
    e.stopPropagation()
    setLoanForRepaymentScheduleModal(loan)
  }

  const toggleInterestRate = (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => {
    e.stopPropagation()
    setLoanInterestRatesModal(loan)
  }

  const interestRatesHandleOk = () => {
    setLoanInterestRatesModal(null)
  }

  return (
    <Card>
      <RepaymentScheduleModal handleOk={repaymentHandleOk} loan={loanForRepaymentScheduleModal} />
      <LoanInterestModal handleOk={interestRatesHandleOk} loan={loanInterestRatesModal} />
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Typography.Title level={3}>{t(`balanceSheet:${balanceSheetRowId}`)}</Typography.Title>
        {isExisting && <LoanSummary loansData={loansByBalansheetRow} />}
        <LoansTable
          balanceSheetRowId={balanceSheetRowId}
          loans={loansByBalansheetRow.loans}
          toggleRepaymentSchedule={toggleRepaymentSchedule}
          toggleInterestRate={toggleInterestRate}
        />
      </Space>
    </Card>
  )
}

export default LoanGroup
