import { Route } from '../../routes/Route'

export const checkIfDisabled = (sectionName: string, disableActions: any) => {
  return disableActions?.find((disabled: any) => disabled === sectionName)
}

export const getDisabledContextdrawerSectionRoutes = (routes: Route[] = []) => {
  const disabledContextdrawPages = []
  for (const route of routes) {
    if (route.disabledContextdrawerSections?.length > 0) {
      disabledContextdrawPages.push(route)
    }
    if (route.routes) {
      for (const childRoute of route.routes) {
        if (childRoute.disabledContextdrawerSections?.length > 0) {
          disabledContextdrawPages.push(childRoute)
        }
      }
    }
  }
  return disabledContextdrawPages
}
