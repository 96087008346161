import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SET_INDUSTRIES, SetIndustries } from './types'
import { Industry } from '../../../types/industry/Industry'
import { getIndustryClassificationRoot } from '../../../utils/tol2008'

export const setIndustries = (industries: Industry[]): SetIndustries => ({
  type: SET_INDUSTRIES,
  payload: industries
})

export const getIndustries = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/industries`,
    success: (payload: { id: number; name: string }[]) => {
      const industries: Industry[] = payload.map(({ id, name }: { id: number; name: string }) => ({
        id,
        name,
        indystryRootId: getIndustryClassificationRoot(id.toString())
      }))
      return setIndustries(industries)
    }
  },
  meta: {
    type: 'INDUSTRIES'
  }
})
