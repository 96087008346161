import { Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setContextCompany } from '../../redux/context/company/actions'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import { loadingSelector } from '../../redux/loading/selectors'
import { currentUserSortedCompaniesSelector } from '../../redux/session/currentUser/selectors'
import { AppDispatch } from '../../redux/store'

const { Option } = Select

type ContextCompanySelectProps = {
  width?: number
}

const defaultProps = {
  width: 240
}

const ContextCompanySelect = ({ width }: ContextCompanySelectProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const selectedCompany = useSelector(contextCompanySelector)
  const companies = useSelector(currentUserSortedCompaniesSelector)
  const { contextCompany: contextCompanyLoading } = useSelector(loadingSelector)

  return (
    <Select
      onChange={(value: string) => {
        const company = companies.find(c => c.id === value)
        if (company) {
          dispatch(setContextCompany(company))
        }
      }}
      loading={contextCompanyLoading}
      className="context-company-select"
      showSearch
      style={{ width }}
      placeholder={t('global:set-context-company')}
      optionFilterProp="children"
      value={selectedCompany ? selectedCompany.id : undefined}
    >
      {(companies || []).map(company => (
        <Option value={company.id} key={company.id} className={company.name}>
          {company.name}
        </Option>
      ))}
    </Select>
  )
}

ContextCompanySelect.defaultProps = defaultProps

export default ContextCompanySelect
