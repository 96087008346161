import React, { useMemo } from 'react'
import { Popover, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Voucher, Transaction } from './types'
import { formatValueByType } from '../../../utils/helpers'
import { Dimension } from '../../../types/dimension/Dimension'

type TransactionTableProps = {
  voucher?: Voucher
}

const getDimensionColumns = (voucher?: Voucher) => {
  const dimensions = [
    ...new Set(
      voucher?.transactions.flatMap(trans => {
        return trans.dimensions?.map(({ name }) => name)
      })
    )
  ]

  return dimensions.map(dimension => ({
    title: dimension,
    key: dimension,
    dataIndex: 'dimensions',
    render: (data: Dimension[]) => {
      return data
        ?.find(({ name }) => dimension === name)
        ?.children?.map(d => d.name)
        .toString()
    }
  }))
}

const TransactionTable = ({ voucher }: TransactionTableProps) => {
  const { t } = useTranslation()

  const dimensionColumns = useMemo(() => getDimensionColumns(voucher), [voucher])

  const columns: ColumnProps<Transaction>[] = [
    {
      title: t('vouchersPage:transactionGuid'),
      key: 'guid',
      dataIndex: 'guid'
    },
    {
      title: t('vouchersPage:accountCode'),
      key: 'accountCode',
      dataIndex: 'accountCode'
    },
    {
      title: t('vouchersPage:accountName'),
      key: 'accountName',
      dataIndex: 'accountName',
      ellipsis: true,
      render: (accountName: string) => {
        return <Popover content={<div>{accountName}</div>}>{accountName}</Popover>
      }
    },
    {
      title: t('vouchersPage:transactionDate'),
      key: 'date',
      ellipsis: true,
      render: (transaction: Transaction) => {
        return transaction.date && dayjs(transaction.date).format('ll')
      }
    },
    {
      title: t('vouchersPage:debit'),
      key: 'debit',
      dataIndex: 'debit',
      align: 'right',
      render: (val: number) => {
        return formatValueByType(val, undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      }
    },
    {
      title: t('vouchersPage:credit'),
      key: 'credit',
      dataIndex: 'credit',
      align: 'right',
      render: (val: number) => {
        return formatValueByType(val, undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      }
    },
    {
      title: t('vouchersPage:transactionType'),
      key: 'type',
      dataIndex: 'type'
    },
    {
      title: t('vouchersPage:transactionDescription'),
      key: 'description',
      dataIndex: 'description',
      ellipsis: true,
      render: (description: string) => {
        return <Popover content={<div>{description}</div>}>{description}</Popover>
      }
    },
    ...dimensionColumns
  ]

  return (
    <Table
      size="small"
      columns={columns}
      locale={t('global:no-data-available')}
      dataSource={voucher?.transactions || []}
      rowKey={record => record.id}
      pagination={false}
    />
  )
}

export default TransactionTable
