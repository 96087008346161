import React from 'react'

import { List } from 'antd'
import BudgetCommentItem, { BudgetComment } from './BudgetCommentItem'

interface BudgetCommentListProps {
  comments: BudgetComment[]
  handleSave: any
  handleDelete: any
  disableActions?: boolean
}

const BudgetCommentList: React.FC<BudgetCommentListProps> = ({
  comments,
  handleSave,
  handleDelete,
  disableActions
}) => {
  return (
    <div
      style={{
        width: 400,
        maxHeight: 400,
        overflow: 'hidden',
        overflowY: 'scroll'
      }}
    >
      <List
        size="small"
        className="comment-list"
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={comment => {
          return (
            <BudgetCommentItem
              comment={comment}
              handleSave={handleSave}
              handleDelete={handleDelete}
              disableActions={disableActions}
            />
          )
        }}
      />
    </div>
  )
}

export default BudgetCommentList
