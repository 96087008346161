export const SET_COMPANY_INFORMATION = 'SET_COMPANY_INFORMATION'
export const SET_STAKEHOLDERS = 'SET_STAKEHOLDERS'

export type SubscribedKeyFigure = { id: number }

export interface SetStakeholdersAction {
  type: typeof SET_STAKEHOLDERS
  payload: any
}

export interface SetCompanyInformationAction {
  type: typeof SET_COMPANY_INFORMATION
  payload: any
}

export type CompanyBasicDataActionTypes = SetCompanyInformationAction | SetStakeholdersAction
