import React from 'react'
import { Button, Col, Row, Card, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ValuationForm } from './components/ValuationForm'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { fiscalYearsSelector } from '../../redux/context/fiscalYears/selectors'
import Chart from './components/Chart'
import { generateValuationChartCategories, generateValuationChartSeries, tranformInitial } from './utils'
import { updateValuationAdjustmentRequest } from '../../redux/context/valuation/actions'
import { valuationPMSelector } from '../../redux/context/reports/selectors'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { FormattedInputNumber } from '../../components/Misc/FormattedInputNumber'
import { ReportTableRow } from '../../components/Table/types'
import { AppDispatch } from '../../redux/store'

interface ProfitMultiplierProps {
  valuationAdjustments?: any
}

const ProfitMultiplier = ({ valuationAdjustments }: ProfitMultiplierProps) => {
  const { t } = useTranslation()
  const valuation = useSelector(valuationPMSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const fiscalYears = useSelector(fiscalYearsSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dispatch: AppDispatch = useDispatch()

  const onSubmit = (values: any) => {
    console.log(values)
    const wages: any[] = []
    Object.entries(values.wages).map(([key, value]) => {
      return value && wages.push({ fiscalYearId: Number(key), value: String(value) })
    })
    const other: any[] = []
    Object.entries(values.other).map(([key, value]) => {
      return value && other.push({ fiscalYearId: Number(key), value: String(value) })
    })
    const { balanceSheet } = valuationAdjustments

    companyId &&
      dispatch(
        updateValuationAdjustmentRequest(
          companyId,
          {
            wages,
            other,
            balanceSheet,
            EBITDAFactor: valuationAdjustments.EBITDAFactor,
            EBITDAHistorySampleSize: valuationAdjustments.EBITDAHistorySampleSize,
            capitalizationInterestRate: values.capitalizationInterestRate
          },
          budgetingScenario && budgetingScenario.id
        )
      )
  }

  return (
    <Card>
      <Form initialValues={tranformInitial(valuationAdjustments)} layout="horizontal" onFinish={onSubmit}>
        <Row gutter={24}>
          <ValuationForm />
          <Col md={12} xs={24}>
            <Card>
              <Chart
                categories={generateValuationChartCategories(valuation as ReportTableRow, fiscalYears)}
                series={generateValuationChartSeries(valuation as ReportTableRow)}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={24} style={{ width: '100%' }}>
          <Col span={6}>
            <h4>{t('valuationPage:capitalisation-interest-basis')}</h4>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="capitalizationInterestRate">
              <FormattedInputNumber percentage addonAfter="%" min={0} step={1} max={100} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('global:save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default ProfitMultiplier
