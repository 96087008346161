import { combineReducers } from 'redux'
import industryAndCompetitorComparisonReducer from './industryAndCompetitorComparison/reducer'
import financialStatementPageReducer from './budgeting/financialStatements/reducer'
import investmentsPageReducer from './investments/reducer'
import reallocationPageReducer from './settings/reallocations/reallocations/reducer'
import multiPurposeDashboardReducer from './dashboard/multiPurposeDashboard/reducer'
import companyDashboardPageReducer from './dashboard/companyDashboard/reducer'

export default combineReducers({
  industryAndCompetitorComparison: industryAndCompetitorComparisonReducer,
  multiPurposeDashboard: multiPurposeDashboardReducer,
  financialStatementsPage: financialStatementPageReducer,
  investmentsPage: investmentsPageReducer,
  reallocationsPage: reallocationPageReducer,
  companyDashboard: companyDashboardPageReducer
})
