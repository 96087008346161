import { CompanyForm, Country } from '../../../types/company/Company'
import { KeyFigureGroup } from '../../../types/keyFigure/KeyFigure'

export interface GlogalKeyFigureMap {
  id: number
  group: KeyFigureGroup
  [Country.fi]: { [key in CompanyForm]?: number }
  [Country.se]: { [key in CompanyForm]?: number }
}

export const globalKeyFigureConfig: GlogalKeyFigureMap[] = [
  {
    id: 1, // Liikevaihto
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 1,
      association: 100,
      realEstate: 201
    },
    se: {
      limited: 1001
    }
  },
  {
    id: 2, // Myyntikate
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 3,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1003
    }
  },
  {
    id: 3, // Käyttökate
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 4,
      association: undefined,
      realEstate: 263
    },
    se: {
      limited: 1004
    }
  },
  {
    id: 4, // Nettotulos
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 5,
      association: undefined,
      realEstate: 260
    },
    se: {
      limited: 1006
    }
  },
  {
    id: 5, // Rahoitustulos
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 6,
      association: undefined,
      realEstate: 261
    },
    se: {
      limited: 1007
    }
  },
  {
    id: 6, // Kokonaistulos
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 7,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1008
    }
  },
  {
    id: 7, // Liikevaihdon muutos-%
    group: 'PROFITABILITY_ABSOLUTE',
    fi: {
      limited: 8,
      association: undefined,
      realEstate: 262
    },
    se: {
      limited: 1009
    }
  },
  {
    id: 8, // Myyntikate-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 59,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1010
    }
  },
  {
    id: 9, // Käyttökate-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 12,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1012
    }
  },
  {
    id: 10, // Liikevoitto-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 13,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1013
    }
  },
  {
    id: 11, // Rahoitustulos-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 14,
      association: undefined,
      realEstate: 264
    },
    se: {
      limited: 1014
    }
  },
  {
    id: 12, // Nettotulos-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 15,
      association: undefined,
      realEstate: 265
    },
    se: {
      limited: 1015
    }
  },
  {
    id: 13, // Henkilöstökulut/liikevaihto-%
    group: 'PROFITABILITY_PERCENTAGE',
    fi: {
      limited: 60,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1060
    }
  },
  {
    id: 14, // Sijoitetun pääoman tuotto-%
    group: 'PROFITABILITY_ROA',
    fi: {
      limited: 38,
      association: undefined,
      realEstate: 287
    },
    se: {
      limited: 1038
    }
  },
  {
    id: 15, // Quick ratio
    group: 'LIQUIDITY',
    fi: {
      limited: 44,
      association: 134,
      realEstate: 292
    },
    se: {
      limited: 1044
    }
  },
  {
    id: 16, // Current ratio
    group: 'LIQUIDITY',
    fi: {
      limited: 45,
      association: 135,
      realEstate: 293
    },
    se: {
      limited: 1045
    }
  },
  {
    id: 17, // Omavaraisuusaste-%
    group: 'SOLVENCY',
    fi: {
      limited: 49,
      association: 136,
      realEstate: 297
    },
    se: {
      limited: 1049
    }
  },
  {
    id: 18, // Net gearing-%
    group: 'SOLVENCY',
    fi: {
      limited: 52,
      association: 137,
      realEstate: 300
    },
    se: {
      limited: 1052
    }
  },
  {
    id: 19, // Käyttöpääoma-%
    group: 'WORKING_CAPITAL',
    fi: {
      limited: 53,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1053
    }
  },
  {
    id: 20, // Myyntisaamisten kiertoaika päivinä
    group: 'WORKING_CAPITAL',
    fi: {
      limited: 56,
      association: 138,
      realEstate: undefined
    },
    se: {
      limited: 1056
    }
  },
  {
    id: 21, // Ostovelkojen kiertoaika päivinä
    group: 'WORKING_CAPITAL',
    fi: {
      limited: 57,
      association: 139,
      realEstate: undefined
    },
    se: {
      limited: 1057
    }
  },
  {
    id: 22, // Varaston kiertonopeus päivinä
    group: 'WORKING_CAPITAL',
    fi: {
      limited: 61,
      association: undefined,
      realEstate: undefined
    },
    se: {
      limited: 1061
    }
  },
  {
    id: 23, // Rahat ja pankkisaamiset
    group: 'CASH',
    fi: {
      limited: 58,
      association: 140,
      realEstate: 301
    },
    se: {
      limited: 1058
    }
  }
]
