import { ProductGroup } from '../../../types/product/product'
import { SetProductGroupsAction, SET_PRODUCTS_GROUPS } from './types'

const initialState: ProductGroup[] = []

const productsGroupsReducer = (state = initialState, action: SetProductGroupsAction) => {
  switch (action.type) {
    case SET_PRODUCTS_GROUPS:
      return action.payload
    default:
      return state
  }
}

export default productsGroupsReducer
