import { Dimension } from '../../../types/dimension/Dimension'
import {
  SET_DIMENSIONS,
  DimensionsActionTypes,
  UPDATE_DIMENSIONS,
  DELETE_DIMENSION,
  ADD_DIMENSION,
  UPDATE_DIMENSION_NAME
} from './types'

const initialState: any[] = []

const dimensionsReducer = (state = initialState, action: DimensionsActionTypes) => {
  switch (action.type) {
    case SET_DIMENSIONS:
      return [...action.payload]
    case UPDATE_DIMENSIONS:
      return state.map((item: Dimension) =>
        action.payload.find(d => d.id === item.id)
          ? {
              ...{
                ...state.find(d => d.id === item.id),
                order: action.payload.find(d => d.id === item.id)?.order,
                ...(action.payload.find(d => d.id === item.id)?.name && {
                  name: action.payload.find(d => d.id === item.id)?.name
                }),
                parentDimensionId: action.payload.find(d => d.id === item.id)?.parentDimensionId
              }
            }
          : { ...item }
      )
    case UPDATE_DIMENSION_NAME:
      return state.map((item: Dimension) =>
        item.id !== action.payload.id ? item : { ...item, name: action.payload.name }
      )
    case DELETE_DIMENSION:
      return state.filter((item: Dimension) => item.dimensionId !== action.payload.dimensionId)
    case ADD_DIMENSION:
      return [...state, action.payload]
    default:
      return state
  }
}

export default dimensionsReducer
