import { createSelector } from 'reselect'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { filtersSelector, periodGroupSelector } from '../filters/selectors'
import { reportableFiscalYearsSelector } from '../fiscalYears/selectors'
import { ReportDataType } from '../reports/types'

export const sortedColumnGroupSelector = createSelector(
  [periodGroupSelector, reportableFiscalYearsSelector, filtersSelector],
  (periodGroups, fiscalYears, filters) => {
    const { dataTypes } = filters
    const groups = periodGroups && periodGroups.length > 0 ? periodGroups : fiscalYears
    const sortedPeriodGroupsArray: PeriodGroup[] | FiscalYear[] = groups
      .slice()
      .sort(
        (n1: PeriodGroup | FiscalYear, n2: PeriodGroup | FiscalYear) => +new Date(n1.endDate) - +new Date(n2.endDate)
      )
    if (dataTypes.includes(ReportDataType.comparativePeriod)) {
      sortedPeriodGroupsArray.sort((a, b) => +new Date(b.endDate) - +new Date(a.endDate))
      sortedPeriodGroupsArray.sort((a, b) => Math.abs(a.id) - Math.abs(b.id))
    }
    return sortedPeriodGroupsArray || []
  }
)

export const columnPeriodsSelector = createSelector(filtersSelector, filters => {
  const { periodGroup } = filters
  if (!periodGroup) return []

  if (periodGroup && 'periods' in periodGroup) {
    return periodGroup.periods
  }
  return []
})
