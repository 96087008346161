import { Button, Select, Form } from 'antd'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SubscribedKeyFigure } from '../../../../../../redux/context/subscribedKeyFigures/types'
import { subscribableKeyFiguresSelector } from '../../../../../../redux/entities/keyFigures/selectors'
import { KeyFigure } from '../../../../../../types/keyFigure/KeyFigure'

const { Option, OptGroup } = Select

interface SubscribedKeyFiguresFormProps {
  subscribedKeyFigures: KeyFigure[]
  handleSave: (selectedKeyFigures: SubscribedKeyFigure[]) => void
}

const SubscribedKeyFiguresForm = ({ handleSave, subscribedKeyFigures }: SubscribedKeyFiguresFormProps) => {
  const [form] = Form.useForm<{ list: SubscribedKeyFigure[] }>()
  const keyFigures = useSelector(subscribableKeyFiguresSelector)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    try {
      const { list } = await form.validateFields()
      handleSave(list.filter(item => !!item.id))
    } catch (error) {
      console.log(error)
    }
  }

  const selectOptions = () => {
    return Object.entries(_.groupBy(keyFigures, 'group')).map(([keyFigureGroupName, keyFigureGroup]) => (
      <OptGroup key={keyFigureGroupName} label={t(`keyFigurePage:${keyFigureGroupName}`)}>
        {keyFigureGroup.map(keyFigure => [
          <Option key={keyFigure.id} value={keyFigure.id}>
            {t(`keyFigureStatement:${keyFigure.id}`)}
          </Option>
        ])}
      </OptGroup>
    ))
  }

  useEffect(() => {
    form.setFieldsValue({ list: subscribedKeyFigures })
  }, [subscribedKeyFigures])

  return (
    <Form onFinish={handleSubmit} form={form}>
      {[0, 1, 2, 3, 4, 5].map(field => (
        <Form.Item name={['list', field, 'id']} rules={[{ required: false }]} key={field}>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={t('global:choose-here')}
            allowClear
            style={{ maxWidth: 300 }}
          >
            {selectOptions()}
          </Select>
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('global:save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SubscribedKeyFiguresForm
