import React from 'react'
import {
  AlignLeftOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BuildOutlined,
  CalendarOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FormatPainterOutlined,
  IdcardOutlined,
  KeyOutlined,
  NumberOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  PieChartOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  SolutionOutlined,
  StockOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { ContractFranchise, ContractProduct } from '../../types/contract/Contract'
import { Route } from '../Route'
import { showAccountorLayout, isGroupCompany, showEconiaNovaIntegration, showBudgetingSettings } from '../utils'
import { Subsidiaries } from '../../pages/settings/company/group/subsidiaries/Subsidiaries'
import AccountantAccountMap from '../../pages/settings/accountant/accountMap/AccountantAccountMap'
import AccountantLogo from '../../pages/settings/accountant/general/AccountantLogo'
import AccountantUsers from '../../pages/settings/accountant/users/AccountantUsers'
import AccountorScorecardSubscriptions from '../../pages/settings/accountant/subscriptions/company-dashboard/AccountorScorecardSubscriptions'
import ActualsGoogleSheets from '../../pages/settings/company/integrations/accounting/ActualsGoogleSheets'
import AppearanceSettings from '../../pages/settings/company/general/Appearance/AppearanceSettings'
import CompanyGeneralSettings from '../../pages/settings/company/general/CompanyGeneralSettings'
import Briox from '../../pages/settings/company/integrations/accounting/Briox'
import BudgetGoogleSheets from '../../pages/settings/company/budgeting/budgetingImport/BudgetGoogleSheets'
import BudgetingCSVImport from '../../pages/settings/company/budgeting/budgetingImport/BudgetingImport'
import BudgetingInput from '../../pages/settings/company/budgeting/budgetingInput/BudgetingInput'
import CompanyAccountMap from '../../pages/settings/company/accountMap/CompanyAccountMap'
import CompanyDashboardSubscriptions from '../../pages/settings/accountant/subscriptions/company-dashboard/CompanyDashboardSubscriptions'
import CompanyUsers from '../../pages/settings/company/users/CompanyUsers'
import Contracts from '../../components/Contracts/Contracts'
import CustomReportsSubscriptions from '../../pages/settings/accountant/subscriptions/company-dashboard/CustomReportsSubscriptions'
import Dimensions from '../../pages/settings/company/dimensions/Dimensions'
import Econia from '../../pages/settings/company/integrations/accounting/Econia'
import Efina from '../../pages/settings/company/integrations/accounting/Efina'
import ExcelImport from '../../pages/settings/company/integrations/accounting/Excel'
import ExternalGoogleSheets from '../../pages/settings/company/integrations/external/ExternalGoogleSheets'
import Fennoa from '../../pages/settings/company/integrations/accounting/Fennoa'
import Figures from '../../pages/settings/company/group/figures/Figures'
import FinancierUsers from '../../pages/settings/financier/users/FinancierUsers'
import FiscalYears from '../../pages/settings/company/fiscalYears/FiscalYears'
import Fivaldi from '../../pages/settings/company/integrations/accounting/Fivaldi'
import Formulas from '../../pages/settings/company/keyFigures/formulas/Formulas'
import Fortnox from '../../pages/settings/company/integrations/accounting/Fortnox'
import Heeros from '../../pages/settings/company/integrations/accounting/Heeros'
import KeyPerformanceIndicators from '../../pages/settings/company/keyFigures/keyPerformanceIndicators/KeyPerformanceIndicators'
import Kiho from '../../pages/settings/company/integrations/external/kiho/Kiho'
import Kotipizza from '../../pages/settings/company/integrations/external/kotipizza/Kotipizza'
import Lemonsoft from '../../pages/settings/company/integrations/accounting/Lemonsoft'
import Maestro from '../../pages/settings/company/integrations/accounting/Maestro'
import Mapon from '../../pages/settings/company/integrations/external/mapon/Mapon'
import Netvisor from '../../pages/settings/company/integrations/accounting/Netvisor'
import PasswordPage from '../../pages/settings/user/password/Password'
import Personnel from '../../pages/settings/company/personnel/Personnel'
import Procountor from '../../pages/settings/company/integrations/accounting/Procountor'
import Restolution from '../../pages/settings/company/integrations/external/restolution/Restolution'
import SettingLayout from '../../layouts/SettingLayout'
import Sonet from '../../pages/settings/company/integrations/accounting/Sonet'
import Subscribed from '../../pages/settings/company/keyFigures/subscribed/Subscribed'
import Talenom from '../../pages/settings/company/integrations/accounting/Talenom'
import UserGeneralSettings from '../../pages/settings/user/general/UserGeneralSettings'
import UserSettingsProfile from '../../pages/settings/user/profile/Profile'
import ReallocationPage from '../../pages/settings/company/reallocation/ReallocationPage'
import Logisystems from '../../pages/settings/company/integrations/external/logisystems/Logisystems'
import Maraplan from '../../pages/settings/company/integrations/external/maraplan/Maraplan'
import AcPanther from '../../pages/settings/company/integrations/external/acPanther/AcPanther'
import Forecasts from '../../pages/settings/company/budgeting/forecasts/Forecasts'
import BudgetingScenarios from '../../pages/settings/company/budgeting/budgetingScenarios/BudgetingScenarios'
import BudgetDrivers from '../../pages/settings/company/budgeting/budgetDrivers/BudgetDrivers'
import VismaL7 from '../../pages/settings/company/integrations/accounting/VismaL7'
import Severa from '../../pages/settings/company/integrations/external/severa/Severa'
import Futursoft from '../../pages/settings/company/integrations/external/futursoft/Futursoft'
import Tamigo from '../../pages/settings/company/integrations/external/tamigo/Tamigo'
import ExternalOneDrive from '../../pages/settings/company/integrations/external/onedrive/ExternalOneDrive'
import ActualsOneDrive from '../../pages/settings/company/integrations/accounting/ActualsOneDrive'
import BudgetOneDrive from '../../pages/settings/company/budgeting/budgetingImport/BudgetOneDrive'
import Vismanet from '../../pages/settings/company/integrations/accounting/Vismanet'
import Xero from '../../pages/settings/company/integrations/accounting/Xero'
import Tulopos from '../../pages/settings/company/integrations/external/tulopos/Tulopos'
import Oscar from '../../pages/settings/company/integrations/accounting/Oscar'
import ExchangeRate from '../../pages/settings/company/group/exchangeRate/ExchangeRate'
import ExchangeRateEdit from '../../pages/settings/company/group/exchangeRate/ExchangeRateEdit'
import HrSuunti from '../../pages/settings/company/integrations/external/hrsuunti/HrSuunti'
import ExchangeRateTable from '../../pages/settings/company/group/exchangeRate/ExchangeRateTable'
import Accounts from '../../pages/settings/company/accounts/Accounts'
import CustomReportOrder from '../../pages/reporting/custom/components/settings/CustomReportOrder'

const settingsRoutes: Route = {
  path: '/settings',
  name: 'settings',
  icon: <SettingOutlined />,
  hideInMenu: true,
  routes: [
    {
      path: '/settings/custom-report',
      name: 'custom-report',
      component: SettingLayout,
      hideInMenu: true,
      routes: [
        {
          path: '/settings/custom-report/order',
          name: 'custom-report-order',
          icon: <OrderedListOutlined />,
          component: CustomReportOrder
        }
      ]
    },
    {
      path: '/settings/user',
      name: 'user',
      component: SettingLayout,
      routes: [
        {
          path: '/settings/user/profile',
          name: 'profile',
          component: UserSettingsProfile
        },
        {
          path: '/settings/user/general',
          name: 'general',
          component: UserGeneralSettings
        },
        {
          path: '/settings/user/password',
          name: 'password',
          component: PasswordPage
        }
      ]
    },
    {
      path: '/settings/accountant',
      name: 'accountant',
      component: SettingLayout,
      authority: { role: ['accountant-admin', 'accountant-user'] },
      routes: [
        {
          path: '/settings/accountant/users',
          name: 'users',
          icon: <TeamOutlined />,
          component: AccountantUsers
        },
        {
          path: '/settings/accountant/general',
          name: 'general',
          icon: <ReadOutlined />,
          routes: [
            {
              path: '/settings/accountant/general/logoupload',
              name: 'logoupload',
              icon: <IdcardOutlined />,
              component: AccountantLogo
            }
          ]
        },
        {
          path: '/settings/accountant/contracts',
          name: 'contracts',
          icon: <AuditOutlined />,
          component: Contracts
        },
        {
          path: '/settings/accountant/account-map',
          name: 'account-map',
          icon: <AlignLeftOutlined />,
          component: AccountantAccountMap
        },
        {
          path: '/settings/accountant/subscriptions',
          name: 'subscriptions',
          icon: <ReconciliationOutlined />,
          routes: [
            {
              path: '/settings/accountant/subscriptions/company-dashboard',
              name: 'company-dashboard',
              icon: <PieChartOutlined />,
              component: CompanyDashboardSubscriptions
            },
            {
              path: '/settings/accountant/subscriptions/custom-reports',
              name: 'custom-reports',
              icon: <ClusterOutlined />,
              component: CustomReportsSubscriptions
            },
            {
              path: '/settings/accountant/subscriptions/accountor-scorecard',
              name: 'accountor-scorecard',
              icon: <StockOutlined />,
              component: AccountorScorecardSubscriptions,
              authority: {
                custom: showAccountorLayout
              }
            }
          ]
        }
      ]
    },
    {
      path: '/settings/financier',
      name: 'accountant',
      component: SettingLayout,
      authority: { role: ['financier-admin', 'financier-user'] },
      routes: [
        {
          path: '/settings/financier/users',
          name: 'users',
          icon: <TeamOutlined />,
          component: FinancierUsers
        },
        {
          path: '/settings/financier/contracts',
          name: 'contracts',
          icon: <AuditOutlined />,
          component: Contracts
        },
        {
          path: '/settings/financier/account-map',
          name: 'account-map',
          icon: <AlignLeftOutlined />,
          component: AccountantAccountMap
        },
        {
          path: '/settings/financier/subscriptions',
          name: 'subscriptions',
          icon: <ReconciliationOutlined />,
          routes: [
            {
              path: '/settings/financier/subscriptions/company-dashboard',
              name: 'company-dashboard',
              icon: <PieChartOutlined />,
              component: CompanyDashboardSubscriptions
            }
          ]
        }
      ]
    },
    {
      path: '/settings/company',
      name: 'company',
      component: SettingLayout,
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      },
      routes: [
        {
          path: '/settings/company/general',
          name: 'general',
          icon: <SettingOutlined />,
          component: CompanyGeneralSettings
        },
        {
          path: '/settings/company/appearance',
          name: 'appearance',
          icon: <FormatPainterOutlined />,
          component: AppearanceSettings
        },
        {
          path: '/settings/company/group',
          name: 'group',
          icon: <DeploymentUnitOutlined />,
          authority: {
            custom: isGroupCompany
          },
          routes: [
            {
              path: '/settings/company/group/subsidiaries',
              name: 'subsidiaries',
              icon: <BuildOutlined />,
              component: Subsidiaries
            },
            {
              path: '/settings/company/group/figures',
              name: 'figures',
              icon: <NumberOutlined />,
              component: Figures
            },
            {
              path: '/settings/company/group/exchangeRate',
              name: 'exchangeRate',
              icon: <StockOutlined />,
              component: ExchangeRate,
              routes: [
                {
                  path: '/settings/company/group/exchangeRate',
                  name: 'exchangeRate',
                  hideInMenu: true,
                  component: ExchangeRateTable
                },
                {
                  path: '/settings/company/group/exchangeRate/edit',
                  name: 'edit',
                  hideInMenu: true,
                  component: ExchangeRateEdit
                }
              ]
            }
          ]
        },
        {
          path: '/settings/company/users',
          name: 'users',
          icon: <TeamOutlined />,
          component: CompanyUsers
        },
        {
          path: '/settings/company/integrations',
          name: 'integrations',
          icon: <ApiOutlined />,
          routes: [
            {
              path: '/settings/company/integrations/accounting',
              name: 'accounting',
              routes: [
                {
                  path: '/settings/company/integrations/accounting/briox',
                  name: 'briox',
                  component: Briox
                },
                {
                  path: '/settings/company/integrations/accounting/efina',
                  name: 'efina',
                  component: Efina
                },
                {
                  path: '/settings/company/integrations/accounting/excel',
                  name: 'excel',
                  component: ExcelImport
                },
                {
                  path: '/settings/company/integrations/accounting/fennoa',
                  name: 'fennoa',
                  component: Fennoa
                },
                {
                  path: '/settings/company/integrations/accounting/fivaldi',
                  name: 'fivaldi',
                  component: Fivaldi
                },
                {
                  path: '/settings/company/integrations/accounting/fortnox',
                  name: 'fortnox',
                  component: Fortnox
                },
                {
                  path: '/settings/company/integrations/accounting/actuals-google-sheets',
                  name: 'actuals-google-sheets',
                  component: ActualsGoogleSheets
                },
                {
                  path: '/settings/company/integrations/accounting/heeros',
                  name: 'heeros',
                  component: Heeros
                },
                {
                  path: '/settings/company/integrations/accounting/lemonsoft',
                  name: 'lemonsoft',
                  component: Lemonsoft
                },
                {
                  path: '/settings/company/integrations/accounting/maestro',
                  name: 'maestro',
                  component: Maestro
                },
                {
                  path: '/settings/company/integrations/accounting/netvisor',
                  name: 'netvisor',
                  component: Netvisor
                },
                {
                  path: '/settings/company/integrations/accounting/econia-nova',
                  name: 'econia-nova',
                  component: Econia,
                  authority: {
                    custom: showEconiaNovaIntegration
                  }
                },
                {
                  path: '/settings/company/integrations/accounting/onedrive',
                  name: 'onedrive',
                  component: ActualsOneDrive
                },
                {
                  path: '/settings/company/integrations/accounting/oscar',
                  name: 'oscar',
                  component: Oscar
                },
                {
                  path: '/settings/company/integrations/accounting/procountor',
                  name: 'procountor',
                  component: Procountor
                },
                {
                  path: '/settings/company/integrations/accounting/sonet',
                  name: 'sonet',
                  component: Sonet
                },
                {
                  path: '/settings/company/integrations/accounting/talenom',
                  name: 'talenom',
                  component: Talenom
                },
                {
                  path: '/settings/company/integrations/accounting/vismaL7',
                  name: 'vismaL7',
                  component: VismaL7
                },
                {
                  path: '/settings/company/integrations/accounting/vismanet',
                  name: 'vismane',
                  component: Vismanet
                },
                {
                  path: '/settings/company/integrations/accounting/xero',
                  name: 'xero',
                  component: Xero
                }
              ]
            },
            {
              path: '/settings/company/integrations/external',
              name: 'external',
              routes: [
                {
                  path: '/settings/company/integrations/external/ac-panther',
                  name: 'acpanther',
                  component: AcPanther
                },
                {
                  path: '/settings/company/integrations/external/futursoft',
                  name: 'futursoft',
                  component: Futursoft
                },
                {
                  path: '/settings/company/integrations/external/google-sheets',
                  name: 'google-sheets',
                  component: ExternalGoogleSheets
                },
                {
                  path: '/settings/company/integrations/external/hrsuunti',
                  name: 'hrsuunti',
                  component: HrSuunti
                },
                {
                  path: '/settings/company/integrations/external/kiho',
                  name: 'kiho',
                  component: Kiho
                },
                {
                  path: '/settings/company/integrations/external/kotipizza',
                  name: 'kotipizza',
                  component: Kotipizza,
                  authority: {
                    franchise: ContractFranchise.KOTIPIZZA
                  }
                },
                {
                  path: '/settings/company/integrations/external/logisystems',
                  name: 'logisystems',
                  component: Logisystems
                },
                {
                  path: '/settings/company/integrations/external/mapon',
                  name: 'mapon',
                  component: Mapon
                },
                {
                  path: '/settings/company/integrations/external/maraplan',
                  name: 'maraplan',
                  component: Maraplan
                },
                {
                  path: '/settings/company/integrations/external/onedrive',
                  name: 'external-onedrive',
                  component: ExternalOneDrive
                },
                {
                  path: '/settings/company/integrations/external/restolution',
                  name: 'restolution',
                  component: Restolution
                },
                {
                  path: '/settings/company/integrations/external/severa',
                  name: 'severa',
                  component: Severa
                },
                {
                  path: '/settings/company/integrations/external/tamigo',
                  name: 'tamigo',
                  component: Tamigo
                },
                {
                  path: '/settings/company/integrations/external/tulopos',
                  name: 'tulopos',
                  component: Tulopos
                }
              ]
            }
          ]
        },
        {
          path: '/settings/company/fiscal-years',
          name: 'fiscal-years',
          icon: <CalendarOutlined />,
          component: FiscalYears
        },
        {
          path: '/settings/company/dimensions',
          name: 'dimensions',
          icon: <ApartmentOutlined />,
          component: Dimensions
        },
        {
          path: '/settings/company/personnel',
          name: 'personnel',
          icon: <SolutionOutlined />,
          component: Personnel
        },
        {
          path: '/settings/company/accounts',
          name: 'accounts',
          icon: <DatabaseOutlined />,
          component: Accounts
        },
        {
          path: '/settings/company/account-map',
          name: 'account-map',
          icon: <AlignLeftOutlined />,
          component: CompanyAccountMap
        },
        {
          path: '/settings/company/reallocation',
          name: 'reallocation',
          icon: <PartitionOutlined />,
          component: ReallocationPage,
          authority: {
            product: ContractProduct.PRO,
            custom: () => !isGroupCompany()
          }
        },
        {
          path: '/settings/company/budgeting',
          name: 'budgeting',
          icon: <BankOutlined />,
          authority: {
            product: [ContractProduct.BASIC, ContractProduct.PRO],
            custom: showBudgetingSettings
          },
          routes: [
            {
              path: '/settings/company/budgeting/input',
              name: 'input',
              component: BudgetingInput,
              authority: {
                custom: showBudgetingSettings
              }
            },
            {
              path: '/settings/company/budgeting/drivers',
              name: 'drivers',
              component: BudgetDrivers,
              authority: {
                custom: showBudgetingSettings
              }
            },

            {
              path: '/settings/company/budgeting/import',
              name: 'import',
              routes: [
                {
                  path: '/settings/company/budgeting/import/csv',
                  name: 'csv',
                  component: BudgetingCSVImport,
                  authority: {
                    custom: showBudgetingSettings
                  }
                },
                {
                  path: '/settings/company/budgeting/import/google-sheet',
                  name: 'google-sheet',
                  component: BudgetGoogleSheets,
                  authority: {
                    custom: showBudgetingSettings
                  }
                },
                {
                  path: '/settings/company/budgeting/import/onedrive',
                  name: 'import-onedrive',
                  component: BudgetOneDrive,
                  authority: {
                    custom: showBudgetingSettings
                  }
                }
              ]
            }
          ]
        },
        {
          path: '/settings/company/budgeting/budgeting-scenarios',
          name: 'budgeting-scenarios',
          component: BudgetingScenarios
        },
        {
          path: '/settings/company/budgeting/forecasts',
          name: 'forecasts',
          component: Forecasts
        },
        {
          path: '/settings/company/key-figures',
          name: 'key-figures',
          icon: <KeyOutlined />,
          routes: [
            {
              path: '/settings/company/key-figures/subscribed',
              name: 'subscribed',
              component: Subscribed
            },
            {
              path: '/settings/company/key-figures/key-performance-indicators',
              name: 'key-performance-indicators',
              component: KeyPerformanceIndicators
            },
            {
              path: '/settings/company/key-figures/formulas',
              name: 'formulas',
              component: Formulas
            }
            // {
            //   path: '/settings/company/key-figures/dimension-dashboard',
            //   name: 'dimension-dashboard'
            // }
            // {
            //   path: '/settings/company/key-figures/financial-structure',
            //   name: 'financial-structure'
            // }
          ]
        },
        {
          path: '/settings/company/subscriptions',
          name: 'subscriptions',
          icon: <ReconciliationOutlined />,
          authority: {
            permission: 'read-root-dimension'
          },
          routes: [
            {
              path: '/settings/company/subscriptions/company-dashboard',
              name: 'company-dashboard',
              icon: <PieChartOutlined />,
              component: CompanyDashboardSubscriptions
            },
            {
              path: '/settings/company/subscriptions/custom-reports',
              name: 'custom-reports',
              icon: <ClusterOutlined />,
              component: CustomReportsSubscriptions
            },
            {
              path: '/settings/company/subscriptions/accountor-scorecard',
              name: 'accountor-scorecard',
              icon: <StockOutlined />,
              component: AccountorScorecardSubscriptions,
              authority: {
                custom: showAccountorLayout
              }
            }
          ]
        }
      ]
    }
  ]
}

export default settingsRoutes as Route
