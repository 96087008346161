import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import dotProp from 'dot-prop'
import { useBackend } from '../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import Loading from '../../../loading/Loading'
import EditableCellTable, {
  EditableCellTableDataIndex,
  EditableColumnProps
} from '../../../../components/Table/EditableCellTable'
import { VariableType } from '../../../../components/Table/types'
import { formatValueByType } from '../../../../utils/helpers'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'

interface DepreciationModalProps {
  investmentId?: string
  handleOk: (dataUpdated: boolean) => void
}
export interface DepreciationItem {
  year: number
  month: number
  deprecationPlan: {
    id: number
    deprecation: number
    residual: number
    correction: {
      id: number
      value: number
    }
  }
  taxDeprecationPlan: {
    id: number
    deprecation: number
    residual: number
    correction: {
      id: number
      value: number
    }
  }
  acceleratedDeprecations: {
    delta: number
    deprecation: number
  }
}

const DepreciationModal: React.FC<DepreciationModalProps> = ({ investmentId, handleOk }: DepreciationModalProps) => {
  const companyId = useSelector(contextCompanyIdSelector)
  const [depreciationData, setDepreciationData] = useState<DepreciationItem[] | []>([])
  const [dataUpdated, setDataupdated] = useState<boolean>(false)
  const { t } = useTranslation()

  const depreciationRequest = useBackend(`api/companies/{companyId}/budgeting/investments/{investmentId}/deprecations`)
  const putAndDeleteDepreciationRequestUrl = useBackend(
    `api/companies/{companyId}/budgeting/deprecation-plans/{deprecationPlanId}/corrections/{correctionId}`
  )
  const postDepreciationRequestUrl = useBackend(
    `api/companies/{companyId}/budgeting/deprecation-plans/{deprecationPlanId}/corrections`
  )
  const getTableData = () => {
    depreciationRequest
      .get({ urlParams: { companyId, investmentId } })
      .then((response: any) => {
        setDepreciationData(response)
      })
      .catch(() => {
        setDepreciationData([])
      })
  }

  useEffect(() => {
    if (investmentId) {
      getTableData()
    }
  }, [investmentId])

  const handleOkCancelAction = () => {
    setDepreciationData([])
    handleOk(dataUpdated)
    setDataupdated(false)
  }

  const columns: EditableColumnProps<DepreciationItem>[] = [
    {
      title: t('investmentsPage:depreciationDate'),
      dataIndex: 'date',
      align: 'right',
      width: '25%',
      render: (text: string, record: DepreciationItem) => {
        const { year, month } = record
        return dayjs({ year, month: month - 1 })
          .endOf('month')
          .format('L')
      }
    },
    {
      title: t('investmentsPage:accounting'),
      children: [
        {
          title: t('investmentsPage:deprecation'),
          dataIndex: ['deprecationPlan', 'deprecation'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:correction'),
          dataIndex: ['deprecationPlan', 'correction', 'value'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
          editable: true,
          inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
            <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
          ))
        },
        {
          title: t('investmentsPage:taxDeprecation'),
          dataIndex: ['deprecationPlan', 'residual'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    },
    {
      title: t('investmentsPage:taxation'),
      children: [
        {
          title: t('investmentsPage:deprecation'),
          dataIndex: ['taxDeprecationPlan', 'deprecation'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:correction'),
          dataIndex: ['taxDeprecationPlan', 'correction', 'value'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
          editable: true,
          inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
            <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
          ))
        },
        {
          title: t('investmentsPage:taxDeprecation'),
          dataIndex: ['taxDeprecationPlan', 'residual'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    },
    {
      title: t('investmentsPage:acceleratedDeprecation'),
      children: [
        {
          title: t('investmentsPage:change'),
          dataIndex: ['acceleratedDeprecations', 'delta'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:acceleratedDeprecation'),
          dataIndex: ['acceleratedDeprecations', 'deprecation'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    }
  ]

  const onUpdate = (
    formFields: DepreciationItem,
    previousRecord: DepreciationItem,
    dataIndex: EditableCellTableDataIndex
  ) => {
    const value = dotProp.get(formFields, (dataIndex as string[]).join('.'), 0)
    let correctionId = 0
    let deprecationPlanId = 0
    if ((dataIndex as string[])[0] === 'deprecationPlan') {
      correctionId = previousRecord.deprecationPlan.correction?.id
      deprecationPlanId = previousRecord.deprecationPlan.id
    } else {
      correctionId = previousRecord.taxDeprecationPlan.correction?.id
      deprecationPlanId = previousRecord.taxDeprecationPlan.id
    }

    if (correctionId) {
      if (value === 0) {
        putAndDeleteDepreciationRequestUrl
          .delete({
            urlParams: { companyId, deprecationPlanId, correctionId }
          })
          .then(() => {
            getTableData()
          })
          .catch()
      } else {
        putAndDeleteDepreciationRequestUrl
          .put({
            urlParams: { companyId, deprecationPlanId, correctionId },
            body: { data: { value } }
          })
          .then(() => {
            getTableData()
          })
          .catch()
      }
    } else {
      value &&
        postDepreciationRequestUrl
          .post({
            urlParams: { companyId, deprecationPlanId },
            body: {
              data: {
                year: formFields.year,
                month: formFields.month,
                value
              }
            }
          })
          .then(() => {
            getTableData()
          })
          .catch()
    }
    setDataupdated(true)
  }

  return (
    <Modal
      style={{ maxWidth: '1200px' }}
      title={t('investmentsPage:depreciations')}
      open={!!investmentId}
      onOk={handleOkCancelAction}
      onCancel={handleOkCancelAction}
      maskClosable={false}
      footer={
        <Button type="primary" onClick={handleOkCancelAction}>
          {t('contractsPage:close')}
        </Button>
      }
      width="unset"
    >
      {depreciationData.length > 0 ? (
        <EditableCellTable<DepreciationItem>
          size="small"
          rowKey={(record: any) => `${record.year.toString()}-${record.month.toString()}`}
          dataSource={depreciationData}
          columns={depreciationData?.[0]?.taxDeprecationPlan ? columns : columns.slice(0, 2)}
          onUpdate={onUpdate}
          pagination={false}
        />
      ) : (
        <Loading />
      )}
    </Modal>
  )
}

export default DepreciationModal
