import { Action, ThunkAction } from '@reduxjs/toolkit'
import { Company } from '../../../types/company/Company'
import { setContextContract } from '../contract/actions'
import { resetContextFilter, setDimensionsFilter } from '../filters/actions'
import { getFiscalYearsRequest } from '../fiscalYears/actions'
import { SET_CONTEXT_COMPANY } from './types'
import { getDimensionsRequest } from '../dimensions/actions'
import { getBudgetingScenariosRequest } from '../budgetingScenarios/actions'
import { getCompanyChartColorsRequest, getCompanySettingsRequest } from '../companySettings/actions'
import { ContextRequestParams } from '../actions'
import { PutRequestAction, REQUEST, PUT } from '../../middleware/types'
import { getAccountsRequest } from '../accounts/actions'
import { emptyGroupSettings, getGroupSettingsRequest } from '../groupSettings/actions'
import { isGroupCompany } from '../../../routes/utils'
import { getForecastsRequest } from '../forecasts/actions'
import { emptySelectedRowDimensionKeysAndData } from '../incomeStatenentDimensions/actions'
import { getGroupCompany } from '../groupCompany/actions'
import { emptyQuickFiltersData } from '../customReports/actions'
import { Store } from '../../types'
import { currentUserDimensionSelector, currentUserPermissionSelector } from '../../session/currentUser/selectors'

export const setContextCompany = (payload: Company): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const dimensions = currentUserDimensionSelector(store)
    const permissions = currentUserPermissionSelector(store)

    dispatch(resetContextFilter())
    dispatch(emptyQuickFiltersData())
    dispatch(emptyGroupSettings())
    dispatch(emptySelectedRowDimensionKeysAndData())

    const { contract, ...company } = payload
    dispatch(getCompanyChartColorsRequest(company.id))
    dispatch({ type: SET_CONTEXT_COMPANY, payload: company })
    contract && dispatch(setContextContract(contract))
    dispatch(getCompanySettingsRequest({ companyId: company.id }))
    dispatch(getFiscalYearsRequest(company.id))
    dispatch(getAccountsRequest(company.id))
    dispatch(getDimensionsRequest(company.id))
    dispatch(getBudgetingScenariosRequest(company.id))
    dispatch(getForecastsRequest(company.id))
    isGroupCompany() && dispatch(getGroupSettingsRequest(company.id))
    isGroupCompany() && dispatch(getGroupCompany(company.id))

    const dimension = dimensions.find(d => d.companyId === company.id)
    if (!permissions.find(p => p.label === 'read-root-dimension') && dimension) {
      dispatch(setDimensionsFilter([dimension.dimensionId]))
    }
  }
}

export const updateCompanyRequest = (
  { companyId, ...params }: ContextRequestParams,
  data: Company
): PutRequestAction => {
  return {
    type: REQUEST,
    payload: {
      method: PUT,
      data,
      url: `/api/companies/${companyId}`,
      params,
      success: setContextCompany
    },
    meta: {
      type: 'UPDATE_COMPANY',
      notification: true
    }
  }
}
