import { Location } from 'history'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { Route as RouterRoute, useLocation, useHistory, Switch, Redirect } from 'react-router-dom'
import i18next from 'i18next'
import Highcharts from 'highcharts'
import NotFound from './pages/error/NotFound'
import Loading from './pages/loading/Loading'
import { useLogin } from './redux/session/currentUser/hooks'
import { Route } from './routes/Route'
import { useAuthorizedRoutes, usePublicRoutes } from './routes/hooks'
import { authTokenSelector } from './redux/session/authentication/selectors'
import { currentUserHomepageSelector, currentUserSelector } from './redux/session/currentUser/selectors'
import reactGaConfigInit from './config/reactGaConfig'
import { useTheme } from './config/highcharts/Theme'
import 'normalize.css'
import './App.sass'
import { Company } from './types/company/Company'

function RenderRoutes({ routes, location }: { routes: Route[]; location: Location }) {
  const { pathname: currentPath } = location

  if (!routes) return null
  return (
    <>
      {routes.map((route: Route) => {
        if (!currentPath.includes(route.path)) return null
        if (route.routes) {
          return route.component ? (
            <route.component key={route.path} route={route}>
              <RenderRoutes routes={route.routes} location={location} />
            </route.component>
          ) : (
            <RenderRoutes key={route.path} routes={route.routes} location={location} />
          )
        }

        return (
          <React.Fragment key={route.path}>
            <RouterRoute
              exact={route.exact === undefined ? true : route.exact}
              path={route.path}
              component={route.component || NotFound}
            />
            {route.urlParam && (
              <RouterRoute
                exact={route.exact === undefined ? true : route.exact}
                path={`${route.path}/:${route.urlParam}`}
                component={route.component || NotFound}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

const App: React.FC = () => {
  Highcharts.setOptions(useTheme())
  const location = useLocation<{ from: any; company: Company }>()
  const history = useHistory()
  const isLoggedIn = useLogin()
  const token = useSelector(authTokenSelector)
  const authorizedRoutes = useAuthorizedRoutes()
  const publicRoutes = usePublicRoutes()
  const currentUser = useSelector(currentUserSelector)
  const homepage = useSelector(currentUserHomepageSelector)
  i18next.loadLanguages(['en', 'sv', 'fi'])
  reactGaConfigInit(history)

  useEffect(() => {
    const { id } = currentUser || {}
    id && ReactGA.set({ userId: id.toString() })
  }, [currentUser])

  if (token && !isLoggedIn) {
    return <Loading />
  }

  if (isLoggedIn) {
    if (location.pathname === '/') {
      history.push(homepage)
    }

    if (location?.state?.from) {
      return (
        <Redirect
          to={{
            pathname: location?.state?.from?.pathname || homepage
          }}
        />
      )
    }

    return (
      <Switch>
        <RenderRoutes routes={authorizedRoutes || []} location={location} />
      </Switch>
    )
  }

  if (location.pathname === '/') {
    return (
      <Redirect
        to={{
          pathname: '/user/login',
          state: { from: { pathname: '/' } }
        }}
      />
    )
  }

  if (!location.pathname.startsWith('/user')) {
    return (
      <Redirect
        to={{
          pathname: '/user/login',
          state: { from: location }
        }}
      />
    )
  }

  return (
    <Switch>
      <RenderRoutes routes={publicRoutes || []} location={location} />
    </Switch>
  )
}

export default App
