import { Account } from '../../../types/account/Account'
import {
  ADD_COMPANY_ACCOUNT,
  AccountsActionTypes,
  DELETE_COMPANY_ACCOUNT,
  SET_COMPANY_ACCOUNTS,
  UPDATE_COMPANY_ACCOUNT
} from './types'

const initialState: Account[] = []

const companyAccountsReducer = (state = initialState, action: AccountsActionTypes) => {
  switch (action.type) {
    case SET_COMPANY_ACCOUNTS:
      return action.payload
    case ADD_COMPANY_ACCOUNT:
      return [action.payload, ...state]
    case UPDATE_COMPANY_ACCOUNT:
      return state.map(account => {
        if (account.code === action.payload.code) {
          return { ...account, ...action.payload }
        }
        return account
      })
    case DELETE_COMPANY_ACCOUNT:
      return state.filter(account => account.code !== action.payload.code)
    default:
      return state
  }
}

export default companyAccountsReducer
