import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'

export const SET_PERSONNEL = 'SET_PERSONNEL'
export const UPDATE_PERSONNEL = 'UPDATE_PERSONNEL'

export interface SetPersonnelAction {
  type: typeof SET_PERSONNEL
  payload: KeyFigureFact[]
}

export interface UpdatePersonnelAction {
  type: typeof UPDATE_PERSONNEL
  payload: KeyFigureFact
}

export type PersonnelActionTypes = SetPersonnelAction | UpdatePersonnelAction
