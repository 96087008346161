import { FormulaFunction } from './types'

export const getFunctions = (source = '') => {
  let level = 0

  const fns = source.split('').reduce((acc, cur, i) => {
    const sub = source.slice(0, i + 1).split('')
    if (cur === '(') level += 1
    if (cur === ')') {
      level -= 1
    }

    if (!acc[level]) {
      acc[level] = []
    }

    if (sub[sub.length - 1] === '(') {
      const f = source.substring(0, i + 1).match(/[a-zA-Z_]+\(/g)
      if (f) {
        const ff = f.map(s => s.replace('(', ''))
        acc[level].push(ff?.pop())
      }
    }

    return acc
  }, {} as any)

  return fns
}

export const getCurrentFunction = (source = '', cursor: any) => {
  let level = 0
  const sub = source.slice(0, cursor.selectionStart).split('')

  const fns = sub.reduce((acc, cur) => {
    if (cur === '(') level += 1
    if (cur === ')') {
      acc[level] += 1
      level -= 1
    }

    if (!acc[level]) {
      acc[level] = 0
    }

    return acc
  }, {} as any)

  const allFunctions = getFunctions(source)
  const levelIndex = fns[level]

  if (
    allFunctions &&
    allFunctions !== undefined &&
    level !== undefined &&
    levelIndex !== undefined &&
    allFunctions[level] !== undefined
  ) {
    return allFunctions[level][levelIndex]
  }

  return ''
}

export const getCurrentArg = (source = '', cursor: any, currentFunction?: FormulaFunction) => {
  if (!currentFunction) return undefined
  let level = 0
  let array = false
  const sub = source.slice(0, cursor?.selectionStart).split('')

  const args = sub.reduce((acc, cur) => {
    if (cur === '[') array = true
    if (cur === ']') array = false
    if (cur === '(') level += 1
    if (cur === ')') {
      acc[level] = 0
      level -= 1
    }

    if (!acc[level]) {
      acc[level] = 0
    }

    if (cur === ',' && !array) acc[level] += 1
    return acc
  }, {} as any)

  return currentFunction.arguments?.[args[level]] || undefined
}

export const getPrevArgumentValue = (source = '', cursor: any): string | undefined => {
  const regex1 =
    /[a-zA-ZäöüÄÖÜß_\s1-90']+\(|\[[a-zA-ZäöüÄÖÜß_\s1-90~,']+(?<!\]])$|(\[[1-90,'\s]+])|([a-zA-ZäöüÄÖÜß_[\]1-90~']+(?=,))/g
  const str = source.substring(0, cursor.selectionStart + 1)

  const matches = str.matchAll(regex1)

  if (matches) {
    const lastOne = [...matches].filter(m => m[1] || m[2]).pop()
    return lastOne?.[1] || lastOne?.[2]
  }
  return undefined
}
