import { SetSessionAccountMapRulesAction, SET_SESSION_ACCOUNT_MAP_RULES } from './types'
import { DefaultAccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

const initialState: DefaultAccountMapRule[] = []

const sessionAccountMapRulesReducer = (state = initialState, action: SetSessionAccountMapRulesAction) => {
  switch (action.type) {
    case SET_SESSION_ACCOUNT_MAP_RULES:
      return action.payload
    default:
      return state
  }
}

export default sessionAccountMapRulesReducer
