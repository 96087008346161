import { IndustryCompany } from '../../../types/industry/Industry'

export const SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS = 'SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS'
export const SET_COMPETITOR_SELECTIONS = 'SET_COMPETITOR_SELECTIONS'
export const TOGGLE_COMPETITOR_SELECTION = 'TOGGLE_COMPETITOR_SELECTION'

export interface IndustryAndCompetitorComparisonPage {
  selectedKeyfigures: number[]
  selectedCompetitors: IndustryCompany[]
}

// Actions
export interface SetKeyfigureSelectionsAction {
  type: typeof SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS
  payload: number[]
}

export interface SetCompetitorSelectionsAction {
  type: typeof SET_COMPETITOR_SELECTIONS
  payload: IndustryCompany[]
}

export interface ToggleCompetitorSelectionAction {
  type: typeof TOGGLE_COMPETITOR_SELECTION
  payload: IndustryCompany
}

export type IndustryAndCompetitorComparisonPageActions = SetKeyfigureSelectionsAction

export type CompetitorSelectionActionTypes = SetCompetitorSelectionsAction | ToggleCompetitorSelectionAction
