import { BarChartOutlined, FundOutlined, PieChartOutlined, TableOutlined } from '@ant-design/icons'
import {
  DashboardItemPreference,
  DashboardItemPreferenceRepresentation,
  GraphType,
  MenuGroup,
  MonthlyComparableType,
  RepresentationType
} from './types'
import { CompanyDashboardItemType } from '../../../../types/dashboard/company/types'
import { RecordType } from '../../../../types/sales/sales'
import {
  customerAccountingSoftwares,
  productGroupAccountingSoftwares,
  productAccountingSoftwares,
  customerGroupAccountingSoftwares
} from '../../../../config/integrations/sales'
import KeyFigureChartFields from './DashboardItemEdit/form/keyFigure/KeyfigureChartFields'
import DashboardItemFields from './DashboardItemEdit/form/DasboardItemFieds'
import DashboardTopItemFields from './DashboardItemEdit/form/DashboardTopItemFields'
import SalesChartFields from './DashboardItemEdit/form/sales/SalesChartFields'
import SalesPieFields from './DashboardItemEdit/form/sales/SalesPieFields'
import SalesTopChartFields from './DashboardItemEdit/form/sales/SalesTopChartFields'
import { getProductsRequest } from '../../../../redux/context/products/actions'
import { getProductGroupsRequest } from '../../../../redux/context/productsGroups/actions'
import { getCustomerGroupsRequest } from '../../../../redux/context/customerGroups/actions'
import { getCustomersRequest } from '../../../../redux/context/customers/actions'
import KeyFigureTableFields from './DashboardItemEdit/form/keyFigure/KeyfigureTableItemFields'
import CashFlowChartItemFields from './DashboardItemEdit/form/cashFlow/CashFlowChartItemFields'
import InternalKeyFigureInfoFields from './DashboardItemEdit/form/keyFigure/InternalKeyFigureInfoFields'
import KeyFigureInfoFields from './DashboardItemEdit/form/keyFigure/KeyFigureInfoFields'
import { getFormulasRequest } from '../../../../redux/context/formulas/actions'
import SalesTableFields from './DashboardItemEdit/form/sales/SalesTableFields'
import InternalKeyFigurePieFields from './DashboardItemEdit/form/keyFigure/InternalKeyFigurePieFields'
import InternalKeyFigureTableFields from './DashboardItemEdit/form/keyFigure/InternalKeyFigureTableFields'
import InternalKeyFigureChartFields from './DashboardItemEdit/form/keyFigure/InternalKeyfigureChartFields'

const keyFigureRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  {
    type: RepresentationType.table,
    formFields: KeyFigureTableFields
  },
  {
    type: RepresentationType.chart,
    options: {
      type: GraphType.line
    },
    formFields: KeyFigureChartFields
  },
  {
    type: RepresentationType.info,
    options: {
      type: GraphType.line,
      comparableDataType: 'actual',
      monthlyComparableType: MonthlyComparableType.sameMonthLastYear
    },
    formFields: KeyFigureInfoFields
  }
]

const internalKeyFigureChartRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  {
    type: RepresentationType.chart,
    options: {
      type: GraphType.line
    },
    formFields: InternalKeyFigureChartFields
  },
  { type: RepresentationType.pie, formFields: InternalKeyFigurePieFields },
  {
    type: RepresentationType.info,
    options: {
      type: GraphType.line,
      comparableDataType: 'actual',
      monthlyComparableType: MonthlyComparableType.sameMonthLastYear
    },
    formFields: InternalKeyFigureInfoFields
  }
]

const salesRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  { type: RepresentationType.table, formFields: SalesTableFields },
  {
    type: RepresentationType.chart,
    options: {
      type: GraphType.line
    },
    formFields: SalesChartFields
  },
  { type: RepresentationType.pie, formFields: SalesPieFields }
]

const salesTopRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  { type: RepresentationType.table, formFields: DashboardTopItemFields },
  {
    type: RepresentationType.chart,
    options: {
      type: GraphType.line
    },
    formFields: SalesTopChartFields
  },
  { type: RepresentationType.pie, formFields: DashboardTopItemFields }
]

const tableRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  { type: RepresentationType.table, formFields: DashboardItemFields }
]

const internalKeyFiguretableRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  { type: RepresentationType.table, formFields: InternalKeyFigureTableFields }
]

const cashFlowChartRepresentationOptions: DashboardItemPreferenceRepresentation[] = [
  {
    type: RepresentationType.chart,
    options: {
      type: GraphType.line
    },
    formFields: CashFlowChartItemFields
  }
]

export const dashboardItemPreferences: DashboardItemPreference[] = [
  // Tulos & Tase
  {
    name: 'INCOME_STATEMENT',
    menuGroup: MenuGroup.FINANCIAL_STATEMENTS,
    translation: 'financialStatementsPage:incomeStatement',
    type: CompanyDashboardItemType.INCOME_STATEMENT,
    params: {
      representation: tableRepresentationOptions
    }
  },
  {
    name: 'BALANCE_SHEET',
    menuGroup: MenuGroup.FINANCIAL_STATEMENTS,
    translation: 'financialStatementsPage:balanceSheet',
    type: CompanyDashboardItemType.BALANCE_SHEET,
    params: {
      representation: tableRepresentationOptions
    }
  },
  // Tunnusluvut
  {
    name: 'PROFITABILITY_ABSOLUTE',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:PROFITABILITY_ABSOLUTE',

    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'PROFITABILITY_PERCENTAGE',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:PROFITABILITY_PERCENTAGE',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [12, 13, 14, 15, 16, 41, 42, 43, 59, 60],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'PROFITABILITY_ROA',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:PROFITABILITY_ROA',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'LIQUIDITY',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:LIQUIDITY',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [44, 45, 46, 47, 48],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'SOLVENCY',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:SOLVENCY',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [49, 50, 51, 52],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'WORKING_CAPITAL',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:WORKING_CAPITAL',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [53, 54, 55, 56, 57],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'CASH',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:CASH',
    type: CompanyDashboardItemType.KEY_FIGURES,
    params: {
      keyFigureIds: [58],
      representation: keyFigureRepresentationOptions
    }
  },
  {
    name: 'INTERNAL_KEYFIGURE_ABSOLUTE',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:internal-keyfigures-absolute',
    type: CompanyDashboardItemType.INTERNAL_KEY_FIGURES,
    contextRequest: getFormulasRequest,
    params: {
      selectedType: 'absolute',
      representation: internalKeyFigureChartRepresentationOptions
    }
  },
  {
    name: 'INTERNAL_KEYFIGURE_PERCENTAGE',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:internal-keyfigures-percentage',
    type: CompanyDashboardItemType.INTERNAL_KEY_FIGURES,
    contextRequest: getFormulasRequest,
    params: {
      selectedType: 'percentage',
      representation: internalKeyFigureChartRepresentationOptions
    }
  },
  {
    name: 'INTERNAL_KEYFIGURE_RATIO',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:internal-keyfigures-ratio',
    type: CompanyDashboardItemType.INTERNAL_KEY_FIGURES,
    contextRequest: getFormulasRequest,
    params: {
      selectedType: 'ratio',
      representation: internalKeyFigureChartRepresentationOptions
    }
  },
  {
    name: 'INTERNAL_KEYFIGURE',
    menuGroup: MenuGroup.KEY_FIGURES,
    translation: 'keyFigurePage:INTERNAL_KEY_FIGURES',
    type: CompanyDashboardItemType.INTERNAL_KEY_FIGURES,
    contextRequest: getFormulasRequest,
    params: {
      selectedType: RepresentationType.table,
      representation: internalKeyFiguretableRepresentationOptions
    }
  },
  // Kassavirta, Rahoituslaskelma
  {
    name: 'RAHOITUSLASKELMA_INDIRECT',
    menuGroup: MenuGroup.CASH_FLOW,
    translation: 'cashFlowPage:RAHOITUSLASKELMA_INDIRECT',
    type: CompanyDashboardItemType.CASH_FLOW_STATEMENT,
    params: {
      type: 'RAHOITUSLASKELMA_INDIRECT',
      selectedGraph: 'DEFAULT',
      representation: [...tableRepresentationOptions, ...cashFlowChartRepresentationOptions]
    }
  },
  {
    name: 'LIIKETOIMINNAN_RAHAVIRTA',
    menuGroup: MenuGroup.CASH_FLOW,
    translation: 'cashFlowPage:LIIKETOIMINNAN_RAHAVIRTA',
    type: CompanyDashboardItemType.CASH_FLOW_STATEMENT,
    params: {
      type: 'RAHOITUSLASKELMA_INDIRECT',
      selectedGraph: 'LIIKETOIMINNAN_RAHAVIRTA',
      representation: cashFlowChartRepresentationOptions
    }
  },
  {
    name: 'INVESTOINTIEN_RAHAVIRTA',
    menuGroup: MenuGroup.CASH_FLOW,
    translation: 'cashFlowPage:INVESTOINTIEN_RAHAVIRTA',
    type: CompanyDashboardItemType.CASH_FLOW_STATEMENT,
    params: {
      type: 'RAHOITUSLASKELMA_INDIRECT',
      selectedGraph: 'INVESTOINTIEN_RAHAVIRTA',
      representation: cashFlowChartRepresentationOptions
    }
  },
  {
    name: 'RAHOITUKSEN_RAHAVIRTA',
    menuGroup: MenuGroup.CASH_FLOW,
    translation: 'cashFlowPage:RAHOITUKSEN_RAHAVIRTA',
    type: CompanyDashboardItemType.CASH_FLOW_STATEMENT,
    params: {
      type: 'RAHOITUSLASKELMA_INDIRECT',
      selectedGraph: 'RAHOITUKSEN_RAHAVIRTA',
      representation: cashFlowChartRepresentationOptions
    }
  },
  // Sales
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: productAccountingSoftwares },
    translation: 'global:productsAbsolute',
    request: getProductsRequest,
    type: CompanyDashboardItemType.SALES,
    params: {
      record: RecordType.products,
      representation: salesRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: productAccountingSoftwares },
    translation: 'global:topProductsAbsolute',
    type: CompanyDashboardItemType.SALES,
    params: {
      type: 'TOP',
      quantity: 30,
      record: RecordType.products,
      representation: salesTopRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: productGroupAccountingSoftwares },
    translation: 'global:productGroupsAbsolute',
    request: getProductGroupsRequest,
    type: CompanyDashboardItemType.SALES,
    params: {
      record: RecordType.productGroups,
      representation: salesRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: productGroupAccountingSoftwares },
    translation: 'global:topProductGroupsAbsolute',
    type: CompanyDashboardItemType.SALES,
    params: {
      type: 'TOP',
      quantity: 30,
      record: RecordType.productGroups,
      representation: salesTopRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: customerGroupAccountingSoftwares },
    translation: 'global:customerGroupsAbsolute',
    request: getCustomerGroupsRequest,
    type: CompanyDashboardItemType.SALES,
    params: {
      record: RecordType.customerGroups,
      representation: salesRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: customerGroupAccountingSoftwares },
    translation: 'global:topCustomerGroupsAbsolute',
    type: CompanyDashboardItemType.SALES,
    params: {
      type: 'TOP',
      quantity: 30,
      record: RecordType.customerGroups,
      representation: salesTopRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: customerAccountingSoftwares },
    translation: 'global:salesCustomersAbsolute',
    request: getCustomersRequest,
    type: CompanyDashboardItemType.SALES,
    params: {
      record: RecordType.customers,
      representation: salesRepresentationOptions
    }
  },
  {
    name: 'SALES',
    menuGroup: MenuGroup.SALES,
    authority: { accountingSoftware: customerAccountingSoftwares },
    translation: 'global:topSalesCustomersAbsolute',
    type: CompanyDashboardItemType.SALES,
    params: {
      type: 'TOP',
      quantity: 30,
      record: RecordType.customers,
      representation: salesTopRepresentationOptions
    }
  }
]

export const representationIcons: {
  [key in DashboardItemPreferenceRepresentation['type']]: any
} = {
  table: TableOutlined,
  chart: BarChartOutlined,
  pie: PieChartOutlined,
  info: FundOutlined
}
