import { createSelector } from 'reselect'
import { contextCompanySelector } from '../../context/company/selectors'
import { Store } from '../../types'

const budgetingConfigSelector = (store: Store) => store.config.budgeting

export const socialSecurityExpenseConfigSelector = createSelector(
  contextCompanySelector,
  budgetingConfigSelector,
  (company, config) => company && config.socialSecurityExpenseConfig[company.country][company.form]
)
