import React from 'react'
import { useSelector } from 'react-redux'
import { dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import {
  multiPurposeActiveDashboardSelector,
  multiPurposeDashboardPageSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import MultiPurposeDashboard from './MultiPurposeDashboard'

const MultiPurposeDashboardDefault: React.FC = () => {
  const dimensionList = useSelector(dimensionListSelector)
  const { keyfigureStatements } = useSelector(multiPurposeDashboardPageSelector)
  const activeDashboardData = useSelector(multiPurposeActiveDashboardSelector)

  return (
    <MultiPurposeDashboard
      keyfigureStatements={keyfigureStatements}
      selectedKeyfigureIds={activeDashboardData?.keyFigures || []}
      selectedInternalKeyfigureIds={activeDashboardData?.formulas || []}
      allRows={dimensionList}
      selectedRows={activeDashboardData?.dimensions}
    />
  )
}
export default MultiPurposeDashboardDefault
