import * as jwt from 'jsonwebtoken'
import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { logout } from '../session/authentication/actions'
import { LOGOUT, SET_TOKEN } from '../session/authentication/types'
import { Store } from '../types'
import { REQUEST } from './types'

const token: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: Action) => {
    const store: Store = getState()
    const skip = [LOGOUT, REQUEST, SET_TOKEN]

    const t = store?.session?.authentication?.token

    if (!skip.includes(action.type) && t) {
      try {
        process.env.REACT_APP_SECRET && jwt.verify(t, process.env.REACT_APP_SECRET)
      } catch (error) {
        dispatch(logout())
      }
    }
    return next(action)
  }

export default token
