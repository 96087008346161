import React, { useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons'
import { Button, Select, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { VariableType } from '../../../../components/Table/types'
import { Loan } from '../../../../types/loan/Loan'
import { transformFieldData } from '../../../../utils/form'
import { formatValueByType } from '../../../../utils/helpers'
import { investmentsPageSelector } from '../../../../redux/pages/investments/selectors'
import { dimensionListSelector } from '../../../../redux/context/dimensions/selectors'
import { Purpose } from '../../../../types/purpose/Purpose'
import { updateExistingLoanRequest, updateNewLoanRequest } from '../../../../redux/context/loans/actions'
import { createExistingLoanRequest, deleteLoanRequest } from '../../../../redux/context/loans/existing/actions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import EditableRowTable from '../../../../components/Table/EditableRowTable'
import DimensionSelect from '../../../../components/Dimension/DimensionSelect'
import PurposesSelect from './PurposesSelect'
import { LoanRepaymentMethod } from '../../../../redux/entities/loanRepaymentMethods/types'
import { loanRepaymentMethodsSelector } from '../../../../redux/entities/loanRepaymentMethods/selectors'
import { AppDispatch } from '../../../../redux/store'
import AccountSelect from '../../../../components/inputs/AccountSelect'
import { normalizedConfigSelector } from '../../../../redux/config/selectors'
import { liabilityMappingSelector } from '../../../../redux/entities/liabilityMapping/selectors'

interface LoansTableProps {
  loans: Loan[]
  toggleRepaymentSchedule: (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => void
  toggleInterestRate: (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => void
  balanceSheetRowId: number
}

const transform = {
  nextInstalmentDate: (val: string) => dayjs(val),
  purposes: (val: Purpose[]) => val.map(p => p.name)
}

const infoTooltip = (text: string) => (
  <Tooltip placement="top" title={text}>
    <InfoCircleOutlined style={{ verticalAlign: 'middle', marginLeft: 8 }} />
  </Tooltip>
)

const LoansTable: React.FC<LoansTableProps> = ({
  loans,
  toggleRepaymentSchedule,
  toggleInterestRate,
  balanceSheetRowId
}) => {
  const { date } = useSelector(investmentsPageSelector)
  const location = useLocation()
  const loanRepaymentMethods = useSelector(loanRepaymentMethodsSelector)
  const {
    financialStatement: { financialExpenseRowId }
  } = useSelector(normalizedConfigSelector)
  const liabilityMapping = useSelector(liabilityMappingSelector)
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const loanData = useMemo(
    () =>
      loans
        .sort((a: Loan, b: Loan) => a.id - b.id)
        .map(l => {
          return {
            ...transformFieldData(l, transform),
            removable: l?.id && location.pathname.includes('existing') && !budgetingScenario?.isLocked
          }
        }),
    [loans]
  )
  const dimensions = useSelector(dimensionListSelector)

  const columns: any = [
    {
      title: t('loans:loan-amount'),
      dataIndex: 'value',
      key: 'value',
      align: 'right',
      editable: true,
      type: 'number',
      fixed: 'left',
      width: 100,
      sorter: { compare: (a: Loan, b: Loan) => a.value - b.value },
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('loans:next-instalment-date'),
      dataIndex: 'nextInstalmentDate',
      align: 'right',
      width: 120,
      editable: true,
      type: 'date',
      sorter: {
        compare: (a: Loan, b: Loan) => dayjs(a.nextInstalmentDate).diff(b.nextInstalmentDate)
      },
      render: (value: Dayjs) => value.format('DD.MM.YYYY')
    },
    {
      title: t('loans:financialExpensesAccountCode'),
      dataIndex: 'financialExpensesAccountCode',
      key: 'financialExpensesAccountCode',
      width: 105,
      editable: true,
      align: 'right',
      type: 'custom',
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: financialExpenseRowId },
      sorter: {
        compare: (a: Loan, b: Loan) => a.financialExpensesAccountCode - b.financialExpensesAccountCode
      }
    },
    {
      title: t('loans:longTermAccountCode'),
      dataIndex: 'longTermAccountCode',
      key: 'longTermAccountCode',
      width: 105,
      editable: true,
      align: 'right',
      type: 'custom',
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: [balanceSheetRowId] },
      sorter: {
        compare: (a: Loan, b: Loan) => a.longTermAccountCode - b.longTermAccountCode
      }
    },
    {
      title: t('loans:shortTermAccountCode'),
      dataIndex: 'shortTermAccountCode',
      key: 'shortTermAccountCode',
      width: 105,
      editable: true,
      align: 'right',
      type: 'custom',
      inputField: AccountSelect,
      inputFieldProps: {
        balanceSheetRowIds: [liabilityMapping?.[balanceSheetRowId]]
      },
      sorter: {
        compare: (a: Loan, b: Loan) => {
          return a.shortTermAccountCode - b.shortTermAccountCode
        }
      }
    },
    {
      title: (
        <div>
          {t('loans:term')}
          {infoTooltip(t('loans:term/info'))}
        </div>
      ),
      align: 'center',
      editable: true,
      children: [
        {
          title: t('global:year', { count: 2 }),
          dataIndex: 'termYears',
          align: 'right',
          width: 100,
          editable: true,
          type: 'number',
          sorter: { compare: (a: Loan, b: Loan) => a.termYears - b.termYears }
        },
        {
          title: t('global:month', { count: 2 }),
          dataIndex: 'termMonths',
          align: 'right',
          width: 100,
          editable: true,
          type: 'number',
          sorter: { compare: (a: Loan, b: Loan) => a.termMonths - b.termMonths }
        }
      ]
    },
    {
      title: (
        <div>
          {t('loans:repayment-schedule')}
          {infoTooltip(t('loans:repayment-program/info'))}
        </div>
      ),
      align: 'center',
      editable: true,
      children: [
        {
          title: t('global:year', { count: 2 }),
          dataIndex: 'repaymentProgramYears',
          align: 'right',
          width: 100,
          editable: true,
          type: 'number',
          sorter: {
            compare: (a: Loan, b: Loan) => a.repaymentProgramYears - b.repaymentProgramYears
          }
        },
        {
          title: t('global:month', { count: 2 }),
          dataIndex: 'repaymentProgramMonths',
          align: 'right',
          width: 100,
          editable: true,
          type: 'number',
          sorter: {
            compare: (a: Loan, b: Loan) => a.repaymentProgramMonths - b.repaymentProgramMonths
          }
        }
      ]
    },
    {
      title: t('loans:yearly-instalments'),
      dataIndex: 'yearlyInstalments',
      align: 'right',
      width: 100,
      editable: true,
      type: 'number',
      sorter: {
        compare: (a: Loan, b: Loan) => a.yearlyInstalments - b.yearlyInstalments
      }
    },
    {
      title: (
        <div>
          {t('loans:instalment-free-period')}
          {infoTooltip(t('loans:instalment-free-period/info'))}
        </div>
      ),
      dataIndex: 'instalmentFreePeriod',
      align: 'right',
      width: 100,
      editable: true,
      type: 'number',
      sorter: {
        compare: (a: Loan, b: Loan) => a.instalmentFreePeriod - b.instalmentFreePeriod
      }
    },
    {
      title: t('loans:loan-repayment-method'),
      dataIndex: 'loanRepaymentMethodId',
      align: 'right',
      width: 110,
      editable: true,
      type: 'custom',
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
        <Select ref={ref} {...props} showSearch optionFilterProp="children">
          {loanRepaymentMethods.map((loanRepaymentMethod: LoanRepaymentMethod) => {
            return (
              <Select.Option key={loanRepaymentMethod.id} value={loanRepaymentMethod.id}>
                {t(`loanRepaymentMethod:${loanRepaymentMethod.id}`)}
              </Select.Option>
            )
          })}
        </Select>
      )),
      sorter: {
        compare: (a: Loan, b: Loan) => a.instalmentFreePeriod - b.instalmentFreePeriod
      },
      render: (val: number) => t(`loanRepaymentMethod:${val}`)
    },
    {
      title: t('loans:interest-rate'),
      dataIndex: 'interestRate',
      align: 'right',
      width: 100,
      editable: true,
      type: 'percent',
      sorter: { compare: (a: Loan, b: Loan) => a.interestRate - b.interestRate },
      render: (value: number) => formatValueByType(value, VariableType.percentage, { maximumFractionDigits: 4 })
    },
    {
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      align: 'right',
      width: 150,
      editable: true,
      type: 'custom',
      inputField: DimensionSelect,
      render: (val: string) => {
        const di = dimensions?.find(d => d.dimensionId === val)
        return di?.name
      }
    },
    {
      title: t('loans:purposes'),
      dataIndex: 'purposes',
      align: 'right',
      editable: true,
      type: 'custom',
      fixed: 'right',
      inputField: PurposesSelect,
      render: (value: Purpose[]) => {
        return value?.join(', ')
      }
    },
    {
      title: t('loans:repayment-schedule'),
      dataIndex: 'repayment-schedule',
      key: 'repayment-schedule',
      width: 65,
      align: 'center',
      fixed: 'right',
      render: (value: any, record: Loan) => (
        <Tooltip placement="top" title={t('loans:repayment-schedule')}>
          <Button
            style={{ marginRight: '8px' }}
            icon={<OrderedListOutlined />}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              return toggleRepaymentSchedule(e, record)
            }}
          />
        </Tooltip>
      )
    },
    {
      title: t('loans:changing-interest'),
      dataIndex: 'interestRates',
      key: 'interestRates',
      width: 70,
      align: 'center',
      fixed: 'right',
      render: (value: any, record: Loan) => (
        <Tooltip placement="top" title={t('loans:changing-interest')}>
          <Button
            style={{ marginRight: '8px' }}
            icon={<OrderedListOutlined />}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              return toggleInterestRate(e, record)
            }}
          />
        </Tooltip>
      )
    }
  ]

  const handleSubmit = (fieldsValue: Loan) => {
    const values = {
      ...fieldsValue,
      nextInstalmentDate: dayjs(fieldsValue.nextInstalmentDate).format('YYYY-MM-DD'),
      balanceSheetRowId: fieldsValue.balanceSheetRowId,
      value: fieldsValue.value,
      interestRate: fieldsValue.interestRate,
      purposes: (fieldsValue.purposes as unknown as string[])?.map((p: string) => {
        return { name: p }
      }) as Purpose[]
    }

    if (location.pathname.includes('existing')) {
      // Updating existin loan
      dispatch(
        updateExistingLoanRequest(
          companyId,
          values?.id,
          values,
          budgetingScenario && budgetingScenario.id,
          dayjs(date).format('YYYY-MM-DD')
        )
      )
    } else {
      // Updating existin loan
      dispatch(
        updateNewLoanRequest(
          companyId,
          values?.id,
          values,
          budgetingScenario && budgetingScenario.id,
          dayjs(date).format('YYYY-MM-DD')
        )
      )
    }
    if (fieldsValue?.isExisting && !fieldsValue?.id) {
      // Creating new existing loan
      dispatch(
        createExistingLoanRequest(
          companyId,
          budgetingScenario && budgetingScenario.id,
          values,
          dayjs(date).format('YYYY-MM-DD')
        )
      )
    }
  }

  const handleRowDelete = useMemo(() => {
    if (location.pathname.includes('existing')) {
      return (loan: Loan) => {
        dispatch(
          deleteLoanRequest(
            companyId,
            loan.id,
            budgetingScenario && budgetingScenario.id,
            dayjs(date).format('YYYY-MM-DD')
          )
        )
      }
    }
    return undefined
  }, [location.pathname])

  return (
    <EditableRowTable<Loan>
      scroll={{ x: 1900 }}
      size="small"
      rowKey="id"
      bordered
      dataSource={loanData}
      columns={columns.filter((c: any) => {
        if (location.pathname.includes('new')) {
          return c.dataIndex !== 'purposes'
        }
        return true
      })}
      handleSave={handleSubmit}
      handleRowDelete={handleRowDelete}
    />
  )
}

export default LoansTable
