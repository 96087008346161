import { Form, Select, Input, FormInstance } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReportDataType } from '../../redux/context/reports/types'
import { useBackend } from '../../services/backend'
import { OneDriveSheet } from '../../pages/settings/company/integrations/types'
import { budgetingScenariosAndForecastsSelector } from '../../redux/context/budgetingScenarios/selectors'

interface OneDriveFormProps {
  form: FormInstance
  type: 'budget' | 'external' | 'actuals'
  filterLockedScenarios?: boolean
}

const OneDriveForm: React.FC<OneDriveFormProps> = ({ form, type, filterLockedScenarios }: OneDriveFormProps) => {
  const { t } = useTranslation()
  const [sheetData, setSheetData] = useState<OneDriveSheet[]>()
  const [url, setUrl] = useState<string>()
  const [error, setError] = useState(false)

  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)

  const sheetDataRequest = useBackend(`/onedrive/sheets`, process.env.REACT_APP_INTEGRATION_URL)

  let forecastsAndScenarios = budgetingScenariosAndForecasts
  if (filterLockedScenarios) {
    forecastsAndScenarios = forecastsAndScenarios.filter(f => !f.isLocked)
  }

  const sheetDataHandler = () => {
    url &&
      sheetDataRequest
        .get({ body: { params: { url } } })
        .then((response: OneDriveSheet[]) => {
          setError(false)
          setSheetData(response)
        })
        .catch(() => {
          setError(true)
          setSheetData(undefined)
        })
  }

  useEffect(sheetDataHandler, [url])

  return (
    <>
      <Form.Item initialValue={type} hidden name="type">
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        preserve
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        style={{ marginTop: 10 }}
        label={t('integrationsPage:url')}
      >
        <Input onChange={(e: any) => setUrl(e.target.value)} />
      </Form.Item>
      <Form.Item
        label={t('integrationsPage:sheet')}
        name="sheet"
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        hasFeedback={
          form.getFieldValue('sheet') &&
          sheetData &&
          !sheetData.map(sheetsData => sheetsData.name).includes(form.getFieldValue('sheet'))
        }
        validateStatus={
          form.getFieldValue('sheet') &&
          sheetData &&
          !sheetData.map(sheetsData => sheetsData.name).includes(form.getFieldValue('sheet'))
            ? 'warning'
            : undefined
        }
      >
        <Select
          loading={sheetDataRequest.loading}
          showSearch
          optionFilterProp="children"
          disabled={!form.getFieldValue('url') || error}
        >
          {sheetData &&
            sheetData.map(sheetsData => (
              <Select.Option key={sheetsData.name} value={sheetsData.name}>
                {sheetsData.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      {type === 'external' && (
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('integrationsPage:code')}
        >
          <Input disabled={!form.getFieldValue('url') || error} />
        </Form.Item>
      )}
      <Form.Item
        name="dataType"
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        label={t('global:type')}
        hidden={type !== 'external'}
        initialValue={type === 'budget' ? ReportDataType.budget : ReportDataType.actuals}
      >
        <Select disabled={!form.getFieldValue('url') || error} optionFilterProp="children">
          {[ReportDataType.actuals, ReportDataType.budget].map(dataType => (
            <Select.Option key={dataType} value={dataType}>
              {dataType === ReportDataType.budget
                ? `${t(`global:${ReportDataType.budget}`)} / ${t(`global:${ReportDataType.forecast}`)}`
                : t(`global:${dataType}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {type !== 'actuals' && (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues?.dataType !== currentValues?.dataType
          }}
        >
          {() =>
            form.getFieldValue('dataType') !== ReportDataType.actuals && (
              <Form.Item
                name="budgetingScenarioId"
                label={`${t('global:scenario')} / ${t(`global:${ReportDataType.forecast}`)}`}
              >
                <Select allowClear optionFilterProp="children">
                  {forecastsAndScenarios.map(scenario => (
                    <Select.Option key={scenario.id} value={scenario.id}>
                      {scenario.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>
      )}
    </>
  )
}

export default OneDriveForm
