import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import { Row } from 'antd'
import _ from 'lodash'
import { Loading } from '../../../../../components/Misc/Loading'
import { salesSelector } from '../../../../../redux/context/reports/selectors'
import {
  CompanyDashboardItem,
  defaultCompanyDashboardChartRepresentationOptions
} from '../../../../../types/dashboard/company/types'
import { errorSelector } from '../../../../../redux/error/selectors'
import ReportTable from '../../../../../components/Table/ReportTable'
import KeyFigureChart from '../../../../reporting/keyFigures/components/keyFigureChart/KeyFigureChart'
import { useTopReportData } from '../../../../../utils/hooks'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { PieChart } from '../../../../../components/Chart/PieChart'
import { currentFiscalYearSelector } from '../../../../../redux/context/fiscalYears/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { getPieSeriesData } from '../utils'
import { RecordType } from '../../../../../types/sales/sales'
import ErrorComponent from '../../../../../components/ErrorComponent/ErrorComponent'
import { VariableType } from '../../../../../components/Table/types'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import { sortedColumnGroupSelector } from '../../../../../redux/context/periodGroups/selectors'
import PeriodGroupTreeSelect from '../../../../../components/PeriodGroup/PeriodGroupTreeSelect'
import { Report } from '../../../../../redux/context/reports/types'

interface SalesProps {
  item: CompanyDashboardItem
  width: number
  height: number
}

export const Sales: React.FC<SalesProps> = ({ item, height, width }: SalesProps) => {
  const salesData = useSelector(salesSelector)
  const {
    params,
    params: { record }
  } = item
  const { [`sales${_.upperFirst(record.toString())}`]: loading } = useSelector(loadingSelector)
  const { formula: formulaLoading } = useSelector(loadingSelector)
  const errors = useSelector(errorSelector)
  const { periodGroup, periodGroups } = useSelector(filtersSelector)
  const currentFiscalYear = useSelector(currentFiscalYearSelector)
  const data = salesData[record as RecordType]
  const [selectedPeriodGroup, setSelectedPeriodGroup] = useState<FiscalYear | PeriodGroup | undefined>(
    periodGroups?.[0] || currentFiscalYear
  )
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(selectedPeriodGroup?.endDate))
  const [showPeriods, setShowPeriods] = useState<boolean>(!!periodGroup)
  const topData = useTopReportData(data, item.params.quantity)
  const sortedColumnGroup = useSelector(sortedColumnGroupSelector)
  const report = item.params.type === 'TOP' ? topData : (data || []).filter(row => (params.ids || []).includes(row.id))

  useEffect(() => {
    const filteredPeriodGroups = (periodGroups?.length && periodGroups?.filter((f: PeriodGroup) => f.id > 0)) || null
    setSelectedPeriodGroup(filteredPeriodGroups?.[0] || currentFiscalYear)
    setSelectedDate(dayjs(filteredPeriodGroups?.[0]?.endDate || currentFiscalYear?.endDate))
    setShowPeriods(false)
  }, [periodGroups, salesData])

  const selectedPeriodGroupOrPeriod = (e: string | number) => {
    if (Number(e)) {
      setShowPeriods(false)
      setSelectedPeriodGroup(Object(sortedColumnGroup).find((f: FiscalYear | PeriodGroup) => f.id === e))
      setSelectedDate(dayjs(Object(sortedColumnGroup).find((f: FiscalYear | PeriodGroup) => f.id === e).endDate))
    } else {
      setShowPeriods(true)
      const monthSelected = e as string
      const date = monthSelected.split(' / ')
      setSelectedPeriodGroup(undefined)
      setSelectedDate(dayjs(`${date[1]}-${date[0]}-01`))
    }
  }

  if (loading || formulaLoading) return <Loading />
  if (!params) return null

  // const reportAmountToValue = (dataToChange: any) => {
  //   const newReport = []
  //   if (dataToChange) {
  //     for (const rep of dataToChange) {
  //       for (const dataType of [ReportDataType.actuals, ReportDataType.budget, ReportDataType.forecast]) {
  //         if (rep[dataType]) {
  //           const newRow = []
  //           for (const rowActual of rep[dataType]) {
  //             newRow.push({ ...rowActual, value: Number(rowActual.amount) || 0 })
  //           }
  //           rep.actuals = newRow
  //           newReport.push(rep)
  //         }
  //       }
  //     }
  //   }

  //   return newReport
  // }

  switch (params.representation.type) {
    case 'chart':
      return (
        <KeyFigureChart
          chartType={(params.representation.options || defaultCompanyDashboardChartRepresentationOptions).type}
          report={report as Report}
          height={height}
          width={width}
          error={errors?.[record]}
        />
      )
    case 'pie':
      return errors?.[record] ? (
        <div style={{ padding: 16 }}>
          <ErrorComponent error={errors?.[record]} />
        </div>
      ) : (
        <>
          <Row style={{ padding: 8 }} justify="center" align="middle">
            <PeriodGroupTreeSelect
              showPeriods={showPeriods}
              selectedDate={selectedDate}
              selectedPeriodGroup={selectedPeriodGroup}
              selectedPeriodGroupOrPeriod={selectedPeriodGroupOrPeriod}
              sortedColumnGroup={sortedColumnGroup}
            />
          </Row>
          <PieChart
            report={report}
            drilldown={false}
            titleText=""
            currentPeriodGroup={selectedPeriodGroup}
            percentage={report.every(row => row.type === VariableType.percentage)}
            seriesData={getPieSeriesData(
              data,
              report,
              selectedPeriodGroup,
              item.params.type,
              selectedDate && new Date(selectedDate.format('YYYY-MM-DD'))
            )}
            date={selectedDate}
            seriesName={item.name}
            height={height}
            width={width}
          />
        </>
      )
    case 'table':
      return <ReportTable toolbar={{ csvExport: false }} report={report} yScroll={height} error={errors?.[record]} />

    default:
      return null
  }
}
