import { BackendRequest } from '../../../../../services/backend'
import { CustomReportData } from './CustomReportOrder'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../../redux/store'
import { generateStructureData } from './utils'
import {
  addCustomReportGroup,
  updateCustomReportGroups,
  updateCustomReports
} from '../../../../../redux/context/customReports/actions'
import { CustomReport, CustomReportGroup } from '../../../../../redux/context/customReports/types'

export const addGroupHandler = async (
  groupData: Partial<CustomReportData>,
  addCustomReportGroupRequest: BackendRequest,
  companyId: string,
  dispatch: AppDispatch
) => {
  return addCustomReportGroupRequest
    .post({
      urlParams: { companyId },
      body: {
        data: groupData
      }
    })
    .then((response: CustomReportData) => {
      dispatch(
        notificationAction({
          type: 'success',
          message: 'ADD_CUSTOM_REPORT_GROUP_SUCCESS'
        })
      )
      dispatch(addCustomReportGroup(response as unknown as CustomReportGroup))
      return { ...response, title: response.name }
    })
    .catch(() => {
      dispatch(
        notificationAction({
          type: 'error',
          message: 'ERROR'
        })
      )
    })
}

export const saveStructureData = (
  structureData: CustomReportData[],
  changedRowsData: number[],
  companyId: string,
  dispatch: AppDispatch,
  structureRequest: BackendRequest
) => {
  const structuredData = generateStructureData(structureData, changedRowsData)
  structureRequest
    .put({
      urlParams: { companyId },
      body: {
        data: structuredData
      }
    })
    .then(() => {
      const repGroups = structuredData.filter(c => c.type === 'CustomReportGroup')
      dispatch(updateCustomReportGroups(repGroups as unknown as CustomReportGroup[]))
      const reports = structuredData.filter(c => c.type !== 'CustomReportGroup')
      dispatch(updateCustomReports(reports as unknown as CustomReport[]))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'UPDATE_CUSTOM_REPORT_STRUCTURE_SUCCESS'
        })
      )
    })
    .catch(() => {
      dispatch(
        notificationAction({
          type: 'error',
          message: 'UPDATE_CUSTOM_REPORT_STRUCTURE_ERROR'
        })
      )
    })
}
