import { combineReducers } from 'redux'
import sessionAccountMapReducer from './accountMapRules/reducer'
import authenticationReducer from './authentication/reducer'
import sessionContractReducer from './contract/reducer'
import currentUserReducer from './currentUser/reducer'
import deliverySubscriptionsReducer from './deliverySubscriptions/reducer'
import cashFlowSelectedKeysReducer from './cashFlow/reducer'

export default combineReducers({
  authentication: authenticationReducer,
  currentUser: currentUserReducer,
  contract: sessionContractReducer,
  accountMapRules: sessionAccountMapReducer,
  deliverySubscriptions: deliverySubscriptionsReducer,
  cashFlowSelectedKeys: cashFlowSelectedKeysReducer
})
