import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Input, Typography, Form } from 'antd'
import React, { useEffect } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { loginErrorSelector } from '../../../redux/session/authentication/selectors'
import useLogin from './hooks'
import { loadingSelector } from '../../../redux/loading/selectors'
import { primaryColor } from '../utils'
import './_Login.sass'
import { AppDispatch } from '../../../redux/store'

const Login: React.FC = () => {
  const { form, handleSubmit } = useLogin()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { login: loginLoading } = useSelector(loadingSelector) || {}
  const error = useSelector(loginErrorSelector)

  useEffect(() => {
    // clear error on page refresh
    dispatch({ type: 'LOGIN_ERROR', payload: undefined })
  }, [dispatch])

  return (
    <Form form={form} onFinish={handleSubmit} className="login-form">
      <Form.Item name="email">
        <Input
          className="login-input email"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="email"
          placeholder={t('global:email')}
          type="email"
        />
      </Form.Item>
      <Form.Item name="password">
        <Input
          className="login-input pass"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="password"
          type="password"
          placeholder={t('global:password')}
        />
      </Form.Item>
      {error && (
        <div style={{ marginBottom: 16 }}>
          <Typography.Text type="danger">{i18next.t(`description:${error.message}`, 'Error')}</Typography.Text>
        </div>
      )}
      <Form.Item>
        <Button
          loading={loginLoading}
          block
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ backgroundColor: primaryColor(window.location.hostname) }}
        >
          {t('global:login')}
        </Button>
      </Form.Item>
      <Link to="/user/forgot-password">{t('usersPage:forgot-password')}</Link>
    </Form>
  )
}

export default Login
