import React from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { formulasSelector } from '../../redux/context/formulas/selectors'

interface FormulaInputProps {
  mode?: 'multiple' | 'tags'
  value?: number[]
  onChange?: (value: number[], option: any) => void
}

const FormulaInput: React.FC<FormulaInputProps> = ({ mode, value, onChange }) => {
  const formulas = useSelector(formulasSelector)

  const triggerChange = (changedValue: number[], option: any) => {
    onChange?.(changedValue as number[], option)
  }

  return (
    <Select
      filterOption={(input, option) => {
        const { children } = option?.props
        return children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
      }}
      value={value}
      mode={mode}
      allowClear
      showSearch
      onChange={triggerChange}
      maxTagCount="responsive"
    >
      {formulas.map(formula => (
        <Select.Option
          key={formula.id}
          value={formula.id}
          translations={{
            nameEn: formula.nameEn,
            nameFi: formula.nameFi,
            nameSv: formula.nameSv
          }}
        >
          {formula.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default FormulaInput
