import { Route } from './Route'
import { store } from '../redux/store'
import { currentUserSortedCompaniesSelector } from '../redux/session/currentUser/selectors'
import { sessionContractIdSelector, sessionLayoutSelector } from '../redux/session/contract/selectors'
import { contextCompanySelector } from '../redux/context/company/selectors'
import { vouchersConfig } from '../config/integrations/vouchers'
import { groupSettingsSelector } from '../redux/context/groupSettings/selectors'
import { GroupSettingDataSource } from '../pages/settings/company/group/types'

export const isValidRoute = (routes: Route[], path: string) => {
  let valid = false
  function recurse(data: Route[]) {
    for (const route of data) {
      if (route.path === path) {
        valid = true
        break
      }
      if (route.routes) {
        recurse(route.routes)
        if (valid) break
      }
    }
  }

  recurse(routes)

  return valid
}

/** Custom authorities */

// Is company a group
export const isGroupCompany = () => {
  const state: any = store.getState()
  const company = contextCompanySelector(state)
  return !!company?.groupId
}

// Is company a elimination company
export const isEliminationCompany = () => {
  const state: any = store.getState()
  const company = contextCompanySelector(state)
  return !!company?.elimination
}

/** Does user have more than one company */
export const multiCompany = () => {
  const state: any = store.getState()
  const companies = currentUserSortedCompaniesSelector(state)
  return companies.length > 1
}

export const showBudgetingSettings = () => {
  const state: any = store.getState()
  const groupSettings = groupSettingsSelector(state)
  if (isGroupCompany()) {
    if (groupSettings?.budget?.source === GroupSettingDataSource.subsidiaries) {
      return false
    }
    return true
  }
  return true
}

export const showAccountorLayout = () => {
  const state: any = store.getState()
  const layout = sessionLayoutSelector(state)
  return layout === 'accountor'
}

export const showEconiaNovaIntegration = () => {
  const state: any = store.getState()
  const contractId = sessionContractIdSelector(state)
  return contractId === 109
}

export const vouchersEnabled = () => {
  const state: any = store.getState()
  const { accountingSoftware } = contextCompanySelector(state) || {}
  return !!accountingSoftware && vouchersConfig[accountingSoftware]
}
