/* eslint-disable prettier/prettier */
import { FormInstance } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from "dayjs";
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  createReallocationRequest,
  createReallocationTargetRequest,
  deleteReallocationRequest,
  deleteReallocationTargetRequest,
  updateReallocationRequest,
  updateReallocationTargetRequest
} from '../../../../../redux/context/reallocations/actions'

import {
  ReallocationFloatingBalance,
  ReallocationSource,
  ReallocationTarget,
  ReallocationTargetType,
  SourcePeriodType
} from '../../../../../types/reallocation/Reallocation'
import { AppDispatch } from '../../../../../redux/store';

const initialSourceValues = {
  actuals: false,
  budget: false,
  description: undefined,
  dimensionId: undefined,
  endDate: undefined,
  id: undefined,
  startDate: undefined,
  targets: undefined,
  keyfigureId: undefined,
  sourceId: undefined,
}

const initialTargetValues = {
  accountFrom: undefined,
  accountTo: undefined,
  companyId: undefined,
  dimensionId: undefined,
  fixed: undefined,
  formulaId: undefined,
  type: ReallocationTargetType.range,
  account: undefined,
  id: undefined,
  // type: ReallocationType.fixed
}

export type ReallocationContextType = {
  sourceForm: FormInstance<ReallocationSource>
  targetParent: ReallocationSource | null
  targetForm: FormInstance<ReallocationTarget>
  floatingValues: ReallocationFloatingBalance[]
  sourceModalVisible: boolean
  targetModalVisible: boolean
  handleNewSource: () => void
  handleEditSource: (reallocation: ReallocationSource) => void
  handleDeleteSource: (reallocation: ReallocationSource) => void
  handleSaveSource: () => void
  handleCloseSource: () => void
  handleNewTarget: (parent: ReallocationSource) => void
  handleCloseTarget: () => void
  handleSaveTarget: () => void
  handleEditTarget: (target: ReallocationTarget, parent: ReallocationSource) => void
  handleCopyTarget: (target: ReallocationTarget, parent: ReallocationSource) => void
  handleDeleteTarget: (target: ReallocationTarget, parent: ReallocationSource) => void
}

export const ReallocationContext = React.createContext<ReallocationContextType | null>(null)

const ReallocationProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()
    const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const [sourceModalVisible, setSourceModalVisible] = useState(false)
  const [targetModalVisible, setTargetModalVisible] = useState(false)
  const [sourceForm] = useForm<ReallocationSource>()
  const [targetForm] = useForm<ReallocationTarget>()
  const [targetParent, setTargetParent] = useState<ReallocationSource | null>(null)
  const [floatingValues, setFloatingValues] = useState<ReallocationFloatingBalance[]>([])

  const transformToForm = (data: ReallocationSource) => ({
    ...data,
    periodType:
      data.startDate && data.endDate
        ? SourcePeriodType.fixedPeriod
        : SourcePeriodType.indefinitely,
    startDate: data.startDate === null ? undefined : dayjs(data.startDate),
    endDate: data.endDate === null ? undefined : dayjs(data.endDate),
  });

  const transformToSave = ({ periodType, ...data }: ReallocationSource) => ({
    ...data,
    startDate:
      periodType === SourcePeriodType.indefinitely
        ? null
        : dayjs(data.startDate)
            .startOf("M")
            .format("YYYY-MM-DD"),
    endDate:
      periodType === SourcePeriodType.indefinitely
        ? null
        : dayjs(data.endDate)
            .endOf("M")
            .format("YYYY-MM-DD"),
  });


  // Source funtions
  const handleNewSource = () => {
    sourceForm.setFieldsValue(initialSourceValues)
    setSourceModalVisible(true)
  }

  const handleEditSource = (data: ReallocationSource) => {
    sourceForm.setFieldsValue(transformToForm(_.cloneDeep(data))|| '')
    setSourceModalVisible(true)
  }
  const handleCloseSource = () => {
    sourceForm.resetFields()
    setSourceModalVisible(false)
  }

  const handleSaveSource = async () => {
    try {
      const values = await sourceForm.validateFields()
      if (!values.actuals && !values.budget) {
        sourceForm.setFields([{ name: 'actuals', errors: [t('reallocationsPage:dataType-error')] }])
        throw new Error('DataType error')
      }
      const transformedValues = transformToSave(values)

      if (values.id) {
        dispatch(updateReallocationRequest(companyId, transformedValues))
      }
      if (!values.id) {
        dispatch(createReallocationRequest(companyId, transformedValues))
      }

      sourceForm.resetFields()
      setSourceModalVisible(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleDeleteSource = (data: ReallocationSource) => {
    dispatch(deleteReallocationRequest(companyId, data))
  }

  // Target funtions
  const handleNewTarget = (parent: ReallocationSource) => {
    setTargetParent(parent)
    targetForm.setFieldsValue(initialTargetValues)
    setTargetModalVisible(true)
  }

  const handleEditTarget = (target: ReallocationTarget, parent: ReallocationSource) => {
    setTargetParent(parent)

    const fValues = parent?.targets?.find(ta => ta.id === target.id)?.floating
    fValues && setFloatingValues(fValues)

    targetForm.setFieldsValue(_.cloneDeep(target))
    setTargetModalVisible(true)
  }

  const handleCopyTarget = (target: ReallocationTarget, parent: ReallocationSource) => {
    setTargetParent(parent)

    const fValues = parent?.targets?.find(ta => ta.id === target.id)?.floating
    fValues && setFloatingValues(fValues)

    targetForm.setFieldsValue(_.cloneDeep({ ...target, id: undefined }))
    setTargetModalVisible(true)
  }

  const handleCloseTarget = () => {
    setTargetParent(null)

    targetForm.resetFields()
    setTargetModalVisible(false)
    setFloatingValues([])
  }

  const handleSaveTarget = async () => {
    if (!targetParent) return
    try {
      const values = await targetForm.validateFields()
      const oldfloatingValues = targetParent?.targets?.find(tar => tar.id === values.id)?.floating
      const newfloatingValues = _.uniqBy([...(values?.floating || []), ...(oldfloatingValues || [])], (elem) => [elem.month, elem.year].join()).filter(f => f.value !== 0)

      const newValues = {
        ...values,
        floating: newfloatingValues
      }

      if (values.id) {
        dispatch(updateReallocationTargetRequest(companyId, newValues, targetParent))
      }
      if (!values.id) {
        dispatch(createReallocationTargetRequest(companyId, newValues, targetParent))
      }

      sourceForm.resetFields()
      setTargetModalVisible(false)
      setFloatingValues([])

    } catch (error) {
      console.log('error', error)
    }
  }

  const handleDeleteTarget = (target: ReallocationTarget, parent: ReallocationSource) => {
    dispatch(deleteReallocationTargetRequest(companyId, target, parent))
  }

  return (
    <ReallocationContext.Provider
      value={{
        sourceForm,
        targetParent,
        floatingValues,
        targetForm,
        sourceModalVisible,
        targetModalVisible,
        handleSaveSource,
        handleNewSource,
        handleEditSource,
        handleCloseSource,
        handleDeleteSource,
        handleNewTarget,
        handleSaveTarget,
        handleEditTarget,
        handleCopyTarget,
        handleCloseTarget,
        handleDeleteTarget
      }}
    >
      {children}
    </ReallocationContext.Provider>
  )
}

export default ReallocationProvider
