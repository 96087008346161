import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import dayjs from 'dayjs'
import { CustomEventsOptions } from '../../../config/highcharts/types'
import { Task, TaskStatus } from '../types'

interface Props {
  tasks: Task[]
}

export const IntegrationChart: React.FC<Props> = ({ tasks }: Props) => {
  const colors = {
    [TaskStatus.PENDING]: '#87e8de',
    [TaskStatus.RUNNING]: '#91d5ff',
    [TaskStatus.FINISHED]: '#b7eb8f',
    [TaskStatus.FAILED]: '#ffa39e'
  }
  const categories: string[] = []

  for (const task of tasks.reverse()) {
    const date = dayjs(task.startTime).format('YYYY-MM-DD')
    const existing = categories.find(e => e === date)
    if (!existing) {
      categories.push(date)
    }
  }

  const series: any[] = Object.keys(TaskStatus).map(status => ({
    name: status,
    type: 'spline',
    color: colors[status as TaskStatus],
    data: categories.map(
      date => tasks.filter(task => task.status === status && dayjs(task.startTime).format('YYYY-MM-DD') === date).length
    )
  }))

  const options: CustomEventsOptions = {
    title: {
      text: ''
    },
    plotOptions: {
      series: {
        animation: false
      }
    },
    xAxis: {
      categories
    },
    yAxis: {
      title: {
        text: null
      }
    },
    series
  }

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
