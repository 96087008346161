import React from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { companyFormBasedKeyFigureSelector } from '../../redux/entities/keyFigures/selectors'

interface KeyFigureInputProps {
  mode?: 'multiple' | 'tags'
  value?: number[]
  onChange?: (value: number[], option: any) => void
}

const KeyFigureInput: React.FC<KeyFigureInputProps> = ({ value, onChange, mode }) => {
  const keyFigures = useSelector(companyFormBasedKeyFigureSelector)

  const { t } = useTranslation()
  const triggerChange = (changedValue: number[], option: any) => {
    onChange?.(changedValue as number[], option)
  }

  return (
    <Select
      filterOption={(input, option) => {
        const { children } = option?.props

        if (option?.type?.isSelectOptGroup) {
          return children.includes(
            (child: any) => child?.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          )
        }
        return children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
      }}
      value={value}
      showSearch
      mode={mode}
      allowClear
      onChange={triggerChange}
      maxTagCount="responsive"
    >
      {Object.entries(_.groupBy(keyFigures, 'group')).map(([groupId, groupKeyFigures]) => (
        <Select.OptGroup key={groupId} label={t(`keyFigurePage:${groupId}`)}>
          {groupKeyFigures.map(keyF => (
            <Select.Option key={keyF.id} value={keyF.id}>
              {t(`keyFigureStatement:${keyF.id}`)}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  )
}

export default KeyFigureInput
