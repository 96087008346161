/** Layout */
export interface CompanyDashboardItemResponsiveLayout {
  lg?: CompanyDashboardItemLayout[]
  md?: CompanyDashboardItemLayout[]
  sm?: CompanyDashboardItemLayout[]
  xs?: CompanyDashboardItemLayout[]
  xxs?: CompanyDashboardItemLayout[]
  lastModified?: string
}

export interface CompanyDashboardItemLayout {
  moved?: boolean
  static?: boolean
  i?: number
  x: number
  y: number
  h: number
  w: number
}

/** Item Types */
export enum CompanyDashboardItemType {
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  BALANCE_SHEET = 'BALANCE_SHEET',
  CASH_FLOW_STATEMENT = 'CASH_FLOW_STATEMENT',
  KEY_FIGURES = 'KEY_FIGURES',
  INTERNAL_KEY_FIGURES = 'INTERNAL_KEY_FIGURES',
  SUBSCRIBED_KEY_FIGURES = 'SUBSCRIBED_KEY_FIGURES',
  VALUATION = 'VALUATION',
  SALES = 'SALES'
}

export interface CompanyDashboardItem {
  id?: number
  name?: string
  type: CompanyDashboardItemType
  params: CompanyDashboardItemParams
}
export interface CompanyDashboard {
  key: number
  id: number
  name: string
  companyId?: string
  items: CompanyDashboardItem[]
  layouts?: CompanyDashboardItemResponsiveLayout // ReactGridLayout.Layouts
  order?: number
}

/** Representation */
export declare type CompanyDashboardRepresentation =
  | CompanyDashboardTableRepresentation
  | CompanyDashboardChartRepresentation
  | CompanyDashboardInfoRepresentation
  | CompanyDashboardPieRepresentation

export interface CompanyDashboardTableRepresentation {
  type: 'table'
}

export interface CompanyDashboardChartRepresentation {
  type: 'chart'
  options?: CompanyDashboardChartRepresentationOptions
}

export interface CompanyDashboardInfoRepresentation {
  type: 'info'
  options?: CompanyDashboardInfoRepresentationOptions
}

export interface CompanyDashboardPieRepresentation {
  type: 'pie'
  // options?: CompanyDashboardPieRepresentationOptions
}

interface CompanyDashboardChartRepresentationOptions {
  type: 'line' | 'column' | 'spline' | 'stackedColumn' | 'groupedColumn'
  series?: { [key: string]: any }
  comparableDataType?: 'actual' | 'budget'
  monthlyComparableType?: string
}

// interface CompanyDashboardPieRepresentationOptions {
//   type: 'line' | 'column' | 'spline' | 'stackedColumn' | 'groupedColumn'
//   series?: { [key: string]: any }
//   comparableDataType?: 'actual' | 'budget'
//   monthlyComparableType?: string
// }

interface CompanyDashboardInfoRepresentationOptions {
  type: 'line' | 'column' | 'spline' | 'stackedColumn' | 'groupedColumn'
  comparableDataType?: 'actual' | 'budget'
  monthlyComparableType?: string
}

export const defaultCompanyDashboardChartRepresentationOptions: CompanyDashboardChartRepresentationOptions = {
  type: 'column'
}

/** Params */
export interface CompanyDashboardItemParams {
  representation: CompanyDashboardRepresentation
  [key: string]: any
}
