import { Input, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from '../../types/company/Company'
import { Role } from '../../types/user/Role'
import { Language } from '../../types/user/User'
import { FormItem } from '../Form/FormItemContext'

const { Option } = Select

interface UserFormFieldsProps {
  selectableCompanies?: Company[]
  selectableRoles?: Role[]
  required?: boolean
  companiesRequired?: boolean
}

const defaultProps = {
  required: true
}

export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  selectableRoles,
  selectableCompanies,
  required,
  companiesRequired
}) => {
  const { t } = useTranslation()
  const {
    location: { hostname }
  } = window

  const domainParts = hostname.split('.')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subDomain, domain, topLevelDomain] = domainParts

  return (
    <>
      <FormItem
        name="firstName"
        preserve
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:first-name')}
      >
        <Input />
      </FormItem>
      <FormItem
        name="lastName"
        preserve
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:last-name')}
      >
        <Input />
      </FormItem>
      <FormItem
        name="email"
        preserve
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:email')}
      >
        <Input />
      </FormItem>
      <FormItem
        name="language"
        preserve
        initialValue={topLevelDomain === 'se' ? Language.sv : Language.fi}
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:language')}
      >
        <Select>
          {Object.keys(Language).map(language => (
            <Option value={language} key={language}>
              {t(`languages:${language}`)}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        name="phone"
        preserve
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:phone')}
      >
        <Input />
      </FormItem>
      <FormItem
        name="position"
        preserve
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
        label={t('usersPage:position')}
      >
        <Input />
      </FormItem>
      {selectableRoles && (
        <FormItem
          name="role"
          preserve
          rules={[
            {
              required,
              message: t('global:required-field')
            }
          ]}
          label={t('usersPage:role')}
        >
          <Select className="user-role-select">
            {selectableRoles.map((role: Role) => (
              <Option value={role.label} key={role.id}>
                {t(`usersPage:${role.label}`)}
              </Option>
            ))}
          </Select>
        </FormItem>
      )}
      {selectableCompanies && (
        <FormItem
          name="companies"
          preserve
          rules={[
            {
              required: companiesRequired !== undefined ? companiesRequired : required,
              message: t('global:required-field'),
              type: 'array'
            }
          ]}
          label={t('usersPage:companies')}
        >
          <Select
            showSearch
            optionFilterProp="children"
            className="user-companies-select"
            mode="multiple"
            placeholder={t(`usersPage:companies-add${companiesRequired ? '-min-one' : ''}`)}
            maxTagCount="responsive"
          >
            {selectableCompanies.map((company: Company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      )}
    </>
  )
}

UserFormFields.defaultProps = defaultProps
