import { LoadingOutlined, SyncOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Input, Result, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { EconiaApiKey } from '../types'
import { notificationAction } from '../../../../../redux/middleware/actions'
import DataUpdate from '../dataUpdate'
import { AppDispatch } from '../../../../../redux/store'

const Econia: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)

  // Eri instanssit koska tarvii eri loading-propertyt
  const getCredentialsRequest = useBackend(`/econia/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setCredentialsRequest = useBackend(`/econia/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const pollRequest = useBackend(`/econia/poll/{companyId}/daily`, process.env.REACT_APP_INTEGRATION_URL)

  const dispatch: AppDispatch = useDispatch()

  const [key, setKey] = useState<string>()
  const [authorized, setAuthorized] = useState(false)

  const getCredentialsHandler = () => {
    getCredentialsRequest
      .get({
        urlParams: { companyId }
      })
      .then((credentials: EconiaApiKey) => {
        setKey(credentials.key)
        if (credentials.key) {
          setAuthorized(true)
        } else {
          setKey('')
          setAuthorized(false)
        }
      })
      .catch(() => {
        setKey('')
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const authenticate = () => {
    setCredentialsRequest
      .put({
        urlParams: { companyId },
        body: {
          data: {
            key
          }
        }
      })
      .then((credentials: EconiaApiKey) => {
        setKey(credentials.key)
        setAuthorized(true)
        dispatch(
          notificationAction({
            type: 'success',
            message: 'IMPORT_RUNNING',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch(() => {
        setAuthorized(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'IMPORT_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  const poll = () => {
    pollRequest
      .post({
        urlParams: { companyId }
      })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'IMPORT_RUNNING',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'IMPORT_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  const emptyCredentials = () => {
    setKey('')
    setAuthorized(false)
  }

  if (authorized) {
    return (
      <>
        <Result
          status="success"
          title={t('integrationsPage:authenticated')}
          extra={
            <>
              <Button onClick={emptyCredentials} type="primary">
                {t('integrationsPage:authenticate')}
              </Button>
              <DataUpdate page="econia" />
            </>
          }
        />
      </>
    )
  }
  return (
    <Form layout="vertical">
      <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:novaId')}>
        <Input
          value={key}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setKey(event.target.value)}
          suffix={getCredentialsRequest.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button.Group>
          <Button
            icon={<UnlockOutlined />}
            type="primary"
            onClick={() => authenticate()}
            loading={setCredentialsRequest.loading}
          >
            {t('integrationsPage:authorize')}
          </Button>
          <Button
            icon={<SyncOutlined />}
            type="default"
            disabled={!key}
            onClick={() => poll()}
            loading={pollRequest.loading}
          >
            {t('integrationsPage:poll')}
          </Button>
        </Button.Group>
      </Form.Item>
    </Form>
  )
}

export default Econia
