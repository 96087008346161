import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

export const SET_KEY_PERFORMANCE_INDICATORS = 'SET_PERFORMANCE_INDICATORS'

export type PerformanceIndicator = { id: number }

export interface SetKeyPerformanceIndicatorsAction {
  type: typeof SET_KEY_PERFORMANCE_INDICATORS
  payload: KeyFigure[]
}

export type KeyPerformanceIndicatorsActionTypes = SetKeyPerformanceIndicatorsAction
