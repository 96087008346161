import { Table } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { getPersonnelRequest } from '../../../../redux/context/personnel/actions'
import { getSortedPersonnelData } from '../../../../redux/context/personnel/selectors'
import { KeyFigureFact } from '../../../../types/key-figures/KeyFigureFact'
import PersonnelEditForm from './Components/PersonnelEditForm'
import { AppDispatch } from '../../../../redux/store'

const PersonnelView: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    companyId && dispatch(getPersonnelRequest(companyId))
  }, [companyId])

  const columnProperties = ['fiscalYear', 'personnel', 'actions']
  const personnelColumns = columnProperties.map(property => {
    const align: 'right' | 'left' | 'center' = 'right'
    const column = {
      title: t(`personnelPage:${property}`),
      dataIndex: property,
      key: property
    }
    if (property === 'actions' || property === 'personnel') return { ...column, align }
    return column
  })

  const personnelData = useSelector(getSortedPersonnelData)
    .reverse()
    .map((personnel: KeyFigureFact) => {
      return {
        key: personnel.id,
        fiscalYear: personnel.year,
        personnel: Number(personnel.value).toFixed(0),
        actions: <PersonnelEditForm personnelData={personnel} />
      }
    })

  return <Table columns={personnelColumns} dataSource={personnelData} />
}

export default PersonnelView
