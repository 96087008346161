import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { sessionContractIdSelector } from '../../../../../../redux/session/contract/selectors'
import {
  createDeliverySubscriptionRequest,
  updateDeliverySubscriptionRequest,
  updateAndSendDeliverySubscriptionRequest,
  createAndSendManualDeliverySubscription
} from '../../../../../../redux/session/deliverySubscriptions/actions'
import {
  DeliveryChannel,
  DeliverySubscription,
  DeliverySubscriptionParams,
  DeliverySubscriptionType
} from '../../../../../../types/deliverySubscription/DeliverySubscription'
import DeliverySubscriptionForm, { DeliverySubscriptionFieldOptions } from './DeliverySubscriptionForm'
import { Company } from '../../../../../../types/company/Company'
import { User } from '../../../../../../types/user/User'
import { AppDispatch } from '../../../../../../redux/store'

export interface DeliverySubscriptionFormData {
  id: number
  companyId: Company['id']
  userId: User['id']
  monthly: number
  periodLock: boolean
  channel: DeliveryChannel
  recipients?: string[] | null
  type: DeliverySubscriptionType
  reportId?: number
  initiator: {
    monthly?: { day: number }
    weekly?: { dow: number }
    daily?: boolean
    periodlock?: boolean
    manual?: boolean
  }
  params: DeliverySubscriptionParams & { endDate: dayjs.Dayjs }
}
interface DeliverySubscriptionModalProps {
  subscriptionType: DeliverySubscriptionType
  visible: boolean
  initiatorOptions: string[]
  paramsOptions?: string[]
  fieldOptions?: DeliverySubscriptionFieldOptions
  deliverySubscription?: DeliverySubscription
  toggleModalVisible: () => void
}

export type IniatorType = 'monthly' | 'periodLock' | 'manual' | 'weekly' | 'daily'

const DeliverySubscriptionModal: React.FC<DeliverySubscriptionModalProps> = ({
  visible,
  deliverySubscription,
  initiatorOptions,
  paramsOptions,
  fieldOptions,
  subscriptionType,
  toggleModalVisible
}: DeliverySubscriptionModalProps) => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const [initiatorOption, setInitiatorOption] = useState<IniatorType>('monthly')
  const contractId = useSelector(sessionContractIdSelector)
  const [form] = useForm<DeliverySubscriptionFormData>()

  const transformPayload = (values: DeliverySubscriptionFormData): DeliverySubscription => {
    return {
      ...values,
      channel:
        subscriptionType === DeliverySubscriptionType.ACCOUNTOR_SCORECARD
          ? DeliveryChannel.FINADECK
          : DeliveryChannel.EMAIL,
      type: subscriptionType,
      recipients: values.recipients || null,
      params: {
        ...values?.params,
        endDate: values?.params?.endDate?.endOf('month').toDate()
      }
    }
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((deliverySubscriptionFormData: DeliverySubscriptionFormData) => {
        const payload = transformPayload(deliverySubscriptionFormData)

        if (deliverySubscription) {
          dispatch(updateDeliverySubscriptionRequest(contractId, deliverySubscription.id, payload))
        } else {
          dispatch(createDeliverySubscriptionRequest(contractId, payload))
        }

        form.resetFields()
        toggleModalVisible()
      })
      .catch(e => console.log(e))

    return null
  }

  const handleSendAndSubmit = () => {
    form
      .validateFields()
      .then((deliverySubscriptionFormData: DeliverySubscriptionFormData) => {
        const payload = transformPayload(deliverySubscriptionFormData)

        if (deliverySubscription) {
          dispatch(updateAndSendDeliverySubscriptionRequest(contractId, deliverySubscription.id, payload))
        } else {
          dispatch(createAndSendManualDeliverySubscription(contractId, payload))
        }

        form.resetFields()
        toggleModalVisible()
      })
      .catch(e => console.log(e))

    return null
  }

  const handleCancel = () => {
    form.resetFields()
    toggleModalVisible()
  }

  const getModalFooter = () => {
    const footer = [
      <Button key="back" onClick={handleCancel}>
        {t('global:cancel')}
      </Button>,
      <Button key="save" type="primary" onClick={() => handleSubmit()}>
        {deliverySubscription ? t('global:update') : t('global:save')}
      </Button>
    ]

    if (
      (initiatorOption === 'periodLock' || initiatorOption === 'manual') &&
      subscriptionType === DeliverySubscriptionType.ACCOUNTOR_SCORECARD
    ) {
      footer.push(
        <Button
          key="save-send"
          type="primary"
          onClick={() => {
            handleSendAndSubmit()
          }}
        >
          {deliverySubscription ? t('global:update-and-send') : t('global:save-and-send')}
        </Button>
      )
    }

    return footer
  }

  return (
    <Modal
      destroyOnClose
      title={deliverySubscription ? t('subscriptionsPage:edit-subscription') : t('subscriptionsPage:add-subscription')}
      open={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={getModalFooter()}
    >
      <DeliverySubscriptionForm
        form={form}
        subscriptionType={subscriptionType}
        deliverySubscription={deliverySubscription}
        initiatorOptions={initiatorOptions}
        fieldOptions={fieldOptions}
        paramsOptions={paramsOptions}
        initiatorOption={initiatorOption}
        setInitiatorOption={setInitiatorOption}
      />
    </Modal>
  )
}

export default DeliverySubscriptionModal
