import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'antd'
import { Loading } from '../../../../../components/Misc/Loading'
import {
  customKeyFigureStatementSelector,
  financialStatementLoadingSelector,
  keyFigureStatementSelector
} from '../../../../../redux/context/reports/selectors'
import {
  CompanyDashboardItem,
  defaultCompanyDashboardChartRepresentationOptions
} from '../../../../../types/dashboard/company/types'
import { getBadgeColor, format } from '../../../../reporting/keyFigures/utils'
import { formatValueByType } from '../../../../../utils/helpers'
import { ReportTableRow } from '../../../../../components/Table/types'
import { errorSelector } from '../../../../../redux/error/selectors'
import ReportTable from '../../../../../components/Table/ReportTable'
import KeyFigureChart from '../../../../reporting/keyFigures/components/keyFigureChart/KeyFigureChart'
import { dimensionQuerySelector, filtersSelector } from '../../../../../redux/context/filters/selectors'
import { useChartSeries } from '../../../../../components/Chart/hooks'
import { useTableColumns } from '../../../../../redux/context/periodGroups/hooks'
import { currentFiscalYearSelector } from '../../../../../redux/context/fiscalYears/selectors'
import InfoStatistics from '../InfoStatistics'
import { drillDownExpandable } from '../../../../../components/Table/utils'
import { getKeyFigureExplainerRequest } from '../../../../../redux/context/reports/actions'
import { KeyFigure } from '../../../../../types/keyFigure/KeyFigure'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { AppDispatch } from '../../../../../redux/store'
import { appLanguageSelector } from '../../../../../redux/app/selectors'

interface KeyFiguresProps {
  item: CompanyDashboardItem
  width: number
  height: number
}

export const KeyFigures: React.FC<KeyFiguresProps> = ({ item: { params }, height, width }: KeyFiguresProps) => {
  const dispatch: AppDispatch = useDispatch()

  const keyFigureStatement = useSelector(keyFigureStatementSelector)
  const filteredStatement = useSelector(customKeyFigureStatementSelector(params.keyFigureIds))
  const loading = useSelector(financialStatementLoadingSelector)
  const loadingKeyfigure = useSelector(loadingSelector)
  const currentFiscalYear = useSelector(currentFiscalYearSelector)
  const companyId = useSelector(contextCompanyIdSelector)!
  const columns = useTableColumns()
  const errors = useSelector(errorSelector)
  const { periodGroup, periodGroups, budgetingScenario } = useSelector(filtersSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const language = useSelector(appLanguageSelector)
  const series: any[] = useChartSeries(keyFigureStatement, {
    cumulative: false,
    chartType: 'column'
  })

  if (loading) return <Loading />
  if (!params) return null

  // TODO: siirrä utils
  const renderCell = (text: any, reportTableRow: ReportTableRow) => {
    if (!text) return undefined
    const value = formatValueByType(text, reportTableRow.variableType)

    const badgeColor = getBadgeColor(reportTableRow, format(reportTableRow, text))

    if (badgeColor) {
      return <Badge color={badgeColor} text={value} />
    }
    return value
  }

  const expandable = drillDownExpandable(
    record =>
      dispatch(
        getKeyFigureExplainerRequest(
          companyId,
          record as unknown as KeyFigure,
          language,
          dimensionQuery,
          periodGroups,
          budgetingScenario?.id
        )
      ),
    id => loadingKeyfigure[`keyFigureExplainer${id}`]
  )

  switch (params.representation.type) {
    case 'chart':
      return (
        <KeyFigureChart
          chartType={(params.representation.options || defaultCompanyDashboardChartRepresentationOptions).type}
          report={filteredStatement}
          height={height}
          width={width}
          error={errors?.keyFigureStatement}
        />
      )
    case 'info':
      return (
        <>
          <InfoStatistics
            periodGroup={periodGroup}
            series={series}
            report={filteredStatement}
            columns={columns.filter(f => f.dataType === 'actuals')}
            params={params}
            periodGroups={periodGroups}
            currentFiscalYear={currentFiscalYear}
          />
          <KeyFigureChart
            chartType={(params.representation.options || defaultCompanyDashboardChartRepresentationOptions).type}
            report={filteredStatement}
            height={height - 60}
            width={width}
            error={errors?.keyFigureStatement}
          />
        </>
      )
    case 'table':
      return (
        <ReportTable
          toolbar={{ csvExport: false }}
          report={filteredStatement?.map(row => ({
            ...row,
            children: [...(row.children || [])]
          }))}
          renderCell={renderCell}
          expandable={expandable}
          yScroll={height}
          error={errors?.keyFigureStatement}
        />
      )

    default:
      return null
  }
}
