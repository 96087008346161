import {
  ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY,
  ReallocationActionTypes,
  ReallocationsPage,
  SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS
} from './types'

const initialState: ReallocationsPage = {
  expandedRowKeys: []
}

const reallocationPageReducer = (state = initialState, action: ReallocationActionTypes) => {
  switch (action.type) {
    case SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS:
      return { ...state, expandedRowKeys: action.payload }
    case ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY:
      return { ...state, expandedRowKeys: [...state.expandedRowKeys, action.payload] }
    default:
      return state
  }
}

export default reallocationPageReducer
