import { GetRequestAction, GET, INTEGRATION_REQUEST } from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import { SetProductsAction, SET_PRODUCTS } from './types'
import { Product } from '../../../types/product/product'

export const setProducts = (products: Product[]): SetProductsAction => ({
  type: SET_PRODUCTS,
  payload: products
})

export const getProductsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: INTEGRATION_REQUEST,
  payload: {
    method: GET,
    params: { companyId },
    url: `/reporting/products/accounting`,
    success: setProducts
  },
  meta: {
    type: 'PRODUCTS'
  }
})
