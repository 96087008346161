import dayjs from 'dayjs'
import i18next from 'i18next'
import { CustomExchangeRate, CustomExchangeRateSeries, StatementType } from '../types'
import { SheetRow } from './ExchangeRateSheet'
import { arrayDownload, downloadMenuItems } from '../../../../../utils/tableExport'

export const parseRates = (
  sheet: SheetRow[],
  companyData?: CustomExchangeRateSeries,
  formValues?: Partial<CustomExchangeRateSeries>
): CustomExchangeRateSeries | null => {
  if (!companyData) return null

  const series = sheet.flatMap(row => {
    const { report, ...values } = row
    return Object.entries(values)
      .map(([key, value]) => {
        const [month, year] = key.split('/').map(Number)
        const date = dayjs({ month: month - 1, year }).format('YYYY-MM-DD')
        return {
          statementType: report as StatementType,
          date,
          month,
          year,
          value: value ? parseFloat(value.replace(',', '.')) : null
        }
      })
      .filter(s => s.value !== null) as CustomExchangeRate[]
  })

  const rates: CustomExchangeRate[] = series.map(serie => {
    const found = companyData?.rates.find(
      ex => dayjs(ex.date).utc().isSame(serie.date, 'month') && serie.statementType === ex.statementType
    )
    return {
      ...serie,
      id: found?.id
    }
  })

  return { ...companyData, ...formValues, rates }
}

export const downloadMenu = (data: SheetRow[]) => {
  const handleMenuClick = ({ key }: any) => {
    const aoa = data.map(row => Object.values(row))
    switch (key) {
      case 'csv':
      case 'xlsx':
        arrayDownload(aoa, i18next.t('menu:/settings/company/group/exchangeRate'), key)
        break
      default:
        break
    }
  }

  const menuProps = {
    items: downloadMenuItems,
    onClick: handleMenuClick
  }

  return menuProps
}
