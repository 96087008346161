import i18next from 'i18next'
import { createSelector } from 'reselect'
import { recursiveFind } from '../../../utils/helpers'
import { contextCompanySelector } from '../../context/company/selectors'
import { cashFlowStatementSelector } from '../../context/reports/selectors'
import { ReportRow } from '../../context/reports/types'
import { Store } from '../../types'

const cashFlowStatementConfigSelector = (store: Store) => store.config.cashFlowStatement

export const cashFlowStatementGraphConfigSelector = createSelector(
  contextCompanySelector,
  cashFlowStatementConfigSelector,
  (company, config) => company && config?.cashFlowGraphConfig?.[company.country]?.[company.form]
)

export const cashFlowStatementGraphReportSelector = (type: string, selectedGraph: string) => {
  return createSelector(
    [cashFlowStatementGraphConfigSelector, cashFlowStatementSelector],
    (config, cashFlowStatement) => {
      const getReportRow = (cfg: { id: number; chartType: string; legendIndex?: number }): ReportRow | null => {
        const row = cashFlowStatement && recursiveFind<ReportRow>(cashFlowStatement, cfg.id)
        return row
      }

      if (!config) return []
      return Object.values(config[type][selectedGraph]).map(cfg => {
        const reportRow = getReportRow(cfg)
        if (reportRow) {
          const { id } = reportRow
          const title = i18next.t(`cashFlowStatement:${id}`, '')
          return {
            id,
            title,
            dataIndex: `${id}`
          }
        }
        return {}
      })
    }
  )
}
