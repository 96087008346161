import { User, UserSetting } from '../../../types/user/User'
import { Company } from '../../../types/company/Company'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const ADD_CURRENT_USER_COMPANY = 'ADD_CURRENT_USER_COMPANY'
export const UPDATE_CURRENT_USER_COMPANY = 'UPDATE_CURRENT_USER_COMPANY'
export const UPDATE_TERMS_AND_CONDITIONS = 'UPDATE_TERMS_AND_CONDITIONS'
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const UPDATE_TOUR = 'UPDATE_TOUR'

export interface UpdateTourAction {
  type: typeof UPDATE_TOUR
}

export interface UpdateTermsAndConditionsAction {
  type: typeof UPDATE_TERMS_AND_CONDITIONS
}

export interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER
  payload: User
}
export interface UpdateUserSettingsAction {
  type: typeof UPDATE_USER_SETTINGS
  payload: UserSetting
}

export interface UpdateUserCompanyAction {
  type: typeof UPDATE_CURRENT_USER_COMPANY
  payload: Company
}

export interface AddCurrentUserCompanyAction {
  type: typeof ADD_CURRENT_USER_COMPANY
  payload: Company
}

export type CurrentUserActionTypes =
  | SetCurrentUserAction
  | AddCurrentUserCompanyAction
  | UpdateUserSettingsAction
  | UpdateTermsAndConditionsAction
  | UpdateTourAction
  | UpdateUserCompanyAction
