// eslint-disable-next-line max-classes-per-file
import _ from 'lodash'
import { Tree, TreeNode } from './Tree'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

type ContexRow = {
  leaf: CustomReportCategory
  company: CustomReportCategory
  dimension: CustomReportCategory
  periodGroup: CustomReportCategory
  func: CustomReportCategory
}

export class CustomTreeNode extends TreeNode<CustomReportCategory> {
  constructor(key: string, value = null as CustomReportCategory | null, parent = null as CustomTreeNode['parent']) {
    super(key, value, parent)
  }
}

export class CustomTree extends Tree<CustomReportCategory> {
  root: CustomTreeNode

  constructor(key: string) {
    super(key)
    this.root = new CustomTreeNode(key)
  }

  insert(parentNodeKey: string, key: string, value = null as CustomReportCategory | null) {
    for (const node of this.preOrderTraversal()) {
      if (node.key === parentNodeKey) {
        node.children.push(new CustomTreeNode(key, value, node))
        return true
      }
    }
    return false
  }

  reset() {
    this.root.children.forEach(c => this.remove(c.key))
  }

  build(categories: CustomReportCategory[]) {
    const generateTreeDataNodes = (cats: CustomReportCategory[], path?: string) => {
      cats.forEach((cat, index) => {
        const currentPath = `${path || 0}-${index}`
        this.insert(`${path || 0}`, currentPath, cat)
        cat.children && generateTreeDataNodes(cat.children, currentPath)
      })
    }
    this.reset()
    generateTreeDataNodes(categories)
  }

  getContextRow(key?: string): ContexRow | null {
    if (!key) return null
    return this.contexRows?.find(row => row?.leaf?.id === key) || null
  }

  get paths() {
    const result: CustomTreeNode[][] = []
    function dfs(node: CustomTreeNode, path: CustomTreeNode[]) {
      if (!node) {
        return
      }
      // Add the current node to the path
      if (node.value !== null) {
        path.push(_.cloneDeep(node))
      }
      // If the current node is a leaf, add the current path to the result
      if (node.isLeaf || node.value?.type === 'function') {
        result.push([...path])
      }
      // Recursively traverse the  subtrees
      if (!node.isLeaf && node.value?.type !== 'function') {
        node.children.forEach(n => dfs(n, path))
      }
      // Remove the current node from the path to backtrack
      path.pop()
    }

    dfs(this.root, [])

    return result
  }

  get tableColumns() {
    const columns = new Set()
    const dataTypes = new Set()
    const companies = new Set()

    this.contexRows.forEach(row => {
      dataTypes.add(row?.periodGroup?.dataType || ReportDataType.actuals)
      companies.add(row?.company?.value)

      Object.entries(row).forEach(([key, value]) => {
        if (key === 'func') columns.add('function')
        if (value && key !== 'func') columns.add(key)
      })
    })

    if (dataTypes.size > 1) {
      columns.add('dataType')
    }
    if (companies.size <= 1) {
      columns.delete('company')
    }

    return [...columns] as string[]
  }

  get contexRows() {
    return this.paths.map(path => {
      return path.reduce((ctx, node) => {
        if (node.value?.type === 'function') {
          ctx.func = node.value
        }
        if (node.value?.type && node.value?.type !== 'function') {
          ctx[node.value?.type as keyof ContexRow] = node.value
        }
        if (node.isLeaf) {
          ctx.leaf = node.value!
        }
        return ctx
      }, {} as ContexRow)
    })
  }

  get companies() {
    const companies = new Set<string>()
    for (const node of this.preOrderTraversal()) {
      if (node.value?.type === 'company' && node.value.value) {
        companies.add(node.value.value as string)
      }
    }
    return [...companies]
  }
}
