import { Popover, Space, Table, Typography } from 'antd'
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import classNames from 'classnames'
import styled from 'styled-components'
import { ColumnGroupType, ColumnType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'

import _ from 'lodash'
import {
  getVariableExplainerRequest,
  setVariableOrder,
  updateCustomReportVariableOrdersRequest
} from '../../../../../redux/context/customReports/actions'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import DraggableBodyRow from '../../../../../components/Table/DraggableBodyRow'
import {
  customReportDateSelector,
  customReportQuickFiltersSelector
} from '../../../../../redux/context/customReports/selectors'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { getCustomReportTableCategoryTitle, scenarioTitle } from '../../../../../redux/context/customReports/utils'
import CustomreportCell from './CustomReportCell'
import CustomReportVoucherModal from './CustomReportVoucherModal'
import { getCategoryVariableType, isLeaf } from '../utils'
import { drillDownExpandable, getCellDataAttributes, renderPeriod } from '../../../../../components/Table/utils'
import { getColor, getDataSourceVariables, getTitleByLanguage, useTableHeight } from './utils'
import { CustomTree } from './CustomTree'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import ResizableTitle from '../../../../../components/Table/ReziableTitle'
import { useResizeableColumns } from '../../../../../components/Table/hooks'
import { AppDispatch } from '../../../../../redux/store'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportTableSection } from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariableRow } from '../../../../../redux/context/customReports/typesVariable'
import { appLanguageSelector } from '../../../../../redux/app/selectors'
import ReferenceRulesTable from '../../../../settings/company/keyFigures/formulas/components/ReferenceRules/ReferenceRulesTable'

const defaultProps = {
  columnWidth: 90,
  nameColumnWidth: 220
}

interface CustomReportProps {
  reportId: number
  section: CustomReportTableSection
}

const StyledTable: typeof Table = styled(Table)`
  & .bold {
    font-weight: bold;
  }
`

const CustomReportTable: React.FC<CustomReportProps> = ({
  section: { variables, categories, id: sectionId, title: sectionTitle },
  reportId
}) => {
  const { t, i18n } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const tableElement = useRef(null)
  const companyId = useSelector(contextCompanyIdSelector)!
  const quickFilters = useSelector(customReportQuickFiltersSelector)
  const [tableColumns, setTableColumns] = useResizeableColumns<CustomReportVariableRow>([])
  const date = useSelector(customReportDateSelector)
  const { displayEmptyRows } = useSelector(filtersSelector)
  const loading = useSelector(loadingSelector)
  const language = useSelector(appLanguageSelector)

  const [categoryTree] = useState(new CustomTree('0'))
  useMemo(() => categoryTree.build(categories), [categories])
  const dataSource = useMemo(
    () => getDataSourceVariables(categoryTree, variables, displayEmptyRows),
    [variables, categories, displayEmptyRows]
  )
  const tableHeight = useTableHeight(tableElement, dataSource)
  const [voucherProps, setVoucherProps] = useState<any>({
    visible: false,
    column: null,
    row: null
  })

  const getTableColumn = (
    category: CustomReportCategory
  ): ColumnGroupType<CustomReportVariableRow> | ColumnType<CustomReportVariableRow> => {
    const getChildren = (c: CustomReportCategory) => {
      switch (c.type) {
        case 'function':
          return undefined
        default:
          return c.children?.map(getTableColumn)
      }
    }

    const getProps = (record: CustomReportVariableRow, value?: number) => {
      return {
        style: {
          background: getColor(category, record, value)
        },

        ...getCellDataAttributes(record, getCategoryVariableType(category), value)
      }
    }

    const getColumnWidth = () => {
      const { leafValues } = categoryTree
      const lastLeaf = leafValues.slice(-1) as unknown as CustomReportCategory
      if (leafValues.length <= 12) return undefined
      if (lastLeaf && category.id === lastLeaf.id) return undefined
      if (category.value === 'growth' || category.value === 'commonSize') return 100
      return defaultProps.columnWidth
    }

    return {
      title: !category.budgetingScenario
        ? getCustomReportTableCategoryTitle(category, categories)
        : scenarioTitle(category),
      dataIndex: ['values', `${category.id}`],
      align: isLeaf(category) ? 'right' : 'center',
      key: category.id,
      width: getColumnWidth(),
      shouldCellUpdate: (record: CustomReportVariableRow, prevRecord: CustomReportVariableRow) => {
        if (_.isEqual(record.style, prevRecord.style)) {
          return false
        }
        return true
      },
      children: getChildren(category),
      onCell: (record: CustomReportVariableRow) => {
        const val = record.values[category.id!]
        return getProps(record, val)
      },
      render: (value, record) =>
        React.createElement(CustomreportCell, {
          value,
          record,
          category,
          categories,
          sectionId,
          setVoucherProps
        })
    }
  }

  const renderTitle = (row: CustomReportVariableRow) => {
    let str = getTitleByLanguage(row)
    if (row.params?.dimension) str = str.concat(` - ${row.params?.dimension?.name}`)
    if (row.params?.dataType) str = str.concat(` - ${t(`global:${row.params?.dataType}`)}`)
    if (row.period && renderPeriod(row, variables || [])) {
      str = str.concat(` (${t(`formulaPeriod:${row.period}`)})`)
    }
    return str
  }

  const renderPopoverTitle = (record: CustomReportVariableRow, title: string): ReactNode => {
    return (
      <div style={{ float: 'left' }}>
        <Popover
          content={
            <Space direction="vertical">
              <ReferenceRulesTable rules={record.style?.referenceValueRules} />
              <Typography.Text>{t('global:formula')}</Typography.Text>
              <Typography.Text code>{record.source}</Typography.Text>
            </Space>
          }
        >
          {renderTitle ? renderTitle(record) : title}
        </Popover>
      </div>
    )
  }

  useEffect(() => {
    setTableColumns([
      {
        title: t('keyFigurePage:key-figure'),
        key: 'title',
        dataIndex: 'title',
        fixed: 'left',
        ellipsis: true,
        width: defaultProps.nameColumnWidth,
        render: (title: string, record: CustomReportVariableRow) => {
          return renderPopoverTitle(record, title)
        }
      },
      ...(categories || []).map(getTableColumn)
    ])
    return () => setTableColumns([])
  }, [categories, i18n.language])

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex]
      dataSource.splice(dragIndex, 1)
      dataSource.splice(hoverIndex, 0, dragRow)
      const orderArray: Partial<CustomReportVariableRow>[] = []
      dataSource.map((row, order) => {
        return orderArray.push({ id: row.id, order })
      })

      dispatch(setVariableOrder(reportId, sectionId, orderArray))
      companyId && dispatch(updateCustomReportVariableOrdersRequest(companyId, reportId, sectionId, orderArray))
    },
    [dataSource]
  )

  const onVoucherModalCancel = () => {
    setVoucherProps({
      visible: false,
      column: null,
      row: null
    })
  }

  return (
    <div className="report-table-container" style={{ height: '100%' }} data-title={sectionTitle} ref={tableElement}>
      <CustomReportVoucherModal
        onCancel={onVoucherModalCancel}
        visible={voucherProps.visible}
        column={voucherProps.column}
        row={voucherProps.row}
        categories={categories}
        categoryTree={categoryTree}
      />
      <StyledTable<CustomReportVariableRow>
        className="table-darken-levels"
        data-name={sectionTitle}
        columns={tableColumns}
        dataSource={dataSource}
        bordered
        size="small"
        scroll={{
          x: categoryTree.leafs.length * defaultProps.columnWidth + defaultProps.nameColumnWidth,
          y: tableHeight
        }}
        rowKey={record => record.id.toString()}
        components={{
          header: {
            cell: ResizableTitle
          },
          body: {
            row: DraggableBodyRow
          }
        }}
        rowClassName={record =>
          classNames({
            bold: record?.style?.fontWeight,
            'explanation-row': record.explanation
          })
        }
        pagination={false}
        expandable={
          // TODO: fix drillDownExpandable types or make new function to avoid type casting
          drillDownExpandable(
            record =>
              dispatch(
                getVariableExplainerRequest(
                  companyId,
                  reportId,
                  record as unknown as CustomReportVariableRow,
                  dayjs(date).endOf('month').format('YYYY-MM-DD'),
                  language,
                  quickFilters
                )
              ),
            id => loading[`customReportVariableExplainer${id}`]
          ) as unknown as ExpandableConfig<CustomReportVariableRow>
        }
        // TODO: fix onRow props
        onRow={(record, index) => {
          return {
            index,
            moveRow,
            dragDisabled: record.explanation
          } as any
        }}
      />
    </div>
  )
}

export default CustomReportTable
