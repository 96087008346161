import { Button, Input, Result, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../../services/backend'
import { ApiSecret } from '../../types'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../../../redux/store'
import DataUpdate from '../../dataUpdate'

interface MaraplanApiProps {
  allowAuthentication: boolean
  credentialsRequestUrl: string
}

const MaraplanApi = ({ allowAuthentication, credentialsRequestUrl }: MaraplanApiProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const credentialsRequest = useBackend(credentialsRequestUrl, process.env.REACT_APP_INTEGRATION_URL)
  const authenticateRequest = useBackend('/maraplan/authentication/{companyId}', process.env.REACT_APP_INTEGRATION_URL)
  const [apiKey, setApiKey] = useState<string>()
  const [authorized, setAuthorized] = useState(false)

  const getCredentialsHandler = () => {
    credentialsRequest
      .get({
        urlParams: { companyId }
      })
      .then((credentials: ApiSecret) => {
        credentials.companyId ? setAuthorized(true) : setAuthorized(false)
      })
      .catch(() => {
        setApiKey('')
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const submit = () => {
    credentialsRequest
      .put({
        urlParams: { companyId },
        body: {
          data: {
            apiKey
          }
        }
      })
      .then((credentials: ApiSecret) => {
        setApiKey(credentials.apiKey)
        setAuthorized(true)
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS'
          })
        )
      })
      .catch(() => {
        setAuthorized(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: 'USERNAME_PASSWORD_MISMATCH'
          })
        )
      })
  }

  const authenticate = () => {
    authenticateRequest
      .post({
        urlParams: { companyId }
      })
      .then(() => {
        setAuthorized(true)
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS'
          })
        )
      })
      .catch(() => {
        setAuthorized(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: 'USERNAME_PASSWORD_MISMATCH'
          })
        )
      })
  }

  const emptyCredentials = () => {
    setApiKey('')
    setAuthorized(false)
  }

  if (authorized) {
    return (
      <>
        <Result
          status="success"
          title={t('integrationsPage:authenticated')}
          extra={
            allowAuthentication && (
              <>
                <Button onClick={emptyCredentials} type="primary">
                  {t('integrationsPage:authenticate')}
                </Button>
                <DataUpdate page="maraplan" />
              </>
            )
          }
        />
      </>
    )
  }
  if (allowAuthentication) {
    return (
      <Form layout="vertical">
        <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:key')}>
          <Input
            value={apiKey}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setApiKey(event.target.value)}
            suffix={credentialsRequest.loading && <LoadingOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button.Group>
            <Button type="primary" onClick={() => submit()} loading={credentialsRequest.loading}>
              {t('integrationsPage:authorize')}
            </Button>
          </Button.Group>
        </Form.Item>
      </Form>
    )
  }
  return (
    <Result
      status="info"
      title={t('integrationsPage:notAuthenticated')}
      extra={
        <Button type="primary" onClick={() => authenticate()}>
          {t('integrationsPage:authorize')}
        </Button>
      }
    />
  )
}

export default MaraplanApi
