import { CompanyUsersActionTypes, SET_COMPANY_USERS } from './types'

const initialState = {}

const companyUsersReducer = (state = initialState, action: CompanyUsersActionTypes) => {
  switch (action.type) {
    case SET_COMPANY_USERS:
      return action.payload
    default:
      return state
  }
}

export default companyUsersReducer
