import { TaskApplication } from '../../pages/superuser/types'

export const vouchersConfig: { [key in TaskApplication]?: boolean } = {
  BRIOX: false,
  ECONIA: false,
  EXCHANGE_RATES: false,
  FENNOA: true,
  FIVALDI: true,
  FORTNOX: true,
  HEEROS: true,
  MAESTRO: true,
  NETVISOR: true,
  PROCOUNTOR: true,
  RESTOLUTION: false,
  SIE: true,
  SONET: false,
  TALENOM: true,
  L7: true,
  VISMANET: true,
  XERO: true,
  OSCAR: true,
  FINADECK: true
}

export const voucherAccountingSoftware = [
  TaskApplication.FENNOA,
  TaskApplication.FIVALDI,
  TaskApplication.FORTNOX,
  TaskApplication.HEEROS,
  TaskApplication.MAESTRO,
  TaskApplication.NETVISOR,
  TaskApplication.PROCOUNTOR,
  TaskApplication.SIE,
  TaskApplication.TALENOM,
  TaskApplication.L7,
  TaskApplication.VISMANET,
  TaskApplication.XERO,
  TaskApplication.OSCAR
]
