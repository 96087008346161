import React, { useMemo, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { Button, Form, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { addSubsidiaryRequest } from '../../../../../../redux/context/subsidiaries/actions'
import { currentUserSortedCompaniesSelector } from '../../../../../../redux/session/currentUser/selectors'
import { subsidiariesSelector } from '../../../../../../redux/context/subsidiaries/selectors'
import { AppDispatch } from '../../../../../../redux/store'
import { loadingSelector } from '../../../../../../redux/loading/selectors'

type FormValues = { ids: string[] }

interface SubsidiariesForm {
  groupId?: string
}

const SubsidiariesForm: React.FC<SubsidiariesForm> = ({ groupId }) => {
  const { t } = useTranslation()
  const [form] = useForm<FormValues>()
  const dispatch: AppDispatch = useDispatch()

  const fancyRef = useRef(null)
  const companyId = useSelector(contextCompanyIdSelector)
  const companies = useSelector(currentUserSortedCompaniesSelector)
  const subsidiaries = useSelector(subsidiariesSelector)
  const loading = useSelector(loadingSelector)

  const handleCompanyAdd = ({ ids }: FormValues) => {
    const subsidiariesData = ids.map(id => ({
      id,
      name: _.keyBy(companies, 'id')[id]?.name
    }))
    companyId && dispatch(addSubsidiaryRequest(companyId, subsidiariesData))
    form.resetFields()
  }

  const selectOptions = useMemo(
    () =>
      companies.map(c => ({
        label: c.name,
        key: c.id,
        value: c.id,
        disabled: [...subsidiaries.map(s => s.id), groupId, companyId].includes(c.id)
      })),
    [companies, subsidiaries]
  )

  const FancySelect = React.forwardRef((props, ref: React.Ref<any>) => (
    <Space.Compact block>
      <Select
        {...props}
        ref={ref}
        mode="multiple"
        filterOption={(input, option) => {
          const companyName = (option?.label as string)?.toLowerCase()
          return companyName?.indexOf(input.toLowerCase()) >= 0 ?? false
        }}
        options={selectOptions}
      />
      <Button loading={loading.createSubsidiary} icon={<PlusOutlined />} type="primary" htmlType="submit">
        {t('global:add')}
      </Button>
    </Space.Compact>
  ))

  return (
    <Form form={form} layout="vertical" onFinish={handleCompanyAdd}>
      <Form.Item label={t('global:add-subsidiaries')} name="ids">
        <FancySelect ref={fancyRef} />
      </Form.Item>
    </Form>
  )
}

export default SubsidiariesForm
