import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../components/Misc/Loading'
import { ReportTableRow } from '../../../../../components/Table/types'
import {
  cashFlowStatementLoadingSelector,
  cashFlowByKeyStatementSelector
} from '../../../../../redux/context/reports/selectors'
import { CompanyDashboardItem } from '../../../../../types/dashboard/company/types'
import { formatValue, renderCell } from '../../../../reporting/cashFlow/utils'
import { errorSelector } from '../../../../../redux/error/selectors'
import ReportTable from '../../../../../components/Table/ReportTable'
import CashFlowChart from '../../../../reporting/cashFlow/CashFlowChart'
import { updateRowKeys } from '../../../../../redux/session/cashFlow/actions'
import { cashFlowKeysSelector } from '../../../../../redux/session/cashFlow/selectors'
import { AppDispatch } from '../../../../../redux/store'

interface CashFlowStatementProps {
  item: CompanyDashboardItem
  width: number
  height: number
}
export const CashFlowStatement: React.FC<CashFlowStatementProps> = ({
  item,
  width,
  height
}: CashFlowStatementProps) => {
  const loading = useSelector(cashFlowStatementLoadingSelector)
  const cashFlowStatement = useSelector(cashFlowByKeyStatementSelector)
  const error = useSelector(errorSelector)
  const { params } = item
  const dispatch: AppDispatch = useDispatch()

  const cashFlowSelectedKeys = useSelector(cashFlowKeysSelector)

  if (!params || !params.type) return null
  if (loading) return <Loading />

  const onExpand = (expanded: boolean, record: ReportTableRow) => {
    const { id } = record
    const index = cashFlowSelectedKeys.indexOf(id)
    index < 0
      ? dispatch(updateRowKeys([...cashFlowSelectedKeys, id]))
      : dispatch(updateRowKeys(cashFlowSelectedKeys.filter((k, i) => index !== i)))
  }

  switch (params.representation.type) {
    case 'chart':
      return (
        <CashFlowChart
          selectedGraph={params.selectedGraph}
          selectedRows={params.selectedRows}
          representationType={params.type}
          formatValue={formatValue}
          height={height}
          width={width}
          error={error?.cashFlowStatement}
        />
      )
    case 'table':
      return (
        <ReportTable
          toolbar={{ csvExport: false }}
          report={cashFlowStatement.filter(r => !r.exclude) || []}
          renderCell={renderCell}
          rowKey={(reportTableRow: ReportTableRow) => String(reportTableRow.id)}
          expandable={{
            expandedRowKeys: cashFlowSelectedKeys.map(String),
            onExpand
          }}
          error={error?.cashFlowStatement}
          rowClassName={(reportTableRow: ReportTableRow) => {
            return reportTableRow.bold ? 'bold' : ''
          }}
          yScroll={height}
        />
      )

    default:
      return null
  }
}
