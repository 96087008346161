import React from 'react'
import { useSelector } from 'react-redux'
import { Space, Typography, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { dimensionStringSelector, dimensionTextSelector } from '../../redux/context/filters/selectors'

const { Text } = Typography

const StyledText = styled(Text)`
  font-size: 1.1rem;
`

const DimensionText = () => {
  const { t } = useTranslation()
  const dimensions = useSelector(dimensionTextSelector)
  const dimensionsString = useSelector(dimensionStringSelector)

  return (
    <Tooltip title={dimensionsString}>
      <Space align="end">
        {dimensions?.reduce((and, sub, j, jarr) => {
          const orArr = sub.reduce((or, curr, i, arr) => {
            or.push(<StyledText>{curr}</StyledText>)
            if (i < arr.length - 1) or.push(<StyledText type="secondary">{t('global:or')}</StyledText>)
            return or
          }, [] as any)
          and.push(orArr)
          if (j < jarr.length - 1) and.push(<StyledText type="secondary">{t('global:and')}</StyledText>)
          return and
        }, [] as any)}
      </Space>
    </Tooltip>
  )
}

export default DimensionText
