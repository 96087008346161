export const formulaExamples = [
  {
    tab: 'formulaExamples:general',
    examples: [
      {
        name: 'keyFigureStatement:3',
        source: 'pl([5, 11, 12, 13], current)',
        type: 'absolute'
      },
      {
        name: 'keyFigureStatement:59',
        source: 'div(pl([5, 11, 12, 13], current), pl(5, current), 0)',
        type: 'absolute'
      },
      {
        name: 'keyFigureStatement:4',
        source: 'pl(25, current) - pl(21, current)',
        type: 'absolute'
      },
      {
        name: 'keyFigureStatement:12',
        source: 'div(pl(25, current) - pl(21, current), pl(1, current), 0)',
        type: 'percentage'
      },
      {
        name: 'balanceSheet:59',
        source: 'bs(59, current)',
        type: 'percentage'
      },
      {
        name: 'keyFigureStatement:60',
        source: 'div(-pl([15, 17], current), pl(5, current), 0)',
        type: 'percentage'
      },
      {
        name: 'keyFigureStatement:8',
        source: 'growth(pl, 5)',
        type: 'percentage'
      },
      {
        name: 'keyFigureStatement:44',
        source: 'div(bs([87, 101, 96], current), bs(139, current) - bs(145, current), 0)',
        type: 'ratio'
      },
      {
        name: 'keyFigureStatement:45',
        source: 'div(bs([71, 87, 96, 101], current), bs(139, current), 0)',
        type: 'ratio'
      },
      {
        name: 'keyFigureStatement:49',
        source: 'div(bs([104, 118, 119], current), bs(152, current) - bs([131, 145], current), 0)',
        type: 'percentage'
      }
    ]
  },
  {
    tab: 'formulaExamples:restaurants',
    examples: [
      {
        name: 'formulaExamples:salesFood',
        source: 'account(3000, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:salesDrinks',
        source: 'account(3010, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:salesAlcohol',
        source: 'account(3020, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:purchasesFood',
        source: 'account(4000, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:purchasesDrinks',
        source: 'account(4010, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:purchasesAlcohol',
        source: 'account(4020, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:productCoverage',
        source: 'account([3000~3020, 3060, 4000~4440], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:coverageFood',
        source: 'account([3000, 4000, 4400], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:coverageDrinks',
        source: 'account([3010, 4010, 4410], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:coverageAlcohol',
        source: 'account([3020, 4020, 4420], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:inventoryChangeFood',
        source: 'account(4400, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:inventoryChangeDrinks',
        source: 'account(4410, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:inventoryChangeAlcohol',
        source: 'account(4420, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:coverageFoodPercentage',
        source: 'div(account([3000, 4000, 4400], current), account(3000, current), 0)',
        type: 'percentage'
      },
      {
        name: 'formulaExamples:coverageDrinksPercentage',
        source: 'div(account([3010, 4010, 4410], current), account(3010, current), 0)',
        type: 'percentage'
      },
      {
        name: 'formulaExamples:coverageAlcoholPercentage',
        source: 'div(account([3020, 4020, 4420],current), account (3020, current), 0)',
        type: 'percentage'
      },
      {
        name: 'formulaExamples:workEfficiency',
        source: 'div(pl(5, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:averagePurchase',
        source: 'div(pl(5, current), receipts(restolution, quantity, period), 0)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:logistics',
    examples: [
      {
        name: 'formulaExamples:equipmentCosts',
        source: 'account([6350~6369, 6200~6280], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:travelingExpenses',
        source: 'account([6305~6341, 6415], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:drivenKilometers',
        source: 'route(mapon, distance, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:fuelCosts',
        source: 'account(4010, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:fuelCostsPerKmDriven',
        source: 'div(account(4010, current), route(mapon, distance, current), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:turnoverPerKmDriven',
        source: 'div(pl(5, current), route(mapon, distance, current), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:salesMarginPerKmDriven',
        source: 'div(pl([5, 11, 12, 13], current), route(mapon, distance, current), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:grossMarginPerKmDriven',
        source: 'div(pl(25, current) - pl(21, current), route(mapon, distance, current), 0)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:indusrial',
    examples: [
      {
        name: 'formulaExamples:variableStaffCosts',
        source: 'account(4510~4689, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:variableOperatingExpenses',
        source: 'account([7010, 7200, 7300~7340], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:internalSalesMargin',
        source: 'pl([5, 8, 9], current) + account(4510~4689, current) + account([7010, 7200, 7300~7340], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:fixedStaffCosts',
        source: 'account([5010~5990, 6100~6150, 6300~6500], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:fixedOtherOperatingExpenses',
        source: 'account([7000~7009, 7011~7199, 7201~7299, 7341~8804], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:materialAndSuppliesStorage',
        source: 'account([1502,1554], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:workInProgressAndFinishedWarehouse',
        source: 'account([1512, 1521], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:changeInFinishedStock',
        source: 'account([3604, 3614~3616], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:purchasesOfMaterialsAndSupplies',
        source: '-pl(10, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:RD',
        source: 'account(8300~8354, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:RDPercentage',
        source: 'div(account(8300~8354, current),account(7000~8804, current), 0)',
        type: 'percentage'
      }
    ]
  },
  {
    tab: 'formulaExamples:trade',
    examples: [
      {
        name: 'formulaExamples:premisesCosts',
        source: 'account([7230~7470], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:machineryAndEquipmentCosts',
        source: 'account([7500~7775, 77701], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:travelingExpenses',
        source: 'account([7800~7893], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:representationExpenses',
        source: 'account(7950, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:marketingCosts',
        source: 'account([8000~8300], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:administrativeExpenses',
        source: 'account([8370~8680], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:otherOperatingExpenses',
        source: 'account([8700~8990], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:workEfficiency',
        source: 'div(pl(5, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:averagePurchase',
        source: 'div(pl(5, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:SaaS',
    examples: [
      {
        name: 'formulaExamples:ARPA',
        source: 'div(pl(5, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:CAC',
        source: 'div(account(4490, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:MRR',
        source: 'account([3000,3010], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:newUsers',
        source: 'gs(code, key, aggregation function, period)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:allUsers',
        source: 'gs(code, key, aggregation function, period)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:realEstateInvestment',
    examples: [
      {
        name: 'formulaExamples:rentalIncome',
        source: 'account(3002~3007, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:compensation',
        source: '-account(4002~4003, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:rentalincomeCompensation',
        source: 'account([3002~3007, 4002~4003], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:rentalIncomeVsCompensation',
        source: 'div(account(3002~3007, current), account(4002~4003, current), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:totalRentalIncomePerYearPercentage',
        source: 'div(account(3002~3007, pastyear), bs(67, current), 0)',
        type: 'percentage'
      }
    ]
  },
  {
    tab: 'formulaExamples:associations',
    examples: [
      {
        name: 'formulaExamples:actualAction',
        source: 'pl(154, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:operatingIncome',
        source: 'pl(155, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:operatingCosts',
        source: 'pl(157, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:generalAllowances',
        source: 'pl(177, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:groupGrantsReceived',
        source: 'pl(188, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:groupGrantsAwarded',
        source: 'pl(189, current)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:services',
    examples: [
      {
        name: 'formulaExamples:premisesCosts',
        source: 'account([7230~7470], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:marketingCosts',
        source: 'account([8000~8300], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:administrativeExpenses',
        source: 'account([8370~8680], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:otherOperatingExpenses',
        source: 'account([8700~8990], current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:newCustomersPerMonth',
        source: 'gs(code, key, aggregation function, period)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:allCustomers',
        source: 'gs(code, key, aggregation function, period)',
        type: 'absolute'
      }
    ]
  },
  {
    tab: 'formulaExamples:construction',
    examples: [
      {
        name: 'formulaExamples:purchases',
        source: 'account(4000, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:toolsAndAccessories',
        source: 'account(4020~4040, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:workAndProtectiveClothing',
        source: 'account(4050, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:externalServices',
        source: 'pl(13, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:employeeWages',
        source: 'account(4500, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:hours',
        source: 'gs(code, key, aggregation function, period)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:employeeWagesPerHours',
        source: 'div(account(4500, current), gs(code, key, aggregation function, period), 0)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:rawMaterialsAndConsumables',
        source: 'pl(10, current)',
        type: 'absolute'
      },
      {
        name: 'formulaExamples:machineryAndEquipment',
        source: 'account(1161, current)',
        type: 'absolute'
      }
    ]
  }
]
