import { PlusOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DeliverySubscription,
  DeliverySubscriptionType
} from '../../../../../types/deliverySubscription/DeliverySubscription'
import DeliverySubscriptionTable from './components/DeliverySubscriptionTable'
import DeliverySubscriptionModal from './components/DeliverySubscriptionModal'

const AccountorScorecardSubscriptions: React.FC = () => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [deliverySubscription, setDeliverySubscription] = useState<DeliverySubscription>()

  const toggleModalVisible = () => {
    setModalVisible(!modalVisible)
    if (!modalVisible) setDeliverySubscription(undefined)
  }

  const toggleDeliverySubscription = (deliverySubscriptionToUpdate: DeliverySubscription) => {
    setModalVisible(true)
    setDeliverySubscription(deliverySubscriptionToUpdate)
  }

  const items = [
    {
      label: t('subscriptionsPage:automatic-sent'),
      key: 'automatic',
      children: (
        <DeliverySubscriptionTable
          subscriptionType={DeliverySubscriptionType.ACCOUNTOR_SCORECARD}
          toggleDeliverySubscription={toggleDeliverySubscription}
          type="auto"
        />
      )
    },
    {
      label: t('subscriptionsPage:manual-sent'),
      key: 'manual',
      children: (
        <DeliverySubscriptionTable
          subscriptionType={DeliverySubscriptionType.ACCOUNTOR_SCORECARD}
          toggleDeliverySubscription={toggleDeliverySubscription}
          type="manual"
        />
      )
    }
  ]

  return (
    <>
      <Button onClick={toggleModalVisible} style={{ marginBottom: 8 }}>
        <PlusOutlined />
        {t('global:add-new')}
      </Button>
      <DeliverySubscriptionModal
        subscriptionType={DeliverySubscriptionType.ACCOUNTOR_SCORECARD}
        visible={modalVisible}
        initiatorOptions={['monthly', 'periodLock', 'manual']}
        deliverySubscription={deliverySubscription}
        toggleModalVisible={toggleModalVisible}
      />

      <Tabs defaultActiveKey="1" items={items} />
    </>
  )
}

export default AccountorScorecardSubscriptions
