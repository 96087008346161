import {
  ReferenceValueRule,
  ReferenceValueRuleOperator,
  ReferenceValueBinaryRule
} from '../../../../../../reporting/keyFigures/components/referenceValueTable/types'
import { evaluateReferenceValueRule } from '../../../../../../reporting/keyFigures/utils'

interface RulesCount {
  [key: string]: number
}

export const countRules = (data?: ReferenceValueRule[]): RulesCount => {
  const countedRules: RulesCount = Object.create(null)
  for (const rule of data || []) {
    if (rule?.operator) {
      const currCount = countedRules[rule.operator] ?? 0
      countedRules[rule.operator] = currCount + 1
    }
  }
  return countedRules
}

export const testRuleAvaibility = (operator: string, value: string, rulesCount: RulesCount) => {
  switch (operator) {
    case ReferenceValueRuleOperator.gt:
    case ReferenceValueRuleOperator.gte:
      if (value === ReferenceValueRuleOperator.gt || value === ReferenceValueRuleOperator.gte) {
        return true
      }
      return (
        (rulesCount?.[ReferenceValueRuleOperator.gt] || 0) + (rulesCount?.[ReferenceValueRuleOperator.gte] || 0) < 1
      )
    case ReferenceValueRuleOperator.lt:
    case ReferenceValueRuleOperator.lte:
      if (value === ReferenceValueRuleOperator.lt || value === ReferenceValueRuleOperator.lte) {
        return true
      }
      return (
        (rulesCount?.[ReferenceValueRuleOperator.lt] || 0) + (rulesCount?.[ReferenceValueRuleOperator.lte] || 0) < 1
      )
    default:
      return true
  }
}

export const ruleAvailable = (operator: string, value: string, rules: ReferenceValueRule[]) => {
  const countedRules = countRules(rules)
  return testRuleAvaibility(operator, value, countedRules)
}

export const testBetweenOverlap = (rule: ReferenceValueRule, value: [number, number]) => {
  if (rule.operator === ReferenceValueRuleOperator.gte) {
    return evaluateReferenceValueRule(rule, value[1])
  }
  if (rule.operator === ReferenceValueRuleOperator.gt) {
    return value?.[1] > rule.value
  }
  if (rule.operator === ReferenceValueRuleOperator.lte) {
    return evaluateReferenceValueRule(rule, value[0])
  }
  if (rule.operator === ReferenceValueRuleOperator.lt) {
    return value?.[0] < rule.value
  }
  if (rule.operator === ReferenceValueRuleOperator.btw && Array.isArray(value)) {
    return value?.some(v => evaluateReferenceValueRule(rule, v))
  }
  return false
}

export const testSelfBetweenOverlap = (self: ReferenceValueBinaryRule, testRule: ReferenceValueRule) => {
  if (testRule.operator === ReferenceValueRuleOperator.gt) {
    return testRule.value < self.value[1]
  }
  if (testRule.operator === ReferenceValueRuleOperator.gte) {
    return evaluateReferenceValueRule(self, testRule.value)
  }
  if (testRule.operator === ReferenceValueRuleOperator.lte) {
    return evaluateReferenceValueRule(self, testRule.value)
  }
  if (testRule.operator === ReferenceValueRuleOperator.lt) {
    return testRule.value > self.value[0]
  }
  if (testRule.operator === ReferenceValueRuleOperator.btw && Array.isArray(testRule.value)) {
    return testRule.value?.some(v => evaluateReferenceValueRule(self, v))
  }
  return false
}
