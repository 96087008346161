import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { SetKeyFiguresAction, SET_KEY_FIGURES } from './types'

export const setKeyFigures = (statementRows: KeyFigure[]): SetKeyFiguresAction => ({
  type: SET_KEY_FIGURES,
  payload: statementRows
})

export const getKeyFiguresRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/key-figures/`,
    success: (payload: KeyFigure[]) => setKeyFigures(payload)
  },
  meta: {
    type: 'KEY_FIGURES'
  }
})
