import { Company } from '../../../types/company/Company'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddFiscalYearsAction,
  ADD_FISCAL_YEAR,
  DeleteFiscalYearsAction,
  DELETE_FISCAL_YEAR,
  SetFiscalYearsAction,
  SET_FISCAL_YEARS,
  UpdateFiscalYearsAction,
  UPDATE_FISCAL_YEAR
} from './types'

export const setFiscalYears = (fiscalYears: FiscalYear[]): SetFiscalYearsAction => ({
  type: SET_FISCAL_YEARS,
  payload: fiscalYears
})

export const updateFiscalYear = (fiscalYear: FiscalYear): UpdateFiscalYearsAction => ({
  type: UPDATE_FISCAL_YEAR,
  payload: fiscalYear
})

export const addFiscalYear = (fiscalYear: FiscalYear): AddFiscalYearsAction => ({
  type: ADD_FISCAL_YEAR,
  payload: fiscalYear
})

export const deleteFiscalYear = (fiscalYear: FiscalYear): DeleteFiscalYearsAction => ({
  type: DELETE_FISCAL_YEAR,
  payload: fiscalYear
})

export const getFiscalYearsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/fiscal-years`,
    success: (payload: FiscalYear[]) => setFiscalYears(payload)
  },
  meta: {
    type: 'FISCAL_YEARS'
  }
})

export const createFiscalYearRequest = (companyId: Company['id'], payload: FiscalYear): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: payload,
    url: `/api/companies/${companyId}/accounting/fiscal-years`,
    success: (response: FiscalYear) => addFiscalYear(response)
  },
  meta: {
    type: 'CREATE_FISCAL_YEAR',
    notification: true
  }
})

export const updateFiscalYearRequest = (
  companyId: Company['id'],
  payload: FiscalYear,
  fiscalYearId: FiscalYear['id']
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    url: `/api/companies/${companyId}/accounting/fiscal-years/${fiscalYearId}`,
    success: (response: FiscalYear) => updateFiscalYear(response)
  },
  meta: {
    type: 'UPDATE_FISCAL_YEAR',
    notification: true
  }
})

export const deleteFiscalYearRequest = (
  companyId: Company['id'],
  fiscalYearId: FiscalYear['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/accounting/fiscal-years/${fiscalYearId}`,
    success: (response: FiscalYear) => deleteFiscalYear(response)
  },
  meta: {
    type: 'DELETE_FISCAL_YEAR',
    notification: true
  }
})
