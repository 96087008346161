import { Company } from '../company/Company'

export enum ForecastType {
  MANUAL = 'MANUAL',
  PERIOD_LOCK = 'PERIOD_LOCK', // Toteuma viimeisimpään suljettuun ALV-kauteen saakka
  CURRENT = 'CURRENT', // Toteuma aina käynnissä olevaa kuukautta edeltävään kuukauteen saakka
  PREVIOUS = 'PREVIOUS' // Toteuma aina käynnissä olevaa kuukautta edeltänyttä kuukautta edeltävään kuukauteen saakka
}

export interface BudgetingScenario {
  id: number
  companyId?: Company['id']
  name?: string
  forecastCursor?: string
  isLocked?: boolean
  description?: string
  templateBudgetingScenarioId?: number
  forecastType?: ForecastType
  subsidiaries?: BudgetingScenario[]
  transfers?: BudgetingScenarioTransfer[]
  transferLog?: BudgetingScenarioTransfer[]
}

export interface BudgetingScenarioTransfer {
  /** Skenaario, jolta kopiointi on tehty */
  sourceId?: number
  /** Mitä dataa on kopioitu */
  entity: BudgetingScenarioEntity
}

export enum BudgetingScenarioEntity {
  /** Budjetointiasetukset */
  settings = 'settings',
  /** Tulos (tilikohtaiset budjetointitavat etc.) */
  budget = 'budget',
  /** Olemassa olevat lainat */
  existingLoans = 'existingLoans',
  /** Olemassa oleva käyttöomaisuus (Professional) */
  existingInvestments = 'existingInvestments',
  /** Poistosuunnitelmat (Basic) */
  deprecationPlans = 'deprecationPlans',
  /** Investoinnit ja suunnitellut rahoituksen lähteet */
  newInvestments = 'newInvestments',
  /** Lisäykset / vähennykset */
  amendments = 'amendments'
}
