import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, ModalFuncProps } from 'antd'
import i18 from 'i18next'

const deleteConfirmModal = (props?: ModalFuncProps) => {
  Modal.confirm({
    title: i18.t('modal:deleteTitle', 'Haluatko varmasti poistaa?'),
    icon: <ExclamationCircleOutlined />,
    okText: i18.t('global:yes'),
    okType: 'danger',
    cancelText: i18.t('global:no'),
    ...props
  })
}

export default deleteConfirmModal
