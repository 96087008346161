import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

export const BugsnagWrapper: React.FC<any> = ({ children }) => {
  const enabled = !!process.env.REACT_APP_BUGSNAG_API_KEY

  if (enabled) {
    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
      plugins: [new BugsnagPluginReact()]
    })
    const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
    return <ErrorBoundary>{children}</ErrorBoundary>
  }

  return children
}
