import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { Store } from '../../types'
import { ReportDataType } from '../reports/types'
import { dimensionListSelector, dimensionTreeSelector } from '../dimensions/selectors'
import { DimensionTree } from '../../../components/Dimension/DimensionTree'
import { recursiveFind } from '../../../utils/helpers'

export const contexFiltersSelector = (store: Store) => {
  return store.context.filters
}

export const filtersSelector = createSelector(contexFiltersSelector, filters => {
  const { dataTypes, ...rest } = filters
  const order = [
    ReportDataType.actuals,
    ReportDataType.comparativePeriod,
    ReportDataType.budget,
    ReportDataType.forecast
  ]
  const orderedDataTypes = order.filter(type => dataTypes.includes(type))
  return { ...rest, dataTypes: orderedDataTypes }
})

export const dimensionsFilterSelector = createSelector(
  [filtersSelector, dimensionListSelector],
  ({ dimensions }, dimensionList) => {
    return dimensions?.map(id => dimensionList.find(d => d.dimensionId === id))
  }
)

export const selectedDimensionFilterSelector = createSelector(
  [filtersSelector, dimensionTreeSelector],
  ({ dimensions }, dimensionTree) => {
    return recursiveFind(dimensionTree, dimensions?.[0], 'dimensionId')
  }
)

export const periodGroupSelector = (store: Store) =>
  (store.context && store.context.filters && store.context.filters.periodGroups) || [] // .map((group, id) => ({ ...group, id: id + 1 }))

export const sortedPeriodGroups = createSelector(periodGroupSelector, periodGroups => {
  const sortedPeriodGroupsArray: PeriodGroup[] = periodGroups
    .slice()
    .sort((n1: PeriodGroup, n2: PeriodGroup) => +new Date(n1.endDate) - +new Date(n2.endDate))
  return sortedPeriodGroupsArray
})

export const forecastCursorSelector = createSelector(filtersSelector, ({ budgetingScenario }) => {
  return budgetingScenario?.forecastCursor ? dayjs(budgetingScenario.forecastCursor) : undefined
})

export const DTSelector = createSelector(dimensionListSelector, list => {
  const tree = new DimensionTree()
  tree.build(list)
  return tree
})

export const dimensionQuerySelector = createSelector([filtersSelector, DTSelector], ({ dimensions }, DT) => {
  const query = DT.getQuery(dimensions)
  return query
})

export const dimensionStringSelector = createSelector([filtersSelector, DTSelector], ({ dimensions }, DT) => {
  const values = DT.getDimensionText(dimensions)
  return values
})

export const dimensionTextSelector = createSelector([filtersSelector, DTSelector], ({ dimensions }, DT) => {
  const values = DT.getNamedSubTrees(dimensions)
  return values
})
