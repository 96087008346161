import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import DashboardItemFields from './DasboardItemFieds'

const DashboardTopItemFields = () => {
  const { t } = useTranslation()

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('global:quantity')} name={['params', 'quantity']}>
        <Select style={{ width: 120 }}>
          {[3, 5, 10, 20, 30, 40, 50].map(val => (
            <Select.Option value={val}>{val}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export default DashboardTopItemFields
