import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SET_STATEMENT_ROWS, SetStatementRowsAction } from './types'
import { StatementRow } from '../../../types/statementRow/StatementRow'

export const setStatementRows = (statementRows: StatementRow[]): SetStatementRowsAction => ({
  type: SET_STATEMENT_ROWS,
  payload: statementRows
})

export const getStatementRowsRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/statement-rows`,
    success: (payload: StatementRow[]) => setStatementRows(payload)
  },
  meta: {
    type: 'STATEMENT_ROWS'
  }
})
