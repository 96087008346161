import { Form, Select } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'
import { getContextByPropertySelector } from '../../../../../../../redux/context/selectors'
import { Context } from '../../../../../../../redux/context/types'
import { loadingSelector } from '../../../../../../../redux/loading/selectors'
import { GraphType } from '../../../types'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from '../../DashboardItemProvider'
import DashboardItemFields from '../DasboardItemFieds'
import SelectionTableInput from '../SelectionTableInput'

export type BaseSalesItem = {
  id: number
  name: string
}

const SalesChartFields = () => {
  const { t } = useTranslation()
  const { companyDashboardItemPreferenceSelection } = useContext(
    CompanyDashboardItemContext
  ) as CompanyDashboardContextType

  const record = companyDashboardItemPreferenceSelection?.params.record
  const salesItems: BaseSalesItem[] = useSelector(getContextByPropertySelector(record as keyof Context))
  const loading = useSelector(loadingSelector)

  const columns: ColumnProps<BaseSalesItem>[] = [
    {
      title: t(`global:name`),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('name')
    }
  ]

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('dashboardPage:chart-type')} name={['params', 'representation', 'options', 'type']}>
        <Select
          options={Object.values(GraphType).map(type => ({
            value: type,
            label: t(`dashboardPage:${type}`)
          }))}
        />
      </Form.Item>
      <Form.Item label={t(`global:${record?.slice(0, -1)}`)} name={['params', 'ids']}>
        <SelectionTableInput<BaseSalesItem>
          loading={!!record && loading[record]}
          dataSource={salesItems}
          columns={columns}
        />
      </Form.Item>
    </>
  )
}

export default SalesChartFields
