import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Contract, ContractFranchise } from '../../../../types/contract/Contract'
import { contractProductsSelector } from '../../../../redux/entities/contractProducts/selectors'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../redux/store'

interface SuperUserContractBasicsProps {
  contract: Contract
}

const SuperUserContractBasics: React.FC<SuperUserContractBasicsProps> = ({ contract: contractProp }) => {
  const getContractsRequest = useBackend(`/api/superuser/contracts`)
  const updateContractRequest = useBackend(`/api/superuser/contracts/{contractId}`)

  const { loading } = getContractsRequest
  const [contracts, setContracts] = useState<Contract[]>([])
  const [contract, setContract] = useState<Contract>(contractProp)
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const products = useSelector(contractProductsSelector)
  const [form] = Form.useForm<Contract>()

  const sendGetContractsRequest = () => {
    getContractsRequest
      .get({})
      .then(setContracts)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACTS_ERROR',
            description: e.message
          })
        )
      })
  }

  const sendUpdatecontractRequest = (values?: Partial<Contract>) => {
    updateContractRequest
      .put({ urlParams: { contractId: contract.id }, body: { data: { ...values } } })
      .then((res: Contract) => {
        setContract({ ...contract, ...res })
        dispatch(
          notificationAction({
            type: 'success',
            message: 'OK'
          })
        )
      })
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACTS_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(sendGetContractsRequest, [])
  useEffect(() => form.resetFields(), [contractProp])

  return (
    <Form
      name="user"
      initialValues={contract}
      form={form}
      onFinish={sendUpdatecontractRequest}
      className="profile-form"
      layout="vertical"
    >
      <Form.Item name="parentId" label="Parent">
        <Select loading={loading} disabled={loading} allowClear showSearch optionFilterProp="children">
          {contracts.map(c => (
            <Select.Option value={c.id} key={c.id}>
              {c.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="product" label="Tuote">
        <Select>
          {Object.keys(products).map(product => (
            <Select.Option value={product} key={product}>
              {t(`contractProduct:${product}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="type" label="Tyyppi">
        <Select>
          {['ACCOUNTANT', 'COMPANY'].map(type => (
            <Select.Option value={type} key={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="franchise" label="Franchise">
        <Select allowClear>
          {Object.entries(ContractFranchise).map(([key, value]) => (
            <Select.Option value={value} key={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="layout" label="Layout">
        <Input />
      </Form.Item>
      <Form.Item name="domain" label="domain">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button loading={updateContractRequest.loading} type="primary" htmlType="submit" className="login-form-button">
          {t('global:update')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SuperUserContractBasics
