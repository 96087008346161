import React from 'react'
import { Button, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { UserPasswordCredentials } from '../../types/user/User'
import { useBackend } from '../../services/backend'

interface PasswordProps {
  onSubmit: (values: UserPasswordCredentials) => void
}

const PasswordForm = ({ onSubmit }: PasswordProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const validatePasswordRequest = useBackend('/api/validate-password')

  const testPassword = (password: string): Promise<string[]> => {
    const response = validatePasswordRequest.post({
      body: {
        data: { password }
      }
    })
    return response
  }

  const onFinish = (values: UserPasswordCredentials) => {
    onSubmit(values)
    form.resetFields()
    return null
  }

  return (
    <Form form={form} onFinish={onFinish} className="password-form" layout="vertical">
      <Form.Item
        name="password"
        label={t('usersPage:password')}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('global:required-field')
          },
          {
            validator: async (__, value) => {
              const testResult = await testPassword(value)

              if (!value || testResult?.length === 0) {
                return Promise.resolve()
              }

              return Promise.reject(new Error(testResult?.map(e => t(`password:${e}`)).join(' ')))
            }
          }
        ]}
      >
        <Input.Password placeholder={t('usersPage:password')} />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label={t('usersPage:confirm-password')}
        hasFeedback
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: t('global:required-field')
          },
          ({ getFieldValue }) => ({
            validator(__, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('usersPage:passwords-no-match')))
            }
          })
        ]}
      >
        <Input.Password placeholder={t('usersPage:confirm-password')} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          {t('global:update')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PasswordForm
