export const SET_LOAN_REPAYMENT_METHODS = 'SET_LOAN_REPAYMENT_METHODS'

export interface LoanRepaymentMethod {
  id: number
  name: string
}

export interface SetLoanRepaymentMethods {
  type: typeof SET_LOAN_REPAYMENT_METHODS
  payload: LoanRepaymentMethod[]
}

export type LoanRepaymentMethodsActionTypes = SetLoanRepaymentMethods
