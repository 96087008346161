/* eslint-disable import/no-cycle */
import { WhiteLabel } from '../../config/customerThemes'
import { User } from '../user/User'

export enum ContractProduct {
  LIGHT = 'LIGHT',
  BASIC = 'BASIC',
  PRO = 'PRO'
}

export enum ContractFranchise {
  KOTIPIZZA = 'KOTIPIZZA',
  KIINTEISTÖMAAILMA = 'KIINTEISTÖMAAILMA',
  LAATUTAKUU = 'LAATUTAKUU'
}

export interface Contract {
  id: number
  parentId?: number
  additionalInformation: string
  // company: Company
  companyId: string
  date: string
  domain: string
  layout: WhiteLabel
  sellerId?: number
  price?: number
  product: ContractProduct
  name: string
  signed: string
  subscription: string
  type: string
  franchise: ContractFranchise
  users: User[]
  parent: Contract
  children: Contract[]
}

export enum ContractFormStep {
  Parties,
  Product,
  Order,
  Result
}
