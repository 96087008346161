import { Dayjs } from 'dayjs'
import { generateDataIndex } from '../../../components/Table/utils'
import { FiscalYear, Period } from '../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { periodLabel } from '../fiscalYears/utils'
import { ReportDataType } from '../reports/types'
import { Column } from './hooks'

export const getPreviousPeriodGroupDataIndex = (
  column: Column,
  sortedGroups: FiscalYear[] | PeriodGroup[]
): string | undefined => {
  let previousGroupIndex = 0
  if (column.periodGroup) {
    previousGroupIndex = sortedGroups.findIndex((g: PeriodGroup | FiscalYear) => g.id === column.periodGroup?.id) - 1
    if (previousGroupIndex > 0) {
      return generateDataIndex(column.dataType, sortedGroups[previousGroupIndex].id)
    }
  }
  return undefined
}

export const getPreviousPeriodGroup = (
  sortedGroups: FiscalYear[] | PeriodGroup[],
  groupId?: PeriodGroup['id']
): FiscalYear | PeriodGroup | undefined => {
  let previousGroupIndex = 0

  previousGroupIndex = sortedGroups.findIndex((g: PeriodGroup | FiscalYear) => g.id === groupId) - 1
  if (previousGroupIndex > 0) {
    return sortedGroups[previousGroupIndex]
  }

  return undefined
}

export const getPreviousPeriodDataIndex = (column: Column): string | 0 | undefined => {
  return column.year && generateDataIndex(column.dataType, undefined, column.year - 1, column.month)
}

const getColumnClassName = (period: Period, dataType: ReportDataType, forecastCursor: '' | Dayjs | undefined) => {
  if (
    forecastCursor &&
    (period.year > forecastCursor.year() ||
      (period.year === forecastCursor.year() && period.month > forecastCursor.month() + 1))
  ) {
    return `${dataType}-column-bright`
  }
  return `${dataType}-column`
}

export const periodColumns = (period: Period, dataType: ReportDataType, forecastCursor: '' | Dayjs | undefined) => {
  return {
    label: periodLabel(period, dataType),
    year: period.year,
    month: period.month,
    dataType,
    className: getColumnClassName(period, dataType, forecastCursor),
    expandable: true,
    dataIndex: generateDataIndex(dataType, undefined, period.year, period.month)
  }
}
