export const SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS = 'SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS'
export const ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY = 'ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY'

export interface ReallocationsPage {
  expandedRowKeys: number[]
}

export interface SetReallocationsPageExpandedRowKeysAction {
  type: typeof SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS
  payload: number[]
}
export interface AddReallocationsPageExpandedRowKeyAction {
  type: typeof ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY
  payload: number
}

export type ReallocationActionTypes =
  | SetReallocationsPageExpandedRowKeysAction
  | AddReallocationsPageExpandedRowKeyAction
