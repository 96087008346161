import { Badge, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { keyFigureReferenceColors } from './config'
import { ReferenceValue } from './types'

interface ReferenceValueTableProps {
  referenceValues: ReferenceValue[]
}

const ReferenceValueTable: React.FC<ReferenceValueTableProps> = (props: ReferenceValueTableProps) => {
  const { t, i18n } = useTranslation()
  const { referenceValues } = props
  const columns: ColumnProps<ReferenceValue>[] = [
    {
      title: t(`keyFigurePage:key-figure`),
      key: 'name',
      render: (referenceValue: ReferenceValue) => t(`keyFigureStatement:${referenceValue.keyFigureId}`)
    },
    {
      title: t(`keyFigurePage:excellent`),
      key: 'excellent',
      render: (referenceValue: ReferenceValue) => {
        return {
          children: (
            <Badge
              color={keyFigureReferenceColors.excellent}
              text={`${referenceValue.excellent.operator} ${referenceValue.excellent.value.toLocaleString(
                i18n.language
              )}`}
            />
          )
        }
      }
    },
    {
      title: t(`keyFigurePage:good`),
      key: 'good',
      render: (referenceValue: ReferenceValue) => {
        return {
          children: (
            <Badge
              color={keyFigureReferenceColors.good}
              text={`${referenceValue.good.operator} ${referenceValue.good.value.toLocaleString(i18n.language)}`}
            />
          )
        }
      }
    },
    {
      title: t(`keyFigurePage:satisfying`),
      key: 'satisfying',
      render: (referenceValue: ReferenceValue) => {
        return {
          children: (
            <Badge
              color={keyFigureReferenceColors.satisfying}
              text={`${referenceValue.satisfying.operator} ${referenceValue.satisfying.value.toLocaleString(
                i18n.language
              )}`}
            />
          )
        }
      }
    },
    {
      title: t(`keyFigurePage:passable`),
      key: 'passable',
      render: (referenceValue: ReferenceValue) => {
        return {
          children: (
            <Badge
              color={keyFigureReferenceColors.passable}
              text={`${referenceValue.passable.operator} ${referenceValue.passable.value.toLocaleString(
                i18n.language
              )}`}
            />
          )
        }
      }
    }
  ]

  return <Table scroll={{ x: 600 }} size="small" dataSource={referenceValues} columns={columns} pagination={false} />
}

export default ReferenceValueTable
