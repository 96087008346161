import { InvestmentFundingSource } from '../../../types/investment/InvestmentFundingSource'
import { GET, GetRequestAction, REQUEST } from '../../middleware/types'
import { SetInvestmentFundingSources, SET_INVESTMENT_FUNDING_SOURCES } from './types'

export const setInvestmentFundingSources = (
  investmentFundingSources: InvestmentFundingSource[]
): SetInvestmentFundingSources => ({
  type: SET_INVESTMENT_FUNDING_SOURCES,
  payload: investmentFundingSources
})

export const getInvestmentFundingSourceRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/investment-funding-sources`,
    success: (payload: InvestmentFundingSource[]) => setInvestmentFundingSources(payload)
  },
  meta: {
    type: 'INVESTMENT_FUNDING_SOURCES'
  }
})
