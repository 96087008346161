import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import React from 'react'
import LogoUpload from '../../../../../components/LogoUpload/LogoUpload'

const { Text } = Typography

const PDFReports = () => {
  const { t } = useTranslation()

  return (
    <>
      <LogoUpload route="company" />
      <Text type="secondary">{t('appearancePage:logo-description')}</Text>
    </>
  )
}

export default PDFReports
