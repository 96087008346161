import { Investment } from '../../../types/investment/Investment'
import { ADD_INVESTMENT, InvestmentActionTypes, SET_INVESTMENTS, UPDATE_INVESTMENT } from './types'

const initialState: Investment[] = []

const investmentsReducer = (state = initialState, action: InvestmentActionTypes) => {
  switch (action.type) {
    case SET_INVESTMENTS:
      return [...action.payload]
    case ADD_INVESTMENT:
      return [...state, action.payload]
    case UPDATE_INVESTMENT:
      return state.map(investment => (investment.id !== action.payload.id ? investment : action.payload))
    default:
      return state
  }
}

export default investmentsReducer
