import { Company } from '../../../types/company/Company'
import { ADD_SUBSIDIARY, SubsidiaryActionTypes, DELETE_SUBSIDIARY, SET_SUBSIDIARIES, UPDATE_SUBSIDIARY } from './types'

const initialState: Company[] = []

const corporationReducer = (state = initialState, action: SubsidiaryActionTypes): any => {
  switch (action.type) {
    case SET_SUBSIDIARIES:
      return [...action.payload]
    case ADD_SUBSIDIARY:
      return [...action.payload]
    case DELETE_SUBSIDIARY:
      return state.filter((subsidiary: Company) => subsidiary.id !== action.payload.id)
    case UPDATE_SUBSIDIARY:
      return state.map((subsidiary: Company) => {
        if (subsidiary.id === action.payload.id) {
          return action.payload
        }
        return subsidiary
      })

    default:
      return state
  }
}

export default corporationReducer
