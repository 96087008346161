import { createSelector } from 'reselect'
import { ReportTableRow } from '../../../components/Table/types'
import { BudgetingLevel } from '../../../types/companySettings/CompanySettings'
import { Column } from '../periodGroups/hooks'
import { ReportRowType } from '../reports/types'
import { incomeStatementSelector } from '../reports/selectors'
import { companySettingsSelector } from '../companySettings/selectors'

export const getBudgetCellComments = (row: ReportTableRow, column: Column) =>
  createSelector([companySettingsSelector, incomeStatementSelector], (settings, incomeStatement) => {
    if (row.budgetComments && row.key?.startsWith(ReportRowType.dimension)) {
      const comments = row.budgetComments?.filter(c => {
        if (c.fiscalYearId) {
          return c.fiscalYearId === column?.periodGroup?.id
        }
        return c.year === column.year && c.month === column.month
      })
      return comments?.slice().sort((a, b) => a.id - b.id) || []
    }

    const budgetingLevel = settings?.budgeting?.level
    const reportRow =
      budgetingLevel === BudgetingLevel.statementRow
        ? incomeStatement.find(r => r.id === row.id)
        : incomeStatement.flatMap(r => r.accounts).find(account => account?.code === row.code)

    if (!reportRow) return []

    const comments = reportRow?.budgetComments?.filter(c => {
      if (c.fiscalYearId) {
        return c.fiscalYearId === column?.periodGroup?.id
      }
      return c.year === column.year && c.month === column.month
    })
    return comments?.slice().sort((a, b) => a.id - b.id) || []
  })
