import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'

export const companySettingsSelector = createSelector([contextSelector], context => {
  return context && context.companySettings
})

export const companySettingsChartColorSelector = createSelector([contextSelector], context => {
  return context && context.companySettings && context.companySettings.chartColors
})

export const companySettingsLoadingSelector = createSelector(loadingSelector, loading => loading.companySettings)
