import { Company } from '../../../types/company/Company'

export const SET_CONTEXT_COMPANY = 'SET_CONTEXT_COMPANY'

export interface SetCompanyAction {
  type: typeof SET_CONTEXT_COMPANY
  payload?: Company
}

export type ContextCompanyActionTypes = SetCompanyAction
