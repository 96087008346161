import { useTranslation } from 'react-i18next'
import { Typography, List, Tabs } from 'antd'
import React, { useMemo } from 'react'
import { formulaExamples } from './FormulaExamples'

const { Text } = Typography

const FormulaHelpExamples = () => {
  const { t } = useTranslation()

  const tabItems = useMemo(
    () =>
      formulaExamples.map(formulaExample => ({
        label: t(formulaExample.tab),
        key: formulaExample.tab,
        children: (
          <List
            itemLayout="horizontal"
            dataSource={formulaExample.examples}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={t(item.name)}
                  description={
                    <Text code copyable>
                      {item.source}
                    </Text>
                  }
                />
              </List.Item>
            )}
          />
        )
      })),
    []
  )

  return <Tabs defaultActiveKey={formulaExamples[0].tab} items={tabItems} tabPosition="left" />
}

export default FormulaHelpExamples
