import { BudgetDriver } from '../../../types/budgetDriver/BudgetDriver'

export const SET_BUDGET_DRIVERS = 'SET_BUDGET_DRIVERS'
export const ADD_BUDGET_DRIVER = 'ADD_BUDGET_DRIVER'
export const UPDATE_BUDGET_DRIVER = 'UPDATE_BUDGET_DRIVER'
export const DELETE_BUDGET_DRIVER = 'DELETE_BUDGET_DRIVER'

export interface SetBudgetDriversAction {
  type: typeof SET_BUDGET_DRIVERS
  payload: BudgetDriver[]
}
export interface AddBudgetDriverAction {
  type: typeof ADD_BUDGET_DRIVER
  payload: BudgetDriver
}
export interface UpdateBudgetDriverAction {
  type: typeof UPDATE_BUDGET_DRIVER
  payload: BudgetDriver
}
export interface DeleteBudgetDriverAction {
  type: typeof DELETE_BUDGET_DRIVER
  payload: BudgetDriver
}

export type BudgetDriversActionTypes =
  | SetBudgetDriversAction
  | AddBudgetDriverAction
  | UpdateBudgetDriverAction
  | DeleteBudgetDriverAction
