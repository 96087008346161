import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { SET_SUBSCRIBED_KEY_FIGURES, SubscribedKeyFiguresActionTypes } from './types'

const initialState: KeyFigure[] = []

const subscribedKeyFiguresReducer = (state = initialState, action: SubscribedKeyFiguresActionTypes) => {
  switch (action.type) {
    case SET_SUBSCRIBED_KEY_FIGURES:
      return action.payload
    default:
      return state
  }
}

export default subscribedKeyFiguresReducer
