import { GetRequestAction, GET, INTEGRATION_REQUEST } from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import { SetProductGroupsAction, SET_PRODUCTS_GROUPS } from './types'
import { ProductGroup } from '../../../types/product/product'

export const setProductGroups = (productGroups: ProductGroup[]): SetProductGroupsAction => ({
  type: SET_PRODUCTS_GROUPS,
  payload: productGroups
})

export const getProductGroupsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: INTEGRATION_REQUEST,
  payload: {
    method: GET,
    params: { companyId },
    url: `/reporting/products/groups/accounting`,
    success: setProductGroups
  },
  meta: {
    type: 'PRODUCT_GROUPS'
  }
})
