import { Button, ColorPicker, Flex, Form, Select, Table } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useWatch } from 'antd/es/form/Form'
import { DeleteOutlined, MinusOutlined } from '@ant-design/icons'
import {
  ReferenceValueRule,
  ReferenceValueRuleOperator
} from '../../../../../../reporting/keyFigures/components/referenceValueTable/types'
import DraggableTableRow from '../../../../../../../components/Table/DraggableTableRow'
import { evaluateReferenceValueRule } from '../../../../../../reporting/keyFigures/utils'
import { testBetweenOverlap, testSelfBetweenOverlap, ruleAvailable } from './utils'
import { VariableType } from '../../../../../../../components/Table/types'
import { FormattedInputNumber } from '../../../../../../../components/Misc/FormattedInputNumber'
import { FormItem } from '../../../../../../../components/Form/FormItemContext'

interface RefereceRulesProps {
  variableType: VariableType
  prefixPath?: string[]
}

const ReferenceRules: React.FC<RefereceRulesProps> = ({
  prefixPath = ['style', 'referenceValueRules'],
  variableType = VariableType.absolute
}) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const rules: ReferenceValueRule[] = useWatch(prefixPath, form) || []

  /**
    Testataan kentät joilla kaikki arvot kenttien muuttuessa, 
    jotta saadaan punaiset kentät pois heti kun valitoinnit on tosia
  */
  useEffect(() => {
    const fielsToValidate: (string | number)[][] = []
    rules.forEach((r, index) => {
      if (r && r.value && r.operator && r.color) {
        fielsToValidate.push([...prefixPath, index])
      }
    })
    form.validateFields(fielsToValidate, { recursive: true })
  }, [rules])

  const validateOverlap = (name: number) => ({
    validator() {
      const overlaps = rules.some((r, index) => {
        const self = rules[name]
        // do not test self
        if (name === index) {
          return false
        }
        // test only rules with all values
        if (r && r && r.value && r.operator && r.color) {
          if (r.operator === ReferenceValueRuleOperator.btw) {
            return testBetweenOverlap(self, r.value)
          }
          if (self.operator === ReferenceValueRuleOperator.btw) {
            return testSelfBetweenOverlap(self, r)
          }
          return evaluateReferenceValueRule(self, r.value)
        }
        return false
      })

      if (!overlaps) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(t('global:overlap')))
    }
  })

  const inputNumberProps = {
    percentage: variableType === VariableType.percentage,
    addonAfter: variableType === VariableType.percentage ? '%' : null,
    step: 1
  }

  return (
    <Form.List name={prefixPath}>
      {(fields, { add, remove }) => (
        <>
          <Table
            dataSource={fields}
            pagination={false}
            components={{
              body: {
                row: DraggableTableRow
              }
            }}
          >
            <Table.Column<ReferenceValueRule>
              title={t('global:rule')}
              key="operator"
              dataIndex="name"
              width={300}
              render={name => {
                return (
                  <FormItem
                    name={[name, 'operator']}
                    rules={[
                      {
                        required: true,
                        message: t('global:required-field')
                      }
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      onChange={() => form.setFieldValue([name, 'value'], undefined)} // Tässä laitetaan undefined että btw opetraattori toimii arrayna
                      options={Object.entries(ReferenceValueRuleOperator).map(([label, operator]) => ({
                        label: t(`referenceValueRuleOperator:${label}`),
                        value: operator,
                        disabled: !ruleAvailable(operator, form.getFieldValue([...prefixPath, name, 'operator']), rules)
                      }))}
                    />
                  </FormItem>
                )
              }}
            />
            <Table.Column<ReferenceValueRule>
              title={t('global:value')}
              key="value"
              dataIndex="name"
              width={200}
              render={(name: number) => {
                const ruleOperator = form.getFieldValue([...prefixPath, name, 'operator'])
                return ruleOperator === ReferenceValueRuleOperator.btw ? (
                  <Flex>
                    <FormItem
                      shouldUpdate
                      name={[name, 'value', 0] as string[]}
                      rules={[
                        {
                          required: true,
                          message: t('global:required-field')
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const othetValue = getFieldValue([...prefixPath, name, 'value', 1])
                            if (value < othetValue) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error(t('global:biggerThan')))
                          }
                        }),
                        validateOverlap(name)
                      ]}
                      style={{ margin: 0 }}
                    >
                      <FormattedInputNumber {...inputNumberProps} />
                    </FormItem>
                    <MinusOutlined />
                    <FormItem
                      shouldUpdate
                      name={[name, 'value', 1] as string[]}
                      rules={[
                        {
                          required: true,
                          message: t('global:required-field')
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const othetValue = getFieldValue([...prefixPath, name, 'value', '0'])
                            if (value > othetValue) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error(t('global:smallerThan')))
                          }
                        }),
                        validateOverlap(name)
                      ]}
                      style={{ margin: 0 }}
                    >
                      <FormattedInputNumber {...inputNumberProps} />
                    </FormItem>
                  </Flex>
                ) : (
                  <FormItem
                    shouldUpdate
                    name={[name, 'value'] as any}
                    rules={[
                      {
                        required: true,
                        message: t('global:required-field')
                      },
                      validateOverlap(name)
                    ]}
                    style={{ margin: 0 }}
                  >
                    <FormattedInputNumber {...inputNumberProps} />
                  </FormItem>
                )
              }}
            />
            <Table.Column<ReferenceValueRule>
              title={t('global:color')}
              key="color"
              dataIndex="name"
              width={200}
              render={name => {
                return (
                  <FormItem
                    name={[name, 'color']}
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: t('global:required-field')
                      }
                    ]}
                    initialValue="#00ff1880"
                    getValueFromEvent={color => {
                      return `#${color.toHex()}`
                    }}
                  >
                    <ColorPicker placement="top" trigger="hover" size="small" />
                  </FormItem>
                )
              }}
            />
            <Table.Column<ReferenceValueRule>
              title={t('global:actions')}
              key="actions"
              align="center"
              dataIndex="name"
              width={100}
              render={name => {
                return <Button danger type="text" onClick={() => remove(name)} icon={<DeleteOutlined />} />
              }}
            />
          </Table>
          <Button onClick={() => add()} block type="dashed">
            + {t('global:add')}
          </Button>
        </>
      )}
    </Form.List>
  )
}

export default ReferenceRules
