import React from 'react'
import { Table } from 'antd'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { ColumnsType } from 'antd/lib/table'
import { StatementRow } from '../../../types/statementRow/StatementRow'
import { Company } from '../../../types/company/Company'
import { contextAccountMapRulesGroupedByStatementRowIdSelector } from '../../../redux/context/accountMapRules/selectors'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

interface AccountMapEditorProps {
  statementRow: StatementRow
  companies: Company[]
}

interface DataType {
  [key: string]: AccountMapRule
}

const AccountMapExpandedTable: React.FC<AccountMapEditorProps> = ({ statementRow, companies }) => {
  const rules = useSelector(contextAccountMapRulesGroupedByStatementRowIdSelector)

  const transformData = () => {
    const grouped = _.groupBy(rules[statementRow.id], 'companyId')
    const rowCount = Math.max(...Object.values(grouped).map(val => val?.length ?? 0)) ?? 0
    if (rowCount < 0) return []
    const rows = Array(rowCount)
      .fill(0)
      .map((__, i) => {
        const row = companies.reduce((r, comp) => {
          return { ...r, [comp.id]: grouped?.[comp.id]?.[i] }
        }, {} as DataType)
        return row
      })
    return rows
  }

  const colums: ColumnsType<DataType> = companies.map(({ id, name }) => ({
    dataIndex: id,
    title: name,
    width: 200,
    render: (val: AccountMapRule) => {
      if (!val?.to || !val?.from) return null
      return `${val?.from} - ${val?.to}`
    }
  }))

  return (
    <div style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
      <Table tableLayout="fixed" columns={colums} dataSource={transformData()} pagination={false} />
    </div>
  )
}

export default AccountMapExpandedTable
