import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserPage from '../../../../components/User/UserPage'
import {
  createSessionContractUserRequest,
  deleteSessionContractUserRequest,
  getSessionContractUsersRequest,
  updateSessionContractUserRequest
} from '../../../../redux/session/contract/actions'
import {
  sessionContractIdSelector,
  sessionContractUserSelector,
  sessionContractUsersLoadingSelector,
  currentUserIsAdminSortedCompaniesSelector
} from '../../../../redux/session/contract/selectors'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'

import { accountantRoles } from '../../../../types/user/Role'
import { AppDispatch } from '../../../../redux/store'

const AccountantUsers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const contractId = useSelector(sessionContractIdSelector)
  const users = useSelector(sessionContractUserSelector)
  const loading = useSelector(sessionContractUsersLoadingSelector)
  const selectableCompanies = useSelector(currentUserIsAdminSortedCompaniesSelector)
  const companies = useSelector(currentUserSortedCompaniesSelector)

  useEffect(() => {
    contractId && dispatch(getSessionContractUsersRequest(contractId))
  }, [contractId, dispatch])

  return (
    <UserPage
      contractId={contractId}
      users={users}
      loading={loading}
      selectableCompanies={_.uniqBy([...selectableCompanies, ...companies], 'id').sort((a, b) =>
        a.name > b.name ? 1 : -1
      )}
      selectableRoles={accountantRoles}
      createUserRequest={createSessionContractUserRequest}
      updateUserRequest={updateSessionContractUserRequest}
      deleteUserRequest={deleteSessionContractUserRequest}
    />
  )
}

export default AccountantUsers
