import { GroupSettingData } from '../../../pages/settings/company/group/types'

export const SET_GROUP_SETTINGS = 'SET_GROUP_SETTINGS'
export const UPDATE_GROUP_SETTINGS = 'SET_GROUP_SETTINGS'
export const EMPTY_GROUP_SETTINGS = 'EMPTY_GROUP_SETTINGS'

export interface SetGroupSettingsAction {
  type: typeof SET_GROUP_SETTINGS
  payload: GroupSettingData
}

export interface UpdateGroupSettingsAction {
  type: typeof UPDATE_GROUP_SETTINGS
  payload: GroupSettingData
}

export interface EmptyGroupSettingsAction {
  type: typeof EMPTY_GROUP_SETTINGS
}

export type GroupSettingsActionTypes = SetGroupSettingsAction | UpdateGroupSettingsAction | EmptyGroupSettingsAction
