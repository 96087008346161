import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import {
  ADD_CONTEXT_CONTRACT_USER,
  ContextContractActionTypes,
  DELETE_CONTEXT_CONTRACT_USER,
  SET_CONTEXT_CONTRACT,
  SET_CONTEXT_CONTRACT_USERS,
  UPDATE_CONTEXT_CONTRACT_USER
} from './types'

const initialState: any = {}

const contextContractReducer = (state = initialState, action: ContextContractActionTypes): Contract => {
  switch (action.type) {
    case SET_CONTEXT_CONTRACT:
      return { ...state, ...action.payload }
    case SET_CONTEXT_CONTRACT_USERS:
      return { ...state, users: action.payload }
    case ADD_CONTEXT_CONTRACT_USER:
      return { ...state, users: [...state.users, action.payload] }
    case UPDATE_CONTEXT_CONTRACT_USER:
      return {
        ...state,
        users: state.users.map((user: User) => (user.id !== action.payload.id ? user : { ...user, ...action.payload }))
      }
    case DELETE_CONTEXT_CONTRACT_USER:
      return { ...state, users: state.users.filter((user: User) => user.id !== action.payload.id) }
    default:
      return state
  }
}

export default contextContractReducer
