import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportDataSection } from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { CustomTree } from '../customReportTable/CustomTree'

/**
 * Category
 * - leafs length has changed
 * - company category value has changes
 * - dimension category value has changed
 * - periodgroup category value, startDate, endDate, dataType or offset has changed
 * - function category value has changed
 * - function cagtegory children has changed
 */
const testCategoriesChange = (
  oldCategories: CustomReportCategory[],
  newCategories: CustomReportCategory[]
): boolean => {
  const newCategoriesTree = new CustomTree('0')
  newCategoriesTree.build(newCategories)
  const oldCategoriesTree = new CustomTree('0')
  oldCategoriesTree.build(oldCategories)

  const hasSamePathCount = () => {
    if (newCategoriesTree.paths.length !== oldCategoriesTree.paths.length) return false
    return true
  }

  const compareCategories = (oldCat: CustomReportCategory | null, newCat?: CustomReportCategory | null): boolean => {
    let categoriesAreSame = true

    switch (oldCat?.type) {
      case 'company':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        break
      case 'dimension':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        break
      case 'periodGroup':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        if (oldCat?.startDate !== newCat?.startDate) categoriesAreSame = false
        if (oldCat?.endDate !== newCat?.endDate) categoriesAreSame = false
        if (oldCat?.dataType !== newCat?.dataType) categoriesAreSame = false
        if (oldCat?.offset !== newCat?.offset) categoriesAreSame = false
        break
      case 'function':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        break
      case 'keyFigureId':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        break
      case 'formulaId':
        if (oldCat?.value !== newCat?.value) categoriesAreSame = false
        break

      default:
        break
    }

    return categoriesAreSame
  }

  // test categories
  const categoriesAreSame = [...oldCategoriesTree.preOrderTraversal()].every(oldNode => {
    const newNode = newCategoriesTree.find(oldNode.key)
    // test function category children
    if (oldNode?.value?.type === 'function' || oldNode?.value?.type === 'dimension') {
      const newFunctionChildrenTree = new CustomTree('0')
      newFunctionChildrenTree.build(newNode?.value?.children || [])
      const oldFunctionChildrenTree = new CustomTree('0')
      oldFunctionChildrenTree.build(oldNode.value.children || [])

      return [...oldFunctionChildrenTree.preOrderTraversal()].every(oldF => {
        const newF = newFunctionChildrenTree.find(oldF.key)
        return compareCategories(oldF?.value, newF?.value)
      })
    }

    return compareCategories(oldNode?.value, newNode?.value)
  })

  return categoriesAreSame && hasSamePathCount()
}

/**
 * Variables
 * - formulaId has changed
 * - keyFigureId has changed
 * - Source maybe
 * - Params has changed
 */
const testVariableChange = (oldVariables: CustomReportVariable[], newVariables: CustomReportVariable[]): boolean => {
  const hasSameSource = (newVar: CustomReportVariable, oldVar: CustomReportVariable) => {
    if (oldVar.keyFigureId === newVar.keyFigureId) return true
    if (oldVar.formulaId === newVar.formulaId) return true
    if (oldVar.source === newVar.source) return true
    return false
  }
  const hasSameParams = (newVar: CustomReportVariable, oldVar: CustomReportVariable) => {
    const { params: newParams = {} } = newVar
    const { params: oldParams = {} } = oldVar
    return Object.entries(newParams).every(([key, newValue]) => {
      const oldValue = oldParams?.[key as keyof typeof oldParams]
      if (key === 'dimension') return (oldValue as Dimension)?.id === newValue?.id
      return oldValue === newValue
    })
  }

  const variablesAreSame = oldVariables?.every(oldVariable => {
    const variable = newVariables?.find(v => v.id === oldVariable.id)
    if (!variable) return false
    return [hasSameParams, hasSameSource].every(f => {
      return f(variable, oldVariable)
    })
  })

  const lengthsAreSame = () => {
    if (oldVariables.length !== newVariables.length) return false
    return true
  }

  return variablesAreSame && lengthsAreSame()
}

/**
 * Tests if section has changed and report needs to be reloaded
 * @param newData
 * @param oldData
 * @returns boolean
 */
export const testSectionChange = (newData: Partial<CustomReportDataSection>, oldData: CustomReportDataSection) => {
  const { categories: newCategories, variables: newVariables } = newData
  const { categories: oldCategories, variables: oldVariables } = oldData
  if (!newCategories || !oldCategories) return false
  if (newData.params?.reallocate !== oldData.params?.reallocate) return false

  const variablesAreSame = testVariableChange(oldVariables || [], newVariables || [])
  const categoriesAreSame = testCategoriesChange(oldCategories, newCategories)

  return variablesAreSame && categoriesAreSame
}
