// eslint-disable-next-line max-classes-per-file
import _ from 'lodash'

export class TreeNode<T> {
  key: string

  value: T | null

  parent: TreeNode<T> | null

  children: TreeNode<T>[]

  constructor(key: string, value = null as T | null, parent = null as TreeNode<T>['parent']) {
    this.key = key
    this.value = value
    this.parent = parent
    this.children = []
  }

  get isLeaf() {
    return this.children.length === 0
  }

  get hasChildren() {
    return !this.isLeaf
  }
}

export class Tree<T> {
  root: TreeNode<T>

  constructor(key: string, value = null as T) {
    this.root = new TreeNode(key, value)
  }

  *preOrderTraversal(node = this.root): Generator<TreeNode<T>> {
    yield node
    if (!node.isLeaf) {
      for (const child of node.children) {
        yield* this.preOrderTraversal(child)
      }
    }
  }

  *postOrderTraversal(node = this.root): Generator<TreeNode<T>> {
    if (!node.isLeaf) {
      for (const child of node.children) {
        yield* this.postOrderTraversal(child)
      }
    }
    yield node
  }

  insert(parentNodeKey: string, key: string, value = null as T | null) {
    for (const node of this.preOrderTraversal()) {
      if (node.key === parentNodeKey) {
        node.children.push(new TreeNode(key, value, node))
        return true
      }
    }
    return false
  }

  remove(key: string) {
    for (const node of this.preOrderTraversal()) {
      const filtered = node.children.filter(c => c.key !== key)
      if (filtered.length !== node.children.length) {
        node.children = filtered
        return true
      }
    }
    return false
  }

  find(key: string) {
    for (const node of this.preOrderTraversal()) {
      if (node.key === key) return node
    }
    return undefined
  }

  get leafs() {
    const result = []
    for (const node of this.preOrderTraversal()) {
      if (node.isLeaf) {
        result.push(_.cloneDeep(node))
      }
    }
    return result
  }

  get leafValues() {
    return this.leafs.map(l => l.value)
  }

  get paths() {
    const result: TreeNode<T>[][] = []
    function dfs(node: TreeNode<T>, path: TreeNode<T>[]) {
      if (!node) {
        return
      }
      // Add the current node to the path
      if (node.value !== null) {
        path.push(_.cloneDeep(node))
      }
      // If the current node is a leaf, add the current path to the result
      if (node.isLeaf) {
        result.push([...path])
      }
      // Recursively traverse the  subtrees
      if (!node.isLeaf) {
        node.children.forEach(n => dfs(n, path))
      }
      // Remove the current node from the path to backtrack
      path.pop()
    }

    dfs(this.root, [])

    return result
  }
}
