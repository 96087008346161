import { ProductGroup } from '../../../types/product/product'
import { SetCustomerGroupsAction, SET_CUSTOMER_GROUPS } from './types'

const initialState: ProductGroup[] = []

const customerGroupsReducer = (state = initialState, action: SetCustomerGroupsAction) => {
  switch (action.type) {
    case SET_CUSTOMER_GROUPS:
      return action.payload
    default:
      return state
  }
}

export default customerGroupsReducer
