import { CustomReportData, CustomReportOrderData } from './CustomReportOrder'

export const generateStructureData = (structureData: CustomReportData[], changedRowsData: number[]) => {
  const changed: CustomReportOrderData[] = []
  const loop = (data: CustomReportData[], parentId?: number) => {
    data.forEach((cat, index) => {
      if (data.find(d => changedRowsData.includes(d.id))) {
        changed.push({
          id: cat.id,
          order: index,
          type: cat.group ? 'CustomReportGroup' : 'CustomReport',
          groupId: parentId || null
        })
      }
      if (cat.children) {
        loop(cat.children, cat.id)
      }
    })
  }
  loop(structureData)
  return changed
}

export const generateTreeDataNodes = (customReportsData: CustomReportData[], path?: string) => {
  const treeNodes: CustomReportData[] = []
  customReportsData.forEach((customReportData, index) => {
    const currentPath = `${path || 0}-${index}`
    treeNodes.push({
      key: currentPath,
      title: customReportData.title,
      id: customReportData.id,
      groupId: customReportData.groupId,
      group: customReportData.group,
      isLeaf: !customReportData.group ? true : customReportData.isLeaf || undefined,
      children:
        customReportData.children && customReportData.children.length > 0
          ? generateTreeDataNodes(customReportData.children, currentPath)
          : undefined
    })
  })

  return treeNodes
}
