import { ReallocationSource } from '../../../types/reallocation/Reallocation'
import {
  ADD_REALLOCATION,
  ADD_REALLOCATION_TARGET,
  DELETE_REALLOCATION,
  DELETE_REALLOCATION_TARGET,
  ReallocationActionTypes,
  SET_REALLOCATIONS,
  UPDATE_REALLOCATION,
  UPDATE_REALLOCATION_TARGET
} from './types'

const initialState: ReallocationSource[] = []

const reallocationReducer = (state = initialState, action: ReallocationActionTypes) => {
  switch (action.type) {
    case SET_REALLOCATIONS:
      return action.payload
    case ADD_REALLOCATION:
      return [action.payload, ...state]
    case UPDATE_REALLOCATION:
      return state.map(reallocation => {
        if (reallocation.id === action.payload.id) return { ...reallocation, ...action.payload }
        return reallocation
      })
    case DELETE_REALLOCATION:
      return state.filter(reallocation => reallocation.id !== action.payload.id)
    case ADD_REALLOCATION_TARGET:
      return state.map(reallocation => {
        if (reallocation.id === action.payload.source.id) {
          return { ...reallocation, targets: [...(reallocation?.targets || []), action.payload.target] }
        }
        return reallocation
      })
    case UPDATE_REALLOCATION_TARGET:
      return state.map(reallocation => {
        if (reallocation.id === action.payload.source.id) {
          return {
            ...reallocation,
            targets: reallocation.targets.map(target => {
              if (target.id === action.payload.target.id) {
                return { ...target, ...action.payload.target }
              }
              return target
            })
          }
        }
        return reallocation
      })
    case DELETE_REALLOCATION_TARGET:
      return state.map(reallocation => {
        if (reallocation.id === action.payload.source.id) {
          return {
            ...reallocation,
            targets: reallocation.targets.filter(target => target.id !== action.payload.target.id)
          }
        }
        return reallocation
      })
    default:
      return state
  }
}

export default reallocationReducer
