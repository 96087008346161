import { Modal, Input, Select, Radio, Form, Checkbox } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { industriesSelector } from '../../../redux/entities/industries/selectors'
import { notificationAction } from '../../../redux/middleware/actions'
import { useBackend } from '../../../services/backend'
import { Company, CompanyForm, Country } from '../../../types/company/Company'
import { Industry } from '../../../types/industry/Industry'
import { CompanyType } from './ContractFormContext'
import { AppDispatch } from '../../../redux/store'

interface CompanyFormProps {
  visible: boolean
  vatId?: string
  companyType?: CompanyType
  handleCompanyChange: (company: Company | null) => void
  toggleCompanyModalVisible: (vatId?: string) => void
}

const CompanyAddForm = ({
  companyType = CompanyType.normal,
  visible,
  handleCompanyChange,
  toggleCompanyModalVisible,
  vatId
}: CompanyFormProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<Company>()
  const industries = useSelector(industriesSelector)
  const companyRequest = useBackend('/api/companies')
  const imaginaryCompanyRequest = useBackend('/api/companies/imaginary')
  const dispatch: AppDispatch = useDispatch()

  const isValidVatId: any['validator'] = (rule: any, id: any) => {
    const trimmedVatId = id.trim()

    const vatIdTests = {
      [Country.fi]: /([0-9]{7}-[0-9]{1})|([0-9]{8})|(FI[0-9]{8})/g,
      [Country.se]: /([0-9]{6}-[0-9]{4})|([0-9]{10})|(SE[0-9]{10})/g
    }

    const matches = Object.entries(vatIdTests).reduce((acc: { [key: string]: any[] | null }, [country, regExp]) => {
      const test = new RegExp(regExp)
      acc[country] = test.exec(trimmedVatId)
      return acc
    }, {})

    const valid = Object.values(matches).some(val => !!val)
    if (valid) {
      return Promise.resolve()
    }
    return Promise.reject(t('contractsPage:business-id-not-acceptable'))
  }

  const industriesSelectOptions = () => {
    return Object.entries(_.groupBy(industries, 'indystryRootId')).map(([rootCode, groupedIndustries]) => (
      <Select.OptGroup key={rootCode} label={`${rootCode} - ${t(`tol2008:${rootCode}`)}`}>
        {groupedIndustries.map((industry: Industry) => (
          <Select.Option key={industry.id} value={industry.id}>
            {`${t(`tol2008:${industry.id}`)} ${industry.id}`}
          </Select.Option>
        ))}
      </Select.OptGroup>
    ))
  }

  const formOptions: { [key in Country]: CompanyForm[] } = {
    fi: [CompanyForm.limited, CompanyForm.association, CompanyForm.realEstate],
    se: [CompanyForm.limited]
  }

  const onSubmit = async () => {
    try {
      const values = await form.validateFields()
      const requestData = {
        body: {
          data: values
        }
      }
      const request = companyType === CompanyType.normal ? companyRequest : imaginaryCompanyRequest
      const response = await request.post(requestData)
      handleCompanyChange(response)
      toggleCompanyModalVisible(response.id)
    } catch (error) {
      dispatch(
        notificationAction({
          type: 'error',
          message: 'CREATE_COMPANT_ERROR',
          description: 'TRY_LATER_OR_CONTACT'
        })
      )
    }
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }

  return (
    <Modal
      width={700}
      title={t('contractsPage:add-company')}
      open={visible}
      onCancel={() => toggleCompanyModalVisible()}
      onOk={onSubmit}
      centered
      maskClosable={false}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      destroyOnClose
    >
      <Form {...formItemLayout} form={form} layout="horizontal" onFinish={onSubmit}>
        {companyType === CompanyType.normal && (
          <Form.Item
            name="id"
            initialValue={vatId}
            rules={[
              {
                validator: isValidVatId
              },
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            hasFeedback
            label={t('contractsPage:vatId')}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('global:name')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="industryId"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('comparisonPage:industry')}
        >
          <Select
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const localeString = option && `${t(`tol2008:${option.key}`)} ${option.key}`
              return localeString ? localeString.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
            }}
          >
            {industriesSelectOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          name="country"
          initialValue={Country.fi}
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('global:country')}
        >
          <Radio.Group
            onChange={() =>
              form.setFieldsValue({
                form: formOptions[form.getFieldValue('country') as Country]?.[0]
              })
            }
          >
            {Object.values(Country).map(key => (
              <Radio key={key} value={key}>
                {t(`country:${key}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country} noStyle>
          {() => (
            <Form.Item
              name="form"
              initialValue={CompanyForm.limited}
              rules={[
                {
                  required: true,
                  message: t('global:required-field')
                }
              ]}
              label={t('global:company-form')}
            >
              <Radio.Group>
                {Object.values(CompanyForm).map(key => {
                  return (
                    <Radio
                      key={key}
                      disabled={!formOptions[form.getFieldValue('country') as Country]?.includes(key)}
                      value={key}
                    >
                      {t(`accountMapPage:${key}`)}
                    </Radio>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          )}
        </Form.Item>

        {companyType === CompanyType.elimination && (
          <Form.Item
            valuePropName="checked"
            hidden
            // eslint-disable-next-line react/jsx-boolean-value
            initialValue={true}
            name="elimination"
            label={t('accountMapPage:elimination')}
          >
            <Checkbox />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default CompanyAddForm
