import React from 'react'
import { Select } from 'antd'
import { Currency } from '../../../../types/currency/Currency'

interface CurrencyInputProps {
  value?: Currency
  onChange?: (value: Currency) => void
}
const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange }) => {
  const triggerChange = (changedValue: Currency) => {
    onChange?.(changedValue)
  }
  return (
    <Select showSearch optionFilterProp="children" value={value} onChange={triggerChange}>
      {Object.keys(Currency).map(c => (
        <Select.Option key={c} value={c}>
          {c}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CurrencyInput
