import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Card, Col, Form } from 'antd'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { fiscalYearsSelector } from '../../redux/context/fiscalYears/selectors'
import Chart from './components/Chart'
import { valuationEBITDAFSelector } from '../../redux/context/reports/selectors'
import { generateValuationChartCategories, generateValuationChartSeries, tranformInitial } from './utils'
import { updateValuationAdjustmentRequest } from '../../redux/context/valuation/actions'
import { ValuationForm } from './components/ValuationForm'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { FormattedInputNumber } from '../../components/Misc/FormattedInputNumber'
import { ReportTableRow } from '../../components/Table/types'
import { AppDispatch } from '../../redux/store'

interface EBITDAFProps {
  valuationAdjustments?: any
}

const EBITDAF = ({ valuationAdjustments }: EBITDAFProps) => {
  const { t } = useTranslation()
  const valuation = useSelector(valuationEBITDAFSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const fiscalYears = useSelector(fiscalYearsSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dispatch: AppDispatch = useDispatch()

  const onSubmit = (values: any) => {
    const wages: any[] = []
    Object.entries(values.wages).map(([key, value]) => {
      return value && wages.push({ fiscalYearId: Number(key), value: String(value) })
    })
    const other: any[] = []
    Object.entries(values.other).map(([key, value]) => {
      return value && other.push({ fiscalYearId: Number(key), value: String(value) })
    })
    const { balanceSheet } = valuationAdjustments
    console.log({
      companyId,
      wages,
      other,
      balanceSheet,
      EBITDAFactor: values.EBITDAFactor,
      EBITDAHistorySampleSize: values.EBITDAHistorySampleSize,
      capitalizationInterestRate: valuationAdjustments.capitalizationInterestRate,
      budgetingScenario
    })
    companyId &&
      dispatch(
        updateValuationAdjustmentRequest(
          companyId,
          {
            wages,
            other,
            balanceSheet,
            EBITDAFactor: values.EBITDAFactor,
            EBITDAHistorySampleSize: values.EBITDAHistorySampleSize,
            capitalizationInterestRate: valuationAdjustments.capitalizationInterestRate
          },
          budgetingScenario && budgetingScenario.id
        )
      )
  }

  return (
    <Card>
      <Form initialValues={tranformInitial(valuationAdjustments)} layout="horizontal" onFinish={onSubmit}>
        <Row gutter={24}>
          <ValuationForm />
          <Col md={12} xs={24}>
            <Card>
              <Chart
                categories={generateValuationChartCategories(valuation as ReportTableRow, fiscalYears)}
                series={generateValuationChartSeries(valuation as ReportTableRow)}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={24} style={{ width: '100%' }}>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <h4>{t('valuationPage:ebitda-history-sample-size')}</h4>
            </Col>
          </Row>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <Form.Item name="EBITDAHistorySampleSize">
                <FormattedInputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <h4>{t('valuationPage:EBITDAF')}</h4>
            </Col>
          </Row>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <Form.Item name="EBITDAFactor">
                <FormattedInputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button style={{ marginTop: '20px' }} type="primary" htmlType="submit">
              {t('global:save')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  )
}

export default EBITDAF
