import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'

export const SET_SESSION_CONTRACT = 'SET_SESSION_CONTRACT'
export const SET_SESSION_CONTRACT_USERS = 'SET_SESSION_CONTRACT_USERS'
export const ADD_SESSION_CONTRACT_USER = 'ADD_SESSION_CONTRACT_USER'
export const ADD_SESSION_CONTRACT_CHILDREN = 'ADD_SESSION_CONTRACT_CHILDREN'
export const UPDATE_SESSION_CONTRACT_CHILDREN = 'UPDATE_SESSION_CONTRACT_CHILDREN'
export const UPDATE_SESSION_CONTRACT_USER = 'UPDATE_SESSION_CONTRACT_USER'
export const DELETE_SESSION_CONTRACT_USER = 'DELETE_SESSION_CONTRACT_USER'

export interface SetSessionContractAction {
  type: typeof SET_SESSION_CONTRACT
  payload: Contract
}
export interface SetSessionContractUsersAction {
  type: typeof SET_SESSION_CONTRACT_USERS
  payload: User[]
}

export interface AddSessionContractUsersAction {
  type: typeof ADD_SESSION_CONTRACT_USER
  payload: User
}
export interface AddSessionContractChildrenAction {
  type: typeof ADD_SESSION_CONTRACT_CHILDREN
  payload: Contract
}

export interface UpdateSessionContractChildrenAction {
  type: typeof UPDATE_SESSION_CONTRACT_CHILDREN
  payload: Contract
}

export interface UpdateSessionContractUsersAction {
  type: typeof UPDATE_SESSION_CONTRACT_USER
  payload: User
}

export interface DeleteSessionContractUsersAction {
  type: typeof DELETE_SESSION_CONTRACT_USER
  payload: User
}

export type SessionContractActionTypes =
  | SetSessionContractAction
  | SetSessionContractUsersAction
  | AddSessionContractUsersAction
  | UpdateSessionContractUsersAction
  | DeleteSessionContractUsersAction
  | AddSessionContractChildrenAction
  | UpdateSessionContractChildrenAction
