import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { dimensionTreeSelector } from '../../../../../redux/context/dimensions/selectors'
import { selectedDimensionFilterSelector } from '../../../../../redux/context/filters/selectors'
import { setSelectedRowDimensionKey } from '../../../../../redux/context/incomeStatenentDimensions/actions'
import { getIncomeStatementDimensionsDataSelectedKeys } from '../../../../../redux/context/incomeStatenentDimensions/selectors'
import { AppDispatch } from '../../../../../redux/store'

interface DimensionsMenuProps {
  reportTableRow: ReportTableRow
  getDimensionData: (dimensionsArray: string[], row: ReportTableRow) => void
}

const DimensionsMenu: React.FC<DimensionsMenuProps> = ({ reportTableRow, getDimensionData }: DimensionsMenuProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const dimensionsTree = useSelector(dimensionTreeSelector)
  const selectedDimension = useSelector(selectedDimensionFilterSelector)
  const selectedKeysForDimensionsData = useSelector(getIncomeStatementDimensionsDataSelectedKeys)

  const getDimensions = () => {
    dispatch(setSelectedRowDimensionKey(reportTableRow))
    if (
      dimensionsTree &&
      !selectedKeysForDimensionsData.find(
        (k: any) => k === reportTableRow.accountCode || k === reportTableRow.statementRowId
      )
    ) {
      if (selectedDimension && selectedDimension.children && selectedDimension.children.length > 0) {
        getDimensionData(
          selectedDimension.children.map(d => d.dimensionId),
          reportTableRow
        )
      } else {
        getDimensionData(
          dimensionsTree.map(d => d.dimensionId),
          reportTableRow
        )
      }
    }
  }

  return selectedKeysForDimensionsData.find(
    (k: any) => k === reportTableRow.accountCode || k === reportTableRow.statementRowId
  ) ? (
    <Button size="small" onClick={() => getDimensions()}>
      {t('financialStatementsPage:hide-dimensions')}
    </Button>
  ) : (
    <Button size="small" onClick={() => getDimensions()}>
      {t('financialStatementsPage:show-dimensions')}
    </Button>
  )
}

export default DimensionsMenu
