import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import {
  createFinancialStatementInvestmentsRequest,
  deleteFinancialStatementInvestmentsRequest,
  updateFinancialStatementInvestmentsRequest
} from '../../../../../redux/context/investments/actions'
import { useBackend } from '../../../../../services/backend'
import { Investment, Investment as InvestmentType } from '../../../../../types/investment/Investment'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import InvestmentModal from '../../../investments/components/InvestmentModal'
import InvestmentFormContextProvider from '../../../investments/InvestmentFormContextProvider'
import { AppDispatch } from '../../../../../redux/store'

interface InvestmentProps {
  reportTableRow: ReportTableRow
}

const InvestmentNameCellRender: React.FC<InvestmentProps> = (props: InvestmentProps) => {
  const companyId = useSelector(contextCompanyIdSelector)
  const [investment, setInvestment] = useState<InvestmentType>()
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const investmentRequest = useBackend('/api/companies/{companyId}/budgeting/investments/{investmentId}')
  const dispatch: AppDispatch = useDispatch()

  const { reportTableRow } = props
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const handleInvestmentReq = (investmentId: string) => {
    investmentRequest
      .get({
        urlParams: { companyId, investmentId }
      })
      .then((investmentResponse: InvestmentType) => {
        setInvestment(investmentResponse)
        setModalVisible(true)
      })
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleDelete = () => {
    if (reportTableRow.id) {
      companyId &&
        dispatch(deleteFinancialStatementInvestmentsRequest(companyId, { id: reportTableRow.id } as Investment))
    }
  }

  return (
    <>
      {modalVisible && (
        <InvestmentFormContextProvider
          reportType={investment && investment.value < 0 ? 'divestment' : 'investment'}
          reportTableRow={reportTableRow}
          isExisting={false}
        >
          <InvestmentModal
            updateInvestmentsRequest={updateFinancialStatementInvestmentsRequest}
            createInvestmentsRequest={createFinancialStatementInvestmentsRequest}
            deleteInvestmentsRequest={deleteFinancialStatementInvestmentsRequest}
            visible={modalVisible}
            onFinish={toggleModal}
            investment={investment}
            page="financialStatement"
          />
        </InvestmentFormContextProvider>
      )}
      <span>{reportTableRow.name}</span>
      <span style={{ float: 'right' }}>
        <Button
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          icon={<EditOutlined />}
          type="link"
          size="small"
          onClick={() => handleInvestmentReq(reportTableRow.id)}
        />
        <Divider type="vertical" />
        <Popconfirm
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          title={t('global:delete-confirm')}
          onConfirm={() => handleDelete()}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button
            disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
            icon={<DeleteOutlined />}
            type="link"
            size="small"
          />
        </Popconfirm>
      </span>
    </>
  )
}

export default React.memo(InvestmentNameCellRender)
