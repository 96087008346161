import ReactGA from 'react-ga'

export default (history: any) => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

    history.listen((location: any) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  }
}
