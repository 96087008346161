import { DeleteOutlined } from '@ant-design/icons'
import { Button, Cascader, Divider, Popconfirm } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { formulasSelector } from '../../../../../redux/context/formulas/selectors'
import { companyFormBasedKeyFigureSelector } from '../../../../../redux/entities/keyFigures/selectors'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import RowSettingsModal from '../RowSettingsModal'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface KeyfigureCategoryProps {
  path: string
  category: CustomReportCategory
  hideActions?: boolean
  multiple?: boolean
}

export const KeyfigureCategory: React.FC<KeyfigureCategoryProps> = ({
  path,
  category,
  hideActions,
  multiple
}: KeyfigureCategoryProps) => {
  const keyFigures = useSelector(companyFormBasedKeyFigureSelector)
  const formulas = useSelector(formulasSelector)
  const { t } = useTranslation()
  const { editObject, deleteObject } = useContext(CategoryContext) as CategoryContextType
  const onChange = (c: any) => {
    if (multiple) {
      if (c.length > 0) {
        editObject(path, { ...category, type: c[0][0], value: c.map((v: any) => v[1]) })
      } else {
        editObject(path, { ...category, type: 'keyFigureId', value: undefined })
      }
    } else {
      editObject(path, { ...category, type: c[0], value: [c[1]] })
    }
  }

  const onChangeTitle = (c: Partial<CustomReportCategory>) => {
    if (c) {
      editObject(path, { ...category, ...c })
    }
  }

  const getval = () => {
    const catValues: any = Array.isArray(category.value) ? category.value : [category.value]
    return catValues?.length > 0 && catValues[0] !== undefined && catValues[0] !== ''
      ? catValues.map((val: any) => [category.type as CustomReportCategory['type'], val as number])
      : null
  }

  return (
    <>
      <Cascader
        multiple={multiple}
        options={[
          {
            disabled: !!(category.type === 'formulaId' && category.value && getval()),
            value: 'keyFigureId',
            label: t(`customReportPage:keyFigureId`),
            children: keyFigures.map(keyFigure => ({
              value: keyFigure.id,
              label: t(`keyFigureStatement:${keyFigure.id}`)
            }))
          },
          {
            disabled: !!(category.type === 'keyFigureId' && category.value && getval()),
            value: 'formulaId',
            label: t(`customReportPage:formulaId`),
            children: formulas.map(formula => ({ value: formula.id, label: formula.name }))
          }
        ]}
        maxTagCount="responsive"
        onChange={onChange}
        value={getval()}
        allowClear={false}
        style={{ width: 420 }}
      />
      {!hideActions && (
        <>
          <Divider type="vertical" />
          <Popconfirm
            title={t('global:delete-confirm')}
            onConfirm={() => deleteObject(path)}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      )}
      <Divider type="vertical" />
      <RowSettingsModal onChange={onChangeTitle} path={path} category={category} />
    </>
  )
}
