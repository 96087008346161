import React, { ReactNode, useState } from 'react'
import { Modal, ModalProps } from 'antd'

export const useModal = <T,>(
  Content: React.FC<T>,
  modalProps: { customTitle?: (params: T) => string } & ModalProps
): [ReactNode, (visible: boolean, newData?: T) => void] => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [contentProps, setContentProps] = useState<T>()

  const setVisible = (visible: boolean, newData?: T) => {
    setIsVisible(visible)
    setContentProps(newData)
  }

  if (!isVisible) return [null, setVisible]

  const modal = (
    <Modal
      title={modalProps?.title || (contentProps && modalProps?.customTitle && modalProps?.customTitle(contentProps))}
      open={isVisible}
      maskClosable={false}
      onOk={() => {
        setVisible(false)
      }}
      onCancel={() => setVisible(false)}
      {...modalProps}
    >
      {contentProps && <Content {...contentProps} />}
    </Modal>
  )

  return [modal, setVisible]
}
