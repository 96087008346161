import React, { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsDrilldown from 'highcharts/modules/drilldown'
import { useSelector } from 'react-redux'
import { SizeMeProps, withSize } from 'react-sizeme'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { customReportDateSelector } from '../../../../../redux/context/customReports/selectors'
import { useBackend } from '../../../../../services/backend'
import { formatter } from '../utils'
import { DrillData, getDrillData, getLeafCategories, getPieSeries } from './chartUtils'
import { CustomReportPieSection } from '../../../../../redux/context/customReports/typesSection'

interface NewPieChartProps {
  section: CustomReportPieSection
  size?: SizeMeProps['size']
}

HighchartsDrilldown(Highcharts)

/**
 * Piirakka graafi
 *
 * Yksi kategoria
 * Yritys -> Dimensio? -> Funktio(% tunnusluvusta)
 */
const NewPieChart: React.FC<NewPieChartProps> = ({ section, size }) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const companyId = useSelector(contextCompanyIdSelector)
  const date = useSelector(customReportDateSelector)
  const [explainerData, setExplainerData] = useState<DrillData>({})
  const explainRequest = useBackend(
    `/api/companies/${companyId}/reporting/custom/reports/${section.reportId}/sections/${section.id}/explain`
  )

  const leafs = getLeafCategories(section.categories)
  const category = leafs[0]
  const isPercentage = useMemo(() => category.type === 'function' && category.value === 'commonSize', [category])

  const handleExplainRequest = async (variableId: number | string) => {
    return explainRequest.get({
      body: {
        params: {
          variableId,
          date: dayjs(date).endOf('month').format('YYYY-MM-DD')
        }
      }
    })
  }

  const drilldown: Highcharts.DrilldownCallbackFunction = async e => {
    const chart = chartRef.current?.chart
    if (!e.seriesOptions && chart) {
      const variableId: string = (e.point as any).drilldown.toString()
      let data: Highcharts.PointOptionsObject[]

      // Jos on eka id, ja explainia ei ole vielä haetaan data asynccinä,
      // muuten otetaan explain statesta
      if (variableId.split('-').length === 1 && !(variableId in explainerData)) {
        chart.showLoading('Loading ...')
        const explain = await handleExplainRequest(variableId)
        const drillData = getDrillData(variableId, explain, section.categories)
        setExplainerData({ ...explainerData, ...drillData })
        data = drillData[variableId]

        chart.hideLoading()
      } else {
        data = explainerData[variableId]
      }

      const series = {
        name: e.point.name,
        id: variableId,
        type: 'pie',
        data: data?.filter(d => d?.y !== null)
      } satisfies Highcharts.SeriesOptionsType

      chart.addSeriesAsDrilldown(e.point, series)
    }
  }

  const options = {
    chart: {
      type: 'pie',
      width: size?.width,
      height: size?.height,
      events: {
        drilldown
      }
    },
    title: {
      text: ''
    },
    series: [getPieSeries(section)],
    drilldown: {
      series: []
    },
    plotOptions: {
      series: {
        animation: false
      },
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: section.style?.showDataLabels === 'true',
          format: isPercentage
            ? '<b>{point.name}</b>: {point.percentage:.1f}%' // Percentage formatting
            : '<b>{point.name}</b>: {point.y:.1f}' // Absolute formatting
        },
        showInLegend: true
      }
    },
    tooltip: {
      formatter
    }
  } as Highcharts.Options

  useEffect(() => {
    chartRef.current?.chart?.update(options)
  }, [section])

  return (
    <div>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  )
}

export default withSize({ monitorHeight: true })(NewPieChart)
