import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

export const SET_KEY_FIGURES = 'SET_KEY_FIGURES'

export interface SetKeyFiguresAction {
  type: typeof SET_KEY_FIGURES
  payload: KeyFigure[]
}

export type KeyFiguresActionTypes = SetKeyFiguresAction
