import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/finadeck-logo-web.svg'
import './_Footer.sass'

const { Text } = Typography

const AppFooter = () => {
  return (
    <div className="global-footer">
      <Link to="https://finadeck.fi" target="_blank" rel="noopener noreferrer">
        <Logo style={{ height: '64px' }} />
      </Link>
      <Text style={{ display: 'block' }} type="secondary">
        Created by Finadeck
      </Text>
    </div>
  )
}

export default AppFooter
