import { useForm } from 'antd/lib/form/Form'
import { useDispatch } from 'react-redux'
import { login } from '../../../redux/session/authentication/actions'
import { Credentials } from '../../../redux/session/authentication/types'
import { AppDispatch } from '../../../redux/store'

const useLogin = () => {
  const dispatch: AppDispatch = useDispatch()

  const [form] = useForm<Credentials>()

  const handleSubmit = async () => {
    const credentials = await form.validateFields()
    dispatch(login(credentials))
  }

  return { handleSubmit, form }
}

export default useLogin
