import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import FormulaError from './FormulaError'
import FormulaSuggestion from './FormulaSuggestion'

interface FormulaAddFunctionProps {
  form: FormInstance
  requestError?: any
  translation?: { formItem: string; select: string }
  functions?: FormulaAddFunctionFunction[]
  cursor?: any
}

export interface FormulaAddFunctionFunction {
  name: string
  source: string
}

const FormulaAddFunction = ({ form, requestError, cursor, functions, translation }: FormulaAddFunctionProps) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(null)

  const addFunction = (key: string) => {
    if (key) {
      setValue(null)
      const source = form.getFieldValue('source') || ''
      form.setFieldsValue({
        source: `${source.slice(0, cursor.selectionStart)}${key}${source.slice(cursor.selectionEnd)}`
      })
    }
  }

  return (
    <>
      <Form.Item label={t(`${translation?.formItem ?? 'global:formula'}`)}>
        <Select
          optionFilterProp="children"
          allowClear
          showSearch
          value={value}
          placeholder={t(`${translation?.select ?? 'formulaPage:addFunction'}`)}
          onChange={addFunction}
          maxTagCount="responsive"
          fieldNames={{ label: 'name', value: 'source' }}
          filterOption={(input, option) => (option?.name ?? '').includes(input)}
          options={functions}
        />
      </Form.Item>
      {requestError?.error && <FormulaError error={requestError.error} str={requestError.source} />}
      {requestError?.error && <FormulaSuggestion error={requestError.error} />}
    </>
  )
}

export default FormulaAddFunction
