import { createSelector } from 'reselect'
import { groupCompanySelector } from '../groupCompany/selectors'
import { contextSelector } from '../selectors'

export const subsidiariesSelector = createSelector(contextSelector, context => context?.subsidiaries)

export const subsidiariesArgumentOptionsSelector = createSelector(
  groupCompanySelector,
  subsidiariesSelector,
  (group, subsidiaries) => {
    return [group!, ...subsidiaries].filter(Boolean).map(subsidiary => ({
      name: `${subsidiary.id} - ${subsidiary.name}`,
      value: `'${subsidiary.id}'`
    }))
  }
)
