import { CustomExchangeRateSeries } from '../../../pages/settings/company/group/types'
import { ExchangeRatesActionTypes, SET_EXCHANGE_RATES, UPDATE_EXCHANGE_RATES } from './types'

const initialState: CustomExchangeRateSeries[] = []

const exchangeRatesReducer = (state = initialState, action: ExchangeRatesActionTypes): any => {
  switch (action.type) {
    case SET_EXCHANGE_RATES:
      return action.payload
    case UPDATE_EXCHANGE_RATES:
      return state.map(value => {
        if (value.companyId === action.payload.companyId) {
          return {
            ...value,
            ...action.payload
          }
        }
        return value
      })

    default:
      return state
  }
}

export default exchangeRatesReducer
