import { Contract } from '../../../types/contract/Contract'
import { DeliverySubscription } from '../../../types/deliverySubscription/DeliverySubscription'
import {
  DELETE,
  DeleteRequestAction,
  DELIVERY_REQUEST,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction
} from '../../middleware/types'
import {
  AddDeliverySubscriptionAction,
  ADD_DELIVERY_SUBSCRIPTION,
  DeleteDeliverySubscriptionAction,
  DELETE_DELIVERY_SUBSCRIPTION,
  SetDeliverySubscriptionsAction,
  SET_DELIVERY_SUBSCRIPTIONS,
  UpdateDeliverySubscriptionAction,
  UPDATE_DELIVERY_SUBSCRIPTION
} from './types'

export const setDeliverySubscriptions = (
  deliverySubscriptions: DeliverySubscription[]
): SetDeliverySubscriptionsAction => ({
  type: SET_DELIVERY_SUBSCRIPTIONS,
  payload: deliverySubscriptions
})

export const addDeliverySubscription = (deliverySubscription: DeliverySubscription): AddDeliverySubscriptionAction => ({
  type: ADD_DELIVERY_SUBSCRIPTION,
  payload: deliverySubscription
})

export const updateDeliverySubscription = (
  deliverySubscription: DeliverySubscription
): UpdateDeliverySubscriptionAction => ({
  type: UPDATE_DELIVERY_SUBSCRIPTION,
  payload: deliverySubscription
})

export const deleteDeliverySubscription = (
  deliverySubscription: DeliverySubscription
): DeleteDeliverySubscriptionAction => ({
  type: DELETE_DELIVERY_SUBSCRIPTION,
  payload: deliverySubscription
})

export const getDeliverySubscriptionsRequest = (contractId: Contract['id']): GetRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: GET,
    url: `/contracts/${contractId}/delivery-subscriptions`,
    success: (payload: DeliverySubscription[]) => setDeliverySubscriptions(payload)
  },
  meta: {
    type: 'DELIVERY_SUBSCRIPTION'
  }
})

export const sendDeliverySubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscriptionId: DeliverySubscription['id']
): PostRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: POST,
    url: `/contracts/${contractId}/delivery-subscriptions/${deliverySubscriptionId}/deliver`,
    success: updateDeliverySubscription
  },
  meta: {
    type: `SEND_DELIVERY_SUBSCRIPTION_${deliverySubscriptionId}`,
    notification: true
  }
})

export const createAndSendManualDeliverySubscriptionSuccess = (
  contractId: Contract['id'],
  deliverySubscription: DeliverySubscription
) => {
  return (dispatch: any) => {
    dispatch(addDeliverySubscription(deliverySubscription))
    return dispatch(sendDeliverySubscriptionRequest(contractId, deliverySubscription.id))
  }
}

export const createAndSendManualDeliverySubscription = (
  contractId: Contract['id'],
  deliverySubscription: DeliverySubscription
): PostRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: POST,
    data: deliverySubscription,
    url: `/contracts/${contractId}/delivery-subscriptions/`,
    success: (payload: DeliverySubscription) => {
      return createAndSendManualDeliverySubscriptionSuccess(contractId, payload)
    }
  },
  meta: {
    type: 'CREATE_DELIVERY_SUBSCRIPTION',
    notification: true
  }
})

export const createDeliverySubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscription: DeliverySubscription
): PostRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: POST,
    data: deliverySubscription,
    url: `/contracts/${contractId}/delivery-subscriptions/`,
    success: addDeliverySubscription
  },
  meta: {
    type: 'CREATE_DELIVERY_SUBSCRIPTION',
    notification: true
  }
})

export const createManualSubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscription: any
): PostRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: POST,
    data: deliverySubscription,
    url: `/contracts/${contractId}/delivery-subscriptions/deliver`
  },
  meta: {
    type: 'CREATE_MANUAL_SUBSCRIPTION',
    notification: true
  }
})

export const updateDeliverySubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscriptionId: DeliverySubscription['id'],
  deliverySubscription: DeliverySubscription
): PutRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: PUT,
    data: deliverySubscription,
    url: `/contracts/${contractId}/delivery-subscriptions/${deliverySubscriptionId}`,
    success: (payload: DeliverySubscription) => updateDeliverySubscription(payload)
  },
  meta: {
    type: 'UPDATE_DELIVERY_SUBSCRIPTION',
    notification: true
  }
})

export const updateAndSendManualDeliverySubscriptionSuccess = (
  contractId: Contract['id'],
  deliverySubscription: DeliverySubscription
) => {
  return (dispatch: any) => {
    dispatch(updateDeliverySubscription(deliverySubscription))
    return dispatch(sendDeliverySubscriptionRequest(contractId, deliverySubscription.id))
  }
}

export const updateAndSendDeliverySubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscriptionId: DeliverySubscription['id'],
  deliverySubscription: DeliverySubscription
): PutRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: PUT,
    data: deliverySubscription,
    url: `/contracts/${contractId}/delivery-subscriptions/${deliverySubscriptionId}`,
    success: (payload: DeliverySubscription) => {
      return updateAndSendManualDeliverySubscriptionSuccess(contractId, payload)
    }
  },
  meta: {
    type: 'UPDATE_DELIVERY_SUBSCRIPTION',
    notification: true
  }
})

export const deleteDeliverySubscriptionRequest = (
  contractId: Contract['id'],
  deliverySubscriptionId: DeliverySubscription['id']
): DeleteRequestAction => ({
  type: DELIVERY_REQUEST,
  payload: {
    method: DELETE,
    url: `/contracts/${contractId}/delivery-subscriptions/${deliverySubscriptionId}`,
    success: (response: DeliverySubscription) => deleteDeliverySubscription(response)
  },
  meta: {
    type: 'DELETE_DELIVERY_SUBSCRIPTION',
    notification: true
  }
})
