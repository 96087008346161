/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { FormInstance } from 'antd'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { useTranslation } from 'react-i18next'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import EditorTheme from './EditorTheme'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import { TableContext } from './plugins/TablePlugin'
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin'
import { CustomReportTextSection } from '../../../../../redux/context/customReports/typesSection'
import './styles.css'

interface EditorProps {
  section?: CustomReportTextSection
  editable?: boolean
  form?: FormInstance
}

const emptyInitialState = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1
      }
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1
  }
}

function InitialStatePlugin({ value }: any): React.ReactElement {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (editor) {
      const initialEditorState = editor.parseEditorState(value || emptyInitialState)
      editor.setEditorState(initialEditorState)
    }
  }, [value, editor])

  return <></>
}

const Editor = ({ editable = true, section, form }: EditorProps) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()

  const editorConfig = {
    namespace: 'MyEditor',
    editorState: section?.text && JSON.stringify(section.text),
    editable,
    theme: EditorTheme,
    onError(error: any) {
      throw error
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableCellNode,
      AutoLinkNode,
      LinkNode,
      TableNode,
      TableRowNode
    ]
  }

  function Placeholder() {
    return <div className="editor-placeholder">{t('customReportPage:editor-placeholder')}</div>
  }

  const onRef = (_floatingAnchorElem: HTMLElement | null) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <TableContext>
        <div className="editor-container">
          {editable && <ToolbarPlugin />}
          <TablePlugin />
          <OnChangePlugin
            onChange={editorState => {
              form?.setFieldValue('text', JSON.stringify(editorState))
            }}
          />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <div className="editor" ref={onRef}>
                  <ContentEditable className="editor-input" />
                </div>
              }
              placeholder={editable ? <Placeholder /> : <></>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <InitialStatePlugin value={section?.text && JSON.stringify(section.text)} />
            <LinkPlugin />
            <TabIndentationPlugin />
          </div>
        </div>
        {floatingAnchorElem && editable && (
          <>
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
          </>
        )}
      </TableContext>
    </LexicalComposer>
  )
}

export default Editor
