import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

export const SET_CONTEXT_ACCOUNT_MAP_RULES = 'SET_CONTEXT_ACCOUNT_MAP_RULES'
export const UPDATE_ACCOUNT_MAP_RULE = 'UPDATE_ACCOUNT_MAP_RULE'

export interface SetContextAccountMapRulesAction {
  type: typeof SET_CONTEXT_ACCOUNT_MAP_RULES
  payload: AccountMapRule[]
}

export interface UpdateAccountMapRuleAction {
  type: typeof UPDATE_ACCOUNT_MAP_RULE
  payload: AccountMapRule
}

export type AccountMapActionTypes = UpdateAccountMapRuleAction | SetContextAccountMapRulesAction
