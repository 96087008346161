import React from 'react'
import { Tag, Alert } from 'antd'
import { red } from '@ant-design/colors'
import { useTranslation } from 'react-i18next'
import { FormulaErrorType } from '../../../../../../types/formula/Formula'

type FormulaSuggestionProps = {
  error: FormulaErrorType
  str: string
}

const FormulaError = ({ error, str }: FormulaSuggestionProps) => {
  const { t } = useTranslation()
  const { type, position } = error

  const { start, end } = position
  const beforeStr = str.slice(0, start)
  const errStr = str.slice(start, end)
  const afterStr = str.slice(end)

  const description = (
    <>
      <div>
        {beforeStr}
        <Tag style={{ textDecoration: 'underline', color: red.primary }}>{errStr || '_'}</Tag>
        {afterStr}
      </div>
    </>
  )

  return (
    <Alert
      style={{ marginBottom: 8 }}
      message={t(`formulaError:${type}`)}
      description={description}
      type="error"
      showIcon
    />
  )
}

export default FormulaError
