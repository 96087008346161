import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import {
  ADD_SESSION_CONTRACT_USER,
  DELETE_SESSION_CONTRACT_USER,
  SET_SESSION_CONTRACT,
  SET_SESSION_CONTRACT_USERS,
  UPDATE_SESSION_CONTRACT_USER,
  ADD_SESSION_CONTRACT_CHILDREN,
  SessionContractActionTypes,
  UPDATE_SESSION_CONTRACT_CHILDREN
} from './types'

const initialState: any = {}

const sessionContractReducer = (state: Contract = initialState, action: SessionContractActionTypes): Contract => {
  switch (action.type) {
    case SET_SESSION_CONTRACT:
      return { ...state, ...action.payload }
    case SET_SESSION_CONTRACT_USERS:
      return { ...state, users: action.payload }
    case ADD_SESSION_CONTRACT_USER:
      return { ...state, users: [...state.users, action.payload] }
    case UPDATE_SESSION_CONTRACT_USER:
      return {
        ...state,
        users: state.users.map((user: User) => (user.id !== action.payload.id ? user : { ...user, ...action.payload }))
      }
    case DELETE_SESSION_CONTRACT_USER:
      return { ...state, users: state.users.filter((user: User) => user.id !== action.payload.id) }
    case ADD_SESSION_CONTRACT_CHILDREN:
      return { ...state, children: [...state.children, action.payload] }
    case UPDATE_SESSION_CONTRACT_CHILDREN:
      return {
        ...state,
        children: state.children.map(c => {
          if (c.id === action.payload.id) {
            return action.payload
          }
          return c
        })
      }
    default:
      return state
  }
}

export default sessionContractReducer
