import { Action, ThunkAction } from '@reduxjs/toolkit'
import {
  DELETE,
  DeleteRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  BudgetCommentActionTypes,
  ADD_STATEMENTROW_BUDGET_COMMENT,
  DELETE_STATEMENTROW_BUDGET_COMMENT,
  UPDATE_STATEMENTROW_BUDGET_COMMENT,
  ADD_ACCOUNT_BUDGET_COMMENT,
  DELETE_ACCOUNT_BUDGET_COMMENT,
  UPDATE_ACCOUNT_BUDGET_COMMENT,
  ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT,
  ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT,
  DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT,
  DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT,
  UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT,
  UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT
} from './types'
import { BudgetComment } from '../../../pages/budgeting/financialStatements/components/comment/BudgetCommentItem'
import { ContextRequestParams } from '../actions'
import { Store } from '../../types'
import { BudgetingLevel } from '../../../types/companySettings/CompanySettings'

export const addStatementRowBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: ADD_STATEMENTROW_BUDGET_COMMENT,
  payload: comment
})

export const addStatementRowDimensionBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT,
  payload: comment
})

export const updateStatementRowBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: UPDATE_STATEMENTROW_BUDGET_COMMENT,
  payload: comment
})

export const updateDimensionStatementRowBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT,
  payload: comment
})

export const deleteStatementRowBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: DELETE_STATEMENTROW_BUDGET_COMMENT,
  payload: comment
})

export const deleteDimensionStatementRowBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT,
  payload: comment
})

export const addAccountBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: ADD_ACCOUNT_BUDGET_COMMENT,
  payload: comment
})

export const addAccountDimensionBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT,
  payload: comment
})

export const updateAccountBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: UPDATE_ACCOUNT_BUDGET_COMMENT,
  payload: comment
})

export const updateDimensionAccountBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT,
  payload: comment
})

export const deleteAccountBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: DELETE_ACCOUNT_BUDGET_COMMENT,
  payload: comment
})

export const deleteDimensionAccountBudgetCommentAction = (comment: BudgetComment): BudgetCommentActionTypes => ({
  type: DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT,
  payload: comment
})

export const addBudgetCommentAction = (comment: BudgetComment): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(addStatementRowBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(addAccountBudgetCommentAction(comment))
    }
  }
}

export const addDimensionBudgetCommentAction = (
  comment: BudgetComment
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(addStatementRowDimensionBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(addAccountDimensionBudgetCommentAction(comment))
    }
  }
}

export const updateBudgetCommentAction = (comment: BudgetComment): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(updateStatementRowBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(updateAccountBudgetCommentAction(comment))
    }
  }
}

export const updateDimensionBudgetCommentAction = (
  comment: BudgetComment
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(updateDimensionStatementRowBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(updateDimensionAccountBudgetCommentAction(comment))
    }
  }
}

export const deleteBudgetCommentAction = (comment: BudgetComment): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(deleteStatementRowBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(deleteAccountBudgetCommentAction(comment))
    }
  }
}

export const deleteDimensionBudgetCommentAction = (
  comment: BudgetComment
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { level } = store.context.companySettings.budgeting

    if (level === BudgetingLevel.statementRow) {
      dispatch(deleteDimensionStatementRowBudgetCommentAction(comment))
    }
    if (level === BudgetingLevel.account) {
      dispatch(deleteDimensionAccountBudgetCommentAction(comment))
    }
  }
}

export const createBudgetCommentRequest = (
  { companyId, ...params }: ContextRequestParams,
  comment: BudgetComment
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `/api/companies/${companyId}/budgeting/comments`,
    data: comment,
    params,
    success: addBudgetCommentAction
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})

export const createDimensionBudgetCommentRequest = (
  comment: BudgetComment,
  { companyId, ...params }: ContextRequestParams
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `/api/companies/${companyId}/budgeting/comments`,
    data: comment,
    params,
    success: addDimensionBudgetCommentAction
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})

export const updateBudgetCommentRequest = (
  { companyId, ...params }: ContextRequestParams,
  { id, ...comment }: BudgetComment
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/budgeting/comments/${id}`,
    data: comment,
    params,
    success: updateBudgetCommentAction
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})

export const updateDimensionBudgetCommentRequest = (
  { id, ...comment }: BudgetComment,
  { companyId, ...params }: ContextRequestParams
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/budgeting/comments/${id}`,
    data: comment,
    params,
    success: updateDimensionBudgetCommentAction
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})

export const deleteBudgetCommentRequest = (
  { companyId, ...params }: ContextRequestParams,
  comment: BudgetComment
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/comments/${comment.id}`,
    params,
    success: () => deleteBudgetCommentAction(comment)
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})

export const deleteDimensionBudgetCommentRequest = (
  comment: BudgetComment,
  { companyId, ...params }: ContextRequestParams
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/comments/${comment.id}`,
    params,
    success: () => deleteDimensionBudgetCommentAction(comment)
  },
  meta: {
    type: 'BUDGET_COMMENTS'
  }
})
