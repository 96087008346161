import React from 'react'
import { Button, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { ProCard } from '@ant-design/pro-components'
import { SearchOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { VoucherQueryParams } from './types'
import { VoucherSearchFilter } from './components/filter/VoucherSearchFilter'

interface VoucherSearchProps {
  params: Partial<VoucherQueryParams>
  setParams: (params: Partial<VoucherQueryParams>) => void
}

export const VoucherSearch: React.FC<VoucherSearchProps> = ({ params, setParams }) => {
  const [form] = useForm()
  const { t } = useTranslation()

  return (
    <ProCard
      title={t('vouchersPage:voucherSearch')}
      headerBordered
      collapsible
      style={{ marginBlockStart: 16 }}
      extra={
        <Space>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              form.submit()
            }}
          >
            {t('global:search')}
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={values => {
          setParams(values)
        }}
        layout="vertical"
        initialValues={params}
      >
        <VoucherSearchFilter />
      </Form>
    </ProCard>
  )
}
