import React from 'react'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Task } from '../types'
import { useBackend } from '../../../services/backend'
import { notificationAction } from '../../../redux/middleware/actions'
import { AppDispatch } from '../../../redux/store'

interface Props {
  url: string
  tasks: Task[]
  setTasks: (tasks: Task[]) => void
}

export const RunTask: React.FC<Props> = ({ url, tasks, setTasks }: Props) => {
  const runRequest = useBackend(url, process.env.REACT_APP_INTEGRATION_URL)
  const { loading } = runRequest
  const dispatch: AppDispatch = useDispatch()

  const run = () => {
    dispatch(
      notificationAction({
        type: 'info',
        message: 'Request sent',
        description: `POST ${url}`
      })
    )
    runRequest
      .post({})
      .then((res: Task[]) => {
        dispatch(
          notificationAction({
            type: 'info',
            message: 'Request finished',
            description: `POST ${url}`
          })
        )
        setTasks([...res, ...tasks])
      })
      .catch((e: Error) =>
        dispatch(
          notificationAction({
            type: 'error',
            message: 'Request failed',
            description: `POST ${url}\n${e.message}`
          })
        )
      )
  }

  return <Button icon={loading ? <LoadingOutlined /> : <ReloadOutlined />} size="small" onClick={run} />
}
