import { Customer } from '../../../types/customer/customer'
import { SetCustomersAction, SET_CUSTOMERS } from './types'

const initialState: Customer[] = []

const customersReducer = (state = initialState, action: SetCustomersAction) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return action.payload
    default:
      return state
  }
}

export default customersReducer
