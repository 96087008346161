import { User } from '../../../types/user/User'

export const SET_COMPANY_USERS = 'SET_COMPANY_USERS'

export interface SetCompanyUsersAction {
  type: typeof SET_COMPANY_USERS
  payload: User[]
}

export type CompanyUsersActionTypes = SetCompanyUsersAction
