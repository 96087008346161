import React, { useEffect, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts-gantt'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { useBackend } from '../../../services/backend'
import { notificationAction } from '../../../redux/middleware/actions'
import { AppDispatch } from '../../../redux/store'

export const IntegrationSchedule: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const chartRef = useRef(null)
  const scheduleRequest = useBackend(`/tasks/schedule`, process.env.REACT_APP_INTEGRATION_URL)
  const [schedules, setSchedules] = useState<any[]>([])

  const request = (params?: any) => {
    scheduleRequest
      .get({ body: { params } })
      .then(setSchedules)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'INTEGRATION_SERVER_NOT_AVAILABLE',
            description: e.message
          })
        )
      })
  }

  useEffect(() => request(), [])

  const chartOptions = {
    chart: {
      type: 'xrange'
    },
    series: schedules.map((application, index) => ({
      name: application.application,
      type: 'gantt',
      data: [
        {
          start: dayjs(application.start).toDate().getTime(),
          end: dayjs(application.end).toDate().getTime(),
          y: index
        }
      ],
      current: 0
    })),
    yAxis: {
      type: 'category',
      uniqueNames: true,
      grid: {
        columns: [
          {
            title: {
              text: 'Application'
            },
            categories: schedules.map(d => `${d.instance} ${d.application}`)
          }
        ]
      }
    }
  }

  return (
    <div style={{ overflowX: 'scroll', padding: 8 }}>
      <HighchartsReact constructorType="ganttChart" highcharts={Highcharts} options={chartOptions} ref={chartRef} />
    </div>
  )
}
