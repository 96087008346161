import { FormInstance, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AccountForm from './AccountForm'
import { Account } from '../../../../types/account/Account'
import { companyAccountsRawByIdSelector } from '../../../../redux/context/accounts/selectors'

interface AccountFormModalProps {
  open: boolean
  onCreate: (values: Account) => void
  onUpdate: (values: Account) => void
  onCancel: () => void
  initialValues?: Account
}

const AccountFormModal: React.FC<AccountFormModalProps> = ({ open, onCreate, onUpdate, onCancel, initialValues }) => {
  const [formInstance, setFormInstance] = useState<FormInstance>()
  const account = useSelector(companyAccountsRawByIdSelector(initialValues?.code))
  const { t } = useTranslation()

  return (
    <Modal
      open={!!open}
      title={t('actions:createNew')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      okButtonProps={{ autoFocus: true }}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields()
          formInstance?.resetFields()
          if (initialValues) {
            onUpdate(values)
          } else {
            onCreate(values)
          }
        } catch (error) {
          console.log('Failed:', error)
        }
      }}
    >
      <AccountForm
        initialValues={account}
        onFormInstanceReady={instance => {
          setFormInstance(instance)
        }}
      />
    </Modal>
  )
}

export default AccountFormModal
