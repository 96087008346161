// eslint-disable-next-line max-classes-per-file
import _, { isArray } from 'lodash'
import i18next from 'i18next'
import { Tree, TreeNode } from '../../pages/reporting/custom/components/customReportTable/Tree'
import { Dimension, DimensionQuery, DimensionQueryOperator } from '../../types/dimension/Dimension'
import { filterpop } from '../../utils/helpers'

export class DimensionTreeNode extends TreeNode<Dimension> {
  constructor(key: string, value = null as Dimension | null, parent = null as DimensionTreeNode['parent']) {
    super(key, value, parent)
  }
}

export class DimensionTree extends Tree<Dimension> {
  root: DimensionTreeNode

  constructor(key = 'root') {
    super(key)
    this.root = new DimensionTreeNode(key)
  }

  static queryBuilder(ids: string[][] | string[]) {
    if (!ids || ids.length === 0) return undefined

    const recurse = (val: string[][] | string[] | string): DimensionQuery => {
      if (isArray(val)) {
        if (val.length > 1) {
          const operator = isArray(val[0]) ? DimensionQueryOperator.and : DimensionQueryOperator.or
          return {
            o: operator,
            c: val.map(child => recurse(child))
          }
        }
        return recurse(val[0])
      }
      return {
        o: DimensionQueryOperator.has,
        v: val
      }
    }

    const query = recurse(ids)
    return query
  }

  insert(parentNodeKey: string, key: string, value = null as Dimension | null) {
    const node = this.find(parentNodeKey)
    if (node?.key === parentNodeKey) {
      node.children.push(new DimensionTreeNode(key, value, node))
      return true
    }
    return false
  }

  reset() {
    this.root.children.forEach(c => this.remove(c.key))
  }

  build(list: Dimension[]) {
    const copy = _.clone(list)
    for (const node of this.preOrderTraversal()) {
      const children = filterpop(copy, d => {
        if (node.key === 'root') {
          // Laitetaan dimensio juureen jos ei parent dimension Id:tä
          // tai jos parent dimensiota ei ole listassa.
          return d.parentDimensionId === null || !copy.find(i => i.dimensionId === d.parentDimensionId)
        }
        return d.parentDimensionId === node.key
      }).map(c => new DimensionTreeNode(c.dimensionId, c, node))
      node.children.push(...children)
    }
  }

  getQuery(ids?: string[]): string | undefined {
    const subTrees = this.getSubTrees(ids)

    if (!subTrees || !ids || ids.length === 0) return undefined

    const query = DimensionTree.queryBuilder(subTrees)

    return JSON.stringify([query])
  }

  getSubTrees(ids?: string[], propertyName: keyof Dimension = 'dimensionId') {
    if (!ids || ids.length === 0) return undefined
    const subTrees: string[][] = []

    this.root.children.forEach(rootNode => {
      const subTreeIds: string[] = []
      for (const node of this.preOrderTraversal(rootNode)) {
        if (ids.includes(`${node?.key}`)) {
          subTreeIds.push(node?.value?.[propertyName] as string)
        }
      }
      if (subTreeIds.length > 0) {
        subTrees.push(subTreeIds)
      }
    })
    return subTrees
  }

  getDimensionText(ids?: string[]) {
    const subTrees = this.getSubTrees(ids, 'name')

    if (!subTrees || !ids || ids.length === 0) return null

    const a = subTrees.map(sub => sub.join(` ${i18next.t('global:or')} `)).join(` ${i18next.t('global:and')} `)

    return a
  }

  getNamedSubTrees(ids?: string[]) {
    const subTrees = this.getSubTrees(ids, 'name')

    return subTrees
  }
}
