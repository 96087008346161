import React from 'react'
import { theme } from 'antd'
import Footer from '../components/GlobalFooter/AppFooter'
import { ReactComponent as Logo } from '../assets/finadeck-logo-web.svg'
import { ReactComponent as AccountorLogo } from '../assets/accountor/accountor_logo.svg'
import RantalainenLogo from '../assets/rantalainen/rantalainen.png'

const UserLayout: React.FC<any> = props => {
  const { children } = props

  const {
    token: { colorBgBase }
  } = theme.useToken()

  const renderLogo = () => {
    if (window.location.hostname === 'accountor.finadeck.fi')
      return <AccountorLogo style={{ height: 50, marginLeft: 10 }} />
    if (window.location.hostname === 'rantalainen.finadeck.fi')
      return <img src={RantalainenLogo} alt="RantalainenLogo" style={{ height: '64px' }} />
    return (
      <>
        <Logo style={{ height: '64px' }} />
        <span className="logo-text">finadeck</span>
      </>
    )
  }

  return (
    <div className="user-layout" style={{ minHeight: '100vh', background: colorBgBase }}>
      <div className="lang">{/* <SelectLang /> */}</div>
      <div
        className="content"
        style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="login-form" style={{ width: '100%' }}>
          <div className="login-header">{renderLogo()}</div>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserLayout
