import React from 'react'
import VehicleBasedIntegrations from '../vehicleBasedIntegrations/VehicleBasedIntegrations'

const Kiho: React.FC = () => {
  return (
    <VehicleBasedIntegrations
      allowAuthentication
      credentialsRequestUrl="/kiho/authentication/{companyId}"
      getVehiclesUrl="/kiho/vehicles/{companyId}"
      updateVehicleUrl="/kiho/vehicles/{companyId}/{id}"
      pageDocsUrl="manual/businesses/settings/company#kiho"
      name="kiho"
    />
  )
}

export default Kiho
