import React, { Key, useState } from 'react'
import { Modal, Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { ReportTableRow } from '../../../../../components/Table/types'
import { periodGroupLabel } from '../../../../../redux/context/fiscalYears/utils'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { formatValueByType } from '../../../../../utils/helpers'
import { Column } from '../../../../../redux/context/periodGroups/hooks'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import { isBudgetDriverMethod } from '../../../../../components/Budgeting/BudgetingMethodBudgetDriver'

interface BudgetDistributionModalProps {
  deleteModalVisible: boolean
  columns?: Column[]
  reportTableRow?: ReportTableRow
  deleteBudgets: (values: PeriodGroup[], row: ReportTableRow, driver?: boolean) => void
  handleCancel: () => void
}

const BudgetDeleteModal = ({
  deleteModalVisible,
  columns,
  reportTableRow,
  deleteBudgets,
  handleCancel
}: BudgetDistributionModalProps) => {
  const { t } = useTranslation()
  const [selectedPeriodGroups, setSelectedPeriodGroups] = useState<Key[]>([])

  const onSelectChange = (selectedRowKeys: Key[]) => {
    setSelectedPeriodGroups(selectedRowKeys)
  }

  const getValue = (record: Column) => {
    const balance = reportTableRow?.budgets?.find(budget => budget.groupId === record.periodGroup?.id)
    return balance?.value
  }

  const tableColumns: ColumnProps<Column>[] = [
    {
      title: t('valuationPage:fiscal-year'),
      dataIndex: 'periodGroup',
      render: record => periodGroupLabel(record, ReportDataType.actuals)
    },
    {
      title: t('global:value'),
      dataIndex: 'value',
      render: (_, record) => {
        return (
          formatValueByType(getValue(record), reportTableRow?.variableType, {
            maximumFractionDigits: 2
          }) || 0
        )
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys: selectedPeriodGroups,
    onChange: onSelectChange
  }

  const handleOk = () => {
    if (!reportTableRow) return
    const periodGroups = selectedPeriodGroups
      .map(key => columns?.find(c => c.periodGroup?.id === key)?.periodGroup)
      .filter(p => p !== undefined) as PeriodGroup[]
    deleteBudgets(periodGroups, reportTableRow, isBudgetDriverMethod(reportTableRow))
    handleCancel()
    setSelectedPeriodGroups([])
  }

  return (
    <Modal
      title={t('financialStatementsPage:delete-budgets')}
      open={deleteModalVisible}
      destroyOnClose
      okText={t('global:delete')}
      onOk={handleOk}
      maskClosable={false}
      okButtonProps={{ disabled: selectedPeriodGroups.length <= 0 }}
      cancelText={t('global:cancel')}
      onCancel={() => {
        handleCancel()
        setSelectedPeriodGroups([])
      }}
    >
      <Table
        rowSelection={rowSelection}
        rowKey={record => record.periodGroup?.id || -1}
        size="small"
        pagination={false}
        columns={tableColumns}
        dataSource={columns?.filter(c => c.dataType === ReportDataType.budget)}
      />
    </Modal>
  )
}

export default BudgetDeleteModal
