import { RequestError } from '../error/types'

export const REQUEST = 'REQUEST'
export const DELIVERY_REQUEST = 'DELIVERY_REQUEST'
export const INTEGRATION_REQUEST = 'INTEGRATION_REQUEST'
export type API_REQUEST = typeof REQUEST | typeof DELIVERY_REQUEST | typeof INTEGRATION_REQUEST

export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

export interface RequestConfig {
  type: API_REQUEST
  baseURL?: string
}

export const backendRequestConfig: RequestConfig = {
  type: REQUEST,
  baseURL: process.env.REACT_APP_BACKEND_URL
}

export const deliveryRequestConfig: RequestConfig = {
  type: DELIVERY_REQUEST,
  baseURL: process.env.REACT_APP_DELIVERY_URL
}

export const integrationRequestConfig: RequestConfig = {
  type: INTEGRATION_REQUEST,
  baseURL: process.env.REACT_APP_INTEGRATION_URL
}

export interface GetRequestAction {
  type: API_REQUEST
  retry?: boolean
  payload: {
    url: string
    method: typeof GET
    data?: any
    params?: any
    success?: Function
  }
  meta: {
    type: string
    notification?: boolean
  }
}

export interface PutRequestAction {
  type: API_REQUEST
  retry?: boolean
  payload: {
    url: string
    method: typeof PUT
    data?: any
    params?: any
    success?: Function
  }
  meta: {
    type: string
    notification?: boolean
  }
}

export interface PostRequestAction {
  type: API_REQUEST
  retry?: boolean
  payload: {
    url: string
    method: typeof POST
    data?: any
    params?: any
    success?: Function
  }
  meta: {
    type: string
    notification?: boolean
  }
}
export interface DeleteRequestAction {
  type: API_REQUEST
  retry?: boolean
  payload: {
    url: string
    method: typeof DELETE
    params?: any
    success?: Function
  }
  meta: {
    type: string
    notification?: boolean
  }
}

export type RequestActionTypes = GetRequestAction | PostRequestAction | DeleteRequestAction | PutRequestAction

export interface MetaAction {
  type: string
  payload?: string
}

export interface ErrorAction {
  type: string
  payload?: RequestError
}

export interface NotificationAction {
  type: 'NOTIFICATION'
  payload: {
    type: 'success' | 'info' | 'error'
    message: string
    description?: string
    duration?: number
  }
}
