import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractFormStep } from '../../types/contract/Contract'
import ContractForm from './components/ContractForm'
import ContractFormProvider from './components/ContractFormContext'
import ContractTable from './components/ContractTable'

const Contracts = () => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisibility] = useState(false)

  const handleCancel = () => {
    setModalVisibility(false)
  }

  return (
    <>
      <Button style={{ marginBottom: '8px' }} onClick={() => setModalVisibility(true)}>
        {`+ ${t('global:add-new')}`}
      </Button>
      <ContractFormProvider>
        <ContractForm handleCancel={handleCancel} modalVisible={modalVisible} />
      </ContractFormProvider>
      <ContractFormProvider initialStep={ContractFormStep.Product}>
        <ContractTable />
      </ContractFormProvider>
    </>
  )
}

export default Contracts
