import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import { SET_COMPETITOR_COMPARISON_DATA, SetCompetitorComparisonData } from './types'
import { CompetitorComparison } from '../../../types/industry/Industry'

export const setCompetitorComparisonData = (
  competitorComparisonData: CompetitorComparison
): SetCompetitorComparisonData => ({
  type: SET_COMPETITOR_COMPARISON_DATA,
  payload: competitorComparisonData
})

export const getCompetitorComparisonData = (
  companies: { id: string }[],
  companyId: Company['id']
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { companies },
    url: `/api/companies/${companyId}/comparison/competitor`,
    success: (payload: CompetitorComparison) => setCompetitorComparisonData(payload)
  },
  meta: {
    type: 'INDUSTRY_COMPARISON'
  }
})
