import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'

export const SET_CONTEXT_CONTRACT = 'SET_CONTEXT_CONTRACT'
export const SET_CONTEXT_CONTRACT_USERS = 'SET_CONTEXT_CONTRACT_USERS'
export const ADD_CONTEXT_CONTRACT_USER = 'ADD_COMPANY_USER'
export const UPDATE_CONTEXT_CONTRACT_USER = 'UPDATE_COMPANY_USER'
export const DELETE_CONTEXT_CONTRACT_USER = 'DELETE_COMPANY_USER'

export interface SetContextContractAction {
  type: typeof SET_CONTEXT_CONTRACT
  payload: Contract
}

export interface SetContextContractUsersAction {
  type: typeof SET_CONTEXT_CONTRACT_USERS
  payload: User[]
}

export interface AddContextContractUsersAction {
  type: typeof ADD_CONTEXT_CONTRACT_USER
  payload: User
}

export interface UpdateContextContractUsersAction {
  type: typeof UPDATE_CONTEXT_CONTRACT_USER
  payload: User
}

export interface DeleteContextContractUsersAction {
  type: typeof DELETE_CONTEXT_CONTRACT_USER
  payload: User
}

export type ContextContractActionTypes =
  | SetContextContractAction
  | SetContextContractUsersAction
  | AddContextContractUsersAction
  | UpdateContextContractUsersAction
  | DeleteContextContractUsersAction
