import {
  REQUEST,
  GetRequestAction,
  GET,
  DELETE,
  DeleteRequestAction,
  PutRequestAction,
  PUT
} from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import {
  SET_FORMULAS,
  SetFormulasAction,
  ADD_FORMULA,
  AddFormulaAction,
  UpdateFormulaAction,
  UPDATE_FORMULA,
  DeleteFormulaAction,
  DELETE_FORMULA
} from './types'
import { Formula } from '../../../types/formula/Formula'
import { ContextRequestParams } from '../actions'

export const setFormulas = (formulas: Formula[]): SetFormulasAction => ({
  type: SET_FORMULAS,
  payload: formulas
})

export const addFormula = (formula: Formula): AddFormulaAction => ({
  type: ADD_FORMULA,
  payload: formula
})

export const updateFormula = (formula: Formula): UpdateFormulaAction => ({
  type: UPDATE_FORMULA,
  payload: formula
})

export const deleteFormula = (formula: Formula): DeleteFormulaAction => ({
  type: DELETE_FORMULA,
  payload: formula
})

export const getFormulasRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/key-figures/formulas`,
    success: setFormulas
  },
  meta: {
    type: 'FORMULAS'
  }
})

export const deleteFormulaRequest = (companyId: Company['id'], formulaId: Formula['id']): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/settings/key-figures/formulas/${formulaId}`,
    success: deleteFormula
  },
  meta: {
    type: 'DELETE_FORMULA'
  }
})

export const updateFormulaOrdersRequest = (companyId: Company['id'], formulaOrders: any[]): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: formulaOrders,
    url: `/api/companies/${companyId}/settings/key-figures/formulas/order`,
    success: setFormulas
  },
  meta: {
    type: 'UPDATE_FORMULA_ORDERS'
  }
})

// export const createFormulaRequest = (companyId: Company['id'], formula: any): PostRequestAction => ({
//   type: REQUEST,
//   payload: {
//     method: POST,
//     url: `/api/companies/${companyId}/settings/key-figures/formulas`,
//     data: formula,
//     success: addFormula
//   },
//   meta: {
//     type: 'CREATE_FORMULA'
//   }
// })
