import React, { useState } from 'react'
import { Card, Typography, Table, Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { OrderedListOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Investment } from '../../../../types/investment/Investment'
import { formatValueByType } from '../../../../utils/helpers'
import { VariableType } from '../../../../components/Table/types'
import InvestmentTableForm from './form/InvestmentTableForm'
import InvestmentSummary from './InvestmentSummary'
import { InvestmentsPageBalanceSheetRow } from '../../../../redux/pages/investments/types'
import DepreciationModal from './DepreciationModal'
import { getInvestmentsSummaryRequest } from '../../../../redux/pages/investments/actions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { investmentsPageSelector } from '../../../../redux/pages/investments/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { useColumnSearchProps } from '../../../../components/Table/useColumSearchProps'
import { AppDispatch } from '../../../../redux/store'

type InvestmentGroupProps = InvestmentsPageBalanceSheetRow

const InvestmentGroup: React.FC<InvestmentGroupProps> = ({ balanceSheetRowId, investments, summary, isExisting }) => {
  const [investmentId, setInvestmentId] = useState<undefined | string>(undefined)
  const companyId = useSelector(contextCompanyIdSelector)!
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()

  const handleOk = (dataUpdated: boolean) => {
    setInvestmentId(undefined)
    if (dataUpdated) {
      if (isExisting) {
        dispatch(
          getInvestmentsSummaryRequest(
            companyId,
            dayjs(date).endOf('month').format('YYYY-MM-DD'),
            budgetingScenario && budgetingScenario.id,
            true
          )
        )
      } else {
        dispatch(
          getInvestmentsSummaryRequest(
            companyId,
            dayjs(date).endOf('month').format('YYYY-MM-DD'),
            budgetingScenario && budgetingScenario.id,
            false
          )
        )
      }
    }
  }

  const columns: ColumnsType<Investment> = [
    {
      title: t('investmentsPage:name'),
      dataIndex: 'name',
      width: '40%',
      ellipsis: true,
      ...useColumnSearchProps('name')
    },
    {
      title: t('investmentsPage:startDate'),
      dataIndex: 'date',
      align: 'right',
      width: '30%',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.date?.localeCompare(b?.date || ''),
      render: value => value && dayjs(value).format('L')
    },
    {
      title: t('investmentsPage:value'),
      dataIndex: 'value',
      align: 'right',
      width: '23%',
      render: value => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('investmentsPage:depreciations'),
      dataIndex: 'deprecations',
      key: 'deprecations',
      align: 'right',
      width: '7%',
      render: (value, record) => {
        if (record.value > 0) {
          return (
            <Button
              style={{ marginRight: '8px' }}
              icon={<OrderedListOutlined />}
              onClick={() => setInvestmentId(`${record.id}`)}
            />
          )
        }
        return null
      }
    }
  ]

  if (!investments?.length) return null

  return (
    <Card>
      <DepreciationModal investmentId={investmentId} handleOk={handleOk} />
      <Space direction="vertical" size="middle">
        <Typography.Title level={3}>{t(`balanceSheet:${balanceSheetRowId}`)}</Typography.Title>
        <InvestmentSummary summary={summary} />
        <Table
          rowKey="id"
          expandable={{
            expandedRowRender: (record, index, indent, expanded) => {
              return expanded ? <InvestmentTableForm investment={record} /> : null
            }
          }}
          dataSource={investments}
          columns={columns}
        />
      </Space>
    </Card>
  )
}

export default InvestmentGroup
