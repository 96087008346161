/* eslint-disable react/function-component-definition */
import { EditOutlined, DeleteOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { VariableType } from '../../../../../components/Table/types'
import { companyAccountsSelector } from '../../../../../redux/context/accounts/selectors'
import { dimensionListSelector } from '../../../../../redux/context/dimensions/selectors'
import { userCompaniesSelector } from '../../../../../redux/session/currentUser/selectors'
import {
  ReallocationSource,
  ReallocationType,
  ReallocationTarget,
  ReallocationTargetType
} from '../../../../../types/reallocation/Reallocation'
import { formatValueByType } from '../../../../../utils/helpers'
import { ReallocationContext, ReallocationContextType } from './ReallocationProvider'

interface ReallocationTargetTableProps {
  reallocation: ReallocationSource
  targets: ReallocationTarget[]
}
const ReallocationTargetTable = ({ reallocation, targets }: ReallocationTargetTableProps) => {
  const dimensions = useSelector(dimensionListSelector)
  const companies = useSelector(userCompaniesSelector)
  const { t } = useTranslation()
  const accounts = useSelector(companyAccountsSelector)
  const { handleNewTarget, handleEditTarget, handleCopyTarget, handleDeleteTarget } = useContext(
    ReallocationContext
  ) as ReallocationContextType

  const percentFormat = (val: string | number) => {
    return `${formatValueByType(+val, VariableType.percentage, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })}`
  }

  const targetColumns: ColumnsType<ReallocationTarget> = [
    {
      key: 'companyId',
      title: t('global:company'),
      dataIndex: 'companyId',
      render: val => companies && companies?.find(d => d.id === val)?.name,
      sorter: (a, b) => {
        const aName = companies && companies?.find(d => d.id === a.companyId)?.name
        const bName = companies && companies?.find(d => d.id === b.companyId)?.name
        return aName?.localeCompare(bName || '') || 0
      }
    },
    {
      key: 'dimensionId',
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      render: val => dimensions && dimensions?.find(d => d.dimensionId === val)?.name,
      sorter: (a, b) => {
        const aName = dimensions && dimensions?.find(d => d.dimensionId === a.dimensionId)?.name
        const bName = dimensions && dimensions?.find(d => d.dimensionId === b.dimensionId)?.name
        return aName?.localeCompare(bName || '') || 0
      }
    },
    {
      key: 'accounts',
      title: t('reallocationsPage:accounts'),
      render: (val, record) => `${record.accountFrom} - ${record.accountTo}`
    },
    {
      key: 'type',
      title: t('reallocationsPage:targetAccounts'),
      render: (val, record) => {
        if (record.type === ReallocationTargetType.range) {
          return `${record.accountFrom} - ${record.accountTo}`
        }
        const selectedAccount = accounts?.find(account => account.code === record.account)
        return selectedAccount && `${selectedAccount.code} - ${selectedAccount.name}`
      }
    }
  ]

  if (reallocation.type === ReallocationType.fixed) {
    targetColumns.push({
      key: 'fixed',
      title: t('reallocationsPage:fixed'),
      dataIndex: 'fixed',
      align: 'right',
      render: percentFormat,
      sorter: (a, b) => a.fixed - b.fixed
    })
  }

  targetColumns.push({
    key: 'actions',
    align: 'right',
    title: t('global:actions'),
    dataIndex: 'dimensionId',
    render: (val: string, record) => (
      <Space>
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => {
            handleEditTarget(record, reallocation)
          }}
        />
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={() => {
            handleCopyTarget(record, reallocation)
          }}
        />
        <Popconfirm
          title={t('global:delete-confirm')}
          onConfirm={() => handleDeleteTarget(record, reallocation)}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button type="text" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </Space>
    )
  })

  const fixedSumRowRender = (pageData: readonly ReallocationTarget[]) => {
    let totalFixed = 0

    pageData.forEach(({ fixed }) => {
      totalFixed += +fixed
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>{t('loans:total-value')}</Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell align="right" index={3}>
          {percentFormat(totalFixed)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  return (
    <div>
      <Table
        rowKey="id"
        pagination={false}
        dataSource={targets?.sort((a, b) => a.id - b.id)}
        columns={targetColumns}
        summary={reallocation.type === ReallocationType.fixed ? fixedSumRowRender : undefined}
      />
      <Button
        icon={<PlusOutlined />}
        style={{ marginTop: '0.5rem' }}
        block
        type="dashed"
        onClick={() => handleNewTarget(reallocation)}
      >
        {t('global:add-new')}
      </Button>
    </div>
  )
}

export default ReallocationTargetTable
