import { Button, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { Loading } from '../../../../../components/Misc/Loading'
import { useDocsURL } from '../../../../../utils/docs'
import DataUpdate from '../dataUpdate'

const Xero: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const [authorized, setAuthorized] = useState(false)
  const getCredentials = useBackend(`/xero/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const authButton = () => {
    return (
      <>
        <Button
          href={`${process.env.REACT_APP_INTEGRATION_URL}/oauth2/xero/authenticate/${companyId}`}
          target="_blank"
          type="primary"
        >
          {t('integrationsPage:authenticate')}
        </Button>
        {authorized && <DataUpdate page="xero" />}
      </>
    )
  }

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((response: any) => {
        switch (response.httpCode) {
          case 401:
            setAuthorized(false)
            break
          default:
            break
        }
        if (response.companyId || response.refresh_token) setAuthorized(true)
      })
      .catch(() => {
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  if (getCredentials.loading) {
    return <Loading />
  }
  if (!authorized) {
    return (
      <>
        <a href={`${docsURL}manual/businesses/settings/company#xero`} target="_blank" rel="noopener noreferrer">
          {t('header:manual')}
        </a>
        <Result status="info" title={t('integrationsPage:notAuthenticated')} extra={authButton()} />
      </>
    )
  }
  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#xero`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Result status="success" title={t('integrationsPage:authenticated')} extra={authButton()} />
    </>
  )
}

export default Xero
