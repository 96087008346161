import { createSelector } from 'reselect'
import i18next from 'i18next'
import { contextSelector } from '../selectors'
import { loadingSelector } from '../../loading/selectors'
import { Dimension } from '../../../types/dimension/Dimension'
import { contextCompanySelector } from '../company/selectors'
import { getDimensionTree } from '../../../components/Dimension/utils'

export const dimensionsLoadingSelector = createSelector(loadingSelector, loading => loading.dimensions)
export const dimensionTreeSelector = createSelector(contextSelector, context => {
  return getDimensionTree(context.dimensions || []) as Dimension[]
})
export const dimensionTreeWithRootSelector = createSelector(
  [contextCompanySelector, dimensionTreeSelector],
  (company, dimensions) => {
    return {
      companyId: company?.id,
      id: -1,
      dimensionId: 'root',
      name: i18next.t('global:enterpriseLevel'),
      children: dimensions
    } as Dimension
  }
)

export const dimensionListSelector = createSelector(contextSelector, context => {
  return context?.dimensions?.slice()?.sort((a, b) => (a.order > b.order ? 1 : -1)) || ([] as Dimension[])
})

export const dimensionByIdSelector = (id: string) =>
  createSelector(dimensionListSelector, list => {
    return list.find(dimension => dimension.dimensionId === id)
  })

export const dimensionsArgumentOptionsSelector = createSelector(dimensionListSelector, dimensions => {
  return dimensions?.map(dimension => ({
    name: `${dimension.dimensionId} - ${dimension.name}`,
    value: `'${dimension.dimensionId}'`
  }))
})
