import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Contract } from '../../../../types/contract/Contract'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { sessionContractUserSelector } from '../../../../redux/session/contract/selectors'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { filterSalesUsers } from '../utils'
import { AppDispatch } from '../../../../redux/store'

interface SuperUserContractBasicsProps {
  contract: Contract
}

const SuperUserManagement: React.FC<SuperUserContractBasicsProps> = ({ contract: contractProp }) => {
  const updateContractRequest = useBackend(`/api/superuser/contracts/{contractId}`)
  const sessionContractUsers = useSelector(sessionContractUserSelector)

  const [contract, setContract] = useState<Contract>(contractProp)
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const [form] = Form.useForm<Contract>()

  const sendUpdatecontractRequest = (values?: Partial<Contract>) => {
    updateContractRequest
      .put({ urlParams: { contractId: contract.id }, body: { data: { ...values } } })
      .then((res: Contract) => {
        setContract({ ...contract, ...res })
        dispatch(
          notificationAction({
            type: 'success',
            message: 'OK'
          })
        )
      })
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACTS_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(() => form.resetFields(), [contractProp])

  return (
    <Form
      name="management"
      initialValues={contract}
      form={form}
      onFinish={sendUpdatecontractRequest}
      className="profile-form"
      layout="vertical"
    >
      <Form.Item name="sellerId" label="Myyjä">
        <Select>
          {filterSalesUsers(sessionContractUsers).map(user => (
            <Select.Option value={user.id} key={user.id}>
              {user.displayName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="price" label="Hinta">
        <FormattedInputNumber />
      </Form.Item>

      <Form.Item name="billingPeriod" label="Laskutuskausi kuukautta">
        <FormattedInputNumber />
      </Form.Item>

      <Form.Item initialValue valuePropName="checked" name="billingStarted" label="Laskutus käynnistynyt">
        <Checkbox />
      </Form.Item>

      <Form.Item>
        <Button loading={updateContractRequest.loading} type="primary" htmlType="submit" className="login-form-button">
          {t('global:update')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SuperUserManagement
