import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Result, Tabs, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { NetvisorApiCredentials } from '../types'
import { notificationAction } from '../../../../../redux/middleware/actions'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import { useDocsURL } from '../../../../../utils/docs'
import Loading from '../../../../loading/Loading'
import DataUpdate from '../dataUpdate'
import { AppDispatch } from '../../../../../redux/store'

const Netvisor: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/netvisor/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setCredentials = useBackend(`/netvisor/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const [customerId, setCustomerId] = useState<string>()
  const [privateKey, setPrivateKey] = useState<string>()
  const [authorized, setAuthorized] = useState<boolean>(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((credentials: NetvisorApiCredentials) => {
        if (credentials.customerId && credentials.privateKey) {
          setAuthorized(true)
        } else {
          setCustomerId('')
          setPrivateKey('')
          setAuthorized(false)
        }
      })
      .catch((err: any) => {
        setCustomerId('')
        setPrivateKey('')
        setAuthorized(false)
        console.log(err)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const submit = () => {
    setCredentials
      .put({
        urlParams: { companyId },
        body: {
          data: {
            customerId,
            privateKey
          }
        }
      })
      .then((credentials: NetvisorApiCredentials) => {
        if (credentials.customerId && credentials.privateKey) {
          setAuthorized(true)
        }
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: 'USERNAME_PASSWORD_MISMATCH'
          })
        )
      })
  }

  const authButton = () => {
    return (
      <>
        <Button type="primary" onClick={() => setAuthorized(false)}>
          {t('integrationsPage:authenticate')}
        </Button>
        <DataUpdate page="netvisor" />
      </>
    )
  }

  if (getCredentials.loading) {
    return <Loading />
  }

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'item-1',
      children: authorized ? (
        <>
          <Result status="success" title={t('integrationsPage:authenticated')} extra={authButton()} />
        </>
      ) : (
        <Form layout="vertical">
          <Form.Item label={t('integrationsPage:username')}>
            <Input
              value={customerId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCustomerId(event.target.value)}
              suffix={getCredentials.loading && <LoadingOutlined />}
            />
          </Form.Item>
          <Form.Item label={t('integrationsPage:key')}>
            <Input
              value={privateKey}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrivateKey(event.target.value)}
              suffix={getCredentials.loading && <LoadingOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => submit()} loading={setCredentials.loading}>
              {t('integrationsPage:authenticate')}
            </Button>
          </Form.Item>
        </Form>
      )
    },
    {
      label: t('integrationsPage:file-upload'),
      key: 'item-2',
      children: (
        <FileUpload
          actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/netvisor-csv/uploads/${companyId}`}
          taskRequestUrl="/netvisor-csv/tasks/{companyId}"
          uploadText={t('integrationsPage:csv-info')}
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#netvisor`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs defaultActiveKey="item-1" items={items} />
    </>
  )
}

export default Netvisor
