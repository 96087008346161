export const SET_TOKEN = 'SET_TOKEN'
export const LOGOUT = 'LOGOUT'

export interface Credentials {
  email: string
  password: string
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: Authentication
}

export interface LogoutAction {
  type: typeof LOGOUT
}

export interface Authentication {
  token?: string
}

export type AuthenticationActionTypes = SetTokenAction | LogoutAction
