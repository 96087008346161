import { Row, Col, Statistic, Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Report } from '../../../../redux/context/reports/types'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'
import { calculateInfoValue, infoTitle, calculateInfoPercentageValue } from './utils'
import { ThresholdValueSetting } from '../../../../redux/context/customReports/typesVariable'

interface StatisticsProps {
  periodGroup: FiscalYear | PeriodGroup | undefined
  series: any
  report: Report
  columns: any
  params: any
  periodGroups: any
  currentFiscalYear?: FiscalYear
}

const InfoStatistics = ({
  periodGroup,
  series,
  report,
  columns,
  params,
  periodGroups,
  currentFiscalYear
}: StatisticsProps) => {
  const { i18n } = useTranslation()

  const percentageValue = calculateInfoPercentageValue(
    periodGroup,
    params,
    report,
    series,
    columns,
    currentFiscalYear
  ) as number

  const getColor = () => {
    switch (params?.representation?.options?.thresholdValueSetting) {
      case ThresholdValueSetting.smallerBetter:
        if (percentageValue > 0) {
          return 'red'
        }
        return '#3f8600'
      case ThresholdValueSetting.biggerBetter:
        if (percentageValue > 0) {
          return '#3f8600'
        }
        return 'red'
      default:
        if (percentageValue > 0) {
          return '#3f8600'
        }
        return 'red'
    }
  }

  const getIcon = () => {
    switch (params?.representation?.options?.thresholdValueSetting) {
      case 'smallerBetter':
        if (percentageValue > 0) {
          return <ArrowDownOutlined />
        }
        return <ArrowUpOutlined />
      case 'biggerBetter':
        if (percentageValue > 0) {
          return <ArrowUpOutlined />
        }
        return <ArrowDownOutlined />
      default:
        if (percentageValue > 0) {
          return <ArrowUpOutlined />
        }
        return <ArrowDownOutlined />
    }
  }

  return (
    <Row style={{ padding: 8 }}>
      <Col span={8}>
        <Statistic
          value={calculateInfoValue(periodGroup, report, columns, currentFiscalYear).toLocaleString(i18n.language)}
          title={infoTitle('current', periodGroup, params, columns, periodGroups, currentFiscalYear)}
          suffix={report[0] && report[0].type === 'percentage' && '%'}
        />
      </Col>
      <Col span={8} offset={8} style={{ textAlign: 'right' }}>
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          placement="top"
          title={calculateInfoPercentageValue(periodGroup, params, report, series, columns, currentFiscalYear, true)}
        >
          <Statistic
            value={percentageValue?.toLocaleString(i18n.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
            title={infoTitle('compare', periodGroup, params, columns, periodGroups, currentFiscalYear)}
            precision={2}
            valueStyle={{
              color: percentageValue === 0 ? 'grey' : getColor()
            }}
            prefix={getIcon()}
            suffix="%"
          />
        </Tooltip>
      </Col>
    </Row>
  )
}

export default InfoStatistics
