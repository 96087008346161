import { Form, Radio } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BudgetingMethodSourceSelect } from '../../../../../../components/Budgeting/BudgetingMethodSourceSelect'
import { BudgetingLevel } from '../../../../../../types/companySettings/CompanySettings'
import { BudgetingMethodTranslations } from '../../../../../budgeting/financialStatements/components/budget/BudgetMethodModal'
import { BudgetingMethod } from '../../../../../budgeting/financialStatements/components/budget/types'

const radioStyle = {
  display: 'block'
}

const BudgetingInputFields = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Form.Item
        name={['budgeting', 'baseLine']}
        rules={[{ required: true }]}
        style={{ maxWidth: 'none' }}
        label={t('budgetingInputPage:income-budgeting-balance')}
      >
        <Radio.Group>
          <Radio style={radioStyle} value="none">
            {t('budgetingInputPage:use-none')}
          </Radio>
          <Radio style={radioStyle} value="lastClosedFiscalYear">
            {t('budgetingInputPage:use-last-closed')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['budgeting', 'level']}
        rules={[{ required: true }]}
        style={{ maxWidth: 'none' }}
        label={t('budgetingInputPage:budgeting-accuracy')}
      >
        <Radio.Group>
          <Radio style={radioStyle} value="statementRow">
            {t('budgetingInputPage:by-statementrow')}
          </Radio>
          <Radio style={radioStyle} value="account">
            {t('budgetingInputPage:by-account')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['budgeting', 'method', 'method']}
        rules={[{ required: true }]}
        style={{ maxWidth: 'none' }}
        label={t('budgetingInputPage:default-budgeting-method')}
        initialValue={BudgetingMethod.absolute}
      >
        <Radio.Group>
          {Object.values(BudgetingMethod).map(method => (
            <Radio key={method} style={radioStyle} value={method}>
              {t(BudgetingMethodTranslations[method])}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          return (
            getFieldValue(['budgeting', 'method', 'method']) === BudgetingMethod.reference && (
              <Form.Item
                name={['budgeting', 'method', 'source']}
                style={{
                  maxWidth: 'none'
                }}
                label={t('financialStatementsPage:budgetmenu-reference-select')}
              >
                <BudgetingMethodSourceSelect
                  budgetingLevel={getFieldValue(['budgeting', 'level']) as BudgetingLevel}
                  width={360}
                />
              </Form.Item>
            )
          )
        }}
      </Form.Item>
    </div>
  )
}

export default BudgetingInputFields
