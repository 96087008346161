import { Col, Form, Input, Row, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BudgetDriver } from '../../../../../../types/budgetDriver/BudgetDriver'
import { AggregationTypes } from '../../../../../../types/formula/Formula'
import { budgetDriverKeyFigureTypes } from '../../../../../reporting/keyFigures/config'

export interface BudgetDriverFormProps {
  form: FormInstance<BudgetDriver>
}

const BudgetDriverForm: React.FC<BudgetDriverFormProps> = ({ form }) => {
  const { t } = useTranslation()
  const budgetDriverId = Form.useWatch('id', form)

  return (
    <Form form={form} layout="vertical" name="budgetDriver">
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['formula', 'id']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['formula', 'code']} hidden>
        <Input />
      </Form.Item>

      <Form.Item
        name="name"
        label={t('global:name')}
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
      >
        <Input disabled={!!budgetDriverId} placeholder={t('global:name')} />
      </Form.Item>

      <Form.Item noStyle>
        <Row gutter={24}>
          <Col>
            <Form.Item
              name={['formula', 'type']}
              label={t('global:type')}
              initialValue="absolute"
              rules={[
                {
                  required: true,
                  message: t('global:required-field')
                }
              ]}
            >
              <Select showSearch optionFilterProp="children" style={{ width: 300 }}>
                {budgetDriverKeyFigureTypes.map(keyFigureType => (
                  <Select.Option key={keyFigureType} value={keyFigureType}>
                    {t(`keyFigurePage:${keyFigureType}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={['formula', 'style', 'decimals']} label={t('global:decimals', 'Desimaalit')}>
              <Select>
                <Select.Option value={0}>0</Select.Option>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        initialValue={AggregationTypes.sum}
        name={['formula', 'aggregationType']}
        label={t('formulaPage:aggregationType')}
      >
        <Select showSearch optionFilterProp="children" style={{ width: 300 }}>
          {Object.keys(AggregationTypes).map(aggregationType => (
            <Select.Option key={aggregationType} value={aggregationType}>
              {t(`formulaPage:${aggregationType}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default BudgetDriverForm
