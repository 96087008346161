import { CompanyDashboard, CompanyDashboardItem } from '../../../../types/dashboard/company/types'

export const SET_COMPANY_DASHBOARD = 'SET_COMPANY_DASHBOARD'
export const UPDATE_COMPANY_DASHBOARD = 'UPDATE_COMPANY_DASHBOARD'
export const SET_COMPANY_DASHBOARD_ITEM = 'SET_COMPANY_DASHBOARD_ITEM'
export const UPDATE_COMPANY_DASHBOARD_ITEM = 'UPDATE_COMPANY_DASHBOARD_ITEM'
export const DELETE_COMPANY_DASHBOARD_ITEM = 'DELETE_COMPANY_DASHBOARD_ITEM'
export const SET_COMPANY_DASHBOARD_LAYOUTS = 'SET_COMPANY_DASHBOARD_LAYOUTS'
export const ADD_COMPANY_DASHBOARD = 'ADD_COMPANY_DASHBOARD'
export const DELETE_COMPANY_DASHBOARD = 'DELETE_COMPANY_DASHBOARD'

export interface SetCompanyDashboardAction {
  type: typeof SET_COMPANY_DASHBOARD
  payload: CompanyDashboard[]
}

export interface UpdateCompanyDashboardAction {
  type: typeof UPDATE_COMPANY_DASHBOARD
  payload: CompanyDashboard
}

export interface AddCompanyDashboardAction {
  type: typeof ADD_COMPANY_DASHBOARD
  payload: CompanyDashboard
}
interface DashboardItemAndId {
  companyDashboardItem: CompanyDashboardItem
  dashboardId: number
}

export interface SetCompanyDashboardItemAction {
  type: typeof SET_COMPANY_DASHBOARD_ITEM
  payload: DashboardItemAndId
}

export interface DeleteCompanyDashboardItemAction {
  type: typeof DELETE_COMPANY_DASHBOARD_ITEM
  payload: DashboardItemAndId
}

export interface DeleteCompanyDashboardAction {
  type: typeof DELETE_COMPANY_DASHBOARD
  payload: { dashboardId: number }
}

export interface UpdateCompanyDashboardItemAction {
  type: typeof UPDATE_COMPANY_DASHBOARD_ITEM
  payload: DashboardItemAndId
}

interface DashboardLayoutAndId {
  layouts: ReactGridLayout.Layouts
  dashboardId: number
}

export interface SetCompanyDashboardLayoutsAction {
  type: typeof SET_COMPANY_DASHBOARD_LAYOUTS
  payload: DashboardLayoutAndId
}

export type CompanyDashboardActionTypes =
  | SetCompanyDashboardAction
  | SetCompanyDashboardLayoutsAction
  | SetCompanyDashboardItemAction
  | UpdateCompanyDashboardItemAction
  | DeleteCompanyDashboardItemAction
  | AddCompanyDashboardAction
  | DeleteCompanyDashboardAction
  | UpdateCompanyDashboardAction
