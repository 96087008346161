import { SetPermissionsAction, SET_PERMISSIONS } from './types'
import { Permission } from '../../../types/user/Permission'

const initialState: Permission[] = []

const permissionsReducer = (state = initialState, action: SetPermissionsAction) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return action.payload
    default:
      return state
  }
}

export default permissionsReducer
