import { ReactText } from 'react'
import { VariableType } from '../../../components/Table/types'
import { ChartYAxis } from '../../../pages/reporting/custom/components/chart/types'
import { TaskApplication } from '../../../pages/superuser/types'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { ReportDataType } from '../reports/types'
import { GraphType } from '../../../pages/reporting/custom/components/chart/ChartTypeSelect'

interface ContextType {
  [key: string]: string
}

export enum OffsetUnit {
  year = 'year',
  month = 'month',
  week = 'week',
  day = 'day'
}

export interface CustomReportCategory {
  id?: string
  title?: string
  type: 'company' | 'dimension' | 'periodGroup' | 'function' | 'keyFigureId' | 'formulaId' | 'reference'
  value?: ReactText | ReactText[]
  startDate?: string
  endDate?: string
  children?: CustomReportCategory[]
  offset?: number
  offsetUnit?: OffsetUnit
  style?: CustomReportCategoryStyle
  budgetingScenario?: BudgetingScenario
  dataType?: ReportDataType.actuals | ReportDataType.budget | ReportDataType.forecast
  budgetingScenarioId?: number
  budgetingScenarioName?: string
  context?: ContextType
  color?: string
  variableType?: VariableType
  accountingSoftware?: TaskApplication
}

export enum DashStyle {
  Dash = 'Dash',
  DashDot = 'DashDot',
  Dot = 'Dot',
  LongDash = 'LongDash',
  LongDashDot = 'LongDashDot',
  LongDashDotDot = 'LongDashDotDot',
  ShortDash = 'ShortDash',
  ShortDashDot = 'ShortDashDot',
  ShortDashDotDot = 'ShortDashDotDot',
  ShortDot = 'ShortDot',
  Solid = 'Solid'
}

export interface CustomReportCategoryStyle {
  type?: GraphType
  dashStyle?: DashStyle
  zIndex?: number
  color?: string
  yAxis?: ChartYAxis
}

export type CustomReportCategoryRow = Omit<CustomReportCategory, 'children' | 'type'> & {
  id: number
  company: CustomReportCategory
  dimension?: CustomReportCategory
  function?: CustomReportCategory
  periodGroup?: CustomReportCategory
  leaf: CustomReportCategory
  type: undefined
  children?: CustomReportCategoryRow[]
  values: {
    [key: string]: number | undefined
  }
}
