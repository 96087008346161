import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../../types'
import { dimensionListSelector } from '../dimensions/selectors'
import { objectMap } from '../../../utils/helpers'
import { contextSelector } from '../selectors'

export const getIncomeStatementDimensionsDataSelectedKeys = createSelector(
  [contextSelector],
  context => context?.incomeStatementDimensionsData.keys
)

export const getIncomeStatementDimensionsData = (store: Store) => store.context?.incomeStatementDimensionsData.data

export const getIncomeStatementDimensionsRowData = createSelector(
  [getIncomeStatementDimensionsDataSelectedKeys, getIncomeStatementDimensionsData, dimensionListSelector],
  (keys, data, dimensions) => {
    const rows = objectMap(data, statementRow =>
      objectMap(statementRow, dim => {
        const dimension = dimensions.find(d => d.dimensionId === dim.dimensionId)
        return { ...dim, ...dimension }
      })
    )
    return rows
  }
)
