import { TreeSelect } from 'antd'
import { TreeNode } from 'antd/lib/tree-select'
import { Dayjs } from 'dayjs'
import React from 'react'
import { periodGroupLabel } from '../../redux/context/fiscalYears/utils'
import { ReportDataType } from '../../redux/context/reports/types'
import { FiscalYear, Period } from '../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'

interface PeriodGroupTreeSelectProps {
  showPeriods: boolean
  selectedDate: Dayjs | null
  selectedPeriodGroup: FiscalYear | PeriodGroup | undefined
  selectedPeriodGroupOrPeriod: (value: string | number) => void
  sortedColumnGroup: PeriodGroup[] | FiscalYear[]
}

const PeriodGroupTreeSelect: React.FC<PeriodGroupTreeSelectProps> = ({
  showPeriods,
  selectedDate,
  selectedPeriodGroup,
  selectedPeriodGroupOrPeriod,
  sortedColumnGroup
}: PeriodGroupTreeSelectProps) => {
  return (
    <TreeSelect
      showSearch
      style={{ width: '30%' }}
      value={showPeriods && selectedDate ? selectedDate.format('M / YYYY') : selectedPeriodGroup?.id}
      dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
      placeholder="Please select"
      onChange={selectedPeriodGroupOrPeriod}
    >
      {(Object(sortedColumnGroup).filter((f: PeriodGroup) => f.id > 0) as any[]).map((group: PeriodGroup) => {
        return (
          <TreeNode
            key={`${group.id}`}
            value={group.id}
            title={
              <span>
                <span>{periodGroupLabel(group, ReportDataType.actuals)}</span>
              </span>
            }
          >
            {group?.periods.map((period: Period) => (
              <TreeNode
                title={`${period.month} / ${period.year}`}
                value={`${period.month} / ${period.year}`}
                key={`${period.month} / ${period.year}`}
              />
            ))}
          </TreeNode>
        )
      })}
    </TreeSelect>
  )
}

export default PeriodGroupTreeSelect
