import { Form, FormInstance, Input, InputNumber } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Account } from '../../../../types/account/Account'
import TranslateFields from '../../../../components/Form/TranslateFields'
import { TaskApplication } from '../../../superuser/types'

interface AccountFormProps {
  initialValues?: Account
  onFormInstanceReady: (instance: FormInstance<Account>) => void
}

const AccountForm: React.FC<AccountFormProps> = ({ initialValues, onFormInstanceReady }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    onFormInstanceReady(form)
  }, [])

  return (
    <Form layout="vertical" form={form} name="accountForm" initialValues={initialValues}>
      <Form.Item
        name="code"
        label={t('vouchersPage:accountCode')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <InputNumber
          max={999999999}
          maxLength={9}
          min={1}
          precision={0}
          style={{ width: '100%' }}
          disabled={!!initialValues?.code}
        />
      </Form.Item>
      <Form.Item
        name="name"
        label={t('global:accountName')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <Input disabled={initialValues && initialValues?.source !== TaskApplication.FINADECK} />
      </Form.Item>
      <TranslateFields />
    </Form>
  )
}

export default AccountForm
