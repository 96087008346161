import React, { useEffect } from 'react'
import { Card, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { getCompanyImformationRequest } from '../../redux/context/companyInformation/actions'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/PageHeaderWrapper'
import Stakeholders from './components/Stakeholders'
import { AppDispatch } from '../../redux/store'

const CompanyInformation: React.FC = () => {
  const location = useLocation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)

  const { t } = useTranslation()

  const fetchCompanyImformation = () => {
    companyId && dispatch(getCompanyImformationRequest({ companyId }))
  }

  useEffect(fetchCompanyImformation, [companyId])

  const tabItems = [
    { label: t('companyInformation:stakeholders'), key: 'stakeholders', children: <Stakeholders /> } // remember to pass the key prop
  ]

  return (
    <PageHeaderWrapper title={t(`menu:${location.pathname}`)} ghost={false}>
      <Card>
        <Tabs items={tabItems} tabBarStyle={{ marginBottom: 48 }} defaultActiveKey="stakeholders" />
      </Card>
    </PageHeaderWrapper>
  )
}

export default CompanyInformation
