/* eslint-disable react/jsx-closing-tag-location */
import { Checkbox, Modal, Radio, Form, Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { handleDeprecationOk } from './service'
import { ReportTableRow } from '../../../../../components/Table/types'
import { DeprecationMethodId } from './types'

interface DeprecationFormProps {
  balanceSheetRowId: number
  reportTableRow: ReportTableRow
}

const DeprecationForm = ({ reportTableRow, balanceSheetRowId }: DeprecationFormProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const createDeprecationPlanRequest = useBackend('/api/companies/{companyId}/budgeting/deprecation-plans')
  const updateDeprecationPlanRequest = useBackend(
    '/api/companies/{companyId}/budgeting/deprecation-plans/{deprecationPlanId}'
  )
  const companyId = useSelector(contextCompanyIdSelector)
  const [modalVisibleDeprecation, setModalVisibilityDeprecation] = useState(false)
  const deprecationMethodId = Form.useWatch(['deprecationMethod', 'id'], form)

  useEffect(() => {
    form.setFieldsValue({
      ...reportTableRow.deprecationPlan,
      deprecationMethod: {
        id: reportTableRow?.deprecationPlan?.deprecationMethodId || 1
      },
      balanceSheetRowId
    })
  }, [reportTableRow.deprecationPlan])

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  const cancelForm = (resetForm: boolean) => {
    setModalVisibilityDeprecation(false)
    resetForm && form.resetFields()
  }

  const handleDeprecationMethodChange = (id: number) => {
    const value = id === DeprecationMethodId.ReducingBalanceMethod ? 0.01 : 1
    form.setFieldsValue({ value })
  }

  return (
    <>
      <Button type="default" size="small" onClick={() => setModalVisibilityDeprecation(true)}>
        {
          // eslint-disable-next-line no-nested-ternary
          !reportTableRow.deprecationPlan
            ? t('financialStatementsPage:deprecation')
            : reportTableRow.deprecationPlan.deprecationMethodId === 1
            ? // eslint-disable-next-line prettier/prettier
              `${reportTableRow.deprecationPlan.value.toFixed(0)}v.`
            : // eslint-disable-next-line react/jsx-curly-newline
              `${(reportTableRow.deprecationPlan.value * 100).toFixed(0)}%`
        }
      </Button>
      <Modal
        width={350}
        open={modalVisibleDeprecation}
        title={t('financialStatementsPage:deprecation-add')}
        okText={t('global:save')}
        cancelText={t('global:cancel')}
        maskClosable={false}
        onCancel={() => cancelForm(false)}
        onOk={() => {
          if (companyId) {
            handleDeprecationOk(form, createDeprecationPlanRequest, updateDeprecationPlanRequest, companyId, () =>
              cancelForm(true)
            )
          }
        }}
        confirmLoading={createDeprecationPlanRequest.loading || updateDeprecationPlanRequest.loading}
        destroyOnClose
        forceRender={false}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="balanceSheetRowId" style={{ display: 'none' }}>
            <FormattedInputNumber />
          </Form.Item>

          <Form.Item
            shouldUpdate
            name={['deprecationMethod', 'id']}
            label={t('financialStatementsPage:deprecation-method')}
          >
            <Radio.Group onChange={e => handleDeprecationMethodChange(e.target.value)}>
              <Radio style={radioStyle} value={DeprecationMethodId.StraightLineDeprecation}>
                {t('financialStatementsPage:straight-line-depreciation')}
              </Radio>
              <Radio style={radioStyle} value={DeprecationMethodId.ReducingBalanceMethod}>
                {t('financialStatementsPage:reducing-balance-method')}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {
              () =>
                deprecationMethodId === DeprecationMethodId.StraightLineDeprecation ? (
                  <Form.Item
                    name="value"
                    rules={[
                      {
                        required: true,
                        message: t('global:required-field')
                      }
                    ]}
                    label={t('financialStatementsPage:years')}
                  >
                    <FormattedInputNumber min={1} max={40} onClick={event => event.currentTarget.select()} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="value"
                    rules={[
                      {
                        required: true,
                        message: t('global:required-field')
                      }
                    ]}
                    label={t('financialStatementsPage:percent')}
                  >
                    <FormattedInputNumber
                      percentage
                      min={1}
                      max={25}
                      step={1}
                      addonAfter="%"
                      onClick={event => event.currentTarget.select()}
                    />
                  </Form.Item>
                )
              // eslint-disable-next-line react/jsx-curly-newline
            }
          </Form.Item>
          <Form.Item name="delete" valuePropName="checked">
            <Checkbox>{t('financialStatementsPage:no-deprecation')}</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default DeprecationForm
