import { combineReducers } from 'redux'
import accountMapRulesReducer from './accountMapRules/reducer'
import budgetinScenariosReducer from './budgetingScenarios/reducer'
import companyAccountsReducer from './accounts/reducer'
import companyDashboardReducer from './dashboard/company/reducer'
import companyInformationReducer from './companyInformation/reducer'
import companySettingsReducer from './companySettings/reducer'
import comparisonReducer from './industryComparison/reducer'
import competitorComparisonReducer from './competitorComparison/reducer'
import contextCompanyReducer from './company/reducer'
import contextContractReducer from './contract/reducer'
import customersReducer from './customers/reducer'
import customReportsReducer from './customReports/reducer'
import dimensionsReducer from './dimensions/reducers'
import filtersReducer from './filters/reducer'
import fiscalYearsReducer from './fiscalYears/reducer'
import formulasReducer from './formulas/reducer'
import investmentsReducer from './investments/reducer'
import keyPerformanceIndicatorsReducer from './performanceIndicators/reducer'
import loansReducer from './loans/reducer'
import personnelReducer from './personnel/reducer'
import productsGroupsReducer from './productsGroups/reducer'
import productsReducer from './products/reducer'
import reallocationReducer from './reallocations/reducer'
import reportsReducer from './reports/reducer'
import subscribedKeyFiguresReducer from './subscribedKeyFigures/reducer'
import subsidiariesReducer from './subsidiaries/reducer'
import valuationAdjustmentsReducer from './valuation/reducer'
import groupSettingsReducer from './groupSettings/reducer'
import forecastsReducer from './forecasts/reducer'
import companyUsersReducer from './companyUsers/reducer'
import budgetDriversReducer from './budgetDrivers/reducer'
import incomeStatementDimensionsDataReducer from './incomeStatenentDimensions/reducer'
import customerGroupsReducer from './customerGroups/reducer'
import groupCompanyReducer from './groupCompany/reducer'
import exchangeRatesReducer from './exchangeRates/reducer'

const contextReducer = combineReducers({
  accountMapRules: accountMapRulesReducer,
  accounts: companyAccountsReducer,
  budgetDrivers: budgetDriversReducer,
  budgetingScenarios: budgetinScenariosReducer,
  company: contextCompanyReducer,
  companyDashboards: companyDashboardReducer,
  companyInformation: companyInformationReducer,
  companySettings: companySettingsReducer,
  companyUsers: companyUsersReducer,
  competitorComparison: competitorComparisonReducer,
  contract: contextContractReducer,
  customerGroups: customerGroupsReducer,
  customers: customersReducer,
  customReports: customReportsReducer,
  dimensions: dimensionsReducer,
  exchangeRates: exchangeRatesReducer,
  filters: filtersReducer,
  fiscalYears: fiscalYearsReducer,
  forecasts: forecastsReducer,
  formulas: formulasReducer,
  groupCompany: groupCompanyReducer,
  groupSettings: groupSettingsReducer,
  incomeStatementDimensionsData: incomeStatementDimensionsDataReducer,
  industryComparison: comparisonReducer,
  investments: investmentsReducer,
  keyPerformanceIndicators: keyPerformanceIndicatorsReducer,
  loans: loansReducer,
  personnel: personnelReducer,
  productGroups: productsGroupsReducer,
  products: productsReducer,
  reallocations: reallocationReducer,
  reports: reportsReducer,
  subscribedKeyFigures: subscribedKeyFiguresReducer,
  subsidiaries: subsidiariesReducer,
  valuationAdjustments: valuationAdjustmentsReducer
})

export default contextReducer
