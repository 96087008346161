import { Button, Form, Modal, Alert } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requireContractProductExact } from '../../../../components/Misc/RestrictedProduct'
import { ReportTableRow } from '../../../../components/Table/types'
import { companyAccountsSelector } from '../../../../redux/context/accounts/selectors'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { contextContractProductSelector } from '../../../../redux/context/contract/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { BudgetingScenario } from '../../../../types/budgetingScenario/BudgetingScenario'
import { Company } from '../../../../types/company/Company'
import { ContractProduct } from '../../../../types/contract/Contract'
import { Investment } from '../../../../types/investment/Investment'
import { InvestmentFunding } from '../../../../types/investment/InvestmentFunding'
import { InvesmentFormContextType, InvestmentFormContext } from '../InvestmentFormContextProvider'
import InvestmentForm from './form/InvestmentForm'
import { getInitialValues, investmentSaveFormat } from './form/utils'
import { AppDispatch } from '../../../../redux/store'

interface InvestmentModalProps {
  visible: boolean
  onFinish: () => void
  updateInvestmentsRequest: (companyId: Company['id'], data: Investment, isExisting?: boolean) => void
  createInvestmentsRequest: (
    companyId: Company['id'],
    data: Investment,
    isExisting?: boolean,
    budgetingScenario?: BudgetingScenario['id']
  ) => void
  deleteInvestmentsRequest: (companyId: Company['id'], investment: Investment, isExisting?: boolean) => void
  investment?: Investment
  formType?: 'investment' | 'divestment'
  reportTableRow?: ReportTableRow
  page?: 'financialStatement'
}

const InvestmentModal: React.FC<InvestmentModalProps> = ({
  visible,
  investment,
  formType,
  reportTableRow,
  page,
  onFinish,
  updateInvestmentsRequest,
  createInvestmentsRequest,
  deleteInvestmentsRequest
}) => {
  const { t } = useTranslation()
  const [form] = useForm<Investment>()
  const { isExisting } = useContext(InvestmentFormContext) as InvesmentFormContextType
  const companyId = useSelector(contextCompanyIdSelector)!
  const accounts = useSelector(companyAccountsSelector)
  const contextContractProduct = useSelector(contextContractProductSelector)
  const [fundingError, setFundingError] = useState<null | string>(null)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    form.setFieldsValue(getInitialValues(investment, reportTableRow))
  }, [investment, reportTableRow])

  const onValuesChange = (changedField: any, allFields: Investment) => {
    if (contextContractProduct && requireContractProductExact(contextContractProduct, ContractProduct.PRO)) {
      const { accountCode: changedAccountCode, date: changedDate } = changedField

      const { deprecationPlan: { bsAccountCode, startDate } = {} } = allFields

      const { statementRowId } = accounts?.find(a => a.code === changedAccountCode) || {}
      const { statementRowId: bsStatementRowId } = accounts?.find(a => a.code === bsAccountCode) || {}

      if (changedAccountCode && statementRowId !== bsStatementRowId) {
        form.setFieldsValue({ deprecationPlan: { bsAccountCode: changedAccountCode } })
      }

      if (changedDate && !startDate) {
        form.setFieldsValue({ deprecationPlan: { startDate: changedDate } })
      }
    }
  }

  const handleInvesmentDelete = () => {
    investment && dispatch(deleteInvestmentsRequest(companyId, investment, isExisting))
    onFinish()
    form.resetFields()
  }

  const handleCancel = () => {
    onFinish()
    form.resetFields()
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      const balanceSheetRowId = values.balanceSheetRowId
        ? values.balanceSheetRowId
        : accounts?.find(a => a.code === values.accountCode)?.statementRowId
      if (!balanceSheetRowId) throw new Error('Failed')

      const data = { ...investmentSaveFormat(values), balanceSheetRowId, vat: 0, isExisting }

      if (!isExisting) {
        const { value: investmentValue } = data
        const fundingValuesSum = data?.fundings?.reduce((sum: number, f: InvestmentFunding) => sum + f.value, 0)
        if (!data?.fundings?.length) {
          setFundingError('Investoinnilla pitää olla rahoitus')
          throw new Error()
        }
        if (investmentValue !== fundingValuesSum) {
          setFundingError('Investoinnin ja rahoituksen summat eivät täsmää')
          throw new Error()
        }
      }
      setFundingError(null)
      if (data.id) {
        dispatch(updateInvestmentsRequest(companyId, data, isExisting))
      } else {
        dispatch(createInvestmentsRequest(companyId, data, isExisting, budgetingScenario && budgetingScenario.id))
      }

      form.resetFields()
      onFinish()
    } catch (error) {
      console.log('Validate Failed:', error)
    }
  }

  const generateModalFooter = () => {
    return (
      <>
        {investment && !isExisting && investment.id && (
          <Button danger style={{ float: 'left' }} onClick={handleInvesmentDelete}>
            {t('global:delete')}
          </Button>
        )}
        <Button type="default" onClick={handleCancel}>
          {t('global:cancel')}
        </Button>
        <Button type="primary" onClick={handleOk}>
          {t('global:save')}
        </Button>
      </>
    )
  }

  return (
    <Modal
      width="max-content"
      title={
        formType === 'investment' || (investment && investment.value > 0)
          ? t('investmentsPage:investmentModalTitle', { context: investment ? 'update' : 'create' })
          : t('financialStatementsPage:divestment')
      }
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={generateModalFooter()}
      destroyOnClose
      forceRender={false}
    >
      <Form form={form} name="investment" layout="vertical" autoComplete="off" onValuesChange={onValuesChange}>
        <InvestmentForm
          isExisting={isExisting}
          formType={formType === 'investment' || (investment && investment.value > 0) ? 'investment' : 'divestment'}
          page={page}
        />
      </Form>
      {fundingError && <Alert message={fundingError} type="error" />}
    </Modal>
  )
}

export default InvestmentModal
