import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../components/Table/types'
import { revenueRowIdSelector } from '../../../../redux/config/financialStatement/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { Column } from '../../../../redux/context/periodGroups/hooks'
import { calculateIncomeStatementPercents } from '../utils'
import { ReportDataType } from '../../../../redux/context/reports/types'
import { formatValueByType } from '../../../../utils/helpers'
import BudgetCommentPopover from './comment/BudgetCommentPopover'

export interface IncomeStatementCellRendererProps {
  value: number
  column: Column
  reportTableRow: ReportTableRow
}

const IncomeStatementCellRenderer: React.FC<IncomeStatementCellRendererProps> = (
  props: IncomeStatementCellRendererProps
) => {
  const { i18n } = useTranslation()
  const revenueRowId = useSelector(revenueRowIdSelector)
  const { value, column, reportTableRow } = props
  const { showIncomeStatemetPercentageChange } = useSelector(filtersSelector)
  const revenueValue = reportTableRow.revenueRow?.[column.dataIndex]
  const percentageValue = calculateIncomeStatementPercents(value, revenueValue || 0)

  if (percentageValue && showIncomeStatemetPercentageChange && reportTableRow.id !== revenueRowId) {
    return (
      <span>
        <span style={{ opacity: 0.7 }}>
          {`(${percentageValue.toLocaleString(i18n.language, {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          })})`}
        </span>
        <br />
        <span>{formatValueByType(value)}</span>
      </span>
    )
  }

  return (
    <>
      <span>
        {(column.dataType === ReportDataType.budget || column.dataType === ReportDataType.forecast) && (
          <BudgetCommentPopover row={reportTableRow} column={column} />
        )}
      </span>
      {formatValueByType(value)}
    </>
  )
}

export default IncomeStatementCellRenderer
