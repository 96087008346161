import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import {
  Button,
  Col,
  DatePicker,
  Form,
  GetProp,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  UploadFile,
  UploadProps
} from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch } from 'react-redux'
import { Transaction, Voucher } from '../types'
import TransactionTableForm from './TransactionTableForm'
import { transformFieldData } from '../../../../utils/form'
import { notificationAction } from '../../../../redux/middleware/actions'
import { Attachment } from '../../../../types/attachment/attachment'
import { useBackend } from '../../../../services/backend'
import { useResetFormOnCloseModal } from '../../../../components/Form/hooks'
import { calculateTotalTrasactionsValueCents } from './utils'

export type TransactionFormFields = Pick<Transaction, 'description' | 'debit' | 'credit' | 'accountCode'>
export type VoucherFormFields = Pick<Voucher, 'description' | 'id'> & {
  transactions: TransactionFormFields[]
  date: Dayjs
}
const { Title } = Typography
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const formStyle: React.CSSProperties = {
  maxWidth: 'none',
  padding: 24
}

const attachmentsToUploadfiles = (attachments?: Attachment[]) => {
  if (!attachments) return []
  const data = attachments?.map(
    attachment =>
      ({
        name: attachment.filename,
        uid: attachment.id
      } satisfies UploadFile)
  )

  return data
}

interface VoucherFormProps {
  open: boolean
  voucher: Voucher | null
  loading?: boolean
  handleClose: () => void
  onCreate: (values: FormData) => void
  onUpdate: (values: FormData) => void
}

const VoucherForm: React.FC<VoucherFormProps> = ({ open, voucher, loading, onCreate, onUpdate, handleClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [form] = useForm<VoucherFormFields>()
  const [uploadFileList, setFileList] = useState<UploadFile[]>([])
  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([])
  const attachmnetRequest = useBackend(
    `accounting/vouchers/${voucher?.id}/attachments/{attachmentId}`,
    process.env.REACT_APP_INTEGRATION_URL,
    'arraybuffer'
  )

  useResetFormOnCloseModal({
    form,
    open
  })

  useEffect(() => {
    if (voucher) {
      form.setFieldsValue(
        transformFieldData(voucher, {
          date: (val: string) => dayjs(val)
        })
      )
      setDefaultFileList(attachmentsToUploadfiles(voucher?.attachments))
    }
  }, [voucher])

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const deleteAttachment = (attachmentId: string) => {
    return attachmnetRequest.delete({
      urlParams: { attachmentId },
      body: {
        params: { companyId: voucher?.companyId }
      }
    })
  }

  const uploadProps: UploadProps = {
    onRemove: file => {
      const index = uploadFileList.indexOf(file)
      const newFileList = uploadFileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: file => {
      setFileList([...uploadFileList, file])

      return false
    },
    fileList: uploadFileList
  }

  const uploadedProps: UploadProps = {
    async onRemove(file) {
      await deleteAttachment(file.uid)
      setDefaultFileList(defaultFileList.filter(f => f.uid !== file.uid))
    }
  }

  return (
    <Modal
      title={t('global:voucher')}
      width={1100}
      open={open}
      onCancel={() => {
        handleClose()
        setFileList([])
      }}
      confirmLoading={loading}
      onOk={async () => {
        try {
          const values = await form.validateFields()
          const formData = new FormData()

          values.id && formData.append('id', values.id.toString())
          formData.append('description', values.description)
          formData.append('date', values.date.format('YYYY-MM-DD'))

          values.transactions.forEach((transaction, index) => {
            Object.entries(transaction).forEach(([key, value]) => {
              value && formData.append(`transactions[${index}][${key}]`, value?.toString())
            })
          })

          uploadFileList.forEach(file => {
            formData.append('files[]', file as FileType)
          })

          values.id ? await onUpdate(formData) : await onCreate(formData)

          setFileList([])
        } catch (error) {
          dispatch(
            notificationAction({
              type: 'error',
              message: 'ERROR',
              description: (error as Error).message
            })
          )
        }
      }}
      okText={t('global:save')}
      maskClosable={false}
    >
      <Form
        form={form}
        style={formStyle}
        layout="vertical"
        onValuesChange={(val: Partial<VoucherFormFields>) => {
          const transaction = val?.transactions?.filter(Boolean)?.[0]
          if (transaction?.credit || transaction?.debit) {
            form.validateFields()
          }
        }}
      >
        <Title level={5}>{t('vouchersPage:voucherInfo')}</Title>
        <Row gutter={24}>
          <FormItem name="id" hidden>
            <Input />
          </FormItem>
          <Col span={8}>
            <FormItem name="description" label={t('vouchersPage:voucherDesc')} rules={[{ required: true }]}>
              <Input />
            </FormItem>
          </Col>
          <Col span={8}>
            {/* TODO: translaatio globaliin  */}
            <FormItem name="date" label={t('contractsPage:date')} rules={[{ required: true }]}>
              <DatePicker style={{ width: '100%' }} format="DD.MM.YYYY" />
            </FormItem>
          </Col>
          <Col span={8}>
            <Form.Item label={t('global:attachment')} valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>{t('vouchersPage:selectAttachment')}</Button>
              </Upload>
            </Form.Item>
            <Upload {...uploadedProps} fileList={defaultFileList} />
          </Col>
        </Row>

        <Title level={5}>{t('vouchersPage:voucherTransactions')}</Title>
        <Form.List
          name="transactions"
          rules={[
            {
              validator: async (__, pageData: Partial<Transaction>[]) => {
                const { difference } = calculateTotalTrasactionsValueCents(pageData)

                if (difference === 0) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error(t('vouchersPage:voucherError')))
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => <TransactionTableForm add={add} remove={remove} errors={errors} />}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default VoucherForm
