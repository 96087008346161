import { BgColorsOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { ColorChangeHandler, SketchPicker } from 'react-color'
import { parseRgbString } from '../../../../../utils/helpers'

interface ColorPickerProps {
  value?: string
  position?: 'top' | 'bottom'
  onChange?: (color?: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = ({ value, position, onChange }) => {
  const [visible, setVisible] = useState(false)

  const handleChange: ColorChangeHandler = selectedColor => {
    const { r, g, b, a } = selectedColor.rgb
    const rgbString = `rgb(${[r, g, b, a].join(',')})`
    onChange?.(rgbString)
  }

  const handleReset = () => {
    onChange?.(undefined)
  }

  const popover = {
    position: 'absolute',
    zIndex: '9000',
    top: position === 'bottom' ? 30 : -340
  } satisfies React.CSSProperties

  const cover = {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.15)',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: '-1'
  } satisfies React.CSSProperties

  const toggle = () => {
    setVisible(!visible)
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = () => {
    toggle()
  }

  return (
    <>
      <Button style={{ backgroundColor: value }} icon={<BgColorsOutlined />} aria-hidden="true" onClick={toggle} />
      {visible ? (
        <div style={popover}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div role="button" style={cover} onClick={toggle} onKeyDown={handleKeyDown} tabIndex={0} />
          <SketchPicker color={parseRgbString(value)} onChange={handleChange} />
          <Button block onClick={handleReset}>
            Clear
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default ColorPicker
