import { Dayjs } from 'dayjs'
import { ReportTableRow } from '../../../components/Table/types'
import { Column } from '../../../redux/context/periodGroups/hooks'
import { ReportRowType } from '../../../redux/context/reports/types'

export const calculateIncomeStatementPercents = (value: number, revenueValue: number) => {
  if (value && revenueValue) {
    return Math.abs(value / revenueValue)
  }

  return null
}

export const isBudgetableForecastCell = (column: Column, forecastCursor: '' | Dayjs | undefined) => {
  // forecasteille budjettimahdollisuus vuositasolle sekä kk tasolla forecastcursorista eteenpäin
  if (!column.year && !column.month) {
    return true
  }
  if (
    column.year &&
    column.month &&
    forecastCursor &&
    (column?.year > forecastCursor.year() ||
      (column.year === forecastCursor.year() && column.month > forecastCursor.month() + 1))
  ) {
    return true
  }
  return false
}

export const getRowKey = (row: ReportTableRow) => {
  if (row.rowType === ReportRowType.dimension) {
    return `${row.key || row.id || row.code}-${row.title || row.name}-dimension`
  }
  return `${row.key || row.id || row.code}-${row.title || row.name}`
}
