import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Button, Space } from 'antd'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import { authTokenSelector } from '../../../../../redux/session/authentication/selectors'
import { autoDownload } from '../../../../../utils/helpers'

const ExcelImport: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const token = useSelector(authTokenSelector)

  const getExcelTemplate = () => {
    axios({
      url: `${process.env.REACT_APP_INTEGRATION_URL}/excel/templates/import`,
      method: 'GET',
      headers: {
        'X-Token': token ?? ''
      },
      responseType: 'blob'
    }).then(response => {
      autoDownload('import-template.xlsx', new Blob([response.data]))
    })
  }

  const getExcelStatementTemplate = () => {
    axios({
      url: `${process.env.REACT_APP_INTEGRATION_URL}/excel/templates/statement`,
      method: 'GET',
      headers: {
        'X-Token': token ?? ''
      },
      responseType: 'blob'
    }).then(response => {
      autoDownload('statement-template.xlsx', new Blob([response.data]))
    })
  }

  return (
    <>
      <Space>
        <Button style={{ marginBottom: 10 }} onClick={() => getExcelTemplate()}>
          {t('integrationsPage:downloadExcelTemplate')}
        </Button>
        <Button style={{ marginBottom: 10 }} onClick={() => getExcelStatementTemplate()}>
          {t('integrationsPage:downloadStatementExcelTemplate')}
        </Button>
      </Space>
      <FileUpload
        actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/excel/uploads/${companyId}`}
        taskRequestUrl="/excel/tasks/{companyId}"
        uploadText={t('integrationsPage:excel-info')}
        fileTypes=".xlsx,.xls"
      />
    </>
  )
}

export default ExcelImport
