import { EditOutlined } from '@ant-design/icons'
import { Button, Modal, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { updatePersonnelRequest } from '../../../../../redux/context/personnel/actions'
import { KeyFigureFact } from '../../../../../types/key-figures/KeyFigureFact'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { AppDispatch } from '../../../../../redux/store'

interface PersonnelFormProps {
  personnelData: KeyFigureFact
}

const PersonnelEditForm = ({ personnelData }: PersonnelFormProps) => {
  const [form] = Form.useForm()
  const setFormValues = () => {
    if (personnelData) {
      form.setFieldsValue({
        value: Number(personnelData.value).toFixed(0)
      })
    }
  }

  useEffect(setFormValues, [personnelData])

  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const handleCreate = () => {
    if (form !== null) {
      form.validateFields().then((values: any) => {
        setVisible(false)
        companyId &&
          dispatch(updatePersonnelRequest(companyId, { ...values, fiscalYearId: personnelData.fiscalYearId }))
      })
    }
    return null
  }

  return (
    <div>
      <EditOutlined
        onClick={() => {
          setVisible(true)
        }}
      />
      <Modal
        title={t('personnelPage:edit-personnel') + personnelData.year}
        open={visible}
        maskClosable={false}
        onCancel={() => {
          setVisible(false)
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleCreate}>
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            label={t('personnelPage:personnel')}
          >
            <FormattedInputNumber />
          </Form.Item>
          <div className="ant-modal-footer" style={{ padding: '5px 0px 0px 0px' }}>
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button
                key="back"
                onClick={() => {
                  setVisible(false)
                }}
              >
                {t('global:cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {t('global:update')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default PersonnelEditForm
