import React from 'react'
import VehicleBasedIntegrations from '../vehicleBasedIntegrations/VehicleBasedIntegrations'

const Mapon: React.FC = () => {
  return (
    <VehicleBasedIntegrations
      allowAuthentication
      credentialsRequestUrl="/mapon/authentication/{companyId}"
      getVehiclesUrl="/mapon/vehicles/{companyId}"
      updateVehicleUrl="/mapon/vehicles/{companyId}/{id}"
      pageDocsUrl="manual/businesses/settings/company#mapon"
      name="mapon"
    />
  )
}

export default Mapon
