// eslint-disable-next-line import/no-cycle
import { TaskApplication } from '../../pages/superuser/types'
import { Contract } from '../contract/Contract'
import { Currency } from '../currency/Currency'

export enum Country {
  fi = 'fi',
  se = 'se'
}

export enum CompanyForm {
  limited = 'limited',
  association = 'association',
  realEstate = 'realEstate'
}

export interface CompanyAcquisition {
  id: number
  /** Emoyhtiön tunnus */
  groupId: string

  /** Tytäryhtiön tunnus */
  subsidiaryId: string

  /** Omistusosuus */
  percentage: number

  /** Omistusosuuden hankitapäivämäärä */
  date: Date
}

export interface Company {
  id: string
  name: string
  industryId: number
  settings?: any
  accountingSoftware: TaskApplication
  contract: Contract
  country: Country
  form: CompanyForm
  currency: Currency
  groupId?: string
  group?: Company
  aquisitions?: CompanyAcquisition[]
  subsidiaries?: Company[]
  elimination?: boolean
}
