import React from 'react'
import { Cascader, Form, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isNumber } from 'lodash'
import { currentUserSelector } from '../../../../redux/session/currentUser/selectors'
import { useAuthorizedRoutes } from '../../../../routes/hooks'
import { Route } from '../../../../routes/Route'
import { UserSetting } from '../../../../types/user/User'
import { currentUserSettingsRequest } from '../../../../redux/session/currentUser/actions'
import { AppDispatch } from '../../../../redux/store'

interface UserSettingFormFields {
  homepage?: string[]
  'pdf-font-size'?: number
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

const UserGeneralSettings = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const [form] = useForm<UserSettingFormFields>()
  const { settings: userSettings } = useSelector(currentUserSelector)
  const authorizedRoutes = useAuthorizedRoutes()

  const map = ({ routes: r, name, path }: Route): Route => ({
    path: name,
    name: t(`menu:${path}`),
    routes: r?.filter((rr: Route) => rr.name && !rr.hideInMenu).map(map) // recursive call
  })

  const transformSettingsToForm = (values: UserSetting[]): UserSettingFormFields => {
    return values.reduce((settings, { settingId, value }) => {
      let val
      switch (settingId) {
        case 'homepage':
          val = !isNumber(value) && value?.split('/')
          break
        case 'pdf-font-size':
          val = value
          break
        default:
          val = value
          break
      }
      return { ...settings, [settingId]: val }
    }, {})
  }

  const transformFormToSettings = (val: Partial<UserSettingFormFields>): UserSetting | null => {
    const entries = Object.entries(val) as Entries<Partial<UserSettingFormFields>>

    if (!entries || !entries[0]) return null

    let setting
    const entry = entries[0]

    switch (entry[0]) {
      case 'homepage':
        setting = {
          settingId: entry[0],
          value: entry[1]?.join('/')
        }
        break
      case 'pdf-font-size':
        setting = {
          settingId: entry[0],
          value: Number(entry[1])
        }
        break

      default:
        break
    }

    if (setting?.settingId) return setting
    return null
  }

  const onFinish = (values: any) => {
    const settings = transformFormToSettings(values)
    settings && dispatch(currentUserSettingsRequest(settings))
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 }
  }

  return (
    <div>
      <Form
        {...formItemLayout}
        initialValues={transformSettingsToForm(userSettings)}
        form={form}
        layout="vertical"
        colon={false}
        onValuesChange={onFinish}
      >
        <Form.Item label={t('usersPage:homepage')} name="homepage">
          <Cascader
            style={{ width: 400 }}
            fieldNames={{ label: 'name', value: 'path', children: 'routes' }}
            options={authorizedRoutes?.[0].routes?.filter((r: Route) => r.name && !r.hideInMenu).map(map)}
          />
        </Form.Item>
        <Form.Item label={t('usersPage:pdf-font-size')} name="pdf-font-size">
          <Select style={{ width: 100 }}>
            {[
              8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
              35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50
            ].map(fontSize => (
              <Select.Option key={fontSize} value={fontSize}>
                {fontSize}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UserGeneralSettings
