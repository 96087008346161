import { PointOptionsObject } from 'highcharts'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { ReportTableRow, VariableType } from '../../../../components/Table/types'
import { Report } from '../../../../redux/context/reports/types'
import { CompanyDashboardItem, CompanyDashboardItemType } from '../../../../types/dashboard/company/types'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'
import { formatValueByType, formatValueToNumberByType } from '../../../../utils/helpers'

export const name = (item: CompanyDashboardItem): string => {
  if (!item.name && item.type === CompanyDashboardItemType.KEY_FIGURES && item.params.representation.type === 'info')
    return i18next.t(`keyFigureStatement:${item.params.keyFigureIds}`)
  if (item.name) return item.name
  if (item.type === CompanyDashboardItemType.INCOME_STATEMENT)
    return i18next.t('financialStatementsPage:incomeStatement')
  if (item.type === CompanyDashboardItemType.BALANCE_SHEET) return i18next.t('financialStatementsPage:balanceSheet')
  if (item.type === CompanyDashboardItemType.CASH_FLOW_STATEMENT) {
    if (item.params.representation.type === 'table' || item.params.selectedGraph === 'DEFAULT') {
      return i18next.t(`cashFlowPage:${item.params.type}` || '')
    }
    return i18next.t(`cashFlowPage:${item.params.selectedGraph}` || '')
  }
  if (item.type === CompanyDashboardItemType.INTERNAL_KEY_FIGURES) {
    if (item.params.selectedType === 'table') {
      return i18next.t(`keyFigurePage:INTERNAL_KEY_FIGURES`)
    }
    return i18next.t(`keyFigurePage:internal-keyfigures-${item.params.selectedType}`)
  }
  if (item.type === CompanyDashboardItemType.KEY_FIGURES) return i18next.t('keyFigurePage:key-figure')
  if (item.type === CompanyDashboardItemType.SALES) {
    return i18next.t('global:sales')
  }
  return ''
}

export const renderCell = (text: any, reportTableRow: ReportTableRow): string | undefined => {
  if (text) return formatValueByType(text, reportTableRow.type)
  return undefined
}

// alla olevat refaktottava kun monessa samoja hommia
export const calculateInfoValue = (
  periodGroup: FiscalYear | PeriodGroup | undefined,
  report: Report,
  columns: any,
  currentFiscalYear?: FiscalYear
) => {
  if (!periodGroup) {
    if (columns.length > 0) {
      const valueThisYear =
        (report[0] &&
          report[0].actuals?.find(d => d.groupId === columns[columns.length - 1]?.periodGroup?.id)?.value) ||
        0
      if (valueThisYear) {
        return formatValueToNumberByType(+valueThisYear, report[0].type || report[0].variableType) || 0
      }
      return 0
    }
    return 0
  }

  if (periodGroup.startDate === currentFiscalYear?.startDate && periodGroup.endDate === currentFiscalYear?.endDate) {
    const year = dayjs().subtract(1, 'month').year()
    const month = dayjs().subtract(1, 'month').month() + 1
    const value = report[0] && report[0].actuals?.find(d => d.year === year && d.month === month)
    if (value) {
      return formatValueToNumberByType(value.value, report[0].type || report[0].variableType) || 0
    }
    return 0
  }

  const year = dayjs(periodGroup.endDate).year()
  const month = dayjs(periodGroup.endDate).month() + 1
  const value = report[0] && report[0].actuals?.find(d => d.year === year && d.month === month)
  if (value) {
    return formatValueToNumberByType(value.value, report[0].type || report[0].variableType) || 0
  }
  return 0
}

const compareInfoValuesText = (valueThisYear: number, valueLastYear: number, type: VariableType) => {
  return `${i18next.t('global:delta')}: ${formatValueByType(valueThisYear - valueLastYear, type)} ${
    type === 'absolute' ? i18next.t('global:currency-symbol') : ''
  }, 
  ${i18next.t('global:referenceValue')}: ${formatValueByType(valueLastYear, type)} ${
    type === 'absolute' ? i18next.t('global:currency-symbol') : ''
  }`
}

export const calculateInfoPercentageValue = (
  periodGroup: FiscalYear | PeriodGroup | undefined,
  params: any,
  report: Report,
  series: any,
  columns: any,
  currentFiscalYear?: FiscalYear,
  compareInfoValues?: boolean
) => {
  if (periodGroup && params.representation.options?.monthlyComparableType === 'previousMonth') {
    let year = dayjs(periodGroup.endDate).year()
    let compareyear = dayjs(periodGroup.endDate).subtract(1, 'month').year()
    let comparemonth = dayjs(periodGroup.endDate).subtract(1, 'month').month() + 1
    let month = dayjs(periodGroup.endDate).month() + 1
    if (periodGroup.startDate === currentFiscalYear?.startDate && periodGroup.endDate === currentFiscalYear?.endDate) {
      year = dayjs().year()
      compareyear = dayjs().subtract(1, 'month').year()
      comparemonth = dayjs().subtract(1, 'month').month()
      month = dayjs().month()
    }
    let previousMonthValue: any = 0
    if (params.representation.options?.comparableDataType === 'actual') {
      previousMonthValue =
        report[0] && report[0].actuals?.find(d => d.year === compareyear && d.month === comparemonth)?.value
    }
    if (params.representation.options?.comparableDataType === 'budget') {
      previousMonthValue =
        report[0] && report[0].budget?.find(d => d.year === compareyear && d.month === comparemonth)?.value
    }
    const thisYearMonthValue = report[0] && report[0].actuals?.find(d => d.year === year && d.month === month)?.value

    const value = thisYearMonthValue && ((thisYearMonthValue - previousMonthValue) / previousMonthValue) * 100
    if (compareInfoValues && previousMonthValue) {
      return thisYearMonthValue && compareInfoValuesText(thisYearMonthValue, previousMonthValue, report[0].type)
    }
    if (thisYearMonthValue && previousMonthValue < 0 && thisYearMonthValue > 0) {
      return Math.abs(value || 0)
    }
    return value || 0
  }
  if (periodGroup && params.representation.options?.monthlyComparableType === 'sameMonthLastYear') {
    let year = dayjs(periodGroup.endDate).year()
    let month = dayjs(periodGroup.endDate).month() + 1
    if (periodGroup.startDate === currentFiscalYear?.startDate && periodGroup.endDate === currentFiscalYear?.endDate) {
      year = dayjs().year()
      month = dayjs().subtract(1, 'month').month() + 1
    }
    const thisYearMonthValue = report[0] && report[0].actuals?.find(d => d.year === year && d.month === month)?.value
    let lastYearMonthValue: any = 0
    if (params.representation.options?.comparableDataType === 'actual') {
      lastYearMonthValue = report[0] && report[0].actuals?.find(d => d.year === year - 1 && d.month === month)?.value
    }
    if (params.representation.options?.comparableDataType === 'budget') {
      lastYearMonthValue = report[0] && report[0].budget?.find(d => d.year === year - 1 && d.month === month)?.value
    }

    if (lastYearMonthValue && thisYearMonthValue) {
      if (compareInfoValues && lastYearMonthValue) {
        return compareInfoValuesText(thisYearMonthValue, lastYearMonthValue, report[0].type)
      }
      if (lastYearMonthValue < 0 && thisYearMonthValue > 0) {
        return Math.abs(((thisYearMonthValue - lastYearMonthValue) / lastYearMonthValue) * 100)
      }
      return ((thisYearMonthValue - lastYearMonthValue) / lastYearMonthValue) * 100
    }

    return 0
  }
  if (periodGroup && params.representation.options?.monthlyComparableType === 'thisMonth') {
    let year = dayjs(periodGroup.endDate).year()
    let month = dayjs(periodGroup.endDate).month() + 1
    if (periodGroup.startDate === currentFiscalYear?.startDate && periodGroup.endDate === currentFiscalYear?.endDate) {
      year = dayjs().year()
      month = dayjs().subtract(1, 'month').month() + 1
    }
    const thisYearMonthValue = report[0] && report[0].actuals?.find(d => d.year === year && d.month === month)?.value
    const thisYearMonthValueBudget =
      report[0] && report[0].budget?.find(d => d.year === year && d.month === month)?.value

    if (thisYearMonthValueBudget && thisYearMonthValue) {
      if (compareInfoValues && thisYearMonthValueBudget) {
        return compareInfoValuesText(thisYearMonthValue, thisYearMonthValueBudget, report[0].type)
      }
      if (thisYearMonthValueBudget < 0 && thisYearMonthValue > 0) {
        return Math.abs(((thisYearMonthValue - thisYearMonthValueBudget) / thisYearMonthValueBudget) * 100)
      }
      return ((thisYearMonthValue - thisYearMonthValueBudget) / thisYearMonthValueBudget) * 100
    }

    return 0
  }
  if (!periodGroup && series[0]) {
    if (series[0].data[columns.length - 2]) {
      const valueThisYear =
        (report[0] &&
          report[0].actuals?.find(d => d.groupId === columns[columns.length - 1]?.periodGroup?.id)?.value) ||
        0

      let valueLastYear =
        (report[0] &&
          report[0].actuals?.find(d => d.groupId === columns[columns.length - 2]?.periodGroup?.id)?.value) ||
        0
      if (params.representation?.options?.comparableDataType === 'budget') {
        valueLastYear =
          (report[0] &&
            report[0].budget?.find(d => d.groupId === columns[columns.length - 1]?.periodGroup?.id)?.value) ||
          0
      }
      if (compareInfoValues && valueLastYear) {
        return compareInfoValuesText(valueThisYear, valueLastYear, report[0].type)
      }
      if (valueLastYear < 0 && valueThisYear > 0) {
        return Math.abs(((valueThisYear - valueLastYear) / valueLastYear) * 100)
      }
      return ((valueThisYear - valueLastYear) / valueLastYear) * 100
    }

    const valueThisYear =
      (report[0] && report[0].actuals?.find(d => d.groupId === columns[columns.length - 1]?.periodGroup?.id)?.value) ||
      0
    let valueLastYear = report[0] && report[0].actuals?.find(d => d.groupId === -1)?.value
    if (params.representation.options?.comparableDataType === 'budget') {
      valueLastYear =
        (report[0] && report[0].budget?.find(d => d.groupId === columns[columns.length - 1]?.periodGroup?.id)?.value) ||
        0
    }

    if (valueLastYear && report[0].type === 'percentage') {
      valueLastYear *= 100
    }

    if (compareInfoValues && valueLastYear) {
      return compareInfoValuesText(valueThisYear, valueLastYear, report[0].type)
    }
    if (valueLastYear && valueLastYear < 0 && valueThisYear > 0) {
      return valueLastYear && valueThisYear && Math.abs(((valueThisYear - valueLastYear) / valueLastYear) * 100)
    }
    return valueLastYear && valueThisYear && ((valueThisYear - valueLastYear) / valueLastYear) * 100
  }

  return 0
}

export const infoTitle = (
  type: string,
  periodGroup: FiscalYear | PeriodGroup | undefined,
  params: any,
  columns: any,
  periodGroups: any,
  currentFiscalYear?: FiscalYear
) => {
  const getCurrentDates = (thisFiscalYearSelected: boolean) => {
    if (thisFiscalYearSelected) {
      const year = dayjs().subtract(1, 'month').year()
      const month = dayjs().subtract(1, 'month').month() + 1
      return `${month} / ${year}`
    }
    const year = periodGroup && dayjs(periodGroup.endDate).year()
    const month = periodGroup && dayjs(periodGroup.endDate).month() + 1
    return `${month} / ${year}`
  }

  if (!periodGroup) {
    if (type === 'current') {
      return columns[columns.length - 1] && columns[columns.length - 1].label
    }
    if (type === 'compare') {
      if (columns[columns.length - 2]) {
        const col =
          params.representation.options?.comparableDataType === 'budget'
            ? columns[columns.length - 1]
            : columns[columns.length - 2]
        return (
          col && `vs. ${col.label} ${params.representation.options?.comparableDataType === 'budget' ? ' budget' : ''}`
        )
      }

      const startyear = periodGroups?.[0] && dayjs(periodGroups?.[0].startDate).year()
      const startmonth = periodGroups?.[0] && dayjs(periodGroups?.[0].startDate).month() + 1
      const endyear = periodGroups?.[0] && dayjs(periodGroups?.[0].endDate).year()
      const endmonth = periodGroups?.[0] && dayjs(periodGroups?.[0].endDate).month() + 1
      if (!startyear && !startmonth) {
        return i18next.t('global:noHistoryData')
      }
      const yearOffset = params.representation.options?.comparableDataType === 'budget' ? 0 : 1
      if (startyear !== endyear) {
        return `vs. ${startmonth} / ${startyear - yearOffset} - ${endmonth} / ${endyear - yearOffset} ${
          params.representation.options?.comparableDataType === 'budget' ? ' budget' : ''
        }`
      }

      return `vs. ${startmonth} - ${endmonth} / ${endyear - yearOffset} ${
        params.representation.options?.comparableDataType === 'budget' ? ' budget' : ''
      }`
    }
  }
  if (periodGroup) {
    let thisFiscalYearSelected = false
    if (periodGroup.startDate === currentFiscalYear?.startDate && periodGroup.endDate === currentFiscalYear?.endDate) {
      thisFiscalYearSelected = true
    }
    if (periodGroup && params.representation.options?.monthlyComparableType === 'previousMonth') {
      if (type === 'current') {
        return getCurrentDates(thisFiscalYearSelected)
      }
      if (type === 'compare') {
        if (thisFiscalYearSelected) {
          const year = dayjs().subtract(1, 'month').year()
          const month = dayjs().subtract(2, 'month').month() + 1
          let title = `vs. ${month} / ${year}`
          if (params.representation.options?.comparableDataType === 'budget') {
            title += ' budget'
          }
          return title
        }
        const year = dayjs(periodGroup.endDate).subtract(1, 'month').year()
        const month = dayjs(periodGroup.endDate).subtract(1, 'month').month() + 1
        let title = `vs. ${month} / ${year}`
        if (params.representation.options?.comparableDataType === 'budget') {
          title += ' budget'
        }
        return title
      }
    }
    if (periodGroup && params.representation.options?.monthlyComparableType === 'sameMonthLastYear') {
      if (type === 'current') {
        return getCurrentDates(thisFiscalYearSelected)
      }
      if (type === 'compare') {
        if (thisFiscalYearSelected) {
          const year = dayjs().subtract(12, 'month').year()
          const month = dayjs().subtract(1, 'month').month() + 1
          let title = year && month && `vs.  ${month} / ${year}`
          if (params.representation.options?.comparableDataType === 'budget') {
            title += ' budget'
          }
          return title
        }
        const year = dayjs(periodGroup.endDate).subtract(12, 'month').year()
        const month = dayjs(periodGroup.endDate).month() + 1
        let title = year && month && `vs.  ${month} / ${year}`
        if (params.representation.options?.comparableDataType === 'budget') {
          title += ' budget'
        }
        return title
      }
    }
    if (periodGroup && params.representation.options?.monthlyComparableType === 'thisMonth') {
      if (type === 'current') {
        return getCurrentDates(thisFiscalYearSelected)
      }
      if (type === 'compare') {
        if (thisFiscalYearSelected) {
          const year = dayjs().year()
          const month = dayjs().subtract(1, 'month').month() + 1
          let title = year && month && `vs.  ${month} / ${year}`
          if (params.representation.options?.comparableDataType === 'budget') {
            title += ' budget'
          }
          return title
        }
        const year = dayjs(periodGroup.endDate).year()
        const month = dayjs(periodGroup.endDate).month() + 1
        let title = year && month && `vs.  ${month} / ${year}`
        if (params.representation.options?.comparableDataType === 'budget') {
          title += ' budget'
        }
        return title
      }
    }
  }
  return ''
}

export const getPieSeriesData = (
  data: Report,
  pieReport: Report,
  selectedPeriodGroup: FiscalYear | PeriodGroup | undefined,
  type: string,
  selectedDate: Date | null
) => {
  if (selectedDate) {
    const pieData: PointOptionsObject[] = []
    const thisYear = selectedDate.getFullYear()
    const thisMonth = selectedDate.getMonth() + 1
    for (const r of pieReport) {
      if (!selectedPeriodGroup) {
        pieData.push({
          name: r.name,
          drilldown: r.id.toString(),
          y: Number(r.actuals?.find((f: any) => f.year === thisYear && f.month === thisMonth)?.value) || 0
        })
      } else {
        pieData.push({
          name: r.name,
          drilldown: r.id.toString(),
          y: Number(r.actuals?.find((f: any) => f.groupId === selectedPeriodGroup?.id)?.value) || 0
        })
      }
    }
    if ((data.length > pieData.length && type === 'TOP') || type === undefined) {
      if (!selectedPeriodGroup) {
        let allValues = 0
        for (const values of data) {
          allValues +=
            Number(values.actuals?.find((f: any) => f.year === thisYear && f.month === thisMonth)?.value) || 0
        }
        for (const pieValue of pieData) {
          allValues -= Number(pieValue.y) || 0
        }
        pieData.push({
          name: i18next.t(`dashboardPage:others`) as string,
          y: allValues || 0
        })
      } else {
        let allValues = 0
        for (const values of data) {
          allValues += Number(values.actuals?.find((f: any) => f.groupId === selectedPeriodGroup?.id)?.value) || 0
        }
        for (const pieValue of pieData) {
          allValues -= Number(pieValue.y) || 0
        }
        pieData.push({
          name: i18next.t(`dashboardPage:others`) as string,
          y: allValues || 0
        })
      }
    }
    return pieData
  }
  return []
}

export const getPeriodGroup = (periodGroups: PeriodGroup[], currentFiscalYear: FiscalYear | undefined) => {
  // yksi hakuväli, valitaan se
  if (periodGroups.filter((f: PeriodGroup) => f.id > 0).length === 1) {
    return periodGroups.filter((f: PeriodGroup) => f.id > 0)[0]
  }
  if (periodGroups.length > 1) {
    // kvartaali, valitaan eka
    if (
      periodGroups.filter((f: PeriodGroup) => f.id > 0).length === 4 &&
      periodGroups
        .filter((f: PeriodGroup) => f.id > 0)
        .find((y: PeriodGroup) => new Date(y.startDate).getFullYear() !== currentFiscalYear?.year)
    ) {
      return periodGroups.filter((f: PeriodGroup) => f.id > 0)[0]
    }
    // muuten etsitään tälle ajalle osuva periodgrouppi
    return (
      currentFiscalYear &&
      periodGroups
        .filter((f: PeriodGroup) => f.id > 0)
        .find(
          (y: PeriodGroup) =>
            new Date(y.startDate).getFullYear() === new Date(currentFiscalYear.startDate).getFullYear()
        )
    )
  }
  return periodGroups.filter((f: PeriodGroup) => f.id > 0)[0]
}
