/* eslint-disable prettier/prettier */
import { Form, Radio, Row, Typography } from 'antd'
import React, { ReactNodeArray } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { socialSecurityExpenseConfigSelector } from '../../../../../../redux/config/budgeting/selectors'
import { companyAccountsByCodeSelector } from '../../../../../../redux/context/accounts/selectors'
import SocialSecurityInput from './SocialSecurityInput'

const radioStyle = {
  display: 'block'
}

interface FieldOptions {
  accountBalanceSheetRow: number
  sourceAccountBalanceSheetRow: number
  factor?: {
    minValue: number
  }
}

const SocialSecurityFields = () => {
  const socialSecurityExpenseConfig = useSelector(socialSecurityExpenseConfigSelector)
  const accountsByCode = useSelector(companyAccountsByCodeSelector)
  const { t } = useTranslation()

  const testAccountPairs = (_: any, value: any) => {
    if (value?.account && !value?.sourceAccounts?.length ||
      value?.sourceAccounts?.length && !value?.account
    ) {
      return Promise.reject(
        new Error(t('budgetingInputPage:target-source-account-missing'))
      )
    }
    return Promise.resolve()
  }

  const testAccountExisting = (_: any, value: any) => {

    if (value?.account && !accountsByCode?.[value.account]) {
      return Promise.reject(
        new Error(t('budgetingInputPage:target-account-error'))
      )
    }

    if (value?.sourceAccounts?.length > 0 && value?.sourceAccounts?.some((a: any) => !accountsByCode[a])) {
      return Promise.reject(
        new Error(t('budgetingInputPage:target-source-account-missing'))
      )
    }
    return Promise.resolve()
  }

  const getRow = (path: string[], name: string, options: FieldOptions) => {
    return (
      <Form.Item
        rules={[
          { validator: testAccountPairs },
          { validator: testAccountExisting }
        ]}
        key={[...path, name].join('_')}
        style={{ maxWidth: 'none' }}
        name={[...path, name]}
      >
        <SocialSecurityInput {...options} />
      </Form.Item>
    )

  }

  const generateForm = (data?: { [key: string]: any }, path = ['socialSecurityExpenses']): ReactNodeArray => {
    if (!data) return [null]
    const children = []
    for (const [key, value] of Object.entries(data)) {
      if (value != null) {
        const currentPath = [...path, key]
        children.push(
          <Row style={{ marginTop: 24 }} key={key}>
            <Typography.Title level={2 + currentPath.length as 4 | 5}>{t(`budgetingInputPage:${key}`)}</Typography.Title>
          </Row>
        )
        if ('rows' in value === false) {
          children.push(generateForm(value, currentPath))
        }
        if ('rows' in value) {
          for (const [name, options] of Object.entries<FieldOptions>(value.rows)) {
            children.push(
              <div key={`${currentPath}.${name}`}>
                <Row>
                  <p style={{ margin: '4px 0px' }}>{t(`budgetingInputPage:${name}`)}</p>
                </Row>
                {getRow(currentPath, name, options)}
              </div>
            )
          }
        }
      }
    }

    return children
  }

  return (
    <>
      <Form.Item rules={[{ required: true }]} name={['socialSecurityExpenses', 'enabled']} style={{ maxWidth: 'none' }}>
        <Radio.Group>
          <Radio style={radioStyle} value={false}>
            {t('budgetingInputPage:manually')}
          </Radio>
          <Radio style={radioStyle} value>
            {t('budgetingInputPage:percentage')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate
      >
        {({ getFieldValue }) => {
          return getFieldValue(['socialSecurityExpenses', 'enabled']) &&
            generateForm(socialSecurityExpenseConfig)
        }}
      </Form.Item>
    </>
  )
}

export default SocialSecurityFields
