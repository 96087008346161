import React from 'react'
import katex from 'katex'

interface KatexComponentProps {
  math: string
  displayMode?: boolean
}

const KatexComponent: React.FC<KatexComponentProps> = ({ math, displayMode }: KatexComponentProps) => {
  const html = () => {
    return {
      __html: katex.renderToString(math, {
        throwOnError: false,
        displayMode
      })
    }
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={html()} />
}

export default KatexComponent
