import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Language, User } from '../../../../types/user/User'
import { allRoles } from '../../../../types/user/Role'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../redux/store'

interface SuperUserUserProfileProps {
  user: User
}

const SuperUserUserProfile: React.FC<SuperUserUserProfileProps> = ({ user }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const updateUserRequest = useBackend(`/api/superuser/users/{userId}`)
  const dispatch: AppDispatch = useDispatch()

  const sendUpdatecontractRequest = (values: Partial<User & { roleId: number }>) => {
    updateUserRequest
      .put({ urlParams: { userId: user.id }, body: { data: { ...values, roles: [{ id: values.roleId }] } } })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'OK'
          })
        )
      })
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'USERS_ERROR',
            description: e.message
          })
        )
      })
  }

  return (
    <Form
      name="user"
      initialValues={{ ...user, roleId: user.role.id }}
      form={form}
      onFinish={sendUpdatecontractRequest}
      className="profile-form"
      layout="vertical"
    >
      <h4>{t('usersPage:email')}</h4>
      <p>{user.email}</p>

      <Form.Item name="firstName" label={t('usersPage:first-name')}>
        <Input placeholder={t('usersPage:first-name')} />
      </Form.Item>
      <Form.Item name="lastName" label={t('usersPage:last-name')}>
        <Input placeholder={t('usersPage:last-name')} />
      </Form.Item>
      <Form.Item name="phone" label={t('usersPage:phone')}>
        <Input placeholder={t('usersPage:phone')} />
      </Form.Item>
      <Form.Item name="language" label={t('usersPage:language')}>
        <Select>
          {Object.keys(Language).map(lng => (
            <Select.Option value={lng} key={lng}>
              {t(`languages:${lng}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="position" label={t('usersPage:position')}>
        <Input placeholder={t('usersPage:position')} />
      </Form.Item>
      <Form.Item name="roleId" label={t('usersPage:role')}>
        <Select>
          {allRoles.map(role => (
            <Select.Option value={role.id} key={role.id}>
              {t(`usersPage:${role.label}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button loading={updateUserRequest.loading} type="primary" htmlType="submit" className="login-form-button">
          {t('global:update')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SuperUserUserProfile
