import { Select, SelectProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum GraphType {
  column = 'column',
  bar = 'bar',
  line = 'line',
  spline = 'spline',
  areaspline = 'areaspline'
}

interface ChartTypeSelectProps extends SelectProps {
  value?: GraphType
  allowClear?: boolean
  onChange?: (value: GraphType) => void
}

const ChartTypeSelect: React.FC<ChartTypeSelectProps> = ({ value, allowClear, onChange, ...restProps }) => {
  const { t } = useTranslation()

  return (
    <Select
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      options={Object.values(GraphType).map(val => ({
        label: t(`graphType:${val}`),
        value: val
      }))}
      {...restProps}
    />
  )
}

export default ChartTypeSelect
