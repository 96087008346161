import { Button, Form, Popconfirm } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { disabledStyle } from '../../../../../components/ContextDrawer/ContextDrawer'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import {
  deleteInvestmentPageInvestmentsRequest,
  updateInvestmentPageInvestmentsRequest
} from '../../../../../redux/context/investments/actions'
import { Investment } from '../../../../../types/investment/Investment'
import { notLocked } from '../../../../settings/company/budgeting/budgetingScenarios/utils'
import InvestmentForm from './InvestmentForm'
import { investmentFormFormat, investmentSaveFormat } from './utils'
import { AppDispatch } from '../../../../../redux/store'

interface InvestmentTableFormProps {
  investment: Investment
}

const InvestmentTableForm: React.FC<InvestmentTableFormProps> = ({ investment }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)
  const [form] = useForm<Investment>()

  const onFinish = (values: any) => {
    const data = { ...investmentSaveFormat(values), isExisting: investment.isExisting }
    dispatch(updateInvestmentPageInvestmentsRequest(companyId, data, investment.isExisting))
  }

  const handleInvesmentDelete = () => {
    investment && dispatch(deleteInvestmentPageInvestmentsRequest(companyId, investment, investment.isExisting))
  }

  return (
    <Form
      form={form}
      name="investment"
      initialValues={investmentFormFormat(investment)}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
    >
      <div style={!notLocked(budgetingScenario) ? (disabledStyle as any) : undefined}>
        <InvestmentForm
          investment={investment}
          isExisting={investment.isExisting}
          formType={investment && investment.value < 0 ? 'divestment' : 'investment'}
        />
      </div>
      <Form.Item>
        <Button disabled={!notLocked(budgetingScenario)} type="primary" style={{ float: 'right' }} htmlType="submit">
          {t('global:save')}
        </Button>
        <Popconfirm title={t('global:delete-confirm')} onConfirm={handleInvesmentDelete}>
          <Button disabled={!notLocked(budgetingScenario)} danger style={{ float: 'left' }}>
            {t('global:delete')}
          </Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  )
}

export default InvestmentTableForm
