import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Input, DatePicker, Select } from 'antd'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { Investment } from '../../../../../types/investment/Investment'
import AccountSelect from '../../../../../components/inputs/AccountSelect'
import { investableStatementRowSelector } from '../../../../../redux/entities/statementRows/selectors'
import { requireContractProductExact } from '../../../../../components/Misc/RestrictedProduct'
import { contextContractProductSelector } from '../../../../../redux/context/contract/selectors'
import { ContractProduct } from '../../../../../types/contract/Contract'
import { InvestmentFormContext, InvesmentFormContextType } from '../../InvestmentFormContextProvider'

interface InvestmentFormFieldsProps {
  investment?: Investment
  formType?: 'investment' | 'divestment'
  page?: 'financialStatement' | 'loans'
}

const InvestmentFormFields: React.FC<InvestmentFormFieldsProps> = ({ investment, formType, page }) => {
  const { t } = useTranslation()
  const { startDateDisabledDate, deprecationBsSelectBalanceSheetRowIds } = useContext(
    InvestmentFormContext
  ) as InvesmentFormContextType
  const contextContractProduct = useSelector(contextContractProductSelector)
  const investableStatementRows = useSelector(investableStatementRowSelector)

  const statementRowSelectOptions = () => {
    return investableStatementRows.map(row => (
      <Select.Option key={row.id} value={row.id}>
        {t(`balanceSheet:${row.id}`)}
      </Select.Option>
    ))
  }

  return (
    <Row gutter={16} align="bottom">
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Col span={12}>
        <Form.Item
          label={
            formType === 'investment'
              ? `${t('financialStatementsPage:source')}`
              : `${t('financialStatementsPage:divesting-what')}`
          }
          name={
            contextContractProduct && requireContractProductExact(contextContractProduct, ContractProduct.PRO)
              ? 'accountCode'
              : 'balanceSheetRowId'
          }
          rules={[{ required: true, message: '' }]}
        >
          {contextContractProduct && requireContractProductExact(contextContractProduct, ContractProduct.PRO) ? (
            <AccountSelect
              balanceSheetRowIds={investment ? [investment.balanceSheetRowId] : deprecationBsSelectBalanceSheetRowIds()}
              disabled={page === 'financialStatement'}
            />
          ) : (
            <Select disabled={page === 'financialStatement'} showSearch optionFilterProp="children">
              {statementRowSelectOptions()}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={
            formType === 'investment'
              ? t('financialStatementsPage:value')
              : `${t('financialStatementsPage:divestment-amount')}`
          }
          name="value"
          rules={[{ required: true, message: '' }]}
        >
          <FormattedInputNumber
            min={formType === 'investment' ? 0 : undefined}
            max={formType === 'investment' ? undefined : 0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={t('financialStatementsPage:date')} name="date" rules={[{ required: true, message: '' }]}>
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} disabledDate={startDateDisabledDate} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="name"
          label={t('financialStatementsPage:description')}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default InvestmentFormFields
