/* eslint-disable react/no-unused-prop-types */
import { Tabs, TabsProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Loading } from '../../../../../components/Misc/Loading'
import ReportTable from '../../../../../components/Table/ReportTable'
import {
  assetsSelector,
  financialStatementLoadingSelector,
  liabilitiesSelector,
  financialStatementErrorSelector
} from '../../../../../redux/context/reports/selectors'
import { CompanyDashboardItem } from '../../../../../types/dashboard/company/types'
import { renderCell } from '../utils'

interface BalanceSheetProps {
  item: CompanyDashboardItem
  width: number
  height: number
}

export const BalanceSheet: React.FC<BalanceSheetProps> = ({ height }: BalanceSheetProps) => {
  const { t } = useTranslation()
  const assets = useSelector(assetsSelector)
  const liabilities = useSelector(liabilitiesSelector)
  const loading = useSelector(financialStatementLoadingSelector)
  const financialStatementError = useSelector(financialStatementErrorSelector)

  if (loading) return <Loading />

  const tabItems: TabsProps['items'] = [
    {
      label: t('financialStatementsPage:assets'),
      key: 'assets',
      destroyInactiveTabPane: true,
      children: (
        <ReportTable
          toolbar={{ csvExport: false }}
          report={assets?.map(row => ({
            ...row,
            children: row.children && row.children?.length > 0 ? [...row.children] : undefined
          }))}
          fiscalYearClosingLabel
          yScroll={height}
          error={financialStatementError}
          renderCell={renderCell}
          rowClassName={reportRow => classNames({ bold: reportRow.isParent })}
        />
      )
    },
    {
      label: t('financialStatementsPage:liabilities'),
      destroyInactiveTabPane: true,
      key: 'liabilities',
      children: (
        <ReportTable
          toolbar={{ csvExport: false }}
          report={liabilities?.map(row => ({
            ...row,
            children: row.children && row.children?.length > 0 ? [...row.children] : undefined
          }))}
          fiscalYearClosingLabel
          yScroll={height}
          error={financialStatementError}
          renderCell={renderCell}
          rowClassName={reportRow => classNames({ bold: reportRow.isParent })}
        />
      )
    }
  ]

  const filteredTabItems = () => {
    return tabItems.filter(item => {
      if (item.key === 'assets' && !assets) return false
      if (item.key === 'liabilities' && !liabilities) return false
      return true
    })
  }

  return <Tabs items={filteredTabItems()} defaultActiveKey="assets" />
}
