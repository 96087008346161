import React from 'react'
import { DashboardOutlined } from '@ant-design/icons'
import CompanyDashboard from '../../pages/dashboard/companyDashboard/CompanyDashboard'
import MultiPurposeDashboardDefault from '../../pages/dashboard/multiPurposeDashboard/MultiPurposeDashboardDefault'
import MultiPurposeDashboardCompact from '../../pages/dashboard/multiPurposeDashboard/MultiPurposeDashboardCompact'
import MultiCompanyDashboard from '../../pages/dashboard/multiCompanyDashboard/MultiCompanyDashboard'
import ScenarioDashboard from '../../pages/dashboard/scenarioDashboard/ScenarioDashboard'
import { Route } from '../Route'
import { multiCompany } from '../utils'
import { ContextFiltersSections } from '../../components/ContextDrawer/types'
import CompanyDashboardItemProvider from '../../pages/dashboard/companyDashboard/components/DashboardItemEdit/DashboardItemProvider'

const dashboardRoutes: Route = {
  path: '/dashboard',
  name: 'dashboard',
  icon: <DashboardOutlined />,
  routes: [
    {
      path: '/dashboard/accountant',
      name: 'accountant',
      authority: { role: ['accountant-admin', 'accountant-user'] },
      component: MultiCompanyDashboard
    },
    {
      path: '/dashboard/financier',
      name: 'financier',
      authority: { role: ['financier-admin', 'financier-user'] },
      component: MultiCompanyDashboard
    },
    {
      path: '/dashboard/companies',
      name: 'companies',
      authority: {
        role: ['company-admin', 'company-user'],
        custom: multiCompany
      },
      component: MultiCompanyDashboard,
      disabledContextdrawerSections: [
        ContextFiltersSections.budgetScenario,
        ContextFiltersSections.dimension,
        ContextFiltersSections.periodGroups,
        ContextFiltersSections.dataTypes
      ]
    },
    {
      path: '/dashboard/company',
      name: 'company',
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      },
      component: () => (
        <CompanyDashboardItemProvider>
          <CompanyDashboard />
        </CompanyDashboardItemProvider>
      )
    },
    {
      path: '/dashboard/dimensions',
      name: 'dimensions',
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      },
      routes: [
        {
          path: '/dashboard/dimensions/default',
          name: 'default',
          component: MultiPurposeDashboardDefault
        },
        {
          path: '/dashboard/dimensions/compact',
          name: 'compact',
          component: MultiPurposeDashboardCompact
        }
      ]
    },
    {
      path: '/dashboard/scenarios',
      name: 'scenarios',
      component: ScenarioDashboard,
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      }
    }
  ]
}

export default dashboardRoutes as Route
