import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocsURL } from '../../../../../../utils/docs'
// import FutursoftUnits from './FutursoftUnits'
import FutursoftApi from './FutursoftApi'

const Futursoft = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: (
        <FutursoftApi
          allowAuthentication
          credentialsRequestUrl="/futursoft/authentication/{companyId}"
          pageDocsUrl="manual/businesses/settings/company#futursoft"
        />
      )
    }
    // tämä käyttöön joskus myöhemmin
    // {
    //   label: t('integrationsPage:unit'),
    //   key: 'unit',
    //   children: (
    //     <FutursoftUnits getUnitsUrl="/futursoft/units/{companyId}" updateUnitsUrl="/futursoft/units/{companyId}/{id}" />
    //   )
    // }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#futursoft`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Futursoft
