import { BudgetDriver } from '../../../types/budgetDriver/BudgetDriver'
import {
  BudgetDriversActionTypes,
  SET_BUDGET_DRIVERS,
  ADD_BUDGET_DRIVER,
  UPDATE_BUDGET_DRIVER,
  DELETE_BUDGET_DRIVER
} from './types'

const initialState: BudgetDriver[] = []

const budgetDriversReducer = (state = initialState, action: BudgetDriversActionTypes) => {
  switch (action.type) {
    case SET_BUDGET_DRIVERS:
      return [...action.payload]
    case ADD_BUDGET_DRIVER:
      return [...state, action.payload]
    case UPDATE_BUDGET_DRIVER:
      return state.map((item: BudgetDriver) => (item.id !== action.payload.id ? item : { ...item, ...action.payload }))
    case DELETE_BUDGET_DRIVER:
      return state.filter((item: BudgetDriver) => item.id !== action.payload.id)
    default:
      return state
  }
}

export default budgetDriversReducer
