import React, { useContext, useEffect, useState } from 'react'
import { Form, Modal, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useWatch } from 'antd/es/form/Form'
import { BudgetingMethod } from './types'
import {
  ReportTableRow,
  RowBudgetingMethod,
  RowBudgetingMethodFormValues,
  RowBudgetingMethodPut
} from '../../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { contextRequest } from '../../../../../redux/context/actions'
import {
  getFinancialStatementRequest,
  getKeyPerformanceIndicatorStatmentRequest
} from '../../../../../redux/context/reports/actions'
import { companySettingsSelector } from '../../../../../redux/context/companySettings/selectors'
import { BudgetingMethodSourceSelect } from '../../../../../components/Budgeting/BudgetingMethodSourceSelect'
import { BudgetingMethodBudgetDriver } from '../../../../../components/Budgeting/BudgetingMethodBudgetDriver'
import { AppDispatch } from '../../../../../redux/store'
import { BudgetingContext, BudgetingContextType } from './BudgetingProvider'
import { parseArrayFromString } from './utils'
import { useResetFormOnCloseModal } from '../../../../../components/Form/hooks'

export enum BudgetingMethodTranslations {
  absolute = 'financialStatementsPage:budgetmenu-absolute',
  reference = 'financialStatementsPage:budgetmenu-reference',
  driver = 'financialStatementsPage:budgetmenu-budget-driver'
}

interface BudgetMethodModalProps {
  visible: boolean
  reportTableRow?: ReportTableRow
  reloadDimensionRows: () => void
}

const BudgetMethodModal = ({ visible, reportTableRow, reloadDimensionRows }: BudgetMethodModalProps) => {
  const { t } = useTranslation()
  const [form] = useForm<RowBudgetingMethodFormValues>()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)
  const settings = useSelector(companySettingsSelector)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [requestError, setRequestError] = useState<any>({
    source: '',
    error: null
  })
  const { setVisible } = useContext(BudgetingContext) as BudgetingContextType
  const budgetingMethod = useWatch('method', form)
  const budgetMethodRequest = useBackend('/api/companies/{companyId}/budgeting/budgeting-methods')

  useResetFormOnCloseModal({
    form,
    open: visible
  })

  useEffect(() => {
    form.setFieldsValue({
      ...reportTableRow?.budgetingMethod,
      sourceIds: parseArrayFromString(reportTableRow?.budgetingMethod?.source)?.map((val: string | null) =>
        val === null ? 'root' : val
      )
    })
  }, [reportTableRow?.budgetingMethod, visible])

  const handleSubmit = async () => {
    if (!reportTableRow) return
    try {
      setConfirmLoading(true)
      const formFields = await form.validateFields()
      const { method, source, sourceIds } = formFields
      const { incomeStatementRowId, accountCode } = reportTableRow
      let budgetingMethodValues: RowBudgetingMethodPut = {
        incomeStatementRowId,
        accountCode,
        method
      }

      if (method === BudgetingMethod.reference) {
        budgetingMethodValues = {
          ...budgetingMethodValues,
          source:
            settings.budgeting.level === 'account'
              ? `account([${sourceIds.join()}], current)`
              : `pl([${sourceIds.join()}], current)`
        }
      }
      if (method === BudgetingMethod.driver) {
        budgetingMethodValues = {
          ...reportTableRow.budgetingMethod,
          ...budgetingMethodValues,
          ...formFields,
          dimensionIds: formFields?.dimensionIds?.map(value => (value === 'root' ? null : value))
        }
      }

      if (companyId) {
        budgetMethodRequest
          .put({
            urlParams: { companyId },
            body: {
              data: budgetingMethodValues
            }
          })
          .then(() => {
            setRequestError({
              source: '',
              error: null
            })
            reloadDimensionRows()
            dispatch(contextRequest(getFinancialStatementRequest))
            dispatch(contextRequest(getKeyPerformanceIndicatorStatmentRequest))
            form.resetFields()
            setConfirmLoading(false)
            setVisible(false)
          })
          .catch(() => {
            setConfirmLoading(false)
            if (budgetMethodRequest.error.current) {
              setRequestError({
                source,
                error: JSON.parse(budgetMethodRequest.error.current?.data?.message)
              })
            }
          })
      }
    } catch (error) {
      setConfirmLoading(false)
      console.log(error)
    }
  }

  const initValues = (changed: Partial<RowBudgetingMethod>) => {
    const { method } = changed
    if (!method) return
    if (reportTableRow?.budgetingMethod?.method === BudgetingMethod.absolute) {
      form.setFieldsValue({
        source: '',
        drivers: undefined
      })
    }
    if (reportTableRow?.budgetingMethod?.method === BudgetingMethod.reference) {
      if (method === BudgetingMethod.driver || method === BudgetingMethod.absolute) {
        form.setFieldsValue({
          source: ''
        })
      } else if (method === BudgetingMethod.reference) {
        form.setFieldsValue({
          source: reportTableRow.budgetingMethod?.source,
          drivers: undefined
        })
      }
    }
    if (reportTableRow?.budgetingMethod?.method === BudgetingMethod.driver) {
      if (method === BudgetingMethod.reference || method === BudgetingMethod.absolute) {
        form.setFieldsValue({
          source: ''
        })
      } else if (method === BudgetingMethod.driver) {
        form.setFieldsValue({
          source: reportTableRow.budgetingMethod?.source
        })
      }
    }
  }

  return (
    <>
      <Modal
        destroyOnClose
        title={`${t('financialStatementsPage:budgeting-method')} ${reportTableRow?.title}`}
        open={visible}
        maskClosable={false}
        onCancel={() => {
          if (reportTableRow?.budgetingMethod) {
            form.setFieldsValue(reportTableRow?.budgetingMethod)
          }
          setVisible(false)
        }}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        okText={t('global:save')}
        cancelText={t('global:cancel')}
      >
        <Form onValuesChange={initValues} form={form} layout="vertical">
          <Form.Item name="method">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={BudgetingMethod.absolute}>
                {t(BudgetingMethodTranslations[BudgetingMethod.absolute])}
              </Radio.Button>
              <Radio.Button value={BudgetingMethod.reference}>
                {t(BudgetingMethodTranslations[BudgetingMethod.reference])}
              </Radio.Button>
              <Radio.Button value={BudgetingMethod.driver}>
                {t(BudgetingMethodTranslations[BudgetingMethod.driver])}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {budgetingMethod === BudgetingMethod.reference && (
              <Form.Item
                name="sourceIds"
                rules={[{ required: true, message: t('global:required-field') }]}
                label={t('financialStatementsPage:budget-reference-select')}
              >
                <BudgetingMethodSourceSelect budgetingLevel={settings.budgeting.level} width="100%" />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {budgetingMethod === BudgetingMethod.driver && (
              <BudgetingMethodBudgetDriver row={reportTableRow} form={form} requestError={requestError} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default BudgetMethodModal
