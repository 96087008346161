import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import SeveraApi from './SeveraApi'
import { useDocsURL } from '../../../../../../utils/docs'
import DimensionMap from '../dimensionMap/DimensionMap'

const Severa: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: <SeveraApi />
    },
    {
      label: t('global:projects'),
      key: 'projects',
      children: (
        <DimensionMap
          getDimensionsUrl="/severa/projects/{companyId}"
          updateDimensionsUrl="/severa/projects/{companyId}/{id}"
          dataKey="id"
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#severa`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Severa
