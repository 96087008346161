import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Loan } from '../../../../types/loan/Loan'
import RepaymentSchedule from './RepaymentScedule'

interface RepaymentScheduleProps {
  loan: Loan | null
  handleOk: (dataUpdated: boolean) => void
}

const RepaymentScheduleModal: React.FC<RepaymentScheduleProps> = ({ loan, handleOk }: RepaymentScheduleProps) => {
  const { t } = useTranslation()
  const [dataUpdated, setDataupdated] = useState<boolean>(false)

  return (
    <Modal
      style={{ maxWidth: '1200px' }}
      title={t('loans:repayment-schedule')}
      open={!!loan}
      onCancel={() => {
        handleOk(dataUpdated)
        setDataupdated(false)
      }}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            handleOk(dataUpdated)
            setDataupdated(false)
          }}
        >
          Ok
        </Button>
      ]}
      maskClosable={false}
      width="unset"
    >
      {loan && <RepaymentSchedule loanData={loan} hasDataUpdated={setDataupdated} />}
    </Modal>
  )
}

export default RepaymentScheduleModal
