import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserPage from '../../../../components/User/UserPage'
import {
  createSessionContractUserRequest,
  deleteSessionContractUserRequest,
  getSessionContractUsersRequest,
  updateSessionContractUserRequest
} from '../../../../redux/session/contract/actions'
import {
  sessionContractIdSelector,
  sessionContractUserSelector,
  sessionContractUsersLoadingSelector
} from '../../../../redux/session/contract/selectors'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { financierRoles } from '../../../../types/user/Role'
import { AppDispatch } from '../../../../redux/store'

const FinancierUsers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const contractId = useSelector(sessionContractIdSelector)
  const users = useSelector(sessionContractUserSelector)
  const loading = useSelector(sessionContractUsersLoadingSelector)
  const selectableCompanies = useSelector(currentUserSortedCompaniesSelector)

  useEffect(() => {
    contractId && dispatch(getSessionContractUsersRequest(contractId))
  }, [contractId, dispatch])

  return (
    <UserPage
      contractId={contractId}
      users={users}
      loading={loading}
      selectableCompanies={selectableCompanies}
      selectableRoles={financierRoles}
      createUserRequest={createSessionContractUserRequest}
      updateUserRequest={updateSessionContractUserRequest}
      deleteUserRequest={deleteSessionContractUserRequest}
    />
  )
}

export default FinancierUsers
