import { Modal, Steps, Form } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSessionContractChildrenById } from '../../../redux/session/contract/selectors'
import { Contract, ContractFormStep } from '../../../types/contract/Contract'
import '../_Contract.sass'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'
import OrderStep from './OrderStep'
import ProductStep from './ProductStep'
import UpdateResultStep from './UpdateResultStep'

interface ContractFormProps {
  contract: Contract
  modalVisible: boolean
  handleCancel: () => void
}

const UpdateContractFormModal: React.FC<ContractFormProps> = ({
  contract,
  handleCancel,
  modalVisible
}: ContractFormProps) => {
  const { t } = useTranslation()
  const company = useSelector(getSessionContractChildrenById(contract.companyId))
  const { form, currentStep, handleUpdateContract, resetForm, handleCompanyChange } = useContext(
    ContractFormContext
  ) as ContractFormContextType

  useEffect(() => {
    handleCompanyChange(company)
    form.setFieldsValue({ contract })
  }, [])

  const closeModal = () => {
    handleCancel()
    resetForm()
  }

  let stepComponent
  switch (currentStep as any) {
    case ContractFormStep.Product:
      stepComponent = <ProductStep showBackButton={false} />
      break
    case ContractFormStep.Order:
      stepComponent = <OrderStep />
      break
    case ContractFormStep.Result:
      stepComponent = <UpdateResultStep closeModal={closeModal} />
      break
    default:
      stepComponent = <div />
      break
  }

  const items = [
    { title: t('contractsPage:product') },
    { title: t('contractsPage:order') },
    { title: t('contractsPage:summary') }
  ]

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }
  return (
    <Modal
      width="unset"
      style={{ maxWidth: '900px' }}
      title={`${t('contractsPage:update-contract')} ${contract.name}`}
      open={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      footer={false}
      destroyOnClose
    >
      <div className="contract">
        <Steps current={currentStep - 1} style={{ marginBottom: 16 }} items={items} />
        <Form
          {...formItemLayout}
          form={form}
          layout="horizontal"
          name="contract"
          preserve
          onFinish={handleUpdateContract}
        >
          {stepComponent}
        </Form>
      </div>
    </Modal>
  )
}

export default UpdateContractFormModal
