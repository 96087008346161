import React, { useContext, useRef } from 'react'
import useComponentSize from '@rehooks/component-size'
import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CompanyDashboardItem, CompanyDashboardItemType } from '../../../../types/dashboard/company/types'
import { useDebounce } from '../../../../utils/hooks'
import { BalanceSheet } from './items/BalanceSheet'
import { CashFlowStatement } from './items/CashFlowStatement'
import { IncomeStatement } from './items/IncomeStatement'
import { KeyFigures } from './items/KeyFigures'
import { deleteCompanyDashboardItemRequest } from '../../../../redux/context/dashboard/company/actions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { name } from './utils'
import { InternalKeyFigures } from './items/InternalKeyFigures'
import { Sales } from './items/Sales'
import { downloadMenuItems, tableDownload } from '../../../../utils/tableExport'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from './DashboardItemEdit/DashboardItemProvider'
import { AppDispatch } from '../../../../redux/store'

interface GridItemProps {
  item: CompanyDashboardItem
  companyDashboardId: number
}

const GridItem = ({ companyDashboardId, item }: GridItemProps) => {
  const dispatch: AppDispatch = useDispatch()

  const ref = useRef<HTMLDivElement>(null)
  const size = useComponentSize(ref)
  const { t } = useTranslation()
  const { width, height } = useDebounce(size, 1000)
  const companyId = useSelector(contextCompanyIdSelector)
  const { handleOpen } = useContext(CompanyDashboardItemContext) as CompanyDashboardContextType

  const headerHeight = 32
  const tableHeaderHeight = 66
  const tabHeight = 59

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    const key = e.keyPath.pop()
    switch (key) {
      case 'delete':
        companyId && dispatch(deleteCompanyDashboardItemRequest(companyId, item, companyDashboardId))
        break
      case 'edit':
        handleOpen(item)
        break
      case 'download':
        tableDownload(ref, name(item), e.key)
        break
      default:
        message.info(`Click on menu item. ${e.key}`)
        break
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      label: t('global:edit'),
      key: 'edit',
      icon: <EditOutlined />
    },
    {
      label: t('global:delete'),
      key: 'delete',
      icon: <DeleteOutlined />
    }
  ]

  if (item.params.representation.type === 'table') {
    menuItems.splice(1, 0, {
      label: t('global:download'),
      key: 'download',
      icon: <DownloadOutlined />,
      children: downloadMenuItems
    })
  }

  const child = () => {
    switch (item.type) {
      case CompanyDashboardItemType.INCOME_STATEMENT:
        return <IncomeStatement item={item} width={width} height={height - tableHeaderHeight - 24} />
      case CompanyDashboardItemType.BALANCE_SHEET:
        return <BalanceSheet item={item} width={width} height={height - tableHeaderHeight - tabHeight} />
      case CompanyDashboardItemType.CASH_FLOW_STATEMENT:
        return <CashFlowStatement item={item} width={width} height={height - tableHeaderHeight} />
      case CompanyDashboardItemType.KEY_FIGURES:
        return <KeyFigures item={item} width={width} height={height - tableHeaderHeight} />
      case CompanyDashboardItemType.INTERNAL_KEY_FIGURES:
        return <InternalKeyFigures item={item} width={width} height={height - tableHeaderHeight} />
      case CompanyDashboardItemType.SALES:
        return <Sales item={item} width={width} height={height - tableHeaderHeight} />

      default:
        return <div>not implemented</div>
    }
  }

  return (
    <>
      <div className="grid-item-header">
        <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }} trigger={['click']}>
          <Button
            onMouseDown={e => e.stopPropagation()}
            onTouchStart={e => e.stopPropagation()}
            icon={<DownOutlined />}
            type="link"
            style={{ color: 'black' }}
            onClick={handleButtonClick}
          >
            {name(item)}
          </Button>
        </Dropdown>
      </div>
      <div ref={ref} className="grid-item-content" style={{ width: '100%', height: `calc(100% - ${headerHeight}px)` }}>
        {child()}
      </div>
    </>
  )
}

export default GridItem
