import { DownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { MenuProps, Dropdown } from 'antd'
import React, { Ref, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import deleteConfirmModal from '../../../../../components/Modal/deleteConfirmModal'
import { deleteCustomReportSectionRequest } from '../../../../../redux/context/customReports/actions'
import { multipleTableDownload, downloadMenuItems } from '../../../../../utils/tableExport'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { AppDispatch } from '../../../../../redux/store'
import { calculatedReportSelector } from '../../../../../redux/context/customReports/selectors'
import { CustomReportSectionsType } from '../../../../../redux/context/customReports/typesSection'
import { LayoutsContext, LayoutsContextType } from '../layout/LayoutContext'

interface CustomReportSectionActionsProps {
  section: CustomReportSectionsType
  sectionElementRef: Ref<HTMLDivElement>
  editModal: (section: CustomReportSectionsType) => void
}

const CustomReportSectionActions: React.FC<CustomReportSectionActionsProps> = ({
  section,
  sectionElementRef,
  editModal
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const { id: reportId } = useSelector(calculatedReportSelector) || {}
  const { onSectionRemove } = useContext(LayoutsContext) as LayoutsContextType

  const handleMenuClick = ({ key }: any) => {
    switch (key) {
      case 'edit':
        editModal(section)
        break
      case 'delete':
        deleteConfirmModal({
          content: section?.title,
          onOk: () => {
            if (companyId && reportId && section.id) {
              dispatch(deleteCustomReportSectionRequest(companyId, reportId, section.id))
              onSectionRemove(section)
            }
          }
        })
        break
      case 'csv':
      case 'xlsx':
        multipleTableDownload(sectionElementRef, section?.title || 'omaRaportti', key)
        break
      default:
        break
    }
  }

  const sectionMenuItems: MenuProps['items'] = [
    {
      icon: <DownloadOutlined />,
      label: `${t('global:download')}`,
      key: 'download',
      disabled: section.type !== 'table',
      children: [...downloadMenuItems]
    },
    { icon: <DeleteOutlined />, label: t('global:delete'), key: 'delete' }
  ]

  const menuProps = {
    items: sectionMenuItems,
    onClick: handleMenuClick
  }

  return (
    <div className="section-actions">
      <Dropdown.Button onClick={() => editModal(section)} menu={menuProps}>
        <EditOutlined />
        {t('global:edit')}
      </Dropdown.Button>
    </div>
  )
}

export default CustomReportSectionActions
