import React from 'react'
import { CalculatorOutlined } from '@ant-design/icons'
import FinancialStatements from '../../pages/budgeting/financialStatements/FinancialStatements'
import { ContractProduct } from '../../types/contract/Contract'
import { Route } from '../Route'
import ExistingLoans from '../../pages/budgeting/loans/ExistingLoans'
import NewLoans from '../../pages/budgeting/loans/NewLoans'

const budgetingRoutes: Route = {
  path: '/budgeting',
  name: 'budgeting',
  icon: <CalculatorOutlined />,
  authority: {
    role: ['company-admin', 'company-user', 'accountant-admin', 'accountant-user', 'financier-admin', 'financier-user'],
    product: [ContractProduct.BASIC, ContractProduct.PRO]
  },
  routes: [
    {
      path: '/budgeting/financial-statements',
      name: 'financial-statements',
      component: FinancialStatements
    },
    {
      path: '/budgeting/loans',
      name: 'loans',
      authority: {
        permission: 'read-root-dimension'
      },
      routes: [
        {
          path: '/budgeting/loans/existing',
          name: 'existing',
          component: ExistingLoans
        },
        {
          path: '/budgeting/loans/new',
          name: 'new',
          component: NewLoans
        }
      ]
    }
  ]
}

export default budgetingRoutes as Route
