import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import {
  createFinancialStatementInvestmentsRequest,
  deleteFinancialStatementInvestmentsRequest,
  updateFinancialStatementInvestmentsRequest
} from '../../../../../redux/context/investments/actions'
import { ContractProduct } from '../../../../../types/contract/Contract'
import { notLocked } from '../../../../settings/company/budgeting/budgetingScenarios/utils'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import InvestmentModal from '../../../investments/components/InvestmentModal'
import InvestmentFormContextProvider from '../../../investments/InvestmentFormContextProvider'
import DeprecationForm from './DeprecationForm'
import Authorized from '../../../../../components/Authorized/Authorized'

interface InvestmentMenuProps {
  reportTableRow: ReportTableRow
}

const InvestmentMenu: React.FC<InvestmentMenuProps> = (props: InvestmentMenuProps) => {
  const { t } = useTranslation()
  const { reportTableRow } = props
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [formType, setFormType] = useState<'investment' | 'divestment'>('investment')
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  return (
    <>
      {modalVisible && (
        <InvestmentFormContextProvider reportTableRow={reportTableRow} isExisting={false} reportType={formType}>
          <InvestmentModal
            updateInvestmentsRequest={updateFinancialStatementInvestmentsRequest}
            createInvestmentsRequest={createFinancialStatementInvestmentsRequest}
            deleteInvestmentsRequest={deleteFinancialStatementInvestmentsRequest}
            visible={modalVisible}
            onFinish={toggleModal}
            formType={formType}
            reportTableRow={reportTableRow}
            page="financialStatement"
          />
        </InvestmentFormContextProvider>
      )}

      <Button
        type="default"
        size="small"
        disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
        onClick={() => {
          setFormType('investment')
          setModalVisible(true)
        }}
      >
        {t('financialStatementsPage:investment')}
      </Button>

      <Button
        type="default"
        size="small"
        disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
        onClick={() => {
          setFormType('divestment')
          setModalVisible(true)
        }}
      >
        {t('financialStatementsPage:divestment')}
      </Button>

      <Authorized
        authority={{
          product: ContractProduct.BASIC,
          custom: () => !!(reportTableRow.depreciable && notLocked(budgetingScenario))
        }}
      >
        <DeprecationForm balanceSheetRowId={reportTableRow.id} reportTableRow={reportTableRow} />
      </Authorized>
    </>
  )
}

export default React.memo(InvestmentMenu)
