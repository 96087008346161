import tol2008Tree from '../resources/tol2008/tol_2008_fi.json'
import { IndustrialClassification } from '../resources/tol2008/types'

export const getIndustryClassificationRoot = (code: IndustrialClassification['code']): string => {
  let found = false
  let root = ''
  function recurse(classifications: IndustrialClassification[]) {
    for (const classification of classifications) {
      if (classification.level === 1) root = classification.code
      if (classification.code === code) {
        found = true
        break
      }
      if (classification.children) {
        recurse(classification.children)
        if (found) break
      }
    }
  }

  recurse(tol2008Tree)

  return root
}
