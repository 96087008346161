import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadOutlined } from '@ant-design/icons'
import VoucherTable from '../../../../../components/Voucher/VoucherTable'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { useBackend } from '../../../../../services/backend'
import { VoucherQueryResult, VoucherQueryParams } from '../../../vouchers/types'
import { AppDispatch } from '../../../../../redux/store'
import { CustomTree } from './CustomTree'
import { DimensionTree } from '../../../../../components/Dimension/DimensionTree'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { userCompaniesSelector } from '../../../../../redux/session/currentUser/selectors'
import { getRequestParams } from '../../../../../components/Voucher/utils'
import ToolBar from '../../../../../components/Misc/ToolBar'

interface CustomReportVoucherModalProps {
  visible: boolean
  row: any
  column: any
  onCancel: any
  categories: CustomReportCategory[]
  categoryTree: CustomTree
}

const CustomReportVoucherModal: React.FC<CustomReportVoucherModalProps> = ({
  categories,
  categoryTree,
  visible,
  row,
  column,
  onCancel
}) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const vouchersRequest = useBackend(`/reporting/vouchers`, process.env.REACT_APP_INTEGRATION_URL)
  const xlsxRequest = useBackend(`/reporting/vouchers/xlsx`, process.env.REACT_APP_INTEGRATION_URL)

  const { loading } = vouchersRequest
  const [voucherQueryResult, setVoucherQueryResult] = useState<Partial<VoucherQueryResult>>()
  const [params, setParams] = useState<Partial<VoucherQueryParams>>({})
  const [companyId, setCompanyId] = useState<number>()
  const companies = useSelector(userCompaniesSelector)

  const getDimensionQuery = (v: CustomReportVariable, c: CustomReportCategory) => {
    const ctxCategory = categoryTree.getContextRow(c?.id)

    const dimArray = []
    if (ctxCategory?.dimension?.value) dimArray.push([ctxCategory.dimension.value])
    if (row?.params?.dimension?.dimensionId) dimArray.push([row.params.dimension.dimensionId])

    const dimensionQuery = DimensionTree.queryBuilder(dimArray)

    return dimensionQuery && JSON.stringify([dimensionQuery])
  }

  const request = (p: Partial<VoucherQueryParams>) => {
    vouchersRequest
      .get({ body: { params: { params: p, companyId, dimensions: getDimensionQuery(row, column) } } })
      .then(setVoucherQueryResult)
      .catch((e: Error) => {
        setVoucherQueryResult({})
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR',
            description: e.message
          })
        )
      })
  }

  const getParams = (c: CustomReportCategory) => {
    const ctxCategory = categoryTree.getContextRow(c?.id)

    const values = {
      companyId: ctxCategory?.company?.value as number,
      accountingSoftWare: companies?.find(comp => comp.id === ctxCategory?.company?.value)?.accountingSoftware
    }

    return values
  }

  const updateParams = () => {
    const values = getParams(column)
    setCompanyId(values.companyId)
    setParams({
      ...params,
      ...values,
      from: dayjs(column?.startDate).startOf('month').format('YYYY-MM-DD'),
      until: dayjs(column?.endDate).endOf('month').format('YYYY-MM-DD'),
      accounts: [row?.code]
    })
  }

  useEffect(() => {
    if (visible && row?.code && companyId) {
      request(params)
    }
  }, [params])

  useEffect(() => {
    updateParams()
    return () => setVoucherQueryResult({})
  }, [categories, row, column])

  const xlsx = (p: Partial<VoucherQueryParams>) => {
    /**
     * AMISKIKKA
     * Joo tämmönen säätö piti tehä, muuten tulee korruptoitunu tiedosto
     * Eli bäkki kääntää bufferin base64 ja tää sit siitä takasi
     */
    const base64ToArrayBuffer = (base64: string) => {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    }
    const requestParams = getRequestParams(p)
    xlsxRequest
      .get({ body: { params: { params: requestParams, companyId } } })
      .then((response: BlobPart) => {
        const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(response as string)]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transactions.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
      })
  }

  return (
    <Modal
      destroyOnClose
      forceRender={false}
      width=""
      title={`${t('global:vouchers')}  ${row?.name}`}
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onCancel}
      className="voucher-modal"
      afterClose={() => setParams({})}
    >
      <ToolBar justifyContent="right">
        <Button type="default" icon={<DownloadOutlined />} loading={xlsxRequest.loading} onClick={() => xlsx(params)}>
          Excel
        </Button>
      </ToolBar>
      <VoucherTable
        loading={loading}
        dataSource={voucherQueryResult}
        params={params}
        setParams={setParams}
        filtersEnabled={false}
      />
    </Modal>
  )
}

export default CustomReportVoucherModal
