import { Account } from '../../../types/account/Account'
import { Company } from '../../../types/company/Company'
import { GET, GetRequestAction, POST, PUT, PostRequestAction, PutRequestAction, REQUEST } from '../../middleware/types'
import {
  SetAccountsAction,
  SET_COMPANY_ACCOUNTS,
  AddAccountAction,
  ADD_COMPANY_ACCOUNT,
  UPDATE_COMPANY_ACCOUNT,
  UpdateAccountAction,
  DELETE_COMPANY_ACCOUNT,
  DeleteAccountAction
} from './types'

export const setAccounts = (accounts: Account[]): SetAccountsAction => ({
  type: SET_COMPANY_ACCOUNTS,
  payload: accounts
})

export const addAccountAction = (account: Account): AddAccountAction => ({
  type: ADD_COMPANY_ACCOUNT,
  payload: account
})

export const updateAccountAction = (account: Account): UpdateAccountAction => ({
  type: UPDATE_COMPANY_ACCOUNT,
  payload: account
})

export const deleteAccountAction = (account: Account): DeleteAccountAction => ({
  type: DELETE_COMPANY_ACCOUNT,
  payload: account
})

export const getAccountsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/accounts`,
    success: setAccounts
  },
  meta: {
    type: 'ACCOUNTS'
  }
})

export const createAccountRequest = (companyId: Company['id'], data: Account): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `/api/companies/${companyId}/accounting/accounts`,
    data,
    success: addAccountAction
  },
  meta: {
    type: 'CREATE_ACCOUNT',
    notification: true
  }
})

export const updateAccountRequest = (companyId: Company['id'], data: Account): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/accounting/accounts/${data.code}`,
    data,
    success: updateAccountAction
  },
  meta: {
    type: 'UPDATE_ACCOUNT',
    notification: true
  }
})
