import _ from 'lodash'

const initialState: any = {}

const loadingReducer = (state: any = initialState, action: any) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type)

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [_.camelCase(requestName)]: requestState === 'REQUEST'
  }
}

export default loadingReducer
