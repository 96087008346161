import { Formula } from '../../../types/formula/Formula'
import { SET_FORMULAS, FormulasActionTypes, ADD_FORMULA, UPDATE_FORMULA, DELETE_FORMULA } from './types'

const initialState: Formula[] = []

const formulasReducer = (state = initialState, action: FormulasActionTypes) => {
  switch (action.type) {
    case SET_FORMULAS:
      return action.payload
    case ADD_FORMULA:
      return [...state, action.payload]
    case UPDATE_FORMULA:
      return state.map((item: Formula) => (item.id !== action.payload.id ? item : { ...item, ...action.payload }))
    case DELETE_FORMULA:
      return state.filter((item: Formula) => item.id !== action.payload.id)
    default:
      return state
  }
}

export default formulasReducer
