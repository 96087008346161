import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SET_PERMISSIONS, SetPermissionsAction } from './types'
import { Permission } from '../../../types/user/Permission'

export const setPermissions = (permissions: Permission[]): SetPermissionsAction => ({
  type: SET_PERMISSIONS,
  payload: permissions
})

export const getPermissionsRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/permissions`,
    success: (payload: Permission[]) => setPermissions(payload)
  },
  meta: {
    type: 'PERMISSIONS'
  }
})
