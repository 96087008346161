import React, { useContext } from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { CompanyDashboardContextType, CompanyDashboardItemContext } from '../DashboardItemProvider'

const DashboardItemFields = () => {
  const { t } = useTranslation()
  const { companyDashboardItemPreferenceSelection } = useContext(
    CompanyDashboardItemContext
  ) as CompanyDashboardContextType
  return (
    <>
      <Form.Item hidden label="id" name="id">
        <Input />
      </Form.Item>
      <Form.Item hidden label="type" name="type">
        <Input />
      </Form.Item>
      <Form.Item hidden label="esitys tyyppi" name={['params', 'representation', 'type']}>
        <Input />
      </Form.Item>
      <Form.Item hidden label="salesissa tyyppi" name={['params', 'record']}>
        <Input />
      </Form.Item>
      <Form.Item hidden label="salesissa TOP esim" name={['params', 'type']}>
        <Input />
      </Form.Item>
      <Form.Item hidden label="datatyyppi" name={['params', 'selectedType']}>
        <Input />
      </Form.Item>
      <Form.Item hidden label="Cashflow" name={['params', 'selectedGraph']}>
        <Input />
      </Form.Item>
      <Form.Item label={t('global:name')} name="name">
        <Input autoFocus placeholder={t(companyDashboardItemPreferenceSelection?.translation || '')} />
      </Form.Item>
    </>
  )
}

export default DashboardItemFields
