import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createSessionAccountMapRule,
  deleteSessionAccountMapRule,
  getSessionAccountMapRulesRequest,
  updateSessionAccountMapRule
} from '../../../../redux/session/accountMapRules/actions'
import { sessionContractIdSelector, sessionContractSelector } from '../../../../redux/session/contract/selectors'
import {
  sessionAccountMapRulesByStatementRowIdSelector,
  sessionAccountMapRulesLoadingSelector
} from '../../../../redux/session/accountMapRules/selectors'
import { CompanyForm, Country } from '../../../../types/company/Company'
import DefaultAccountMap from './DefaultAccountMap'
import { DefaultAccountMapRule } from '../../../../types/accountMapRule/AccountMapRule'
import { AppDispatch } from '../../../../redux/store'

const AccountantAccountMap: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const { companyId } = useSelector(sessionContractSelector)
  const contractId = useSelector(sessionContractIdSelector)
  const loading = useSelector(sessionAccountMapRulesLoadingSelector)
  const accountMapRulesByStatementRowId = useSelector(sessionAccountMapRulesByStatementRowIdSelector) as _.Dictionary<
    DefaultAccountMapRule[]
  >
  const [cascaderValues, setCascaderValues] = useState<[Country, CompanyForm]>([Country.fi, CompanyForm.limited])

  useEffect(() => {
    const [country, companyForm] = cascaderValues as [Country, CompanyForm]
    dispatch(getSessionAccountMapRulesRequest(contractId, country, companyForm))
  }, [contractId, companyId, cascaderValues, dispatch])

  const handleCascader = (e: any) => {
    setCascaderValues(e)
  }

  return (
    <DefaultAccountMap
      accountMapRulesByStatementRowId={accountMapRulesByStatementRowId}
      loading={loading}
      createAccountMapRule={(e: any) => {
        return createSessionAccountMapRule(contractId, cascaderValues[0], cascaderValues[1], e)
      }}
      updateAccountMapRule={(e: any) => {
        return updateSessionAccountMapRule(contractId, cascaderValues[0], cascaderValues[1], e)
      }}
      deleteAccountMapRule={(e: any) => {
        return deleteSessionAccountMapRule(contractId, cascaderValues[0], cascaderValues[1], e)
      }}
      handleAccountantCascaderAction={handleCascader}
    />
  )
}

export default AccountantAccountMap
