import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

type LoadingWrapperProps = {
  loading: boolean
  children: React.ReactNode
}

const LoadingWrapper = ({ children, loading }: LoadingWrapperProps) => {
  return (
    <Spin
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      {children}
    </Spin>
  )
}

export default LoadingWrapper
