import React from 'react'
import { useLocation } from 'react-router'
import ContextCompanySelect from './ContextCompanySelect'
import HeaderInfo from './HeaderInfo'
import { Route } from '../../routes/Route'
import { getDisabledContextdrawerSectionRoutes } from '../ContextDrawer/utils'

interface HeaderContentProps {
  routes: Route['routes']
}

const HeaderContent: React.FC<HeaderContentProps> = ({ routes }: HeaderContentProps) => {
  const location = useLocation()
  const disabledContextdrawPages = getDisabledContextdrawerSectionRoutes(routes)

  const hideName = () => {
    return disabledContextdrawPages?.find(page => page.path === location.pathname)?.disabledContextdrawerSections
  }

  return (
    <>
      <ContextCompanySelect width={260} />
      {!hideName() && <HeaderInfo />}
    </>
  )
}

export default HeaderContent
