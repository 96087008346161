import { Industry } from '../../../types/industry/Industry'
import { SetIndustries, SET_INDUSTRIES } from './types'

const initialState: Industry[] = []

const industryReducer = (state = initialState, action: SetIndustries) => {
  switch (action.type) {
    case SET_INDUSTRIES:
      return action.payload
    default:
      return state
  }
}

export default industryReducer
