import React from 'react'
import { Row, Col, Button, Input, Select, DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { TaskApplication, TaskType, TaskStatus } from '../types'

interface Props {
  request: (params?: any) => void
}

interface IntegrationTaskFormFields {
  companyId: string
  application: string[]
  type: string[]
  status: string[]
  after: dayjs.Dayjs | null
  before: dayjs.Dayjs | null
}

export const IntegrationTaskSearch: React.FC<Props> = ({ request }: Props) => {
  return (
    <Form<IntegrationTaskFormFields>
      onFinish={values => {
        console.log(values)
        request(values)
      }}
      initialValues={{
        after: dayjs().subtract(14, 'days')
      }}
    >
      <Row gutter={24}>
        <Col span={8} key="company-id" style={{ display: 'block' }}>
          <Form.Item label="Company ID" name="companyId">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} key="application" style={{ display: 'block' }}>
          <Form.Item label="Application" name="application">
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {Object.keys(TaskApplication).map(key => (
                <Select.Option value={key} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key="type" style={{ display: 'block' }}>
          <Form.Item label="Type" name="type">
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {Object.keys(TaskType).map(key => (
                <Select.Option value={key} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key="status" style={{ display: 'block' }}>
          <Form.Item label="Status" name="status">
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {Object.keys(TaskStatus).map(key => (
                <Select.Option value={key} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key="after" style={{ display: 'block' }}>
          <Form.Item label="After" name="after">
            <DatePicker style={{ width: '100%' }} showTime />
          </Form.Item>
        </Col>
        <Col span={8} key="before" style={{ display: 'block' }}>
          <Form.Item label="Before" name="before">
            <DatePicker style={{ width: '100%' }} showTime />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
