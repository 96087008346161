import { Language } from '../../types/user/User'
import { AppState, AppStateActionTypes, CHANGE_LANGUAGE } from './types'

const initialState: AppState = {
  language: Language.fi
}

const appReducer = (state = initialState, action: AppStateActionTypes) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }

    default:
      return state
  }
}

export default appReducer
