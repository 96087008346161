import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import VehicleBasedIntegrationsVehicles from './VehicleBasedIntegrationsVehicles'
import VehicleBasedIntegrationsApi from './VehicleBasedIntegrationsApi'
import { useDocsURL } from '../../../../../../utils/docs'

interface VehicleBasedIntegrationsProps {
  allowAuthentication: boolean
  credentialsRequestUrl: string
  getVehiclesUrl: string
  updateVehicleUrl: string
  name: string
  pageDocsUrl?: string
}

const VehicleBasedIntegrations = ({
  allowAuthentication,
  credentialsRequestUrl,
  getVehiclesUrl,
  updateVehicleUrl,
  pageDocsUrl,
  name
}: VehicleBasedIntegrationsProps) => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: (
        <VehicleBasedIntegrationsApi
          allowAuthentication={allowAuthentication}
          credentialsRequestUrl={credentialsRequestUrl}
          pageDocsUrl={pageDocsUrl}
          name={name}
        />
      )
    },
    {
      label: t('integrationsPage:vehicles'),
      key: 'vehicles',
      children: <VehicleBasedIntegrationsVehicles getVehiclesUrl={getVehiclesUrl} updateVehicleUrl={updateVehicleUrl} />
    }
  ]

  return (
    <>
      <a href={`${docsURL}${pageDocsUrl}`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default VehicleBasedIntegrations
