import { Button, Space } from 'antd'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toggleAllFinancialStatementsBudgetInEditAction } from '../../../../../redux/pages/budgeting/financialStatements/actions'
import { AppDispatch } from '../../../../../redux/store'
import { BudgetingContext, BudgetingContextType } from './BudgetingProvider'
import { financialStatementsPageSelector } from '../../../../../redux/pages/budgeting/financialStatements/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'

interface BudgetingMenuButtonsProps {
  editingBudget: boolean
  setEditingBudget: (val: boolean) => void
}

const BudgetingMenuButtons = ({ editingBudget, setEditingBudget }: BudgetingMenuButtonsProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { handleSaveAll } = useContext(BudgetingContext) as BudgetingContextType
  const { budgetsInEdit } = useSelector(financialStatementsPageSelector)
  const { dimensions } = useSelector(filtersSelector)
  const [loading, setLoading] = useState(false)
  if (dimensions && dimensions?.length > 1) return null

  const load = async () => {
    setLoading(true)
    setTimeout(() => {
      if (editingBudget) dispatch(toggleAllFinancialStatementsBudgetInEditAction())
      setEditingBudget(!editingBudget)
      setLoading(false)
    }, 100)
  }

  return (
    <Space>
      <Button loading={loading} onClick={() => load()}>
        {!editingBudget ? t('financialStatementsPage:enableBudgeting') : t('financialStatementsPage:disableBudgeting')}
      </Button>
      <Button hidden={!Object.values(budgetsInEdit).some(b => b)} onClick={() => handleSaveAll()}>
        {t('financialStatementsPage:save-all-unsaved-budgets')}
      </Button>
    </Space>
  )
}

export default BudgetingMenuButtons
