import { Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestError } from '../../redux/error/types'

interface ErrorComponentProps {
  error?: RequestError
}

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation()
  return (
    <Result
      status="500"
      title={t(`errorMessage:${error?.message || 'DEFAULT'}`, `Error ${error?.code}`)}
      subTitle={t(`errorDescription:${error?.message}`, '')}
    />
  )
}

export default ErrorComponent
