import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { notificationAction } from '../../../redux/middleware/actions'
import { useBackend } from '../../../services/backend'
import { Voucher, VoucherQueryParams, VoucherQueryResult } from './types'
import { VoucherSearch } from './VoucherSearch'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import VoucherTable from '../../../components/Voucher/VoucherTable'
import { getRequestParams } from '../../../components/Voucher/utils'
import { AppDispatch } from '../../../redux/store'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import VoucherForm from './components/VoucherForm'
import { TaskApplication } from '../../superuser/types'
import Authorized from '../../../components/Authorized/Authorized'
import { currentFiscalYearSelector } from '../../../redux/context/fiscalYears/selectors'

const Vouchers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const vouchersRequest = useBackend(`/reporting/vouchers`, process.env.REACT_APP_INTEGRATION_URL)
  const xlsxRequest = useBackend(`/reporting/vouchers/xlsx`, process.env.REACT_APP_INTEGRATION_URL)
  const voucherCreateUrl = useBackend('/accounting/vouchers', process.env.REACT_APP_INTEGRATION_URL)
  const voucherEditUrl = useBackend('/accounting/vouchers/{id}', process.env.REACT_APP_INTEGRATION_URL)
  const [voucherQueryResult, setVoucherQueryResult] = useState<Partial<VoucherQueryResult>>({})
  const currentFiscalYear = useSelector(currentFiscalYearSelector)

  const { loading } = vouchersRequest
  const [voucher, setVoucher] = useState<Voucher | null>(null)
  const [params, setParams] = useState<Partial<VoucherQueryParams>>({
    pageSize: 20,
    from: dayjs(currentFiscalYear?.startDate),
    until: dayjs(currentFiscalYear?.endDate)
  })
  const [open, setOpen] = useState(false)

  const request = (p: Partial<VoucherQueryParams>) => {
    const requestParams = getRequestParams(p)
    vouchersRequest
      .get({ body: { params: { params: requestParams, companyId } } })
      .then(setVoucherQueryResult)
      .catch((e: Error) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(() => {
    request(params)
  }, [params, companyId])

  const xlsx = (p: Partial<VoucherQueryParams>) => {
    /**
     * AMISKIKKA
     * Joo tämmönen säätö piti tehä, muuten tulee korruptoitunu tiedosto
     * Eli bäkki kääntää bufferin base64 ja tää sit siitä takasi
     */
    const base64ToArrayBuffer = (base64: string) => {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    }
    const requestParams = getRequestParams(p)
    xlsxRequest
      .get({ body: { params: { params: requestParams, companyId } } })
      .then((response: BlobPart) => {
        const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(response as string)]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transactions.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
      })
  }

  const handleEdit = (value: Voucher) => {
    setVoucher(value)
    setOpen(true)
  }

  const handleClose = () => {
    setVoucher(null)
    setOpen(false)
  }

  const handleDelete = async ({ id }: Voucher) => {
    await voucherEditUrl.delete({
      urlParams: { id },
      body: {
        params: {
          companyId
        }
      }
    })
    request(params)
  }

  const onCreate = async (values: FormData) => {
    await voucherCreateUrl.post({
      body: {
        params: {
          companyId
        },
        data: values
      }
    })

    request(params)
    setVoucher(null)
    setOpen(false)
  }

  const onUpdate = async (values: FormData) => {
    await voucherEditUrl.put({
      urlParams: { id: values.get('id') },
      body: {
        params: {
          companyId
        },
        data: values
      }
    })
    request(params)
    setVoucher(null)
    setOpen(false)
  }

  return (
    <PageHeaderWrapper>
      <VoucherForm
        loading={voucherCreateUrl.loading || voucherEditUrl.loading}
        voucher={voucher}
        open={open}
        onCreate={onCreate}
        onUpdate={onUpdate}
        handleClose={handleClose}
      />
      <Flex gap={32} vertical>
        <VoucherSearch params={params} setParams={setParams} />
        <Card>
          <Flex justify="end" style={{ paddingBlock: 16 }}>
            <Space>
              <Authorized authority={{ accountingSoftware: TaskApplication.FINADECK }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)}>
                  {t('vouchersPage:createVoucher')}
                </Button>
              </Authorized>
              <Button
                type="default"
                icon={<DownloadOutlined />}
                loading={xlsxRequest.loading}
                onClick={() => xlsx(params)}
              >
                Excel
              </Button>
            </Space>
          </Flex>
          <VoucherTable
            showActions
            loading={loading}
            dataSource={voucherQueryResult}
            params={params}
            editVoucher={handleEdit}
            deleteVoucher={handleDelete}
            setParams={setParams}
          />
        </Card>
      </Flex>
    </PageHeaderWrapper>
  )
}

export default Vouchers
