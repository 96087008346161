import React, { useState, useEffect } from 'react'
import { Descriptions } from 'antd'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { notificationAction } from '../../../redux/middleware/actions'
import { useBackend } from '../../../services/backend'
import { AppDispatch } from '../../../redux/store'

interface ServerStatus {
  name: string
  version: string
  hostname: string
  time: Date
}

export const IntegrationServer: React.FC = () => {
  const statusRequest = useBackend('/status', process.env.REACT_APP_INTEGRATION_URL)
  const [status, setStatus] = useState<ServerStatus>()

  const dispatch: AppDispatch = useDispatch()

  const request = () => {
    statusRequest
      .get({})
      .then(setStatus)
      .catch((e: Error) =>
        dispatch(
          notificationAction({
            type: 'error',
            message: 'INTEGRATION_SERVER_NOT_AVAILABLE',
            description: e.message
          })
        )
      )
  }

  useEffect(request, [])

  return (
    <Descriptions size="small" column={4}>
      <Descriptions.Item label="Name">{status && status.name}</Descriptions.Item>
      <Descriptions.Item label="Version">{status && status.version}</Descriptions.Item>
      <Descriptions.Item label="Hostname">{status && status.hostname}</Descriptions.Item>
      <Descriptions.Item label="Time">{status && dayjs(status.time).format('YYYY-MM-DD hh:mm:ss')}</Descriptions.Item>
    </Descriptions>
  )
}
