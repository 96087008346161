import React, { useRef, useState } from 'react'
import { Button, Form, Input, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { RuleObject } from 'antd/lib/form'
import { User } from '../../../../types/user/User'
import { useTableProps } from '../../../../utils/hooks'
import { Company } from '../../../../types/company/Company'
import { useBackend } from '../../../../services/backend'
import { useColumnSearchProps } from '../../../../components/Table/useColumSearchProps'

interface SuperUserUserProfileProps {
  user: User
}

const SuperUserUserCompanies: React.FC<SuperUserUserProfileProps> = ({ user }) => {
  const [companies, setCompanies] = useState<Company[]>(user.companies)
  const { t } = useTranslation()
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)
  const [form] = Form.useForm<Company>()

  const companySearchRequest = useBackend('/api/companies')
  const addCompanyRequest = useBackend('/api/superuser/users/{id}/companies')
  const removeCompanyRequest = useBackend('/api/superuser/users/{id}/companies/{companyId}')

  const addCompany = (company: Company) => {
    addCompanyRequest
      .post({ urlParams: { id: user.id }, body: { data: company } })
      .then((c: Company) => setCompanies([c, ...companies]))
  }

  const removeCompany = (company: Company) => {
    removeCompanyRequest
      .delete({ urlParams: { id: user.id, companyId: company.id } })
      .then(() => setCompanies(companies.filter(c => c.id !== company.id)))
  }

  const isValidCompany = async (__: RuleObject, vatId: string) => {
    const trimmedVatId = vatId.trim()

    const [company] = await companySearchRequest.get({ body: { params: { vatId: trimmedVatId } } })

    if (company) {
      form.setFieldsValue(company)
      return company
    }
    return Promise.reject(new Error('Y-tunnus ei kelpaa'))
  }

  const columns: ColumnProps<Company>[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.id || '').localeCompare(b?.id || '') || 0,
      ...useColumnSearchProps('id')
    },
    {
      title: 'Nimi',
      key: 'name',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.name || '').localeCompare(b?.name || '') || 0,
      ...useColumnSearchProps('name')
    },
    {
      title: 'Toiminnot',
      key: 'actions',
      render: (company: Company) => {
        return (
          <Button type="link" onClick={() => removeCompany(company)}>
            Poista
          </Button>
        )
      }
    }
  ]

  return (
    <>
      <Form
        name="company"
        form={form}
        onFinish={() => {
          form
            .validateFields()
            .then(values => {
              addCompany(values)
              form.resetFields()
            })
            .catch(info => {
              console.log('Validate Failed:', info)
            })
        }}
        layout="vertical"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t('global:required-field')
            },
            {
              validator: isValidCompany
            }
          ]}
          hasFeedback
          name="id"
          label="Y-tunnus"
        >
          <Input />
        </Form.Item>
        <Form.Item name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Lisää
          </Button>
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        rowKey={record => record.id.toString()}
        size="small"
        dataSource={companies}
        columns={columns}
      />
    </>
  )
}

export default SuperUserUserCompanies
