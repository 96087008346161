import React from 'react'
import _ from 'lodash'
import { Select, Button, Form } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { industriesSelector } from '../../../redux/entities/industries/selectors'
import { Industry } from '../../../types/industry/Industry'
import { industryComparisonLoadingSelector } from '../../../redux/context/industryComparison/selectors'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'

export interface IndustryFormValues {
  industryId: string
  revenueFrom: number
  revenueTo: number
}

interface IndustryFormProps {
  submitText?: string
  industryInitialValue?: number
  handleSubmit: (values: IndustryFormValues) => void
}

const IndustryForm = ({ submitText, handleSubmit, industryInitialValue }: IndustryFormProps) => {
  const { t } = useTranslation()
  const industryComparisonLoading = useSelector(industryComparisonLoadingSelector)
  const industries = useSelector(industriesSelector)
  const turnOverInputStyle = { display: 'inline-block', width: 'calc(50% - 12px)' }

  const industriesSelectOptions = () => {
    return Object.entries(_.groupBy(industries, 'indystryRootId')).map(([rootCode, groupedIndustries]) => (
      <Select.OptGroup key={rootCode} label={`${rootCode} - ${t(`tol2008:${rootCode}`)}`}>
        {groupedIndustries.map((industry: Industry) => (
          <Select.Option key={industry.id} value={industry.id}>
            {t(`tol2008:${industry.id}`)}
          </Select.Option>
        ))}
      </Select.OptGroup>
    ))
  }

  return (
    <Form<IndustryFormValues>
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ industryId: industryInitialValue }}
    >
      <Form.Item name="industryId" label={t('comparisonPage:industry')}>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            const localeString = t(`tol2008:${option?.key}`, '')
            return localeString ? localeString.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
          }}
        >
          {industriesSelectOptions()}
        </Select>
      </Form.Item>
      <Form.Item label={`${t('comparisonPage:revenue')} €`}>
        <Form.Item name="revenueFrom" style={turnOverInputStyle}>
          <FormattedInputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>-</span>
        <Form.Item name="revenueTo" style={turnOverInputStyle}>
          <FormattedInputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Button loading={industryComparisonLoading} block type="primary" htmlType="submit">
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default IndustryForm
