import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import { useDocsURL } from '../../../../../utils/docs'

const Lemonsoft: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <a href={`${docsURL}manual/businesses/settings/company#sie`} target="_blank" rel="noopener noreferrer">
          {t('header:manual')}
        </a>
      </div>
      <FileUpload
        actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/sie/uploads/${companyId}`}
        uploadText={t('integrationsPage:sie-info')}
        taskRequestUrl="/sie/tasks/{companyId}"
        successDescription="IMPORT_SUCCESS_EMAIL"
      />
    </>
  )
}

export default Lemonsoft
