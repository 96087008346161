import { GetRequestAction, GET, INTEGRATION_REQUEST } from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import { SetCustomerGroupsAction, SET_CUSTOMER_GROUPS } from './types'
import { CustomerGroup } from '../../../types/customer/customer'

export const setCustomerGroups = (customerGroups: CustomerGroup[]): SetCustomerGroupsAction => ({
  type: SET_CUSTOMER_GROUPS,
  payload: customerGroups
})

export const getCustomerGroupsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: INTEGRATION_REQUEST,
  payload: {
    method: GET,
    params: { companyId },
    url: `/reporting/customers/groups/accounting`,
    success: setCustomerGroups
  },
  meta: {
    type: 'CUSTOMER_GROUPS'
  }
})
