/* eslint-disable no-debugger */
/* eslint-disable import/no-cycle */
import dayjs from 'dayjs'
import { CustomReportSortOrder } from '../../../pages/reporting/custom/components/SortButton'
import {
  ADD_CUSTOM_REPORT_SECTION,
  ADD_CUSTOM_REPORT,
  ADD_SECTION_VARIABLE_EXPLAINER,
  ADD_SECTION_VARIABLE,
  CustomReport,
  CustomReportsActionTypes,
  CustomReportStore,
  DELETE_CUSTOM_REPORT_SECTION,
  DELETE_CUSTOM_REPORT_VARIABLE,
  DELETE_CUSTOM_REPORT,
  EDIT_CUSTOM_REPORT_SECTION_STYLE,
  EDIT_CUSTOM_REPORT_SECTION,
  ORDER_COLUMN_ASCENDING,
  ORDER_COLUMN_DEFAULT,
  ORDER_COLUMN_DESCENDING,
  RESET_CUSTOM_REPORT_ORDER,
  SET_CALCULATED_REPORT,
  SET_CUSTOM_REPORT,
  SET_CUSTOM_REPORTS,
  SET_DATE,
  SET_SECTION_DATA,
  SET_SECTION_ORDER,
  SET_VARIABLE_ORDER,
  UPDATE_CUSTOM_REPORT,
  UPDATE_SECTION_VARIABLE,
  SET_QUICK_FILTERS,
  EMPTY_QUICK_FILTERS,
  SET_ALL_SECTION_DATA,
  SET_SECTIONS_DATA,
  SET_CUSTOM_REPORT_GROUPS,
  DELETE_CUSTOM_REPORT_GROUP,
  UPDATE_CUSTOM_REPORT_GROUP,
  ADD_CUSTOM_REPORT_GROUP,
  UPDATE_CUSTOM_REPORT_GROUPS,
  UPDATE_CUSTOM_REPORTS,
  UPDATE_SECTIONS
} from './types'
import { CustomReportCategory } from './typesCategory'
import { CustomReportTableSection, CustomReportChartSection } from './typesSection'
import { CustomReportVariable } from './typesVariable'

const initialState: CustomReportStore = {
  date: dayjs().endOf('month').toString(),
  reports: [],
  reportGroups: [],
  calculated: null,
  sort: {},
  quickFilters: undefined
}

const recursiveDefaultUpdate = (variable: CustomReportVariable, row: CustomReportVariable) => {
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          return a.id?.toString().localeCompare(b.id?.toString() ?? '') ?? 0
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row.id?.toString().startsWith(c.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}

const recursiveAscendingUpdate = (
  variable: CustomReportVariable,
  row: CustomReportVariable,
  column: CustomReportCategory
) => {
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          const aValue = a?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          const bValue = b?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          return aValue - bValue
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row.id?.toString().startsWith(c.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}
const recursiveDescendingUpdate = (
  variable: CustomReportVariable,
  row: CustomReportVariable,
  column: CustomReportCategory
) => {
  if (!row.id) return variable
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          const aValue = a?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          const bValue = b?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          return bValue - aValue
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row?.id?.toString().startsWith(c?.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}

const customReportsReducer = (state = initialState, action: CustomReportsActionTypes) => {
  switch (action.type) {
    case SET_CUSTOM_REPORTS:
      return {
        ...state,
        reports: action.payload
      }
    case SET_CUSTOM_REPORT_GROUPS:
      return {
        ...state,
        reportGroups: action.payload
      }
    case ADD_CUSTOM_REPORT:
      return {
        ...state,
        // calculated: action.payload,
        reports: [...state.reports, action.payload]
      }
    case ADD_CUSTOM_REPORT_GROUP:
      return {
        ...state,
        reportGroups: [...state.reportGroups, action.payload]
      }
    case SET_CUSTOM_REPORT:
      return {
        ...state,
        reports: state.reports.map(report => {
          if (report.id !== action.payload.id) {
            return report
          }
          return { ...report, ...action.payload }
        })
      }
    case UPDATE_CUSTOM_REPORT:
      return {
        ...state,
        reports: state.reports.map(report => {
          if (report.id !== action.payload.id) {
            return report
          }
          return { ...report, ...action.payload }
        }),
        calculated: { ...state.calculated, ...action.payload }
      }
    case UPDATE_CUSTOM_REPORT_GROUP:
      return {
        ...state,
        reportGroups: state.reportGroups.map(report => {
          if (report.id !== action.payload.id) {
            return report
          }
          return { ...report, name: action.payload.name }
        })
      }
    case UPDATE_CUSTOM_REPORT_GROUPS:
      return {
        ...state,
        reportGroups: state.reportGroups.map(report => {
          const updateReport = action.payload?.find(cr => cr.id === report.id)
          if (!updateReport) {
            return report
          }
          return { ...report, ...updateReport }
        })
      }
    case UPDATE_CUSTOM_REPORTS:
      return {
        ...state,
        reports: state.reports.map(report => {
          const updateReport = action.payload?.find(cr => cr.id === report.id)
          if (!updateReport) {
            return report
          }
          return { ...report, ...updateReport }
        })
      }
    case DELETE_CUSTOM_REPORT:
      return {
        ...state,
        reports: state.reports.filter(report => report.id !== action.payload)
      }
    case DELETE_CUSTOM_REPORT_GROUP:
      return {
        ...state,
        reportGroups: state.reportGroups.filter(report => report.id !== action.payload)
      }
    case DELETE_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.filter(s => s.id !== action.payload.sectionId)
        },
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.filter(s => s.id !== action.payload.sectionId)
            }
          }
          return report
        })
      }
    case ADD_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: [...(state.calculated?.sections || []), action.payload]
        },
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections ? [...report.sections, action.payload] : [action.payload]
            }
          }
          return report
        })
      }
    case EDIT_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.map(item =>
                item.id !== action.payload.id ? item : { ...item, ...action.payload }
              )
            }
          }
          return report
        }),
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(section => {
            if (section.id === action.payload.id) return { ...section, ...action.payload }
            return section
          })
        }
      }
    case EDIT_CUSTOM_REPORT_SECTION_STYLE:
      return {
        ...state,
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.map(item =>
                item.id !== action.payload.id
                  ? item
                  : {
                      ...item,
                      style: {
                        ...(item as CustomReportTableSection | CustomReportChartSection).style,
                        type: action.payload?.style?.type || 'column',
                        switchRowsAndColumns: action.payload?.style?.switchRowsAndColumns || undefined,
                        showDataLabels: action.payload?.style?.showDataLabels || undefined
                      },
                      variables: (item as CustomReportTableSection | CustomReportChartSection).variables
                        ?.map(variable => {
                          const data = action.payload?.variables?.find(f => f.id === variable.id) || {}
                          return { ...variable, ...data }
                        })
                        .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)),
                      categories: action.payload?.categories,
                      title: action.payload?.title,
                      type: action.payload?.type
                    }
              )
            }
          }
          return report
        }),
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(item =>
            item.id !== action.payload.id
              ? item
              : {
                  ...item,
                  style: {
                    ...(item as CustomReportTableSection | CustomReportChartSection).style,
                    type: action.payload?.style?.type || 'column',
                    switchRowsAndColumns: action.payload?.style?.switchRowsAndColumns || undefined,
                    showDataLabels: action.payload?.style?.showDataLabels || undefined
                  },
                  variables: (item as CustomReportTableSection | CustomReportChartSection).variables
                    ?.map(variable => {
                      const data = action.payload?.variables?.find(f => f.id === variable.id) || {}
                      return { ...variable, ...data }
                    })
                    .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)),
                  categories: action.payload?.categories,
                  title: action.payload?.title,
                  type: action.payload?.type
                }
          )
        }
      }
    case DELETE_CUSTOM_REPORT_VARIABLE:
      return {
        ...state,
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.map(section =>
                section.id !== action.payload.sectionId
                  ? section
                  : {
                      ...section,
                      variables: (section as CustomReportTableSection | CustomReportChartSection).variables.filter(
                        variable => variable.id !== action.payload.id
                      )
                    }
              )
            }
          }
          return report
        }),
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.filter(
                  variable => variable.id !== action.payload.id
                )
              }
            }
            return section
          })
        }
      }
    case SET_CALCULATED_REPORT:
      return {
        ...state,
        calculated: action.payload
      }
    case SET_SECTION_ORDER:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: action.payload.sections.map(section => {
            return state?.calculated?.sections?.find(rc => section.id === rc.id)
          })
        },
        reports: state.reports?.map((report: CustomReport) => {
          if (report.id === action.payload.id) {
            return {
              ...report,
              sections: action.payload.sections.map(section => {
                return report?.sections?.find(rc => section.id === rc.id)
              })
            }
          }
          return report
        })
      }
    case SET_SECTION_DATA:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.id) return action.payload
            return section
          })
        }
      }
    case SET_ALL_SECTION_DATA:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections:
            (action.payload && action.payload?.[0]?.reportId === state.calculated?.id && action.payload) ||
            state.calculated?.sections
        }
      }
    case SET_SECTIONS_DATA:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: [
            ...(state.calculated?.sections.filter(s => s.reportId === state.calculated?.id) || []),
            ...action.payload.filter(s => s.reportId === state.calculated?.id)
          ]
        }
      }
    case UPDATE_SECTIONS:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(section => {
            const found = action.payload.find(s => s.id === section.id)
            return found || section
          })
        }
      }

    case ADD_SECTION_VARIABLE:
      return {
        ...state,
        reports: state.reports?.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.map(section =>
                section.id !== action.payload.variable[0].sectionId
                  ? section
                  : {
                      ...section,
                      variables: [
                        ...((section as CustomReportTableSection | CustomReportChartSection)?.variables || []),
                        ...action.payload.variable
                      ]
                    }
              )
            }
          }
          return report
        }),
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.variable[0].sectionId) {
              return {
                ...section,
                variables: [
                  ...((section as CustomReportTableSection | CustomReportChartSection)?.variables || []),
                  ...action.payload.variable
                ]
              }
            }
            return section
          })
        }
      }
    case ADD_SECTION_VARIABLE_EXPLAINER:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.variable.sectionId) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  if (variable.id === action.payload.variable.id)
                    return { ...variable, children: action.payload.explainer }
                  return variable
                })
              }
            }
            return section
          })
        }
      }
    case UPDATE_SECTION_VARIABLE:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  if (variable.id === action.payload.id) return { ...variable, ...action.payload }
                  return variable
                })
              }
            }
            return section
          })
        }
      }
    case ORDER_COLUMN_ASCENDING:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveAscendingUpdate(variable, action.payload.row, action.payload.column)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.ascending
            }
          }
        }
      }
    case ORDER_COLUMN_DESCENDING:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveDescendingUpdate(variable, action.payload.row, action.payload.column)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.descending
            }
          }
        }
      }
    case ORDER_COLUMN_DEFAULT:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveDefaultUpdate(variable, action.payload.row)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.default
            }
          }
        }
      }
    case RESET_CUSTOM_REPORT_ORDER:
      return {
        ...state,
        sort: {}
      }
    case SET_VARIABLE_ORDER:
      return {
        ...state,
        reports: state.reports.map((report: CustomReport) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              sections: report.sections?.map(section => {
                return section.id !== action.payload.sectionId
                  ? section
                  : {
                      ...section,
                      variables: action.payload.order.map(o => {
                        return {
                          ...(section as CustomReportTableSection | CustomReportChartSection).variables.find(
                            variable => variable.id === o.id
                          ),
                          order: o.order
                        }
                      })
                    }
              })
            }
          }
          return report
        }),
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(s => {
            if (s.id === action.payload.sectionId) {
              return {
                ...s,
                variables: action.payload.order.map(o => {
                  return {
                    ...(s as CustomReportTableSection | CustomReportChartSection).variables.find(
                      variable => variable.id === o.id
                    ),
                    order: o.order
                  }
                })
              }
            }
            return s
          })
        }
      }
    case SET_DATE:
      return {
        ...state,
        date: action.payload
      }
    case SET_QUICK_FILTERS:
      return {
        ...state,
        quickFilters: action.payload
      }
    case EMPTY_QUICK_FILTERS:
      return {
        ...state,
        quickFilters: undefined
      }
    default:
      return state
  }
}

export default customReportsReducer
