import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../redux/middleware/actions'
import { useBackend } from '../../../../services/backend'
import { AppDispatch } from '../../../../redux/store'

interface DataUpdateProps {
  page: string
  initial?: boolean
  notAuthorized?: boolean
}

enum RequestType {
  initial = 'initial',
  daily = 'daily'
}

const DataUpdate: React.FC<DataUpdateProps> = ({ page, initial, notAuthorized }: DataUpdateProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const [taskStatus, setTaskStatus] = useState(null)
  const companyId = useSelector(contextCompanyIdSelector)
  const getDataDaily = useBackend(`/${page}/daily/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const getDataInitial = useBackend(`/${page}/initial/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const getTasksRequest = useBackend(`/tasks`, process.env.REACT_APP_INTEGRATION_URL)

  const getTasks = () => {
    getTasksRequest
      .get({
        body: {
          params: {
            companyId,
            application: [page.toUpperCase()],
            status: ['RUNNING', 'PENDING'],
            after: dayjs().subtract(1, 'days')
          }
        }
      })
      .then((response: any) => {
        setTaskStatus(response?.[0]?.status || null)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(getTasks, [page])

  const getDataAction = (type: RequestType) => {
    const request = type === RequestType.initial ? getDataInitial : getDataDaily
    request
      .post({
        urlParams: { companyId }
      })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: `${type.toUpperCase()}_DATA_OK`
          })
        )
        getTasks()
      })
      .catch((err: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: `${type.toUpperCase()}_DATA_NOT_OK`,
            description: err.message
          })
        )
      })
  }

  return (
    <>
      {initial && (
        <Button
          disabled={!!(taskStatus === 'RUNNING' || taskStatus === 'PENDING')}
          loading={getDataInitial.loading}
          onClick={() => getDataAction(RequestType.initial)}
          type="primary"
        >
          {taskStatus === 'RUNNING' || taskStatus === 'PENDING'
            ? t('global:dataFetchInProgress')
            : t('integrationsPage:authenticate')}
        </Button>
      )}
      {!notAuthorized && (
        <Button
          disabled={!!(taskStatus === 'RUNNING' || taskStatus === 'PENDING')}
          loading={getDataDaily.loading}
          onClick={() => getDataAction(RequestType.daily)}
        >
          {taskStatus === 'RUNNING' || taskStatus === 'PENDING'
            ? t('global:dataFetchInProgress')
            : t('global:update_fiscalyears')}
        </Button>
      )}
    </>
  )
}

export default DataUpdate
