import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocsURL } from '../../../../../../utils/docs'
import TuloposApi from './TuloposApi'
import DimensionMap from '../dimensionMap/DimensionMap'

const Tulopos: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    { label: t('integrationsPage:api'), key: 'api', children: <TuloposApi /> },
    {
      label: t('integrationsPage:restaurants'),
      key: 'restaurants',
      children: (
        <DimensionMap
          getDimensionsUrl="/tulopos/restaurants/{companyId}"
          updateDimensionsUrl="/tulopos/restaurants/{companyId}/{id}"
          dataKey="unit"
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#tulopos`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Tulopos
