import { SET_LOAN_REPAYMENT_METHODS, LoanRepaymentMethod } from './types'
import { GetRequestAction, REQUEST, GET } from '../../middleware/types'

export const setLoanRepaymentMethods = (loanRepaymentMethods: LoanRepaymentMethod[]) => ({
  type: SET_LOAN_REPAYMENT_METHODS,
  payload: loanRepaymentMethods
})

export const getLoanRepaymentMethods = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: `/api/loan-repayment-methods`,
    success: (payload: any[]) => setLoanRepaymentMethods(payload)
  },
  meta: {
    type: 'KEY_FIGURES'
  }
})
