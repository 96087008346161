/* eslint-disable no-underscore-dangle */
import { CloseOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Result, Select, Space, TreeSelect, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { RcFile, UploadFile } from 'antd/es/upload'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import { notificationAction } from '../../redux/middleware/actions'
import { userCompaniesSelector } from '../../redux/session/currentUser/selectors'
import { useBackend } from '../../services/backend'
import { BudgetingScenario } from '../../types/budgetingScenario/BudgetingScenario'
import { Company } from '../../types/company/Company'
import { Dimension } from '../../types/dimension/Dimension'
import {
  CreateServicedeskRequestBody,
  JiraIssueComponent,
  JiraServicedeskRequestFormFields,
  JiraServicedeskRequestType,
  ServiceDeskResponse
} from '../../types/jira/jira'
import { useAuthority } from '../../utils/Authorizable/authorize'
import { getDimensionTree } from '../Dimension/utils'
import { AppDispatch } from '../../redux/store'

interface JiraRequestModalProps {
  open: boolean
  onCreate: () => void
  onCancel: () => void
}

const { REACT_APP_JIRA_COMPANY_FIELD, REACT_APP_JIRA_DIMENSION_FIELD, REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD } =
  process.env

export const JiraRequestModal: React.FC<JiraRequestModalProps> = ({ open, onCreate, onCancel }) => {
  const dispatch: AppDispatch = useDispatch()

  const [form] = useForm<JiraServicedeskRequestFormFields>()
  const companies = useSelector(userCompaniesSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const { t } = useTranslation()
  const requestTypesRequest = useBackend('/jira/request-types', process.env.REACT_APP_INTEGRATION_URL)
  const requestComponentsRequest = useBackend('/jira/components', process.env.REACT_APP_INTEGRATION_URL)
  const scenarioRequest = useBackend('/api/companies/{companyId}/budgeting/budgeting-scenarios')
  const forecastRequest = useBackend('/api/companies/{companyId}/budgeting/forecasts')
  const dimensionRequest = useBackend('/api/companies/{companyId}/accounting/dimensions')
  const requestRequest = useBackend('/jira/request', process.env.REACT_APP_INTEGRATION_URL)
  const companySearchRequest = useBackend('/api/companies')
  const [files, setFiles] = useState<UploadFile[]>([])
  const [requestTypes, setRequestTypes] = useState<JiraServicedeskRequestType[]>([])
  const [requestComponents, setRequestComponents] = useState<JiraIssueComponent[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [budgetingScenariosAndForecasts, setBudgetingScenariosAndForecasts] = useState<BudgetingScenario[]>([])
  const [dimensions, setDimensions] = useState<Dimension[]>([])
  const isSuperUser = useAuthority({
    role: 'superuser'
  })
  const companyValue = Form.useWatch(['requestFieldValues', REACT_APP_JIRA_COMPANY_FIELD], form)

  const customFieldTransform = (item?: Dimension | BudgetingScenario | Company): string | undefined => {
    if (!item) return undefined
    return `${item.name} ( ${item.id} )`
  }

  const handleCompanyChange = (company?: Company) => {
    setSelectedCompany(company)
    form.setFieldsValue({
      requestFieldValues: {
        [REACT_APP_JIRA_COMPANY_FIELD]: company ? customFieldTransform(company) : undefined,
        [REACT_APP_JIRA_DIMENSION_FIELD]: undefined,
        [REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD]: undefined
      }
    })
  }

  const selectOptionTransform = (item: Dimension | BudgetingScenario | Company) => ({
    key: item.id,
    value: customFieldTransform(item),
    label: item.name
  })

  const getScenarioForecastData = async (id: string) => {
    try {
      const scenarioResponse = await scenarioRequest.get({
        urlParams: {
          companyId: id
        }
      })
      const forecastResponse = await forecastRequest.get({
        urlParams: {
          companyId: id
        }
      })
      setBudgetingScenariosAndForecasts([...scenarioResponse, ...forecastResponse])
    } catch (error) {
      setBudgetingScenariosAndForecasts([])
    }
  }

  const getDimensionData = async (id: string) => {
    try {
      const dimensionResponse = await dimensionRequest.get({
        urlParams: {
          companyId: id
        }
      })
      setDimensions(getDimensionTree(dimensionResponse))
    } catch (error) {
      setDimensions([])
    }
  }

  const getCompanyData = async (id: string) => {
    try {
      const companyResponse = await companySearchRequest.get({
        body: {
          params: {
            vatId: id.replace('-', '').trim()
          }
        }
      })
      handleCompanyChange(companyResponse[0])
    } catch (error) {
      handleCompanyChange()
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const typesResponse: ServiceDeskResponse<JiraServicedeskRequestType> = await requestTypesRequest.get()
        const componentsResponse: JiraIssueComponent[] = await requestComponentsRequest.get()
        const requestOrder = ['3', '9', '6'] // #amiskikka order muut0s näille iideille
        setRequestTypes(typesResponse.values.sort((a, b) => requestOrder.indexOf(a.id) - requestOrder.indexOf(b.id)))
        setRequestComponents(componentsResponse)
      } catch (error) {
        console.log(error)
      }
    }

    if (open) {
      getData()
      contextCompany?.id && handleCompanyChange(contextCompany)
    }
  }, [open])

  useEffect(() => {
    if (selectedCompany?.id && open) {
      getScenarioForecastData(selectedCompany?.id)
      getDimensionData(selectedCompany?.id)
    }
  }, [selectedCompany])

  const transformFields = (values: JiraServicedeskRequestFormFields): CreateServicedeskRequestBody => {
    const data: CreateServicedeskRequestBody = {
      ...values,
      requestFieldValues: {
        ...values.requestFieldValues,
        components: values?.requestFieldValues?.components?.map((id: string) => ({
          id
        }))
      }
    }
    return data
  }

  interface TreeSelectOptionType {
    value?: string | number
    title?: React.ReactNode
    label?: React.ReactNode
    key?: React.Key
    children?: TreeSelectOptionType[]
  }

  const dimensionMap = ({ children, ...o }: Dimension): TreeSelectOptionType => ({
    value: customFieldTransform(o),
    title: o.name,
    children: children?.map(dimensionMap) // recursive call
  })

  const options = {
    name: 'file',
    multiple: true,
    fileList: files,
    onRemove: (item: UploadFile) => {
      setFiles(files.filter(f => f.name !== item.name))
    },
    beforeUpload: (file: UploadFile, allFiles: UploadFile[]) => {
      setFiles([...files, ...allFiles])
      return false
    }
  }

  return (
    <Modal
      open={open}
      title={t('jiraRequestModal:modalTitle')}
      width={700}
      maskClosable={false}
      okText={t('global:send')}
      cancelText={t('global:cancel')}
      onCancel={() => {
        form.resetFields()
        setSearchValue('')
        setFiles([])
        setSelectedCompany(undefined)
        onCancel()
      }}
      confirmLoading={requestRequest.loading}
      onOk={async () => {
        try {
          const values = await form.validateFields()
          const transformedData = transformFields(values)
          const formData = new FormData()
          files.forEach((file: UploadFile) => {
            formData.append('file', file as RcFile)
          })
          formData.append('data', JSON.stringify(transformedData))

          await requestRequest.post({
            body: {
              data: formData
            }
          })
          dispatch(
            notificationAction({
              type: 'success',
              message: t('jiraRequestModal:successMessage', 'Request sent')
            })
          )
          form.resetFields()
          setSearchValue('')
          setSelectedCompany(undefined)
          setFiles([])
          onCreate()
        } catch (error) {
          console.log('Validate Failed:', error)
        }
      }}
    >
      {requestTypesRequest.error.current ? (
        <Result
          status="warning"
          title={t('jiraRequestModal:warningTitle')}
          subTitle={t('jiraRequestModal:warningSubTitle')}
          extra={process.env.REACT_APP_SUPPORT_EMAIL}
        />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name="requestTypeId"
            label={t('jiraRequestModal:requestType')}
            labelAlign="left"
          >
            <Select>
              {requestTypes.map(requestType => {
                return (
                  <Select.Option value={requestType.id} key={requestType.id}>
                    <Space>
                      <img
                        style={{
                          height: 20
                        }}
                        src={requestType.icon._links.iconUrls['16x16']}
                        alt="icon"
                      />
                      <span>{t(`jiraRequestType:${requestType.id}`)}</span>
                    </Space>
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'summary']}
            label={t('jiraRequestModal:summary')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'description']}
            label={t('global:description')}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'components']}
            label={t('jiraRequestModal:components')}
          >
            <Select mode="multiple" maxTagCount="responsive">
              {requestComponents.map(requestComponent => (
                <Select.Option value={requestComponent.id} key={requestComponent.id}>
                  {t(`menu:${requestComponent.description}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {isSuperUser ? (
            <>
              <Form.Item label={t('contractsPage:vatId')}>
                <Input.Search
                  allowClear
                  enterButton
                  placeholder={t('contractsPage:vatId')}
                  onSearch={getCompanyData}
                  value={searchValue}
                  onChange={e => {
                    setSearchValue(e.target.value)
                  }}
                  style={{
                    width: 200
                  }}
                />
              </Form.Item>
              <Form.Item label={t('global:company')}>
                <span className="ant-form-text">{companyValue}</span>
                {companyValue && <Button type="text" icon={<CloseOutlined />} onClick={() => handleCompanyChange()} />}
              </Form.Item>
            </>
          ) : (
            <Form.Item label={t('global:company')}>
              <Select
                value={selectedCompany?.id}
                showSearch
                optionFilterProp="children"
                onChange={(id: string) => {
                  const newCompany = companies.find(c => c.id === id)
                  handleCompanyChange(newCompany)
                }}
                options={companies.map(c => ({
                  key: c.id,
                  value: c.id,
                  label: c.name
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          )}
          <Form.Item
            hidden
            shouldUpdate
            name={['requestFieldValues', REACT_APP_JIRA_COMPANY_FIELD]}
            label={t('global:dimension')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            shouldUpdate
            name={['requestFieldValues', REACT_APP_JIRA_DIMENSION_FIELD]}
            label={t('global:dimension')}
          >
            <TreeSelect loading={dimensionRequest.loading} allowClear treeData={dimensions.map(dimensionMap)} />
          </Form.Item>
          <Form.Item
            name={['requestFieldValues', REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD]}
            label={t('global:scenarioSlashForecast')}
          >
            <Select
              loading={scenarioRequest.loading}
              allowClear
              options={budgetingScenariosAndForecasts.map(selectOptionTransform)}
            />
          </Form.Item>

          <Upload.Dragger {...options}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('jiraRequestModal:upload')}</p>
            <p className="ant-upload-hint">{t('jiraRequestModal:uploadInfo')}</p>
          </Upload.Dragger>
        </Form>
      )}
    </Modal>
  )
}
