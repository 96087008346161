import React from 'react'
import { SafetyCertificateOutlined } from '@ant-design/icons'
import SettingLayout from '../../layouts/SettingLayout'
import SuperUserContract from '../../pages/superuser/contracts/components/SuperUserContract'
import SuperUserContracts from '../../pages/superuser/contracts/SuperUserContracts'
import Deliveries from '../../pages/superuser/deliveries/Deliveries'
import { Integration } from '../../pages/superuser/integration/Integration'
import SuperUserUser from '../../pages/superuser/users/components/SuperUserUser'
import SuperUserUsers from '../../pages/superuser/users/SuperUserUsers'
import { Route } from '../Route'

const superuserRoutes: Route = {
  path: '/superuser',
  name: 'superuser',
  icon: <SafetyCertificateOutlined />,
  component: SettingLayout,
  authority: { role: 'superuser' },
  routes: [
    {
      path: '/superuser/contracts',
      name: 'contracts',
      component: SuperUserContracts
    },
    {
      path: '/superuser/contracts/show',
      name: 'contract',
      urlParam: 'id',
      component: SuperUserContract,
      hideInMenu: true
    },
    {
      path: '/superuser/users',
      name: 'users',
      component: SuperUserUsers
    },
    {
      path: '/superuser/users/show',
      name: 'user',
      urlParam: 'id',
      component: SuperUserUser,
      hideInMenu: true
    },
    {
      path: '/superuser/integration',
      name: 'integration',
      component: Integration
    },
    {
      path: '/superuser/deliveries',
      name: 'deliveries',
      component: Deliveries
    }
  ]
}

export default superuserRoutes
