import React, { useEffect } from 'react'
import { Switch, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { setDataTypeFilter, toggleDataTypeFilter } from '../../../redux/context/filters/actions'
import { ReportDataType } from '../../../redux/context/reports/types'
import { ContractProduct } from '../../../types/contract/Contract'
import { contextContractProductSelector } from '../../../redux/context/contract/selectors'
import { AppDispatch } from '../../../redux/store'

interface DataTypeFilterProps {
  disableActions?: boolean
}

const DataTypeFilter = ({ disableActions }: DataTypeFilterProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { dataTypes, budgetingScenario } = useSelector(filtersSelector)
  const contextContractProduct = useSelector(contextContractProductSelector)

  const forecastEnabled = !!budgetingScenario?.forecastCursor

  const toggleForecast = () => {
    if (!dataTypes.includes(ReportDataType.forecast) && forecastEnabled) {
      dispatch(setDataTypeFilter(dataTypes.filter(dt => dt !== ReportDataType.budget)))
      dispatch(toggleDataTypeFilter(ReportDataType.forecast))
    }
    if (!forecastEnabled && dataTypes.includes(ReportDataType.forecast)) {
      dispatch(toggleDataTypeFilter(ReportDataType.forecast))
    }
  }
  useEffect(toggleForecast, [forecastEnabled])

  const onChange = (type: ReportDataType) => {
    dispatch(toggleDataTypeFilter(type))
  }

  return (
    <div>
      {[ReportDataType.actuals, ReportDataType.budget, ReportDataType.comparativePeriod].map((dataType, key) => {
        return (
          <div key={dataType} style={{ marginTop: key !== 0 ? 8 : undefined, width: '100%' }}>
            <Switch
              disabled={
                disableActions ||
                (dataType === ReportDataType.budget &&
                  (contextContractProduct === ContractProduct.LIGHT || dataTypes.includes(ReportDataType.forecast)))
              }
              checked={dataTypes.includes(dataType)}
              onChange={() => onChange(dataType)}
            />
            <Typography.Text style={{ marginLeft: 8 }}>{t(`global:${_.kebabCase(dataType)}`)}</Typography.Text>
          </div>
        )
      })}
      {forecastEnabled && (
        <div key={ReportDataType.forecast} style={{ marginTop: 8, width: '100%' }}>
          <Switch
            disabled={disableActions || dataTypes.includes(ReportDataType.budget)}
            checked={dataTypes.includes(ReportDataType.forecast)}
            onChange={() => onChange(ReportDataType.forecast)}
          />
          <Typography.Text style={{ marginLeft: 8 }}>{t(`global:${ReportDataType.forecast}`)}</Typography.Text>
        </div>
      )}
    </div>
  )
}

export default DataTypeFilter
