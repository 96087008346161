import React from 'react'
import { Form, Button } from 'antd'
import { useTranslation } from 'react-i18next'

interface SubmitButtonProps {
  onSubmit?: () => void
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const [submittable, setSubmittable] = React.useState(false)

  // Watch all values
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [values])

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={() => onSubmit?.()}>
      {t('global:save')}
    </Button>
  )
}

export default SubmitButton
