/* eslint-disable react/jsx-fragments */
import { ConnectedRouter } from 'connected-react-router/immutable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { history, persistor, store } from './redux/store'
import * as serviceWorker from './serviceWorker'
import App from './App'
import i18nextConfig from './config/i18nextConfig'
import Loading from './pages/loading/Loading'
import AppConfigProvider from './components/Misc/AppConfigProvider'
import { BugsnagWrapper } from './config/BugsnagWrapper'
import dayjsConfig from './config/dayjsConfig'
import './index.css'

dayjsConfig()
i18nextConfig()

ReactDOM.render(
  <BugsnagWrapper>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<Loading />}>
            <AppConfigProvider>
              <App />
            </AppConfigProvider>
          </Suspense>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </BugsnagWrapper>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
