import { BudgetingMethod } from '../../pages/budgeting/financialStatements/components/budget/types'
import { Account } from '../account/Account'

export interface CompanySettings {
  chartColors: string[]
  budgeting: {
    level: BudgetingLevel
    baseLine: BudgetingBaseLine
    method: {
      method: BudgetingMethod
      source: string
    }
    defaultBudgetingMethod: DefaultBudgetingMethod
  }
  vat: {
    sales: { [key: string]: number }
    purchases: { [key: string]: number }
  }
  socialSecurityExpenses: {
    enabled: boolean
    pensionExpenses: {
      YEL: {
        expenses: SocialSecurityExpenseSetting
        accruals: SocialSecurityExpenseSetting
      }
      TyEL: {
        expenses: SocialSecurityExpenseSetting
        accruals: SocialSecurityExpenseSetting
      }
    }
    otherSocialSecurityExpenses: {
      socialSecurity: {
        expenses: SocialSecurityExpenseSetting
        accruals: SocialSecurityExpenseSetting
      }
      accidentInsurance: {
        expenses: SocialSecurityExpenseSetting
      }
      unemploymentInsurance: {
        expenses: SocialSecurityExpenseSetting
      }
      groupLifeInsurance: {
        expenses: SocialSecurityExpenseSetting
      }
      mandatoryInsurance: {
        accruals: SocialSecurityExpenseSetting
      }
    }
  }
  simulations?: BudgetSimulations
}

export interface CompanySettingsForm {
  budgeting: {
    level: BudgetingLevel
    method: {
      method: BudgetingMethod
      source: string[]
    }
    baseLine: BudgetingBaseLine
    defaultBudgetingMethod: DefaultBudgetingMethod
  }
  vat: {
    sales: VatRecord[]
    purchases: VatRecord[]
  }
  socialSecurityExpenses: {
    enabled: boolean
    pensionExpenses: {
      YEL: {
        expenses: SocialSecurityExpenseSettingForm
        accruals: SocialSecurityExpenseSettingForm
      }
      TyEL: {
        expenses: SocialSecurityExpenseSettingForm
        accruals: SocialSecurityExpenseSettingForm
      }
    }
    otherSocialSecurityExpenses: {
      socialSecurity: {
        expenses: SocialSecurityExpenseSettingForm
        accruals: SocialSecurityExpenseSettingForm
      }
      accidentInsurance: {
        expenses: SocialSecurityExpenseSettingForm
      }
      unemploymentInsurance: {
        expenses: SocialSecurityExpenseSettingForm
      }
      groupLifeInsurance: {
        expenses: SocialSecurityExpenseSettingForm
      }
      mandatoryInsurance: {
        accruals: SocialSecurityExpenseSettingForm
      }
    }
  }
  simulations?: BudgetSimulations
}

export enum BudgetingLevel {
  account = 'account',
  statementRow = 'statementRow'
}

export enum BudgetingBaseLine {
  lastClosedFiscalYear = 'lastClosedFiscalYear',
  none = 'none'
}

export enum DefaultBudgetingMethod {
  absolute = 'absolute'
}

export interface SocialSecurityExpenseSetting {
  account: Account
  sourceAccounts?: Account[]
  factor: number
}

export interface SocialSecurityExpenseSettingForm {
  account: number
  sourceAccounts?: number[]
  factor: number
}

export type VatCategory = 'sales' | 'purchases'
export interface VatRecord {
  vat: number
  value: number
}

export interface BudgetSimulations {
  /** Pääkytkin */
  enabled: boolean

  /** Tulos / verot */
  taxes: BudgetSimulation

  /** Lyhytaikaiset myyntisaamiset */
  accountsReceivable: BudgetSimulation

  /** Lyhytaikaiset ostovelat */
  accountsPayable: BudgetSimulation

  /** ALV-saamiset */
  vatReceivable: BudgetSimulation

  /** ALV-velat */
  vatPayable: BudgetSimulation

  /** Lomapalkkavelkavaraus */
  holidayPayReserve: BudgetSimulation

  /** Eläke- ja muiden henkilösivukulujen velkavaraus */
  pensionReserve: BudgetSimulation
}

export interface BudgetSimulation {
  enabled: boolean
  accountCode?: number
}
