import { DatePicker, Form, Row, Col } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'
import { DateRangeValue } from '../../../../../../types/date/types'
import { ReallocationType } from '../../../../../../types/reallocation/Reallocation'
import { ReallocationContext, ReallocationContextType } from '../ReallocationProvider'
import { enumerateDaysBetweenDates } from '../../../../../../utils/periodGroups/utils'

const colProps = {
  xs: 24,
  sm: 12,
  md: 4,
  lg: 4,
  xl: 4
}

function ReallocationFloatingForm() {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const formFloatinValues = Form.useWatch('floating', form)
  const targetId = Form.useWatch('id', form)
  const { targetParent, floatingValues } = useContext(ReallocationContext) as ReallocationContextType
  const [floatingTimePeriod, setFloatingTimePeriod] = useState<DateRangeValue<dayjs.Dayjs>>([
    dayjs(targetParent?.startDate || dayjs()),
    dayjs(targetParent?.endDate || dayjs().add(11, 'M'))
  ])

  const getData = () => {
    if (floatingTimePeriod && floatingTimePeriod[0] && floatingTimePeriod[1]) {
      const monthArr = enumerateDaysBetweenDates(floatingTimePeriod[0], floatingTimePeriod[1])

      const obj = monthArr?.map(m => {
        const month = m.month() + 1
        const year = m.year()
        return {
          month,
          year,
          value: floatingValues?.find(f => f.year === year && f.month === month)?.value || 0
        }
      })
      return obj
    }

    return undefined
  }

  const updateFields = () => {
    form.setFieldsValue({ floating: getData() })
  }

  const getFloatingMinMaxDates = () => {
    if (floatingValues.length <= 0) return
    const arr = floatingValues.map(({ month, year }) => {
      return dayjs({ month: (month as number) - 1, year })
    })
    const minDate = dayjs.min(arr)
    const maxDate = dayjs.max(arr)
    setFloatingTimePeriod([minDate, maxDate])
  }

  useEffect(() => {
    getFloatingMinMaxDates()
  }, [])

  useEffect(() => {
    targetParent && updateFields()
  }, [floatingTimePeriod, targetParent, targetId])

  return (
    <>
      <Form.Item label={t('global:time-period')}>
        <DatePicker.RangePicker
          value={floatingTimePeriod}
          onChange={values => setFloatingTimePeriod(values)}
          picker="month"
        />
      </Form.Item>
      <Form.Item label={t(`reallocationsPage:${ReallocationType.floating}`)}>
        <Form.List name="floating">
          {fields => (
            <Row gutter={[16, 16]} align="bottom">
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Col {...colProps} key={key}>
                    <span>{`${formFloatinValues?.[key].month}/${formFloatinValues?.[key].year}`}</span>
                    <Form.Item name={[name, 'value']} noStyle {...restField} isListField={false}>
                      <FormattedInputNumber
                        percentage
                        style={{ textAlign: 'right', width: '100%' }}
                        min={0}
                        max={100}
                        addonAfter="%"
                        step={1}
                        required={false}
                      />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          )}
        </Form.List>
      </Form.Item>
    </>
  )
}

export default ReallocationFloatingForm
