import { combineReducers } from 'redux'
import contractProductsReducer from './contractProducts/reducer'
import investmentFundingSourceReducer from './investmentFundingSources/reducer'
import keyFiguresReducer from './keyFigures/reducer'
import loanRepaymentMethodsReducer from './loanRepaymentMethods/reducer'
import statementRowsReducer from './statementRows/reducer'
import industryReducer from './industries/reducers'
import permissionsReducer from './permissions/reducer'
import LiabilityMappingReducer from './liabilityMapping/reducer'

const entitiesReducer = combineReducers({
  contractProducts: contractProductsReducer,
  investmentFundingSources: investmentFundingSourceReducer,
  keyFigures: keyFiguresReducer,
  loanRepaymentMethods: loanRepaymentMethodsReducer,
  statementRows: statementRowsReducer,
  industries: industryReducer,
  permissions: permissionsReducer,
  liabilityMapping: LiabilityMappingReducer
})

export default entitiesReducer
