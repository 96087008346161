import { LockOutlined, WarningOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { yellow } from '@ant-design/colors'
import { Popover } from 'antd'
import { BalanceError } from '../../../../../redux/context/reports/types'

interface RestrictedProps {
  errors?: BalanceError[]
}

const Restricted: React.FC<RestrictedProps> = ({ errors }) => {
  const { t } = useTranslation()

  const generateErrorMessages = () => {
    return errors?.map(err => <p>{t(`error:${err}`)}</p>)
  }

  const errorIncludesUnauthorized = () => {
    if (errors?.find((e: BalanceError) => e === 'unauthorized')) {
      return <LockOutlined style={{ fontSize: '20px' }} />
    }
    return <WarningOutlined style={{ color: yellow.primary, fontSize: '20px' }} />
  }

  return (
    <Popover placement="top" title={generateErrorMessages()}>
      {errorIncludesUnauthorized()}
    </Popover>
  )
}

export default Restricted
