import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import FunctionInformation from './FunctionInformation'
import { Argument, FormulaFunction } from '../types'
import { getCurrentArg, getCurrentFunction, getPrevArgumentValue } from '../utils'

type FormulaSourceProps = {
  textAreaRef: any
  cursor: any
  form: FormInstance
  functions: { [key: string]: FormulaFunction }
  setTextAreaRef: (node: TextAreaRef) => any
}

const FormulaSource = ({ textAreaRef, cursor, form, functions, setTextAreaRef }: FormulaSourceProps) => {
  const { t } = useTranslation()
  const [currentFunction, setCurrentFunction] = useState<FormulaFunction>()
  const [currentArgument, setCurrentArgument] = useState<Argument>()
  const [prevArgumentValue, setPrevArgumentValue] = useState<string>() // edellisen argumentin arvo

  const saveTextAreaRef = useCallback(
    (node: TextAreaRef) => {
      if (node !== null && textAreaRef === null) {
        setTextAreaRef(node)
      }
    },
    [textAreaRef]
  )

  const addArgument = (argument: string) => {
    const source = form.getFieldValue('source') || ''
    form.setFieldsValue({
      source: `${source.slice(0, cursor.selectionStart)}${argument}${source.slice(cursor.selectionEnd)}`
    })
    setTimeout(() => {
      cursor.select(cursor.selectionStart + argument.length)
    }, 10)
  }

  const handleCursorChange = () => {
    const source = form.getFieldValue('source') || ''
    const f = functions[getCurrentFunction(source, cursor)]
    setCurrentFunction(f)
    setCurrentArgument(getCurrentArg(source, cursor, f))
    setPrevArgumentValue(getPrevArgumentValue(source, cursor))
  }

  useEffect(handleCursorChange, [cursor])

  return (
    <>
      <Form.Item
        name="source"
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
      >
        <Input.TextArea className="text-area" ref={saveTextAreaRef} rows={4} spellCheck={false} />
      </Form.Item>
      {currentFunction && (
        <>
          <Divider />
          <FunctionInformation
            currentFunction={currentFunction}
            currentArgument={currentArgument}
            prevArgumentValue={prevArgumentValue}
            addArgument={addArgument}
          />
        </>
      )}
    </>
  )
}

export default FormulaSource
