import { GET, GetRequestAction, REQUEST } from '../../middleware/types'
import { User } from '../../../types/user/User'
import { Company } from '../../../types/company/Company'
import { CompanyUsersActionTypes, SET_COMPANY_USERS } from './types'

export const setCompanyUsersAction = (companyUsers: User[]): CompanyUsersActionTypes => ({
  type: SET_COMPANY_USERS,
  payload: companyUsers
})

export const getCompanyUsersRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/users`,
    success: setCompanyUsersAction
  },
  meta: {
    type: 'COMPANY_USERS'
  }
})
