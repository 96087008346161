import useComponentSize from '@rehooks/component-size'
import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTableColumns } from '../redux/context/periodGroups/hooks'
import { filtersSelector } from '../redux/context/filters/selectors'
import { Report, ReportDataType } from '../redux/context/reports/types'
import { contextCompanyIdSelector } from '../redux/context/company/selectors'

export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how  we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const useTableProps = (ref: any, breakPoint = 600) => {
  const size = useComponentSize(ref)
  const debouncedSize = useDebounce(size, 500)
  const [props, setProps] = useState({})

  useEffect(() => {
    if (size.width !== 0 && size.height !== 0) {
      const p = {
        ...(size.width <= breakPoint ? { scroll: { x: breakPoint } } : {})
      }

      setProps(p)
    }
  }, [debouncedSize, breakPoint, size])

  return props
}

export const useTextAreaCursor = (ref: any) => {
  const [selection, setSelection] = useState({
    selectionStart: ref ? ref.resizableTextArea.textArea?.selectionStart : 0,
    selectionEnd: ref ? ref.resizableTextArea.textArea?.selectionEnd : 0
  })

  const getSelection = () => {
    if (ref) {
      const { selectionStart, selectionEnd } = ref.resizableTextArea.textArea
      return { selectionStart, selectionEnd }
    }
    return { selectionStart: 0, selectionEnd: 0 }
  }

  const handleSelectionChange = () => {
    const s = getSelection()
    setSelection(s)
  }

  const handleChange = useCallback(handleSelectionChange, [ref])

  useEffect(() => {
    if (ref) {
      ref.resizableTextArea.textArea.addEventListener('click', handleChange)
      ref.resizableTextArea.textArea.addEventListener('keyup', handleChange)
    }
    // eslint-disable-next-line consistent-return
    return () => {
      if (ref) {
        // eslint-disable-next-line no-debugger
        // debugger
        ref.resizableTextArea.textArea?.removeEventListener('click', handleChange)
        ref.resizableTextArea.textArea?.removeEventListener('keyup', handleChange)
      }
    }
  }, [ref, handleChange])

  const select = (start: number, end?: number) => {
    if (!ref) return
    ref.resizableTextArea.textArea.setSelectionRange(start, end || start)
    setSelection(getSelection())
    ref.resizableTextArea.textArea.focus()
  }

  const cursor = {
    ...selection,
    select
  }

  return cursor
}

export const useTopReportData = (data: Report, quantity: number) => {
  const [report, setReport] = useState<Report>([])
  const { periodGroup, periodGroups } = useSelector(filtersSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const columns = useTableColumns()

  const handler = () => {
    const compareGroupId = periodGroup
      ? periodGroup.id
      : columns
          .filter(c => c.dataType === ReportDataType.actuals)
          .map(c => c.periodGroup?.id)
          .pop()

    const r = data
      .slice()
      .sort((a, b) => {
        const aValue = a.actuals?.find(balance => balance.groupId === compareGroupId)?.value || 0
        const bValue = b.actuals?.find(balance => balance.groupId === compareGroupId)?.value || 0
        return bValue - aValue
      })
      .slice(0, quantity)

    setReport(r)
  }

  useEffect(handler, [quantity, companyId, periodGroups, periodGroup, data])

  return report
}
