import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { BudgetingConfig, BUDGETING_CONFIG, SetBudgetingConfigAction } from './types'

export const setBudgetingConfig = (budgetingConfig: BudgetingConfig): SetBudgetingConfigAction => ({
  type: BUDGETING_CONFIG,
  payload: budgetingConfig
})

export const getBudgetingConfigRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: '/api/config/budgeting',
    success: setBudgetingConfig
  },
  meta: {
    type: 'BUDGETING_CONFIG'
  }
})
