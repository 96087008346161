import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { getReallocationsRequest, setReallocations } from '../../../../redux/context/reallocations/actions'
import ReallocationModal from './components/ReallocationModal'
import ReallocationTable from './components/ReallocationTable'
import ReallocationProvider from './components/ReallocationProvider'
import ReallocationTargetModal from './components/ReallocationTargetModal'
import { contextRequest } from '../../../../redux/context/actions'
import { getFormulasRequest, setFormulas } from '../../../../redux/context/formulas/actions'
import { AppDispatch } from '../../../../redux/store'

const ReallocationPage = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!

  useEffect(() => {
    dispatch(getReallocationsRequest(companyId))
    dispatch(contextRequest(getFormulasRequest))

    return () => {
      dispatch(setReallocations([]))
      dispatch(setFormulas([]))
    }
  }, [companyId])

  return (
    <div>
      <ReallocationProvider>
        <ReallocationModal />
        <ReallocationTargetModal />
        <ReallocationTable />
      </ReallocationProvider>
    </div>
  )
}

export default ReallocationPage
