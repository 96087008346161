import React, { ComponentClass, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Table, Input, Popconfirm, Form, Button, Space, FormItemProps, DatePicker } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Key } from 'antd/lib/table/interface'
import { FormattedInputNumber } from '../Misc/FormattedInputNumber'
import './_EditableTable.sass'

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'percent' | 'text' | 'date' | 'custom'
  inputField: ComponentClass | FunctionComponent
  inputFieldProps: any
  record: T
  index: number
  children: React.ReactNode
  formItemProps?: FormItemProps
}

const EditableCell = <T extends {}>({
  editing,
  dataIndex,
  inputType,
  inputField,
  inputFieldProps,
  record,
  children,
  formItemProps,
  ...restProps
}: EditableCellProps<T>) => {
  let inputNode: any = [<Input />]
  if (inputType === 'custom') inputNode = [inputField, { record, ...inputFieldProps }]
  if (inputType === 'number') inputNode = [FormattedInputNumber]
  if (inputType === 'percent') inputNode = [FormattedInputNumber, { percentage: true, step: 1, addonAfter: '%' }]
  if (inputType === 'date') inputNode = [DatePicker, { format: 'DD.MM.YYYY' }]

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} {...formItemProps}>
          {React.createElement(inputNode[0], inputNode[1])}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export type EditableColumnProps<T> = ColumnType<T> & {
  editable?: boolean
  type?: 'number' | 'percent' | 'text' | 'date' | 'custom'
  inputField?: ReactNode
  inputFieldProps?: any
  formItemProps?: FormItemProps
  children?: EditableColumnProps<T>[]
}

interface EditableTableRowProps<T> extends Omit<TableProps<T>, 'rowKey'> {
  columns?: EditableColumnProps<T>[]
  handleSave: (data: T) => void
  handleRowDelete?: (data: T) => void
  handleNewRow?: (data?: T[]) => T
  handleCancel?: () => void
  onValuesChange?: (values: any, allValues: T) => void
  rowKey: keyof T | ((record: Partial<T>, index?: number) => Key | string)
}

const EditableRowTable = <T extends {}>({
  columns,
  dataSource,
  rowKey,
  onValuesChange,
  handleSave,
  handleNewRow,
  handleRowDelete,
  handleCancel,
  ...restProps
}: EditableTableRowProps<T>) => {
  const [form] = Form.useForm<T>()
  const { t } = useTranslation()
  const [data, setData] = useState(dataSource)
  const [editingKey, setEditingKey] = useState('')

  useEffect(() => {
    setData(dataSource)
  }, [dataSource])

  const getKey = (record: T): string => {
    const theKey = typeof rowKey === 'function' ? rowKey(record) : record[rowKey]
    return `${theKey}`
  }

  const isEditing = (record: T) => getKey(record) === editingKey

  const edit = (record: T) => {
    form.setFieldsValue({
      ...(record as any)
    })
    setEditingKey(getKey(record))
  }

  const cancel = () => {
    setEditingKey('')
    handleCancel?.()
  }

  const save = async (key: React.Key) => {
    if (data === undefined) return
    try {
      const row = (await form.validateFields()) as T
      form.resetFields()
      const newData = [...data]
      const index = newData.findIndex(item => key === getKey(item))

      if (index > -1) {
        const item = newData[index]
        const newRow = { ...item, ...row }
        newData.splice(index, 1, newRow)
        setData(newData)
        setEditingKey('')
        handleSave(newRow)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const addNewRow = () => {
    if (!handleNewRow) return
    const newRow = handleNewRow(data as T[])
    setData([...(data || []), newRow])
    setEditingKey(getKey(newRow))
  }

  const tableColumns = [
    ...(columns || []),
    {
      title: t('global:edit'),
      dataIndex: 'edit',
      fixed: 'right' as const,
      width: 100,
      render: (__: any, record: T) => {
        const editable = isEditing(record)
        const removable = (!!handleRowDelete && (record as any)?.removable) ?? true
        return editable ? (
          <span>
            <Space>
              <Button type="link" icon={<CheckOutlined />} onClick={() => save(getKey(record))} />
              <Popconfirm title={t('global:delete-confirm')} onConfirm={cancel}>
                <Button type="link" icon={<CloseOutlined />} />
              </Popconfirm>
            </Space>
          </span>
        ) : (
          <>
            <Button type="link" icon={<EditOutlined />} disabled={editingKey !== ''} onClick={() => edit(record)} />
            {removable && (
              <Popconfirm title={t('global:delete-confirm')} onConfirm={() => handleRowDelete!(record)}>
                <Button danger type="link" icon={<DeleteOutlined />} disabled={editingKey !== ''} />
              </Popconfirm>
            )}
          </>
        )
      }
    }
  ]

  const mergedColumns = tableColumns.map((col: EditableColumnProps<T>) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      children: col.children?.map(c => ({
        ...c,
        onCell: (record: T) => ({
          record,
          inputType: c.type || 'text',
          dataIndex: c.dataIndex,
          inputField: c.inputField,
          inputFieldProps: c.inputFieldProps,
          title: c.title,
          editing: isEditing(record),
          formItemProps: c.formItemProps
        })
      })),
      onCell: (record: T) => ({
        record,
        inputType: col.type || 'text',
        dataIndex: col.dataIndex,
        inputField: col.inputField,
        inputFieldProps: col.inputFieldProps,
        title: col.title,
        editing: isEditing(record),
        formItemProps: col.formItemProps
      })
    }
  })

  return (
    <Form form={form} component={false} onValuesChange={onValuesChange}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        rowKey={record => getKey(record)}
        bordered
        dataSource={data}
        columns={mergedColumns as ColumnType<T>[]}
        rowClassName="editable-row"
        pagination={false}
        {...restProps}
      />
      {handleNewRow && (
        <Button onClick={addNewRow} type="dashed" style={{ marginTop: '0.5rem' }} block icon={<PlusOutlined />}>
          {t('customReportPage:add-row')}
        </Button>
      )}
    </Form>
  )
}

export default EditableRowTable
