import { ValuationAdjustmentsActionTypes, UPDATE_VALUATION_ADJUSTMENTS, SET_VALUATION_ADJUSTMENTS } from './types'

const initialState: any[] = []

const valuationAdjustmentsReducer = (state = initialState, action: ValuationAdjustmentsActionTypes) => {
  switch (action.type) {
    case SET_VALUATION_ADJUSTMENTS:
      return action.payload
    case UPDATE_VALUATION_ADJUSTMENTS:
      return action.payload
    default:
      return state
  }
}

export default valuationAdjustmentsReducer
