import { Button, Row, Form, Col, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import { updateExistingLoanRequest, updateNewLoanRequest } from '../../../../redux/context/loans/actions'
import { createExistingLoanRequest, deleteLoanRequest } from '../../../../redux/context/loans/existing/actions'
import { Company } from '../../../../types/company/Company'
import { Loan } from '../../../../types/loan/Loan'
import { Purpose } from '../../../../types/purpose/Purpose'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { LoanFormFields } from './LoanFormFields'
import {
  updateLoansInvestmentsRequest,
  createLoansInvestmentsRequest,
  deleteLoansInvestmentsRequest
} from '../../../../redux/context/investments/actions'
import { useBackend } from '../../../../services/backend'
import InvestmentModal from '../../investments/components/InvestmentModal'
import InvestmentFormContextProvider from '../../investments/InvestmentFormContextProvider'
import { Investment } from '../../../../types/investment/Investment'
import { investmentsPageSelector } from '../../../../redux/pages/investments/selectors'
import { notLocked } from '../../../settings/company/budgeting/budgetingScenarios/utils'
import { disabledStyle } from '../../../../components/ContextDrawer/ContextDrawer'
import { AppDispatch } from '../../../../redux/store'

interface LoanFormProps {
  currentPage: 'existing' | 'new' | 'investment'
  loan?: Loan
  companyId: Company['id']
  addNew?: boolean
  closeModal?: () => void
}

const LoanForm = ({ companyId, loan, currentPage, addNew, closeModal }: LoanFormProps) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const dispatch: AppDispatch = useDispatch()

  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const [investment, setInvestment] = useState<Investment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const investmentRequest = useBackend('/api/companies/{companyId}/budgeting/investments/{investmentId}')

  const handleSubmit = () => {
    form.validateFields().then(fieldsValue => {
      const values = {
        ...fieldsValue,
        nextInstalmentDate: fieldsValue.nextInstalmentDate.format('YYYY-MM-DD'),
        purposes:
          fieldsValue.purposes?.map((p: string) => {
            return { name: p }
          }) || [],
        balanceSheetRowId: fieldsValue.balanceSheetRowId || loan?.balanceSheetRowId,
        value: fieldsValue.value || Number(loan?.value),
        interestRate: fieldsValue.interestRate
      }

      if (loan?.id) {
        if (currentPage === 'existing') {
          // Updating existin loan
          dispatch(
            updateExistingLoanRequest(
              companyId,
              loan?.id,
              values,
              budgetingScenario && budgetingScenario.id,
              dayjs(date).format('YYYY-MM-DD')
            )
          )
        } else {
          // Updating existin loan
          dispatch(
            updateNewLoanRequest(
              companyId,
              loan?.id,
              values,
              budgetingScenario && budgetingScenario.id,
              dayjs(date).format('YYYY-MM-DD')
            )
          )
        }
      }
      if ((loan?.isExisting && !loan?.id) || addNew) {
        // Creating new exiating loan
        dispatch(
          createExistingLoanRequest(
            companyId,
            budgetingScenario && budgetingScenario.id,
            values,
            dayjs(date).format('YYYY-MM-DD')
          )
        )
        if (closeModal && addNew) {
          closeModal()
        }
      }
    })
  }
  const handleInvestmentReq = (investmentId: string) => {
    investmentRequest
      .get({
        urlParams: { companyId, investmentId }
      })
      .then((investmentResponse: Investment) => {
        setInvestment(investmentResponse)
        setModalVisible(true)
      })
  }
  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const getPurposes = () => {
    if (loan && loan?.purposes?.length > 0) {
      return loan?.purposes.map((v: Purpose) => v.name)
    }
    if (loan?.investment?.name) {
      return [loan?.investment?.name]
    }
    return []
  }

  const handleInvesmentDelete = () => {
    loan &&
      dispatch(
        deleteLoanRequest(
          companyId,
          loan.id,
          budgetingScenario && budgetingScenario.id,
          dayjs(date).format('YYYY-MM-DD')
        )
      )
  }

  const handleValuesChange = (values: Partial<Loan>) => {
    const { balanceSheetRowId } = values
    if (balanceSheetRowId) {
      // Tyhjätään accountit jos tase erä muutttuu, eri lähteen alla voi olla eri tilejä.
      form.setFieldValue(['longTermAccountCode'], undefined)
      form.setFieldValue(['shortTermAccountCode'], undefined)
    }
  }

  return (
    <>
      {modalVisible && (
        <InvestmentFormContextProvider
          reportType={investment && investment.value < 0 ? 'divestment' : 'investment'}
          isExisting={false}
        >
          <InvestmentModal
            updateInvestmentsRequest={updateLoansInvestmentsRequest}
            createInvestmentsRequest={createLoansInvestmentsRequest}
            deleteInvestmentsRequest={deleteLoansInvestmentsRequest}
            visible={modalVisible}
            onFinish={toggleModal}
            investment={investment}
            page="financialStatement"
          />
        </InvestmentFormContextProvider>
      )}
      <Form
        form={form}
        className="loan-form"
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        initialValues={{
          ...loan,
          nextInstalmentDate: loan?.nextInstalmentDate ? dayjs(loan.nextInstalmentDate, 'YYYY-MM-DD') : null,
          purposes: getPurposes(),
          termsAndRepaymentsSame: !!(
            loan?.termYears === loan?.repaymentProgramYears && loan?.termMonths === loan?.repaymentProgramMonths
          )
        }}
      >
        <div style={!notLocked(budgetingScenario) ? (disabledStyle as any) : undefined}>
          <LoanFormFields loanType={currentPage} addNew={addNew} />
          {currentPage === 'new' && (
            <Col xl={8} lg={10} md={12} sm={14}>
              <Form.Item label={t('loans:purposes')}>
                <Button
                  block
                  onClick={() => loan?.investment?.id && handleInvestmentReq(loan?.investment?.id.toString())}
                >
                  {loan?.investment?.name}
                </Button>
              </Form.Item>
            </Col>
          )}
        </div>
        <Row>
          {loan?.id && currentPage !== 'new' && (
            <Col span={8}>
              <Popconfirm
                title={t('global:delete-confirm')}
                onConfirm={handleInvesmentDelete}
                okText={t('global:yes')}
                cancelText={t('global:no')}
              >
                <Button disabled={!notLocked(budgetingScenario)} danger>
                  {t('global:delete')}
                </Button>
              </Popconfirm>
            </Col>
          )}
          <Col span={2} offset={loan?.id && currentPage !== 'new' ? 14 : 20}>
            <Button
              disabled={!notLocked(budgetingScenario)}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('global:save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(LoanForm)
