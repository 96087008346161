import { CompanyDashboardActionTypes, CompanyDashboardPage, SET_ACTIVE_COMPANY_DASHBOARD } from './types'

const initialState: CompanyDashboardPage = {
  activeDashboard: null
}

const companyDashboardPageReducer = (state = initialState, action: CompanyDashboardActionTypes) => {
  switch (action.type) {
    case SET_ACTIVE_COMPANY_DASHBOARD:
      return { ...state, activeDashboard: action.payload }
    default:
      return state
  }
}

export default companyDashboardPageReducer
