import React, { useEffect, useMemo } from 'react'
import { Radio, Typography, Form, Alert, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AccountSelect from '../../../../../components/inputs/AccountSelect'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { normalizedConfigSelector } from '../../../../../redux/config/selectors'
import { contextCompanySelector } from '../../../../../redux/context/company/selectors'
import { getGroupSettingsRequest, updateGroupSettingsRequest } from '../../../../../redux/context/groupSettings/actions'
import {
  groupSettingsLoadingSelector,
  groupSettingsSelector
} from '../../../../../redux/context/groupSettings/selectors'
import { isGroupCompany } from '../../../../../routes/utils'
import { GroupSettingData, GroupSettingDataSource } from '../types'
import { AppDispatch } from '../../../../../redux/store'

const Figures = () => {
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const company = useSelector(contextCompanySelector)!
  const groupSettings = useSelector(groupSettingsSelector)
  const budgetSource = Form.useWatch(['budget', 'source'], form)
  const actualsSource = Form.useWatch(['actuals', 'source'], form)
  const loading = useSelector(groupSettingsLoadingSelector)
  const {
    financialStatement: { plAssociateProfitRowId, plMinoritySharesRowId, bsMinoritySharesRowId, bsAssociateSharesRowId }
  } = useSelector(normalizedConfigSelector)

  const accountsRequired = useMemo(
    () => [budgetSource, actualsSource].includes(GroupSettingDataSource.subsidiaries),
    [actualsSource, budgetSource]
  )

  const groupSettingsConfig = {
    plAssociateProfitAccountCode: plAssociateProfitRowId,
    plMinoritySharesAccountCode: plMinoritySharesRowId,
    bsMinoritySharesAccountCode: bsMinoritySharesRowId,
    bsAssociateSharesAccountCode: bsAssociateSharesRowId
  }

  useEffect(() => {
    form.resetFields()
    isGroupCompany() && dispatch(getGroupSettingsRequest(company.id))
  }, [company.id])

  const handleSubmit = (data: GroupSettingData) => {
    company.id && dispatch(updateGroupSettingsRequest(company.id, data))
  }

  const getInitValues = () => {
    if (groupSettings) {
      form.setFieldsValue(groupSettings)
    }
  }

  useEffect(getInitValues, [groupSettings])

  return (
    <LoadingWrapper loading={loading}>
      <Form
        onFinish={handleSubmit}
        onValuesChange={(changed, allValues) => handleSubmit(allValues)}
        initialValues={groupSettings}
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
      >
        <Typography style={{ marginTop: 24 }}>
          <Form.Item label={t('subsidiaries:groupActuals')} name={['actuals', 'source']} rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={GroupSettingDataSource.group}>{t('subsidiaries:integration')}</Radio>
                <Radio value={GroupSettingDataSource.subsidiaries}>{t('subsidiaries:addUp')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('subsidiaries:groupBudget')} name={['budget', 'source']} rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={GroupSettingDataSource.group}>{t('subsidiaries:enter')}</Radio>
                <Radio value={GroupSettingDataSource.subsidiaries}>{t('subsidiaries:addUp')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {budgetSource === GroupSettingDataSource.subsidiaries ? (
            <Alert
              style={{ marginBottom: 16 }}
              message={t('global:alertNoteTitle')}
              description={t('subsidiaries:budgetingInfoText')}
              type="info"
            />
          ) : null}
          {accountsRequired
            ? Object.entries(groupSettingsConfig).map(([key, value]) => (
                <Form.Item label={t(`subsidiaries:${key}`)} name={key}>
                  <AccountSelect allowClear balanceSheetRowIds={value} />
                </Form.Item>
              ))
            : null}
          <Typography.Title level={5}>{t('subsidiaries:groupBalanceSheet')}</Typography.Title>
          <Typography.Paragraph>{t('subsidiaries:balanceSheetIncomestatement')}</Typography.Paragraph>
        </Typography>
      </Form>
    </LoadingWrapper>
  )
}

export default Figures
