import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { FinancialStatementConfig, SetFinancialStatementConfigAction, SET_FINANCIAL_STATEMENT_CONFIG } from './types'

export const setFinancialStatementConfig = (
  financialStatementConfig: FinancialStatementConfig
): SetFinancialStatementConfigAction => ({
  type: SET_FINANCIAL_STATEMENT_CONFIG,
  payload: financialStatementConfig
})

export const getFinancialStatementConfigRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: '/api/config/financial-statement',
    success: setFinancialStatementConfig
  },
  meta: {
    type: 'FINANCIAL_STATEMENT_CONFIG'
  }
})
