import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Row, Card, Form } from 'antd'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { fiscalYearsSelector } from '../../redux/context/fiscalYears/selectors'
import Chart from './components/Chart'
import { valuationNAVSelector } from '../../redux/context/reports/selectors'
import { generateValuationChartCategories, generateValuationChartSeries, tranformInitial } from './utils'
import { updateValuationAdjustmentRequest } from '../../redux/context/valuation/actions'
import { ValuationForm } from './components/ValuationForm'
import { filtersSelector } from '../../redux/context/filters/selectors'
import BalanceSheetCorrectionInput from './components/BalanceSheetCorrectionInput'
import { ReportTableRow } from '../../components/Table/types'
import { AppDispatch } from '../../redux/store'

interface NetAssetValuesProps {
  valuationAdjustments?: any
}

const NetAssetsValue = ({ valuationAdjustments }: NetAssetValuesProps) => {
  const { t } = useTranslation()
  const valuation = useSelector(valuationNAVSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const fiscalYears = useSelector(fiscalYearsSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const dispatch: AppDispatch = useDispatch()

  const onSubmit = (values: any) => {
    const wages: any[] = []
    Object.entries(values.wages).map(([key, value]) => {
      return value && wages.push({ fiscalYearId: Number(key), value: String(value) })
    })
    const other: any[] = []
    Object.entries(values.other).map(([key, value]) => {
      return value && other.push({ fiscalYearId: Number(key), value: String(value) })
    })

    companyId &&
      dispatch(
        updateValuationAdjustmentRequest(
          companyId,
          {
            wages,
            other,
            balanceSheet: values.balanceSheet,
            EBITDAFactor: valuationAdjustments.EBITDAFactor,
            EBITDAHistorySampleSize: valuationAdjustments.EBITDAHistorySampleSize,
            capitalizationInterestRate: valuationAdjustments.capitalizationInterestRate
          },
          budgetingScenario && budgetingScenario.id
        )
      )
  }

  return (
    <Card>
      <Form initialValues={tranformInitial(valuationAdjustments)} layout="horizontal" onFinish={onSubmit}>
        <Row gutter={24}>
          <ValuationForm />
          <Col md={12} xs={24}>
            <Card>
              <Chart
                categories={generateValuationChartCategories(valuation as ReportTableRow, fiscalYears)}
                series={generateValuationChartSeries(valuation as ReportTableRow)}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <h3>{t('valuationPage:balance-sheet-corrections')}</h3>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <h4>{t('valuationPage:fiscal-year')}</h4>
            </Col>
            <Col span={9}>
              <h4>{t('valuationPage:balance-sheet')}</h4>
            </Col>
            <Col span={9}>
              <h4>{t('valuationPage:correction')}</h4>
            </Col>
          </Row>
          <Form.List name="balanceSheet">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => (
                    <Row gutter={24} style={{ width: '100%', marginBottom: 16 }} key={field.name}>
                      <Form.Item noStyle {...field}>
                        <BalanceSheetCorrectionInput id={field.key} />
                      </Form.Item>
                      <Button
                        icon={<DeleteOutlined />}
                        type="text"
                        danger
                        disabled={fields.length !== field.key + 1}
                        onClick={() => remove(field.name)}
                      />
                    </Row>
                  ))}
                  <Row style={{ marginTop: '20px' }}>
                    <Button onClick={() => add()}>{`+ ${t('global:add-new')}`}</Button>

                    <Button style={{ marginLeft: '20px' }} type="primary" htmlType="submit">
                      {t('global:save')}
                    </Button>
                  </Row>
                </>
              )
            }}
          </Form.List>
        </Row>
      </Form>
    </Card>
  )
}

export default NetAssetsValue
