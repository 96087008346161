import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'

export const SET_BUDGETING_SCENARIOS = 'SET_BUDGETING_SCENARIOS'
export const ADD_BUDGETING_SCENARIO = 'ADD_BUDGETING_SCENARIO'
export const UPDATE_BUDGETING_SCENARIO = 'UPDATE_BUDGETING_SCENARIO'
export const DELETE_BUDGETING_SCENARIO = 'DELETE_BUDGETING_SCENARIO'

export interface SetBudgetingScenariosAction {
  type: typeof SET_BUDGETING_SCENARIOS
  payload: BudgetingScenario[]
}
export interface AddBudgetingScenarioAction {
  type: typeof ADD_BUDGETING_SCENARIO
  payload: BudgetingScenario
}
export interface UpdateBudgetingScenarioAction {
  type: typeof UPDATE_BUDGETING_SCENARIO
  payload: BudgetingScenario
}
export interface DeleteBudgetingScenarioAction {
  type: typeof DELETE_BUDGETING_SCENARIO
  payload: BudgetingScenario
}

export type BudgetingScenariosActionTypes =
  | SetBudgetingScenariosAction
  | AddBudgetingScenarioAction
  | DeleteBudgetingScenarioAction
  | UpdateBudgetingScenarioAction
