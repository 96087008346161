import { BudgetDriver } from '../../../types/budgetDriver/BudgetDriver'
import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddBudgetDriverAction,
  ADD_BUDGET_DRIVER,
  DeleteBudgetDriverAction,
  DELETE_BUDGET_DRIVER,
  SetBudgetDriversAction,
  SET_BUDGET_DRIVERS,
  UpdateBudgetDriverAction,
  UPDATE_BUDGET_DRIVER
} from './types'

export const setBudgetDrivers = (budgetDrivers: BudgetDriver[]): SetBudgetDriversAction => ({
  type: SET_BUDGET_DRIVERS,
  payload: budgetDrivers
})

export const addBudgetDriver = (budgetDriver: BudgetDriver): AddBudgetDriverAction => ({
  type: ADD_BUDGET_DRIVER,
  payload: budgetDriver
})

export const updateBudgetDriver = (budgetDriver: BudgetDriver): UpdateBudgetDriverAction => ({
  type: UPDATE_BUDGET_DRIVER,
  payload: budgetDriver
})

export const deleteBudgetDriver = (budgetDriver: BudgetDriver): DeleteBudgetDriverAction => ({
  type: DELETE_BUDGET_DRIVER,
  payload: budgetDriver
})

export const getBudgetDriversRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/budgeting/drivers`,
    success: (payload: BudgetDriver[]) => setBudgetDrivers(payload)
  },
  meta: {
    type: 'BUDGET_DRIVERS'
  }
})

export const createBudgetDriverRequest = (companyId: Company['id'], payload: BudgetDriver): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/drivers`,
    success: (response: BudgetDriver) => addBudgetDriver(response)
  },
  meta: {
    type: 'CREATE_BUDGET_DRIVER',
    notification: true
  }
})

export const updateBudgetDriverRequest = (companyId: Company['id'], payload: BudgetDriver): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/drivers/${payload.id}`,
    success: (response: BudgetDriver) => updateBudgetDriver(response)
  },
  meta: {
    type: 'UPDATE_BUDGET_DRIVER',
    notification: true
  }
})

export const deleteBudgetDriverRequest = (
  companyId: Company['id'],
  budgetDriverId: BudgetDriver['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/drivers/${budgetDriverId}`,
    success: (response: BudgetDriver) => deleteBudgetDriver(response)
  },
  meta: {
    type: 'DELETE_BUDGET_DRIVER',
    notification: true
  }
})

export const updateBudgetDriverOrdersRequest = (companyId: Company['id'], formulaOrders: any[]): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: formulaOrders,
    url: `/api/companies/${companyId}/budgeting/drivers/order`,
    success: (payload: BudgetDriver[]) => setBudgetDrivers(payload)
  },
  meta: {
    type: 'UPDATE_BUDGET_DRIVER_ORDERS'
  }
})
