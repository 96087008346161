import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePermission } from '../../redux/session/currentUser/hooks'
import { Company } from '../../types/company/Company'
import { Role } from '../../types/user/Role'
import { User } from '../../types/user/User'
import UserModal from './UserModal'
import UserTable from './UserTable'
import LoadingWrapper from '../Misc/LoadingWrapper'

interface UserPageProps {
  contractId: number
  users: User[]
  selectableCompanies: Company[]
  selectableRoles: Role[]
  loading: boolean
  createUserRequest: Function
  updateUserRequest: Function
  deleteUserRequest: Function
}

const UserPage: React.FC<UserPageProps> = (props: UserPageProps) => {
  const {
    contractId,
    users,
    selectableCompanies,
    selectableRoles,
    loading,
    createUserRequest,
    updateUserRequest,
    deleteUserRequest
  } = props
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [user, setUser] = useState<User>()
  const writeUsersPermission = usePermission('write-users')

  const toggleModalVisible = () => {
    if (modalVisible) setUser(undefined)
    setModalVisible(!modalVisible)
  }

  const toggleUser = (userToUpdate: User) => {
    setModalVisible(true)
    setUser(userToUpdate)
  }

  return (
    <LoadingWrapper loading={loading}>
      <UserModal
        contractId={contractId}
        user={user}
        visible={modalVisible}
        selectableCompanies={selectableCompanies}
        selectableRoles={selectableRoles}
        toggleModalVisible={toggleModalVisible}
        updateUserRequest={updateUserRequest}
        createUserRequest={createUserRequest}
      />
      {writeUsersPermission && (
        <Button onClick={toggleModalVisible} style={{ marginBottom: 8 }}>
          <PlusOutlined />
          {t('global:add-new')}
        </Button>
      )}
      <UserTable users={users} toggleUser={toggleUser} deleteUserRequest={deleteUserRequest} />
    </LoadingWrapper>
  )
}

export default UserPage
