/* eslint-disable prettier/prettier */
import { Cascader, Form, Tabs } from 'antd'
import _, { Dictionary } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingWrapper from '../../../../components/Misc/LoadingWrapper'
import { contextRequest } from '../../../../redux/context/actions'
import { updateCompanyRequest } from '../../../../redux/context/company/actions'
import { contextCompanySelector, contextCompanyCountrySelector, contextCompanyFormSelector } from '../../../../redux/context/company/selectors'
import { companySettingsLoadingSelector } from '../../../../redux/context/companySettings/selectors'
import { statementRowSelector, groupedStatementRowsSelector } from '../../../../redux/entities/statementRows/selectors'
import { DefaultAccountMapRule } from '../../../../types/accountMapRule/AccountMapRule'
import { Country, CompanyForm } from '../../../../types/company/Company'
import { StatementRow } from '../../../../types/statementRow/StatementRow'
import DefaultAccountMapTable from './DefaultAccountMapTable'

import '../../../../components/AccountMap/_AccountMap.sass'
import { AppDispatch } from '../../../../redux/store'


interface DefaultAccountMapProps {
  loading: boolean
  deleteAccountMapRule: Function
  createAccountMapRule: Function
  updateAccountMapRule: Function
  accountMapRulesByStatementRowId?: _.Dictionary<DefaultAccountMapRule[]>
  handleAccountantCascaderAction?: Function
}

// Account map page
const DefaultAccountMap: React.FC<DefaultAccountMapProps> = ({
  loading,
  deleteAccountMapRule,
  createAccountMapRule,
  updateAccountMapRule,
  accountMapRulesByStatementRowId,
  handleAccountantCascaderAction
}) => {
  const { t } = useTranslation()
  const [cascaderValues, setCascaderValues] = useState<[Country, CompanyForm]>([Country.fi, CompanyForm.limited])
  const statementRowsForAccountantSelection = useSelector(statementRowSelector)
  const statementRowsForCompanySelection = useSelector(groupedStatementRowsSelector)
  const groupedStatementRows: Dictionary<StatementRow[]> = handleAccountantCascaderAction
    ? _.groupBy(
      statementRowsForAccountantSelection.filter(
        (row: any) => row.country === cascaderValues[0] && row.companyForm === cascaderValues[1]
      ),
      'report'
    )
    : statementRowsForCompanySelection
  const { INCOME_STATEMENT: incomeStatement, ASSETS: assets, LIABILITIES: liabilities } = groupedStatementRows
  const updateCompanyLoading = useSelector(companySettingsLoadingSelector)
    const dispatch: AppDispatch = useDispatch()

  const company = useSelector(contextCompanySelector)
  const country = useSelector(contextCompanyCountrySelector)
  const companyForm = useSelector(contextCompanyFormSelector)

  const handleCascaderChange = (values?: any[]) => {
    if (!values) return
    const [newCountry, newCompanyForm] = values as [Country, CompanyForm]
    const newValues = {
      ...company,
      country: newCountry,
      form: newCompanyForm
    }
    dispatch(contextRequest(updateCompanyRequest, newValues))
  }

  const accountantCascader = (values: any[]) => {
    const selectedValues: [Country, CompanyForm] = values as [Country, CompanyForm]
    values && setCascaderValues(selectedValues)
    handleAccountantCascaderAction && handleAccountantCascaderAction(values)
  }

  interface CountryOption {
    value: Country
    label?: React.ReactNode
    disabled?: boolean
    children?: CompanyFormOption[]
  }
  interface CompanyFormOption {
    value: CompanyForm
    label?: React.ReactNode
    disabled?: boolean
  }

  const options: CountryOption[] = [
    {
      value: Country.fi,
      label: t(`country:${Country.fi}`),
      children: [
        {
          value: CompanyForm.limited,
          label: t(`accountMapPage:${CompanyForm.limited}`)
        },
        {
          value: CompanyForm.association,
          label: t(`accountMapPage:${CompanyForm.association}`)
        },
        {
          value: CompanyForm.realEstate,
          label: t(`accountMapPage:${CompanyForm.realEstate}`)
        }
      ]
    },
    {
      value: Country.se,
      label: t(`country:${Country.se}`),
      children: [
        {
          value: CompanyForm.limited,
          label: t(`accountMapPage:${CompanyForm.limited}`)
        }
      ]
    }
  ]

  if (!accountMapRulesByStatementRowId) return null

  const tabItems = [
    {
      label: t('financialStatementsPage:incomeStatement'),
      key: 'incomeStatement',
      children: (
        <DefaultAccountMapTable
          translation="incomeStatement"
          loading={loading}
          statementRows={incomeStatement}
          createAccountMapRule={createAccountMapRule}
          updateAccountMapRule={updateAccountMapRule}
          deleteAccountMapRule={deleteAccountMapRule}
          accountMapRulesByStatementRowId={accountMapRulesByStatementRowId}
        />
      )
    },
    {
      label: t('financialStatementsPage:assets'),
      key: 'assets',
      children: (
        <DefaultAccountMapTable
          translation="balanceSheet"
          statementRows={assets}
          loading={loading}
          createAccountMapRule={createAccountMapRule}
          updateAccountMapRule={updateAccountMapRule}
          deleteAccountMapRule={deleteAccountMapRule}
          accountMapRulesByStatementRowId={accountMapRulesByStatementRowId}
        />
      )
    },
    {
      label: t('financialStatementsPage:liabilities'),
      key: 'liabilities',
      children: (
        <DefaultAccountMapTable
          translation="balanceSheet"
          statementRows={liabilities}
          loading={loading}
          createAccountMapRule={createAccountMapRule}
          updateAccountMapRule={updateAccountMapRule}
          deleteAccountMapRule={deleteAccountMapRule}
          accountMapRulesByStatementRowId={accountMapRulesByStatementRowId}
        />
      )
    }
  ]

  return (
    <LoadingWrapper loading={!handleAccountantCascaderAction && updateCompanyLoading}>
      <Form.Item label={t('global:company-form')}>
        <Cascader<any>
          allowClear={false}
          defaultValue={handleAccountantCascaderAction ? cascaderValues : [country, companyForm]}
          options={options}
          onChange={handleAccountantCascaderAction ? accountantCascader : handleCascaderChange}
          placeholder="Please select"
        />
      </Form.Item>
      <Tabs defaultActiveKey="incomeStatement" items={tabItems} />
    </LoadingWrapper>
  )
}

export default DefaultAccountMap
