import React, { useContext, useEffect } from 'react'

import { Layout, Layouts } from 'react-grid-layout'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CustomReport } from '../../../../../redux/context/customReports/types'
import LayoutItem from './LayoutItem'
import { StyledResponsiveGridLayout } from './StyledComponents'
import { BREAKPOINTS, COLS, ROW_HEIGHT, getDataGrid } from '../grid.utils'
import { LayoutsContext, LayoutsContextType } from './LayoutContext'

const { Text, Title } = Typography

interface CustomReportLayoutEditorProps {
  report: CustomReport
}

const CustomReportLayoutEditor: React.FC<CustomReportLayoutEditorProps> = ({ report }) => {
  const { t } = useTranslation()
  const ref = React.createRef()
  const { layouts, setEditedLayouts, editedLayouts, currentBreakPoint, setCurrentBreakPoint } = useContext(
    LayoutsContext
  ) as LayoutsContextType

  useEffect(() => {
    setEditedLayouts(layouts)

    return () => {
      setEditedLayouts(undefined)
    }
  }, [])

  const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    const edited = Object.fromEntries(Object.entries(allLayouts).filter(([, value]) => value.length > 0))
    setEditedLayouts(edited)
  }

  return (
    <StyledResponsiveGridLayout
      layouts={editedLayouts}
      onLayoutChange={onLayoutChange}
      onBreakpointChange={setCurrentBreakPoint}
      breakpoints={BREAKPOINTS}
      cols={COLS}
      rowHeight={ROW_HEIGHT}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
    >
      {report.sections.map(section => (
        <LayoutItem
          ref={ref}
          key={section.id}
          data-grid={{
            ...getDataGrid(section, currentBreakPoint, editedLayouts),
            static: false,
            isDraggable: true,
            isResizable: true
          }}
          section={section}
          content={
            <div style={{ padding: 16 }}>
              <Title level={4}>{section.title}</Title>
              <Text strong type="secondary">
                {t(`customReportSectionType:${section.type}`)}
              </Text>
            </div>
          }
        />
      ))}
    </StyledResponsiveGridLayout>
  )
}

export default CustomReportLayoutEditor
