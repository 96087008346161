import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { Divider, Popover, Table, Tag, Typography } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useBackend } from '../../../services/backend'
import { Delivery, DeliveryStatus } from '../../../types/deliverySubscription/Delivery'
import { notificationAction } from '../../../redux/middleware/actions'
import { useTableProps } from '../../../utils/hooks'
import { DeliverySubscription } from '../../../types/deliverySubscription/DeliverySubscription'
import { renderDeliverySubscriptionInitiator } from '../../settings/accountant/subscriptions/company-dashboard/utils'
import { DeliverySearch } from './DeliverySearch'
import { Loading } from '../../../components/Misc/Loading'
import { AppDispatch } from '../../../redux/store'

const Deliveries: React.FC = () => {
  const deliveriesRequest = useBackend(`/api/superuser/deliveries`)
  const { t } = useTranslation()
  const { loading } = deliveriesRequest
  const dispatch: AppDispatch = useDispatch()

  const [deliverySubscriptions, setDeliverySubscriptions] = useState<DeliverySubscription[]>([])

  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)

  const request = (params?: any) => {
    deliveriesRequest
      .get({ body: { params } })
      .then(setDeliverySubscriptions)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'DELIVERIES_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(request, [])

  const expandedRowRender = (deliverySubscription: DeliverySubscription) => {
    const columns: ColumnProps<Delivery>[] = [
      {
        title: t('subscriptionsPage:deliveryEndTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        render: (text: string) => {
          return dayjs(text).format('lll')
        }
      },
      {
        title: t('status:status'),
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => {
          const tr = (s: string) => {
            return t(`status:${s.toLowerCase()}`)
          }
          switch (text) {
            case DeliveryStatus.FAILED:
              return <Tag color="red">{tr(text)}</Tag>
            case DeliveryStatus.FINISHED:
              return <Tag color="green">{tr(text)}</Tag>
            case DeliveryStatus.PENDING:
            default:
              return <Tag>{tr(text)}</Tag>
          }
        }
      },
      {
        title: t('error:error'),
        dataIndex: 'errors',
        key: 'errors',
        ellipsis: true,
        render: (text: string) =>
          text && (
            <Popover
              content={
                <Typography.Text code copyable>
                  {text}
                </Typography.Text>
              }
            >
              <Typography.Text code>{text}</Typography.Text>
            </Popover>
          )
      }
    ]

    return deliverySubscription.deliveries && deliverySubscription.deliveries.length ? (
      <Table
        size="small"
        columns={columns}
        dataSource={(deliverySubscription.deliveries || []).sort(
          (a, b) => (dayjs(b?.endTime).valueOf() || 0) - (dayjs(a?.endTime).valueOf() || 0)
        )}
        pagination={false}
      />
    ) : (
      <div>Ei Lähetyksiä</div>
    )
  }

  const columns: ColumnProps<DeliverySubscription>[] = [
    {
      title: t('subscriptionsPage:company'),
      key: 'company',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.company?.name.localeCompare(b?.company?.name || '') || 0,
      render: (deliverySubscription: DeliverySubscription) =>
        deliverySubscription.company && deliverySubscription.company.name
    },
    {
      title: 'Tyyppi',
      key: 'type',
      dataIndex: 'type',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.type.localeCompare(b?.type || '') || 0,
      ellipsis: true
    },
    {
      title: t('subscriptionsPage:user'),
      key: 'user',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.user?.displayName.localeCompare(b?.user?.displayName || '') || 0,
      render: (deliverySubscription: DeliverySubscription) =>
        deliverySubscription.user && deliverySubscription.user.displayName
    },
    {
      title: t('subscriptionsPage:initiator'),
      key: 'initiator',
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => (a?.initiator?.monthly?.day || 0) - (b?.initiator?.monthly?.day || 0) || 0,
      render: renderDeliverySubscriptionInitiator
    },
    {
      title: t('subscriptionsPage:latestPeriodLock'),
      key: 'periodLock',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        (dayjs(a?.latestPeriodLock || '').valueOf() || 0) - (dayjs(b?.latestPeriodLock || '').valueOf() || 0),
      render: (deliverySubscription: DeliverySubscription) => {
        return deliverySubscription.latestPeriodLock && dayjs(deliverySubscription.latestPeriodLock).format('lll')
      }
    },
    {
      title: t('subscriptionsPage:lastDeliveryAt'),
      key: 'lastDeliveryAt',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        (dayjs(a?.lastDeliveryAt || '').valueOf() || 0) - (dayjs(b?.lastDeliveryAt || '').valueOf() || 0),
      render: (deliverySubscription: DeliverySubscription) => {
        return deliverySubscription.lastDeliveryAt && dayjs(deliverySubscription.lastDeliveryAt).format('lll')
      }
    },
    {
      title: 'Lähetyksiä',
      key: 'deliveryCount',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.deliveries?.length || 0) - (b?.deliveries?.length || 0),
      render: (deliverySubscription: DeliverySubscription) => {
        return deliverySubscription.deliveries && deliverySubscription.deliveries.length
      }
    }
  ]

  return (
    <div ref={contentEl}>
      <DeliverySearch request={request} />
      <Divider />
      {loading ? (
        <Loading />
      ) : (
        deliverySubscriptions.length > 0 && (
          <Table
            {...tableProps}
            rowKey={record => record.id.toString()}
            size="small"
            dataSource={deliverySubscriptions}
            expandedRowRender={(deliverySubscription: DeliverySubscription) => expandedRowRender(deliverySubscription)}
            columns={columns}
          />
        )
      )}
    </div>
  )
}

export default Deliveries
