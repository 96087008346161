import React from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import { roleBasedKeyFigureSelector } from '../../../../../../../redux/entities/keyFigures/selectors'
import { KeyFigure } from '../../../../../../../types/keyFigure/KeyFigure'
import DashboardItemFields from '../DasboardItemFieds'
import SelectionTableInput from '../SelectionTableInput'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'

const KeyFigureTableFields = () => {
  const { t } = useTranslation()
  const selectableKeyFigures = useSelector(roleBasedKeyFigureSelector)

  const columns: ColumnProps<KeyFigure>[] = [
    {
      title: t(`keyFigurePage:key-figure`),
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('id', val => t(`keyFigureStatement:${val.id || val}`))
    }
  ]

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('global:keyFigure')} name={['params', 'keyFigureIds']}>
        <SelectionTableInput<KeyFigure> dataSource={selectableKeyFigures} columns={columns} />
      </Form.Item>
    </>
  )
}

export default KeyFigureTableFields
