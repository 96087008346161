import { Language } from '../../types/user/User'

export interface AppState {
  language: Language
}

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export interface ChangeLanguageAction {
  type: typeof CHANGE_LANGUAGE
  payload: Language
}

export type AppStateActionTypes = ChangeLanguageAction
