import { Formula } from '../../../types/formula/Formula'

export const SET_FORMULAS = 'SET_FORMULAS'
export const ADD_FORMULA = 'ADD_FORMULA'
export const UPDATE_FORMULA = 'UPDATE_FORMULA'
export const DELETE_FORMULA = 'DELETE_FORMULA'

export interface SetFormulasAction {
  type: typeof SET_FORMULAS
  payload: Formula[]
}

export interface AddFormulaAction {
  type: typeof ADD_FORMULA
  payload: Formula
}

export interface UpdateFormulaAction {
  type: typeof UPDATE_FORMULA
  payload: Formula
}

export interface DeleteFormulaAction {
  type: typeof DELETE_FORMULA
  payload: Formula
}

export type FormulasActionTypes = SetFormulasAction | AddFormulaAction | UpdateFormulaAction | DeleteFormulaAction
