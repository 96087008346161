import { SetIndustryComparisonData, SET_INDUSTRY_COMPARISON_DATA } from './types'

const initialState: any[] = []

const industryComparisonReducer = (state = initialState, action: SetIndustryComparisonData) => {
  switch (action.type) {
    case SET_INDUSTRY_COMPARISON_DATA:
      return action.payload
    default:
      return state
  }
}

export default industryComparisonReducer
