import { Cascader, Form, Input, Modal, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import {
  addCompanyDashboard,
  createCompanyDashboardRequest,
  updateCompanyDashboardRequest
} from '../../../../redux/context/dashboard/company/actions'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../../services/backend'
import { CompanyDashboard } from '../../../../types/dashboard/company/types'
import { mapObjectToFieldData } from '../../../../utils/form'
import { AppDispatch } from '../../../../redux/store'

interface DashboardModalProps {
  visible: 'edit' | 'new' | false
  dashboard?: CompanyDashboard
  setVisible: React.Dispatch<React.SetStateAction<false | 'edit' | 'new'>>
}

const DashboardModal: React.FC<DashboardModalProps> = ({ visible, setVisible, dashboard }) => {
  const [form] = Form.useForm<{ id?: number; name: string }>()
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const userCompanies = useSelector(currentUserSortedCompaniesSelector)
  const [companies, setCompanies] = useState(userCompanies.map(({ id, name }) => ({ id, title: name, isLeaf: false })))
  const dashboardRequest = useBackend(`/api/companies/{companyId}/dashboards`)
  const copyDashboardRequest = useBackend('/api/companies/{companyId}/dashboards/copy/{dashboardId}')
  const [copySelection, setCopySelection] = useState<(string | number)[]>([])
  const [newDashboardMode, setNewDashboardMode] = useState<'new' | 'copy'>('new')
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (visible === 'edit') {
      const { id, name } = dashboard || {}
      const fields = mapObjectToFieldData({ id, name }) as any
      form.setFields(fields)
    }
  }, [dashboard, visible])

  const handleSave = async () => {
    setConfirmLoading(true)
    form
      .validateFields()
      .then(async values => {
        if (!companyId) return
        if (visible === 'new') {
          switch (newDashboardMode) {
            case 'new':
              dispatch(createCompanyDashboardRequest(companyId, values.name))
              break
            case 'copy':
              try {
                const [, dashboardId] = copySelection
                const response = await copyDashboardRequest.post({ urlParams: { companyId, dashboardId } })
                dispatch(addCompanyDashboard(response))
              } catch (error) {
                console.log(error)
              }
              break
            default:
              break
          }
        }
        if (visible === 'edit') {
          values.id && dispatch(updateCompanyDashboardRequest(companyId, values, values.id))
        }
        form.resetFields()
        setVisible(false)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
    setConfirmLoading(false)
    setNewDashboardMode('new')
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  const onChange = (value: any) => {
    setCopySelection(value)
  }

  const loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    try {
      const response = await dashboardRequest.get({ urlParams: { companyId: targetOption.id } })
      targetOption.loading = false

      targetOption.children =
        response.length === 0
          ? [{ title: t('global:no-results'), id: null, isLeaf: true, disabled: true }]
          : response.map((r: CompanyDashboard) => ({ ...r, title: r.name || t('global:default') }))
      setCompanies([...companies])
    } catch (error) {
      targetOption.loading = false
      console.log(error)
    }
  }

  const filter = (inputValue: any, path: any) => {
    return path.some((option: any) => option?.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1)
  }

  return (
    <Modal
      title={visible === 'edit' ? t('global:edit') : t('dashboardPage:add-new-dashboard')}
      destroyOnClose
      open={!!visible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      width="unset"
      maskClosable={false}
      style={{ maxWidth: 700 }}
      okButtonProps={{ loading: confirmLoading, disabled: copySelection.length < 2 && newDashboardMode === 'copy' }}
      confirmLoading={confirmLoading}
    >
      {visible === 'new' && (
        <Radio.Group
          onChange={e => {
            e.preventDefault()
            setNewDashboardMode(e.target.value)
          }}
          value={newDashboardMode}
          style={{ marginBottom: 16 }}
        >
          <Radio value="new">{t('contractsPage:add-new')}</Radio>
          <Radio value="copy">{t('global:copy')}</Radio>
        </Radio.Group>
      )}
      {newDashboardMode === 'new' && (
        <div>
          <Form form={form} name="companyDashboard" layout="vertical">
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label={t('global:name')}
              rules={[{ required: true, message: t('global:required-field') }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      )}
      {newDashboardMode === 'copy' && (
        <Form layout="vertical">
          <Form.Item name="reportId" label={t('global:copy')}>
            <Cascader<any>
              value={copySelection}
              fieldNames={{ label: 'title', value: 'id', children: 'children' }}
              options={companies}
              placeholder={t('global:choose-here')}
              loadData={loadData}
              onChange={onChange}
              showSearch={{ filter }}
              changeOnSelect
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default DashboardModal
