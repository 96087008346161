import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { GraphType } from '../../../types'
import DashboardTopItemFields from '../DashboardTopItemFields'

const SalesTopChartFields = () => {
  const { t } = useTranslation()

  return (
    <>
      <DashboardTopItemFields />
      <Form.Item label={t('dashboardPage:chart-type')} name={['params', 'representation', 'options', 'type']}>
        <Select
          options={Object.values(GraphType).map(type => ({
            value: type,
            label: t(`dashboardPage:${type}`)
          }))}
        />
      </Form.Item>
    </>
  )
}

export default SalesTopChartFields
