/* eslint-disable no-nested-ternary */
import { ReportDataType } from '../reports/types'
import {
  FilterActionTypes,
  Filters,
  SET_DATATYPE_FILTER,
  SET_FISCAL_YEAR_FILTER,
  TOGGLE_DISPLAY_EMPTY_ROWS,
  TOGGLE_DATATYPE_FILTER,
  SET_BUDGETING_SCENARIO_FILTER,
  SET_CONTEXT_FILTER,
  SET_CUMULATIVE_FILTER,
  TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE,
  ADD_PERIOD_GROUP,
  REMOVE_PERIOD_GROUP,
  SET_PERIOD_GROUP_FILTER,
  TOGGLE_CUMULATIVE_FILTER,
  SET_PERIOD_GROUPS,
  SET_DIMENSIONS_FILTER,
  RESET_CONTEXT_FILTER
} from './types'

const initialState: Filters = {
  fiscalYear: null,
  periodGroup: undefined,
  dataTypes: [ReportDataType.actuals, ReportDataType.budget],
  displayEmptyRows: false,
  cumulativeFilter: undefined,
  showIncomeStatemetPercentageChange: false,
  periodGroups: undefined,
  dimensions: []
}

const filtersReducer = (state = initialState, action: FilterActionTypes): Filters => {
  switch (action.type) {
    case RESET_CONTEXT_FILTER:
      return initialState
    case SET_CONTEXT_FILTER:
      return { ...state, ...action.payload }
    case SET_FISCAL_YEAR_FILTER:
      return { ...state, fiscalYear: action.payload }
    case SET_CUMULATIVE_FILTER:
      return { ...state, cumulativeFilter: action.payload }
    case SET_DIMENSIONS_FILTER:
      return { ...state, dimensions: action.payload }
    case SET_BUDGETING_SCENARIO_FILTER:
      return { ...state, budgetingScenario: action.payload }
    case SET_DATATYPE_FILTER:
      return { ...state, dataTypes: action.payload }
    case TOGGLE_DATATYPE_FILTER:
      return {
        ...state,
        dataTypes: state.dataTypes.includes(action.payload)
          ? state.dataTypes.filter(d => d !== action.payload)
          : state.dataTypes.concat(action.payload)
      }
    case TOGGLE_DISPLAY_EMPTY_ROWS:
      return { ...state, displayEmptyRows: !state.displayEmptyRows }
    case TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE:
      return {
        ...state,
        showIncomeStatemetPercentageChange: !state.showIncomeStatemetPercentageChange
      }
    case SET_PERIOD_GROUPS:
      return { ...state, periodGroups: action.payload }

    case ADD_PERIOD_GROUP:
      return {
        ...state,
        periodGroups: [...(state?.periodGroups || []), action.payload]
      }
    case REMOVE_PERIOD_GROUP:
      return {
        ...state,
        periodGroups: state?.periodGroups?.filter(period => period.id !== action.payload.id)
      }
    case SET_PERIOD_GROUP_FILTER:
      return { ...state, periodGroup: action.payload }
    case TOGGLE_CUMULATIVE_FILTER:
      return {
        ...state,
        cumulativeFilter: !state.cumulativeFilter
          ? action.payload
          : action.payload.month === state.cumulativeFilter.month && action.payload.year === state.cumulativeFilter.year
          ? undefined
          : action.payload
      }
    default:
      return state
  }
}

export default filtersReducer
