import { Form, Input } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { cashFlowStatementGraphReportSelector } from '../../../../../../../redux/config/cashFlowStatement/selectors'
import { sleep } from '../../../../../../../utils/helpers'
import { CompanyDashboardItemContext } from '../../DashboardItemProvider'
import DashboardItemFields from '../DasboardItemFieds'
import SelectionTableInput from '../SelectionTableInput'

const CashFlowChartItemFields = () => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const { companyDashboardItemPreferenceSelection } = useContext(CompanyDashboardItemContext)!
  const {
    params: { type, selectedGraph }
  } = companyDashboardItemPreferenceSelection!

  const rows = useSelector(cashFlowStatementGraphReportSelector(type, selectedGraph))

  const columns = [
    {
      title: t(`global:name`),
      dataIndex: 'title'
    }
  ]

  useEffect(() => {
    const updateSelectedRows = async () => {
      // TODO: hax fix me
      await sleep(1)
      const ids = rows.map((c: any) => c.id)
      form.setFieldValue(['params', 'selectedRows'], ids)
    }
    updateSelectedRows()
  }, [companyDashboardItemPreferenceSelection])

  return (
    <>
      <DashboardItemFields />
      <Form.Item hidden label={t(`cashFlowPage:${selectedGraph}`)} name={['params', 'selectedGraph']}>
        <Input />
      </Form.Item>
      <Form.Item label={t(`cashFlowPage:${selectedGraph}`)} name={['params', 'selectedRows']}>
        <SelectionTableInput dataSource={rows} columns={columns} />
      </Form.Item>
    </>
  )
}

export default CashFlowChartItemFields
