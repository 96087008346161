import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { SET_SESSION_ACCOUNT_MAP_RULES, SetSessionAccountMapRulesAction } from './types'
import { CompanyForm, Country } from '../../../types/company/Company'
import { GetRequestAction, REQUEST, PUT, GET } from '../../middleware/types'
import { AccountMapRule, DefaultAccountMapRule } from '../../../types/accountMapRule/AccountMapRule'
import { Store } from '../../types'
import { Contract } from '../../../types/contract/Contract'

export const setSessionAccountMapRules = (payload: DefaultAccountMapRule[]): SetSessionAccountMapRulesAction => ({
  type: SET_SESSION_ACCOUNT_MAP_RULES,
  payload
})

export const setSessionAccountMapRulesWithIds = (
  payload: DefaultAccountMapRule[]
): ThunkAction<void, Store, null, Action<string>> => {
  return async dispatch => {
    console.log(payload)
    const rulesWithIds = payload?.map((rule: DefaultAccountMapRule, index: number) => {
      return { ...rule, id: rule.id || `${'DEFAULT-'}${index.toString()}` }
    })

    dispatch(setSessionAccountMapRules(rulesWithIds))
  }
}

export const getSessionAccountMapRulesRequest = (
  contractId: Contract['id'],
  country: Country,
  companyForm: CompanyForm
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/session/accountant/${contractId}/default-account-map/${country}/${companyForm}`,
    success: (payload: DefaultAccountMapRule[]) => setSessionAccountMapRulesWithIds(payload)
  },
  meta: {
    type: 'SESSION_ACCOUNT_MAP_RULES'
  }
})

export const updateSessionAccountMapRulesRequest = (
  contractId: Contract['id'],
  country: Country,
  companyForm: CompanyForm,
  rules: DefaultAccountMapRule[]
) => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: rules,
    url: `/api/session/accountant/${contractId}/default-account-map/${country}/${companyForm}`,
    success: (payload: any) => {
      return setSessionAccountMapRulesWithIds(payload)
    }
  },
  meta: {
    type: 'UPDATE_ACCOUNT_MAP_RULES',
    notification: true
  }
})

const idTransform = (data: DefaultAccountMapRule[]): DefaultAccountMapRule[] => {
  console.log(data)
  return data.map(d => ({
    ...d,
    id: d?.id?.toString().includes('NEW_RULE') || d?.id?.toString().includes('DEFAULT') ? undefined : d.id
  }))
}

export const updateSessionAccountMapRule = (
  contractId: Contract['id'],
  country: Country,
  companyForm: CompanyForm,
  payload: AccountMapRule
): ThunkAction<void, Store, null, Action<string>> => {
  return async (dispatch, getState) => {
    const store: Store = getState()

    const { accountMapRules } = store.session
    const { companyId } = store.session.contract

    if (accountMapRules && companyId) {
      const newRules: DefaultAccountMapRule[] = accountMapRules.map((rule: AccountMapRule) => {
        return rule.id !== payload.id ? rule : { ...rule, ...payload }
      }) as DefaultAccountMapRule[]

      dispatch(updateSessionAccountMapRulesRequest(contractId, country, companyForm, idTransform(newRules)))
    }
  }
}

export const createSessionAccountMapRule = (
  contractId: Contract['id'],
  country: Country,
  companyForm: CompanyForm,
  payload: DefaultAccountMapRule
): ThunkAction<void, Store, null, Action<string>> => {
  return async (dispatch, getState) => {
    const store: Store = getState()

    const { accountMapRules } = store.session
    const { companyId } = store.session.contract

    if (accountMapRules && companyId) {
      const newRules: DefaultAccountMapRule[] = [...accountMapRules, payload] as DefaultAccountMapRule[]
      console.log({ accountMapRules, newRules, payload })
      dispatch(updateSessionAccountMapRulesRequest(contractId, country, companyForm, idTransform(newRules)))
    }
  }
}

export const deleteSessionAccountMapRule = (
  contractId: Contract['id'],
  country: Country,
  companyForm: CompanyForm,
  id: string
): ThunkAction<void, Store, null, Action<string>> => {
  return async (dispatch, getState) => {
    const store: Store = getState()

    const { accountMapRules } = store.session
    const { companyId } = store.session.contract

    if (accountMapRules && companyId) {
      const newRules: DefaultAccountMapRule[] = accountMapRules.filter(
        (rule: AccountMapRule) => rule.id !== id
      ) as DefaultAccountMapRule[]
      dispatch(updateSessionAccountMapRulesRequest(contractId, country, companyForm, idTransform(newRules)))
    }
  }
}
