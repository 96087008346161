export interface CompanyDashboardPage {
  activeDashboard: number | null
}

export const SET_ACTIVE_COMPANY_DASHBOARD = 'SET_ACTIVE_COMPANY_DASHBOARD'

export interface SetActiveCompanyDasboardAction {
  type: typeof SET_ACTIVE_COMPANY_DASHBOARD
  payload: number | null
}

export type CompanyDashboardActionTypes = SetActiveCompanyDasboardAction
