/* eslint-disable react/jsx-boolean-value */
import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Input, Select, Form, Radio, Space, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RuleObject } from 'antd/lib/form'
import { sessionContractUserSelector, sessionContractSelector } from '../../../redux/session/contract/selectors'
import { useBackend } from '../../../services/backend'
import { User } from '../../../types/user/User'
import CompanyForm from './CompanyForm'
import { CompanyType, ContractFormContext, ContractFormContextType } from './ContractFormContext'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { testVatId } from '../../../utils/helpers'
import { UserFormFields } from '../../User/UserFormFields'
import { FormItemGroup } from '../../Form/FormItemContext'

const { Option } = Select

const PartiesStep = () => {
  const { t } = useTranslation()
  const sessionContract = useSelector(sessionContractSelector)
  const users = useSelector(sessionContractUserSelector)
  const companySearchRequest = useBackend('/api/companies')
  const userCompanies = useSelector(currentUserSortedCompaniesSelector)
  const [companyModalVisible, setCompanyModalVisible] = useState(false)
  const {
    form,
    company,
    showCompanyAdminForm,
    companyType,
    setCompanyType,
    setStep,
    handleCompanyChange,
    toggleCompanyAdminForm
  } = useContext(ContractFormContext) as ContractFormContextType

  const toggleCompanyModalVisible = (vatId?: string) => {
    setCompanyModalVisible(!companyModalVisible)
    if (vatId) {
      form.setFields([{ name: ['contract', 'company', 'id'], value: vatId }])
      form.validateFields([['contract', 'company', 'id']])
    }
  }

  const companyNotFound = () => {
    return (
      <>
        <p>{t(`error:${companySearchRequest.error?.current?.data?.message}`)}</p>
        <Button onClick={() => toggleCompanyModalVisible()}>{t('contractsPage:add-company')}</Button>
      </>
    )
  }

  const isValidCompany = async (__: RuleObject, vatId: string) => {
    handleCompanyChange(null)
    const trimmedVatId = vatId.trim()
    const valid = testVatId(vatId)

    if (valid) {
      try {
        const response = await companySearchRequest.get({ body: { params: { vatId: trimmedVatId } } })
        handleCompanyChange(response[0])
      } catch (error) {
        handleCompanyChange(null)
        return Promise.reject(companyNotFound)
      }
    } else {
      return Promise.reject(t('contractsPage:business-id-not-acceptable'))
    }
    return null
  }

  const wrapperColStyle = { span: 14, offset: 6 }

  return (
    <>
      <CompanyForm
        companyType={companyType}
        visible={companyModalVisible}
        vatId={form.getFieldValue(['contract', 'companyId'])}
        toggleCompanyModalVisible={toggleCompanyModalVisible}
        handleCompanyChange={handleCompanyChange}
      />
      <Form.Item wrapperCol={wrapperColStyle}>
        <Typography.Title level={4} type="secondary">
          {t('contractsPage:company')}
        </Typography.Title>
      </Form.Item>
      <Form.Item wrapperCol={wrapperColStyle}>
        <Radio.Group onChange={e => setCompanyType(e.target.value)} value={companyType}>
          <Space direction="vertical">
            <Radio value={CompanyType.normal}>{t('contractsPage:normal-company')}</Radio>
            <Radio value={CompanyType.imaginary}>{t('contractsPage:imaginary-company')}</Radio>
            <Radio value={CompanyType.group}>{t('contractsPage:concern-company')}</Radio>
            <Radio value={CompanyType.elimination}>{t('accountMapPage:elimination')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {companyType === CompanyType.normal && (
        <>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.contract.companyId !== curValues.contract.companyId}
            noStyle
          >
            {() => (
              <Form.Item
                name={['contract', 'company', 'id']}
                hasFeedback
                preserve
                rules={[
                  {
                    validator: isValidCompany
                  },
                  {
                    required: true,
                    message: t('global:required-field')
                  }
                ]}
                label={t('contractsPage:vatId')}
              >
                <Input />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={t('contractsPage:name')}>
            <span className="ant-form-text">{company && company.name}</span>
          </Form.Item>
        </>
      )}
      {(companyType === CompanyType.imaginary || companyType === CompanyType.elimination) && (
        <>
          <Form.Item wrapperCol={wrapperColStyle}>
            <Button block onClick={() => toggleCompanyModalVisible()}>
              {t('contractsPage:add-company')}
            </Button>
          </Form.Item>
          <Form.Item label={t('contractsPage:name')}>
            <span className="ant-form-text">{company && company.name}</span>
          </Form.Item>
        </>
      )}
      {companyType === CompanyType.group && (
        <>
          <Form.Item
            label={t('contractsPage:name')}
            name={['contract', 'company', 'name']}
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('contractsPage:parent-company')}
            name={['contract', 'company', 'groupId']}
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
          >
            <Select showSearch optionFilterProp="children">
              {(userCompanies || []).map(userCompany => (
                <Option value={userCompany.id} key={userCompany.id} className={userCompany.name}>
                  {userCompany.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      <div style={{ display: showCompanyAdminForm ? 'block' : 'none' }}>
        <Form.Item wrapperCol={wrapperColStyle}>
          <Typography.Title level={4} type="secondary">
            {t('contractsPage:master-user')}
            <MinusCircleOutlined
              style={{ marginLeft: '8px' }}
              className="dynamic-delete-button"
              onClick={toggleCompanyAdminForm}
            />
          </Typography.Title>
        </Form.Item>
        <FormItemGroup prefix={['masterUser']}>
          <UserFormFields required={showCompanyAdminForm} />
        </FormItemGroup>
      </div>
      <Form.Item style={{ display: !showCompanyAdminForm ? 'block' : 'none' }} wrapperCol={wrapperColStyle}>
        <Button style={{ width: '100%' }} type="dashed" onClick={toggleCompanyAdminForm}>
          {`+ ${t('contractsPage:add-master-user')}`}
        </Button>
      </Form.Item>
      <Form.Item wrapperCol={wrapperColStyle}>
        <Typography.Title level={4} type="secondary">
          {sessionContract.name}
        </Typography.Title>
      </Form.Item>
      <Form.Item
        name="users"
        preserve
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        label={t('contractsPage:contact-person')}
      >
        <Select
          placement="topLeft"
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          showSearch
          optionFilterProp="children"
        >
          {users.map((user: User) => (
            <Option value={user.id} key={user.id}>
              {user.displayName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={wrapperColStyle}>
        <Button type="primary" onClick={() => setStep(1)}>
          {t('contractsPage:continue')}
        </Button>
      </Form.Item>
    </>
  )
}

export default PartiesStep
