import { CustomExchangeRateSeries } from '../../../pages/settings/company/group/types'
import { Company } from '../../../types/company/Company'
import { GET, GetRequestAction, REQUEST } from '../../middleware/types'
import { SetExchangeRatesAction, SET_EXCHANGE_RATES, UPDATE_EXCHANGE_RATES, UpdateExchangeRateAction } from './types'

export const setExchangeRates = (exchangeRates: CustomExchangeRateSeries[]): SetExchangeRatesAction => ({
  type: SET_EXCHANGE_RATES,
  payload: exchangeRates
})

export const updateExchangeRate = (exchangeRates: CustomExchangeRateSeries): UpdateExchangeRateAction => ({
  type: UPDATE_EXCHANGE_RATES,
  payload: exchangeRates
})

export const getExchangeRatesRequest = (groupId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `api/companies/${groupId}/group/exchange-rates`,
    success: (response: CustomExchangeRateSeries[]) => setExchangeRates(response)
  },
  meta: {
    type: 'EXCHANGE_RATES'
  }
})
