import { EMPTY_GROUP_SETTINGS, GroupSettingsActionTypes, SET_GROUP_SETTINGS, UPDATE_GROUP_SETTINGS } from './types'

const initialState = {}

const groupSettingsReducer = (state = initialState, action: GroupSettingsActionTypes) => {
  switch (action.type) {
    case SET_GROUP_SETTINGS:
      return action.payload
    case UPDATE_GROUP_SETTINGS:
      return { ...state, ...action.payload }
    case EMPTY_GROUP_SETTINGS:
      return {}
    default:
      return state
  }
}

export default groupSettingsReducer
