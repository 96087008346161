import i18next from 'i18next'
import { useModal } from '../Modal/useModal'
import VouchersModalContent, { VouchersModalProps } from './VouchersModalContent'

export const useVourcherModal = () => {
  return useModal<VouchersModalProps>(VouchersModalContent, {
    customTitle: ({ row }) => `${i18next.t('global:vouchers')}  ${row?.title}`,
    width: '',
    className: 'voucher-modal',
    destroyOnClose: true
  })
}
