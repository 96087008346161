import { Company } from '../../../types/company/Company'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { GET, GetRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { PerformanceIndicator, SetKeyPerformanceIndicatorsAction, SET_KEY_PERFORMANCE_INDICATORS } from './types'
import { ContextRequestParams } from '../actions'

export const setKeyPerformanceIndicators = (
  keyPerformanceIndicators: KeyFigure[]
): SetKeyPerformanceIndicatorsAction => ({
  type: SET_KEY_PERFORMANCE_INDICATORS,
  payload: keyPerformanceIndicators
})

export const getPerformanceIndicatorsRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/key-figures/key-performance-indicators`,
    success: (payload: KeyFigure[]) => setKeyPerformanceIndicators(payload)
  },
  meta: {
    type: 'KEY_PERFORMANCE_INDICATORS'
  }
})

export const updateKeyPerformanceIndicatorsRequest = (
  companyId: Company['id'],
  data: PerformanceIndicator[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/settings/key-figures/key-performance-indicators`,
    success: (payload: KeyFigure[]) => setKeyPerformanceIndicators(payload)
  },
  meta: {
    type: 'UPDATE_KEY_PERFORMANCE_INDICATORS',
    notification: true
  }
})
