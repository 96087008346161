import React, { useContext } from 'react'
import { Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReallocationContext, ReallocationContextType } from './ReallocationProvider'
import ReallocationTargetFormFields from './form/ReallocationTargetFormFields'

const ReallocationModal = () => {
  const { t } = useTranslation()
  const { targetModalVisible, targetForm, handleSaveTarget, handleCloseTarget } = useContext(
    ReallocationContext
  ) as ReallocationContextType

  return (
    <Modal
      open={targetModalVisible}
      title={t('reallocationsPage:reallocation-target')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      onOk={handleSaveTarget}
      onCancel={handleCloseTarget}
      destroyOnClose
      forceRender={false}
      maskClosable={false}
      width={800}
    >
      <Form form={targetForm} layout="vertical" name="reallocation_form" autoComplete="off">
        <ReallocationTargetFormFields />
      </Form>
    </Modal>
  )
}

export default ReallocationModal
