import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { CashFlowStatementConfig, SetCashFlowStatementConfigAction, SET_CASH_FLOW_STATEMENT_CONFIG } from './types'

export const setCashFlowStatementConfig = (
  cashFlowStatementConfig: CashFlowStatementConfig
): SetCashFlowStatementConfigAction => ({
  type: SET_CASH_FLOW_STATEMENT_CONFIG,
  payload: cashFlowStatementConfig
})

export const getCashFlowStatementConfigRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: '/api/config/cash-flow-statement',
    success: setCashFlowStatementConfig
  },
  meta: {
    type: 'CASH_FLOW_STATEMENT_CONFIG'
  }
})
