import { Product } from '../../../types/product/product'
import { SetProductsAction, SET_PRODUCTS } from './types'

const initialState: Product[] = []

const productsReducer = (state = initialState, action: SetProductsAction) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export default productsReducer
