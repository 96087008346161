import { Budget } from '../../../../pages/budgeting/financialStatements/components/budget/types'
import { ReportRow } from '../../../context/reports/types'

export interface FinancialStatementsPage {
  budgetsInEdit: { [code: string]: boolean }
  driversInEdit: { [code: string]: boolean }
  reallocations: { [accountCode: string]: {} }
  contextMenu: any
}

export interface ReallocationData {
  unallocated: ReportRow
  sources: ReportRow
  targets: ReportRow
}

export const RESET_FINANCIAL_STATEMENTS_PAGE = 'RESET_FINANCIAL_STATEMENTS_PAGE'
export const RESET_FINANCIAL_STATEMENTS_BUDGETS = 'RESET_FINANCIAL_STATEMENTS_BUDGETS'
export const SET_FINANCIAL_STATEMENTS_BUDGET = 'SET_FINANCIAL_STATEMENTS_BUDGET'
export const TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT = 'TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT'
export const TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT = 'TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT'
export const SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT = 'SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT'
export const TOGGLE_CONTEXT_MENU = 'TOGGLE_CONTEXT_MENU'
export const TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT = 'TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT'
export const RESET_ALL_FINANCIAL_STATEMENTS_BUDGETS = 'RESET_ALL_FINANCIAL_STATEMENTS_BUDGETS'
export const SET_FINANCIAL_STATEMENT_REALLOCATION = 'SET_FINANCIAL_STATEMENT_REALLOCATION'

export interface SetFinancialStatementReallocationAction {
  type: typeof SET_FINANCIAL_STATEMENT_REALLOCATION
  payload: { data: ReallocationData; accountCode: string }
}

export interface ResetFinancialStatementsPageAction {
  type: typeof RESET_FINANCIAL_STATEMENTS_PAGE
}
export interface ResetFinancialStatementsBudgetsAction {
  type: typeof RESET_FINANCIAL_STATEMENTS_BUDGETS
  payload: string
}

export interface ResetAllFinancialStatementsBudgetsAction {
  type: typeof RESET_ALL_FINANCIAL_STATEMENTS_BUDGETS
}

export interface SetFinancialStatementsBudgetAction {
  type: typeof SET_FINANCIAL_STATEMENTS_BUDGET
  payload: { code: string; budget: Budget }
}

export interface ToggleFinancialStatementsBudgetInEditAction {
  type: typeof TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT
  payload: string
}

export interface ToggleFinancialStatementsDriverInEditAction {
  type: typeof TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT
  payload: string
}

export interface ToggleAllFinancialStatementsBudgetInEditAction {
  type: typeof TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT
}

export interface SetFinancialStatementsBudgetInEditAction {
  type: typeof SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT
  payload: { [code: string]: boolean }
}

export type FinancialStatementsActionTypes =
  | ResetFinancialStatementsPageAction
  | ResetFinancialStatementsBudgetsAction
  | SetFinancialStatementsBudgetAction
  | ToggleFinancialStatementsBudgetInEditAction
  | ToggleFinancialStatementsDriverInEditAction
  | SetFinancialStatementsBudgetInEditAction
  | ToggleAllFinancialStatementsBudgetInEditAction
  | ResetAllFinancialStatementsBudgetsAction
  | SetFinancialStatementReallocationAction
