import { Button, Modal, Space, Table, TableProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DownloadOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Attachment } from '../../../../types/attachment/attachment'
import { Voucher } from '../types'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { autoDownload } from '../../../../utils/helpers'

interface AttacmentModalProps {
  voucher?: Voucher
  onClose: () => void
}

const AttacmentModal: React.FC<AttacmentModalProps> = ({ voucher, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [attachments, setAttachments] = useState(voucher?.attachments)
  const attachmnetRequest = useBackend(
    `accounting/vouchers/${voucher?.id}/attachments/{attachmentId}`,
    process.env.REACT_APP_INTEGRATION_URL,
    'arraybuffer'
  )

  useEffect(() => {
    setAttachments(voucher?.attachments)
  }, [voucher])

  const downloadAttachment = async (attachment: Attachment) => {
    try {
      const response = await attachmnetRequest.get({
        urlParams: { attachmentId: attachment.id },
        body: {
          params: { companyId: voucher?.companyId }
        }
      })

      autoDownload(attachment.filename, response)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'DOWNLOAD_ERROR' }))
    }
  }

  const columns: TableProps<Attachment>['columns'] = [
    {
      dataIndex: 'filename',
      title: t('global:name')
    },
    {
      dataIndex: 'contentType',
      title: t('global:type')
    },
    {
      title: t('global:download'),
      align: 'center',
      render: (attachment: Attachment) => (
        <Space>
          <Button type="link" icon={<DownloadOutlined />} onClick={() => downloadAttachment(attachment)} />
        </Space>
      )
    }
  ]

  return (
    <Modal
      title="Liitteet"
      destroyOnClose
      open={!!voucher}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Table loading={attachmnetRequest.loading} columns={columns} dataSource={attachments} pagination={false} />
    </Modal>
  )
}

export default AttacmentModal
