import { ProCard } from '@ant-design/pro-components'
import { Statistic } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatValueByType } from '../../../../utils/helpers'
import { LoanSummaryBalanceSheetRow } from '../types'

interface LoanSummaryProps {
  loansData?: LoanSummaryBalanceSheetRow
}
const { Divider } = ProCard
const LoanSummary: React.FC<LoanSummaryProps> = ({ loansData }) => {
  const { t } = useTranslation()

  return (
    <ProCard.Group direction="row">
      <ProCard>
        <Statistic
          title={`${t(`balanceSheet:${loansData?.balanceSheetRowId}`)} ${t('loans:actualBalanceShort')}`}
          value={loansData?.summary.actualBalance}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
      <Divider type="vertical" />
      <ProCard>
        <Statistic
          title={`${t(`balanceSheet:${loansData?.balanceSheetRowId}`)} ${t('loans:budgetBalanceShort')}`}
          value={loansData?.summary.budgetBalance}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
      <Divider type="vertical" />
      <ProCard>
        <Statistic
          title={t('loans:difference')}
          value={loansData?.summary.difference}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
    </ProCard.Group>
  )
}

export default LoanSummary
