import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import { useTranslation } from 'react-i18next'
import { useResetFormOnCloseModal } from '../../../../../components/Form/hooks'
import { VariableFormValues } from '../../../../../redux/context/customReports/typesVariable'
import CustomReportVariableForm from './CustomReportVariableForm'

interface ModalFormProps {
  switchRowsAndColumns: boolean
  variableNamePath?: NamePath
  open: boolean
  onCancel: () => void
}

const SectionFormVariableModal: React.FC<ModalFormProps> = ({
  switchRowsAndColumns,
  variableNamePath,
  open,
  onCancel
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<VariableFormValues>()
  const parentForm = Form.useFormInstance()
  const sectionType = Form.useWatch('type', parentForm)

  const variable = variableNamePath ? parentForm?.getFieldValue(variableNamePath) : undefined

  useResetFormOnCloseModal({
    form,
    open
  })

  useEffect(() => {
    open && form.setFieldsValue(variable)
  }, [variableNamePath])

  const onOk = () => {
    form.submit()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const containerStyles = {
    flexGrow: 1,
    overflowY: 'scroll',
    margin: '0px -14px',
    marginTop: '8px',
    padding: '0px 14px',
    maxHeight: 'calc(100vh - 200px)'
  } satisfies React.CSSProperties

  return (
    <Modal
      destroyOnClose
      title={t('customReportPage:add-row')}
      open={open}
      onOk={onOk}
      maskClosable={false}
      onCancel={onCancel}
      width="fit-content"
      style={{ top: 20 }}
    >
      <div style={containerStyles}>
        <Form style={{ width: 500 }} {...layout} form={form} name="customReportVariableForm" layout="vertical">
          <CustomReportVariableForm
            chartStylesEnable={!switchRowsAndColumns}
            variable={variable}
            sectionType={sectionType}
          />
        </Form>
      </div>
    </Modal>
  )
}

export default SectionFormVariableModal
