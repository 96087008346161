import { SearchOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Arrow from '../../../../../components/Misc/Arrow'
import { vouchersConfig } from '../../../../../config/integrations/vouchers'
import { formatValueByType } from '../../../../../utils/helpers'
import { TaskApplication } from '../../../../superuser/types'
import SortButton from '../SortButton'
import { getCategoryVariableType } from '../utils'
import Restricted from './Restricted'
import { currentUserSelector } from '../../../../../redux/session/currentUser/selectors'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { showArrow } from './utils'

interface CustomreportCellProps {
  value: number | undefined
  record: CustomReportVariable
  category: CustomReportCategory
  categories: CustomReportCategory[]
  sectionId: number
  setVoucherProps: ({
    row,
    column,
    visible
  }: {
    row: CustomReportVariable
    column: CustomReportCategory
    visible: boolean
  }) => void
}

const StyledCustomReportCell = styled.div`
  &:hover {
    & .sort-button {
      display: flex;
      cursor: pointer;
    }
  }
`

const CustomreportCell: React.FC<CustomreportCellProps> = ({
  value,
  record,
  category,
  categories,
  sectionId,
  setVoucherProps
}) => {
  const columnVariableType = getCategoryVariableType(category)
  const { permissions } = useSelector(currentUserSelector)
  const { t } = useTranslation()

  const accountingSoftwareEnable = (path?: string) => {
    let accountingSoftware: TaskApplication | null = null
    path?.split('-').reduce((cats: any, key: string) => {
      const { accountingSoftware: as } = cats?.[+key] || {}
      if (as) {
        accountingSoftware = as
      }
      return cats?.[+key].children
    }, categories)
    return accountingSoftware && vouchersConfig[accountingSoftware]
  }

  const showContextMenu = () => {
    if (
      value !== undefined &&
      accountingSoftwareEnable(category?.id) &&
      (record as any).account === true &&
      category.type === 'periodGroup' &&
      (!category.dataType || category.dataType === 'actuals')
    ) {
      if (permissions?.map(p => p.label).includes('read-vouchers')) return true
    }
    return false
  }

  const menu = (row: CustomReportVariable, column: CustomReportCategory) => {
    const items: MenuProps['items'] = [
      {
        label: t('global:show-vouchers'),
        key: 'drill',
        icon: <SearchOutlined />,
        onClick: () => setVoucherProps({ row, column, visible: true })
      }
    ]

    return { items }
  }

  const getValue = () => {
    const variableType = columnVariableType || record.type
    let options
    if (record.style?.decimals) {
      options = {
        minimumFractionDigits: record.style?.decimals,
        maximumFractionDigits: record.style?.decimals
      }
    }

    return formatValueByType(value, variableType, options)
  }

  const errors = useMemo(() => record?.balances?.find(r => r.groupId === category.id)?.errors || [], [record])

  return (
    <StyledCustomReportCell>
      {record.children && <SortButton sectionId={sectionId} row={record} column={category} />}
      {value !== undefined || (value === undefined && (!errors || errors.length < 1)) ? (
        <Dropdown disabled={!showContextMenu()} menu={menu(record, category)} trigger={['contextMenu']}>
          <span className={classNames({ 'hover-link': showContextMenu() })}>
            {showArrow(value, category, record) && value && (
              <Arrow value={value} thresholdValueSetting={record?.style?.thresholdValueSetting} />
            )}
            {getValue()}
          </span>
        </Dropdown>
      ) : (
        <Restricted errors={errors} />
      )}
    </StyledCustomReportCell>
  )
}

export default CustomreportCell
