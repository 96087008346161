import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { Company } from '../../types/company/Company'
import { Store } from '../types'
import { RequestActionTypes } from '../middleware/types'
import { contextCompanyIdSelector } from './company/selectors'
import { BudgetingScenario } from '../../types/budgetingScenario/BudgetingScenario'
import { dimensionQuerySelector, filtersSelector } from './filters/selectors'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'
import { appLanguageSelector } from '../app/selectors'

export interface ContextRequestParams {
  companyId: Company['id']
  dimensions?: string
  budgetingScenarioId?: BudgetingScenario['id']
  data?: any
  periodGroups?: PeriodGroup[]
}

export const contextRequest = (
  request: (params: ContextRequestParams, data?: any) => RequestActionTypes,
  data?: any
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const companyId = contextCompanyIdSelector(store)
    const dimensionQuery = dimensionQuerySelector(store)
    const lng = appLanguageSelector(store)
    const { budgetingScenario, periodGroups } = filtersSelector(store)

    if (!companyId) return
    const contextParams = {
      companyId,
      periodGroups,
      lng,
      dimensions: dimensionQuery,
      budgetingScenarioId: budgetingScenario && budgetingScenario.id
    }

    dispatch(request(contextParams, data))
  }
}
