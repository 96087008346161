import { ReallocationSource, ReallocationTarget } from '../../../types/reallocation/Reallocation'

export const SET_REALLOCATIONS = 'SET_REALLOCATIONS'
export const UPDATE_REALLOCATION = 'UPDATE_REALLOCATION'
export const ADD_REALLOCATION = 'ADD_REALLOCATION'
export const DELETE_REALLOCATION = 'DELETE_REALLOCATION'
export const UPDATE_REALLOCATION_TARGET = 'UPDATE_REALLOCATION_TARGET'
export const ADD_REALLOCATION_TARGET = 'ADD_REALLOCATION_TARGET'
export const DELETE_REALLOCATION_TARGET = 'DELETE_RDELETE_REALLOCATION_TARGETEALLOCATION'

export interface SetReallocationsAction {
  type: typeof SET_REALLOCATIONS
  payload: ReallocationSource[]
}

export interface UpdateReallocationAction {
  type: typeof UPDATE_REALLOCATION
  payload: ReallocationSource
}

export interface AddReallocationAction {
  type: typeof ADD_REALLOCATION
  payload: ReallocationSource
}

export interface DeleteReallocationAction {
  type: typeof DELETE_REALLOCATION
  payload: ReallocationSource
}
export interface UpdateReallocationTargetAction {
  type: typeof UPDATE_REALLOCATION_TARGET
  payload: { target: ReallocationTarget; source: ReallocationSource }
}

export interface AddReallocationTargetAction {
  type: typeof ADD_REALLOCATION_TARGET
  payload: { target: ReallocationTarget; source: ReallocationSource }
}

export interface DeleteReallocationTargetAction {
  type: typeof DELETE_REALLOCATION_TARGET
  payload: { target: ReallocationTarget; source: ReallocationSource }
}

export type ReallocationActionTypes =
  | SetReallocationsAction
  | UpdateReallocationAction
  | AddReallocationAction
  | DeleteReallocationAction
  | UpdateReallocationTargetAction
  | AddReallocationTargetAction
  | DeleteReallocationTargetAction
