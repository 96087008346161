import { DeleteOutlined, EditOutlined, LockOutlined, PlusOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Table, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import {
  deleteBudgetingScenarioRequest,
  getBudgetingScenariosRequest,
  updateBudgetingScenario,
  addBudgetingScenario
} from '../../../../../redux/context/budgetingScenarios/actions'
import { budgetingScenariosSelector } from '../../../../../redux/context/budgetingScenarios/selectors'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  BudgetingScenario,
  BudgetingScenarioTransfer,
  ForecastType
} from '../../../../../types/budgetingScenario/BudgetingScenario'
import BudgetingScenarioForm from './components/BudgetingScenarioAndForecastForm'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { setBudgetingScenarioFilter } from '../../../../../redux/context/filters/actions'
import { useBackend } from '../../../../../services/backend'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import {
  addForecast,
  deleteForecastRequest,
  getForecastsRequest,
  updateForecast
} from '../../../../../redux/context/forecasts/actions'
import { forecastsSelector } from '../../../../../redux/context/forecasts/selectors'
import { GroupSettingDataSource } from '../../group/types'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import { subsidiariesSelector } from '../../../../../redux/context/subsidiaries/selectors'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'
import { Company } from '../../../../../types/company/Company'
import { formatFormValues } from './utils'
import { AppDispatch } from '../../../../../redux/store'

interface BudgetingScenariosAndForecastsProps {
  page: 'budgetingScenarios' | 'forecasts'
}

const BudgetingScenariosAndForecasts: React.FC<BudgetingScenariosAndForecastsProps> = ({
  page
}: BudgetingScenariosAndForecastsProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const companyId = useSelector(contextCompanyIdSelector)
  const { budgetingScenario: budgetingScenarioFilter } = useSelector(filtersSelector)
  const budgetingScenariosData = useSelector(budgetingScenariosSelector)
  const forecastsData = useSelector(forecastsSelector)
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const [modalVisible, setModalVisibility] = useState(false)
  const [isEditing, setIsEditing] = useState<BudgetingScenario>()
  const [selectedGroup, setSelectedGroup] = useState<Company>()
  const subsidiariesData = useSelector(subsidiariesSelector)
  const { budgetingScenarios: budgetingScenariosLoading, forecasts: forecastsLoading } = useSelector(loadingSelector)

  const groupRequest = useBackend(`api/companies/${companyId}/group`)
  const { request: budgetingScenarioRequest } = useBackend(
    `/api/companies/{companyId}/budgeting/budgeting-scenarios${isEditing?.id ? `/${isEditing.id}` : ''}`
  )
  const { request: forecastsRequest } = useBackend(
    `/api/companies/{companyId}/budgeting/forecasts${isEditing?.id ? `/${isEditing.id}` : ''}`
  )
  const budgetinScenarioLockRequest = useBackend(
    `/api/companies/{companyId}/budgeting/budgeting-scenarios/{budgetingScenarioId}/lock`
  )
  const budgetinScenarioUnlockRequest = useBackend(
    `/api/companies/{companyId}/budgeting/budgeting-scenarios/{budgetingScenarioId}/unlock`
  )
  const forecastLockRequest = useBackend(`/api/companies/{companyId}/budgeting/forecasts/{forecastId}/lock`)
  const forecastUnlockRequest = useBackend(`/api/companies/{companyId}/budgeting/forecasts/{forecastId}/unlock`)

  const getSubsidiariesAndGroupCompany = async () => {
    const group = await groupRequest.get()
    setSelectedGroup(group.group)
    companyId && dispatch(getSubsidiariesRequest(companyId))
  }

  useEffect(() => {
    groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && getSubsidiariesAndGroupCompany()
  }, [companyId, groupBudgetSourceSetting])

  const handleCancel = () => {
    if (form) {
      form.resetFields()
    }
    setIsEditing(undefined)
    setModalVisibility(false)
  }

  const createScenario = async (values: BudgetingScenario, resetLoans?: boolean) => {
    setIsEditing(values)
    const requestType = page === 'budgetingScenarios' ? budgetingScenarioRequest : forecastsRequest
    const response = await requestType.post({
      urlParams: { companyId },
      body: {
        data: values,
        params: {
          resetLoans
        }
      }
    })

    if (page === 'budgetingScenarios') {
      dispatch(addBudgetingScenario(response))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'CREATE_BUDGETING_SCENARIO_SUCCESS'
        })
      )
    } else {
      dispatch(addForecast(response))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'CREATE_FORECAST_SUCCESS'
        })
      )
    }

    handleCancel()
  }

  useEffect(() => {
    if (page === 'budgetingScenarios') {
      companyId && dispatch(getBudgetingScenariosRequest(companyId))
    } else {
      companyId && dispatch(getForecastsRequest(companyId))
    }
  }, [companyId])

  const showModal = () => setModalVisibility(true)

  const initSubsidiaries = (budgetingScenario: BudgetingScenario) => {
    const parsedSubsidiaries = {}
    budgetingScenario?.subsidiaries?.forEach(s => {
      const id = s.companyId
      if (id) {
        Object.assign(parsedSubsidiaries, { [id]: s.id })
      }
    })
    return parsedSubsidiaries
  }

  const setInitValues = (budgetingScenario: BudgetingScenario) => {
    form.setFieldsValue({
      ...budgetingScenario,
      forecastCursor: budgetingScenario?.forecastCursor && dayjs(budgetingScenario.forecastCursor, 'YYYY-MM-DD'),
      selectedForecastType: budgetingScenario?.forecastType === 'MANUAL' ? 'manual' : 'rolling',
      subsidiaries: initSubsidiaries(budgetingScenario)
    })
  }

  const handleEdit = (budgetingScenario: BudgetingScenario) => {
    setIsEditing(budgetingScenario)
    showModal()
    setInitValues(budgetingScenario)
  }

  const handleOk = (transfersData?: BudgetingScenarioTransfer[]) => {
    if (form && companyId) {
      form.validateFields().then(async (scenario: BudgetingScenario) => {
        let values = formatFormValues(scenario, transfersData)
        if (page === 'forecasts' && !values.forecastType) {
          values = {
            ...values,
            forecastType: ForecastType.MANUAL
          }
        }

        if (isEditing) {
          const requestType = page === 'budgetingScenarios' ? budgetingScenarioRequest : forecastsRequest
          requestType
            .put({
              urlParams: { companyId },
              body: {
                data: values
              }
            })
            .then((response: BudgetingScenario) => {
              if (page === 'forecasts') {
                dispatch(updateForecast(response))
                dispatch(
                  notificationAction({
                    type: 'success',
                    message: 'UPDATE_FORECAST_SUCCESS'
                  })
                )
              } else {
                dispatch(updateBudgetingScenario(response))
                dispatch(
                  notificationAction({
                    type: 'success',
                    message: 'UPDATE_BUDGETING_SCENARIO_SUCCESS'
                  })
                )
              }
              if (budgetingScenarioFilter?.id === response.id) {
                dispatch(setBudgetingScenarioFilter(response))
              }
            })
            .catch(() => {
              if (page === 'forecasts') {
                dispatch(
                  notificationAction({
                    type: 'error',
                    message: 'UPDATE_FORECAST_ERROR'
                  })
                )
              } else {
                dispatch(
                  notificationAction({
                    type: 'error',
                    message: 'UPDATE_BUDGETING_SCENARIO_ERROR'
                  })
                )
              }
            })
            .finally(() => {
              handleCancel()
            })
        } else {
          await createScenario(values)
        }
      })
    }
  }

  const lockRecord = (record: BudgetingScenario) => {
    const req = page === 'forecasts' ? forecastLockRequest : budgetinScenarioLockRequest
    req
      .put({
        urlParams: {
          companyId,
          ...(page === 'forecasts' ? { forecastId: record.id } : { budgetingScenarioId: record.id })
        }
      })
      .then((response: BudgetingScenario) => {
        page === 'forecasts' ? dispatch(updateForecast(response)) : dispatch(updateBudgetingScenario(response))
        if (budgetingScenarioFilter?.id === response.id) {
          dispatch(setBudgetingScenarioFilter(response))
        }
      })
  }

  const unlockRecord = (record: BudgetingScenario) => {
    const req = page === 'forecasts' ? forecastUnlockRequest : budgetinScenarioUnlockRequest
    req
      .put({
        urlParams: {
          companyId,
          ...(page === 'forecasts' ? { forecastId: record.id } : { budgetingScenarioId: record.id })
        }
      })
      .then((response: BudgetingScenario) => {
        page === 'forecasts' ? dispatch(updateForecast(response)) : dispatch(updateBudgetingScenario(response))
        if (budgetingScenarioFilter?.id === response.id) {
          dispatch(setBudgetingScenarioFilter(response))
        }
      })
  }

  const generateCompanyColumns = () => {
    if (groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries) {
      const group = {
        title: selectedGroup?.name,
        ellipsis: true,
        width: 150,
        render: (record: BudgetingScenario) => (
          <>
            {record.subsidiaries?.find((subsidiary: any) => subsidiary.companyId === selectedGroup?.id)?.name ||
              t('global:default')}
          </>
        )
      }
      /** PYSTYY */
      const groupSubsidiaries = subsidiariesData.map(s => {
        return {
          title: s.name,
          ellipsis: true,
          width: 150,
          render: (record: BudgetingScenario) => (
            <>
              {record.subsidiaries?.find((subsidiary: any) => subsidiary.companyId === s.id)?.name ||
                t('global:default')}
            </>
          )
        }
      })
      return [group, ...groupSubsidiaries]
    }
    return []
  }

  const columns: ColumnProps<BudgetingScenario>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      ellipsis: true,
      fixed: 'left',
      width: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries ? 200 : undefined
    },
    {
      title: t('global:description'),
      dataIndex: 'description',
      ellipsis: true,
      fixed: 'left',
      width: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries ? 200 : undefined
    },
    ...generateCompanyColumns(),
    {
      title: t('global:actions'),
      key: 'action',
      width: 110,
      align: 'right',
      fixed: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && 'right',
      render: (record: BudgetingScenario) => (
        <span>
          <EditOutlined onClick={() => handleEdit(record)} />
          <Divider type="vertical" />
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => {
              if (record.id === budgetingScenarioFilter?.id) {
                dispatch(setBudgetingScenarioFilter(undefined))
              }
              if (page === 'forecasts') {
                companyId && dispatch(deleteForecastRequest(companyId, record.id))
              } else {
                companyId && dispatch(deleteBudgetingScenarioRequest(companyId, record.id))
              }
            }}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <DeleteOutlined />
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            placement="bottomRight"
            title={record.isLocked ? t('global:unlock') : t('global:lock')}
            onConfirm={() => {
              if (record.isLocked) {
                unlockRecord(record)
              } else {
                lockRecord(record)
              }
            }}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            {record.isLocked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined />}
          </Popconfirm>
        </span>
      )
    }
  ]

  return (
    <div>
      <Button
        style={{ marginBottom: '16px' }}
        onClick={() => {
          showModal()
        }}
      >
        <PlusOutlined />
        {t('global:add-new')}
      </Button>
      <BudgetingScenarioForm
        page={page}
        form={form}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalVisible={modalVisible}
        selectedGroup={selectedGroup}
        loading={budgetingScenarioRequest.loading || forecastsRequest.loading}
      />

      <Table
        scroll={{ x: 500 }}
        loading={budgetingScenariosLoading || forecastsLoading}
        rowKey={(record: BudgetingScenario) => record.id.toString()}
        columns={columns}
        dataSource={page === 'forecasts' ? forecastsData : budgetingScenariosData}
      />
    </div>
  )
}

export default BudgetingScenariosAndForecasts
