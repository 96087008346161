import dayjs from 'dayjs'
import {
  BudgetingScenario,
  BudgetingScenarioEntity,
  BudgetingScenarioTransfer
} from '../../../../../types/budgetingScenario/BudgetingScenario'

export const notLocked = (budgetingScenario?: BudgetingScenario) => {
  if (budgetingScenario && budgetingScenario.isLocked === true) {
    return false
  }
  return true
}

export const formatFormValues = (
  scenario: BudgetingScenario,
  transfersData?: BudgetingScenarioTransfer[]
): BudgetingScenario => {
  const subsidiariesArr: BudgetingScenario[] = []
  Object.values(scenario?.subsidiaries || {}).forEach(s => {
    if (s) {
      subsidiariesArr.push({
        id: s
      } as unknown as BudgetingScenario)
    }
  })
  return {
    ...scenario,
    forecastCursor: scenario.forecastCursor && dayjs(scenario.forecastCursor).date(1).format('YYYY-MM-DD'),
    subsidiaries: subsidiariesArr,
    transfers: transfersData?.filter(obj => obj.entity !== undefined || obj.sourceId !== undefined)
  }
}

export const getLatestTransfers = (
  scenariosAndForecasts: BudgetingScenario[],
  availableEntities: string[],
  budgetingScenarioId: number
) => {
  const selectedScenario = scenariosAndForecasts.find(b => b.id === budgetingScenarioId)
  const allTransActions: BudgetingScenarioTransfer[] = []
  availableEntities.forEach((a: string) => {
    const allTransfers = selectedScenario?.transferLog?.filter(tr => tr.entity === a)
    let latestTransferSourceId =
      allTransfers && allTransfers?.length > 0 && allTransfers[allTransfers?.length - 1].sourceId
    if (!scenariosAndForecasts.find(s => s.id === latestTransferSourceId) && latestTransferSourceId !== 0) {
      latestTransferSourceId = undefined
    }
    allTransActions.push({
      sourceId: latestTransferSourceId === 0 ? 0 : latestTransferSourceId || undefined,
      entity: a as BudgetingScenarioEntity
    })
  })
  return allTransActions
}
