export enum ReferenceValueRuleOperator {
  gt = '>',
  gte = '>=',
  btw = '><',
  lte = '<=',
  lt = '<'
}

export type ReferenceValueRule = ReferenceValueUnaryRule | ReferenceValueBinaryRule

export interface ReferenceValueBinaryRule {
  operator: ReferenceValueRuleOperator.btw
  value: [number, number]
  color: string
}

export interface ReferenceValueUnaryRule {
  operator:
    | ReferenceValueRuleOperator.gt
    | ReferenceValueRuleOperator.gte
    | ReferenceValueRuleOperator.lt
    | ReferenceValueRuleOperator.lte
  value: number
  color: string
}

export interface ReferenceValue {
  keyFigureId: number
  excellent: ReferenceValueRule
  good: ReferenceValueRule
  satisfying: ReferenceValueRule
  passable: ReferenceValueRule
}
