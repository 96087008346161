import { CashFlowKeysActionTypes, UPDATE_ROW_KEYS } from './types'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

const initialState: KeyFigure['id'][] = []

const cashFlowKeysReducer = (state = initialState, action: CashFlowKeysActionTypes) => {
  switch (action.type) {
    case UPDATE_ROW_KEYS:
      return [...action.keys]
    default:
      return state
  }
}

export default cashFlowKeysReducer
