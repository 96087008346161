import { Investment } from '../../../types/investment/Investment'

export const SET_INVESTMENTS = 'SET_INVESTMENTS'
export const UPDATE_INVESTMENT = 'UPDATE_INVESTMENT'
export const ADD_INVESTMENT = 'ADD_INVESTMENT'

export interface UpdateInvestmentAction {
  type: typeof UPDATE_INVESTMENT
  payload: Investment
}

export interface AddInvestmentAction {
  type: typeof ADD_INVESTMENT
  payload: Investment
}

export interface SetInvestmentsAction {
  type: typeof SET_INVESTMENTS
  payload: Investment[]
}

export type InvestmentActionTypes = SetInvestmentsAction | UpdateInvestmentAction | AddInvestmentAction
