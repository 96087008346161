import { Transaction } from '../types'
import { TransactionFormFields } from './VoucherForm'

export const calculateTotalTrasactionsValueCents = (
  transactions: Partial<Transaction>[] | readonly TransactionFormFields[]
) => {
  let totalCredit = 0
  let totalDebit = 0

  transactions.forEach(transaction => {
    totalCredit += +(transaction?.credit || 0) * 100
    totalDebit += +(transaction?.debit || 0) * 100
  })

  const difference = Math.round(totalCredit - totalDebit)

  return { totalCredit, totalDebit, difference }
}
