import { Space, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { groupCompanySelector } from '../../../../../redux/context/groupCompany/selectors'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'
import SubsidiariesForm from './components/SubsidiariesForm'
import { SubsidiariesTable } from './components/SubsidiariesTable'
import { AppDispatch } from '../../../../../redux/store'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import Loading from '../../../../loading/Loading'

export const Subsidiaries: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const groupCompany = useSelector(groupCompanySelector)
  const loading = useSelector(loadingSelector)

  const getSubsidiaries = async () => {
    companyId && dispatch(getSubsidiariesRequest(companyId))
  }

  useEffect(() => {
    getSubsidiaries()
  }, [companyId])

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {loading.groupCompany ? (
        <Loading />
      ) : (
        <>
          <Typography.Text strong>{`${t('contractsPage:parent-company')}: ${groupCompany?.name}`}</Typography.Text>
          <SubsidiariesForm groupId={groupCompany?.id} />
          <SubsidiariesTable groupId={groupCompany?.id} />
        </>
      )}
    </Space>
  )
}
