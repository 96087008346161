import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Radio, Select, TreeSelect } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CompanyInput from '../../../../../dashboard/multiPurposeDashboard/form/CompanyInput'
import { ReallocationTargetType, ReallocationType } from '../../../../../../types/reallocation/Reallocation'
import { useBackend } from '../../../../../../services/backend'
import { Dimension } from '../../../../../../types/dimension/Dimension'
import { ReallocationContext, ReallocationContextType } from '../ReallocationProvider'
import ReallocationFloatingForm from './ReallocationFloatingForm'
import { companyAccountsSelector } from '../../../../../../redux/context/accounts/selectors'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'
import { getDimensionTree } from '../../../../../../components/Dimension/utils'

const ReallocationTargetFormFields = () => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const companyId = Form.useWatch(['companyId'], form)
  const targetId = Form.useWatch(['id'], form)
  const [dimensionList, setDimensionList] = useState<Dimension[]>([])
  const dimensionRequest = useBackend(`/api/companies/{companyId}/accounting/dimensions`)
  const { targetParent } = useContext(ReallocationContext) as ReallocationContextType
  const accounts = useSelector(companyAccountsSelector)

  const fetchDimensions = async () => {
    try {
      const dimensions = await dimensionRequest.get({ urlParams: { companyId } })
      setDimensionList(getDimensionTree(dimensions))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    companyId && fetchDimensions()
    return () => {
      setDimensionList([])
    }
  }, [companyId])

  return (
    <>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="companyId"
        label={t('global:company')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <CompanyInput />
      </Form.Item>
      <Form.Item name="dimensionId" label={t('reallocationsPage:targetDimension')}>
        <TreeSelect
          disabled={dimensionRequest.loading}
          allowClear
          fieldNames={{ label: 'name', value: 'dimensionId', children: 'children' }}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={dimensionList}
          showSearch
          filterTreeNode={(inputval, node) => {
            return node?.props?.name?.toLowerCase().includes(inputval.toLowerCase())
          }}
        />
      </Form.Item>
      <Form.Item
        name="accountFrom"
        label={t('reallocationsPage:accountFrom')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <FormattedInputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name="accountTo"
        label={t('reallocationsPage:accountTo')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <FormattedInputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="type" label={t('reallocationsPage:reallocationType')}>
        <Radio.Group>
          <Radio value={ReallocationTargetType.range}>{t('reallocationsPage:range')}</Radio>
          <Radio value={ReallocationTargetType.account}>{t('reallocationsPage:account')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return prevValues?.type !== currentValues?.type
        }}
      >
        {({ getFieldValue }) => {
          return (
            getFieldValue('type') === ReallocationTargetType.account && (
              <Form.Item
                name="account"
                rules={[{ required: true, message: ' ' }]}
                label={t('reallocationsPage:targetAccount')}
              >
                <Select showSearch optionFilterProp="children">
                  {accounts?.map(account => (
                    <Select.Option key={account.code} value={account.code}>
                      {`${account.code} - ${account.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          )
        }}
      </Form.Item>

      {targetParent?.type === ReallocationType.fixed && (
        <Form.Item
          name="fixed"
          label={t('reallocationsPage:fixed')}
          rules={[
            { required: true, message: t('global:required-field') },
            {
              validator: (__, value) => {
                const targets = targetParent?.targets?.filter(target => target.id !== targetId)
                const targetSum =
                  targets?.reduce((sum, target) => {
                    const fixedValue = target.fixed || 0
                    const newSum = sum + fixedValue
                    return parseFloat(newSum.toPrecision(4))
                  }, 0) || 0

                if (targetSum + value <= 1) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error(t('reallocationsPage:fixed-error')))
              }
            }
          ]}
        >
          <FormattedInputNumber
            percentage
            style={{ textAlign: 'right', width: '100%' }}
            min={0}
            max={100}
            addonAfter="%"
            step={1}
            required={false}
          />
        </Form.Item>
      )}
      {targetParent?.type === ReallocationType.floating && <ReallocationFloatingForm />}
    </>
  )
}

export default ReallocationTargetFormFields
