import { createSelector } from 'reselect'
import { Store } from '../../types'
import { contextCompanyCountrySelector, contextCompanyFormSelector } from '../../context/company/selectors'

export const keyFiguresSelector = (store: Store) => store.entities.keyFigures || []

export const companyFormBasedKeyFigureSelector = createSelector(
  keyFiguresSelector,
  contextCompanyCountrySelector,
  contextCompanyFormSelector,
  (keyFigures, country, companyForm) => {
    return keyFigures.filter(keyFigure => keyFigure.country === country && keyFigure.companyForm === companyForm)
  }
)

export const roleBasedKeyFigureSelector = createSelector(companyFormBasedKeyFigureSelector, keyFigures => {
  return keyFigures || []
})

export const comparableKeyFiguresSelector = createSelector(roleBasedKeyFigureSelector, keyFigures => {
  return keyFigures.filter(keyFigure => keyFigure.comparable) || []
})

export const subscribableKeyFiguresSelector = createSelector(roleBasedKeyFigureSelector, keyFigures => {
  return keyFigures.filter(keyFigure => keyFigure.subscribable) || []
})

export const drawableKeyFiguresSelector = createSelector(roleBasedKeyFigureSelector, keyFigures => {
  return keyFigures.filter(keyFigure => keyFigure.drawable) || []
})
