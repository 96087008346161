import { BudgetingConfigActionTypes, BUDGETING_CONFIG } from './types'

const initialState = {}

const BudgetingConfigReducer = (state = initialState, action: BudgetingConfigActionTypes) => {
  switch (action.type) {
    case BUDGETING_CONFIG:
      return action.payload
    default:
      return state
  }
}

export default BudgetingConfigReducer
