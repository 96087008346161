import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Authorized from '../../../../components/Authorized/Authorized'
import ToolBar from '../../../../components/Misc/ToolBar'
import {
  createAccountRequest,
  deleteAccountAction,
  getAccountsRequest,
  updateAccountRequest
} from '../../../../redux/context/accounts/actions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { Account } from '../../../../types/account/Account'
import AccountFormModal from './AccountFormModal'
import AccountTable from './AccountTable'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { isGroupCompany } from '../../../../routes/utils'

const Accounts = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const [open, setOpen] = useState(false)
  const [initialValues, setInitialValues] = useState<Account | undefined>()
  const accountRequest = useBackend('/api/companies/{companyId}/accounting/accounts/{code}')

  useEffect(() => {
    // Haetaan vain initial, koska asiointiroolin vaihdon yhteydessä haetaan myös
    dispatch(getAccountsRequest(companyId))
  }, [])

  const onCreate = (values: Account) => {
    dispatch(createAccountRequest(companyId, values))
    setOpen(false)
  }

  const onUpdate = (values: Account) => {
    dispatch(updateAccountRequest(companyId, values))
    setOpen(false)
  }

  const onDelete = async (values: Account) => {
    try {
      await accountRequest.delete({
        urlParams: {
          code: values.code,
          companyId
        }
      })
      dispatch(deleteAccountAction(values))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'DELETE_ACCOUNT_SUCCESS'
        })
      )
      setOpen(false)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'DELETE_ACCOUNT_ERROR' }))
    }
  }

  const handleCreate = (item?: Account) => {
    setInitialValues(item)
    setOpen(true)
  }

  const handleEdit = (account: Account) => {
    setInitialValues(account)
    setOpen(true)
  }

  return (
    <>
      <Authorized authority={{ permission: 'write-accounts' }}>
        <AccountFormModal
          initialValues={initialValues}
          open={open}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={() => setOpen(false)}
        />

        <ToolBar visible={!isGroupCompany()}>
          <Button size="small" icon={<PlusOutlined />} onClick={() => handleCreate()}>
            {t('global:add-new')}
          </Button>
        </ToolBar>
      </Authorized>
      <AccountTable onEdit={handleEdit} onDelete={onDelete} />
    </>
  )
}

export default Accounts
