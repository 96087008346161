import { Form, Radio, Select } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'
import { roleBasedKeyFigureSelector } from '../../../../../../../redux/entities/keyFigures/selectors'
import { KeyFigure } from '../../../../../../../types/keyFigure/KeyFigure'
import { GraphType } from '../../../types'
import DashboardItemFields from '../DasboardItemFieds'
import SelectionTableInput from '../SelectionTableInput'
import { ThresholdValueSetting } from '../../../../../../../redux/context/customReports/typesVariable'

const KeyFigureInfoFields = () => {
  const { t } = useTranslation()
  const selectableKeyFigures = useSelector(roleBasedKeyFigureSelector)

  const columns: ColumnProps<KeyFigure>[] = [
    {
      title: t(`keyFigurePage:key-figure`),
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('id', val => t(`keyFigureStatement:${val.id || val}`))
    }
  ]

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('dashboardPage:chart-type')} name={['params', 'representation', 'options', 'type']}>
        <Select
          options={Object.values(GraphType).map(type => ({
            value: type,
            label: t(`dashboardPage:${type}`)
          }))}
        />
      </Form.Item>
      <Form.Item required label={t('global:keyFigure')} name={['params', 'keyFigureIds']}>
        <SelectionTableInput<KeyFigure> selectionType="radio" dataSource={selectableKeyFigures} columns={columns} />
      </Form.Item>
      <Form.Item
        label={t('dashboardPage:compareSettingType')}
        name={['params', 'representation', 'options', 'comparableDataType']}
      >
        <Radio.Group>
          <Radio key="actual" style={radioStyle} value="actual">
            {t('global:actuals')}
          </Radio>
          <Radio key="budget" style={radioStyle} value="budget">
            {t('global:budget')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t('dashboardPage:compareSettingTitle')}
        name={['params', 'representation', 'options', 'monthlyComparableType']}
      >
        <Radio.Group>
          <Radio key="sameMonthLastYear" style={radioStyle} value="sameMonthLastYear">
            {t('dashboardPage:sameMonthLastYear')}
          </Radio>
          <Radio key="previousMonth" style={radioStyle} value="previousMonth">
            {t('dashboardPage:previousMonth')}
          </Radio>
          <Radio key="thisMonth" style={radioStyle} value="thisMonth">
            {t('dashboardPage:thisMonth')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t('customReportPage:thresholdValueSetting')}
        key="thresholdValueSetting"
        name={['params', 'representation', 'options', 'thresholdValueSetting']}
      >
        <Select
          options={Object.keys(ThresholdValueSetting).map(val => ({
            label: t(`customReportPage:${val}`),
            value: val
          }))}
        />
      </Form.Item>
    </>
  )
}

export default KeyFigureInfoFields
