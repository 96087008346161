import dayjs from 'dayjs'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { dispatchFinancialStatementRequests } from '../../../pages/budgeting/financialStatements/service'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { Investment } from '../../../types/investment/Investment'
import {
  DELETE,
  DeleteRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import { getInvestmentsSummaryRequest } from '../../pages/investments/actions'
import { investmentsPageSelector } from '../../pages/investments/selectors'
import { Store } from '../../types'
import { contextCompanyIdSelector } from '../company/selectors'
import { filtersSelector } from '../filters/selectors'
import {
  AddInvestmentAction,
  ADD_INVESTMENT,
  SetInvestmentsAction,
  SET_INVESTMENTS,
  UpdateInvestmentAction,
  UPDATE_INVESTMENT
} from './types'

export const updateInvestment = (investment: Investment): UpdateInvestmentAction => ({
  type: UPDATE_INVESTMENT,
  payload: investment
})

export const addInvestment = (investment: Investment): AddInvestmentAction => ({
  type: ADD_INVESTMENT,
  payload: investment
})

export const setInvestments = (investments: Investment[]): SetInvestmentsAction => ({
  type: SET_INVESTMENTS,
  payload: investments
})

export const createFinancialStatementInvestmentsRequest = (
  companyId: string,
  investment: Investment,
  isExisting?: boolean,
  budgetingScenarioId?: BudgetingScenario['id']
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/investments`,
    data: investment,
    success: () => dispatchFinancialStatementRequests
  },
  meta: {
    type: 'INVESTMENTS',
    notification: true
  }
})

export const createLoansInvestmentsRequest = (
  companyId: string,
  investment: Investment,
  isExisting?: boolean,
  budgetingScenarioId?: BudgetingScenario['id']
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/investments`,
    data: investment
  },
  meta: {
    type: 'INVESTMENTS',
    notification: true
  }
})

export const updateFinancialStatementInvestmentsRequest = (
  companyId: string,
  investment: Investment
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`,
    data: investment,
    success: () => dispatchFinancialStatementRequests
  },
  meta: {
    type: 'UPDATE_INVESTMENTS',
    notification: true
  }
})

export const updateLoansInvestmentsRequest = (companyId: string, investment: Investment): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`,
    data: investment
  },
  meta: {
    type: 'UPDATE_INVESTMENTS',
    notification: true
  }
})

export const deleteFinancialStatementInvestmentsRequest = (
  companyId: string,
  investment: Investment
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`,
    success: () => dispatchFinancialStatementRequests
  },
  meta: {
    type: 'DELETE_INVESTMENTS',
    notification: true
  }
})

export const deleteLoansInvestmentsRequest = (companyId: string, investment: Investment): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`
  },
  meta: {
    type: 'DELETE_INVESTMENTS',
    notification: true
  }
})

export const getInvestmentsSummaryRequestThunk = (
  isExisting?: boolean
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const companyId = contextCompanyIdSelector(store)!
    const { date } = investmentsPageSelector(store)
    const { budgetingScenario } = filtersSelector(store)

    dispatch(
      getInvestmentsSummaryRequest(
        companyId,
        dayjs(date).endOf('month').format('YYYY-MM-DD'),
        budgetingScenario && budgetingScenario.id,
        isExisting
      )
    )
  }
}

export const createInvestmentPageInvestmentsRequest = (
  companyId: string,
  investment: Investment,
  isExisting?: boolean,
  budgetingScenarioId?: BudgetingScenario['id']
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/investments`,
    data: investment,
    success: () => getInvestmentsSummaryRequestThunk(isExisting)
  },
  meta: {
    type: 'INVESTMENTS',
    notification: true
  }
})

export const updateInvestmentPageInvestmentsRequest = (
  companyId: string,
  investment: Investment,
  isExisting?: boolean
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`,
    data: investment,
    success: () => getInvestmentsSummaryRequestThunk(isExisting)
  },
  meta: {
    type: 'UPDATE_INVESTMENTS',
    notification: true
  }
})

export const deleteInvestmentPageInvestmentsRequest = (
  companyId: string,
  investment: Investment,
  isExisting?: boolean
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/investments/${investment.id}`,
    success: () => getInvestmentsSummaryRequestThunk(isExisting)
  },
  meta: {
    type: 'DELETE_INVESTMENTS',
    notification: true
  }
})
