import { Action, ThunkAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { Dispatch } from 'react'
import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import { Store } from '../../types'
import { ContextRequestParams } from '../actions'
import {
  AddCustomReportsAction,
  AddCustomReportSectionAction,
  ADD_CUSTOM_REPORT,
  ADD_CUSTOM_REPORT_SECTION,
  CustomReport,
  DeleteCustomReportSectionAction,
  DELETE_CUSTOM_REPORT_SECTION,
  EditCustomReportSectionAction,
  EDIT_CUSTOM_REPORT_SECTION,
  DeleteCustomReportsAction,
  DELETE_CUSTOM_REPORT,
  SetCustomReportAction,
  SET_CUSTOM_REPORT,
  SetCustomReportsAction,
  SET_CUSTOM_REPORTS,
  DeleteCustomReportVariableAction,
  DELETE_CUSTOM_REPORT_VARIABLE,
  SetCalculatedReportAction,
  SET_CALCULATED_REPORT,
  SetSectionOrderAction,
  SET_SECTION_ORDER,
  SET_VARIABLE_ORDER,
  SetVariableOrderAction,
  SetCustomReportDateAction,
  SET_DATE,
  SET_SECTION_DATA,
  SetSectionDataAction,
  AddSectionVariableAction,
  ADD_SECTION_VARIABLE,
  UPDATE_SECTION_VARIABLE,
  UpdateSectionVariableAction,
  AddSectionVariableExplainerAction,
  ADD_SECTION_VARIABLE_EXPLAINER,
  UPDATE_CUSTOM_REPORT,
  UpdateCustomReportAction,
  EDIT_CUSTOM_REPORT_SECTION_STYLE,
  EditCustomReportSectionStyleAction,
  OrderColumnAscendingAction,
  ORDER_COLUMN_ASCENDING,
  OrderColumnDescendingAction,
  ORDER_COLUMN_DESCENDING,
  OrderColumnDefaultAction,
  ORDER_COLUMN_DEFAULT,
  ResetCustomReportOrderAction,
  RESET_CUSTOM_REPORT_ORDER,
  SetCustomReportQuickFiltersAction,
  SET_QUICK_FILTERS,
  EmptyCustomReportQuickFiltersAction,
  EMPTY_QUICK_FILTERS,
  SET_ALL_SECTION_DATA,
  SetAllSectionDataAction,
  SetSectionsDataAction,
  SET_SECTIONS_DATA,
  CustomReportGroup,
  SET_CUSTOM_REPORT_GROUPS,
  SetCustomReportGroupsAction,
  DELETE_CUSTOM_REPORT_GROUP,
  DeleteCustomReportGroupAction,
  UPDATE_CUSTOM_REPORT_GROUP,
  UpdateCustomReportGroupAction,
  ADD_CUSTOM_REPORT_GROUP,
  AddCustomReportGroupAction,
  UPDATE_CUSTOM_REPORT_GROUPS,
  UpdateCustomReportGroupsAction,
  UPDATE_CUSTOM_REPORTS,
  UpdateCustomReportsAction,
  UPDATE_SECTIONS,
  UpdateSectionsAction
} from './types'
import { QuickFilterForm } from '../../../pages/reporting/custom/components/QuickFilters'
import { CustomReportCategory } from './typesCategory'
import {
  CustomReportSectionsType,
  CustomReportTableSection,
  CustomReportChartSection,
  CustomReportTextSection
} from './typesSection'
import { CustomReportVariable, CustomReportVariableRow } from './typesVariable'
import { Language } from '../../../types/user/User'

export const addCustomReportSection = (payload: CustomReportSectionsType): AddCustomReportSectionAction => ({
  type: ADD_CUSTOM_REPORT_SECTION,
  payload
})

export const editCustomReportSection = (payload: CustomReportSectionsType): EditCustomReportSectionAction => ({
  type: EDIT_CUSTOM_REPORT_SECTION,
  payload
})

export const editCustomReportSectionStyle = (
  payload: CustomReportTableSection | CustomReportChartSection
): EditCustomReportSectionStyleAction => ({
  type: EDIT_CUSTOM_REPORT_SECTION_STYLE,
  payload
})

export const deleteCustomReportSection = (
  sectionId: CustomReportSectionsType['id'],
  reportId: CustomReport['id']
): DeleteCustomReportSectionAction => ({
  type: DELETE_CUSTOM_REPORT_SECTION,
  payload: { sectionId, reportId }
})

// Report actions
export const updateSections = (sections: CustomReportSectionsType[]): UpdateSectionsAction => ({
  type: UPDATE_SECTIONS,
  payload: sections
})

export const setCustomReports = (reports: CustomReport[]): SetCustomReportsAction => ({
  type: SET_CUSTOM_REPORTS,
  payload: reports
})

export const setCustomReportGroups = (reportGroups: CustomReportGroup[]): SetCustomReportGroupsAction => ({
  type: SET_CUSTOM_REPORT_GROUPS,
  payload: reportGroups
})

export const addCustomReportGroup = (reportGroup: CustomReportGroup): AddCustomReportGroupAction => ({
  type: ADD_CUSTOM_REPORT_GROUP,
  payload: reportGroup
})

export const addCustomReport = (report: CustomReport): AddCustomReportsAction => ({
  type: ADD_CUSTOM_REPORT,
  payload: report
})

export const setCustomReport = (report: CustomReport): SetCustomReportAction => ({
  type: SET_CUSTOM_REPORT,
  payload: report
})

export const updateCustomReport = (report: Partial<CustomReport>): UpdateCustomReportAction => ({
  type: UPDATE_CUSTOM_REPORT,
  payload: report
})

export const updateCustomReportGroup = (report: CustomReportGroup): UpdateCustomReportGroupAction => ({
  type: UPDATE_CUSTOM_REPORT_GROUP,
  payload: report
})

export const updateCustomReportGroups = (report: CustomReportGroup[]): UpdateCustomReportGroupsAction => ({
  type: UPDATE_CUSTOM_REPORT_GROUPS,
  payload: report
})

export const updateCustomReports = (report: CustomReport[]): UpdateCustomReportsAction => ({
  type: UPDATE_CUSTOM_REPORTS,
  payload: report
})

export const deleteCustomReport = ({ id }: CustomReport): DeleteCustomReportsAction => ({
  type: DELETE_CUSTOM_REPORT,
  payload: id
})

export const deleteCustomReportGroup = ({ id }: CustomReportGroup): DeleteCustomReportGroupAction => ({
  type: DELETE_CUSTOM_REPORT_GROUP,
  payload: id
})

export const setQuickFiltersData = (filters: QuickFilterForm): SetCustomReportQuickFiltersAction => ({
  type: SET_QUICK_FILTERS,
  payload: filters
})

export const emptyQuickFiltersData = (): EmptyCustomReportQuickFiltersAction => ({
  type: EMPTY_QUICK_FILTERS
})

export const setCalculatedReport = (report: CustomReport | null): SetCalculatedReportAction => ({
  type: SET_CALCULATED_REPORT,
  payload: report
})

export const setSectionOrder = (id: number, sections: Partial<CustomReportSectionsType>[]): SetSectionOrderAction => ({
  type: SET_SECTION_ORDER,
  payload: { id, sections }
})

export const setAllSectionData = (sections: CustomReportSectionsType[]): SetAllSectionDataAction => ({
  type: SET_ALL_SECTION_DATA,
  payload: sections
})

export const setSectionData = (section: CustomReportSectionsType): SetSectionDataAction => ({
  type: SET_SECTION_DATA,
  payload: section
})

export const setSectionsData = (sections: CustomReportSectionsType[]): SetSectionsDataAction => ({
  type: SET_SECTIONS_DATA,
  payload: sections
})

export const resetCustomReportOrder = (): ResetCustomReportOrderAction => ({
  type: RESET_CUSTOM_REPORT_ORDER
})

export const oderColumnDefault = (
  sectionId: number,
  row: CustomReportVariable,
  column: CustomReportCategory
): OrderColumnDefaultAction => {
  return {
    type: ORDER_COLUMN_DEFAULT,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const oderColumnAscending = (
  sectionId: number,
  row: CustomReportVariable,
  column: CustomReportCategory
): OrderColumnAscendingAction => {
  return {
    type: ORDER_COLUMN_ASCENDING,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const oderColumnDescending = (
  sectionId: number,
  row: CustomReportVariable,
  column: CustomReportCategory
): OrderColumnDescendingAction => {
  return {
    type: ORDER_COLUMN_DESCENDING,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const setVariableOrder = (
  reportId: number,
  sectionId: number,
  order: Partial<CustomReportVariable>[]
): SetVariableOrderAction => ({
  type: SET_VARIABLE_ORDER,
  payload: { reportId, sectionId, order }
})

export const addSectionVariable = (reportId: number, variable: CustomReportVariable[]): AddSectionVariableAction => ({
  type: ADD_SECTION_VARIABLE,
  payload: { reportId, variable }
})

export const addSectionVariableExplainer = (
  explainer: CustomReportVariable,
  variable: CustomReportVariable
): AddSectionVariableExplainerAction => ({
  type: ADD_SECTION_VARIABLE_EXPLAINER,
  payload: { explainer, variable }
})

export const updateSectionVariable = (variable: CustomReportVariable): UpdateSectionVariableAction => ({
  type: UPDATE_SECTION_VARIABLE,
  payload: variable
})

export const deleteCustomReportVariable = (
  { id }: CustomReportVariable,
  reportId: CustomReport['id'],
  sectionId: (CustomReportTableSection | CustomReportChartSection)['id']
): DeleteCustomReportVariableAction => ({
  type: DELETE_CUSTOM_REPORT_VARIABLE,
  payload: { id, reportId, sectionId }
})

export const setCustomReportDate = (payload: string): SetCustomReportDateAction => ({
  type: SET_DATE,
  payload
})

// ****************
// Report requests
// ****************

export const getCustomReportsRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/custom/reports`,
    success: setCustomReports
  },
  meta: {
    type: 'CUSTOM_REPORT_REPORTS'
  }
})

export const getCustomReportGroupsRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/reporting/custom/reports/groups`,
    success: (payload: CustomReportGroup[]) => setCustomReportGroups(payload.map(c => ({ ...c, group: true })))
  },
  meta: {
    type: 'CUSTOM_REPORT_GROUPS'
  }
})

export const createCustomReportRequest = (companyId: Company['id'], data: CustomReport): PostRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: POST,
    url: `/api/companies/${companyId}/reporting/custom/reports`,
    success: addCustomReport
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

export const updateCustomReportRequest = (
  companyId: Company['id'],
  id: CustomReport['id'],
  data: Partial<CustomReport>
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/reporting/custom/reports/${id}`,
    success: updateCustomReport
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

export const updateCustomReportGroupRequest = (
  companyId: Company['id'],
  id: CustomReport['id'],
  data: Partial<CustomReportGroup>
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/reporting/custom/reports/groups/${id}`,
    success: updateCustomReportGroup
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

export const deleteCustomReportsRequest = (companyId: Company['id'], id: CustomReport['id']): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/reporting/custom/reports/${id}`,
    success: deleteCustomReport
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

export const deleteCustomReportGroupRequest = (
  companyId: Company['id'],
  id: CustomReport['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/reporting/custom/reports/groups/${id}`,
    success: deleteCustomReportGroup
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

// ****************
// Section requests
// ****************

export const getCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilter?: QuickFilterForm
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: {
      date: date && dayjs(date).endOf('month').format('YYYY-MM-DD'),
      quickFilter
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: setSectionData
  },
  meta: {
    type: `CUSTOM_REPORT_SECTION_${sectionId}`
  }
})

export const deleteCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: () => deleteCustomReportSection(sectionId, reportId)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

const editCustomReportSectionAndFetchData = (
  payload: CustomReportSectionsType,
  styleUpdate: boolean | undefined,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilters?: QuickFilterForm
) => {
  return (dispatch: any) => {
    if (styleUpdate) {
      dispatch(editCustomReportSectionStyle(payload as CustomReportTableSection | CustomReportChartSection))
    }
    if (!styleUpdate) {
      dispatch(editCustomReportSection(payload))
      dispatch(getCustomReportSectionRequest(companyId, reportId, sectionId, date, quickFilters))
    }
  }
}

export const updateCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  data: CustomReportSectionsType,
  styleUpdate?: boolean,
  date?: string,
  quickFilters?: QuickFilterForm
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data,
    params: {
      date: date && (data as CustomReportTextSection)?.text && dayjs(date).endOf('month').format('YYYY-MM-DD')
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: (payload: CustomReportSectionsType) =>
      editCustomReportSectionAndFetchData(payload, styleUpdate, companyId, reportId, sectionId, date, quickFilters)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

const addCustomReportSectionAndFetchData = (
  payload: CustomReportSectionsType,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  date?: string,
  quickFilters?: QuickFilterForm
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addCustomReportSection(payload))
    dispatch(getCustomReportSectionRequest(companyId, reportId, payload.id, date, quickFilters))
  }
}

export const addCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  section: CustomReportSectionsType,
  date?: string,
  quickFilters?: QuickFilterForm
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: section,
    params: {
      date: (section as CustomReportTextSection).text && date && dayjs(date).endOf('month').format('YYYY-MM-DD')
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections`,
    success: (payload: CustomReportSectionsType) =>
      addCustomReportSectionAndFetchData(payload, companyId, reportId, date, quickFilters)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

export const updateCustomReportSectionOrdersRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sections: Partial<CustomReportSectionsType>[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: sections,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/order`
  },
  meta: {
    type: 'UPDATE_CUSTOM_REPORTS_SECTION_ORDERS'
  }
})

// ****************
// Variable requests
// ****************

// export const addVariableRequest = (
//   companyId: Company['id'],
//   reportId: CustomReport['id'],
//   sectionId: CustomReportSection['id'],
//   variable: CustomReportVariable
// ): PostRequestAction => ({
//   type: REQUEST,
//   payload: {
//     method: POST,
//     data: variable,
//     url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}/variables`,
//     success: () => calculateCustomReportRequest(companyId, reportId)
//   },
//   meta: {
//     type: 'CUSTOM_REPORTS'
//   }
// })

// export const editVariableRequest = (
//   companyId: Company['id'],
//   reportId: CustomReport['id'],
//   sectionId: CustomReportSection['id'],
//   variable: CustomReportVariable,
//   variableId: number
// ): PutRequestAction => ({
//   type: REQUEST,
//   payload: {
//     method: PUT,
//     data: variable,
//     url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}/variables/${variableId}`,
//     success: () => calculateCustomReportRequest(companyId, reportId)
//   },
//   meta: {
//     type: 'CUSTOM_REPORTS'
//   }
// })

export const deleteVariablesAction = (actions: any[]): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    actions.forEach((action: any) => {
      dispatch(action)
    })
  }
}

export const deleteVariableRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  { sectionId, id }: Partial<CustomReportVariable>
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}/variables/${id}`,
    success: (payload: any) => sectionId && deleteCustomReportVariable(payload, reportId, sectionId)
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

export const getVariableExplainerRequest = (
  companyId: Company['id'],
  reportId: number,
  variable: CustomReportVariableRow,
  date: string,
  lng: Language,
  quickFilter?: QuickFilterForm
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { sectionId: variable.sectionId, variableId: variable.id, date, lng, quickFilter },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${variable.sectionId}/explain`,
    success: (payload: any) => addSectionVariableExplainer(payload.variables, variable)
  },
  meta: {
    type: `CUSTOM_REPORT_VARIABLE_EXPLAINER_${variable.id}`
  }
})

export const updateCustomReportVariableOrdersRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: (CustomReportTableSection | CustomReportChartSection)['id'],
  orders: any[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: orders,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}/variables/order`
  },
  meta: {
    type: 'UPDATE_CUSTOM_REPORTS_VARIABLE_ORDERS'
  }
})
