import { BudgetComment } from '../../../pages/budgeting/financialStatements/components/comment/BudgetCommentItem'

export const ADD_STATEMENTROW_BUDGET_COMMENT = 'ADD_STATEMENTROW_BUDGET_COMMENT'
export const ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT = 'ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT'
export const UPDATE_STATEMENTROW_BUDGET_COMMENT = 'UPDATE_STATEMENTROW_BUDGET_COMMENT'
export const UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT = 'UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT'
export const DELETE_STATEMENTROW_BUDGET_COMMENT = 'DELETE_STATEMENTROW_BUDGET_COMMENT'
export const DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT = 'DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT'
export const ADD_ACCOUNT_BUDGET_COMMENT = 'ADD_ACCOUNT_BUDGET_COMMENT'
export const ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT = 'ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT'
export const UPDATE_ACCOUNT_BUDGET_COMMENT = 'UPDATE_ACCOUNT_BUDGET_COMMENT'
export const UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT = 'UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT'
export const DELETE_ACCOUNT_BUDGET_COMMENT = 'DELETE_ACCOUNT_BUDGET_COMMENT'
export const DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT = 'DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT'

export interface AddStatementRowBudgetCommentAction {
  type: typeof ADD_STATEMENTROW_BUDGET_COMMENT
  payload: BudgetComment
}

export interface AddStatementRowDimensionBudgetCommentAction {
  type: typeof ADD_STATEMENTROW_DIMENSION_BUDGET_COMMENT
  payload: BudgetComment
}

export interface UpdateStatementRowBudgetCommentAction {
  type: typeof UPDATE_STATEMENTROW_BUDGET_COMMENT
  payload: BudgetComment
}

export interface UpdateDimensionStatementRowBudgetCommentAction {
  type: typeof UPDATE_DIMENSION_STATEMENTROW_BUDGET_COMMENT
  payload: BudgetComment
}

export interface DeleteStatementRowBudgetCommentAction {
  type: typeof DELETE_STATEMENTROW_BUDGET_COMMENT
  payload: BudgetComment
}

export interface DeleteDimensionStatementRowBudgetCommentAction {
  type: typeof DELETE_DIMENSION_STATEMENTROW_BUDGET_COMMENT
  payload: BudgetComment
}
export interface AddAccountBudgetCommentAction {
  type: typeof ADD_ACCOUNT_BUDGET_COMMENT
  payload: BudgetComment
}

export interface AddAccountDimensionBudgetCommentAction {
  type: typeof ADD_ACCOUNT_DIMENSION_BUDGET_COMMENT
  payload: BudgetComment
}

export interface UpdateAccountBudgetCommentAction {
  type: typeof UPDATE_ACCOUNT_BUDGET_COMMENT
  payload: BudgetComment
}

export interface UpdateDimensionAccountBudgetCommentAction {
  type: typeof UPDATE_DIMENSION_ACCOUNT_BUDGET_COMMENT
  payload: BudgetComment
}

export interface DeleteAccountBudgetCommentAction {
  type: typeof DELETE_ACCOUNT_BUDGET_COMMENT
  payload: BudgetComment
}

export interface DeleteDimensionAccountBudgetCommentAction {
  type: typeof DELETE_DIMENSION_ACCOUNT_BUDGET_COMMENT
  payload: BudgetComment
}

export type BudgetCommentActionTypes =
  | AddStatementRowBudgetCommentAction
  | UpdateStatementRowBudgetCommentAction
  | DeleteStatementRowBudgetCommentAction
  | AddAccountBudgetCommentAction
  | UpdateAccountBudgetCommentAction
  | DeleteAccountBudgetCommentAction
  | AddStatementRowDimensionBudgetCommentAction
  | AddAccountDimensionBudgetCommentAction
  | DeleteDimensionAccountBudgetCommentAction
  | DeleteDimensionStatementRowBudgetCommentAction
  | UpdateDimensionAccountBudgetCommentAction
  | UpdateDimensionStatementRowBudgetCommentAction
