/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { Upload, message, Image } from 'antd'
import type { GetProp, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { notificationAction } from '../../redux/middleware/actions'
import { authTokenSelector } from '../../redux/session/authentication/selectors'
import { contextContractIdSelector } from '../../redux/context/contract/selectors'
import { sessionContractIdSelector } from '../../redux/session/contract/selectors'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { AppDispatch } from '../../redux/store'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

interface LogoUploadProps {
  route: 'accountant' | 'company'
}

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const LogoUpload = ({ route }: LogoUploadProps) => {
  const token = useSelector(authTokenSelector)
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<any[]>([])

  // tilitoimisto
  const contractIdAccountant = useSelector(sessionContractIdSelector)

  // yritysasetus
  const contractIdCompany = useSelector(contextContractIdSelector)

  const getLogo = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/contracts/${
        route === 'accountant' ? contractIdAccountant : contractIdCompany
      }/logo`,
      method: 'GET',
      headers: {
        'X-Token': token || ''
      },
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        setFileList([
          {
            uid: '1',
            name: 'logo',
            status: 'done',
            url
          }
        ])
      })
      .catch(() => {
        setFileList([])
      })
  }

  useEffect(getLogo, [companyId])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleSubmit = (file: RcFile) => {
    const formData = new FormData()
    formData.append('file', file)
    axios({
      method: 'put',
      headers: {
        'X-Token': token ?? '',
        'Content-Type': 'multipart/form-data'
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/api/contracts/${
        route === 'accountant' ? contractIdAccountant : contractIdCompany
      }/logo`,
      data: formData
    })
      .then(() => {
        dispatch(notificationAction({ type: 'success', message: 'IMPORT_SUCCESS' }))
        getLogo()
      })
      .catch(error => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'IMPORT_ERROR',
            description: error?.response?.data?.message
          })
        )
      })
  }

  const handleDelete = () => {
    axios({
      method: 'delete',
      headers: {
        'X-Token': token ?? '',
        'Content-Type': 'multipart/form-data'
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/api/contracts/${
        route === 'accountant' ? contractIdAccountant : contractIdCompany
      }/logo`
    })
  }

  const onChange = ({ fileList: newFileList }: any) => {
    if (newFileList.length < 1) {
      handleDelete()
    }
    setFileList(newFileList)
  }

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
      return Upload.LIST_IGNORE
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
      return Upload.LIST_IGNORE
    }
    return isJpgOrPng && isLt2M
  }

  return (
    <>
      <Upload
        multiple={false}
        accept=".png,.jpg,.PNG,.JPG"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={handlePreview}
        beforeUpload={beforeUpload}
        customRequest={({ file }) => {
          handleSubmit(file as RcFile)
        }}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: visible => setPreviewOpen(visible),
            afterOpenChange: visible => !visible && setPreviewImage('')
          }}
          src={previewImage}
        />
      )}
    </>
  )
}

export default LogoUpload
