import React from 'react'
import { Select, TreeSelect } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReportRow } from '../../redux/context/reports/types'
import { BudgetingLevel } from '../../types/companySettings/CompanySettings'
import { incomeStatementSelector } from '../../redux/context/reports/selectors'

const { SHOW_PARENT } = TreeSelect

interface BudgetingMethodSourceSelectProps {
  budgetingLevel: BudgetingLevel
  width?: number | string
  value?: string
  onChange?: (value: string | string[]) => void
}

export const BudgetingMethodSourceSelect: React.FC<BudgetingMethodSourceSelectProps> = ({
  width = 240,
  budgetingLevel,
  value,
  onChange
}) => {
  const incomeStatement = useSelector(incomeStatementSelector)
  const { t } = useTranslation()

  const traverse = (row: ReportRow, children = false): any => {
    if (children) {
      return {
        value: row.code,
        title: `${row.code} - ${row.name}`
      }
    }

    return {
      value: row.id,
      title: t(`incomeStatement:${row.id}`),
      selectable: false,
      children: row?.accounts?.map((child: ReportRow) => traverse(child, true))
    }
  }

  const getAccountLevelSourceIds = (sourceIds: string[]): string[] => {
    const sourceIdList = []
    for (const sourceId of sourceIds) {
      if (incomeStatement.find(i => i.id === +sourceId)) {
        const incomeStatementRow = incomeStatement.find(i => i.id === +sourceId)
        incomeStatementRow?.accounts?.forEach(account => {
          sourceIdList.push(account.code)
        })
      } else {
        sourceIdList.push(sourceId)
      }
    }
    return sourceIdList
  }

  if (budgetingLevel === BudgetingLevel.account) {
    return (
      <TreeSelect
        multiple
        treeCheckable
        maxTagCount="responsive"
        showCheckedStrategy={SHOW_PARENT}
        value={value}
        onChange={sourceIds => onChange && onChange(getAccountLevelSourceIds(sourceIds as unknown as string[]))}
        style={{ width }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Please select"
        allowClear
        treeData={incomeStatement.filter(row => row?.accounts?.length).map(row => traverse(row))}
      />
    )
  }
  return (
    <Select mode="multiple" value={value} onChange={onChange} style={{ width }}>
      {incomeStatement.map(row => (
        <Select.Option key={row.id} value={row.id}>
          {t(`incomeStatement:${row.id}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
