import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SetIndustryComparisonData, SET_INDUSTRY_COMPARISON_DATA } from './types'
import { Company } from '../../../types/company/Company'
import { IndustryComparison } from '../../../types/industry/Industry'

export const setIndustryComparisonData = (industryComparisonData: IndustryComparison[]): SetIndustryComparisonData => ({
  type: SET_INDUSTRY_COMPARISON_DATA,
  payload: industryComparisonData
})

export const getIndustryComparisonData = (data: any, companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: data,
    url: `/api/companies/${companyId}/comparison/industry`,
    success: (payload: IndustryComparison[]) => setIndustryComparisonData(payload)
  },
  meta: {
    type: 'COMPARISON'
  }
})
