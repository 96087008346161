import { Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { ReactComponent as Logo } from '../../assets/finadeck-logo-web.svg'

const Welcome: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Result
      subTitle={dayjs().format('DD.MM.YYYY')}
      icon={<Logo style={{ height: '128px' }} />}
      title={t('global:welcome')}
    />
  )
}

export default Welcome
