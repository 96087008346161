import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Typography, theme } from 'antd'
import { MenuProps } from 'antd/es/menu'
import { Link, useLocation } from 'react-router-dom'
import { ProCard } from '@ant-design/pro-components'
import RcResizeObserver from 'rc-resize-observer'
import { MenuItemType } from 'antd/es/menu/interface'
import PageHeaderWrapper from '../components/PageHeaderWrapper/PageHeaderWrapper'
import { Route } from '../routes/Route'
import DocumentationLink from '../components/Misc/DocumentationLink'

interface CompanySettingsProps {
  children: React.ReactNode
  route: Route
}

function CompanySettings({ children, route, route: { routes = [] } }: CompanySettingsProps) {
  const location = useLocation()
  const { t } = useTranslation()
  const [responsive, setResponsive] = useState(false)

  const {
    token: { paddingContentHorizontalLG, padding, marginLG }
  } = theme.useToken()

  const getSettingTitle = () => {
    return location.pathname.split('/').slice(0, 3).join('/')
  }

  const getSettingPageTitle = () => {
    const apu = location.pathname.split('/')
    const [last] = apu.slice(-1)
    if (parseInt(last, 10)) return null
    return (
      <Typography.Title level={4} style={{ marginBottom: marginLG }}>
        {t(`menu:${location.pathname}`, '')}
      </Typography.Title>
    )
  }

  const generateMenuItems = (menuRoutes: Route[]): MenuItemType[] => {
    return menuRoutes
      .filter((r: Route) => !r.hideInMenu)
      .map((r: Route) => {
        const name = t(`menu:${r.path}`, r.name)
        const child: Route[] = r.routes?.filter((c: Route) => !c.hideInMenu) || []
        return {
          key: r.path,
          label: child?.length > 0 ? name : <Link to={r.path}>{name}</Link>,
          icon: r?.icon,
          children: child?.length > 0 && generateMenuItems(child)
        }
      })
  }

  const generateMenu = (settingRoutes: Route[], mode: MenuProps['mode'], selectedKey: string) => {
    return (
      <Menu
        style={{ height: '100%', width: 'calc(100% + 1px)' }}
        mode={mode}
        selectedKeys={[selectedKey]}
        items={generateMenuItems(settingRoutes)}
      />
    )
  }

  return (
    <PageHeaderWrapper
      title={t(`menu:${getSettingTitle()}`, '')}
      subTitle={<DocumentationLink route={route} />}
      ghost={false}
      breadcrumb
    >
      <RcResizeObserver
        key="resize-observer"
        onResize={offset => {
          setResponsive(offset.width < 900)
        }}
      >
        <ProCard split={responsive ? 'horizontal' : 'vertical'}>
          <ProCard
            colSpan={responsive ? 24 : '248px'}
            style={{
              border: 0,
              marginBottom: responsive ? -padding : undefined,
              marginRight: responsive ? undefined : -padding,
              padding: responsive ? undefined : `0 ${padding}`
            }}
          >
            <div style={{ marginInline: -paddingContentHorizontalLG }}>
              {generateMenu(routes, responsive ? 'horizontal' : 'inline', location.pathname)}
            </div>
          </ProCard>
          <ProCard colSpan={responsive ? 24 : 'calc(100% - 248px)'}>
            {getSettingPageTitle()}
            {children}
          </ProCard>
        </ProCard>
      </RcResizeObserver>
    </PageHeaderWrapper>
  )
}

export default CompanySettings
