import React, { useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { InputRef, Input, Button } from 'antd'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

type DataIndex = string | string[]

export const useColumnSearchProps = <T extends {}>(
  dataIndex: DataIndex,
  render?: (dataIndexValue: any, record: T, index?: number) => string
) => {
  const { t } = useTranslation()
  const inputEl = useRef<InputRef>(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys: string[], confirm: any, i: string) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(i.toString())
  }

  const handleReset = (clearFilters: Function, confirm: any) => {
    clearFilters()
    setSearchText('')
    confirm()
  }

  const getValue = (record: any, dataI: DataIndex): number | string | null => {
    if (typeof dataI === 'string') {
      return record[dataI]
    }
    return dataI.reduce((value, i) => value?.[i], record)
  }

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputEl}
          placeholder={t('global:search')}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex?.toString())}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex?.toString())}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('global:search')}
        </Button>
        <Button
          className="resetButton"
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {t('global:reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (search: any, record: any) => {
      const dataIndexValue = getValue(record, dataIndex)
      const text = render ? render(dataIndexValue, record) : dataIndexValue
      return !!text?.toString().toLowerCase().includes(search.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (inputEl && inputEl !== null && inputEl.current) {
            inputEl.current.focus()
          }
        })
      }
    },
    render: (dataIndexValue: any, record: any) => {
      if (searchedColumn === dataIndex.toString()) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={render ? render(dataIndexValue, record) : dataIndexValue?.toString()}
          />
        )
      }
      return render ? render(dataIndexValue, record) : dataIndexValue
    }
  }
}
