import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { KeyFiguresActionTypes, SET_KEY_FIGURES } from './types'

const initialState: KeyFigure[] = []

const keyFiguresReducer = (state = initialState, action: KeyFiguresActionTypes) => {
  switch (action.type) {
    case SET_KEY_FIGURES:
      return action.payload
    default:
      return state
  }
}

export default keyFiguresReducer
