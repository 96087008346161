import { createSelector } from 'reselect'
import { Store } from '../types'
import { Context } from './types'

export const contextSelector = (store: Store) => store.context as Context

export const getContextByPropertySelector = (propertyName: keyof Context) =>
  createSelector(contextSelector, ctx => {
    return ctx[propertyName]
  })
