import { createSelector } from 'reselect'
import { forecastsSelector } from '../forecasts/selectors'
import { contextSelector } from '../selectors'

export const budgetingScenariosSelector = createSelector(contextSelector, context => context.budgetingScenarios || [])

export const budgetingScenariosAndForecastsSelector = createSelector(
  [budgetingScenariosSelector, forecastsSelector],
  (a, b) => [...a, ...b]
)
