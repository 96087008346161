import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useBackend } from '../../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import DimensionSelect from '../../../../../../components/Dimension/DimensionSelect'
import EditableCellTable from '../../../../../../components/Table/EditableCellTable'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import { AppDispatch } from '../../../../../../redux/store'

const Kotipizza: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const [restaurants, setRestaurants] = useState<any[]>([])
  const getRestaurants = useBackend(`/kotipizza/restaurants/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const updateRestaurant = useBackend(
    `/kotipizza/restaurants/{companyId}/{RAVINTOLANUMERO}`,
    process.env.REACT_APP_INTEGRATION_URL
  )

  const fetchRestaurants = async () => {
    try {
      const response = await getRestaurants.get({
        urlParams: { companyId }
      })

      setRestaurants(response)
    } catch (error) {
      setRestaurants([])
    }
  }

  useEffect(() => {
    fetchRestaurants()
  }, [companyId])

  const columns = [
    {
      title: t('global:name'),
      dataIndex: 'RAVINTOLAN_NIMI',
      key: 'RAVINTOLAN_NIMI',
      width: '50%'
    },
    {
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      key: 'dimensionId',
      width: '50%',
      render: (text: any, record: any) => record?.dimension?.name,
      editable: true,
      inputField: DimensionSelect
    }
  ]

  const onUpdate = (formFields: any) => {
    const { RAVINTOLANUMERO, dimensionId } = formFields
    updateRestaurant
      .put({
        urlParams: { companyId, RAVINTOLANUMERO },
        body: { data: { dimensionId: dimensionId || null, RAVINTOLANUMERO } }
      })
      .then((response: any) => {
        setRestaurants(
          restaurants.map(r => {
            if (r.RAVINTOLANUMERO === response.RAVINTOLANUMERO) {
              return { ...r, ...response, dimension: response?.dimension }
            }
            return r
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'UPDATE_INTEGRATION_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  return (
    <LoadingWrapper loading={getRestaurants.loading || updateRestaurant.loading}>
      <EditableCellTable<any>
        rowKey={(val: any) => val.RAVINTOLAN_NIMI}
        dataSource={restaurants}
        columns={columns}
        onUpdate={onUpdate}
      />
    </LoadingWrapper>
  )
}

export default Kotipizza
