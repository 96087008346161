import React from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'antd'
import { updateCurrentUserPasswordRequest } from '../../../../redux/session/currentUser/actions'
import { UserPasswordCredentials } from '../../../../types/user/User'
import PasswordForm from '../../../../components/User/PasswordForm'
import { AppDispatch } from '../../../../redux/store'

const Password = () => {
  const dispatch: AppDispatch = useDispatch()

  const handleSumit = (values: UserPasswordCredentials) => {
    dispatch(updateCurrentUserPasswordRequest(values))
  }

  return (
    <Row>
      <Col span={8}>
        <PasswordForm onSubmit={handleSumit} />
      </Col>
    </Row>
  )
}

export default Password
