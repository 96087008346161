import { Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { companyAccountsByStatementRowIdSelector } from '../../redux/context/accounts/selectors'
import { Account } from '../../types/account/Account'

interface AccountSelectProps {
  balanceSheetRowIds?: number[]
  selectableBalanceSheetRowId?: number
  disabled?: boolean
  allowClear?: boolean
  value?: number
  placeholder?: string
  style?: React.CSSProperties
  onChange?: (value: number) => void
}

const AccountSelect: React.FC<AccountSelectProps> = ({
  balanceSheetRowIds,
  value,
  allowClear,
  selectableBalanceSheetRowId,
  disabled,
  placeholder,
  style,
  onChange
}) => {
  const { t } = useTranslation()
  const accountsByStatementRowIdId = useSelector(companyAccountsByStatementRowIdSelector)
  const triggerChange = (changedValue: number) => {
    onChange?.(changedValue)
  }

  const toOption = (account: Account) => ({
    label: `${account.code} - ${account.name}`,
    value: account.code
  })

  const options = Object.entries(accountsByStatementRowIdId)
    .filter(([statementRowId]) => {
      if (balanceSheetRowIds) {
        return balanceSheetRowIds.includes(+statementRowId)
      }
      return true
    })
    .filter(([statementRowId]) => {
      if (!selectableBalanceSheetRowId) return true
      return +statementRowId === selectableBalanceSheetRowId
    })
    .map(([statementRowId, accounts], index, array) => {
      if (array.length > 1) {
        return {
          label: t(`balanceSheet:${statementRowId}`, t(`incomeStatement:${statementRowId}`)),
          options: accounts.map(toOption)
        }
      }
      return accounts.map(toOption)
    })
    .flat()

  return (
    <Select
      allowClear={allowClear}
      disabled={disabled}
      onChange={triggerChange}
      value={value}
      style={style}
      showSearch
      placeholder={placeholder}
      dropdownStyle={{ width: 300, minWidth: 300 }}
      optionFilterProp="label"
      options={options}
    />
  )
}

export default AccountSelect
