import i18next from 'i18next'

export const translateKatex = (katex = ''): string | undefined => {
  const regex = /\((\w+):([0-9a-zA-Z]+)\)/g
  return katex
    ?.toString()
    .match(regex)
    ?.reduce((acc, match) => {
      return acc?.replace(match, `\\textrm{${i18next.t(match.replace('(', '').replace(')', '')).replace('%', '\\%')}}`)
    }, katex)
}
