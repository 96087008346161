import axios from 'axios'
import axiosRetry from 'axios-retry'
import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { RequestActionTypes, RequestConfig } from './types'
import { metaAction, notificationAction, errorAction } from './actions'

const getMetaType = (str: string) => {
  // Poistaa numerot metatyypistä
  const arr = str.split('_')
  if (Number.isInteger(parseInt(arr[arr.length - 1], 10))) {
    arr.pop()
  }

  return arr.join('_')
}

export const request = ({ type, baseURL }: RequestConfig) => {
  const use: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
    (next: Dispatch) =>
    (action: RequestActionTypes) => {
      if (action.type !== type) {
        return next(action)
      }

      const api = axios.create({
        baseURL,
        headers: { 'X-Token': getState().session ? getState().session.authentication.token : undefined }
      })

      if (action.retry) {
        axiosRetry(api, {
          retries: 3,
          retryDelay: retryCount => {
            return retryCount * 1000
          }
        })
      }

      dispatch({ type: action.meta.type.concat('_REQUEST') })
      api(action.payload)
        .then(response => {
          dispatch(metaAction(action.meta.type.concat('_SUCCESS')))
          if (action.meta.notification) {
            dispatch(
              notificationAction({
                type: 'success',
                message: getMetaType(action.meta.type).concat('_SUCCESS')
              })
            )
          }
          if (action.payload.success) dispatch(action.payload.success(response.data))
        })
        .catch(error => {
          // If error dispatch null to set initial state to redux
          console.log(error)
          if (error?.response?.status === 404 && action.payload.success) dispatch(action.payload.success(null))
          dispatch(
            errorAction(action.meta.type.concat('_ERROR'), {
              code: error?.response?.status,
              message: error?.response?.data?.message
            })
          )
          if (action.meta.notification) {
            dispatch(
              notificationAction({
                type: 'error',
                message: getMetaType(action.meta.type).concat('_ERROR'),
                description: error?.response?.data?.message
              })
            )
          }
        })
      return next(action)
    }

  return use
}
