import React from 'react'
import { DatePicker, Modal, Switch, Input, Form } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { createFiscalYearRequest } from '../../../../../redux/context/fiscalYears/actions'
import { DateRangeValue } from '../../../../../types/date/types'
import { useAuthority } from '../../../../../utils/Authorizable/authorize'
import { budgetableSettingAuthority } from '../FiscalYears'
import { AppDispatch } from '../../../../../redux/store'

interface FiscalYearsFormProps {
  fiscalYear?: FiscalYear
  latestFiscalYear?: FiscalYear
  modalVisible: boolean
  handleCancel: () => void
  handleUpdateData: (values: any) => void
}

const FiscalYearsForm = ({
  fiscalYear,
  latestFiscalYear,
  modalVisible,
  handleCancel,
  handleUpdateData
}: FiscalYearsFormProps) => {
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const { t } = useTranslation()

  const showBudgetableSetting = useAuthority(budgetableSettingAuthority)

  const cancelForm = () => {
    form.resetFields()
    handleCancel()
  }

  const handleOk = async () => {
    try {
      const {
        period: [startDate, endDate],
        ...restValues
      } = await form.validateFields()

      const values = {
        ...restValues,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      }

      if (values.id) {
        handleUpdateData(values)
      }
      if (!values.id) {
        companyId && dispatch(createFiscalYearRequest(companyId, values))
      }
    } catch (error) {
      console.log(error)
    } finally {
      cancelForm()
    }
  }

  const getSuggestedNextPeriod = (): DateRangeValue<Dayjs> | undefined => {
    if (!latestFiscalYear) return undefined
    const startDate = dayjs(latestFiscalYear.startDate).add(1, 'years')
    const endDate = dayjs(latestFiscalYear.endDate).add(1, 'years')

    return [startDate, endDate]
  }

  return (
    <Modal
      title={fiscalYear ? t('fiscalYearsPage:edit-fiscal-year') : t('fiscalYearsPage:add-new')}
      open={modalVisible}
      onOk={handleOk}
      onCancel={cancelForm}
      cancelText={t('global:cancel')}
      forceRender={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form
        name="fiscalyears-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        initialValues={{
          ...fiscalYear,
          period: fiscalYear?.id
            ? [dayjs(fiscalYear?.startDate), dayjs(fiscalYear?.endDate)]
            : getSuggestedNextPeriod(),
          isOpen: !fiscalYear ? true : fiscalYear.isOpen
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="period" label={t('global:time-period')}>
          <DatePicker.RangePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues: any, curValues: any) => prevValues?.isOpen !== curValues?.isOpen}>
          {() => (
            <>
              <Form.Item
                name="isOpen"
                valuePropName="checked"
                label={`${t('fiscalYearsPage:isopen')} / ${t('fiscalYearsPage:isclosed')}`}
              >
                <Switch
                  checkedChildren={t('fiscalYearsPage:isopen')}
                  unCheckedChildren={t('fiscalYearsPage:isclosed')}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
        <Form.Item
          hidden={!showBudgetableSetting}
          name="budgetable"
          valuePropName="checked"
          label={`${t('global:budgetable')} `}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default FiscalYearsForm
