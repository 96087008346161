import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Collapse, Divider, Form, Popconfirm, Select, Space, theme } from 'antd'
import React, { ReactText, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'antd/es/form/Form'
import { TimePeriodOptions } from '../../../../../redux/context/customReports/types'
import ColorPicker from './ColorPicker'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { FormItemGroup } from '../../../../../components/Form/FormItemContext'
import SeriesStyleFields from '../chart/SeriesStyleFields'
import { VariableType } from '../../../../../components/Table/types'
import RowSettingsModal from '../RowSettingsModal'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface FunctionCategoryProps {
  path: string
  category: CustomReportCategory
}

export const FunctionCategory: React.FC<FunctionCategoryProps> = ({ path, category }: FunctionCategoryProps) => {
  const { t } = useTranslation()
  const {
    dimensionMap,
    sectionForm,
    categoryTree,
    editObject,
    deleteObject,
    addFunctionCompany,
    addDimension,
    getPathContext
  } = useContext(CategoryContext) as CategoryContextType
  const [color, setColor] = useState<string | undefined>(category.color || 'rgb(255,255,255,1)')
  const dimensionTree = dimensionMap && category.value && dimensionMap[category.value as ReactText]
  const categoryContext = getPathContext(path)
  const [styleForm] = useForm()
  const { token } = theme.useToken()
  const switchRowsAndColumns = useWatch(['style', 'switchRowsAndColumns'], sectionForm)
  const sectionType = useWatch('type', sectionForm)
  const categoryStylesEnabled = useMemo(
    () => switchRowsAndColumns && sectionType === 'graph',
    [switchRowsAndColumns, sectionType]
  )

  /* 
  Jos funktiolla ei ole parenttia nii silloin sille voi lisätä vain yrityksiä ja 
  funktion value voi olla vain sum
   */
  const hasParent = !!categoryTree.find(path)?.parent?.value

  useEffect(() => {
    styleForm.setFieldsValue(category)
  }, [category])

  const onChange = (c: Partial<CustomReportCategory>) => {
    switch (c.value) {
      case 'div':
        editObject(path, {
          ...category,
          ...c,
          children: [
            {
              id: `${path}-0`,
              type: 'reference',
              value: undefined
            },
            {
              id: `${path}-1`,
              type: 'reference',
              value: undefined
            }
          ]
        })
        break
      case 'diff':
      case 'growth':
        editObject(path, {
          ...category,
          ...c,
          children: [
            {
              id: `${path}-0`,
              type: 'periodGroup',
              value: TimePeriodOptions.CurrentMonth
            },
            {
              id: `${path}-1`,
              type: 'periodGroup',
              value: TimePeriodOptions.CurrentMonth
            }
          ]
        })
        break
      case 'division':
      case 'commonSize':
        editObject(path, {
          ...category,
          ...c,
          children: [
            {
              id: `${path}-0`,
              type: 'keyFigureId',
              value: []
            },
            {
              id: `${path}-1`,
              type: 'periodGroup',
              value: TimePeriodOptions.CurrentMonth
            }
          ]
        })
        break
      case 'sum':
        editObject(path, {
          ...category,
          ...c,
          children: [
            {
              id: `${path}-0`,
              type: 'dimension',
              value: undefined,
              children: []
            },
            {
              id: `${path}-1`,
              type: 'dimension',
              value: undefined,
              children: []
            }
          ]
        })
        break
      default:
        editObject(path, { ...category, ...c })
        break
    }
  }

  const handleColorChange = (newColor?: string) => {
    setColor(newColor)
    onChange({ color: newColor })
  }

  return (
    <>
      <Space size={0} split={<Divider type="vertical" />}>
        <Select
          disabled={!hasParent}
          onChange={(value: ReactText) => onChange({ value })}
          style={{ width: 200 }}
          value={(category.value as ReactText) || 'diff'}
        >
          <Select.Option value="diff">{t('customReportPage:diff')}</Select.Option>
          <Select.Option value="growth">{t('customReportPage:growth')}</Select.Option>
          <Select.Option value="commonSize">{t('customReportPage:commonSize')}</Select.Option>
          <Select.Option value="division">{t('customReportPage:division')}</Select.Option>
          <Select.Option value="sum" disabled={!dimensionMap[categoryContext.companyId]?.length}>
            {t('customReportPage:sum')}
          </Select.Option>
          <Select.Option value="div">{t('customReportPage:divide')}</Select.Option>
        </Select>
        {category.value === 'sum' && hasParent ? (
          <Button
            disabled={!!(dimensionTree && dimensionTree.length < 1)}
            onClick={() => addDimension(path)}
            icon={<PlusOutlined />}
          >
            {t('global:dimension')}
          </Button>
        ) : null}
        {category.value === 'sum' && !hasParent ? (
          <Button
            disabled={!!(dimensionTree && dimensionTree.length < 1)}
            onClick={() => addFunctionCompany(path)}
            icon={<PlusOutlined />}
          >
            {t('global:company')}
          </Button>
        ) : null}

        {category.value === 'div' && (
          <Select
            onChange={(variableType: ReactText) => onChange({ variableType: variableType as VariableType })}
            style={{ width: 200 }}
            value={category.variableType || VariableType.absolute}
          >
            {Object.values(VariableType).map(v => (
              <Select.Option value={v}>{t(`keyFigurePage:${v}`)}</Select.Option>
            ))}
          </Select>
        )}
        {!categoryStylesEnabled && <ColorPicker value={color} onChange={handleColorChange} />}
        <Popconfirm
          title={t('global:delete-confirm')}
          onConfirm={() => deleteObject(path)}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
        <RowSettingsModal onChange={onChange} path={path} category={category} />
      </Space>
      {categoryStylesEnabled ? (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <SettingOutlined rotate={isActive ? 90 : 0} />}
          style={{
            background: token.colorBgContainer,
            margin: 0,
            marginTop: 4
          }}
          items={[
            {
              key: '1',
              label: t('global:style'),
              children: (
                <Space>
                  <Form
                    key={path}
                    layout="inline"
                    form={styleForm}
                    onValuesChange={(val, allVals) => onChange(allVals)}
                  >
                    <FormItemGroup prefix={['style']}>
                      <SeriesStyleFields />
                    </FormItemGroup>
                  </Form>
                </Space>
              )
            }
          ]}
        />
      ) : null}
    </>
  )
}
