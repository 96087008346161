import React, { useState } from 'react'
import { Descriptions, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/PageHeaderWrapper'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import IndustryComparison from './IndustryComparison'
import CompetitorComparison from './CompetitorComparison'
import './_IndustryComparison.sass'
import DocumentationLink from '../../components/Misc/DocumentationLink'

const IndustryAndCompetitorComparison: React.FC = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState('industry')
  const contextCompany = useSelector(contextCompanySelector)

  const handleTabChange = (key: string) => {
    setCurrentPage(key)
  }

  const tabList = [
    {
      key: 'industry',
      label: t('comparisonPage:industry-comparison')
    },
    {
      key: 'competitor',
      label: t('comparisonPage:competitor-comparison')
    }
  ]

  const companyInfo = (
    <Descriptions size="small" column={1}>
      <Descriptions.Item label={t('comparisonPage:company')}>{contextCompany && contextCompany.name}</Descriptions.Item>
      <Descriptions.Item label={t('comparisonPage:industry')}>
        {contextCompany && `${contextCompany.industryId} - ${t(`tol2008:${contextCompany.industryId}`)}`}
      </Descriptions.Item>
    </Descriptions>
  )

  const getPageComponent = () => {
    switch (currentPage) {
      case 'industry':
        return <IndustryComparison />
      case 'competitor':
        return <CompetitorComparison />
      default:
        return null
    }
  }

  return (
    <PageHeaderWrapper
      ghost={false}
      title={t('menu:/comparison')}
      subTitle={
        <Tooltip placement="right" title="info">
          <DocumentationLink />
        </Tooltip>
      }
      content={companyInfo}
      tabList={tabList}
      tabActiveKey={currentPage}
      onTabChange={handleTabChange}
    >
      {getPageComponent()}
    </PageHeaderWrapper>
  )
}

export default IndustryAndCompetitorComparison
