import { PeriodGroup } from '../periodGroup/PeriodGroup'
import { DeliverySubscription } from './DeliverySubscription'

export enum DeliveryStatus {
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export interface Delivery {
  id: number
  deliverySubscriptionId: number
  deliverySubscription: DeliverySubscription
  status?: DeliveryStatus
  meta?: DeliveryMeta
  errors?: any
  startTime?: Date
  endTime?: Date
  uuid: string
}

export interface DeliveryMeta {
  periodGroups?: PeriodGroup[]
}
