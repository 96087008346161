import { FinancialStatementConfigActionTypes, SET_FINANCIAL_STATEMENT_CONFIG } from './types'

const initialState = {}

const financialStatementConfigReducer = (state = initialState, action: FinancialStatementConfigActionTypes) => {
  switch (action.type) {
    case SET_FINANCIAL_STATEMENT_CONFIG:
      return action.payload
    default:
      return state
  }
}

export default financialStatementConfigReducer
