import i18next from 'i18next'
import { Dayjs } from 'dayjs'
import { setPeriodGroupFilter } from '../../redux/context/filters/actions'
import { Column } from '../../redux/context/periodGroups/hooks'
import { Balance, Report, ReportDataType } from '../../redux/context/reports/types'
import { FiscalYear } from '../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'
import { hasMonthlyData } from '../Table/utils'
import { ReportRowExplanation } from './PieChart'
import { DrillData } from '../../pages/reporting/custom/components/chart/chartUtils'

export const getSerieOptions = (dataType: ReportDataType, type = 'column') => {
  switch (dataType) {
    case ReportDataType.actuals:
      return {
        type,
        dashStyle: null
      }
    case ReportDataType.budget:
      return {
        type: 'spline',
        dashStyle: 'ShortDash'
      }
    case ReportDataType.comparativePeriod:
      return {
        type: 'spline',
        dashStyle: 'ShortDot'
      }
    case ReportDataType.forecast:
      return {
        type,
        dashStyle: null
      }
    default:
      return {
        type: 'spline',
        dashStyle: null
      }
  }
}

export const setFiscalyearFilterByLabel = (
  label: string | null,
  columns: Column[] = [],
  dataSource: Report,
  dispatch: Function
): void => {
  const c = columns.find((clm: Column) => clm.label === label)
  if (c && c.expandable && hasMonthlyData(dataSource, c)) {
    dispatch(setPeriodGroupFilter(c.periodGroup))
  } else {
    dispatch(setPeriodGroupFilter(undefined))
  }
}

export const setFiscalYearFilterEvent = (columns: Column[] = [], dataSource?: Report, dispatch?: Function) => {
  if (!dataSource || !dispatch) return null
  return {
    events: {
      click: (e: React.MouseEvent) => {
        const labelHtml = e.currentTarget.innerHTML
        const label = labelHtml.replaceAll(/<tspan\s*.*>\s*.*<\/tspan>/gi, ' ')
        setFiscalyearFilterByLabel(label, columns, dataSource, dispatch)
      }
    },
    style: {
      cursor: 'pointer' as const,
      color: '#096dd9'
    }
  }
}

export const chartDrillupAll = (rootName?: string, chart?: Highcharts.Chart) => {
  let counter = 0
  if (!chart) return
  while (chart.series?.[0]?.name !== 'Series 1') {
    if (counter > 10) {
      break
    }
    counter += 1
    chart.drillUp()
  }
}

const explainersToSeries = (
  explainer: ReportRowExplanation[],
  dataType = ReportDataType.actuals,
  pGroup?: FiscalYear | PeriodGroup,
  d?: Dayjs | null,
  forceDrillDown?: boolean
): Highcharts.PointOptionsObject[] => {
  return explainer.map(explain => {
    const balances = explain[dataType] as Balance[]
    const balance = pGroup?.id ? balances?.find(b => b.groupId === pGroup?.id) : undefined

    let value = balance?.value
    if (d && !pGroup) {
      value = balances?.find(b => b.year === d.year() && b.month === d.month() + 1)?.value
    }

    return {
      name: explain.title || (i18next.t(`${explain.translation}`) as string) || '',
      drilldown: forceDrillDown || 'children' in explain ? explain.id?.toString() : undefined,
      y: value ? Math.abs(value) : null,
      negative: value ? value < 0 : false
    }
  })
}

export const getDrilldownData = (
  formulaId: string,
  explainers: ReportRowExplanation[],
  pGroup?: FiscalYear | PeriodGroup,
  d?: Dayjs | null
): DrillData => {
  const explanations: DrillData = {}
  const traverse = (id: string, rows: ReportRowExplanation[]) => {
    if (rows?.length === 1 && 'children' in rows[0]) {
      rows.forEach((row: ReportRowExplanation) => {
        row.children && row.id && traverse(id, row.children)
      })
    } else {
      const series = explainersToSeries(rows, ReportDataType.actuals, pGroup, d)
      explanations[id] = series
      rows.forEach((row: ReportRowExplanation) => {
        row.children && row.id && traverse(row.id.toString(), row.children)
      })
    }
  }
  traverse(formulaId, explainers)
  return explanations
}
