import { CashFlowStatementConfigActionTypes, SET_CASH_FLOW_STATEMENT_CONFIG } from './types'

const initialState = {}

const cashFlowStatementConfigReducer = (state = initialState, action: CashFlowStatementConfigActionTypes) => {
  switch (action.type) {
    case SET_CASH_FLOW_STATEMENT_CONFIG:
      return action.payload
    default:
      return state
  }
}

export default cashFlowStatementConfigReducer
