import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Result, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { HeerosCompanyUser } from '../types'
import { useDocsURL } from '../../../../../utils/docs'
import DataUpdate from '../dataUpdate'
import { AppDispatch } from '../../../../../redux/store'

const Heeros: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const getUserRequest = useBackend(`/heeros/users/company/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setUserRequest = useBackend(`/heeros/users/company/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const [user, setUser] = useState<HeerosCompanyUser>()
  const [authorized, setAuthorized] = useState(false)

  const fetch = () => {
    getUserRequest
      .get({
        urlParams: { companyId }
      })
      .then((response: HeerosCompanyUser) => {
        setUser(response)
        if (response) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
          setUser(undefined)
        }
      })
      .catch(() => {
        setAuthorized(false)
        setUser(undefined)
      })
  }

  useEffect(fetch, [companyId])

  const submit = () => {
    setUserRequest
      .put({
        urlParams: { companyId },
        body: { data: user }
      })
      .then((response: HeerosCompanyUser) => {
        setUser(response)
        setAuthorized(true)
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch((err: Error) => {
        setAuthorized(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: err.message
          })
        )
      })
  }

  const emptyCredentials = () => {
    setUser(undefined)
    setAuthorized(false)
  }

  if (authorized) {
    return (
      <>
        <Result
          status="success"
          title={t('integrationsPage:authenticated')}
          extra={
            <>
              <Button onClick={emptyCredentials} type="primary">
                {t('integrationsPage:authenticate')}
              </Button>
              <DataUpdate page="heeros" />
            </>
          }
        />
      </>
    )
  }
  return (
    <Form layout="vertical">
      <a href={`${docsURL}manual/businesses/settings/company#heeros`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:customer-id')}>
        <Input
          value={user && user.la}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ la: event.target.value })}
          suffix={getUserRequest.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => submit()} loading={setUserRequest.loading}>
          {t('integrationsPage:authenticate')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Heeros
