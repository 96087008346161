import _ from 'lodash'
import { Dimension } from '../../types/dimension/Dimension'

export const treeToflatList = (listToFlat: any[]) => {
  // muuttaa minkä tahansa puumuotoisen datan flätiksi listaksi
  if (listToFlat && listToFlat.length > 0) {
    const flattenList: any[] = []
    const traverse = (ds: any[]) => {
      for (const d of ds) {
        const list = _.clone(d)
        delete list.children
        flattenList.push(list)
        if (d.children) traverse(d.children)
      }
    }

    traverse(listToFlat)
    return flattenList
  }
  return []
}

export const getDimensionTree = (dimensionList: Dimension[]) => {
  const traverse: any = (dimension: Dimension) => {
    if (dimensionList) {
      const children = dimensionList
        .slice()
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .filter(d => d.parentDimensionId === dimension.dimensionId)
      return {
        ...dimension,
        children: children.length > 0 ? children.map(child => traverse(child)) : undefined
      }
    }
    return []
  }
  return (
    dimensionList
      ?.filter(d => !d.parentDimensionId || !dimensionList.find(i => i.dimensionId === d.parentDimensionId))
      .slice()
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(root => traverse(root)) || []
  )
}
