import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { ADD_FORECAST, ForecastsActionTypes, DELETE_FORECAST, SET_FORECASTS, UPDATE_FORECAST } from './types'

const initialState: BudgetingScenario[] = []

const forecastsReducer = (state = initialState, action: ForecastsActionTypes) => {
  switch (action.type) {
    case SET_FORECASTS:
      return [...action.payload]
    case ADD_FORECAST:
      return [...state, action.payload]
    case UPDATE_FORECAST:
      return state.map((item: BudgetingScenario) =>
        item.id !== action.payload.id ? item : { ...item, ...action.payload }
      )
    case DELETE_FORECAST:
      return state.filter((item: BudgetingScenario) => item.id !== action.payload.id)
    default:
      return state
  }
}

export default forecastsReducer
