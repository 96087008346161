import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

export const Loading: React.FC = () => {
  return (
    <Spin
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      spinning
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    />
  )
}
