import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

export const UPDATE_ROW_KEYS = 'UPDATE_ROW_KEYS'

export interface UpdateKeysAction {
  type: typeof UPDATE_ROW_KEYS
  keys: KeyFigure['id'][]
}

export type CashFlowKeysActionTypes = UpdateKeysAction
