import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BudgetDriverForm from './BudgetDriverForm'
import { BudgetDriver } from '../../../../../../types/budgetDriver/BudgetDriver'
import { updateBudgetDriver, addBudgetDriver } from '../../../../../../redux/context/budgetDrivers/actions'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { useBackend } from '../../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { AppDispatch } from '../../../../../../redux/store'

interface BudgetDriverModalProps {
  budgetDriver?: BudgetDriver
  visible: boolean
  handleCancel: () => void
}

const BudgetDriverModal: React.FC<BudgetDriverModalProps> = ({
  visible,
  budgetDriver,
  handleCancel
}: BudgetDriverModalProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [form] = Form.useForm<BudgetDriver>()
  const createBudgetDriverRequest = useBackend('/api/companies/{companyId}/budgeting/drivers/')
  const updateBudgetDriverRequest = useBackend('/api/companies/{companyId}/budgeting/drivers/{budgetDriverId}')

  useEffect(() => {
    form.setFieldsValue({ ...budgetDriver })
  }, [budgetDriver])

  const getRequestData = (values: BudgetDriver) => {
    return {
      urlParams: {
        companyId,
        budgetDriverId: values.id
      },
      body: {
        data: values
      }
    }
  }

  const updateBudgetDriverHandler = async (data: BudgetDriver) => {
    try {
      const res = await updateBudgetDriverRequest.put(getRequestData(data))
      dispatch(updateBudgetDriver(res))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'UPDATE_BUDGET_DRIVER'
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const createBudgetDriverHandler = async (data: BudgetDriver) => {
    try {
      const res = await createBudgetDriverRequest.post(getRequestData(data))
      dispatch(addBudgetDriver(res))
      dispatch(
        notificationAction({
          type: 'success',
          message: 'CREATE_BUDGET_DRIVER'
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      title={t('menu:/settings/company/budgeting/drivers')}
      open={visible}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            values.id ? updateBudgetDriverHandler(values) : createBudgetDriverHandler(values)
            form.resetFields()
            handleCancel()
          })
          .catch(info => {
            console.log('Validate Failed:', info)
          })
      }}
      onCancel={() => {
        handleCancel()
      }}
    >
      <BudgetDriverForm form={form} />
    </Modal>
  )
}

export default BudgetDriverModal
