import React, { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { Button, Empty, Form, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { CompanyDashboardContextType, CompanyDashboardItemContext } from './DashboardItemProvider'
import { NewItemSelect } from './ItemSelect'
import DashboardItemFields from './form/DasboardItemFieds'
import { MenuGroup } from '../types'
import { contextRequest } from '../../../../../redux/context/actions'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { getCashFlowStatementRequest } from '../../../../../redux/context/reports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { loadingSelector } from '../../../../../redux/loading/selectors'

export const NewDasboadItemModal: React.FC = () => {
  const [form] = useForm()
  const dispatch: AppDispatch = useDispatch()
  const { dimensions: dimensionsLoading } = useSelector(loadingSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const dimensionQuery = 'useSelector(dimensionQuerySelector)'
  const { dimensions } = useSelector(filtersSelector)

  const { t } = useTranslation()
  const {
    companyDashboardItem,
    visible,
    step,
    companyDashboardItemPreferenceSelection,
    handleCancel,
    handleSubmit,
    handleReturn
  } = useContext(CompanyDashboardItemContext) as CompanyDashboardContextType

  useEffect(() => {
    if ((companyId && dimensions?.length && !dimensionsLoading) || (companyId && !dimensions?.length)) {
      dispatch(contextRequest(getCashFlowStatementRequest))
    }
  }, [companyId, dimensionQuery])

  const stepComponent = useMemo(() => {
    switch (step) {
      case 'edit':
        if (companyDashboardItemPreferenceSelection?.params?.representation?.formFields) {
          return <companyDashboardItemPreferenceSelection.params.representation.formFields />
        }
        return <Empty />
      case 'select':
        return <NewItemSelect />
      default:
        return <DashboardItemFields />
    }
  }, [step, companyDashboardItemPreferenceSelection])

  useEffect(() => {
    form.setFieldsValue(companyDashboardItem)
    return () => {
      form.resetFields()
    }
  }, [companyDashboardItem])

  const renderModalFooter = () => {
    return (
      <>
        {step !== 'select' && companyDashboardItem && (
          <Button
            style={{ float: 'left' }}
            icon={<ArrowLeftOutlined />}
            type="default"
            onClick={() => {
              handleReturn()
            }}
          >
            {t('global:back')}
          </Button>
        )}
        <Button type="default" onClick={handleCancel}>
          {t('global:cancel')}
        </Button>
        {step === 'edit' ? (
          <Button
            type="primary"
            onClick={async () => {
              try {
                const values = await form.validateFields()
                handleSubmit(values)
              } catch (error) {
                console.log('error', error)
              }
            }}
          >
            {t('global:save')}
          </Button>
        ) : null}
      </>
    )
  }

  const renderModalTitle = () => {
    if (step === 'select') {
      return `${t(companyDashboardItem?.id ? 'dashboardPage:edit-item' : 'dashboardPage:new-item')}`
    }

    if (step === 'edit') {
      return (
        <>
          <span>
            {Number.isInteger(companyDashboardItemPreferenceSelection?.menuGroup) &&
            companyDashboardItemPreferenceSelection !== null
              ? t(`dashboardPage:${MenuGroup[companyDashboardItemPreferenceSelection.menuGroup]}`, '')
              : ''}
          </span>
          <span> - </span>
          <Typography.Text type="secondary">
            {t(`dashboardPage:${companyDashboardItem?.params?.representation?.type}`, '')}
          </Typography.Text>
        </>
      )
    }
    return ''
  }

  return (
    <Modal
      title={renderModalTitle()}
      open={visible}
      destroyOnClose
      footer={renderModalFooter()}
      onCancel={handleCancel}
      maskClosable={false}
      width="unset"
      style={{ maxWidth: 700, top: 32 }}
    >
      <Form form={form} layout="vertical">
        {stepComponent}
      </Form>
    </Modal>
  )
}
