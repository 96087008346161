import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BudgetDriverModal from './components/BudgetDriverModal'
import BudgetDriversTable from './components/BudgetDriversTable'

const BudgetDrivers: React.FC = () => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <p>{t('budgetDrivers:infoText')}</p>
      <Button onClick={() => setVisible(true)} style={{ marginBottom: '8px' }}>{`+ ${t('global:add-new')}`}</Button>
      <BudgetDriverModal budgetDriver={undefined} visible={visible} handleCancel={handleCancel} />
      <BudgetDriversTable />
    </>
  )
}

export default BudgetDrivers
