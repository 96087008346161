import dayjs from 'dayjs'
import { Loan } from '../../../types/loan/Loan'
import { PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { UpdateLoanAction, UPDATE_LOAN } from './types'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { getExistingLoansSummaryRequest } from './existing/actions'
import { getNewLoansSummaryRequest } from './new/actions'

export const updateLoan = (loan: Loan): UpdateLoanAction => ({
  type: UPDATE_LOAN,
  payload: loan
})

export const updateExistingLoanRequest = (
  companyId: string,
  loanId: number,
  loan: Loan,
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  date: string | null
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: loan,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/loans/${loanId}`,
    success: () =>
      getExistingLoansSummaryRequest(companyId, dayjs(date).endOf('month').format('YYYY-MM-DD'), budgetingScenarioId)
  },
  meta: {
    type: 'UPDATE_LOAN',
    notification: true
  }
})

export const updateNewLoanRequest = (
  companyId: string,
  loanId: number,
  loan: Loan,
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  date: string | null
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: loan,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/loans/${loanId}`,
    success: () =>
      getNewLoansSummaryRequest(companyId, dayjs(date).endOf('month').format('YYYY-MM-DD'), budgetingScenarioId)
  },
  meta: {
    type: 'UPDATE_LOAN',
    notification: true
  }
})
