import dayjs from 'dayjs'
import { Company } from '../../../../types/company/Company'
import { Loan } from '../../../../types/loan/Loan'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../../middleware/types'
import { ResetLoansAction, RESET_LOANS, SetLoansSummaryAction, SET_EXISTING_LOANS_SUMMARY } from '../types'
import { BudgetingScenario } from '../../../../types/budgetingScenario/BudgetingScenario'
import { LoanSummaryReport } from '../../../../pages/budgeting/loans/types'

export const setExistingLoansSummary = (summary: LoanSummaryReport): SetLoansSummaryAction => ({
  type: SET_EXISTING_LOANS_SUMMARY,
  payload: summary
})

export const resetExistingLoans = (): ResetLoansAction => ({
  type: RESET_LOANS,
  payload: null
})

export const getExistingLoansSummaryRequest = (
  companyId: Company['id'],
  date: string | null,
  budgetingScenarioId: BudgetingScenario['id'] | undefined
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { date, isExisting: true, budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/loans/summary`,
    success: setExistingLoansSummary
  },
  meta: {
    type: `LOANS`
  }
})

export const createExistingLoanRequest = (
  companyId: Company['id'],
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  loan: Loan,
  date: string | null
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: loan,
    url: `/api/companies/${companyId}/budgeting/loans/existing`,
    params: { budgetingScenarioId },
    success: () =>
      getExistingLoansSummaryRequest(companyId, dayjs(date).endOf('month').format('YYYY-MM-DD'), budgetingScenarioId)
  },
  meta: {
    type: 'CREATE_LOAN',
    notification: true
  }
})

export const partitionLoanRequest = (
  companyId: Company['id'],
  loanId: Loan['id'],
  loans: any,
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  date: string | null
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: { loans },
    url: `/api/companies/${companyId}/budgeting/loans/${loanId}/partition`,
    success: () =>
      getExistingLoansSummaryRequest(companyId, dayjs(date).endOf('month').format('YYYY-MM-DD'), budgetingScenarioId)
  },
  meta: {
    type: 'PARTITION_LOAN',
    notification: true
  }
})

export const deleteLoanRequest = (
  companyId: Company['id'],
  loanId: number,
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  date: string | null
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/loans/${loanId}`,
    success: () =>
      getExistingLoansSummaryRequest(companyId, dayjs(date).endOf('month').format('YYYY-MM-DD'), budgetingScenarioId)
  },
  meta: {
    type: 'DELETE_LOAN',
    notification: true
  }
})
