import { Modal, Typography, Form, Checkbox, List } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Financier } from './FundingApplications'

interface FundingApplicationModalProps {
  modalOpen: boolean
  financiers: Financier[]
  onSubmit: () => void
  onCancel: () => void
}

const FundingApplicationModal: React.FC<FundingApplicationModalProps> = ({
  financiers,
  modalOpen,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()

  return (
    <Modal
      width={600}
      open={modalOpen}
      title={t('fundingApplicationsPage:send-request')}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={t('global:send')}
      cancelText={t('global:cancel')}
      maskClosable={false}
    >
      <Typography.Text>{t('fundingApplicationsPage:request-info')}</Typography.Text>
      <List
        style={{ marginTop: 48 }}
        itemLayout="horizontal"
        dataSource={form.getFieldValue('financiers')?.map((key: string) => financiers.find(f => f.name === key))}
        renderItem={(financier: { name: string; url: string; img: any }) => (
          <List.Item>
            <List.Item.Meta
              className="funding-applications"
              avatar={
                <div>
                  {typeof financier?.img === 'object' ? (
                    <figure style={{ maxHeight: 24, maxWidth: 128 }}>{financier?.img}</figure>
                  ) : (
                    <img style={{ maxHeight: 24, maxWidth: 128 }} alt="example" src={financier?.img} />
                  )}
                </div>
              }
              title={<div style={{ textAlign: 'end' }}>{financier?.name}</div>}
            />
          </List.Item>
        )}
      />
      <Form.Item initialValue={false} name="terms1" rules={[{ required: true, message: '*' }]} valuePropName="checked">
        <Checkbox style={{ float: 'left', marginTop: '35px' }}>{t('fundingApplicationsPage:checkBox1')}</Checkbox>
      </Form.Item>
      <Form.Item initialValue={false} name="terms2" rules={[{ required: true, message: '*' }]} valuePropName="checked">
        <Checkbox style={{ float: 'left', marginTop: '35px' }}>{t('fundingApplicationsPage:checkBox2')}</Checkbox>
      </Form.Item>
    </Modal>
  )
}

export default FundingApplicationModal
