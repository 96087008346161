import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Result, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { BrioxApiCredentials } from '../types'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { useDocsURL } from '../../../../../utils/docs'
import DataUpdate from '../dataUpdate'
import { AppDispatch } from '../../../../../redux/store'

const Briox: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/briox/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setCredentials = useBackend(`/briox/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const dispatch: AppDispatch = useDispatch()

  const [clientId, setClientId] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [authorized, setAuthorized] = useState(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((credentials: BrioxApiCredentials) => {
        setClientId(credentials.clientId)
        setAuthToken(credentials.authToken)
        if (credentials.clientId && credentials.authToken) {
          setAuthorized(true)
        } else {
          setClientId('')
          setAuthToken('')
          setAuthorized(false)
        }
      })
      .catch(() => {
        setClientId('')
        setAuthToken('')
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const submit = () => {
    setCredentials
      .put({
        urlParams: { companyId },
        body: {
          data: {
            clientId,
            authToken
          }
        }
      })
      .then((credentials: BrioxApiCredentials) => {
        setClientId(credentials.clientId)
        setAuthorized(true)
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch(() => {
        setAuthorized(false)
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: 'USERNAME_PASSWORD_MISMATCH'
          })
        )
      })
  }

  const emptyCredentials = () => {
    setClientId('')
    setAuthToken('')
    setAuthorized(false)
  }

  if (authorized) {
    return (
      <>
        <Result
          status="success"
          title={t('integrationsPage:authenticated')}
          extra={
            <>
              <Button onClick={emptyCredentials} type="primary">
                {t('integrationsPage:authenticate')}
              </Button>
              <DataUpdate page="briox" />
            </>
          }
        />
      </>
    )
  }
  return (
    <Form layout="vertical">
      <a href={`${docsURL}manual/businesses/settings/company#briox`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:customer-id')}>
        <Input
          value={clientId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setClientId(event.target.value)}
          suffix={getCredentials.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item label={t('integrationsPage:authenticate-key')}>
        <Input
          value={authToken}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAuthToken(event.target.value)}
          suffix={getCredentials.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button.Group>
          <Button type="primary" onClick={() => submit()} loading={setCredentials.loading}>
            {t('integrationsPage:authorize')}
          </Button>
        </Button.Group>
      </Form.Item>
    </Form>
  )
}

export default Briox
