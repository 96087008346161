import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Modal, Form, Button, Divider, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  addDimension,
  deleteDimensionsRequest,
  updateDimensionName
} from '../../../../../redux/context/dimensions/actions'
import { useBackend } from '../../../../../services/backend'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../../redux/store'

interface DimensionsFormProps {
  cancel: () => void
  selectedDimension?: Dimension
}

const DimensionForm: React.FC<DimensionsFormProps> = ({ cancel, selectedDimension }: DimensionsFormProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [modalVisible, setModalVisibility] = useState(false)
  const [form] = Form.useForm()
  const addDimensionsRequest = useBackend('/api/companies/{companyId}/accounting/dimensions')
  const editDimensionsRequest = useBackend('/api/companies/{companyId}/accounting/dimensions/{dimensionId}')

  const handleOk = () => {
    form.validateFields().then((dimension: Dimension) => {
      addDimensionsRequest
        .post({
          urlParams: { companyId },
          body: {
            data: dimension
          }
        })
        .then((response: Dimension) => {
          companyId && dispatch(addDimension(response))
          dispatch(
            notificationAction({
              type: 'success',
              message: 'OK'
            })
          )
          setModalVisibility(false)
          form.resetFields()
          cancel()
        })
        .catch(() => {
          dispatch(
            notificationAction({
              type: 'error',
              message: 'ERROR'
            })
          )
        })
    })
  }

  const handleEdit = () => {
    form.validateFields().then((dimension: Dimension) => {
      selectedDimension &&
        editDimensionsRequest
          .put({
            urlParams: { companyId, dimensionId: selectedDimension.dimensionId },
            body: {
              data: dimension
            }
          })
          .then((response: Dimension) => {
            companyId && dispatch(updateDimensionName(response))
            dispatch(
              notificationAction({
                type: 'success',
                message: 'OK'
              })
            )
            setModalVisibility(false)
            form.resetFields()
            cancel()
          })
          .catch(() => {
            dispatch(
              notificationAction({
                type: 'error',
                message: 'ERROR'
              })
            )
          })
    })
  }

  const handleCancel = () => {
    setModalVisibility(false)
    form.resetFields()
  }

  const handleDelete = (dimension: Dimension) => {
    companyId && dispatch(deleteDimensionsRequest(companyId, dimension.dimensionId))
  }

  return (
    <>
      {selectedDimension && selectedDimension.source === 'FINADECK' && (
        <>
          <EditOutlined onClick={() => setModalVisibility(true)} />
          <Divider type="vertical" />
          <Popconfirm
            title={t('global:delete-confirm')}
            onConfirm={() => handleDelete(selectedDimension)}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </>
      )}
      {!selectedDimension && (
        <Button onClick={() => setModalVisibility(true)}>{t('dimensionsPage:add-new-dimension')}</Button>
      )}
      <Form layout="vertical" form={form} initialValues={selectedDimension}>
        <Modal
          footer={[
            <Button
              type="primary"
              onClick={selectedDimension ? handleEdit : handleOk}
              loading={addDimensionsRequest.loading || editDimensionsRequest.loading}
            >
              {selectedDimension ? t('global:edit') : t('global:save')}
            </Button>,
            <Button type="default" onClick={handleCancel} style={{ marginLeft: 8 }}>
              {t('global:cancel')}
            </Button>
          ]}
          maskClosable={false}
          title={selectedDimension ? t('dimensionsPage:edit-dimension') : t('dimensionsPage:add-new-dimension')}
          open={modalVisible}
          onCancel={handleCancel}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: t('global:required-field') }]}
            label={t('global:name')}
          >
            <Input placeholder={t('global:name')} />
          </Form.Item>
        </Modal>
      </Form>
    </>
  )
}

export default DimensionForm
