import React from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsCustomEvents from 'highcharts-custom-events'
import { useTranslation } from 'react-i18next'

HighchartsExporting(Highcharts)
HighchartsCustomEvents(Highcharts as any)

interface ValuationChartProps {
  categories?: any
  series?: (number | null)[]
}

const ValueEvaluationChart: React.FC<ValuationChartProps> = ({ categories, series }: ValuationChartProps) => {
  const { t } = useTranslation()
  const seriesData: any = [{ name: t('valuationPage:value'), type: 'column', data: series as (number | null)[] }]

  const options = {
    title: {
      text: ''
    },
    xAxis: {
      categories
    },
    yAxis: {
      title: {
        text: null
      }
    },
    plotOptions: {
      series: {
        animation: false
      }
    },
    series: seriesData
  }

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default ValueEvaluationChart
