import React, { useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsCustomEvents from 'highcharts-custom-events'
import { SeriesOptionsType } from 'highcharts'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { getPlotOptions } from '../../../components/Chart/plotOptions'

HighchartsExporting(Highcharts)
HighchartsCustomEvents(Highcharts as any)

interface CompetitorChartProps {
  categories: any
  series: SeriesOptionsType[]
}

const CompetitorChart: React.FC<CompetitorChartProps> = ({ categories, series }: CompetitorChartProps) => {
  const { t } = useTranslation()
  const [graphType, setGraphType] = useState<'column' | 'line' | 'spline'>('column')
  const [specialColumnType, setSpecialColumnType] = useState<'stackedColumn' | 'groupedColumn' | undefined>(undefined)

  const graphSeries: any[] = []
  for (const serie of series) {
    graphSeries.push({ ...serie, type: graphType })
  }

  const plotOptions = getPlotOptions(graphType, specialColumnType)

  const options = {
    chart: { animation: false },
    title: {
      text: ''
    },
    xAxis: {
      categories
    },
    yAxis: {
      title: {
        text: null
      }
    },
    plotOptions,
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<b>{point.key}</b><table>',
      pointFormat: `
        <tr><td><span style="color:{point.color}">◉</span> {series.name}: </td>
        <td style="text-align: right"><b>{point.y}</b></td></tr>
      `,
      footerFormat: '</table>'
    },
    series: graphSeries
  }

  const selectGraphType = (e: any) => {
    if (e === 'stackedColumn' || e === 'groupedColumn') {
      setGraphType('column')
      setSpecialColumnType(e)
    } else {
      setGraphType(e)
      setSpecialColumnType(undefined)
    }
  }

  return (
    <div className="chart-container">
      {/* TODO: oma komponentti */}
      <div className="tool-bar" style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
        <Select
          style={{ width: 380, marginBottom: 5 }}
          defaultValue={graphType}
          onChange={(e: any) => selectGraphType(e)}
        >
          {['column', 'line', 'spline', 'stackedColumn', 'groupedColumn'].map(type => (
            <Select.Option key={type} value={type}>
              {t(`global:${type}`)}
            </Select.Option>
          ))}
        </Select>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default CompetitorChart
