import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddSubsidiaryAction,
  ADD_SUBSIDIARY,
  DeleteSubsidiaryAction,
  DELETE_SUBSIDIARY,
  SetSubsidiariesAction,
  SET_SUBSIDIARIES,
  UpdateSubsidiaryAction,
  UPDATE_SUBSIDIARY
} from './types'

export const addSubsidiaries = (subsidiaries: Company[]): AddSubsidiaryAction => ({
  type: ADD_SUBSIDIARY,
  payload: subsidiaries
})

export const setSubsidiaries = (subsidiaries: Company[]): SetSubsidiariesAction => ({
  type: SET_SUBSIDIARIES,
  payload: subsidiaries
})

export const updateSubsidiary = (subsidiary: Company): UpdateSubsidiaryAction => ({
  type: UPDATE_SUBSIDIARY,
  payload: subsidiary
})

export const deleteSubsidiary = (subsidiary: Company): DeleteSubsidiaryAction => ({
  type: DELETE_SUBSIDIARY,
  payload: subsidiary
})

export const addSubsidiaryRequest = (
  companyId: Company['id'],
  subsidiaries: Partial<Company>[]
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `api/companies/${companyId}/group/subsidiaries`,
    data: subsidiaries,
    success: (response: Company[]) => addSubsidiaries(response)
  },
  meta: {
    type: 'CREATE_SUBSIDIARY',
    notification: true
  }
})

export const getSubsidiariesRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `api/companies/${companyId}/group`,
    success: (response: any) => setSubsidiaries(response.subsidiaries || [])
  },
  meta: {
    type: 'GET_SUBSIDIARIES'
  }
})

export const updateSubsidiaryAcquisitionsRequest = (
  companyId: Company['id'],
  subsidiary: Company
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `api/companies/${companyId}/group/subsidiaries/${subsidiary.id}/acquisitions`,
    data: subsidiary,
    success: updateSubsidiary
  },
  meta: {
    type: 'UPDATE_SUBSIDIARY_ACQUISITIONS'
  }
})

export const deleteSubsidiariesRequest = (
  companyId: Company['id'],
  subsidiaryId: Company['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `api/companies/${companyId}/group/subsidiaries/${subsidiaryId}`,
    success: (response: Company[]) => setSubsidiaries(response)
  },
  meta: {
    type: 'DELETE_SUBSIDIARY',
    notification: true
  }
})

export const updateSubsidiarieOrdersRequest = (
  companyId: Company['id'],
  subsidiarieOrders: any[],
  subsidiariesDataSource: any
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: subsidiarieOrders,
    url: `/api/companies/${companyId}/group/subsidiaries/order`,
    success: () => setSubsidiaries(subsidiariesDataSource)
  },
  meta: {
    type: 'UPDATE_FORMULA_ORDERS'
  }
})
