import React from 'react'
import { FileTextOutlined, FileExcelOutlined } from '@ant-design/icons'
import { utils, writeFile } from 'xlsx'

export const downloadMenuItems = [
  { label: 'CSV', key: 'csv', icon: <FileTextOutlined /> },
  { label: 'Excel', key: 'xlsx', icon: <FileExcelOutlined /> }
]

/**
 * Removes illegal characters from sheet names
 * @param input string
 * @returns string
 */
export const sanitizeSheetName = (input: string): string => {
  const illegalRe = /[/?\\:*\][]/g
  const replacement = ''
  return input.replace(illegalRe, replacement)
}

export const cloneAndSanitizeTable = (tableHTMLEl: HTMLDivElement) => {
  const cloned = tableHTMLEl?.cloneNode(true) as HTMLTableElement
  cloned?.querySelector('.ant-table-footer')?.remove()
  cloned?.querySelector('.ant-table-measure-row')?.remove()
  return cloned
}

// Some reason antd tables are made od two different tables,
// tables so here we are combining them to one sheet
function convert(container: HTMLDivElement) {
  const tables = cloneAndSanitizeTable(container).getElementsByTagName('table')
  const data = [...tables].reduce((acc, current) => {
    const sheet = utils.table_to_sheet(current, { raw: false })
    const json = utils.sheet_to_json(sheet, {
      header: 1,
      raw: true,
      defval: ''
    }) as any
    return acc.concat(json)
  }, [])
  return utils.json_to_sheet(data, { skipHeader: true })
}

// htmlElement on div
export const tableDownload = (htmlElement: any, name: string, fileFormat = 'xlsx') => {
  try {
    const tableHTMLEl = htmlElement?.current?.getElementsByClassName('report-table')[0]

    if (!tableHTMLEl) return

    const ws = convert(tableHTMLEl)
    // Set name row width
    ws['!cols'] = [{ wch: 42 }]
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, ws, 'Sheet1')
    const fileName = name ? `${name}.${fileFormat}` : `report.${fileFormat}`

    writeFile(workbook, fileName)
  } catch (error) {
    console.log(error)
  }
}

// htmlElement on div
export const multipleTableDownload = (htmlElement: any, name: string, fileFormat = 'xlsx') => {
  try {
    const tableContainers = htmlElement?.current?.getElementsByClassName('report-table-container')
    if (!tableContainers) return

    // Extract Data (create a workbook object from the table)
    const workbook = utils.book_new()

    for (const [index, item] of [...tableContainers].entries()) {
      const tableContainer = item?.getElementsByClassName('ant-table')
      const ws = convert(tableContainer[0])
      const title = item?.dataset?.title ?? 'report'
      const sheetName = `${index + 1}. ${sanitizeSheetName(title)}`.slice(0, 30)
      // Set name row width
      ws['!cols'] = [{ wch: 42 }]
      utils.book_append_sheet(workbook, ws, sheetName)
    }

    const fileName = name ? `${name}.${fileFormat}` : `report.${fileFormat}`

    writeFile(workbook, fileName)
  } catch (error) {
    console.log('error', error)
  }
}

export const arrayDownload = (aoa: any[][], name: string, fileFormat = 'xlsx') => {
  try {
    const ws = utils.aoa_to_sheet(aoa)
    // Set name row width
    ws['!cols'] = [{ wch: 42 }]
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, ws, 'Sheet1')
    const fileName = name ? `${name}.${fileFormat}` : `report.${fileFormat}`

    writeFile(workbook, fileName)
  } catch (error) {
    console.log(error)
  }
}
