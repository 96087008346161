import React, { useContext } from 'react'
import _ from 'lodash'
import { Result, Descriptions, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { User } from '../../../types/user/User'
import { getHumanReadableArrayText } from '../../../utils/helpers'
import { sessionContractUserSelector } from '../../../redux/session/contract/selectors'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'

interface ResultStepProps {
  closeModal: () => void
}

const CreateResultStep: React.FC<ResultStepProps> = ({ closeModal }) => {
  const users = useSelector(sessionContractUserSelector)
  const { t } = useTranslation()
  const { form, resetForm, company, error } = useContext(ContractFormContext) as ContractFormContextType

  const masterUserName = `${form.getFieldValue(['masterUser', 'firstName'])} ${form.getFieldValue([
    'masterUser',
    'lastName'
  ])}`

  const information = !error && company && (
    <div>
      <Descriptions title={t('contractsPage:company')} column={1}>
        <Descriptions.Item label={t('contractsPage:name')}>{company.name}</Descriptions.Item>
        <Descriptions.Item label={t('contractsPage:product')}>
          {t(`contractProduct:${form.getFieldValue(['contract', 'product'])}`)}
        </Descriptions.Item>
        {form.getFieldValue(['masterUser', 'firstName']) && (
          <Descriptions.Item label={t('contractsPage:company-admin')}>{masterUserName}</Descriptions.Item>
        )}
      </Descriptions>
      <Descriptions title={t('contractsPage:accounting-office')} column={1}>
        <Descriptions.Item label={t('contractsPage:contact-person')}>
          {getHumanReadableArrayText(
            (form.getFieldValue('users') || []).map((id: User['id']) => _.keyBy(users, 'id')[id].displayName)
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )

  const extra = (
    <>
      <Button onClick={resetForm} type="primary">
        {t('contractsPage:add-new')}
      </Button>
      <Button onClick={closeModal}>{t('contractsPage:close')}</Button>
    </>
  )

  return (
    <Result
      status={!error ? 'success' : 'error'}
      title={!error ? t('contractsPage:customer-created-successfully') : t('contractsPage:something-went-wrong')}
      extra={extra}
    >
      {information}
    </Result>
  )
}

export default CreateResultStep
