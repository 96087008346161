/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Card, Divider, Form, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Prompt, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { ExpandableConfig } from 'antd/lib/table/interface'
import _ from 'lodash'
import { useForm } from 'antd/es/form/Form'
import { MenuItemType } from 'antd/es/menu/interface'
import { ReportTableRow } from '../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { getFinancialStatementRequest, resetReports } from '../../../redux/context/reports/actions'
import {
  budgetableAssetsSelector,
  budgetableIncomeStatementSelector,
  budgetableLiabilitiesSelector,
  financialStatementLoadingSelector,
  financialStatementErrorSelector
} from '../../../redux/context/reports/selectors'
import { ReportRowType, ReportDataType, Report } from '../../../redux/context/reports/types'
import ReallocationMenu from './components/reallocation/ReallocationMenu'
import Amendment from './components/amendment/Amendment'
import AmendmentMenu from './components/amendment/AmendmentMenu'
import Investment from './components/investment/InvestmentNameCellRender'
import InvestmentMenu from './components/investment/InvestmentMenu'
import KeyPerformanceIndicators from './keyPerformanceIndicators/KeyPerformanceIndicators'
import { contextRequest } from '../../../redux/context/actions'
import { resetFinancialStatementsPage } from '../../../redux/pages/budgeting/financialStatements/actions'
import { financialStatementsPageSelector } from '../../../redux/pages/budgeting/financialStatements/selectors'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import { dimensionQuerySelector, filtersSelector } from '../../../redux/context/filters/selectors'
import ReportTable from '../../../components/Table/ReportTable'
import { Column } from '../../../redux/context/periodGroups/hooks'
import { RenderBudgetCell } from './components/budget/BudgetCell'
import IncomeStatementCellRenderer from './components/IncomeStatementCellRenderer'
import { recursiveFind } from '../../../utils/helpers'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { getCompanySettingsRequest } from '../../../redux/context/companySettings/actions'
import BudgetCommentPopover from './components/comment/BudgetCommentPopover'
import { getCompanyUsersRequest } from '../../../redux/context/companyUsers/actions'
import { isBudgetDriverMethod } from '../../../components/Budgeting/BudgetingMethodBudgetDriver'
import BudgetMenu from './components/budget/BudgetMenu'
import { getAccountMapRulesRequest } from '../../../redux/context/accountMapRules/actions'
import { useBackend } from '../../../services/backend'
import { isBudgetableForecastCell } from './utils'
import DimensionsMenu from './components/dimensions/DimensionsMenu'
import { emptySelectedRowDimensionKeysAndData } from '../../../redux/context/incomeStatenentDimensions/actions'
import { dimensionTreeSelector } from '../../../redux/context/dimensions/selectors'
import { ActualRowType } from '../../../components/Table/hooks'
import { getRowDimensionDataReq } from './service'
import { AppDispatch } from '../../../redux/store'
import BudgetingProvider from './components/budget/BudgetingProvider'
import BudgetingMenuButtons from './components/budget/BudgetingMenuButtons'
import { BudgetingLevel } from '../../../types/companySettings/CompanySettings'
import { companySettingsSelector } from '../../../redux/context/companySettings/selectors'
import { loadingSelector } from '../../../redux/loading/selectors'
import { appLanguageSelector } from '../../../redux/app/selectors'

const FinancialStatements: React.FC = () => {
  const { t } = useTranslation()
  const [form] = useForm()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)
  const loading = useSelector(financialStatementLoadingSelector)
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const { budgetsInEdit } = useSelector(financialStatementsPageSelector)
  const financialStatementError = useSelector(financialStatementErrorSelector)
  const { periodGroup } = useSelector(filtersSelector)
  const incomeStatement = useSelector(budgetableIncomeStatementSelector)
  const assets = useSelector(budgetableAssetsSelector)
  const liabilities = useSelector(budgetableLiabilitiesSelector)
  const { budgeting: { level: budgetingLevel } = {} } = useSelector(companySettingsSelector)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const dimensionTree = useSelector(dimensionTreeSelector)
  const { pathname } = useLocation()
  const [dimensionDataLoading, setDimensionDataLoading] = useState(false)
  const rowDimensionDataRequest = useBackend('/api/companies/{companyId}/reporting/financial-statement')
  const [currentTab, setCurrentTab] = useState<string>('incomeStatement')
  const [editingBudget, setEditingBudget] = useState<boolean>(false)
  const { dimensions: dimensionsLoading } = useSelector(loadingSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const language = useSelector(appLanguageSelector)

  const getReport = (key: string): Report | undefined => {
    switch (key) {
      case 'incomeStatement':
        return incomeStatement
      case 'assets':
        return assets
      case 'liabilities':
        return liabilities
      default:
        return []
    }
  }

  useEffect(() => {
    setSelectedRows(selectedRows.filter(s => !s.includes('dimension')) || [])
    dispatch(emptySelectedRowDimensionKeysAndData())
    // TODO: Tehtävä käyttäjän datojen ja yrityste datojen haku ennen raporttien hakua. jotta if hässäkän saa pois
    if ((companyId && dimensions?.length && !dimensionsLoading) || (companyId && !dimensions?.length)) {
      dispatch(getAccountMapRulesRequest(companyId))
      dispatch(contextRequest(getFinancialStatementRequest))
      dispatch(resetFinancialStatementsPage())
      dispatch(getCompanyUsersRequest(companyId))
      dispatch(contextRequest(getCompanySettingsRequest))
    }
    return () => {
      dispatch(resetReports())
    }
  }, [companyId, budgetingScenario, dimensionQuery, periodGroups, language])

  useEffect(() => {
    dispatch(resetFinancialStatementsPage())
  }, [periodGroup, dispatch])

  const tabList = [
    {
      key: 'incomeStatement',
      label: t('financialStatementsPage:incomeStatement')
    },
    {
      key: 'assets',
      label: t('financialStatementsPage:assets')
    },
    {
      key: 'liabilities',
      label: t('financialStatementsPage:liabilities')
    }
  ]

  const onTabChange = (key: string) => {
    setSelectedRows([])
    setCurrentTab(key)
  }

  const renderCell = useMemo(
    () => (value: any, reportTableRow: ReportTableRow, column: Column) => {
      // eslint-disable-next-line react/destructuring-assignment
      switch (reportTableRow.rowType) {
        case ReportRowType.budgetMenu:
          if (
            (column.dataType === ReportDataType.budget && !column.cumulative) ||
            isBudgetDriverMethod(reportTableRow) ||
            (column.dataType === ReportDataType.forecast &&
              !column.cumulative &&
              budgetingScenario?.forecastCursor &&
              isBudgetableForecastCell(column, dayjs(budgetingScenario.forecastCursor)))
          ) {
            return <RenderBudgetCell budgedMenuRow={reportTableRow} column={column} value={value} />
          }
          return undefined
        default:
          if (value === undefined) return undefined
          return <IncomeStatementCellRenderer value={value} column={column} reportTableRow={reportTableRow} />
      }
    },
    [budgetingScenario]
  )

  const getRowDimensionData = async (dimensionsArray: string[], row: ReportTableRow) => {
    setDimensionDataLoading(true)
    if (companyId) {
      await getRowDimensionDataReq(
        dimensionsArray,
        row,
        companyId,
        rowDimensionDataRequest,
        dispatch,
        budgetingScenario,
        periodGroups
      )
    }
    setDimensionDataLoading(false)
  }

  const renderTitle = (reportTableRow: ReportTableRow, props?: any): ReactNode => {
    const { rowType, title } = reportTableRow
    switch (rowType) {
      case ReportRowType.budgetMenu:
        return <BudgetMenu reportTableRow={reportTableRow} />
      case ReportRowType.investmentMenu:
        return <InvestmentMenu reportTableRow={reportTableRow} />
      case ReportRowType.investment:
      case ReportRowType.investmentFunding:
        return <Investment reportTableRow={reportTableRow} />
      case ReportRowType.investmentFundingMenu:
        return t('financialStatementsPage:sources-of-funding')
      case ReportRowType.amendmentMenu:
        return <AmendmentMenu reportTableRow={reportTableRow} />
      case ReportRowType.dimensionsButton:
        return <DimensionsMenu getDimensionData={getRowDimensionData} reportTableRow={reportTableRow} />
      case ReportRowType.amendmentCounterPartMenu:
        return t('financialStatementsPage:amendment-deduction-counter-part')
      case ReportRowType.amendment:
        return <Amendment reportTableRow={reportTableRow} />
      case ReportRowType.amendmentCounterPart:
        return <Amendment reportTableRow={reportTableRow} />
      case ReportRowType.reallocationMenu:
        return <ReallocationMenu reportTableRow={reportTableRow} {...props} />
      case ReportRowType.budgetableRow:
        return editingBudget ? <BudgetMenu reportTableRow={reportTableRow} /> : title
      default:
        return title
    }
  }

  const rowClassName = (reportTableRow: ReportTableRow) => {
    const splitted = reportTableRow.className?.split(' ') || []
    const classes = classNames(
      {
        bold: reportTableRow.isParent,
        'budgetable-row':
          reportTableRow.rowType &&
          [
            ReportRowType.budgetMenu,
            ReportRowType.investmentMenu,
            ReportRowType.amendmentMenu,
            ReportRowType.investmentFundingMenu,
            ReportRowType.amendmentCounterPartMenu
          ].includes(reportTableRow.rowType),
        'dimension-row': reportTableRow.actualRowType === ActualRowType.dimension,
        'budget-menu-row': reportTableRow.rowType === ReportRowType.budgetMenu
      },
      ...splitted
    )

    return classes
  }

  const contextMenuItems = (row: ReportTableRow, column: Column): MenuItemType[] => {
    const items: {
      display: (row: ReportTableRow, column: Column) => boolean
      item: MenuItemType
    }[] = [
      {
        display: r => {
          const budgetableDatatypes = [ReportDataType.budget, ReportDataType.forecast]
          if ((dimensions?.length || 0) > 1) return false
          if (!r.budgetingMethod?.method) return false
          if (!budgetableDatatypes.includes(column.dataType as any)) return false
          if (!(r.incomeStatementRowId || r.accountCode)) return false
          if (r.rowType === ReportRowType.budgetMenu) return false
          if (periodGroups && !periodGroup) return false
          if (budgetingLevel === BudgetingLevel.account && r.incomeStatementRowId) return false
          if (budgetingLevel === BudgetingLevel.statementRow && r.accountCode) return false
          return true
        },
        item: {
          label: (
            <BudgetCommentPopover row={row} column={column} icon={false}>
              {t('global:make-comment')}
            </BudgetCommentPopover>
          ),
          key: 'comment'
        }
      }
    ]

    return items.filter(item => item.display(row, column)).map(({ item }) => item)
  }

  const getDimensions = async (row: ReportTableRow) => {
    const dim = recursiveFind(dimensionTree, row.dimensionId, 'dimensionId') // find((d) => d.dimensionId === row.dimensionId);

    const dimensionIds = dim?.children?.map((c: any) => c.dimensionId) ?? []
    if (dimensionIds) {
      getRowDimensionData(dimensionIds, row)
    }
  }

  const expandable: ExpandableConfig<ReportTableRow> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      return !record.children ||
        (record.children &&
          record.children.length < 1 &&
          record.actualRowType !== ActualRowType.dimension) ? undefined : expanded ? (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      ) : (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      )
    },
    onExpand: (exp, record) => {
      setSelectedRows(_.xor([record.key], selectedRows))
      if (record.actualRowType === ActualRowType.dimension) {
        exp && getDimensions(record)
      }
    },
    rowExpandable: () => true,
    expandedRowKeys: selectedRows.map(String)
  }

  return (
    <PageHeaderWrapper
      title={t('menu:/budgeting/financial-statements')}
      subTitle={
        <Tooltip placement="right" title="info">
          <DocumentationLink route={{ path: '/budgeting' }} />
        </Tooltip>
      }
      tabList={tabList}
      ghost={false}
      tabActiveKey={currentTab}
      onTabChange={onTabChange}
    >
      <Card>
        {!financialStatementError && (
          <>
            <Typography.Title level={3}>
              {t('menu:/settings/company/key-figures/key-performance-indicators')}
            </Typography.Title>
            <KeyPerformanceIndicators />
            <Divider />
          </>
        )}
        <Prompt
          when={Object.values(budgetsInEdit).some(b => b)}
          message={t('financialStatementsPage:budget-confirm-message')}
        />
        <LoadingWrapper loading={loading || dimensionDataLoading || false}>
          <Typography.Title level={3}>{t(`financialStatementsPage:${currentTab}`)}</Typography.Title>
          <Form form={form}>
            <BudgetingProvider>
              <ReportTable
                toggleAllRows={keys => (selectedRows.length ? setSelectedRows([]) : setSelectedRows(keys))}
                contextMenuItems={contextMenuItems}
                toolbar={{
                  enablePercents: true,
                  csvExport: true,
                  leftContent:
                    pathname.startsWith('/budgeting') && currentTab === 'incomeStatement' ? (
                      <BudgetingMenuButtons editingBudget={editingBudget} setEditingBudget={setEditingBudget} />
                    ) : null
                }}
                enableCumulatives={currentTab === 'incomeStatement'}
                report={getReport(currentTab) || []}
                renderCell={renderCell}
                renderTitle={renderTitle}
                rowClassName={rowClassName}
                error={financialStatementError}
                yScroll={600}
                nameColumnWidth={400}
                columnWidth={100}
                rowKey="key"
                expandable={expandable}
              />
            </BudgetingProvider>
          </Form>
        </LoadingWrapper>
      </Card>
    </PageHeaderWrapper>
  )
}

export default FinancialStatements
