import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input, Select, Modal, DatePicker, Form, Radio, Space, Alert, Table, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import {
  BudgetingScenario,
  BudgetingScenarioTransfer,
  ForecastType
} from '../../../../../../types/budgetingScenario/BudgetingScenario'
import { budgetingScenariosAndForecastsSelector } from '../../../../../../redux/context/budgetingScenarios/selectors'
import ScenarioMapTable from './ScenarioMapTable'
import { budgetGroupSettingsSourceSelector } from '../../../../../../redux/context/groupSettings/selectors'
import { GroupSettingDataSource } from '../../../group/types'
import { Company } from '../../../../../../types/company/Company'
import { availableEntitiesSelector } from '../../../../../../redux/context/contract/selectors'
import { getLatestTransfers } from '../utils'

interface BudgetingScenarioFormProps {
  modalVisible: boolean
  handleOk: (e: BudgetingScenarioTransfer[]) => void
  handleCancel: (e: React.MouseEvent<HTMLElement>) => void
  form: FormInstance
  page: 'budgetingScenarios' | 'forecasts'
  selectedGroup?: Company
  loading?: boolean
}

const BudgetingScenarioForm = ({
  modalVisible,
  handleOk,
  handleCancel,
  form,
  page,
  selectedGroup,
  loading
}: BudgetingScenarioFormProps) => {
  const { t } = useTranslation()
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)
  const budgetingScenarioName = form.getFieldValue('name')
  const budgetingScenarioId = useWatch('id', form) as number
  const budgetingScenarioForecastType = useWatch('forecastType', form)
  const [selectedTableRows, setSelectedTableRows] = useState<React.Key[]>([])
  const availableEntities = useSelector(availableEntitiesSelector)
  const templates: BudgetingScenario[] = [
    {
      id: 0,
      name: t('global:default')
    }
  ]

  useEffect(() => {
    setSelectedTableRows([])
  }, [modalVisible])

  useEffect(() => {
    form.setFieldsValue({
      transfers: getLatestTransfers(budgetingScenariosAndForecasts, availableEntities, budgetingScenarioId)
    })
  }, [budgetingScenarioId])

  const modalTitle = () => {
    if (budgetingScenarioId) {
      return `${t('global:edit')} ${budgetingScenarioName}`
    }
    if (page === 'budgetingScenarios') {
      return `${t('global:add-new')} ${t('global:scenario')}`
    }
    return `${t('global:add-new')} ${t('global:forecast')}`
  }

  const columns = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      render: (text: string) => {
        return t(`budgetingScenarioEntity:${text}`, text)
      }
    },
    {
      title: t('global:scenarioSlashForecast'),
      dataIndex: 'key',
      width: '50%',
      render: (val: string, record: { key?: string }, index: number) => {
        return (
          <>
            <Form.List name="transfers">
              {() => (
                <>
                  <Form.Item hidden name={[index, 'entity']} initialValue={val} />
                  <Form.Item
                    noStyle
                    name={[index, 'sourceId']}
                    rules={[
                      {
                        required: !!selectedTableRows?.find(s => s === record.key),
                        message: t('global:required-field')
                      }
                    ]}
                  >
                    <Select
                      disabled={!selectedTableRows?.find(s => s === record.key)}
                      style={{ width: '100%' }}
                      showSearch
                      optionFilterProp="children"
                    >
                      {[...templates, ...budgetingScenariosAndForecasts]
                        .filter(f => f.id !== budgetingScenarioId)
                        .map((scenario: BudgetingScenario) => {
                          return (
                            <Select.Option key={scenario.id} value={scenario.id}>
                              {scenario.name}
                            </Select.Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )
      }
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedTableRows(selectedRowKeys)
    },
    getCheckboxProps: (record: { name: string }) => ({
      name: record.name
    }),
    selectedRowKeys: selectedTableRows
  }

  const handleOkPrep = () => {
    form.validateFields().then(() => {
      const selectedTransferRows = form.getFieldValue('transfers')
      const filteredArray = selectedTransferRows.filter((mainItem: BudgetingScenarioTransfer) =>
        selectedTableRows.some(filterItem => filterItem === mainItem.entity)
      )
      handleOk(filteredArray)
    })
  }

  return (
    <Modal
      width={800}
      title={modalTitle()}
      maskClosable={false}
      open={modalVisible}
      onOk={handleOkPrep}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('global:cancel')}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOkPrep}>
          {form.getFieldValue('id') ? t('global:update') : t('global:save')}
        </Button>
      ]}
    >
      <Form onFinish={handleOkPrep} layout="vertical" form={form}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:name')}
        >
          <Input placeholder={t('global:name')} />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:description')}
        >
          <Input placeholder={t('global:description')} />
        </Form.Item>

        <Alert message={t('budgetingScenarioPage:entitiesWarnig')} type="warning" showIcon />
        <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            columns={columns}
            pagination={false}
            dataSource={availableEntities.map(a => ({ name: a, key: a }))}
          />
        </Space>

        {page === 'forecasts' && (
          <>
            <Form.Item
              initialValue={budgetingScenarioForecastType === 'MANUAL' ? 'manual' : 'rolling'}
              name="selectedForecastType"
              label={t('budgetingScenarioPage:selectForecastType')}
            >
              <Select>
                <Select.Option key="rolling" value="rolling">
                  {t('budgetingScenarioPage:rolling')}
                </Select.Option>
                <Select.Option key="manual" value="manual">
                  {t('budgetingScenarioPage:manual')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues?.selectedForecastType !== currentValues?.selectedForecastType
              }}
            >
              {({ getFieldValue }) =>
                getFieldValue(['selectedForecastType']) === 'manual' ? (
                  <Form.Item
                    rules={[{ required: true, message: t('global:required-field') }]}
                    name="forecastCursor"
                    label={t('budgetingScenarioPage:select-cursor-filter')}
                  >
                    <DatePicker.MonthPicker format="YYYY-MM" placeholder={t('global:select-month')} />
                  </Form.Item>
                ) : (
                  <Form.Item initialValue={ForecastType.CURRENT} name="forecastType">
                    <Radio.Group>
                      <Radio key={ForecastType.CURRENT} value={ForecastType.CURRENT}>
                        {t('budgetingScenarioPage:CURRENT')}
                      </Radio>
                      <Radio key={ForecastType.PREVIOUS} value={ForecastType.PREVIOUS}>
                        {t('budgetingScenarioPage:PREVIOUS')}
                      </Radio>
                      {groupBudgetSourceSetting !== GroupSettingDataSource.subsidiaries && (
                        <Radio key={ForecastType.PERIOD_LOCK} value={ForecastType.PERIOD_LOCK}>
                          {t('budgetingScenarioPage:PERIOD_LOCK')}
                        </Radio>
                      )}
                    </Radio.Group>
                  </Form.Item>
                )
              }
            </Form.Item>
          </>
        )}
        {groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && (
          <ScenarioMapTable selectedGroup={selectedGroup} />
        )}
      </Form>
    </Modal>
  )
}

export default BudgetingScenarioForm
