import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Modal, Radio, Select, theme } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { NamePath } from 'antd/es/form/interface'
import { FormProviderProps } from 'antd/es/form/context'
import { ProCard } from '@ant-design/pro-components'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  addCustomReportSectionRequest,
  updateCustomReportSectionRequest
} from '../../../../../redux/context/customReports/actions'
import { CustomReport } from '../../../../../redux/context/customReports/types'
import { CategoryContext, CategoryContextType } from '../categorySelection/CategoryContext'
import {
  customReportDateSelector,
  customReportQuickFiltersSelector
} from '../../../../../redux/context/customReports/selectors'
import CategorySelection from '../categorySelection/CategorySelection'
import ChartTypeSelect, { GraphType } from '../chart/ChartTypeSelect'
import CustomReportSectionCopyForm from './CustomReportSectionCopyForm'
import { getVariable } from '../utils'
import { useBackend } from '../../../../../services/backend'
import { AppDispatch } from '../../../../../redux/store'
import Editor from '../editor/Editor'
import { testSectionChange } from './utils'
import VariableTable from '../variable/VariableTable'
import {
  CustomReportSectionsType,
  CustomReportTableSection,
  CustomReportChartSection,
  CustomReportDataSection,
  CustomReportTextSection,
  CustomReportPieSection
} from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface SectionModalProps {
  visible: boolean
  setVisible: (value: boolean) => void
  reportId?: CustomReport['id']
  section: CustomReportSectionsType | null
}

const SectionModal: React.FC<SectionModalProps> = React.memo(({ reportId, section, visible, setVisible }) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)
  const { categories, setCategories, reset, isCategoriesValid, setSectionForm } = useContext(
    CategoryContext
  ) as CategoryContextType
  const {
    token: { boxShadowTertiary }
  } = theme.useToken()
  const [step, setStep] = useState(section ? 1 : 0)
  const date = useSelector(customReportDateSelector)
  const [variableNamePath, setVariableNamePath] = useState<NamePath>()
  const [variableDelete, setVariableDelete] = useState<CustomReportVariable[]>([])
  const [variableModalOpen, setVariableModalOpen] = useState(false)
  const quickFilters = useSelector(customReportQuickFiltersSelector)
  const deleteVariableRequest = useBackend(
    `/api/companies/{companyId}/reporting/custom/reports/{reportId}/sections/{sectionId}/variables/{id}`
  )
  const sectionType = useWatch('type', form)

  useEffect(() => {
    setSectionForm(form)
  }, [])

  const transforToForm = (sec: Partial<CustomReportSectionsType>) => ({
    ...sec,
    ...(!(section as CustomReportTableSection | CustomReportChartSection | CustomReportPieSection)?.params && {
      params: { reallocate: true }
    }),
    variables: (sec as CustomReportTableSection | CustomReportChartSection)?.variables?.map((v, i) => ({
      ...v,
      key: `${i}`,
      params: {
        ...v.params,
        dataType: [v.params?.dataType, v.params?.budgetingScenario?.id].filter(Boolean)
      }
    }))
  })

  useEffect(() => {
    if (section && visible) {
      section ? setStep(1) : setStep(0)
      form.setFieldsValue(transforToForm(section))
    }
  }, [section])

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
    reset()
    setStep(0)
    setVariableDelete([])
  }

  function mapCategoryIds(this: string, { children, ...o }: CustomReportCategory, index: number): CustomReportCategory {
    const path = this ? `${this}-${index}` : `${index}`
    return {
      ...o,
      id: path,
      children: children?.map(mapCategoryIds, path)
    }
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const { title, type, style, variables, params } = values
      const text = values.text && JSON.parse(values.text)

      const categoriesWithids = categories.map(mapCategoryIds)
      const newSection: Partial<CustomReportSectionsType> = {
        title,
        type,
        style,
        params,
        text,
        categories: categoriesWithids,
        variables: variables?.map(getVariable) ?? []
      }

      const updateStyleOnly =
        (!text && section && testSectionChange(newSection, section as CustomReportDataSection)) || false

      // Handle variable deletes before everything
      await Promise.all(
        variableDelete.map(variable => {
          return deleteVariableRequest.delete({
            urlParams: { companyId, reportId, sectionId: section!.id, id: variable.id }
          })
        })
      )

      if (section && reportId) {
        companyId &&
          dispatch(
            updateCustomReportSectionRequest(
              companyId,
              reportId,
              section.id,
              newSection as CustomReportSectionsType,
              updateStyleOnly,
              date,
              quickFilters
            )
          )
      } else {
        companyId &&
          reportId &&
          dispatch(
            addCustomReportSectionRequest(
              companyId,
              reportId,
              newSection as CustomReportSectionsType,
              date,
              quickFilters
            )
          )
      }
      handleCancel()
    } catch (error) {
      console.log(`Error`, error)
    }
  }

  const handleStepChange = () => {
    setStep(1)
  }

  const resetForm = () => {
    form.resetFields()
    setCategories([])
  }

  const handleSectionCopy = (values: Partial<CustomReportSectionsType>) => {
    setCategories((values as CustomReportTableSection | CustomReportChartSection)?.categories ?? [])
    form.setFieldsValue(transforToForm(values))
  }

  const handleFormFinish: FormProviderProps['onFormFinish'] = (name, { values, forms }) => {
    if (name === 'customReportVariableForm') {
      const { sectionForm } = forms
      const variables = sectionForm.getFieldValue('variables') || []
      if (variableNamePath) {
        sectionForm.setFieldValue(variableNamePath as NamePath, {
          ...variables.find((v: CustomReportVariable) => v.id === values.id),
          ...values
        })
      } else {
        const keys = variables.map(({ key }: { key: number }) => +key)
        const maxId = Math.max(...((keys.length > 0 && keys) || [0]))
        sectionForm.setFieldsValue({
          variables: [...variables, { ...values, key: `${maxId + 1}` }]
        })
      }

      setVariableModalOpen(false)
    }
  }

  const containerStyles = {
    flexGrow: 1,
    overflowY: 'scroll',
    margin: '0px -14px',
    marginTop: '8px',
    padding: '0px 14px',
    maxHeight: 'calc(100vh - 200px)'
  } satisfies React.CSSProperties

  if (!reportId) return null

  let stepComponent
  switch (step) {
    case 0:
      stepComponent = <CustomReportSectionCopyForm handleReset={resetForm} handleCopy={handleSectionCopy} />
      break
    case 1:
      stepComponent = (
        <div style={containerStyles}>
          <Form.Provider onFormFinish={handleFormFinish}>
            <Form
              name="sectionForm"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                type: 'table',
                style: {
                  showDataLabels: 'false',
                  type: GraphType.column
                },
                params: { reallocate: true }
              }}
            >
              <Form.Item
                name="title"
                label={t('customReportPage:title')}
                rules={[{ required: true, message: t('global:required-field') }]}
                style={{ maxWidth: 300 }}
              >
                <Input />
              </Form.Item>
              <Form.Item name="type" label={t('global:type')}>
                <Radio.Group buttonStyle="solid">
                  <Radio value="table">{t(`dashboardPage:table`)}</Radio>
                  <Radio value="graph">{t(`dashboardPage:chart`)}</Radio>
                  <Radio value="pie">{t(`dashboardPage:pie`)}</Radio>
                  <Radio value="text">{t('global:text')}</Radio>
                </Radio.Group>
              </Form.Item>
              {sectionType === 'text' && (
                <Form.Item name="text" label="">
                  <Editor form={form} section={section as CustomReportTextSection} />
                </Form.Item>
              )}
              {sectionType === 'pie' && (
                <Form.Item name={['style', 'showDataLabels']} label={t('customReportPage:graphValues')}>
                  <Radio.Group buttonStyle="solid">
                    <Radio value="true">{t('customReportPage:inSight')}</Radio>
                    <Radio value="false">{t('customReportPage:hide')}</Radio>
                  </Radio.Group>
                </Form.Item>
              )}

              {sectionType === 'graph' && (
                <>
                  <Form.Item name={['style', 'type']} label={t('customReportPage:graphType')}>
                    <ChartTypeSelect style={{ width: 300 }} />
                  </Form.Item>
                  <Form.Item
                    style={{ width: 300 }}
                    name={['style', 'stacking']}
                    label={t('customReportPage:stacking', 'Pinoaminen')}
                  >
                    <Select
                      allowClear
                      options={[
                        {
                          label: t('customReportPage:stackingNormal', 'Normaali'),
                          value: 'normal'
                        },
                        {
                          label: t('customReportPage:stackingPercent', 'Prosentti'),
                          value: 'percent'
                        }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name={['style', 'showDataLabels']} label={t('customReportPage:graphValues')}>
                    <Radio.Group buttonStyle="solid">
                      <Radio value="true">{t('customReportPage:inSight')}</Radio>
                      <Radio value="false">{t('customReportPage:hide')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
              {sectionType !== 'text' && (
                <>
                  <Form.Item noStyle name={['params', 'reallocate']} valuePropName="checked">
                    <Checkbox>{t('customReportPage:calculateReallocation')}</Checkbox>
                  </Form.Item>
                  {sectionType !== 'pie' && (
                    <Form.Item name={['style', 'switchRowsAndColumns']} valuePropName="checked">
                      <Checkbox>{t('customReportPage:switchRowsAndColumns')}</Checkbox>
                    </Form.Item>
                  )}
                </>
              )}

              {/* Fixes variables behind tab */}
              {sectionType !== 'text' && (
                <>
                  <Form.Item hidden name="variables" />
                  <ProCard
                    style={{ marginBottom: 24, boxShadow: boxShadowTertiary }}
                    bordered
                    tabs={{
                      type: 'line'
                    }}
                  >
                    <ProCard.TabPane key="columns" tab={t('global:table-column', { count: 2 })}>
                      <CategorySelection categories={categories} />
                    </ProCard.TabPane>
                    <ProCard.TabPane key="rows" tab={t('global:table-row', { count: 3 })} style={{ padding: 0 }}>
                      <VariableTable
                        open={variableModalOpen}
                        setOpen={val => setVariableModalOpen(val)}
                        hanldeVariableDelete={val => {
                          if (val.id) {
                            setVariableDelete([...variableDelete, val])
                          }
                        }}
                        setVariableNamePath={setVariableNamePath}
                        variableNamePath={variableNamePath}
                      />
                    </ProCard.TabPane>
                  </ProCard>
                </>
              )}
            </Form>
          </Form.Provider>
        </div>
      )
      break

    default:
      stepComponent = null
      break
  }

  return (
    <Modal
      destroyOnClose
      width={step === 0 ? 400 : 1100}
      style={{ top: 20 }}
      maskClosable={false}
      title={section ? t('customReportPage:edit-section') : t('customReportPage:add-section')}
      open={visible}
      onCancel={handleCancel}
      footer={
        <div>
          {step === 0 ? (
            <>
              <Button onClick={handleCancel}>{t('global:cancel')}</Button>
              <Button onClick={handleStepChange} type="primary">
                {t('contractsPage:continue')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleCancel}>{t('global:cancel')}</Button>
              <Button onClick={() => form.submit()} disabled={!isCategoriesValid} type="primary">
                {t('global:save')}
              </Button>
            </>
          )}
        </div>
      }
    >
      {stepComponent}
    </Modal>
  )
})

export default SectionModal
