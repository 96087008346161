import {
  AddExistingInvestmentAction,
  AddNewInvestmentAction,
  ADD_EXISTING_INVESTMENT,
  ADD_NEW_INVESTMENT,
  ResetInvestmentsAction,
  RESET_INVESTMENTS,
  SetExistingInvestmentsAction,
  SetInvestmentPageDateAction,
  SetNewInvestmentsAction,
  SET_EXISTING_INVESTMENTS,
  SET_INVESTMENT_PAGE_DATE,
  SET_NEW_INVESTMENTS,
  UpdateExistingInvestmentAction,
  UpdateNewInvestmentAction,
  UPDATE_EXISTING_INVESTMENT,
  UPDATE_NEW_INVESTMENT
} from './types'
import { Company } from '../../../types/company/Company'
import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { Investment } from '../../../types/investment/Investment'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'

export const setInvestmentPageDateAction = (payload: any): SetInvestmentPageDateAction => ({
  type: SET_INVESTMENT_PAGE_DATE,
  payload
})

// Existing
export const setExistingInvestmentsAction = (payload: any): SetExistingInvestmentsAction => ({
  type: SET_EXISTING_INVESTMENTS,
  payload
})

export const addExistingInvestment = (investment: Investment): AddExistingInvestmentAction => ({
  type: ADD_EXISTING_INVESTMENT,
  payload: investment
})

export const updateExistingInvestment = (investment: Investment): UpdateExistingInvestmentAction => ({
  type: UPDATE_EXISTING_INVESTMENT,
  payload: investment
})

export const getExistingInvestmentsRequest = (companyId: Company['id'], date: string): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { date },
    url: `/api/companies/${companyId}/budgeting/investments/report/existing`,
    success: setExistingInvestmentsAction
  },
  meta: {
    type: `EXISTING_INVESTMENTS`
  }
})

// New
export const setNewInvestmentsAction = (payload: any): SetNewInvestmentsAction => ({
  type: SET_NEW_INVESTMENTS,
  payload
})

export const addNewInvestment = (investment: Investment): AddNewInvestmentAction => ({
  type: ADD_NEW_INVESTMENT,
  payload: investment
})

export const updateNewInvestment = (investment: Investment): UpdateNewInvestmentAction => ({
  type: UPDATE_NEW_INVESTMENT,
  payload: investment
})

export const resetInvestments = (): ResetInvestmentsAction => ({
  type: RESET_INVESTMENTS,
  payload: null
})

export const getInvestmentsSummaryRequest = (
  companyId: Company['id'],
  date: string,
  budgetingScenarioId: BudgetingScenario['id'] | undefined,
  isExisting?: boolean
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { date, isExisting, budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/investments/summary`,
    success: isExisting ? setExistingInvestmentsAction : setNewInvestmentsAction
  },
  meta: {
    type: `NEW_INVESTMENTS`
  }
})
