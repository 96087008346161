import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

export const languageFields = {
  fin: 'nameFi',
  eng: 'nameEn',
  swe: 'nameSv'
} as const

type LanguageFields = typeof languageFields

type LanguageCode = keyof LanguageFields

export type LanguageField = (typeof languageFields)[LanguageCode]

export type TranslationFields = {
  [key in LanguageField]: string
}

const TranslateFields = () => {
  const { t } = useTranslation()
  return (
    <>
      {Object.entries(languageFields).map(([lng, field]) => (
        <Form.Item key={lng} label={t('global:translation', { language: lng.toLocaleUpperCase() })} name={field}>
          <Input />
        </Form.Item>
      ))}
    </>
  )
}

export default TranslateFields
