import { ConfigDictionary } from '../types'

export interface BudgetingConfig {
  socialSecurityExpenseConfig: ConfigDictionary<SocialSecurityExpenseConfig>
}

export interface SocialSecurityExpenseConfig {
  pensionExpenses: {
    YEL: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
          factor: {
            minValue: number
          }
        }
        accruals: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
    TyEL: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number

          factor: {
            minValue: number
          }
        }
        accruals: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
  }
  otherSocialSecurityExpenses: {
    socialSecurity: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
        accruals: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
    accidentInsurance: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
    unemploymentInsurance: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
    groupLifeInsurance: {
      rows: {
        expenses: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
    mandatoryInsurance: {
      rows: {
        accruals: {
          accountBalanceSheetRow: number
          sourceAccountBalanceSheetRow: number
        }
      }
    }
  }
}

export const BUDGETING_CONFIG = 'BUDGETING_CONFIG'

export interface SetBudgetingConfigAction {
  type: typeof BUDGETING_CONFIG
  payload: BudgetingConfig
}

export type BudgetingConfigActionTypes = SetBudgetingConfigAction
