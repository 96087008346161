import React from 'react'
import { Table, TableProps, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  ReferenceValueRule,
  ReferenceValueRuleOperator
} from '../../../../../../reporting/keyFigures/components/referenceValueTable/types'

interface ReferenceRulesTableProps {
  rules?: ReferenceValueRule[]
}

const ReferenceRulesTable: React.FC<ReferenceRulesTableProps> = ({ rules }) => {
  const { t } = useTranslation()

  if (!rules) return null

  const columns: TableProps<ReferenceValueRule>['columns'] = [
    {
      title: t('global:rule'),
      dataIndex: 'operator',
      key: 'operator',
      render: (value: ReferenceValueRuleOperator) => {
        return t(
          `referenceValueRuleOperator:${
            Object.keys(ReferenceValueRuleOperator)[Object.values(ReferenceValueRuleOperator).indexOf(value)]
          }`
        )
      }
    },
    {
      title: t('global:value'),
      dataIndex: 'value',
      key: 'value',
      render(value) {
        if (Array.isArray(value)) {
          return `[${value.join()}]`
        }
        return value
      }
    },
    {
      title: t('global:color'),
      dataIndex: 'color',
      key: 'color',
      render: value => (
        <Tag style={{ color: 'black' }} color={value}>
          {value}
        </Tag>
      )
    }
  ]

  return (
    <>
      <Typography.Text>{t('global:referenceRules')}</Typography.Text>
      <Table columns={columns} dataSource={rules} pagination={false} size="small" bordered={false} />
    </>
  )
}

export default ReferenceRulesTable
