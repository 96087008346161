import React from 'react'
import { useAuthority } from '../../utils/Authorizable/authorize'
import { Authority } from '../../utils/Authorizable/types'

interface AuthorizedProps {
  authority: Authority
}

const Authorized: React.FC<AuthorizedProps> = ({ authority, children }) => {
  const authorized = useAuthority(authority)

  if (!authorized) return null

  return <>{children}</>
}

export default Authorized
