import React, { useState } from 'react'
import { InputNumber, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { MinusOutlined } from '@ant-design/icons'

interface AccountRuleValue {
  from?: number | null
  to?: number | null
  companyId?: string
  statementRowId?: number
}

interface AccountRuleInputProps {
  value?: AccountRuleValue
  onChange?: (value: AccountRuleValue) => void

  companyId?: string
  statementRowId?: number
}

const AccountRuleInput: React.FC<AccountRuleInputProps> = ({ value = {}, companyId, statementRowId, onChange }) => {
  const { t } = useTranslation()
  const [from, setFrom] = useState<number | null>()
  const [to, setTo] = useState<number | null>()

  const triggerChange = (changedValue: { from?: number | null; to?: number | null }) => {
    onChange?.({
      companyId,
      statementRowId,
      from,
      to,
      ...value,
      ...changedValue
    })
  }

  const onFromChange = (newFrom: number | null) => {
    if (!('from' in value)) {
      setFrom(newFrom)
    }
    triggerChange({ from: newFrom })
  }

  const onToChange = (newTo: number | null) => {
    if (!('to' in value)) {
      setTo(newTo)
    }
    triggerChange({ to: newTo })
  }

  return (
    <Row justify="space-between">
      <Col>
        <InputNumber
          className="rule-field rule-field-from"
          value={value.from || from}
          onChange={onFromChange}
          placeholder={t('accountMapPage:from')}
        />
      </Col>
      <Col>
        <MinusOutlined style={{ marginTop: 7 }} />
      </Col>
      <Col>
        <InputNumber
          className="rule-field rule-field-to"
          value={value.to || to}
          onChange={onToChange}
          placeholder={t('accountMapPage:to')}
        />
      </Col>
    </Row>
  )
}

export default AccountRuleInput
