import { createSelector } from 'reselect'
import { Store } from '../../types'
import { loadingSelector } from '../../loading/selectors'

export const competitorComparisonSelector = (store: Store) => store.context.competitorComparison

export const competitorComparisonLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.competitorComparison
)
