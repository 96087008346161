import dayjs from 'dayjs'

export default {
  0: (state: any) => {
    return {
      ...state,
      context: {
        ...state.context,
        filters: {
          ...state.context.filters,
          cumulativeDataTypes: []
        }
      }
    }
  },
  1: (state: any) => {
    return {
      ...state,
      context: {
        ...state.context,
        filters: {
          ...state.context.filters,
          periodGroups: []
        }
      }
    }
  },
  2: (state: any) => {
    return {
      ...state,
      context: {
        ...state.context,
        reports: {
          ...state.context.reports,
          financialStatement: {
            incomeStatement: [],
            assets: [],
            liabilities: []
          }
        }
      }
    }
  },
  3: (state: any) => {
    return {
      ...state,
      pages: {
        ...state.pages,
        accountantDashboard: {
          ...state.pages.accountantDashboard,
          periodGroups: [
            {
              id: dayjs().year(),
              startDate: dayjs({ year: dayjs().year(), month: 0, day: 1 }).format('YYYY-MM-DD'),
              endDate: dayjs({ year: dayjs().year(), month: 11, day: 1 }).format('YYYY-MM-DD')
            }
          ]
        }
      }
    }
  },
  4: (state: any) => {
    return {
      ...state,
      pages: {
        ...state.pages,
        scenarioDashboard: {
          selectedScenarios: [],
          selectedKeyfigures: [],
          selectedInternalKeyfigures: [],
          keyfigureStatements: {},
          internalKeyfigureStatements: {},
          groupId: undefined
        }
      }
    }
  },
  5: (state: any) => {
    return {
      ...state,
      pages: {
        // eslint-disable-next-line react/destructuring-assignment
        ...state.pages,
        investmentsPage: {
          date: null,
          existingInvestments: null
        }
      }
    }
  },
  6: (state: any) => {
    return {
      ...state,
      pages: {
        // eslint-disable-next-line react/destructuring-assignment
        ...state.pages,
        accountantDashboard: {
          activeDashboard: undefined,
          dashboards: [],
          formulas: {},
          globalKeyfigureStatements: {}
        }
      }
    }
  },
  7: (state: any) => {
    return {
      ...state,
      context: {
        ...state.context,
        reports: {
          ...state.context.reports,
          sales: {
            ...state.context.reports.sales,
            customerGroups: []
          }
        }
      }
    }
  }
}
