import React from 'react'
import { Divider, Tabs } from 'antd'
import { IntegrationServer } from './IntegrationServer'
import { IntegrationTasks } from './IntegrationTasks'
import { IntegrationSchedule } from './IntegrationSchedule'

export const Integration: React.FC = () => {
  const items = [
    { label: 'Haku', key: 'item-1', children: <IntegrationTasks /> }, // remember to pass the key prop
    { label: 'Aikataulu', key: 'item-2', children: <IntegrationSchedule /> }
  ]

  return (
    <>
      <IntegrationServer />
      <Divider />
      <Tabs defaultActiveKey="1" items={items} />
    </>
  )
}
