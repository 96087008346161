import _ from 'lodash'
import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'
import { appLanguageSelector } from '../../app/selectors'
import { propertyStringSorter, translateRecord } from '../../../utils/helpers'

export const companyAccountsRawSelector = createSelector(contextSelector, context => context.accounts)

export const companyAccountsRawByIdSelector = (code?: number) =>
  createSelector(companyAccountsRawSelector, accounts => accounts?.find(account => account.code === code))

export const companyAccountsSelector = createSelector(
  [companyAccountsRawSelector, appLanguageSelector],
  (accounts, language) =>
    accounts
      ?.slice()
      .sort(propertyStringSorter('code'))
      .map(account => translateRecord(account, language))
)

export const companyAccountsArgumentOptionsSelector = createSelector(companyAccountsSelector, accounts => {
  return accounts
    ?.slice()
    ?.sort((a, b) => a.code - b.code)
    .map(account => ({
      name: `${account.code} - ${account.name}`,
      value: account.code
    }))
})

export const companyAccountsByStatementRowIdSelector = createSelector(companyAccountsSelector, accounts =>
  _.groupBy(accounts, 'statementRowId')
)

export const companyAccountsByIdSelector = createSelector(companyAccountsSelector, accounts => _.keyBy(accounts, 'id'))
export const companyAccountsByCodeSelector = createSelector(companyAccountsSelector, accounts =>
  _.keyBy(accounts, 'code')
)

export const companyAccountsLoadingSelector = createSelector(loadingSelector, loading => !!loading.accounts)

export const getAccountByCode = (id: string) =>
  createSelector(companyAccountsSelector, accounts => {
    return accounts?.find(c => c.code.toString() === id) || null
  })
