/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Divider, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { usePermission } from '../../redux/session/currentUser/hooks'
import { User } from '../../types/user/User'
import { useTableProps } from '../../utils/hooks'
import { useColumnSearchProps } from '../Table/useColumSearchProps'
import { AppDispatch } from '../../redux/store'

interface UserTableProps {
  users: User[]
  toggleUser: Function
  deleteUserRequest: Function
}

const UserTable: React.FC<UserTableProps> = (props: UserTableProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { users, toggleUser, deleteUserRequest } = props
  const writeUsersPermission = usePermission('write-users')
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)

  const columns: ColumnProps<User>[] = [
    {
      title: t('usersPage:name'),
      dataIndex: 'displayName',
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.displayName.localeCompare(b?.displayName || ''),
      ...useColumnSearchProps('displayName')
    },
    {
      title: t('usersPage:position'),
      dataIndex: 'position',
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.position.localeCompare(b?.position || '')
    },
    {
      title: t('usersPage:email'),
      dataIndex: 'email',
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.email.localeCompare(b?.email || '')
    },
    {
      title: t('usersPage:role'),
      key: 'role',
      render: (user: User) => t(`usersPage:${user.role?.label}`),
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.role?.label.localeCompare(b?.role?.label || '')
    }
  ]

  writeUsersPermission &&
    columns.push({
      title: t('global:actions'),
      key: 'action',
      align: 'right',
      width: 100,
      render: (user: User) => (
        <span>
          <EditOutlined onClick={() => toggleUser(user)} />
          <Divider type="vertical" />
          <Popconfirm
            placement="bottomRight"
            title={`${t('usersPage:delete-confirm-text')} ${user.displayName}?`}
            onConfirm={() => dispatch(deleteUserRequest(user.id))}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </span>
      )
    })

  return (
    <div ref={contentEl} style={{ width: '100%' }}>
      <Table pagination={false} {...tableProps} dataSource={users} columns={columns} rowKey="id" />
    </div>
  )
}

export default UserTable
