import { createSelector } from 'reselect'
import { Store } from '../../types'
import { loadingSelector } from '../../loading/selectors'

export const industryComparisonSelector = (store: Store) => store.context.industryComparison
export const industryComparisonLoadingSelector = createSelector(loadingSelector, loading => loading.comparison)

/** Industry Comparison company data */
export const companyDataSelector = createSelector(industryComparisonSelector, industryData => {
  return (industryData && industryData.companyData) || []
})

/** Industry Comparison industry data */
export const industryDataSelector = createSelector(industryComparisonSelector, industryData => {
  return (industryData && industryData.industryData) || []
})
