import { Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { FivaldiApiCredentials } from '../types'
import { useDocsURL } from '../../../../../utils/docs'
import DataUpdate from '../dataUpdate'
import { Loading } from '../../../../../components/Misc/Loading'

const Fivaldi: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/fivaldi/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const [authorized, setAuthorized] = useState(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((credentials: FivaldiApiCredentials) => {
        if (credentials.businessId) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
      })
      .catch(() => {
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  if (getCredentials.loading) return <Loading />

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#fivaldi`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>

      {authorized ? (
        <Result
          status="success"
          title={t('integrationsPage:authenticated')}
          extra={
            <>
              <DataUpdate page="fivaldi" initial notAuthorized={!authorized} />
            </>
          }
        />
      ) : (
        <Result status="info" title={t('integrationsPage:notAuthenticated')} />
      )}
    </>
  )
}

export default Fivaldi
