import { CheckOutlined } from '@ant-design/icons'
import { Button, Form, Select, Space } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PartialFiscalYearForm = () => {
  const { t } = useTranslation()

  const monthSelectOptions = () => {
    return dayjs.months().map((month, i) => (
      <Select.Option key={month} value={i}>
        {month}
      </Select.Option>
    ))
  }

  const periodLengths = () => {
    return dayjs.months().map((month, index) => (
      <Select.Option key={month} value={index + 1}>
        {index + 1}
      </Select.Option>
    ))
  }

  return (
    <Form name="partialFiscalYear">
      <Space.Compact block>
        <Form.Item name="periodStart" noStyle rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              width: 'calc(50% - 16px)'
            }}
            allowClear
            placeholder={t('contextDrawer:start')}
          >
            {monthSelectOptions()}
          </Select>
        </Form.Item>

        <Form.Item name="periodLength" noStyle rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: 'calc(50% - 16px)', textAlign: 'center' }}
            allowClear
            placeholder={t('contextDrawer:length')}
          >
            {periodLengths()}
          </Select>
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" icon={<CheckOutlined />} htmlType="submit" />
        </Form.Item>
      </Space.Compact>
    </Form>
  )
}

export default PartialFiscalYearForm
