import { CheckOutlined } from '@ant-design/icons'
import { Button, Form, Select, Space } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { fiscalYearsSelector } from '../../../../../redux/context/fiscalYears/selectors'

interface YearQuartersFormProps {
  year: number
}

const { Option } = Select

const YearQuartersForm: React.FC<YearQuartersFormProps> = ({ year }) => {
  const fiscalYears = useSelector(fiscalYearsSelector)

  return (
    <Form name="yearQuarters" initialValues={{ quartersYear: year }}>
      <Space.Compact block>
        <Form.Item name="quartersYear" noStyle rules={[{ required: true }]}>
          <Select>
            {fiscalYears.map(fiscalYear => (
              <Option key={fiscalYear.id} value={fiscalYear.year}>
                {fiscalYear.year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" icon={<CheckOutlined />} htmlType="submit" />
        </Form.Item>
      </Space.Compact>
    </Form>
  )
}

export default YearQuartersForm
