import { Dimension } from '../../../types/dimension/Dimension'

export const SET_DIMENSIONS = 'SET_DIMENSIONS'
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS'
export const DELETE_DIMENSION = 'DELETE_DIMENSION'
export const ADD_DIMENSION = 'ADD_DIMENSION'
export const UPDATE_DIMENSION_NAME = 'UPDATE_DIMENSION_NAME'

export interface SetDimensionsAction {
  type: typeof SET_DIMENSIONS
  payload: Dimension[]
}

export interface UpdateDimensionsAction {
  type: typeof UPDATE_DIMENSIONS
  payload: Dimension[]
}

export interface UpdateDimensionNameAction {
  type: typeof UPDATE_DIMENSION_NAME
  payload: Dimension
}

export interface DeleteDimensionAction {
  type: typeof DELETE_DIMENSION
  payload: Dimension
}

export interface AddDimensionAction {
  type: typeof ADD_DIMENSION
  payload: Dimension
}

export type DimensionsActionTypes =
  | SetDimensionsAction
  | UpdateDimensionsAction
  | DeleteDimensionAction
  | AddDimensionAction
  | UpdateDimensionNameAction
