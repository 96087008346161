import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { User } from '../../../../types/user/User'
import SuperUserUserProfile from './SuperUserUserProfile'
import SuperUserUserCompanies from './SuperUserUserCompanies'
import { SuperUserUserDelete } from './SuperUserUserDelete'
import LoadingWrapper from '../../../../components/Misc/LoadingWrapper'
import { AppDispatch } from '../../../../redux/store'

const SuperUserUser: React.FC = () => {
  const { id } = useParams() as any
  const getRequest = useBackend(`/api/superuser/users/{id}`)
  const { loading } = getRequest
  const dispatch: AppDispatch = useDispatch()

  const [user, setUser] = useState<User>()

  const request = () => {
    getRequest
      .get({ urlParams: { id } })
      .then(setUser)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'USER_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(request, [id])

  const items = useMemo(
    () =>
      user
        ? [
            { label: 'Profiili', key: 'item-1', children: <SuperUserUserProfile user={user} /> },
            { label: 'Yritykset', key: 'item-2', children: <SuperUserUserCompanies user={user} /> },
            { label: 'Danger zone', key: 'item-3', children: <SuperUserUserDelete user={user} /> }
          ]
        : [],
    [user]
  )

  return (
    <LoadingWrapper loading={loading}>
      <Typography.Title level={3}>{user?.displayName}</Typography.Title>
      <Tabs defaultActiveKey="1" items={items} />
    </LoadingWrapper>
  )
}

export default SuperUserUser
