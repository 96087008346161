import { Company } from '../../../types/company/Company'

export const ADD_SUBSIDIARY = 'ADD_SUBSIDIARY'
export const SET_SUBSIDIARIES = 'SET_SUBSIDIARIES'
export const DELETE_SUBSIDIARY = 'DELETE_SUBSIDIARY'
export const UPDATE_SUBSIDIARY = 'UPDATE_SUBSIDIARY'

export interface AddSubsidiaryAction {
  type: typeof ADD_SUBSIDIARY
  payload: Company[]
}

export interface SetSubsidiariesAction {
  type: typeof SET_SUBSIDIARIES
  payload: Company[]
}

export interface UpdateSubsidiaryAction {
  type: typeof UPDATE_SUBSIDIARY
  payload: Company
}

export interface DeleteSubsidiaryAction {
  type: typeof DELETE_SUBSIDIARY
  payload: Company
}

export type SubsidiaryActionTypes =
  | AddSubsidiaryAction
  | SetSubsidiariesAction
  | UpdateSubsidiaryAction
  | DeleteSubsidiaryAction
