import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, DatePicker, Upload, Alert, Form, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { RcFile } from 'antd/lib/upload'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import ScenarioSelect from '../../../../../components/Scenario/ScenarioSelect'
import { authTokenSelector } from '../../../../../redux/session/authentication/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { AppDispatch } from '../../../../../redux/store'

const BudgetingCSVImport = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const monthFormat = 'YYYY-MM-DD'
  const [files, setFiles] = useState<RcFile[]>([])
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const { budgetingScenario } = useSelector(filtersSelector)
  const token = useSelector(authTokenSelector)
  const companyId = useSelector(contextCompanyIdSelector)

  const resetForm = () => {
    form.resetFields()
  }

  useEffect(resetForm, [companyId])

  const handleSubmit = () => {
    form.validateFields().then(formvalues => {
      setFileLoading(true)
      const formData = new FormData()
      files.forEach((file: RcFile) => {
        formData.append('file', file)
      })
      formData.append('startDate', dayjs(formvalues.startDate).date(1).format(monthFormat))
      formData.append('endDate', dayjs(formvalues.endDate).endOf('month').format(monthFormat))

      axios({
        method: 'post',
        headers: {
          'X-Token': token ?? '',
          'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BACKEND_URL}/api/companies/${companyId}/budgeting/budget-deltas/import`,
        params: {
          budgetingScenarioId: formvalues.budgetingScenario
        },
        data: formData
      })
        .then(() => {
          setFileLoading(false)
          dispatch(notificationAction({ type: 'success', message: 'IMPORT_SUCCESS' }))
        })
        .catch(error => {
          setFileLoading(false)
          dispatch(
            notificationAction({
              type: 'error',
              message: 'IMPORT_ERROR',
              description: error?.response?.data?.message
            })
          )
        })
    })
  }

  const getCsvTemplate = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/templates/budget-import`,
      method: 'GET',
      headers: {
        'X-Token': token ?? ''
      },
      responseType: 'blob' // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'finadeck-budget-import-template.csv')
      document.body.appendChild(link)
      link.click()
    })
  }

  const options = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    headers: {
      'X-Token': token || ''
    },
    fileList: files,
    onRemove: () => {
      setFiles([])
    },
    beforeUpload: (file: RcFile) => {
      setFiles([file])
      return false
    }
  }

  return (
    <>
      <Button icon={<DownloadOutlined />} onClick={getCsvTemplate}>
        {t('budgetingImportPage:get-template')}
      </Button>
      <Form layout="vertical" form={form} colon onFinish={handleSubmit}>
        <Form.Item style={{ maxWidth: 400 }} label={t('global:scenario')}>
          <ScenarioSelect initialValue={budgetingScenario?.id} filterLockedScenarios />
        </Form.Item>
        <Form.Item
          name="startDate"
          rules={[{ required: true, message: t('budgetingImportPage:select-month') }]}
          style={{ maxWidth: 400 }}
          label={t('budgetingImportPage:start-date')}
        >
          <DatePicker.MonthPicker placeholder={t('budgetingImportPage:select-month')} />
        </Form.Item>
        <Form.Item
          name="endDate"
          rules={[{ required: true, message: t('budgetingImportPage:select-month') }]}
          style={{ maxWidth: 400 }}
          label={t('budgetingImportPage:end-date')}
        >
          <DatePicker.MonthPicker placeholder={t('budgetingImportPage:select-month')} />
        </Form.Item>
        <Form.Item
          name="file"
          rules={[{ required: true }]}
          style={{ maxWidth: 600 }}
          label={t('budgetingImportPage:import-file')}
        >
          <Upload.Dragger {...options}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('budgetingImportPage:upload-text')}</p>
            <Alert
              style={{ textAlign: 'left', margin: 16 }}
              message={t('global:attention')}
              description={t('budgetingImportPage:upload-description')}
              type="info"
              showIcon
            />
          </Upload.Dragger>
        </Form.Item>
        <Button loading={fileLoading} type="primary" htmlType="submit" disabled={files.length === 0}>
          {t('global:save')}
        </Button>
      </Form>
      <Divider />
      {t('budgetingImportPage:info-text')}
    </>
  )
}

export default BudgetingCSVImport
