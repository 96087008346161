import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { PersonnelActionTypes, SET_PERSONNEL, UPDATE_PERSONNEL } from './types'

const initialState: KeyFigureFact[] = []

const personnelReducer = (state = initialState, action: PersonnelActionTypes) => {
  switch (action.type) {
    case SET_PERSONNEL:
      return [...action.payload]
    case UPDATE_PERSONNEL:
      return state.map((item: KeyFigureFact) =>
        item.fiscalYearId !== action.payload.fiscalYearId ? item : { ...item, ...action.payload }
      )
    default:
      return state
  }
}

export default personnelReducer
