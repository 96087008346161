import { CheckOutlined } from '@ant-design/icons'
import { DatePicker, Form, Button, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const CustomPeriodGroup = () => {
  const { t } = useTranslation()

  return (
    <Form name="customPeriodGroup">
      <Space.Compact block>
        <Form.Item name="periodGroupRange" noStyle rules={[{ required: true }]}>
          <RangePicker
            style={{ width: '100%' }}
            placeholder={[t('contextDrawer:start'), t('contextDrawer:end')]}
            format="YYYY-MM"
            picker="month"
            allowClear
          />
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" icon={<CheckOutlined />} htmlType="submit" />
        </Form.Item>
      </Space.Compact>
    </Form>
  )
}

export default CustomPeriodGroup
