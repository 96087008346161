import { LoanRepaymentMethodsActionTypes, SET_LOAN_REPAYMENT_METHODS, LoanRepaymentMethod } from './types'

const initialState: LoanRepaymentMethod[] = []

const loanRepaymentMethodsReducer = (state = initialState, action: LoanRepaymentMethodsActionTypes) => {
  switch (action.type) {
    case SET_LOAN_REPAYMENT_METHODS:
      return action.payload
    default:
      return state
  }
}

export default loanRepaymentMethodsReducer
