import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form } from 'antd'
import { Company } from '../../types/company/Company'
import { allRoles, Role } from '../../types/user/Role'
import { User } from '../../types/user/User'
import UserForm, { UserFormData } from './UserForm'
import { permissionsSelector } from '../../redux/entities/permissions/selectors'
import { dimensionListSelector } from '../../redux/context/dimensions/selectors'
import { Dimension } from '../../types/dimension/Dimension'
import { AppDispatch } from '../../redux/store'

export interface UserModalProps {
  contractId: number
  user?: User
  selectableCompanies: Company[]
  selectableRoles: Role[]
  visible: boolean
  toggleModalVisible: () => void
  createUserRequest: Function
  updateUserRequest: Function
}

const UserModal: React.FC<UserModalProps> = (props: UserModalProps) => {
  const { t } = useTranslation()
  const {
    contractId,
    user,
    selectableCompanies,
    selectableRoles,
    visible,
    toggleModalVisible,
    createUserRequest,
    updateUserRequest
  } = props
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()

  const allPermissions = useSelector(permissionsSelector)
  const dimensionList = useSelector(dimensionListSelector)

  const selectableCompaniesIds = selectableCompanies.map(sc => sc.id)

  const notEditableUserCompanies = user?.companies.filter(c => !selectableCompaniesIds.includes(c.id)) || []
  const editableUserCompanies = user?.companies.filter(c => selectableCompaniesIds.includes(c.id)) || []

  const userInEdit: User | undefined = user && { ...user }
  if (userInEdit) {
    userInEdit.companies = editableUserCompanies
  }

  const handleSubmit = async () => {
    try {
      const userFormData: UserFormData = await form.validateFields()
      const role = allRoles.find(r => r.label === userFormData.role)

      const userDataNew = {
        ...userFormData,
        roles: [role],
        companies: [
          ...notEditableUserCompanies,
          ...(userFormData.companies || [])
            .map((companyId: any) => selectableCompanies.find(c => c.id === companyId))
            .filter(Boolean)
        ],
        permissions: userFormData.permissions?.map((label: string) => allPermissions.find(p => p.label === label)),
        dimensions: (userFormData.dimensions || []).map((selection: any) => {
          return dimensionList.find(d => d.dimensionId === selection.value)
        })
      }

      if (user) {
        dispatch(updateUserRequest(userDataNew, user.id))
      } else {
        contractId && dispatch(createUserRequest(userDataNew, contractId))
      }

      form.resetFields()
      toggleModalVisible()
    } catch (error) {
      console.log(error)
    }

    return null
  }

  const transforUserValuesToForm = (u?: User) => {
    return {
      ...u,
      companies: u?.companies.map((c: Company) => c.id).filter(id => selectableCompaniesIds.includes(id)) || [],
      role: u?.role?.label,
      dimensions: u?.dimensions.map((d: Dimension) => ({
        title: d.name,
        value: d.dimensionId
      })),
      permissions: u?.permissions.map(p => p.label)
    }
  }

  useEffect(() => {
    form.resetFields()
    user
      ? form.setFieldsValue(transforUserValuesToForm(user))
      : form.setFieldsValue({ permissions: ['read-root-dimension'] })
  }, [user])

  const handleCancel = () => {
    if (form) {
      form.resetFields()
    }
    toggleModalVisible()
  }

  return (
    <Modal
      width="unset"
      destroyOnClose
      style={{ maxWidth: '650px' }}
      title={user ? t('usersPage:modify-user') : t('usersPage:add-new-user')}
      open={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      maskClosable={false}
      okText={userInEdit ? t('global:update') : t('global:confirm')}
      cancelText={t('global:cancel')}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <UserForm
          form={form}
          contractId={contractId}
          user={userInEdit}
          companiesRequired={!(notEditableUserCompanies.length > 0)}
          selectableCompanies={selectableCompanies}
          selectableRoles={selectableRoles}
        />
      </Form>
    </Modal>
  )
}

export default UserModal
