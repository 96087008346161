import { initialColors } from '../../../pages/settings/company/general/Appearance/GraphColorSettings'
import {
  CompanySettingsActionTypes,
  SET_COMPANY_SETTINGS,
  SET_COMPANY_CHART_COLORS,
  DELETE_COMPANY_CHART_COLORS
} from './types'

const initialState = {
  chartColors: initialColors
}

const companySettingsReducer = (state = initialState, action: CompanySettingsActionTypes) => {
  switch (action.type) {
    case SET_COMPANY_SETTINGS:
      return { ...state, ...action.payload }
    case SET_COMPANY_CHART_COLORS:
      return { ...state, chartColors: [...action.payload] }
    case DELETE_COMPANY_CHART_COLORS:
      return { ...state, chartColors: [...action.payload] }
    default:
      return state
  }
}

export default companySettingsReducer
