/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect'
import _ from 'lodash'
import { Store } from '../../types'
import {
  DeliverySubscription,
  DeliverySubscriptionType
} from '../../../types/deliverySubscription/DeliverySubscription'

const deliverySubscriptions = (state: Store) => state.session.deliverySubscriptions

export const deliverySubscriptionsSelector = createSelector(deliverySubscriptions, ds => ds)

export const groupedDeliverySubscriptionsSelector = (type: DeliverySubscriptionType) => {
  return createSelector(deliverySubscriptions, ds => {
    return _.groupBy(ds, 'type')[type] as DeliverySubscription[]
  })
}
