import { CompanySettings } from '../../../types/companySettings/CompanySettings'

export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS'
export const SET_COMPANY_CHART_COLORS = 'SET_COMPANY_CHART_COLORS'
export const DELETE_COMPANY_CHART_COLORS = 'DELETE_COMPANY_CHART_COLORS'

export interface SetCompanySettingsAction {
  type: typeof SET_COMPANY_SETTINGS
  payload: CompanySettings
}

export interface SetCompanyChartColorsAction {
  type: typeof SET_COMPANY_CHART_COLORS
  payload: string[]
}

export interface DeleteCompanyChartColorsAction {
  type: typeof DELETE_COMPANY_CHART_COLORS
  payload: string[]
}

export type CompanySettingsActionTypes =
  | SetCompanySettingsAction
  | SetCompanyChartColorsAction
  | DeleteCompanyChartColorsAction
