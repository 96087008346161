/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, FormInstance } from 'antd'
import { useSelector } from 'react-redux'
import { Company } from '../../types/company/Company'
import { Role } from '../../types/user/Role'
import { Language, User } from '../../types/user/User'
import { permissionsSelector } from '../../redux/entities/permissions/selectors'
import { Tab } from '../../types/tab/tab'
import { UserFormFields } from './UserFormFields'
import UserPermissionFields from './UserPermissionFields'

export interface UserFormData {
  email?: string
  firstName?: string
  lastName?: string
  language?: Language
  phone?: string | null
  position?: string
  companies?: string[]
  role?: string
  permissions?: string[]
  dimensions?: any[]
}

interface UserFormProps {
  contractId: number
  user?: User
  selectableCompanies: Company[]
  selectableRoles: Role[]
  companiesRequired?: boolean
  form: FormInstance
}

const UserForm = ({ form, selectableCompanies, selectableRoles, companiesRequired, user }: UserFormProps) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('basic')
  const allPermissions = useSelector(permissionsSelector)
  const formErrorBasic = form.getFieldsError([
    'email',
    'firstName',
    'lastName',
    'phone',
    'position',
    'companies',
    'role'
  ])
  const basicHasError = Object.values(formErrorBasic).some((val: any) => val)

  useEffect(() => {
    setActiveKey('basic')
  }, [user, basicHasError])

  // FORCE RENDER ALL TABS, !!TÄRKEE, *****NAN TÄRKEE!!
  const items: Tab[] = [
    {
      label: t('usersPage:basic-settings'),
      key: 'basic',
      forceRender: true,
      children: (
        <UserFormFields
          companiesRequired={companiesRequired}
          selectableCompanies={selectableCompanies}
          selectableRoles={selectableRoles}
        />
      )
    },
    {
      label: t('global:permissions', 'Käyttöoikeudet'),
      key: 'permissions',
      forceRender: true,
      children: <UserPermissionFields permissions={allPermissions} />
    }
  ]

  return <Tabs items={items} activeKey={activeKey} onChange={key => setActiveKey(key)} />
}

export default UserForm
