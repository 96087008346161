import React, { useEffect, useState } from 'react'
import * as jwt from 'jsonwebtoken'
import Text from 'antd/lib/typography/Text'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Result } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import PasswordForm from '../../../components/User/PasswordForm'
import { resetPasswordRequest } from '../../../redux/session/currentUser/actions'
import { UserPasswordCredentials } from '../../../types/user/User'
import { AppDispatch } from '../../../redux/store'

interface ResetPasswordProps {
  extra: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ extra }) => {
  const search = new URLSearchParams(useLocation().search)
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const history = useHistory()
  const token = search.get('token')
  const [tokenError, setTokenError] = useState<jwt.VerifyErrors>()

  useEffect(() => {
    token &&
      process.env.REACT_APP_SECRET &&
      jwt.verify(token, process.env.REACT_APP_SECRET, err => {
        if (err) {
          setTokenError(err)
        }
      })
  }, [token])

  const handleSumit = (values: UserPasswordCredentials) => {
    token && dispatch(resetPasswordRequest(token, values))
    history.push('/user/login')
  }

  return tokenError ? (
    <Result status="403" title={t(`jwt:${tokenError.name}`)} subTitle={tokenError.message} extra={t(extra)} />
  ) : (
    <>
      <Text>{t('usersPage:give-new-password')}</Text>
      <PasswordForm onSubmit={handleSumit} />
    </>
  )
}

export default ResetPassword
