export interface Role {
  id: number
  label: RoleLabel
}

export declare type RoleLabel =
  | 'superuser'
  | 'company-admin'
  | 'company-user'
  | 'accountant-admin'
  | 'accountant-user'
  | 'financier-admin'
  | 'financier-user'

export const companyRoles: Role[] = [
  { id: 6, label: 'company-admin' },
  { id: 7, label: 'company-user' }
]

export const accountantRoles: Role[] = [
  { id: 2, label: 'accountant-admin' },
  { id: 3, label: 'accountant-user' }
]

export const financierRoles: Role[] = [
  { id: 2, label: 'financier-admin' },
  { id: 3, label: 'financier-user' }
]

export const allRoles: Role[] = [
  { id: 2, label: 'accountant-admin' },
  { id: 3, label: 'accountant-user' },
  { id: 4, label: 'financier-admin' },
  { id: 5, label: 'financier-user' },
  { id: 6, label: 'company-admin' },
  { id: 7, label: 'company-user' }
]
