import { Company } from '../../../types/company/Company'
import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { SetGroupCompany, SET_GROUP_COMPANY } from './types'

export const setGroupCompany = (groupCompany: Company): SetGroupCompany => ({
  type: SET_GROUP_COMPANY,
  payload: groupCompany
})

export const getGroupCompany = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/group`,
    success: setGroupCompany
  },
  meta: {
    type: 'GROUP_COMPANY'
  }
})
