import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'

export const SET_FORECASTS = 'SET_FORECASTS'
export const ADD_FORECAST = 'ADD_FORECAST'
export const UPDATE_FORECAST = 'UPDATE_FORECAST'
export const DELETE_FORECAST = 'DELETE_FORECAST'

export interface SetForecastsAction {
  type: typeof SET_FORECASTS
  payload: BudgetingScenario[]
}
export interface AddForecastAction {
  type: typeof ADD_FORECAST
  payload: BudgetingScenario
}
export interface UpdateForecastAction {
  type: typeof UPDATE_FORECAST
  payload: BudgetingScenario
}
export interface DeleteForecastAction {
  type: typeof DELETE_FORECAST
  payload: BudgetingScenario
}

export type ForecastsActionTypes = SetForecastsAction | AddForecastAction | DeleteForecastAction | UpdateForecastAction
