import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { contextContractProductSelector } from '../../redux/context/contract/selectors'
import { ContractProduct } from '../../types/contract/Contract'

export const requireContractProductAtLeast = (contract: ContractProduct, required: ContractProduct) => {
  const array = Object.values(ContractProduct)
  return array.findIndex(p => p === contract) >= array.findIndex(p => p === required)
}

export const requireContractProductExact = (contract: ContractProduct, required: ContractProduct) => {
  return contract === required
}

export const useProduct = (product: ContractProduct) => {
  const [isExact, setIsExact] = useState<boolean>(false)
  const [isAtLeast, setIsAtLeast] = useState<boolean>(false)
  const contractProduct = useSelector(contextContractProductSelector)!

  useEffect(() => {
    setIsExact(requireContractProductExact(product, contractProduct))
    setIsAtLeast(requireContractProductAtLeast(product, contractProduct))
  }, [product, contractProduct])

  return { isExact, isAtLeast }
}

type RestrictedProductProps = {
  product: ContractProduct
  exact?: boolean
  fallback?: JSX.Element | string
}

const RestrictedProduct: React.FunctionComponent<RestrictedProductProps> = ({ product, exact, fallback, children }) => {
  const { isExact, isAtLeast } = useProduct(product)
  if (exact && isExact) {
    return <>{children}</>
  }
  if (!exact && isAtLeast) {
    return <>{children}</>
  }
  // Otherwise, render the fallback
  return <>{fallback}</>
}

export default RestrictedProduct
