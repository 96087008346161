import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserPage from '../../../../components/User/UserPage'
import {
  createContextContractUserRequest,
  deleteContextContractUserRequest,
  getContextContractUsersRequest,
  updateContextContractUserRequest
} from '../../../../redux/context/contract/actions'
import {
  contextContractIdSelector,
  contextContractUserSelector,
  contextContractUsersLoadingSelector
} from '../../../../redux/context/contract/selectors'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { companyRoles } from '../../../../types/user/Role'
import { AppDispatch } from '../../../../redux/store'

const CompanyUsers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const contractId = useSelector(contextContractIdSelector)
  const users = useSelector(contextContractUserSelector)
  const loading = useSelector(contextContractUsersLoadingSelector)
  const companies = useSelector(currentUserSortedCompaniesSelector)

  useEffect(() => {
    contractId && dispatch(getContextContractUsersRequest(contractId))
  }, [contractId, dispatch])

  return (
    <>
      {contractId && (
        <UserPage
          contractId={contractId}
          users={users}
          loading={loading}
          selectableCompanies={_.uniqBy([...companies], 'id').sort((a, b) => (a.name > b.name ? 1 : -1))}
          selectableRoles={companyRoles}
          createUserRequest={createContextContractUserRequest}
          updateUserRequest={updateContextContractUserRequest}
          deleteUserRequest={deleteContextContractUserRequest}
        />
      )}
    </>
  )
}

export default CompanyUsers
