import {
  SetKeyfigureSelectionsAction,
  SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS,
  TOGGLE_COMPETITOR_SELECTION,
  SET_COMPETITOR_SELECTIONS,
  SetCompetitorSelectionsAction,
  ToggleCompetitorSelectionAction
} from './types'
import { IndustryCompany } from '../../../types/industry/Industry'

export const setIndustryCompetitorKeyfigureSelection = (payload: number[]): SetKeyfigureSelectionsAction => ({
  type: SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS,
  payload
})

// Competitor
export const setCompetitorSelections = (payload: IndustryCompany[]): SetCompetitorSelectionsAction => ({
  type: SET_COMPETITOR_SELECTIONS,
  payload
})

export const toggleCompetitorSelection = (payload: IndustryCompany): ToggleCompetitorSelectionAction => ({
  type: TOGGLE_COMPETITOR_SELECTION,
  payload
})
