import { DownOutlined, FilterOutlined } from '@ant-design/icons'
import { MenuProps, Dropdown, Button, Select, DatePicker, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useBackend } from '../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { emptyQuickFiltersData, setQuickFiltersData } from '../../../../redux/context/customReports/actions'
import { customReportQuickFiltersSelector } from '../../../../redux/context/customReports/selectors'
import { AppDispatch } from '../../../../redux/store'

const { RangePicker } = DatePicker

export interface QuickFilterForm {
  period?: {
    start: Date
    end: Date
  }
  sales?: {
    supplier?: (number | string)[]
    seller?: (number | string)[]
    dimension?: (number | string)[]
  }
}

export interface QuickFilterData {
  period?: {
    start: Date
    end: Date
  }
  sales?: {
    suppliers?: { id: string | number; name: string }[]
    sellers?: { id: string | number; name: string }[]
    dimensions?: { id: string | number; name: string }[]
  }
}

const QuickFilters: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const savedQuickFilters = useSelector(customReportQuickFiltersSelector)
  const [quickFilters, setQuickFilters] = useState<QuickFilterData>({})
  const companyId = useSelector(contextCompanyIdSelector)
  const quickFiltersRequest = useBackend('/api/companies/{companyId}/reporting/custom/quick-filter')
  const [params, setParams] = useState<Partial<QuickFilterForm>>(
    savedQuickFilters || {
      period: undefined,
      sales: {
        supplier: undefined,
        seller: undefined,
        dimension: undefined
      }
    }
  )
  const getFilters = async () => {
    try {
      const response = await quickFiltersRequest.get({
        urlParams: { companyId }
      })
      setQuickFilters(response)
    } catch (error) {
      setQuickFilters({})
    }
  }
  useEffect(() => {
    if (companyId) {
      getFilters()
    }
  }, [companyId])

  const items: MenuProps['items'] = [
    {
      label: (
        <RangePicker
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
          style={{ width: '100%', marginBottom: 20 }}
          placeholder={[t('contextDrawer:start'), t('contextDrawer:end')]}
          format="YYYY-MM"
          picker="month"
          value={
            params.period?.start &&
            params.period?.end && [dayjs(params.period?.start || undefined), dayjs(params.period?.end || undefined)]
          }
          allowClear
          onChange={date =>
            date && date[0] && date[1]
              ? setParams({
                  ...params,
                  period: {
                    start: new Date(dayjs(date[0]).startOf('month').format('YYYY-MM-DD')),
                    end: new Date(dayjs(date[1]).endOf('month').format('YYYY-MM-DD'))
                  }
                })
              : setParams({
                  ...params,
                  period: undefined
                })
          }
        />
      ),
      key: '1'
    },
    {
      label: (
        <>
          <Button
            size="small"
            onClick={() =>
              setParams({
                ...params,
                sales: { ...params.sales, seller: quickFilters.sales?.sellers?.map(s => s.id) || undefined }
              })
            }
          >
            Valitse kaikki
          </Button>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode="multiple"
            allowClear
            showSearch
            maxTagCount="responsive"
            placeholder="Myyjä(t)"
            value={params.sales?.seller}
            onChange={seller =>
              setParams({ ...params, sales: { ...params.sales, seller: (seller?.length > 0 && seller) || undefined } })
            }
            style={{ width: '100%', marginBottom: 20 }}
            fieldNames={{ label: 'name', value: 'id' }}
            options={quickFilters?.sales?.sellers || []}
          />
        </>
      ),
      key: '2'
    },
    {
      label: (
        <>
          <Button
            size="small"
            onClick={() =>
              setParams({
                ...params,
                sales: { ...params.sales, supplier: quickFilters.sales?.suppliers?.map(s => s.id) || undefined }
              })
            }
          >
            Valitse kaikki
          </Button>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode="multiple"
            allowClear
            showSearch
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder="Toimittaja(t)"
            value={params.sales?.supplier}
            onChange={supplier =>
              setParams({
                ...params,
                sales: { ...params.sales, supplier: (supplier?.length > 0 && supplier) || undefined }
              })
            }
            style={{ width: '100%', marginBottom: 20 }}
            fieldNames={{ label: 'name', value: 'id' }}
            options={quickFilters?.sales?.suppliers || []}
          />
        </>
      ),
      key: '3'
    },
    {
      label: (
        <>
          <Button
            size="small"
            onClick={() =>
              setParams({
                ...params,
                sales: { ...params.sales, dimension: quickFilters.sales?.dimensions?.map(s => s.id) || undefined }
              })
            }
          >
            Valitse kaikki
          </Button>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode="multiple"
            allowClear
            showSearch
            maxTagCount="responsive"
            placeholder="Laskentakohde"
            value={params.sales?.dimension}
            onChange={dimension =>
              setParams({
                ...params,
                sales: { ...params.sales, dimension: (dimension?.length > 0 && dimension) || undefined }
              })
            }
            style={{ width: '100%', marginBottom: 20 }}
            fieldNames={{ label: 'name', value: 'id' }}
            options={quickFilters?.sales?.dimensions || []}
          />
        </>
      ),
      key: '4'
    },
    {
      label: (
        <div style={{ textAlign: 'right' }}>
          <Button
            danger
            onClick={() => {
              dispatch(emptyQuickFiltersData())
              setParams({
                period: undefined,
                sales: {
                  supplier: undefined,
                  seller: undefined,
                  dimension: undefined
                }
              })
              setOpen(false)
            }}
          >
            Tyhjennä
          </Button>
          <Divider type="vertical" />
          <Button
            onClick={() => {
              setParams(
                savedQuickFilters || {
                  period: undefined,
                  sales: {
                    supplier: undefined,
                    seller: undefined,
                    dimension: undefined
                  }
                }
              )
              setOpen(false)
            }}
          >
            Peruuta
          </Button>

          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => {
              setOpen(false)
              return dispatch(setQuickFiltersData(params))
            }}
          >
            Tallenna valinnat
          </Button>
        </div>
      ),
      key: '5'
    }
  ]

  if (Object.keys(quickFilters).length === 0) {
    return <></>
  }
  return (
    <Dropdown
      menu={{
        items
      }}
      open={open}
      overlayStyle={{ width: 400 }}
    >
      <Button onClick={(): void => setOpen(true)} icon={<FilterOutlined />} style={{ width: '100%' }}>
        Pikafiltterit
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default QuickFilters
