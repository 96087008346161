import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, InputRef, Popconfirm, Select, Space, Table, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import {
  addDimension,
  deleteDimension,
  getDimensionsRequest,
  setDimensions,
  updateDimensions
} from '../../../../redux/context/dimensions/actions'
import { dimensionListSelector, dimensionsLoadingSelector } from '../../../../redux/context/dimensions/selectors'
import { Dimension } from '../../../../types/dimension/Dimension'
import DimensionsDraggable from './components/DimensionsDraggable'
import { currentUserIsAdminSelector } from '../../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../../services/backend'
import { getDimensionTree } from '../../../../components/Dimension/utils'
import { notificationAction } from '../../../../redux/middleware/actions'
import DimensionForm from './components/DimensionForm'
import { AppDispatch } from '../../../../redux/store'

const { Title } = Typography

const DimensionsView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [searchText, setSearchText] = useState('')
  const [showUndoButton, setShowUndoButton] = useState(false)
  const [edit, setEdit] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState('')
  const loading = useSelector(dimensionsLoadingSelector)
  const inputEl = useRef<InputRef>(null)
  const isAdmin = useSelector(currentUserIsAdminSelector)
  const undoDataRequest = useBackend('/api/companies/{companyId}/undo/dimensions')
  const latesRequest = useBackend('/api/companies/{companyId}/undo/dimensions/latest')
  const updateDefaultDimensionRequest = useBackend(
    '/api/companies/{companyId}/accounting/dimensions/default/{dimensionId}'
  )
  const deleteDefaultDimensionRequest = useBackend(
    'api/companies/{companyId}/accounting/dimensions/default/{dimensionId}'
  )

  const dimensionList = useSelector(dimensionListSelector)

  const getUndoData = () => {
    undoDataRequest
      .get({
        urlParams: { companyId }
      })
      .then((response: any) => {
        if (response.length > 0) {
          setShowUndoButton(true)
        } else {
          setShowUndoButton(false)
        }
      })
  }

  useEffect(() => {
    dispatch(setDimensions([]))
    companyId && dispatch(getDimensionsRequest(companyId))
    document.querySelectorAll<HTMLButtonElement>('.resetButton').forEach(node => node.click())
    getUndoData()
  }, [companyId])

  const filteredData = (value: string, record: Dimension) => {
    let found = false

    if (record.name.toString().toLowerCase().includes(value.toLowerCase())) {
      found = true
    }

    function recurse(records: Dimension[]) {
      for (const child of records) {
        if (child.name.toString().toLowerCase().includes(value.toLowerCase())) {
          found = true
          break
        }
        if (child.children) {
          recurse(child.children)
          if (found) break
        }
      }
    }

    if (record.children) {
      recurse(record.children)
    }

    return found
  }

  const handleSearch = (selectedKeys: string[], confirm: any, dataIndex: string) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('global:search')}
        </Button>
        <Button className="resetButton" onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t('global:reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: Dimension) => {
      return filteredData(value, record)
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (inputEl && inputEl !== null && inputEl.current) {
            inputEl?.current?.focus()
          }
        })
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text}
        />
      ) : (
        text
      )
  })

  const onCancel = () => {
    getUndoData()
    setEdit(false)
  }

  const columns: any = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name')
    },
    {
      title: t('global:actions'),
      dataIndex: 'actions',
      align: 'right',
      key: 'actions',
      render: (text: string, record: Dimension) => (
        <DimensionForm cancel={() => onCancel()} selectedDimension={record} />
      )
    }
  ]

  const handeUndo = () => {
    latesRequest
      .post({
        urlParams: { companyId }
      })
      .then((response: any) => {
        const actions: any = {
          afterInsert: deleteDimension(response.data[0]),
          beforeUpdate: updateDimensions(response.data),
          beforeRemove: addDimension(response.data[0])
        }

        dispatch(
          notificationAction({
            type: 'success',
            message: 'UNDO_DIMENSIONS_SUCCESS'
          })
        )
        companyId && dispatch(actions[response.type])
        getUndoData()
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR'
          })
        )
      })
  }

  const updateDefaultDimension = (dimensionId: string) => {
    updateDefaultDimensionRequest
      .put({
        urlParams: { companyId, dimensionId }
      })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'UPDATED'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR'
          })
        )
      })
  }

  const clearDefaultDimension = () => {
    const dimensionId = dimensionList.find(d => d.default)?.dimensionId
    deleteDefaultDimensionRequest
      .delete({
        urlParams: { companyId, dimensionId }
      })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'UPDATED'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR'
          })
        )
      })
  }

  return (
    <>
      {edit ? (
        <DimensionsDraggable cancel={() => onCancel()} />
      ) : (
        <>
          <Space style={{ marginBottom: 16 }}>
            {isAdmin && (
              <>
                <DimensionForm cancel={() => onCancel()} />
                <Popconfirm
                  title={t('dimensionsPage:edit-confirm')}
                  onConfirm={() => setEdit(true)}
                  okText={t('global:yes')}
                  cancelText={t('global:no')}
                >
                  <Button>{t('dimensionsPage:edit-dimensions')}</Button>
                </Popconfirm>
              </>
            )}

            {isAdmin && showUndoButton && (
              <Popconfirm
                title={t('global:delete-confirm')}
                onConfirm={() => handeUndo()}
                okText={t('global:yes')}
                cancelText={t('global:no')}
              >
                <Button loading={latesRequest.loading} style={{ marginLeft: 5 }}>
                  {t('dimensionsPage:undo-text')}
                </Button>
              </Popconfirm>
            )}
          </Space>
          <Table
            loading={loading || latesRequest.loading}
            rowKey={record => record.dimensionId?.toString()}
            columns={columns}
            dataSource={getDimensionTree(dimensionList)}
          />
          {!loading && dimensionList.length > 0 && (
            <>
              <Title level={5}>{t('dimensionsPage:unTargeted')}:</Title>
              <Select
                allowClear
                onClear={() => clearDefaultDimension()}
                onSelect={updateDefaultDimension}
                loading={updateDefaultDimensionRequest.loading || deleteDefaultDimensionRequest.loading}
                style={{ width: 300 }}
                defaultValue={dimensionList.find(d => d.default)?.dimensionId}
                options={dimensionList.map(d => ({
                  value: d.dimensionId,
                  label: <span>{d.name}</span>
                }))}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default DimensionsView
