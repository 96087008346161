import React from 'react'
import { Button, Popconfirm } from 'antd'
import { useDispatch } from 'react-redux'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { User } from '../../../../types/user/User'
import { AppDispatch } from '../../../../redux/store'

interface DeleteUserProps {
  user: User
}

export const SuperUserUserDelete: React.FC<DeleteUserProps> = ({ user }: DeleteUserProps) => {
  const deleteRequest = useBackend(`/api/superuser/users/{userId}`)
  const { loading } = deleteRequest
  const dispatch: AppDispatch = useDispatch()

  const run = () => {
    deleteRequest
      .delete({ urlParams: { userId: user.id } })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'OK'
          })
        )
      })
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'USERS_ERROR',
            description: e.message
          })
        )
      })
  }

  return (
    <Popconfirm placement="bottomRight" title="Oletko Varma" onConfirm={() => run()} okText="Kyllä" cancelText="Ei">
      <Button type="primary" danger loading={loading}>
        Poista
      </Button>
    </Popconfirm>
  )
}
