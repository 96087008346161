import _ from 'lodash'

const initialState: any = {}

const errorReducer = (state: any = initialState, action: any) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|ERROR)/.exec(type)

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [_.camelCase(requestName)]: requestState === 'ERROR' ? action.payload : undefined
  }
}

export default errorReducer
