import { createSelector } from 'reselect'
import { contextSelector } from '../selectors'
import { currentUserSortedCompaniesSelector } from '../../session/currentUser/selectors'

export const exchangeRatesSelector = createSelector(contextSelector, context => context?.exchangeRates)
export const exchangeRatesCompanySelector = createSelector(
  [exchangeRatesSelector, currentUserSortedCompaniesSelector],
  (rates, companies) => {
    return rates.map(er => {
      const company = companies.find(s => s?.id === er.companyId)
      return {
        ...er,
        company
      }
    })
  }
)

export const exchangeRateSelector = (id: string) =>
  createSelector(exchangeRatesSelector, rates => {
    rates.find(r => r.companyId === id)
  })
