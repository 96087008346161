import React, { useState, useMemo, useEffect } from 'react'
import { DynamicDataSheetGrid, keyColumn, createTextColumn } from 'react-datasheet-grid'

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css'
import { Dayjs } from 'dayjs'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CustomExchangeRateSeries } from '../types'
import { reportNames } from './ExchangeRate'

// Define your row type anywhere
export type SheetRow = {
  [key: string]: string | null
}

const StyledDataSheetGrid = styled(DynamicDataSheetGrid)`
    --dsg-selection-border-color: 'tomato';
    --dsg-border-color:#e8ebed;
    --dsg-selection-border-color:rgb(69, 85, 230);
    --dsg-selection-border-radius:2px;
    --dsg-selection-border-width:2px;
    --dsg-selection-background-color:rgba(69, 128, 230, 0.04);
    --dsg-selection-disabled-border-color:#595f62;
    --dsg-selection-disabled-background-color:rgba(0, 0, 0, 0.04);
    --dsg-corner-indicator-width:10px;
    --dsg-header-text-color:rgb(157, 166, 171):
    --dsg-header-active-text-color:black;
    --dsg-cell-background-color:white;
    --dsg-cell-disabled-background-color:rgb(250, 250, 250);
    --dsg-transition-duration:.1s;
    --dsg-expand-rows-indicator-width:10px;
    --dsg-scroll-shadow-width:7px;
    --dsg-scroll-shadow-color:rgba(0,0,0,.2);
`

interface ExchangeRateSheetProps {
  data: SheetRow[]
  columns: Dayjs[]
  companyData?: CustomExchangeRateSeries
  editable?: boolean
  onChange: (sheet: SheetRow[]) => void
  decimals?: number
}

const ExchangeRateSheet: React.FC<ExchangeRateSheetProps> = ({
  companyData,
  columns,
  data,
  editable,
  decimals = 3,
  onChange
}) => {
  const [sheet, setSheet] = useState<SheetRow[]>(data)
  const { t } = useTranslation()

  const valueParser = (value: string) => {
    const val = parseFloat(value?.replace(',', '.'))

    const formatted = val.toFixed(8)
    return Number.isNaN(val) ? '' : formatted
  }

  const handleChange = (values: SheetRow[]) => {
    onChange(values)
    setSheet(values)
  }

  useEffect(() => {
    setSheet(data)
  }, [data])

  const cols = useMemo(
    () => [
      {
        ...keyColumn<SheetRow, 'report'>(
          'report',
          createTextColumn({
            alignRight: true,
            formatBlurredInput: val => {
              return t(`reportTypes:${reportNames[val as keyof typeof reportNames]}`)
            }
          })
        ),
        title: `${companyData?.currency}/${companyData?.baseCurrency}`,
        minWidth: 150,
        disabled: true
      },
      ...columns.map(month => {
        const key = month.format('MM/YYYY')
        return {
          ...keyColumn(
            key,
            createTextColumn({
              alignRight: true,
              continuousUpdates: false,
              parseUserInput: valueParser,
              parsePastedValue: valueParser,
              formatBlurredInput: (val = '') => {
                const parsed = parseFloat(val?.toString()?.replace(',', '.'))
                const formatted = parsed.toFixed(decimals)
                return Number.isNaN(parsed) ? '' : formatted
              }
            })
          ),
          title: key,
          disabled: !editable
        }
      })
    ],
    [columns, decimals, editable]
  )

  return (
    <StyledDataSheetGrid<any> lockRows disableExpandSelection value={sheet} onChange={handleChange} columns={cols} />
  )
}
export default ExchangeRateSheet
