import { Contract, ContractProduct } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddContextContractUsersAction,
  ADD_CONTEXT_CONTRACT_USER,
  DeleteContextContractUsersAction,
  DELETE_CONTEXT_CONTRACT_USER,
  SetContextContractUsersAction,
  SET_CONTEXT_CONTRACT,
  SET_CONTEXT_CONTRACT_USERS,
  UpdateContextContractUsersAction,
  UPDATE_CONTEXT_CONTRACT_USER
} from './types'
import { setDataTypeFilter } from '../filters/actions'
import { ReportDataType } from '../reports/types'

export const setContextContractUsers = (payload: User[]): SetContextContractUsersAction => ({
  type: SET_CONTEXT_CONTRACT_USERS,
  payload
})

export const addContextContractUser = (user: User): AddContextContractUsersAction => ({
  type: ADD_CONTEXT_CONTRACT_USER,
  payload: user
})

export const updateContextContractUser = (user: User): UpdateContextContractUsersAction => ({
  type: UPDATE_CONTEXT_CONTRACT_USER,
  payload: user
})

export const deleteContextContractUser = (user: User): DeleteContextContractUsersAction => ({
  type: DELETE_CONTEXT_CONTRACT_USER,
  payload: user
})

export const getContextContractUsersRequest = (contractId: Contract['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `api/contracts/${contractId}/users`,
    success: (payload: User[]) => setContextContractUsers(payload)
  },
  meta: {
    type: 'CONTEXT_CONTRACT_USERS'
  }
})

export const setContextContract = (contract: Contract) => {
  return (dispatch: any) => {
    dispatch({ type: SET_CONTEXT_CONTRACT, payload: contract })
    if (contract.product === ContractProduct.LIGHT) {
      dispatch(setDataTypeFilter([ReportDataType.actuals]))
    } else {
      dispatch(setDataTypeFilter([ReportDataType.actuals, ReportDataType.budget]))
    }
    dispatch(getContextContractUsersRequest(contract.id))
  }
}

export const createContextContractUserRequest = (user: User, contractId: Contract['id']): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `api/contracts/${contractId}/users`,
    data: user,
    success: (response: User) => addContextContractUser(response)
  },
  meta: {
    type: 'CREATE_USER',
    notification: true
  }
})

export const updateContextContractUserRequest = (user: User, userId: number): PutRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: PUT,
    url: `api/users/${userId}`,
    data: user,
    success: (response: User) => updateContextContractUser(response)
  },
  meta: {
    type: 'UPDATE_USER',
    notification: true
  }
})

export const deleteContextContractUserRequest = (userId: number): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `api/users/${userId}`,
    success: (response: User) => deleteContextContractUser(response)
  },
  meta: {
    type: 'DELETE_USER',
    notification: true
  }
})
