import { Button, Card, Col, Row, Tabs, Tooltip, Typography } from 'antd'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthorizedData } from '../../../../../utils/Authorizable/authorize'
import { dashboardItemPreferences, representationIcons } from '../config'
import { DashboardItemPreference, DashboardItemPreferenceSelection, MenuGroup } from '../types'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from './DashboardItemProvider'

export const NewItemSelect = () => {
  const { t } = useTranslation()
  const itemPreferences = useAuthorizedData(dashboardItemPreferences)
  const { activeSelectionTab, setActiveSelectionTab, handleItemSelection } = useContext(
    CompanyDashboardItemContext
  ) as CompanyDashboardContextType

  const itemActions = (preference: DashboardItemPreference) => {
    if (!preference.params || !preference.params.representation) return []

    return preference.params.representation.map(representation => {
      const Icon = representationIcons[representation.type]
      return (
        <Tooltip title={t(`dashboardPage:${representation.type}`)}>
          <Button
            icon={<Icon />}
            type="link"
            onClick={() => {
              const newItem: DashboardItemPreferenceSelection = {
                ...preference,
                params: {
                  ...preference.params,
                  representation
                }
              }
              handleItemSelection(newItem)
            }}
          />
        </Tooltip>
      )
    })
  }

  const tabItems = Object.entries(_.groupBy(itemPreferences, 'menuGroup')).map(([groupId, groupItems]) => ({
    label: t(`dashboardPage:${MenuGroup[+groupId]}`),
    key: groupId,
    children: (
      <Row gutter={16}>
        {groupItems.map(item => (
          <Col span={8} key={item.translation}>
            <Card
              className="dashboard-item-card ant-card-hoverable"
              style={{ textAlign: 'center' }}
              actions={itemActions(item)}
            >
              <Typography.Text strong>{t(item.translation)}</Typography.Text>
            </Card>
          </Col>
        ))}
      </Row>
    )
  }))

  return (
    <Tabs
      activeKey={activeSelectionTab.toString()}
      onChange={val => setActiveSelectionTab(val)}
      items={tabItems}
      className="dashboard-item-select"
    />
  )
}
