import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultAccountMapRule } from '../../../../types/accountMapRule/AccountMapRule'
import { StatementRow } from '../../../../types/statementRow/StatementRow'
import DefaultAccountRulesTable from './DefaultAccountRulesTable'

interface AccounMapTableProps {
  translation: string
  statementRows: StatementRow[]
  loading: boolean
  deleteAccountMapRule: Function
  createAccountMapRule: Function
  updateAccountMapRule: Function
  accountMapRulesByStatementRowId: _.Dictionary<DefaultAccountMapRule[]>
}

const DefaultAccountMapTable: React.FC<AccounMapTableProps> = ({
  translation,
  statementRows,
  loading,
  deleteAccountMapRule,
  createAccountMapRule,
  updateAccountMapRule,
  accountMapRulesByStatementRowId
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const { t } = useTranslation()

  const toggleAllSelected = () => {
    const allIds = statementRows.map((row: StatementRow) => row.id.toString())
    allIds.length === selectedKeys.length ? setSelectedKeys([]) : setSelectedKeys(allIds)
  }

  const getIcon = (isOpen: boolean) => {
    return isOpen ? <UpSquareOutlined /> : <DownSquareOutlined />
  }

  const columns = [
    {
      title: () => (
        <span>
          <Button
            style={{ marginRight: '16px', marginLeft: '-49px', cursor: 'pointer' }}
            icon={getIcon((statementRows || []).length === selectedKeys.length)}
            type="text"
          />
          {t('accountMapPage:name')}
        </span>
      ),
      dataIndex: 'title',
      rowSpan: 2,
      key: 'id',
      onHeaderCell: () => {
        return {
          onClick: () => {
            toggleAllSelected()
          }
        }
      },
      render: (text: string, statementRow: StatementRow) => {
        return statementRow.isParent ? (
          <b>{t(`${translation}:${statementRow.id}`)}</b>
        ) : (
          t(`${translation}:${statementRow.id}`)
        )
      }
    },
    {
      title: t('global:actions'),
      dataIndex: '',
      key: 'action',
      align: 'right' as const,
      render: () => {
        return null
      }
    }
  ]

  const toggleSelectedKeys = (id: string) => {
    const index = selectedKeys.indexOf(id)
    index < 0 ? setSelectedKeys([...selectedKeys, id]) : setSelectedKeys(selectedKeys.filter((k, i) => index !== i))
  }

  const expandIconRender = ({ record }: any) => {
    const id = record.id.toString()
    return record.isParent ? null : (
      <Button
        type="text"
        onClick={() => toggleSelectedKeys(id)}
        icon={getIcon(selectedKeys.includes(record.id.toString()))}
      />
    )
  }

  return (
    <Table
      className="account-map-table"
      size="small"
      columns={columns}
      expandIcon={expandIconRender}
      rowKey={statementRow => statementRow.id.toString()}
      loading={loading}
      expandedRowKeys={selectedKeys}
      expandedRowRender={(statementRow: StatementRow) => {
        return statementRow.isParent ? null : (
          <DefaultAccountRulesTable
            statementRow={statementRow}
            deleteAccountMapRule={deleteAccountMapRule}
            createAccountMapRule={createAccountMapRule}
            updateAccountMapRule={updateAccountMapRule}
            accountMapRulesByStatementRowIdSelector={accountMapRulesByStatementRowId}
          />
        )
      }}
      dataSource={statementRows}
      pagination={false}
    />
  )
}

export default DefaultAccountMapTable
