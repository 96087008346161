/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { useBackend } from '../../../../../../services/backend'
import EditableCellTable from '../../../../../../components/Table/EditableCellTable'
import DimensionSelect from '../../../../../../components/Dimension/DimensionSelect'
import { Vehicle } from './types'
import { EditableColumnProps } from '../../../../../../components/Table/EditableRowTable'
import { AppDispatch } from '../../../../../../redux/store'

interface VehicleBasedIntegrationsVehiclesProps {
  getVehiclesUrl: string
  updateVehicleUrl: string
}

const VehicleBasedIntegrationsVehicles = ({
  getVehiclesUrl,
  updateVehicleUrl
}: VehicleBasedIntegrationsVehiclesProps) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const getVehicles = useBackend(getVehiclesUrl, process.env.REACT_APP_INTEGRATION_URL)
  const updateVehicle = useBackend(updateVehicleUrl, process.env.REACT_APP_INTEGRATION_URL)
  const [vehicles, setVehicles] = useState<Vehicle[]>([])

  const fetchVehicles = async () => {
    try {
      const response = await getVehicles.get({
        urlParams: { companyId }
      })

      setVehicles(response)
    } catch (error) {
      setVehicles([])
    }
  }

  useEffect(() => {
    fetchVehicles()
  }, [companyId])

  const onUpdate = (formFields: Vehicle) => {
    const { id, dimensionId } = formFields
    updateVehicle
      .put({
        urlParams: { companyId, id },
        body: { data: { dimensionId: dimensionId || null } }
      })
      .then((response: Vehicle) => {
        setVehicles(
          vehicles.map(r => {
            if (r.id === response.id) {
              return { ...r, ...response, dimension: response?.dimension }
            }
            return r
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'UPDATE_INTEGRATION_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  const columns: EditableColumnProps<Vehicle>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: t('global:register-number'),
      dataIndex: 'number',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a.number.localeCompare(b.number)
    },
    {
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      render: (text: any, record: Vehicle) => record?.dimension?.name,
      editable: true,
      inputField: DimensionSelect
    }
  ]

  return (
    <LoadingWrapper loading={getVehicles.loading || updateVehicle.loading}>
      <EditableCellTable<Vehicle>
        size="middle"
        rowKey={val => val.id}
        dataSource={vehicles}
        columns={columns}
        onUpdate={onUpdate}
        pagination={false}
      />
    </LoadingWrapper>
  )
}

export default VehicleBasedIntegrationsVehicles
