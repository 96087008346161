import { createSelector } from 'reselect'
import { loadingSelector } from '../../../loading/selectors'
import { contextSelector } from '../../selectors'

export const companyDashboardSelector = createSelector(contextSelector, context => context.companyDashboards)

export const companyDashboardLayoutsSelector = createSelector(
  contextSelector,
  context => context.companyDashboards && context.companyDashboards[0] && context.companyDashboards[0].layouts
)

export const companyDashboardLoadingSelector = createSelector(loadingSelector, loading => loading.companyDashboard)
