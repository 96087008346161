import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountMapRulesRequest, setContextAccountMapRules } from '../../../../redux/context/accountMapRules/actions'
import { contextCompanyIdSelector, contextCompanySelector } from '../../../../redux/context/company/selectors'
import { contextAccountMapRulesLoadingSelector } from '../../../../redux/context/accountMapRules/selectors'
import AccountMap from '../../../../components/AccountMap/AccountMap'
import { getCompanySettingsRequest, setCompanySettingsAction } from '../../../../redux/context/companySettings/actions'
import { contextRequest } from '../../../../redux/context/actions'
import { CompanySettings } from '../../../../types/companySettings/CompanySettings'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../../redux/store'

const CompanyAccountMap: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const loading = useSelector(contextAccountMapRulesLoadingSelector)
  const company = useSelector(contextCompanySelector)
  const companyForm = company?.form
  const { budgetingScenario } = useSelector(filtersSelector)

  useEffect(() => {
    companyId && dispatch(getAccountMapRulesRequest(companyId))
    companyId && dispatch(contextRequest(getCompanySettingsRequest))

    return () => {
      dispatch(setContextAccountMapRules([]))
      dispatch(setCompanySettingsAction({} as CompanySettings))
    }
  }, [companyId, dispatch, companyForm, budgetingScenario])

  return <>{companyId && <AccountMap loading={loading} />}</>
}

export default CompanyAccountMap
