import React, { useContext } from 'react'
import { Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReallocationContext, ReallocationContextType } from './ReallocationProvider'
import ReallocationFormFields from './form/ReallocationFormFields'

const ReallocationModal = () => {
  const { t } = useTranslation()
  const { sourceModalVisible, sourceForm, handleSaveSource, handleCloseSource } = useContext(
    ReallocationContext
  ) as ReallocationContextType

  return (
    <Modal
      open={sourceModalVisible}
      title={t('reallocationsPage:reallocation')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      onCancel={handleCloseSource}
      onOk={handleSaveSource}
      destroyOnClose
      maskClosable={false}
      forceRender={false}
    >
      <Form
        form={sourceForm}
        layout="horizontal"
        name="reallocation_form"
        autoComplete="off"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 16 }}
      >
        <ReallocationFormFields />
      </Form>
    </Modal>
  )
}

export default ReallocationModal
