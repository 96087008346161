import dayjs from 'dayjs'
import {
  InvestmentsPage,
  InvestmentPageActionTypes,
  SET_EXISTING_INVESTMENTS,
  ADD_EXISTING_INVESTMENT,
  UPDATE_EXISTING_INVESTMENT,
  SET_INVESTMENT_PAGE_DATE,
  ADD_NEW_INVESTMENT,
  SET_NEW_INVESTMENTS,
  UPDATE_NEW_INVESTMENT,
  RESET_INVESTMENTS
} from './types'

const initialState: InvestmentsPage = {
  date: dayjs().toString(),
  existingInvestments: null,
  newInvestments: null
}

const investmentsPageReducer = (state = initialState, action: InvestmentPageActionTypes) => {
  switch (action.type) {
    case SET_INVESTMENT_PAGE_DATE:
      return { ...state, date: action.payload }
    case SET_EXISTING_INVESTMENTS:
      return { ...state, existingInvestments: action.payload }
    case ADD_EXISTING_INVESTMENT:
      return {
        ...state,
        existingInvestments: {
          ...state.existingInvestments,
          balanceSheetRows: state.existingInvestments?.balanceSheetRows.map(row => {
            if (row?.balanceSheetRowId === action.payload.balanceSheetRowId) {
              return { ...row, investments: [...row.investments, action.payload] }
            }
            return row
          })
        }
      }
    case UPDATE_EXISTING_INVESTMENT:
      return {
        ...state,
        existingInvestments: {
          ...state.existingInvestments,
          balanceSheetRows: state.existingInvestments?.balanceSheetRows.map(row => {
            return {
              ...row,
              investments: row?.investments.map(investment =>
                investment.id !== action.payload.id ? investment : action.payload
              )
            }
          })
        }
      }
    case SET_NEW_INVESTMENTS:
      return { ...state, newInvestments: action.payload }
    case ADD_NEW_INVESTMENT:
      return {
        ...state,
        newInvestments: {
          ...state.newInvestments,
          balanceSheetRows: state.newInvestments?.balanceSheetRows.map(row => {
            if (row?.balanceSheetRowId === action.payload.balanceSheetRowId) {
              return { ...row, investments: [...row.investments, action.payload] }
            }
            return row
          })
        }
      }
    case UPDATE_NEW_INVESTMENT:
      return {
        ...state,
        newInvestments: {
          ...state.newInvestments,
          balanceSheetRows: state.newInvestments?.balanceSheetRows.map(row => {
            return {
              ...row,
              investments: row?.investments.map(investment =>
                investment.id !== action.payload.id ? investment : action.payload
              )
            }
          })
        }
      }
    case RESET_INVESTMENTS:
      return { ...state, existingInvestments: null, newInvestments: null }
    default:
      return state
  }
}

export default investmentsPageReducer
