import { TaskApplication } from '../../pages/superuser/types'

export const customerAccountingSoftwares = [
  TaskApplication.FENNOA,
  TaskApplication.NETVISOR,
  TaskApplication.PROCOUNTOR
]

export const customerGroupAccountingSoftwares = [TaskApplication.NETVISOR, TaskApplication.PROCOUNTOR]

export const productAccountingSoftwares = [TaskApplication.FENNOA, TaskApplication.NETVISOR, TaskApplication.PROCOUNTOR]

export const productGroupAccountingSoftwares = [TaskApplication.NETVISOR, TaskApplication.PROCOUNTOR]
