import i18next from 'i18next'
import { ReportTableRow, VariableType } from '../components/Table/types'
import { CustomReportCategory } from '../redux/context/customReports/typesCategory'
import { Column } from '../redux/context/periodGroups/hooks'
import { formatValueByType, formatValueToNumberByType } from './helpers'

export const downloadCSV = (csv: any, filename: string) => {
  // CSV file
  const BOM = '\uFEFF'
  const csvFile = new Blob([BOM + csv], {
    type: 'text/csv;charset=utf-8'
  })

  // Download link
  const downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

const flattenReport = (report: ReportTableRow[]) => {
  const finalReport: ReportTableRow[] = []
  function traverse(rows: ReportTableRow[]) {
    for (const row of rows) {
      const { children, ...rowData } = row
      if (!rowData.key?.includes('menu')) {
        finalReport.push(rowData)
      }
      if (children) traverse(children)
    }
  }
  traverse(report)
  return finalReport
}

export const getRowData = (row: ReportTableRow, columns: Column[], cumulative?: boolean) => {
  if (cumulative) {
    return columns.map(
      column => formatValueToNumberByType(row[`${column.dataIndex}-cumulative`], row.variableType) || 0
    )
  }
  return columns.map(column => {
    if (
      (column as unknown as CustomReportCategory).type === 'function' &&
      ((column as unknown as CustomReportCategory).value === 'growth' ||
        (column as unknown as CustomReportCategory).value === 'commonSize')
    ) {
      return formatValueToNumberByType(row[column.dataIndex], VariableType.percentage) || 0
    }
    return formatValueToNumberByType(row[column.dataIndex], row.variableType) || 0
  })
}

export const getCSVData = (report: ReportTableRow[], columns: Column[]) => {
  const header = [i18next.t(`global:account`), i18next.t(`global:accountName`), ...columns.map(column => column.label)]

  const flatReport = flattenReport(report)

  const rows = flatReport
    .filter(
      f =>
        f.rowType !== 'amendmentCounterPart' &&
        f.rowType !== 'amendment' &&
        f.rowType !== 'investmentFunding' &&
        f.rowType !== 'investment'
    )
    .map(row => {
      const rowTitle = [Number.isInteger(row.code) ? row.code : '', row.customTitle || row.name || row.title]
      const rowData = getRowData(row, columns).map(value => {
        if (value === null) return null

        const formattedValue = formatValueByType(
          row.variableType === VariableType.percentage ? value / 100 : value,
          row.variableType,
          { useGrouping: false }
        ).replace('−', '-')
        if (row.variableType === VariableType.percentage || row.variableType === VariableType.ratio) {
          return formattedValue
        }

        return formattedValue && parseFloat(formattedValue)
      })
      return rowTitle.concat(rowData)
    })

  const stringHeader = [header.join(';')]
  const stringRows = rows.map(row => {
    return row.join(';')
  })

  const csv = stringHeader.concat(stringRows).join('\n')
  return csv
}

export const tableCsvExport = (report: ReportTableRow[], columns: Column[]) => {
  const csv = getCSVData(report, columns)
  const fileName = i18next.t(`reportTypes:${report[0].report}`, 'raportti')
  downloadCSV(csv, `${fileName}.csv`)
}
