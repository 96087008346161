import { Dayjs } from 'dayjs'
import { Investment } from '../../../types/investment/Investment'

export const SET_INVESTMENT_PAGE_DATE = 'SET_INVESTMENT_PAGE_DATE'
export const SET_EXISTING_INVESTMENTS = 'SET_EXISTING_INVESTMENTS'
export const ADD_EXISTING_INVESTMENT = 'ADD_EXISTING_INVESTMENT'
export const UPDATE_EXISTING_INVESTMENT = 'UPDATE_EXISTING_INVESTMENT'
export const SET_NEW_INVESTMENTS = 'SET_NEW_INVESTMENTS'
export const ADD_NEW_INVESTMENT = 'ADD_NEW_INVESTMENT'
export const UPDATE_NEW_INVESTMENT = 'UPDATE_NEW_INVESTMENT'
export const RESET_INVESTMENTS = 'RESET_INVESTMENTS'
export interface InvestmentsPageSummary {
  actualResidual?: number
  budgetResidual: number
  difference?: number
  taxResidual: number
}

export interface InvestmentsPageBalanceSheetRow {
  balanceSheetRowId: number
  investments: Investment[]
  summary: InvestmentsPageSummary
  isExisting?: boolean
  type: 'existing' | 'new'
}

export interface SetInvestmentPageDateAction {
  type: typeof SET_INVESTMENT_PAGE_DATE
  payload: Dayjs
}

// Existing Actions
export interface AddExistingInvestmentAction {
  type: typeof ADD_EXISTING_INVESTMENT
  payload: Investment
}

export interface UpdateExistingInvestmentAction {
  type: typeof UPDATE_EXISTING_INVESTMENT
  payload: Investment
}

export interface InvestmentsPageReport {
  balanceSheetRows: InvestmentsPageBalanceSheetRow[]
  summary: InvestmentsPageSummary
}
export interface SetExistingInvestmentsAction {
  type: typeof SET_EXISTING_INVESTMENTS
  payload: any
}

// New Actions
export interface AddNewInvestmentAction {
  type: typeof ADD_NEW_INVESTMENT
  payload: Investment
}

export interface UpdateNewInvestmentAction {
  type: typeof UPDATE_NEW_INVESTMENT
  payload: Investment
}

export interface InvestmentsPageReport {
  balanceSheetRows: InvestmentsPageBalanceSheetRow[]
  summary: InvestmentsPageSummary
}
export interface SetNewInvestmentsAction {
  type: typeof SET_NEW_INVESTMENTS
  payload: any
}
export interface ResetInvestmentsAction {
  type: typeof RESET_INVESTMENTS
  payload: any
}

// Page data
export interface InvestmentsPage {
  date: string
  existingInvestments: InvestmentsPageReport | null
  newInvestments: InvestmentsPageReport | null
}

export type InvestmentPageActionTypes =
  | SetExistingInvestmentsAction
  | AddExistingInvestmentAction
  | UpdateExistingInvestmentAction
  | SetNewInvestmentsAction
  | AddNewInvestmentAction
  | UpdateNewInvestmentAction
  | SetInvestmentPageDateAction
  | ResetInvestmentsAction
