import dayjs from 'dayjs'

export enum ReallocationType {
  fixed = 'fixed',
  keyFigureId = 'keyFigureId',
  formulaId = 'formulaId',
  floating = 'floating'
}

export enum SourcePeriodType {
  indefinitely,
  fixedPeriod
}

export interface ReallocationSource {
  id: number
  companyId?: string
  description: string
  /** Valinnainen, jos jyvitetään yritykseltä toiselle */
  dimensionId?: string
  /** Valinnainen, jos sääntö on toistaiseksi voimassa oleva */
  startDate?: string | dayjs.Dayjs | null
  /** Valinnainen, jos sääntö on toistaiseksi voimassa oleva */
  endDate?: string | dayjs.Dayjs | null
  /** Koskeeko jyvityssääntö toteumaa */
  actuals: boolean
  /** Koskeeko jyvityssääntö budjettia */
  budget: boolean
  /** Jyvitykssäännön tyyppi */
  type: ReallocationType
  keyFigureId: number
  formulaId: number
  /** Jyvityksen kohteet */
  targets: ReallocationTarget[]
  periodType?: SourcePeriodType
}

export enum ReallocationTargetType {
  range = 'range', // Tileiltä tileille
  account = 'account' // Tileiltä tilille
}

export interface ReallocationFloatingBalance {
  year: number
  month: number
  value: number
}

export interface ReallocationTarget {
  id: number
  sourceId: number
  companyId: string
  /** Valinnainen, jos jyvitetään yritykseltä toiselle */
  dimensionId?: string
  /** Jyvityksen kohdistus */
  type: ReallocationTargetType
  /** Tilit alkaen */
  accountFrom: number
  /** Tilit päättyen */
  accountTo: number
  /** Tili, jos kohdistus on tileiltä tilille */
  account?: number
  fixed: number
  floating: ReallocationFloatingBalance[]
  source?: ReallocationSource
}
