import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  AddForecastAction,
  ADD_FORECAST,
  DeleteForecastAction,
  DELETE_FORECAST,
  SetForecastsAction,
  SET_FORECASTS,
  UpdateForecastAction,
  UPDATE_FORECAST
} from './types'

export const setForecasts = (forecast: BudgetingScenario[]): SetForecastsAction => ({
  type: SET_FORECASTS,
  payload: forecast
})

export const addForecast = (forecast: BudgetingScenario): AddForecastAction => ({
  type: ADD_FORECAST,
  payload: forecast
})

export const updateForecast = (forecast: BudgetingScenario): UpdateForecastAction => ({
  type: UPDATE_FORECAST,
  payload: forecast
})

export const deleteForecast = (forecast: BudgetingScenario): DeleteForecastAction => ({
  type: DELETE_FORECAST,
  payload: forecast
})

export const getForecastsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/budgeting/forecasts`,
    success: (payload: BudgetingScenario[]) => setForecasts(payload)
  },
  meta: {
    type: 'FORECASTS'
  }
})

export const createForecastsRequest = (companyId: Company['id'], payload: BudgetingScenario): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/forecasts`,
    success: (response: BudgetingScenario) => addForecast(response)
  },
  meta: {
    type: 'CREATE_FORECAST',
    notification: true
  }
})

export const updateForecastRequest = (companyId: Company['id'], payload: BudgetingScenario): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    url: `/api/companies/${companyId}/budgeting/forecasts/${payload.id}`,
    success: (response: BudgetingScenario) => updateForecast(response)
  },
  meta: {
    type: 'UPDATE_FORECAST',
    notification: true
  }
})

export const deleteForecastRequest = (
  companyId: Company['id'],
  budgetingScenarioId: BudgetingScenario['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/budgeting/forecasts/${budgetingScenarioId}`,
    success: (response: BudgetingScenario) => deleteForecast(response)
  },
  meta: {
    type: 'DELETE_FORECAST',
    notification: true
  }
})
