import React, { useState, useRef } from 'react'
import { Table, Tag, List, Typography, Divider, Popover } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { useBackend } from '../../../services/backend'
import { Task, TaskStatus } from '../types'
import { useTableProps } from '../../../utils/hooks'
import { Loading } from '../../../components/Misc/Loading'
import { RunTask } from './RunTask'
import { notificationAction } from '../../../redux/middleware/actions'
// import { IntegrationTaskProgress } from './IntegrationTaskProgress'
import { IntegrationTaskSearch } from './IntegrationTaskSearch'
import { IntegrationChart } from './IntegrationChart'
import { AppDispatch } from '../../../redux/store'

export const IntegrationTasks: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const taskRequest = useBackend(`/tasks`, process.env.REACT_APP_INTEGRATION_URL)
  const { loading } = taskRequest
  const [tasks, setTasks] = useState<Task[]>([])

  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)

  const request = (params?: any) => {
    taskRequest
      .get({ body: { params } })
      .then(setTasks)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'INTEGRATION_SERVER_NOT_AVAILABLE',
            description: e.message
          })
        )
      })
  }

  const findChild = (root: Task, id: number): Task | undefined => {
    if (!root.children || root.children.length < 1) return undefined

    for (const child of root.children) {
      if (child.id === id) return child
      const found = findChild(child, id)
      if (found) return found
    }

    return undefined
  }

  const renderError = (root: Task, error: any): string | undefined => {
    if (error.id || error.FAILED.id) {
      const task = findChild(root, error.FAILED.id)
      return task && task.errors && task.errors.map(e => renderError(task, e)).toString()
    }
    return JSON.stringify(error.FAILED, null, 2)
  }

  const renderErrors = (task: Task) => {
    return (
      task.errors && (
        <Popover
          content={
            <List
              size="small"
              dataSource={task.errors}
              renderItem={error => (
                <List.Item>
                  <Typography.Text code copyable>
                    {renderError(task, error)}
                    {/* {error.FAILED.id ? 'asdf' : JSON.stringify(error.FAILED, null, 2)} */}
                  </Typography.Text>
                </List.Item>
              )}
            />
          }
        >
          <Typography.Text code>{task.errors.map(error => renderError(task, error)).toString()}</Typography.Text>
        </Popover>
      )
    )
  }

  // useEffect(request, [])

  const columns: ColumnProps<Task>[] = [
    // {
    //   title: 'ID',
    //   key: 'id',
    //   render: (task: Task) => task.id
    // },
    {
      title: 'Company ID',
      key: 'companyId',
      // filters: companyIds.map(key => ({ text: t(key), value: key })),
      // onFilter: (value, task) => task.companyId.indexOf(value) === 0,
      // sorter: (a, b) => a.companyId.localeCompare(b.companyId),
      // sortDirections: ['descend', 'ascend'],
      render: ({ parentId, companyId }: Task) => !parentId && <Typography.Text copyable>{companyId}</Typography.Text>
    },
    {
      title: 'Application',
      key: 'application',
      // filters: Object.keys(TaskApplication).map(key => ({ text: t(key), value: key })),
      // onFilter: (value, task) => task.application.indexOf(value) === 0,
      render: ({ parentId, application }: Task) => !parentId && application
    },
    {
      title: 'Type',
      key: 'type',
      // filters: Object.keys(TaskType).map(key => ({ text: t(key), value: key })),
      // onFilter: (value, task) => task.type.indexOf(value) === 0,
      render: ({ type }: Task) => type
    },
    {
      title: 'Status',
      key: 'status',
      // filters: Object.keys(TaskStatus).map(key => ({ text: t(key), value: key })),
      // onFilter: (value, task) => task.status.indexOf(value) === 0,
      render: ({ status }: Task) => {
        switch (status) {
          case TaskStatus.FAILED:
            return <Tag color="red">{status}</Tag>
          case TaskStatus.FINISHED:
            return <Tag color="green">{status}</Tag>
          case TaskStatus.RUNNING:
            return <Tag color="blue">{status}</Tag>
          case TaskStatus.PENDING:
          default:
            return <Tag>{status}</Tag>
          // return <IntegrationTaskProgress task={task} tasks={tasks} setTasks={setTasks} />
        }
      }
    },
    {
      title: 'Name',
      key: 'name',
      ellipsis: true,
      render: ({ name }: Task) => <Popover content={name}>{name}</Popover>
    },
    {
      title: 'Start time',
      key: 'startTime',
      render: ({ startTime }: Task) => dayjs(startTime).format('YYYY-MM-DD hh:mm:ss'),
      sorter: (a, b) => (new Date(a.startTime) < new Date(b.endTime) ? 0 : 1),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Run time',
      key: 'runTime',
      align: 'right',
      render: ({ endTime, startTime }: Task) =>
        Math.round(dayjs.duration(dayjs(endTime || new Date()).diff(dayjs(startTime))).as('seconds'))
    },
    {
      title: 'Errors',
      key: 'errors',
      ellipsis: true,
      render: renderErrors
    },
    {
      title: 'Artefact',
      key: 'artefact',
      ellipsis: true,
      render: ({ artefact }: Task) => {
        return (
          artefact && (
            <Popover
              content={
                <Typography>
                  <Typography.Paragraph copyable>
                    <pre style={{ fontSize: 10 }}>{JSON.stringify(artefact, null, 2)}</pre>
                  </Typography.Paragraph>
                </Typography>
              }
            >
              <Typography.Text code>{JSON.stringify(artefact, null, 2)}</Typography.Text>
            </Popover>
          )
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: ({ application, type, companyId, parentId }: Task) => {
        return (
          !parentId && (
            <RunTask
              url={`${application.toLowerCase()}/${type.toLowerCase()}/${companyId.toLowerCase()}`}
              tasks={tasks}
              setTasks={setTasks}
            />
          )
        )
      }
    }
  ]

  return (
    <div ref={contentEl}>
      <IntegrationTaskSearch request={request} />
      <Divider />
      {loading ? (
        <Loading />
      ) : (
        tasks.length > 0 && (
          <>
            <Table
              {...tableProps}
              dataSource={tasks}
              size="small"
              columns={columns}
              rowKey={({ id }: Task) => id.toString()}
            />
            <Divider />
            <IntegrationChart tasks={_.clone(tasks)} />
          </>
        )
      )}
    </div>
  )
}
