import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import dotProp from 'dot-prop'
import { useSelector } from 'react-redux'
import { LoanInstalment } from '../../../../types/loan/LoanInstalment'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { VariableType } from '../../../../components/Table/types'
import EditableCellTable, {
  EditableCellTableDataIndex,
  EditableColumnProps
} from '../../../../components/Table/EditableCellTable'
import Loading from '../../../loading/Loading'
import { formatValueByType } from '../../../../utils/helpers'
import { useBackend } from '../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { Loan } from '../../../../types/loan/Loan'
import { filtersSelector } from '../../../../redux/context/filters/selectors'

interface RepaymentScheduleProps {
  loanData: Loan
  hasDataUpdated: (dataUpdated: boolean) => void
}

const RepaymentSchedule: React.FC<RepaymentScheduleProps> = ({ loanData, hasDataUpdated }: RepaymentScheduleProps) => {
  const { t, i18n } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [repaymentData, setRepaymentData] = useState<LoanInstalment[] | []>()
  const alignRight: 'right' | 'left' | 'center' = 'right'
  const alignCenter: 'right' | 'left' | 'center' = 'center'
  const { budgetingScenario } = useSelector(filtersSelector)
  const repaymentRequest = useBackend(`api/companies/{companyId}/budgeting/loans/{loanId}/instalments`)
  const putAndDeleteRepaymentRequestUrl = useBackend(
    `api/companies/{companyId}/budgeting/loans/{loanId}/corrections/{correctionId}`
  )
  const postRepaymentRequestUrl = useBackend(`api/companies/{companyId}/budgeting/loans/{loanId}/corrections`)
  const render = (text: string) => {
    return Number(text || 0).toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const getTableData = () => {
    repaymentRequest
      .get({
        params: { budgetingScenarioId: budgetingScenario?.id },
        urlParams: { companyId, loanId: loanData.id }
      })
      .then((response: LoanInstalment[]) => {
        setRepaymentData(response)
      })
      .catch(() => {
        setRepaymentData([])
      })
  }

  useEffect(() => {
    getTableData()
  }, [loanData])

  const columns: EditableColumnProps<LoanInstalment>[] = [
    {
      title: t('loans:instalment-date'),
      dataIndex: 'instalmentDate',
      key: 'instalmentDate',
      render: (text: string) => {
        return dayjs(text).format('L')
      }
    },
    {
      title: t('loans:period'),
      dataIndex: 'period',
      key: 'period',
      align: alignCenter
    },
    {
      title: t('loans:opening-balance'),
      dataIndex: 'openingBalance',
      key: 'openingBalance',
      align: alignRight,
      render
    },
    {
      title: t('loans:interest-value'),
      dataIndex: 'interestValue',
      key: 'interestValue',
      align: alignRight,
      render
    },
    {
      title: t('loans:instalment-value'),
      dataIndex: 'instalmentValue',
      key: 'instalmentValue',
      align: alignRight,
      render
    },
    {
      title: t('loans:management-fee'),
      dataIndex: 'managementFee',
      key: 'managementFee',
      align: alignRight,
      render
    },
    {
      title: t('investmentsPage:correction'),
      dataIndex: ['correction', 'value'],
      align: 'right',
      width: '25%',
      render: (value: number) =>
        formatValueByType(value, VariableType.absolute, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }),
      editable: true,
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
        <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
      ))
    },
    {
      title: t('loans:total-value'),
      dataIndex: 'totalValue',
      key: 'totalValue',
      align: alignRight,
      render
    },
    {
      title: t('loans:closing-balance'),
      dataIndex: 'closingBalance',
      key: 'closingBalance',
      align: alignRight,
      render
    }
  ]

  const onUpdate = (
    formFields: LoanInstalment,
    previousRecord: LoanInstalment,
    dataIndex: EditableCellTableDataIndex
  ) => {
    const value = dotProp.get(formFields, (dataIndex as string[]).join('.'), 0)
    const correctionId = previousRecord?.correction?.id
    if (value || value === 0) {
      if (correctionId) {
        if (value === 0) {
          putAndDeleteRepaymentRequestUrl
            .delete({
              urlParams: { companyId, loanId: loanData.id, correctionId }
            })
            .then(() => {
              getTableData()
            })
            .catch()
        } else {
          putAndDeleteRepaymentRequestUrl
            .put({
              urlParams: { companyId, loanId: loanData.id, correctionId },
              body: {
                data: {
                  id: correctionId,
                  year: formFields.year,
                  month: formFields.month,
                  loanId: loanData.id,
                  value
                }
              }
            })
            .then(() => {
              getTableData()
            })
            .catch()
        }
      } else {
        value &&
          postRepaymentRequestUrl
            .post({
              urlParams: { companyId, loanId: loanData.id },
              body: {
                data: {
                  year: formFields.year,
                  month: formFields.month,
                  value
                }
              }
            })
            .then(() => {
              getTableData()
            })
            .catch()
      }
    }
    hasDataUpdated(true)
  }

  return (
    <>
      {repaymentData && repaymentData?.length > 0 ? (
        <EditableCellTable<LoanInstalment>
          size="small"
          rowKey={(record: any) => `${record.year.toString()}-${record.month.toString()}`}
          dataSource={repaymentData}
          columns={columns}
          onUpdate={onUpdate}
          pagination={false}
          loading={putAndDeleteRepaymentRequestUrl.loading || postRepaymentRequestUrl.loading}
        />
      ) : (
        <Loading />
      )}
    </>
  )
}

export default RepaymentSchedule
