import React from 'react'
import { BlockOutlined } from '@ant-design/icons'
import { Route } from '../Route'
import { ContractProduct } from '../../types/contract/Contract'
import ExistingInvestments from '../../pages/budgeting/investments/ExistingInvestments'
import NewInvestments from '../../pages/budgeting/investments/NewInvesments'

const investmentRoutes: Route = {
  path: '/investments',
  name: 'investments',
  icon: <BlockOutlined />,
  authority: {
    product: ContractProduct.PRO
  },
  routes: [
    {
      path: '/investments/existing',
      name: 'existing',
      component: ExistingInvestments
    },
    {
      path: '/investments/investment',
      name: 'investment',
      component: NewInvestments
    }
    // {
    //   path: '/investments/divestment',
    //   name: 'divestment',
    //   component: null
    // }
  ]
}

export default investmentRoutes
