import { Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  return <Result status={404} title="404" subTitle={t('error:notFound')} />
}

export default NotFound
