/* eslint-disable prettier/prettier */
import { Select } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'

interface CompanyInputProps {
  mode?: 'multiple' | 'tags'
  value?: number[]
  onChange?: (value: number[]) => void
}
const CompanyInput: React.FC<CompanyInputProps> = ({ mode, value, onChange }) => {
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const triggerChange = (changedValue: number[]) => {
    onChange?.(changedValue as number[])
  }

  return (
    <Select
      mode={mode}
      allowClear
      showSearch
      filterOption={(input, option) => {
        const { children } = option?.props
        return children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
      }}
      style={{ width: '100%' }}
      value={value}
      onChange={triggerChange}
      maxTagCount="responsive"

    >
      {myCompanies.map(company => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CompanyInput
