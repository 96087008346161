import {} from 'redux'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'
import { Company } from '../../../types/company/Company'
import { GET, GetRequestAction, PUT, REQUEST, PutRequestAction } from '../../middleware/types'
import {
  SetContextAccountMapRulesAction,
  SET_CONTEXT_ACCOUNT_MAP_RULES,
  UpdateAccountMapRuleAction,
  UPDATE_ACCOUNT_MAP_RULE
} from './types'

export const setContextAccountMapRules = (payload: AccountMapRule[]): SetContextAccountMapRulesAction => ({
  type: SET_CONTEXT_ACCOUNT_MAP_RULES,
  payload
})

export const updateAccountMapRule = (payload: AccountMapRule): UpdateAccountMapRuleAction => ({
  type: UPDATE_ACCOUNT_MAP_RULE,
  payload
})

export const updateContextAccountMapRulesRequest = (
  companyId: Company['id'],
  rules: AccountMapRule[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: rules,
    url: `/api/companies/${companyId}/accounting/accounts/map`,
    success: (payload: any) => setContextAccountMapRules(payload)
  },
  meta: {
    type: 'UPDATE_ACCOUNT_MAP_RULES',
    notification: true
  }
})

export const getAccountMapRulesRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/accounts/map`,
    success: setContextAccountMapRules
  },
  meta: {
    type: 'ACCOUNT_MAP_RULES'
  }
})
