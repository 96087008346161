import React from 'react'
import dayjs from 'dayjs'
import { Row, Col, Button, Input, Select, DatePicker, Form } from 'antd'
import { DeliverySubscriptionType } from '../../../types/deliverySubscription/DeliverySubscription'
import { DeliveryStatus } from '../../../types/deliverySubscription/Delivery'

interface DeliveryFormFields {
  companyId: string
  type: string[]
  status: string[]
  after: dayjs.Dayjs | null
  before: dayjs.Dayjs | null
}

interface Props {
  request: (params?: any) => void
}

export const DeliverySearch: React.FC<Props> = ({ request }: Props) => {
  return (
    <Form<DeliveryFormFields>
      onFinish={values => {
        request(values)
      }}
      initialValues={{
        after: dayjs().subtract(14, 'days')
      }}
    >
      <Row gutter={24}>
        <Col span={8} key="company-id" style={{ display: 'block' }}>
          <Form.Item label="Company ID" name="companyId">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} key="type" style={{ display: 'block' }}>
          <Form.Item label="Type" name="type">
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {Object.keys(DeliverySubscriptionType).map(key => (
                <Select.Option value={key} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key="status" style={{ display: 'block' }}>
          <Form.Item label="Status" name="status">
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {Object.keys(DeliveryStatus).map(key => (
                <Select.Option value={key} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key="after" style={{ display: 'block' }}>
          <Form.Item label="After" name="after">
            <DatePicker style={{ width: '100%' }} showTime />
          </Form.Item>
        </Col>
        <Col span={8} key="before" style={{ display: 'block' }}>
          <Form.Item label="Before" name="before">
            <DatePicker style={{ width: '100%' }} showTime />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
