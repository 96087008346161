import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { getFormulasRequest, addFormula, updateFormula } from '../../../../../redux/context/formulas/actions'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { contextRequest } from '../../../../../redux/context/actions'
import { Formula } from '../../../../../types/formula/Formula'
import { formulasWithoutDriversSelector } from '../../../../../redux/context/formulas/selectors'
import { getAccountsRequest } from '../../../../../redux/context/accounts/actions'
import FormulaHelp from './components/FormulaHelp'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'
import { AppDispatch } from '../../../../../redux/store'
import FormulaTable from './components/FormulaTable'
import FormulaFormModal from './components/FormulaFormModal'
import { useBackend } from '../../../../../services/backend'
import './_Formulas.sass'

const Formulas = () => {
  const companyId = useSelector(contextCompanyIdSelector)
  const formulas = useSelector(formulasWithoutDriversSelector)
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const [formulasDataSource, setFormulasDataSource] = useState(formulas)
  const [initialValues, setInitialValues] = useState<Formula>()
  const [visible, setVisible] = useState(false)

  const createFormulaRequest = useBackend('/api/companies/{companyId}/settings/key-figures/formulas/')
  const updateFormulaRequest = useBackend('/api/companies/{companyId}/settings/key-figures/formulas/{formulaId}')
  const [formulaError, setFormulaError] = useState<any>({
    source: '',
    error: null
  })

  useEffect(() => {
    setFormulasDataSource(formulas)
  }, [formulas])

  const handleEdit = (formula: Formula) => {
    setInitialValues(_.cloneDeep(formula))
    setVisible(true)
  }

  const handleCancel = () => {
    setInitialValues(undefined)
    setVisible(false)
    setFormulaError({
      source: '',
      error: null
    })
  }

  const fetchData = () => {
    companyId && dispatch(contextRequest(getFormulasRequest))
    companyId && dispatch(getAccountsRequest(companyId))
    companyId && dispatch(getSubsidiariesRequest(companyId))
  }

  useEffect(fetchData, [companyId])

  const handleSubmit = async (values: Omit<Formula, 'style'> & { style: any }) => {
    const {
      id: formulaId,
      style: { fontWeight, ...restStyles },
      ...rest
    } = values
    const requestData = {
      urlParams: {
        companyId,
        formulaId
      },
      body: {
        data: {
          style: {
            fontWeight: fontWeight ? 'bold' : 'normal',
            ...restStyles
          },
          ...rest
        }
      }
    }

    if (formulaId) {
      try {
        const res = await updateFormulaRequest.put(requestData)
        dispatch(updateFormula(res))

        handleCancel()
      } catch (error) {
        if (updateFormulaRequest.error.current) {
          setFormulaError({
            source: rest.source,
            error: JSON.parse(updateFormulaRequest.error.current?.data?.message)
          })
          return Promise.reject(Error('Syntax error'))
        }
      }
    } else {
      try {
        const res = await createFormulaRequest.post(requestData)
        dispatch(addFormula(res))

        handleCancel()
      } catch (error) {
        if (createFormulaRequest.error.current) {
          setFormulaError({
            source: rest.source,
            error: JSON.parse(createFormulaRequest.error.current?.data?.message)
          })
          return Promise.reject(Error('Syntax error'))
        }
      }
    }

    return Promise.resolve()
  }

  return (
    <div>
      <div>
        <Button
          onClick={() => {
            setVisible(true)
            setInitialValues(undefined)
          }}
          style={{ marginBottom: 8 }}
        >
          {`+ ${t('global:add-new')}`}
        </Button>
        <FormulaHelp />
      </div>
      <FormulaFormModal
        formulaError={formulaError}
        initialValues={initialValues}
        loading={createFormulaRequest.loading || updateFormulaRequest.loading}
        visible={visible}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
      <FormulaTable formulas={formulasDataSource} handleEdit={handleEdit} setFormulas={setFormulasDataSource} />
    </div>
  )
}

export default Formulas
