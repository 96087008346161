import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import { contextCompanySelector } from '../../../../redux/context/company/selectors'
import CurrencyInput from '../../../dashboard/multiPurposeDashboard/form/CurrencyInput'
import { contextRequest } from '../../../../redux/context/actions'
import { updateCompanyRequest } from '../../../../redux/context/company/actions'
import { Company } from '../../../../types/company/Company'
import { updateUserCompanyAction } from '../../../../redux/session/currentUser/actions'
import { AppDispatch } from '../../../../redux/store'

const CompanyGeneralSettings: React.FC = () => {
  const { t } = useTranslation()
  const [form] = useForm<Company>()
  const company = useSelector(contextCompanySelector)!
  const dispatch: AppDispatch = useDispatch()

  const onFinish = (values: any) => {
    if (!values) return
    const newValues = {
      ...company,
      ...values
    }
    dispatch(contextRequest(updateCompanyRequest, newValues))
    dispatch(updateUserCompanyAction(newValues))
  }
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(company)
  }, [company])

  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 6 }
  }

  return (
    <Form form={form} layout="vertical" {...layout} initialValues={company} onFinish={onFinish}>
      <Form.Item label={t('global:vatId')}>
        <b>{company.id}</b>
      </Form.Item>
      <Form.Item name="name" label={t('global:name')}>
        <Input />
      </Form.Item>
      <Form.Item name="currency" label={t('global:currency')}>
        <CurrencyInput />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('global:save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyGeneralSettings
