import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import Checkbox from 'antd/es/checkbox'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useColumnSearchProps } from '../../../../../components/Table/useColumSearchProps'
import { dimensionListSelector } from '../../../../../redux/context/dimensions/selectors'
import { formulasSelector } from '../../../../../redux/context/formulas/selectors'
import { reallocationsSelector } from '../../../../../redux/context/reallocations/selectors'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { setReallocationsPageRowKeys } from '../../../../../redux/pages/settings/reallocations/reallocations/actions'
import { reallocationsPageSelector } from '../../../../../redux/pages/settings/reallocations/reallocations/selectors'
import { ReallocationSource, ReallocationType } from '../../../../../types/reallocation/Reallocation'
import { ReallocationContext, ReallocationContextType } from './ReallocationProvider'
import ReallocationTargetTable from './ReallocationTargetTable'
import { AppDispatch } from '../../../../../redux/store'

function ReallocationTable() {
  const dispatch: AppDispatch = useDispatch()

  const reallocations = useSelector(reallocationsSelector)
  const { expandedRowKeys } = useSelector(reallocationsPageSelector)
  const dimensions = useSelector(dimensionListSelector)
  const formulas = useSelector(formulasSelector)
  const { reallocations: reallocationsLoading } = useSelector(loadingSelector)
  const { handleNewSource, handleEditSource, handleDeleteSource } = useContext(
    ReallocationContext
  ) as ReallocationContextType
  const { t } = useTranslation()

  const typeRenderer = ({ type, ...source }: ReallocationSource) => {
    switch (type) {
      case ReallocationType.fixed:
      case ReallocationType.floating:
        return t(`reallocationsPage:${type}`, '')
      case ReallocationType.keyFigureId:
        return t(`keyFigureStatement:${source[type]}`, '')
      case ReallocationType.formulaId:
        return formulas.find(f => f.id === source[type])?.name || ''
      default:
        return ''
    }
  }

  const columns: ColumnProps<ReallocationSource>[] = [
    {
      key: 'dimensionId',
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      ...useColumnSearchProps('dimensionId', val => {
        return (dimensions && dimensions?.find(d => d.dimensionId === val)?.name) || ''
      })
    },
    {
      key: 'description',
      title: t('global:description'),
      dataIndex: 'description',
      ...useColumnSearchProps('description')
    },
    {
      key: 'actuals',
      title: t('global:actuals'),
      dataIndex: 'actuals',
      render: (val: boolean) => <Checkbox disabled checked={val} />
    },
    {
      key: 'budget',
      title: t('global:budget'),
      dataIndex: 'budget',
      render: (val: boolean) => <Checkbox disabled checked={val} />
    },
    {
      key: 'period',
      title: t('global:time-period'),
      dataIndex: 'period',
      render: (value, record) =>
        record.startDate && record.endDate
          ? `${dayjs(record.startDate).format('MM/YYYY')} - ${dayjs(record.endDate).format('MM/YYYY')}`
          : t('global:indefinitely')
    },
    {
      key: 'type',
      title: t('global:type'),
      dataIndex: 'type',
      ...useColumnSearchProps('type', (val, record: ReallocationSource) => typeRenderer(record))
    },
    {
      key: 'actions',
      title: t('global:actions'),
      align: 'right',
      dataIndex: 'actions',
      render: (val: string, record) => (
        <Space>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditSource(record)
            }}
          />
          <Popconfirm
            title={t('global:delete-confirm')}
            onConfirm={() => handleDeleteSource(record)}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button type="text" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => handleNewSource()} style={{ marginBottom: '1rem' }}>
        {t('global:add')}
      </Button>
      <Table
        loading={reallocationsLoading}
        size="small"
        rowKey="id"
        bordered={false}
        dataSource={reallocations}
        columns={columns}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: rows => dispatch(setReallocationsPageRowKeys(rows as number[])),
          expandedRowRender: record => <ReallocationTargetTable reallocation={record} targets={record.targets} />
        }}
      />
    </>
  )
}

export default ReallocationTable
