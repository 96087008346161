import React, { ReactNode, useState } from 'react'
import { Card, Button, Form } from 'antd'
import { BankOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { blue } from '@ant-design/colors'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { notificationAction } from '../../redux/middleware/actions'
import { autoDownload } from '../../utils/helpers'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/PageHeaderWrapper'
import { useBackend } from '../../services/backend'

import { ReactComponent as Invesdor } from './logos/invesdor.svg'
import { ReactComponent as Fennia } from './logos/fennia.svg'
import fundu from './logos/fundu.png'
import qred from './logos/qred.png'
import opr from './logos/opr.png'

import CheckableCards from './CheckableCards'
import FundingApplicationModal from './FundingApplicationModal'
import { AppDispatch } from '../../redux/store'

export type Financier = {
  name: string
  url?: string
  img: ReactNode
}

const FundingApplications = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const { t } = useTranslation()
  const [form] = useForm()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const fundingApplicationRequest = useBackend('api/companies/{companyId}/funding/applications/send')
  const previewRequest = useBackend(
    'companies/{companyId}/financing/funding/application/preview',
    process.env.REACT_APP_DELIVERY_URL,
    'arraybuffer'
  )

  const financiers: Financier[] = [
    {
      name: 'Qred' as const,
      url: 'https://qred.com/fi/',
      img: qred
    },
    {
      name: 'Fennia' as const,
      url: 'https://www.fennia.fi/asunnot-ja-kiinteistot/rahoitus',
      img: <Fennia style={{ color: '#008A28', width: '100%' }} />
    },
    {
      name: 'Fundu' as const,
      url: 'https://www.fundu.fi/yrityksille/',
      img: fundu
    },
    {
      name: 'Invesdor' as const,
      url: 'https://www.invesdor.com/fi',
      img: <Invesdor style={{ width: '100%' }} />
    },
    {
      name: 'Opr' as const,
      url: 'https://www.opr-finance.fi/yritysasiakkaat/',
      img: opr
    },
    {
      name: t('fundingApplicationsPage:bankFinancing'),
      url: undefined,
      img: <BankOutlined style={{ width: '100%', fontSize: '1.5rem', color: blue.primary }} />
    }
  ]

  const onSubmit = async () => {
    const { financiers: financierList } = await form.getFieldsValue()
    setModalVisible(false)
    fundingApplicationRequest
      .post({
        urlParams: { companyId },
        body: {
          data: financierList
        }
      })
      .then(() => {
        dispatch(notificationAction({ type: 'success', message: 'FUNDING_APPLICATION_SUCCESS' }))
      })
      .catch(() => {
        dispatch(notificationAction({ type: 'error', message: 'FUNDING_APPLICATION_ERROR' }))
      })

    form.resetFields()
  }

  const onCancel = () => {
    setModalVisible(false)
  }

  const preview = async () => {
    try {
      const response = await previewRequest.get({
        urlParams: { companyId }
      })

      autoDownload('funding-application-preview.pdf', response)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'FUNDING_APPLICATION_ERROR' }))
    }
  }

  return (
    <PageHeaderWrapper
      title={t('menu:/funding-applications')}
      subTitle={t('fundingApplicationsPage:sub-title')}
      ghost={false}
    >
      <Form form={form} layout="horizontal" onFinish={onSubmit} className="funding-applications">
        <Card>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <>
                <Form.Item shouldUpdate name="financiers" noStyle rules={[{ required: true, message: '*' }]}>
                  <CheckableCards financiers={financiers} />
                </Form.Item>
                <div>
                  <Button onClick={preview} loading={previewRequest.loading}>
                    {t('global:preview')}
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    disabled={getFieldValue('financiers')?.length === 0}
                    onClick={() => setModalVisible(true)}
                    loading={fundingApplicationRequest.loading}
                    type="primary"
                  >
                    {t('fundingApplicationsPage:sendFundingApplication')}
                  </Button>
                </div>
              </>
            )}
          </Form.Item>
        </Card>

        {/* Modal */}
        <FundingApplicationModal
          modalOpen={modalVisible}
          financiers={financiers}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Form>
    </PageHeaderWrapper>
  )
}

export default FundingApplications
