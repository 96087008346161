import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Company } from '../../../../types/company/Company'
import {
  CompanyDashboard,
  CompanyDashboardItemType,
  CompanyDashboardItem
} from '../../../../types/dashboard/company/types'
import {
  GET,
  GetRequestAction,
  PUT,
  PutRequestAction,
  REQUEST,
  PostRequestAction,
  POST,
  DeleteRequestAction,
  DELETE
} from '../../../middleware/types'
import { Store } from '../../../types'
import {
  getCashFlowStatementRequest,
  getFinancialStatementRequest,
  getKeyFigureStatementRequest,
  getInternalKeyFigureStatementRequest,
  getSalesProductsStatementRequest,
  getSalesProductGroupsStatementRequest,
  getSalesCustomersStatementRequest,
  getSalesCustomerGroupsStatementRequest,
  updateInternalKeyFigureStatementRequest
} from '../../reports/actions'
import {
  SetCompanyDashboardAction,
  SET_COMPANY_DASHBOARD,
  SetCompanyDashboardItemAction,
  SET_COMPANY_DASHBOARD_ITEM,
  DeleteCompanyDashboardItemAction,
  DELETE_COMPANY_DASHBOARD_ITEM,
  UpdateCompanyDashboardItemAction,
  UPDATE_COMPANY_DASHBOARD_ITEM,
  SetCompanyDashboardLayoutsAction,
  SET_COMPANY_DASHBOARD_LAYOUTS,
  AddCompanyDashboardAction,
  ADD_COMPANY_DASHBOARD,
  DeleteCompanyDashboardAction,
  DELETE_COMPANY_DASHBOARD,
  UpdateCompanyDashboardAction,
  UPDATE_COMPANY_DASHBOARD
} from './types'
import { contextRequest, ContextRequestParams } from '../../actions'
import { RecordType } from '../../../../types/sales/sales'

export const setCompanyDashboard = (companyDashboard: CompanyDashboard[]): SetCompanyDashboardAction => ({
  type: SET_COMPANY_DASHBOARD,
  payload: companyDashboard
})

export const updateCompanyDashboard = (companyDashboard: CompanyDashboard): UpdateCompanyDashboardAction => ({
  type: UPDATE_COMPANY_DASHBOARD,
  payload: companyDashboard
})

export const addCompanyDashboard = (companyDashboard: CompanyDashboard): AddCompanyDashboardAction => ({
  type: ADD_COMPANY_DASHBOARD,
  payload: companyDashboard
})

export const setCompanyDashboardItem = (
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): SetCompanyDashboardItemAction => ({
  type: SET_COMPANY_DASHBOARD_ITEM,
  payload: { companyDashboardItem, dashboardId }
})

export const updateCompanyDashboardItem = (
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): UpdateCompanyDashboardItemAction => ({
  type: UPDATE_COMPANY_DASHBOARD_ITEM,
  payload: { companyDashboardItem, dashboardId }
})

export const deleteCompanyDashboardItem = (
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): DeleteCompanyDashboardItemAction => ({
  type: DELETE_COMPANY_DASHBOARD_ITEM,
  payload: { companyDashboardItem, dashboardId }
})

export const deleteCompanyDashboard = (dashboardId: number): DeleteCompanyDashboardAction => ({
  type: DELETE_COMPANY_DASHBOARD,
  payload: { dashboardId }
})

export const setCompanyDashboardLayouts = (layouts: any, dashboardId: number): SetCompanyDashboardLayoutsAction => ({
  type: SET_COMPANY_DASHBOARD_LAYOUTS,
  payload: { layouts, dashboardId }
})

const getReportRequests = (items: CompanyDashboardItem[]): ((params: ContextRequestParams) => GetRequestAction)[] => {
  if (!items) return []
  return items.reduce(
    (
      acc: ((params: ContextRequestParams, formulaIds?: number[]) => GetRequestAction)[],
      item: CompanyDashboardItem
    ) => {
      switch (item.type) {
        case CompanyDashboardItemType.INCOME_STATEMENT:
        case CompanyDashboardItemType.BALANCE_SHEET:
          if (!acc.includes(getFinancialStatementRequest)) acc.push(getFinancialStatementRequest)
          break
        case CompanyDashboardItemType.CASH_FLOW_STATEMENT:
          if (!acc.includes(getFinancialStatementRequest)) acc.push(getFinancialStatementRequest)
          if (!acc.includes(getCashFlowStatementRequest)) acc.push(getCashFlowStatementRequest)
          break
        case CompanyDashboardItemType.KEY_FIGURES:
          if (!acc.includes(getKeyFigureStatementRequest)) acc.push(getKeyFigureStatementRequest)
          break
        case CompanyDashboardItemType.INTERNAL_KEY_FIGURES:
          if (!acc.includes(getInternalKeyFigureStatementRequest)) acc.push(getInternalKeyFigureStatementRequest)
          break
        case CompanyDashboardItemType.SALES:
          switch (item.params.record) {
            case RecordType.products:
              if (!acc.includes(getSalesProductsStatementRequest)) acc.push(getSalesProductsStatementRequest)
              break
            case RecordType.productGroups:
              if (!acc.includes(getSalesProductGroupsStatementRequest)) acc.push(getSalesProductGroupsStatementRequest)
              break
            case RecordType.customerGroups:
              if (!acc.includes(getSalesCustomerGroupsStatementRequest))
                acc.push(getSalesCustomerGroupsStatementRequest)
              break
            case RecordType.customers:
              if (!acc.includes(getSalesCustomersStatementRequest)) acc.push(getSalesCustomersStatementRequest)
              break
            default:
              break
          }
          break
        default:
          return acc
      }
      return acc
    },
    []
  )
}

export const getDataForCompanyDashboard = (
  companyDashboard?: CompanyDashboard
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { company } = store.context
    if (company && companyDashboard && companyDashboard.items) {
      const requests = getReportRequests(companyDashboard.items)

      // otetaan kaikkien sektioiden sis. tunnusluvut ja haetaan kerralla data vain niille
      if (companyDashboard.items.filter(r => r.type === CompanyDashboardItemType.INTERNAL_KEY_FIGURES)) {
        const onlyInternal = companyDashboard.items.filter(
          r => r.type === CompanyDashboardItemType.INTERNAL_KEY_FIGURES
        )
        const keyFigures = onlyInternal.reduce((acc: number[], item: CompanyDashboardItem) => {
          acc.push(...(item?.params?.keyFigureIds || []))
          if (item.params.keyFigureDefaultPieIds) {
            acc.push(...(item?.params?.keyFigureDefaultPieIds || []))
          }
          return acc
        }, [])
        dispatch(contextRequest(getInternalKeyFigureStatementRequest, keyFigures))
      }

      requests.forEach((item: (params: ContextRequestParams) => GetRequestAction) => {
        if (item !== getInternalKeyFigureStatementRequest) {
          dispatch(contextRequest(item))
        }
      })
    }
  }
}

export const createCompanyDashboardItemSuccess = (
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(setCompanyDashboardItem(companyDashboardItem, dashboardId))

    const requests = getReportRequests([companyDashboardItem])
    requests.forEach((request: (params: ContextRequestParams) => GetRequestAction) => {
      if (request === getInternalKeyFigureStatementRequest) {
        dispatch(contextRequest(updateInternalKeyFigureStatementRequest, companyDashboardItem.params.keyFigureIds))
      } else {
        dispatch(contextRequest(request))
      }
    })
  }
}

export const updateCompanyDashboardItemSuccess = (
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(
      updateCompanyDashboardItem(
        {
          id: companyDashboardItem.id,
          name: companyDashboardItem.name,
          params: companyDashboardItem.params,
          type: companyDashboardItem.type
        },
        dashboardId
      )
    )
    if (companyDashboardItem.type === CompanyDashboardItemType.INTERNAL_KEY_FIGURES) {
      dispatch(contextRequest(updateInternalKeyFigureStatementRequest, companyDashboardItem.params.keyFigureIds))
    }
  }
}

export const getCompanyDashboardRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/dashboards`,
    success: setCompanyDashboard
  },
  meta: {
    type: 'COMPANY_DASHBOARD'
  }
})

export const createCompanyDashboardRequest = (companyId: Company['id'], dashboardName: string): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `/api/companies/${companyId}/dashboards`,
    data: { name: dashboardName, layouts: {} },
    success: (payload: CompanyDashboard) => addCompanyDashboard(payload)
  },
  meta: {
    type: 'ADD_COMPANY_DASHBOARD'
  }
})

export const updateCompanyDashboardRequest = (
  companyId: Company['id'],
  companyDashboard: Partial<CompanyDashboard>,
  companyDashboardId: number
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/dashboards/${companyDashboardId}`,
    data: companyDashboard,
    success: updateCompanyDashboard
  },
  meta: {
    type: 'UPDATE_COMPANY_DASHBOARD'
  }
})

export const createCompanyDashboardItemRequest = (
  companyId: Company['id'],
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `/api/companies/${companyId}/dashboards/${dashboardId}/items`,
    data: companyDashboardItem,
    success: (payload: CompanyDashboardItem) => createCompanyDashboardItemSuccess(payload, dashboardId)
  },
  meta: {
    type: 'CREATE_COMPANY_DASHBOARD_ITEM'
  }
})

export const updateCompanyDashboardItemRequest = (
  companyId: Company['id'],
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/dashboards/${dashboardId}/items/${companyDashboardItem.id}`,
    data: companyDashboardItem,
    success: (payload: CompanyDashboardItem) =>
      // TODO: korjattava bäkkiin tämä, laitettava sitten muotoon updateCompanyDashboardItem(payload)
      updateCompanyDashboardItemSuccess(payload, dashboardId)
  },
  meta: {
    type: 'UPDATE_COMPANY_DASHBOARD_ITEM'
  }
})

export const deleteCompanyDashboardRequest = (companyId: Company['id'], dashboardId: number): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/dashboards/${dashboardId}`,
    success: () => deleteCompanyDashboard(dashboardId)
  },
  meta: {
    type: 'DELETE_COMPANY_DASHBOARD'
  }
})

export const deleteCompanyDashboardItemRequest = (
  companyId: Company['id'],
  companyDashboardItem: CompanyDashboardItem,
  dashboardId: number
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/dashboards/${dashboardId}/items/${companyDashboardItem.id}`,
    success: (payload: CompanyDashboardItem) => deleteCompanyDashboardItem(payload, dashboardId)
  },
  meta: {
    type: 'DELETE_COMPANY_DASHBOARD_ITEM'
  }
})

export const updateCompanyDashboardLayouts = (
  companyDashboard: CompanyDashboard,
  layoutsData: ReactGridLayout.Layouts,
  currentLayout: ReactGridLayout.Layout[],
  companyId: Company['id']
): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const { company } = store.context
    if (company && companyDashboard.id !== 0 && company.id === companyId) {
      const layouts = layoutsData

      let lastModified = ''
      if (currentLayout === layouts.lg) {
        lastModified = 'lg'
      }
      if (currentLayout === layouts.md) {
        lastModified = 'md'
      }
      if (currentLayout === layouts.sm) {
        lastModified = 'sm'
      }
      if (currentLayout === layouts.xs) {
        lastModified = 'xs'
      }
      if (currentLayout === layouts.xxs) {
        lastModified = 'xxs'
      }

      dispatch(setCompanyDashboardLayouts({ ...layouts, lastModified }, companyDashboard.id))
      const compDas = companyDashboard
      delete compDas.order

      companyId &&
        dispatch(
          updateCompanyDashboardRequest(
            companyId,
            {
              ...compDas,
              layouts: {
                ...layouts,
                lastModified
              }
            },
            companyDashboard.id
          )
        )
    }
  }
}
