import { Route } from './Route'
import { useLogin } from '../redux/session/currentUser/hooks'
import config from './config'
import { useAuthorizedData } from '../utils/Authorizable/authorize'

export const useAuthorizedRoutes = () => {
  const isLoggedIn = useLogin()
  const routes = useAuthorizedData(config.filter(route => route.authorized))

  if (!isLoggedIn) return []
  return routes
}

export const usePublicRoutes = () => {
  const traverse = (routes: Route[]): Route[] => {
    return routes
      .filter((route: Route) => !route.authorized)
      .map((route: Route) => {
        if (route.routes) {
          return { ...route, routes: traverse(route.routes) }
        }
        return route
      })
  }
  return traverse(config)
}
