/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import openSocket from 'socket.io-client'
import { Company } from '../../types/company/Company'
import { Thread } from '../../types/threads/Thread'
import { setThreads } from '../threads/actions'

export const wsConnect = (company?: Company) => ({ type: 'WS_CONNECT', payload: { company } })
export const wsConnected = (company: Company) => ({ type: 'WS_CONNECTED', payload: { company } })

const socketMiddleware = () => {
  let socket: any // TODO SocketIOClient.Socket

  return (store: any) => (next: Dispatch) => (action: any) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket) {
          socket.close()
        }
        socket = openSocket(`wss://staging.finadeck.fi:5200/threads/${action.payload.company.id}`)

        socket.on('threadMessageSaved', () => {
          console.log('web socket threadMessageSaved')
        })
        socket.on('disconnected', () => {
          console.log('web socket disconnected')
        })
        socket.on('connected', (threads: Thread[]) => {
          store.dispatch(setThreads(threads))
        })

        break
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close()
        }
        console.log('websocket closed')
        break
      case 'WS_SAVE_THREAD_MESSAGE':
        console.log('sending a message', action.msg)
        socket.emit('saveThreadMessage', action.payload)
        break
      default:
        return next(action)
    }
  }
}

export default socketMiddleware()
