/* eslint-disable react/destructuring-assignment */
import { DeleteOutlined, EditOutlined, HistoryOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Space, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sessionContractIdSelector } from '../../../../../../redux/session/contract/selectors'
import {
  deleteDeliverySubscriptionRequest,
  getDeliverySubscriptionsRequest,
  sendDeliverySubscriptionRequest
} from '../../../../../../redux/session/deliverySubscriptions/actions'
import { groupedDeliverySubscriptionsSelector } from '../../../../../../redux/session/deliverySubscriptions/selectors'
import {
  DeliverySubscription,
  DeliverySubscriptionType,
  ManualDelivery
} from '../../../../../../types/deliverySubscription/DeliverySubscription'
import { useTableProps } from '../../../../../../utils/hooks'
import { loadingSelector } from '../../../../../../redux/loading/selectors'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import { renderDeliverySubscriptionInitiator } from '../utils'
import Deliveries from './Deliveries'
import { useColumnSearchProps } from '../../../../../../components/Table/useColumSearchProps'
import { AppDispatch } from '../../../../../../redux/store'

interface DeliverySubscriptionTableProps {
  subscriptionType: DeliverySubscriptionType
  toggleDeliverySubscription: Function
  type?: string
}

const DeliverySubscriptionTable: React.FC<DeliverySubscriptionTableProps> = ({
  subscriptionType,
  toggleDeliverySubscription,
  type
}) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const contractId = useSelector(sessionContractIdSelector)
  let deliverySubscriptions = useSelector(groupedDeliverySubscriptionsSelector(subscriptionType))
  const [deliverySubscription, setDeliverySubscription] = useState<DeliverySubscription>()
  const [deliveryModalVisibility, setDeliveryModalVisibility] = useState(false)
  const loading = useSelector(loadingSelector)

  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)

  const toggleDeliveries = (d: DeliverySubscription) => {
    setDeliverySubscription(d)
    setDeliveryModalVisibility(true)
  }

  if (subscriptionType === DeliverySubscriptionType.ACCOUNTOR_SCORECARD && deliverySubscriptions) {
    if (type === 'auto') {
      deliverySubscriptions = deliverySubscriptions.filter(d => !(d.initiator as ManualDelivery).manual)
    }
    if (type === 'manual') {
      deliverySubscriptions = deliverySubscriptions.filter(d => (d.initiator as ManualDelivery).manual)
    }
  }

  const userColumnRender = (text: any, d: DeliverySubscription) => {
    return d?.recipients?.join(', ') || d.user?.displayName || ''
  }

  useEffect(() => {
    contractId && dispatch(getDeliverySubscriptionsRequest(contractId))
  }, [contractId, dispatch])

  const columns: ColumnProps<DeliverySubscription>[] = [
    {
      title: t('subscriptionsPage:company'),
      key: 'company',
      dataIndex: ['company', 'name'],
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.company?.name.localeCompare(b?.company?.name || '') || 0,
      ...useColumnSearchProps(['company', 'name'])
    },
    // Renderöity kenttä ei tule dataIndexistä
    {
      title: t('subscriptionsPage:user'),
      key: 'recipient',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const aStr = userColumnRender(null, a)
        const bStr = userColumnRender(null, b)
        return aStr.localeCompare(bStr || '') || 0
      },
      ...useColumnSearchProps('', userColumnRender)
    },
    {
      title: t('subscriptionsPage:initiator'),
      key: 'initiator',
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      sorter: (a: any, b: any) =>
        (a?.initiator?.monthly?.day || a?.initiator?.weekly?.dow || 0) -
          (b?.initiator?.monthly?.day || b?.initiator?.weekly?.dow || 0) || 0,
      render: renderDeliverySubscriptionInitiator
    },
    {
      title: t('subscriptionsPage:latestPeriodLock'),
      key: 'periodLock',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        (dayjs(a?.latestPeriodLock || '').valueOf() || 0) - (dayjs(b?.latestPeriodLock || '').valueOf() || 0),
      render: (ds: DeliverySubscription) => {
        return ds?.latestPeriodLock && dayjs(ds.latestPeriodLock).format('MM/YYYY')
      }
    },
    {
      title: t('subscriptionsPage:lastDeliveryAt'),
      key: 'lastDeliveryAt',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        (dayjs(a?.lastDeliveryAt || '').valueOf() || 0) - (dayjs(b?.lastDeliveryAt || '').valueOf() || 0),
      defaultSortOrder: 'descend',
      render: (ds: DeliverySubscription) => {
        return ds.lastDeliveryAt && dayjs(ds.lastDeliveryAt).format('lll')
      }
    },
    {
      title: t('global:actions'),
      key: 'action',
      width: 180,
      align: 'right',
      render: (ds: DeliverySubscription) => (
        <Space size={0} split={<Divider type="vertical" style={{ margin: 4 }} />}>
          <Button size="small" type="text" icon={<HistoryOutlined />} onClick={() => toggleDeliveries(ds)} />
          <Button size="small" type="text" icon={<EditOutlined />} onClick={() => toggleDeliverySubscription(ds)} />
          <Button
            size="small"
            type="text"
            icon={<MailOutlined />}
            loading={loading[`sendDeliverySubscription${ds.id}`]}
            onClick={() => dispatch(sendDeliverySubscriptionRequest(contractId, ds.id))}
          />
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => dispatch(deleteDeliverySubscriptionRequest(contractId, ds.id))}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button size="small" type="text" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  if (
    subscriptionType === DeliverySubscriptionType.COMPANY_DASHBOARD ||
    subscriptionType === DeliverySubscriptionType.CUSTOM_REPORT
  ) {
    columns.splice(1, 0, {
      title: t('global:report'),
      key: 'reportName',
      dataIndex: 'reportName',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.reportName?.localeCompare(b?.reportName || '') || 0
    })
  }

  return (
    <LoadingWrapper loading={false}>
      <Deliveries
        deliverySubscription={deliverySubscription}
        setDeliverySubscription={setDeliverySubscription}
        setDeliveryModalVisibility={setDeliveryModalVisibility}
        visible={deliveryModalVisibility}
      />
      <div ref={contentEl}>
        <Table
          {...tableProps}
          dataSource={deliverySubscriptions}
          columns={columns}
          rowKey={record => record.id.toString()}
          pagination={false}
        />
      </div>
    </LoadingWrapper>
  )
}

export default DeliverySubscriptionTable
