import { TreeSelect } from 'antd'
import React, { ReactText, useContext, useEffect } from 'react'
import { Company } from '../../../../../types/company/Company'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface DimensionTreeSelectProps {
  category: CustomReportCategory
  companyId?: Company['id']
  path: string
}
export const DimensionTreeSelect: React.FC<DimensionTreeSelectProps> = ({
  path,
  category,
  companyId
}: DimensionTreeSelectProps) => {
  const { dimensionMap, dimensionLoading, editObject } = useContext(CategoryContext) as CategoryContextType
  const dimensionTree = !!dimensionMap && !!companyId ? dimensionMap[companyId] : undefined

  const selectDefaultDimension = () => {
    if (dimensionTree?.[0] && !category.value) {
      editObject(path, { ...category, value: dimensionTree[0].dimensionId })
    }
  }

  useEffect(selectDefaultDimension, [dimensionTree])

  const renderTreeSelectNodes = (dimensions: Dimension[]) => {
    return dimensions.map(dimension => {
      return (
        <TreeSelect.TreeNode value={dimension.dimensionId} title={dimension.name} key={dimension.dimensionId}>
          {dimension.children && renderTreeSelectNodes(dimension.children)}
        </TreeSelect.TreeNode>
      )
    })
  }

  return (
    <TreeSelect
      loading={dimensionLoading}
      disabled={!dimensionTree}
      style={{ width: 200 }}
      showSearch
      value={category?.value?.toString()}
      filterTreeNode={(inputval, node) => {
        return node && node.props.title.toLowerCase().includes(inputval.toLowerCase())
      }}
      onChange={(value: ReactText) => editObject(path, { ...category, value })}
    >
      {dimensionTree && renderTreeSelectNodes(dimensionTree)}
    </TreeSelect>
  )
}
