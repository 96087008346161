/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react'
import { Card, Typography, List, Divider, Tabs, Button, Tag, Tooltip } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ProCard } from '@ant-design/pro-components'
import { comparableKeyFiguresSelector } from '../../redux/entities/keyFigures/selectors'
import IndustryForm, { IndustryFormValues } from './components/IndustryForm'
import KeyFigureSelection from '../../components/KeyFigure/KeyFigureSelection'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import { useBackend } from '../../services/backend'
import VatIdForm from './components/VatIdForm'
import { getCompetitorComparisonData } from '../../redux/context/competitorComparison/actions'
import { competitorComparisonSelector } from '../../redux/context/competitorComparison/selectors'
import CompetitorChart from './components/Chart'
import { IndustryCompany } from '../../types/industry/Industry'
import { generateCompareChartSeries, generateCompareChartCategories } from './utils'
import { industryAndCompetitorComparisonPageSelector } from '../../redux/pages/industryAndCompetitorComparison/selectors'
import {
  setIndustryCompetitorKeyfigureSelection,
  toggleCompetitorSelection,
  setCompetitorSelections
} from '../../redux/pages/industryAndCompetitorComparison/actions'
import { KeyFigure } from '../../types/keyFigure/KeyFigure'
import { AppDispatch } from '../../redux/store'

const CompetitorComparison: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const contextCompany = useSelector(contextCompanySelector)
  const keyFigures = useSelector(comparableKeyFiguresSelector)
  const { companyData, comparisonData } = useSelector(competitorComparisonSelector)
  const { selectedKeyfigures, selectedCompetitors } = useSelector(industryAndCompetitorComparisonPageSelector)
  const [companySearchResults, setCompanySearchResults] = useState<IndustryCompany[]>([])
  const [industryId, setIndustryId] = useState<IndustryFormValues['industryId']>(contextCompany!.industryId.toString())
  const [revenueFrom, setRevenueFrom] = useState<IndustryFormValues['revenueFrom']>()
  const [revenueTo, setRevenueTo] = useState<IndustryFormValues['revenueTo']>()
  const industryCompanySearchRequest = useBackend('/api/companies')

  const MAX_COMPANIES = 5

  const setResults = (value: IndustryCompany[]) => {
    setCompanySearchResults(value || [])
  }

  const handleCompetitorDataRequest = () => {
    if (selectedCompetitors.length > 0) {
      const ids = selectedCompetitors.map((company: IndustryCompany) => ({
        id: company.id
      }))
      contextCompany && dispatch(getCompetitorComparisonData(ids, contextCompany.id))
    }
  }

  const handleCompanySearchRequest = () => {
    industryCompanySearchRequest
      .get({
        body: {
          params: {
            revenueFrom,
            revenueTo,
            industryId
          }
        }
      })
      .then((response: IndustryCompany[]) => {
        setResults(response)
      })
  }

  useEffect(handleCompanySearchRequest, [contextCompany, revenueFrom, revenueTo, industryId])

  useEffect(handleCompetitorDataRequest, [selectedCompetitors])

  const onChange = (keyFigureSelections: number[]): void => {
    dispatch(setIndustryCompetitorKeyfigureSelection(keyFigureSelections as number[]))
  }

  const toggleSelectedCompany = (company: IndustryCompany): void => {
    dispatch(toggleCompetitorSelection(company))
  }

  const handleTagClose = (removedTag: IndustryCompany) => {
    dispatch(toggleCompetitorSelection(removedTag))
  }

  const handleIndustryFormSubmit = ({ industryId, revenueFrom, revenueTo }: IndustryFormValues) => {
    setIndustryId(industryId)
    setRevenueFrom(revenueFrom)
    setRevenueTo(revenueTo)
  }

  const companyTagRow = () => {
    return selectedCompetitors.map((company: IndustryCompany) => {
      const maxLength = 30
      const isLongTag = company.name.length > maxLength
      const tagElem = (
        <Tag style={{ fontSize: 14 }} key={company.id} closable onClose={() => handleTagClose(company)}>
          {isLongTag ? `${company.name.slice(0, maxLength)}...` : company.name}
        </Tag>
      )
      return isLongTag ? (
        <Tooltip title={company.name} key={company.name}>
          {tagElem}
        </Tooltip>
      ) : (
        tagElem
      )
    })
  }

  const companySearchList = () => {
    return (
      (companySearchResults.length > 0 || industryCompanySearchRequest.loading) && (
        <List
          className="company-search-list"
          style={{ marginTop: '8px', maxHeight: '300px', overflowY: 'auto' }}
          loading={industryCompanySearchRequest.loading}
          itemLayout="horizontal"
          dataSource={companySearchResults}
          locale={{ emptyText: ' ' }}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  disabled={
                    selectedCompetitors &&
                    selectedCompetitors.every((c: IndustryCompany) => c.id !== item.id) &&
                    selectedCompetitors.length >= MAX_COMPANIES
                  }
                  style={{ marginRight: '-8px' }}
                  type="link"
                  onClick={() => toggleSelectedCompany(item)}
                >
                  {selectedCompetitors.some((c: IndustryCompany) => c.id === item.id)
                    ? t('global:delete')
                    : t('global:add')}
                </Button>
              ]}
            >
              {item.name}
            </List.Item>
          )}
        />
      )
    )
  }

  const tabItems = [
    {
      label: t('comparisonPage:industry'),
      key: 'item-1',
      children: (
        <IndustryForm
          submitText={t('global:search')}
          handleSubmit={handleIndustryFormSubmit}
          industryInitialValue={contextCompany && contextCompany.industryId}
        />
      )
    },
    {
      label: t('comparisonPage:vatid'),
      key: 'item-2',
      children: (
        <VatIdForm
          handleSuccess={(result?: IndustryCompany) => {
            setResults(result ? [result] : [])
          }}
        />
      )
    }
  ]

  return (
    <ProCard split="vertical">
      <ProCard colSpan="300px">
        <Typography.Title level={4}>{t('comparisonPage:competitor-search')}</Typography.Title>
        <Tabs defaultActiveKey="1" onChange={() => setResults([])} items={tabItems} />
        {companySearchList()}
        <Divider />
        <Typography.Title level={4}>{t('comparisonPage:comparison-targets')}</Typography.Title>
        <KeyFigureSelection
          keyFigureTranslation="keyFigureStatement"
          selectableKeyFigures={keyFigures}
          selectedKeyfigures={selectedKeyfigures}
          onChange={onChange}
        />
      </ProCard>
      <ProCard>
        {selectedCompetitors.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <div>
              <Typography.Title style={{ display: 'inline-block' }} level={4}>
                {t('comparisonPage:compared-companies')}
              </Typography.Title>
              <span style={{ marginLeft: 8 }}>{`${selectedCompetitors.length} / ${MAX_COMPANIES}`}</span>
              <Button type="link" onClick={() => dispatch(setCompetitorSelections([]))}>
                {t('comparisonPage:empty')}
              </Button>
            </div>
            {companyTagRow()}
          </div>
        )}
        <List
          locale={{ emptyText: t('comparisonPage:no-selected-comparison-targets') }}
          grid={{
            gutter: 16,
            lg: 1,
            xl: 2,
            xxl: 3
          }}
          dataSource={selectedKeyfigures.map((id: number) => _.keyBy(keyFigures, 'id')[id])}
          renderItem={(item: KeyFigure) => (
            <List.Item>
              <Card hoverable bordered title={t(`keyFigureStatement:${item.id}`)}>
                <CompetitorChart
                  categories={generateCompareChartCategories()}
                  series={
                    contextCompany &&
                    generateCompareChartSeries(
                      item.id,
                      contextCompany,
                      selectedCompetitors,
                      companyData || [],
                      comparisonData || []
                    )
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </ProCard>
    </ProCard>
  )
}

export default CompetitorComparison
