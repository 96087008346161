import _ from 'lodash'
import { ReportTableRow } from '../../../components/Table/types'
import { Balance } from '../../../redux/context/reports/types'
import { formatValueByType } from '../../../utils/helpers'

/** Taulu */
export const renderCell = (text: any) => {
  if (!text) return undefined
  const value = Math.round(+text)
  if (Math.sign(value) === 0) {
    return '0'
  }
  return formatValueByType(value)
}

/** Graafi */
export const formatValue = (b?: Balance) => {
  if (!b?.value) return 0

  const value = Math.round(b?.value)
  if (Math.sign(value) === 0) {
    return 0
  }
  return value
}

export const treeToList = (data?: ReportTableRow[]) => {
  const rowList: ReportTableRow[] = []
  if (data) {
    const traverse = (rows: ReportTableRow[]) => {
      for (const row of rows) {
        const rowData = _.clone(row)
        delete rowData.children
        rowList.push(rowData)
        if (row.children) traverse(row.children)
      }
    }

    traverse(data || [])
  }
  return rowList || []
}
