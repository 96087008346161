import React, { useState, useEffect } from 'react'
import { Button, Result } from 'antd'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { ConfigConsumer } from 'antd/es/config-provider'
import { MenuDataItem, ProLayout, ProLayoutProps, Settings } from '@ant-design/pro-components'
import styled from 'styled-components'
import { ContextDrawer } from '../components/ContextDrawer/ContextDrawer'
import AppFooter from '../components/GlobalFooter/AppFooter'
import ContextCompanySelect from '../components/GlobalHeader/ContextCompanySelect'
import HeaderContent from '../components/GlobalHeader/HeaderContent'
import { contextCompanyIdSelector } from '../redux/context/company/selectors'
import UserTour from '../components/Tour/Tour'
import TermsAndConditions from '../components/TermsConditions/TermsConditions'
import settings from '../config/layoutDefaultSettings'
import { sessionLayoutSelector } from '../redux/session/contract/selectors'
import Logo from '../components/Misc/Logo'
import { currentUserHomepageSelector } from '../redux/session/currentUser/selectors'
import HeaderRightMenu from '../components/GlobalHeader/HeaderRightMenu'
// import UpdateVersion from '../UpdateVersion'

export interface BasicLayoutProps extends ProLayoutProps {
  breadcrumbNameMap: {
    [path: string]: MenuDataItem
  }
  route: ProLayoutProps['route'] & {
    authority: string[]
  }
  settings: Settings
  dispatch: Dispatch
}

const StyledDiv = styled.div`
  display: inline-flex;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const BasicLayout: React.FC = (props: any) => {
  const { children, route } = props
  const location = useLocation()
  const companyId = useSelector(contextCompanyIdSelector)
  const homepage = useSelector(currentUserHomepageSelector)
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)
  const whiteLabel = useSelector(sessionLayoutSelector)

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }, [collapsed])

  const handleMenuCollapse = (payload: boolean): void => {
    setCollapsed(payload)
  }

  const menuItemStyle = {
    marginLeft: collapsed ? 12 : 0
  }

  return (
    <ProLayout
      pageTitleRender={({ pathname }) => {
        return `Finadeck - ${t(`menu:/${pathname?.split('/')[1]}`, 'App')}`
      }}
      contentStyle={{
        position: 'relative',
        minHeight: 'calc(100vh - 57px - 114px)'
      }}
      logo={whiteLabel ? <Logo type={whiteLabel} /> : null}
      title={whiteLabel ? <Logo wordmark type={whiteLabel} /> : null}
      headerTitleRender={(logo, title, _) => {
        const defaultDom = (
          <Link to={homepage} id="logo" style={{ color: 'unset' }}>
            <>
              {logo}
              {title}
            </>
          </Link>
        )
        if (document.body.clientWidth < 1400) {
          return defaultDom
        }
        if (_.isMobile) return defaultDom
        return (
          <>
            {defaultDom}
            {/* <MenuCard /> */}
          </>
        )
      }}
      route={route.routes}
      collapsed={collapsed}
      collapsedButtonRender={false}
      onCollapse={handleMenuCollapse}
      headerContentRender={() => {
        return (
          <StyledDiv>
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <HeaderContent routes={route.routes} />
          </StyledDiv>
        )
      }}
      rightContentRender={() => <HeaderRightMenu routes={route.routes} />}
      subMenuItemRender={menuItemProps => {
        if (menuItemProps.isUrl || menuItemProps.children) {
          return (
            <span style={menuItemStyle}>
              {menuItemProps?.icon}
              <span>{t(`menu:${menuItemProps.path}`)}</span>
            </span>
          )
        }
        return (
          <span style={menuItemStyle}>
            {menuItemProps?.icon}
            <span>{t(`menu:${menuItemProps.path}`)}</span>
          </span>
        )
      }}
      menuItemRender={menuItemProps => {
        if (menuItemProps?.isUrl || menuItemProps.children) {
          return (
            <span style={menuItemStyle}>
              {menuItemProps.icon}
              <span>{t(`menu:${menuItemProps.path}`, menuItemProps.name)}</span>
            </span>
          )
        }
        return (
          <ConfigConsumer>
            {({ theme: antTheme }) => (
              <Link
                to={String(menuItemProps.path)}
                style={{
                  ...menuItemStyle,
                  color: menuItemProps.path === location.pathname ? antTheme?.token?.colorPrimary : undefined
                }}
              >
                {menuItemProps?.icon}
                <span>{t(`menu:${menuItemProps.path}`, menuItemProps.name)}</span>
              </Link>
            )}
          </ConfigConsumer>
        )
      }}
      footerRender={!location.pathname.startsWith('/reporting/custom') && AppFooter}
      {...props}
      {...settings}
    >
      {companyId ||
      location.pathname.startsWith('/settings/user') ||
      location.pathname.startsWith('/settings/accountant') ||
      location.pathname.startsWith('/settings/financier') ||
      location.pathname.startsWith('/dashboard/accountant') ||
      location.pathname.startsWith('/dashboard/financier') ||
      location.pathname.startsWith('/deliveries') ? (
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      ) : (
        <Result title={t('global:set-context-company-text')} extra={<ContextCompanySelect />} />
      )}
      {/* <UpdateVersion /> */}
      <TermsAndConditions />
      <ContextDrawer routes={route.routes} />
      <UserTour />
    </ProLayout>
  )
}

export default BasicLayout
