import { green, red } from '@ant-design/colors'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import React from 'react'
import { CustomReportVariableStyle } from '../../redux/context/customReports/typesVariable'

interface ArrowProps {
  value: number | string
  thresholdValueSetting?: CustomReportVariableStyle['thresholdValueSetting']
}

const Arrow: React.FC<ArrowProps> = ({ value, thresholdValueSetting }) => {
  if (!thresholdValueSetting) return null
  const tresholdOperators = {
    biggerBetter: (a: number) => a > 0,
    smallerBetter: (a: number) => a < 0,
    unset: null
  }

  function getArrow() {
    const func = tresholdOperators[thresholdValueSetting || 'unset']
    if (func && typeof value === 'number') {
      return func(+value) ? (
        <ArrowUpOutlined style={{ color: green[5], marginRight: 5 }} />
      ) : (
        <ArrowDownOutlined style={{ color: red[5], marginRight: 5 }} />
      )
    }
    return null
  }

  return <span>{getArrow()}</span>
}

export default Arrow
