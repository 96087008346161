import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loading: React.FC = () => {
  const icon = <LoadingOutlined style={{ fontSize: 48 }} spin />
  return (
    <LoadingContainer>
      <Spin indicator={icon} />
    </LoadingContainer>
  )
}

export default Loading
