import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useBackend } from '../../../services/backend'
import { IndustryCompany } from '../../../types/industry/Industry'

interface VatIdFormProps {
  handleSuccess: (result?: IndustryCompany) => void
}

const VatIdForm = ({ handleSuccess }: VatIdFormProps) => {
  const { t } = useTranslation()
  const companySearchRequest = useBackend('/api/companies')

  const handleVatIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const regex = /([0-9]{7}-[0-9]{1})|([0-9]{8})|(FI[0-9]{8})/g
    // If is valid vatId format check if found from database
    const valid = value ? value.match(regex) : false

    if (valid) {
      companySearchRequest
        .get({ body: { params: { vatId: value } } })
        .then((result: IndustryCompany[]) => {
          handleSuccess(result[0])
        })
        .catch(() => {
          handleSuccess()
        })
    }
  }

  return (
    <Form layout="horizontal">
      <Form.Item
        name="vatId"
        validateStatus={companySearchRequest.loading ? 'validating' : undefined}
        label={t('comparisonPage:vatid')}
      >
        <Input onChange={handleVatIdChange} allowClear />
      </Form.Item>
    </Form>
  )
}

export default VatIdForm
