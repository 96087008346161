import { Button, Table, Typography, Input, Divider, Form } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dotProp from 'dot-prop'
import { ownersAndExecutiveBoardSelector } from '../../../redux/context/companyInformation/selectors'
import GroupMemberFactory, { GroupMember, MemberType } from '../factories/GroupMemberFactory'
import { updateCompanyInformationRequest } from '../../../redux/context/companyInformation/actions'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { AppDispatch } from '../../../redux/store'

const EditableContext = React.createContext<any>(null)

interface EditableCellProps {
  title: React.ReactNode
  editing: boolean
  children: React.ReactNode
  dataIndex: string
  index: number
  inputType: string
  record: any
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <FormattedInputNumber max={100} min={0} /> : <Input />

  return (
    <EditableContext.Consumer>
      {() => (
        <td {...restProps}>
          {editing && record ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`
                }
              ]}
              initialValue={record[dataIndex]}
              style={{ margin: 0 }}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      )}
    </EditableContext.Consumer>
  )
}

interface EditableGroupTableFormProps {
  data: [string, MemberType]
  path: string[]
}

const Group = ({ data, path }: EditableGroupTableFormProps) => {
  const [form] = Form.useForm()
  const [group, member] = data
  const { t } = useTranslation()
  const stakeholders = useSelector(ownersAndExecutiveBoardSelector)
  const dataSource = path.reduce((value: any, key: string) => value[key], stakeholders)
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const instance = GroupMemberFactory.createInstance(member)
  const [members, setMembers] = useState<GroupMember[]>([])
  const [editingKey, setEditingKey] = useState('')
  const [memberCount, setMemberCount] = useState(0)

  const updateMembers = () => {
    setMembers(
      dataSource?.map((value: GroupMember, index: number) =>
        GroupMemberFactory.createInstance(member, { ...value, key: index })
      ) || []
    )
    setMemberCount(members.length)
  }

  useEffect(updateMembers, [dataSource])

  const handleSave = (newMembers: GroupMember[]) => {
    const newData = dotProp.set(stakeholders, path.join('.'), newMembers)
    companyId && dispatch(updateCompanyInformationRequest(companyId, newData))
    setMembers(newMembers)
    setEditingKey('')
    form.resetFields()
  }

  const handleDelete = (key: string) => {
    const newMembers = members.filter(m => m.key !== key)
    handleSave(newMembers)
  }

  const handleAdd = () => {
    const newMember = GroupMemberFactory.createInstance(member, { key: memberCount.toString() })
    if (!newMember) return

    setMembers([...members, newMember])
    setEditingKey(newMember.key)
    setMemberCount(memberCount + 1)
  }

  const isEditing = (record: GroupMember) => {
    return record.key === editingKey
  }

  const saveRow = (contextForm: any, key: string) => {
    contextForm.validateFields().then((row: GroupMember) => {
      const newMembers = [...members]
      const index = newMembers.findIndex(item => key === item.key)
      if (index > -1) {
        const item = newMembers[index]
        newMembers.splice(index, 1, {
          ...item,
          ...row
        })
        handleSave(newMembers)
      } else {
        handleSave([...newMembers, row])
      }
    })
  }

  const cancel = () => {
    setEditingKey('')
  }

  const edit = (key: string) => {
    setEditingKey(key)
  }

  const generateTableColumns = () => {
    const propertyColumns = Object.keys(instance || {})
      .filter(property => property !== 'key')
      .map(property => ({
        title: t(`global:${property}`),
        dataIndex: property,
        editable: true,
        width: 200,
        onCell: (record: GroupMember) => {
          return {
            record,
            inputType: 'text',
            dataIndex: property,
            title: property,
            editing: isEditing(record)
          }
        }
      }))

    return [
      ...propertyColumns,
      {
        title: t('global:actions'),
        dataIndex: 'actions',
        align: 'right',
        render: (text, record) => {
          const editable = isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {contextForm => (
                  <Button type="link" onClick={() => saveRow(contextForm, record.key)} style={{ marginRight: 8 }}>
                    {t('global:save')}
                  </Button>
                )}
              </EditableContext.Consumer>
              <Button onClick={cancel} type="link">
                {t('global:cancel')}
              </Button>
            </span>
          ) : (
            <span>
              <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record.key)}>
                {t('global:edit')}
              </Button>
              <Button type="link" disabled={editingKey !== ''} onClick={() => handleDelete(record.key)}>
                {t('global:delete')}
              </Button>
            </span>
          )
        }
      }
    ] as ColumnProps<GroupMember>[]
  }

  const columns = generateTableColumns()

  const components = {
    body: {
      cell: EditableCell
    }
  }

  return (
    <div key={group}>
      <Typography.Title level={4}>{t(`companyInformation:${group}`, { count: 2 })}</Typography.Title>
      {path.length === 1 && <Divider />}
      <Button style={{ marginBottom: 16 }} onClick={() => handleAdd()}>
        {`${t('global:add')} ${t(`companyInformation:${group}`, { count: 1 }).toLowerCase()}`}
      </Button>
      <Form form={form}>
        <EditableContext.Provider value={form}>
          <Table
            pagination={false}
            components={components}
            size="middle"
            rowClassName={() => 'editable-row'}
            bordered
            rowKey={record => record.key}
            dataSource={members}
            columns={columns}
            style={{ marginBottom: 32 }}
          />
        </EditableContext.Provider>
      </Form>
    </div>
  )
}

const EditableGroupTable = Group

export default EditableGroupTable
