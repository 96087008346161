import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabsProps } from 'antd/es/tabs'
import { PageContainer, PageContainerProps, RouteContext } from '@ant-design/pro-components'
import { DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core'
import { SortableContext, horizontalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { Tab } from '../../types/tab/tab'
import { DraggableTabNode } from './DndTabs'

interface PageContainerWrapperProps extends Omit<PageContainerProps, 'breadcrumb'> {
  breadcrumb?: boolean
  onOrderChange?: (order: string[]) => void
}

function PageHeaderWrapper({
  children,
  breadcrumb,
  title,
  tabList,
  tabProps,
  onOrderChange,
  ...restProps
}: PageContainerWrapperProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const value = useContext(RouteContext)
  const { pathname } = location
  const [className, setClassName] = useState('')
  const [tabItems, setTabItems] = useState<Tab[]>([])
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 }
  })

  useEffect(() => {
    setTabItems(tabList as Tab[])
  }, [tabList])

  const getBreadcrumbItems = (fullpath: string): ItemType[] => {
    let localeString = 'menu:'
    const urls = fullpath
      .split('/')
      .slice(1)
      .filter(str => {
        return !parseInt(str, 10)
      })
      .map((path: string) => {
        localeString += `/${path}`
        return { title: t(localeString) }
      })
    return urls
  }

  const orderTabs = (tabs: Tab[], { active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = tabs.findIndex(i => i.key === active.id)
      const overIndex = tabs.findIndex(i => i.key === over?.id)
      return arrayMove(tabs, activeIndex, overIndex)
    }
    return tabs
  }

  const onDragEnd = (dragEvent: DragEndEvent) => {
    const newOrder = orderTabs(tabItems, dragEvent)
    const orderList = newOrder.map(({ key }) => key)
    setTabItems(newOrder)
    onOrderChange!(orderList)
  }

  const renderDragBar: TabsProps['renderTabBar'] = (tabBarProps, DefaultTabBar) => (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={tabItems.map(i => i.key)} strategy={horizontalListSortingStrategy}>
        <DefaultTabBar {...tabBarProps}>
          {node => (
            <DraggableTabNode {...node.props} key={node.key} onActiveBarTransform={setClassName}>
              {node}
            </DraggableTabNode>
          )}
        </DefaultTabBar>
      </SortableContext>
    </DndContext>
  )

  return (
    <PageContainer
      {...restProps}
      token={{
        paddingBlockPageContainerContent: 18,
        paddingInlinePageContainerContent: 18
      }}
      tabList={tabItems}
      title={title ?? value?.title}
      onBack={() => history.goBack()}
      breadcrumb={breadcrumb ? { items: getBreadcrumbItems(pathname) } : undefined}
      tabProps={{
        className,
        destroyInactiveTabPane: true,
        ...tabProps,
        renderTabBar: tabItems && onOrderChange ? renderDragBar : undefined
      }}
    >
      {children}
    </PageContainer>
  )
}

export default PageHeaderWrapper
