import { Form, Input, Space, Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BudgetComment } from './BudgetCommentItem'

interface BudgetCommentFormProps {
  comment?: BudgetComment
  handleSubmit: (comment: BudgetComment) => void
  handleCancel?: () => void
}

const BudgetCommentForm: React.FC<BudgetCommentFormProps> = ({ comment, handleSubmit, handleCancel }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<BudgetComment>()

  return (
    <Form
      form={form}
      initialValues={comment}
      onFinish={values => {
        handleSubmit(values)

        form.resetFields()
      }}
      onFinishFailed={console.log}
      autoComplete="off"
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item name="text" rules={[{ required: true, message: t('global:add-comment') }]}>
        <Input.TextArea autoSize />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" size="small">
            {comment?.id ? t('global:edit') : t('global:make-comment')}
          </Button>
          {handleCancel && (
            <Button htmlType="button" onClick={handleCancel} size="small">
              {t('global:cancel')}
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default BudgetCommentForm
