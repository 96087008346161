import React, { useMemo, useState } from 'react'
import VirtualList from 'rc-virtual-list'
import { List, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { ArgumentOption } from '../types'

const ArgumentOptionList: React.FC<any> = ({ options, height, addArgument }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const filteredData = useMemo(() => {
    if (!search) return options

    return options.filter((item: ArgumentOption) => {
      return item.name.toLowerCase().includes(search.toLowerCase())
    })
  }, [search, options])

  return (
    <div>
      <Input
        placeholder={t('global:search')}
        onChange={e => setSearch(e.target.value)}
        style={{ margin: '1rem 0px' }}
      />
      <List size="small">
        <VirtualList data={filteredData} height={options.length > 0 ? height : 40} itemHeight={41} itemKey="value">
          {item => (
            <List.Item
              className="virtual-list-item"
              key={item.value}
              actions={[
                <Button size="small" type="link" onClick={() => addArgument(item.value.toString())}>
                  {t('global:add')}
                </Button>
              ]}
            >
              {item.name}
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  )
}

export default ArgumentOptionList
