import React from 'react'
import VehicleBasedIntegrations from '../vehicleBasedIntegrations/VehicleBasedIntegrations'

const Logisystems: React.FC = () => {
  return (
    <VehicleBasedIntegrations
      allowAuthentication={false}
      credentialsRequestUrl="/logisystems/authentication/{companyId}"
      getVehiclesUrl="/logisystems/vehicles/{companyId}"
      updateVehicleUrl="/logisystems/vehicles/{companyId}/{id}"
      pageDocsUrl="manual/businesses/settings/company#logisystems-kuljetusvelho"
      name="logisystems"
    />
  )
}

export default Logisystems
