import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { contextRequest } from '../../../../../redux/context/actions'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { getFinancialStatementReallocationsRequest } from '../../../../../redux/pages/budgeting/financialStatements/actions'
import { AppDispatch } from '../../../../../redux/store'

interface ReallocationMenuProps {
  reportTableRow: ReportTableRow
  expandRows: any
}

const ReallocationMenu: React.FC<ReallocationMenuProps> = ({ reportTableRow, expandRows }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const loading = useSelector(loadingSelector)

  return (
    <div>
      <>
        <span className="ant-table-row-indent indent-level-1" style={{ paddingLeft: 8 }} />
        {!reportTableRow.children && !loading[`financialStatementReallocations${reportTableRow.code}`] && (
          <button
            className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
            aria-label="Collapse row"
            type="button"
            onClick={() => {
              dispatch(
                contextRequest(getFinancialStatementReallocationsRequest, {
                  accountCode: reportTableRow.code
                })
              )
              expandRows(true, reportTableRow)
            }}
          />
        )}
        {loading[`financialStatementReallocations${reportTableRow.code}`] && (
          <LoadingOutlined style={{ marginTop: 2.5, marginInlineEnd: 4, marginInlineStart: 2 }} />
        )}
      </>
      <span>{t('menu:/settings/company/reallocation')}</span>
    </div>
  )
}

export default ReallocationMenu
