import { GetRequestAction, REQUEST, GET } from '../../middleware/types'
import { LiabilityMapping, SetLiabilytyMappingAction, LIABILITY_MAPPING } from './types'

export const setLiabilityMappingConfig = (liabilityMapping: LiabilityMapping): SetLiabilytyMappingAction => ({
  type: LIABILITY_MAPPING,
  payload: liabilityMapping
})

export const getBudgetingLiabilityMappingRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: '/api/config/liability-mapping',
    success: setLiabilityMappingConfig
  },
  meta: {
    type: 'LIABILITY_MAPPING'
  }
})
