import dayjs from 'dayjs'
import { Table, TableColumnsType } from 'antd'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useColumnSearchProps } from '../../../../components/Table/useColumSearchProps'
import { User } from '../../../../types/user/User'
import { useTableProps } from '../../../../utils/hooks'

interface SuperUserUserTableProps {
  users: User[]
}

const SuperUserUserTable: React.FC<SuperUserUserTableProps> = ({ users }: SuperUserUserTableProps) => {
  const { t } = useTranslation()
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl, 900)

  const columns: TableColumnsType<User> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Sopimus',
      key: 'contractId',
      dataIndex: 'contractId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.contractId - b.contractId,
      render: (id: number) => {
        return <Link to={`/superuser/contracts/show/${id}`}>{id}</Link>
      }
    },
    {
      title: 'Etunimi',
      key: 'firstName',
      dataIndex: 'firstName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.firstName || '').localeCompare(b?.firstName || ''),
      ...useColumnSearchProps('firstName')
    },
    {
      title: 'Sukunimi',
      key: 'lastName',
      dataIndex: 'lastName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.lastName || '').localeCompare(b?.lastName || ''),
      ...useColumnSearchProps('lastName')
    },
    {
      title: 'Sähköposti',
      key: 'email',
      dataIndex: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.email.localeCompare(b?.email || ''),
      ...useColumnSearchProps('email')
    },
    {
      title: 'Puhelin',
      key: 'phone',
      dataIndex: 'phone',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.phone || '').localeCompare(b?.phone || '')
    },
    {
      title: 'Asema',
      key: 'position',
      dataIndex: 'position',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.position || '').localeCompare(b?.position || '')
    },
    {
      title: 'Rooli',
      key: 'role',
      dataIndex: ['role', 'label'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.role?.label || '').localeCompare(b?.role?.label || ''),
      render: (label: string) => t(`usersPage:${label}`)
    },
    {
      title: 'Viimesin kirjautuminen',
      key: 'lastLoginAt',
      dataIndex: 'lastLoginAt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.lastLoginAt || '').localeCompare(b?.lastLoginAt || ''),
      render: (lastLoginAt: string) => {
        return lastLoginAt && dayjs(lastLoginAt).format('lll')
      }
    },
    {
      title: 'Toiminnot',
      key: 'actions',
      fixed: 'right',
      render: ({ id }: User) => {
        return <Link to={`/superuser/users/show/${id}`}>Avaa</Link>
      }
    }
  ]

  return (
    <div ref={contentEl}>
      <Table
        {...tableProps}
        rowKey={record => record.id.toString()}
        size="small"
        dataSource={users || []}
        columns={columns}
      />
    </div>
  )
}

export default SuperUserUserTable
