import { InvestmentFundingSource } from '../../../types/investment/InvestmentFundingSource'
import { SetInvestmentFundingSources, SET_INVESTMENT_FUNDING_SOURCES } from './types'

const initialState: InvestmentFundingSource[] = []

const investmentFundingSourceReducer = (state = initialState, action: SetInvestmentFundingSources) => {
  switch (action.type) {
    case SET_INVESTMENT_FUNDING_SOURCES:
      return action.payload
    default:
      return state
  }
}

export default investmentFundingSourceReducer
