import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Button, FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import { Formula } from '../../../../../../types/formula/Formula'
import FormulaHelp from './FormulaHelp'
import { transformFieldData } from '../../../../../../utils/form'
import FormulaForm from './FormulaForm'
import { formulaByIdSelector } from '../../../../../../redux/context/formulas/selectors'

interface FormulaModalProps {
  initialValues?: Formula
  formulaError?: any
  loading?: boolean
  visible: boolean
  handleSubmit: (values: any) => Promise<any>
  handleCancel: () => void
}

const FormulaFormModal: React.FC<FormulaModalProps> = ({
  initialValues,
  loading,
  visible,
  formulaError,
  handleSubmit,
  handleCancel
}) => {
  const [formInstance, setFormInstance] = useState<FormInstance>()
  const { t } = useTranslation()
  const formula = useSelector(formulaByIdSelector(initialValues?.id))

  const config = {
    fontWeight: (value: any) => {
      return value && value === 'bold'
    }
  }

  return (
    <Modal
      style={{ maxWidth: 800 }}
      width="unset"
      open={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={() => {
        handleCancel()
      }}
      title={t('formulaPage:formulaEditor')}
      footer={[
        <div key="help" style={{ float: 'left' }}>
          <FormulaHelp />
        </div>,
        <Button
          key="back"
          onClick={() => {
            formInstance?.resetFields()
            handleCancel()
          }}
        >
          {t('global:cancel')}
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={async () => {
            try {
              const values = await formInstance?.validateFields()
              await handleSubmit(values)

              formInstance?.resetFields()
            } catch (error) {
              console.log('Validate Failed:', error)
            }
          }}
        >
          {t('global:save')}
        </Button>
      ]}
    >
      <FormulaForm
        formulaError={formulaError}
        initialValues={transformFieldData(formula, config)}
        onFormInstanceReady={instance => {
          setFormInstance(instance)
        }}
      />
    </Modal>
  )
}

export default FormulaFormModal
