import { ReportTableRow } from '../../../components/Table/types'
import {
  EmptySelectedRowDimensionKeysAndDataAction,
  EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA,
  SetAllDimensionsRowDataAction,
  SetSelectedRowDimensionKeyAction,
  SET_ALL_DIMENSIONS_ROW_DATA,
  SET_SELECTED_ROW_DIMENSION_KEY
} from './types'

export const emptySelectedRowDimensionKeysAndData = (): EmptySelectedRowDimensionKeysAndDataAction => ({
  type: EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA
})

export const setSelectedRowDimensionKey = (row: ReportTableRow): SetSelectedRowDimensionKeyAction => ({
  type: SET_SELECTED_ROW_DIMENSION_KEY,
  payload: row
})

export const setAllDimensionsRowData = (data: {
  key: string
  data: Partial<ReportTableRow>[]
}): SetAllDimensionsRowDataAction => ({
  type: SET_ALL_DIMENSIONS_ROW_DATA,
  payload: data
})
