import { DownloadOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { arrayDownload, downloadMenuItems } from '../../../../../utils/tableExport'
import { SheetRow } from './ExchangeRateSheet'

interface ExchangeRateDownloadButtonProps {
  data: SheetRow[]
}

const ExchangeRateDownloadButton: React.FC<ExchangeRateDownloadButtonProps> = ({ data }) => {
  const { t } = useTranslation()

  const handleMenuClick = ({ key }: any) => {
    const aoa = data.map(row => Object.values(row))
    switch (key) {
      case 'csv':
      case 'xlsx':
        arrayDownload(aoa, t('menu:/settings/company/group/exchangeRate'), key)
        break
      default:
        break
    }
  }

  const menuProps = {
    items: downloadMenuItems,
    onClick: handleMenuClick
  }

  return (
    <Dropdown menu={menuProps}>
      <Button icon={<DownloadOutlined />} />
    </Dropdown>
  )
}

export default ExchangeRateDownloadButton
