import { ActualRowType } from '../../../components/Table/hooks'
import { RowBudgetingMethod, VariableType } from '../../../components/Table/types'
import { BudgetComment } from '../../../pages/budgeting/financialStatements/components/comment/BudgetCommentItem'
import { Amendment } from '../../../types/amendment/Amendment'
import { Company } from '../../../types/company/Company'
import { Dimension } from '../../../types/dimension/Dimension'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { Investment } from '../../../types/investment/Investment'
import { InvestmentFunding } from '../../../types/investment/InvestmentFunding'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { RecordType } from '../../../types/sales/sales'
import { CustomReportVariable } from '../customReports/typesVariable'

export enum ReportType {
  keyFigureStatement = 'KEY_FIGURE_STATEMENT',
  internalKeyFigureStatement = 'INTERNAL_KEY_FIGURE_STATEMENT',
  incomeStatement = 'INCOME_STATEMENT',
  cashFlowStatement = 'CASH_FLOW_STATEMENT',
  balanceSheet = 'BALANCE_SHEET',
  salesStatement = 'SALES_STATEMENT'
}

export enum ReportDataType {
  actuals = 'actuals',
  comparativePeriod = 'comparativePeriod',
  budget = 'budget',
  forecast = 'forecast'
}

export enum ReportDataTypeCumulatives {
  cumulative = 'cumulative',
  cumulativeBudget = 'cumulativeBudget',
  cumulativeForecast = 'cumulativeForecast',
  cumulativeComparativePeriod = 'cumulativeComparativePeriod',
  cumulativeDifference = 'cumulativeDifference',
  cumulativeComparativeDifferencePeriod = 'cumulativeComparativeDifferencePeriod'
}

export enum BalanceError {
  unauthorized = 'unauthorized',
  dataTypeNotSupported = 'dataTypeNotSupported',
  sourceNotSupported = 'sourceNotSupported',
  dimensionNotFound = 'dimensionNotFound'
}
export interface Balance {
  fiscalYearId?: FiscalYear['id']
  year?: number
  month?: number
  groupId?: number | string
  type?: string
  amount?: string
  value: number | undefined
  errors?: BalanceError[]
}

export enum ValuationModel {
  NAV = 'NAV', // Net Asset Value -> Substanssiarvo
  PM = 'PM', // Profit Multiplier -> Tuottoarvo
  EBITDAF = 'EBITDAF', // Käyttökatekerroin
  DCF = 'DCF' // Discounted Cash Flow Method -> Diskontattu kassavirtamalli
}

export interface Valuation {
  [key: string]: {
    [key: string]: Balance[]
  }
}

export interface Invoices {
  sales?: SalesInvoices
  purchases?: PurchaseInvoices
}

export interface InvoiceValue {
  value: number
}

export interface BusinessPartner {
  id: number
  name: string
  vatId?: string
  active: boolean
  sales: InvoiceValue
  purchases: InvoiceValue
}

export interface Product {
  id: number
  name: string
  price: number
  unit: string
  currency: string
  active: boolean
  sales: InvoiceValue
  purchases: InvoiceValue
}

export interface SalesInvoices {
  customers: BusinessPartner[]
  products: Product[]
}

export interface PurchaseInvoices {
  suppliers: BusinessPartner[]
  products: Product[]
}

export enum ReportRowType {
  reallocationMenu = 'reallocationMenu',
  reallocation = 'reallocation',
  budgetMenu = 'budgetMenu',
  budgetableRow = 'budgetableRow',
  investmentMenu = 'investmentMenu',
  investment = 'investment',
  investmentFundingMenu = 'investmentFundingMenu',
  investmentFunding = 'investmentFunding',
  amendmentMenu = 'amendmentMenu',
  amendment = 'amendment',
  amendmentCounterPart = 'amendmentCounterPart',
  amendmentCounterPartMenu = 'amendmentCounterPartMenu',
  account = 'account',
  dimensionsButton = 'dimensionsButton',
  dimension = 'dimension',
  statementRow = 'statementRow'
}

export interface ReportRow {
  [key: string]: any
  key: string
  id: number
  code: number | string
  name: string
  bold?: string // cash flow
  style?: any
  isParent?: boolean // financial statement
  type: VariableType // tässä on variable type
  report?: ReportType
  rowType: ReportRowType
  accounts?: ReportRow[]
  children?: ReportRow[]
  actuals?: Balance[]
  forecast?: Balance[]
  budget?: Balance[]
  budgets?: Balance[]
  budgetComments?: BudgetComment[]
  investments?: Investment[]
  amendments?: Amendment[]
  amendmentCounterParts?: Amendment[]
  fundings?: InvestmentFunding[]
  budgetingMethod?: RowBudgetingMethod
  depreciable?: boolean
  actualRowType?: ActualRowType
}

export type ReportResponse<T> = ReportResponseItem<T>[]

export interface ReportResponseItem<T> {
  companyId: Company['id']
  dimensionId?: Dimension['dimensionId']
  data: T
}

export declare type Report = ReportRow[]

export enum CashFlowStatementRepresentationType {
  KASSAVIRTALASKELMA_INDIRECT = 'KASSAVIRTALASKELMA_INDIRECT',
  RAHOITUSLASKELMA_INDIRECT = 'RAHOITUSLASKELMA_INDIRECT'
}

export declare type Sales = {
  [RecordType.products]: Report
  [RecordType.productGroups]: Report
  [RecordType.customerGroups]: Report
  [RecordType.customers]: Report
}

export declare type FinancialStatement = {
  incomeStatement: Report
  assets: Report
  liabilities: Report
}

export declare type CashFlowStatement = {
  cashFlowStatementRepresentationType: CashFlowStatementRepresentationType
  statementRows: Report
}[]

export interface Reports {
  financialStatement: FinancialStatement
  keyFigureStatement?: Report
  sales: Sales
  internalKeyFigureStatement?: Report
  keyPerformanceIndicatorStatement?: Report
  cashFlowStatement?: Report
  valuation?: Valuation
  invoices?: Invoices
}

export const SET_FINANCIAL_STATEMENT = 'SET_FINANCIAL_STATEMENT'
export const SET_KEY_FIGURE_STATEMENT = 'SET_KEY_FIGURE_STATEMENT'
export const ADD_KEY_FIGURE_EXPLAINER = 'ADD_KEY_FIGURE_EXPLAINER'
export const ADD_CASH_FLOW_EXPLAINER = 'ADD_CASH_FLOW_EXPLAINER'
export const SET_INTERNAL_KEY_FIGURE_STATEMENT = 'SET_INTERNAL_KEY_FIGURE_STATEMENT'
export const ADD_INTERNAL_KEY_FIGURE_STATEMENT = 'ADD_INTERNAL_KEY_FIGURE_STATEMENT'
export const SET_KEY_PERFORMANCE_INDICATOR_STATEMENT = 'SET_KEY_PERFORMANCE_INDICATOR_STATEMENT'
export const SET_CASH_FLOW_STATEMENT = 'SET_CASH_FLOW_STATEMENT'
export const SET_VALUATION = 'SET_VALUATION'
export const SET_SALES_INVOICES = 'SET_SALES_INVOICES'
export const SET_PURCHASE_INVOICES = 'SET_PURCHASE_INVOICES'
export const SET_SALES_PRODUCTS = 'SET_SALES_PRODUCTS'
export const SET_SALES_PRODUCT_GROUPS = 'SET_SALES_PRODUCT_GROUPS'
export const SET_SALES_CUSTOMER_GROUPS = 'SET_SALES_CUSTOMER_GROUPS'
export const SET_SALES_CUSTOMERS = 'SET_SALES_CUSTOMERS'
export const RESET_REPORTS = 'RESET_REPORTS'
export const ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER = 'ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER'

export interface SetFinancialStatementAction {
  type: typeof SET_FINANCIAL_STATEMENT
  payload: FinancialStatement
}

export interface SetKeyFigureStatementAction {
  type: typeof SET_KEY_FIGURE_STATEMENT
  payload: Report
}

export interface AddKeyFigureExplainerAction {
  type: typeof ADD_KEY_FIGURE_EXPLAINER
  payload: { explainer: CustomReportVariable[]; keyFigure: KeyFigure }
}

export interface AddCashFlowExplainerAction {
  type: typeof ADD_CASH_FLOW_EXPLAINER
  payload: { explainer: ReportRow[]; keyFigure: KeyFigure }
}

export interface SetInternalKeyFigureStatementAction {
  type: typeof SET_INTERNAL_KEY_FIGURE_STATEMENT
  payload: Report
}
export interface AddInternalKeyFigureStatementAction {
  type: typeof ADD_INTERNAL_KEY_FIGURE_STATEMENT
  payload: Report
}

export interface AddInternalKeyfigureVariableExplainerAction {
  type: typeof ADD_INTERNAL_KEYFIGURE_VARIABLE_EXPLAINER
  payload: { explainer: ReportRow[]; variable: ReportRow }
}

export interface SetKeyPerformanceIndicatorStatementAction {
  type: typeof SET_KEY_PERFORMANCE_INDICATOR_STATEMENT
  payload: Report
}

export interface SetCashFlowStatementAction {
  type: typeof SET_CASH_FLOW_STATEMENT
  payload: Report
}

export interface SetValuationAction {
  type: typeof SET_VALUATION
  payload: Valuation
}

export interface SetSalesInvoicesAction {
  type: typeof SET_SALES_INVOICES
  payload: SalesInvoices
}

export interface SetPurchaseInvoicesAction {
  type: typeof SET_PURCHASE_INVOICES
  payload: PurchaseInvoices
}

export interface SetSalesProductsAction {
  type: typeof SET_SALES_PRODUCTS
  payload: Report
}
export interface SetSalesProductGroupsAction {
  type: typeof SET_SALES_PRODUCT_GROUPS
  payload: Report
}

export interface SetSalesCustomerGroupsAction {
  type: typeof SET_SALES_CUSTOMER_GROUPS
  payload: Report
}
export interface SetSalesCustomersAction {
  type: typeof SET_SALES_CUSTOMERS
  payload: Report
}

export interface ResetReportsAction {
  type: typeof RESET_REPORTS
}

export type ReportActionTypes =
  | AddCashFlowExplainerAction
  | AddInternalKeyfigureVariableExplainerAction
  | AddKeyFigureExplainerAction
  | ResetReportsAction
  | SetCashFlowStatementAction
  | SetFinancialStatementAction
  | SetInternalKeyFigureStatementAction
  | AddInternalKeyFigureStatementAction
  | SetKeyFigureStatementAction
  | SetKeyPerformanceIndicatorStatementAction
  | SetPurchaseInvoicesAction
  | SetSalesCustomerGroupsAction
  | SetSalesCustomersAction
  | SetSalesInvoicesAction
  | SetSalesProductGroupsAction
  | SetSalesProductsAction
  | SetValuationAction
