import {
  RESET_FINANCIAL_STATEMENTS_BUDGETS,
  RESET_FINANCIAL_STATEMENTS_PAGE,
  ResetFinancialStatementsPageAction,
  SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  SetFinancialStatementsBudgetInEditAction,
  SetFinancialStatementsBudgetAction,
  TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  ToggleFinancialStatementsBudgetInEditAction,
  SET_FINANCIAL_STATEMENTS_BUDGET,
  ResetFinancialStatementsBudgetsAction,
  ToggleAllFinancialStatementsBudgetInEditAction,
  TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  SET_FINANCIAL_STATEMENT_REALLOCATION,
  SetFinancialStatementReallocationAction,
  ReallocationData,
  TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT,
  ToggleFinancialStatementsDriverInEditAction
} from './types'
import { Budget } from '../../../../pages/budgeting/financialStatements/components/budget/types'
import { ContextRequestParams } from '../../../context/actions'
import { GetRequestAction, REQUEST, GET } from '../../../middleware/types'

export const resetFinancialStatementsPage = (): ResetFinancialStatementsPageAction => ({
  type: RESET_FINANCIAL_STATEMENTS_PAGE
})

export const setFinancialStatementsBudgets = (payload: {
  code: string
  budget: Budget
}): SetFinancialStatementsBudgetAction => ({
  type: SET_FINANCIAL_STATEMENTS_BUDGET,
  payload
})

export const toggleAllFinancialStatementsBudgetInEditAction = (): ToggleAllFinancialStatementsBudgetInEditAction => ({
  type: TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT
})

export const resetFinancialStatementsBudgets = (id: string | number): ResetFinancialStatementsBudgetsAction => ({
  type: RESET_FINANCIAL_STATEMENTS_BUDGETS,
  payload: id.toString()
})

export const toggleFinancialStatementsBudgetInEditAction = (
  payload: number | string
): ToggleFinancialStatementsBudgetInEditAction => ({
  type: TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  payload: payload.toString()
})

export const toggleFinancialStatementsDriverInEditAction = (
  payload: number | string
): ToggleFinancialStatementsDriverInEditAction => ({
  type: TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT,
  payload: payload.toString()
})

export const setFinancialStatementsBudgetInEditAction = (payload: {
  [code: string]: boolean
}): SetFinancialStatementsBudgetInEditAction => ({
  type: SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  payload
})

export const setFinancialStatementAccountReallocations = (
  data: any,
  accountCode: string
): SetFinancialStatementReallocationAction => ({
  type: SET_FINANCIAL_STATEMENT_REALLOCATION,
  payload: { data, accountCode }
})

export const getFinancialStatementReallocationsRequest = (
  { companyId, ...params }: ContextRequestParams,
  { accountCode }: any
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/reallocations/summary/${accountCode}`,
    params,
    success: (payload: ReallocationData) => {
      return setFinancialStatementAccountReallocations(payload, accountCode)
    }
  },
  meta: {
    type: `FINANCIAL_STATEMENT_REALLOCATIONS_${accountCode}`
  }
})
