import { Authentication, AuthenticationActionTypes, SET_TOKEN } from './types'

const initialState: Authentication = {}

const authenticationReducer = (state: Authentication = initialState, action: AuthenticationActionTypes) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload.token }
    default:
      return state
  }
}

export default authenticationReducer
