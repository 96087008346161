import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocsURL } from '../../../../../../utils/docs'
import MaraplanApi from './MaraplanApi'
import DimensionMap from '../dimensionMap/DimensionMap'

const Maraplan = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: <MaraplanApi allowAuthentication={false} credentialsRequestUrl="/maraplan/authentication/{companyId}" />
    },
    {
      label: t('global:costCentres'),
      key: 'dimensions',
      children: (
        <DimensionMap
          getDimensionsUrl="/maraplan/dimensions/{companyId}"
          updateDimensionsUrl="/maraplan/dimensions/{companyId}/{id}"
          dataKey="id"
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#maraplan`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Maraplan
