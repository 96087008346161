import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { getInvestmentsSummaryRequest, resetInvestments } from '../../../redux/pages/investments/actions'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import InvestmentsPage from './InvestmentsPage'
import { AppDispatch } from '../../../redux/store'

const NewInvestments = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const { newInvestments, date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)

  useEffect(() => {
    if (date !== null && companyId) {
      dispatch(
        getInvestmentsSummaryRequest(
          companyId,
          dayjs(date).endOf('month').format('YYYY-MM-DD'),
          budgetingScenario && budgetingScenario.id,
          false
        )
      )
    }
    return () => {
      dispatch(resetInvestments())
    }
  }, [companyId, date, budgetingScenario])

  return <InvestmentsPage investmentsData={newInvestments} />
}

export default NewInvestments
