import { createSelector } from 'reselect'
import { Store } from '../../types'
import { contextCompanyCountrySelector, contextCompanyFormSelector } from '../../context/company/selectors'

const investmenFundingSources = (store: Store) => store.entities.investmentFundingSources || []

export const investmenFundingSourceSelector = createSelector(
  contextCompanyCountrySelector,
  contextCompanyFormSelector,
  investmenFundingSources,
  (country, companyForm, f) => {
    return f.filter(row => row.country === country && row.companyForm === companyForm)
  }
)
