import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'

export const subscribedKeyFiguresSelector = createSelector(
  contextSelector,
  context => context.subscribedKeyFigures || []
)

export const subscribedKeyFiguresLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.subscribedKeyFigures
)
