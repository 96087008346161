import { Button, FormInstance } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import { useBackend } from '../../../../../services/backend'
import { notLocked } from '../../../../settings/company/budgeting/budgetingScenarios/utils'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import AmendmentForm from './AmendmentForm'
import { handleCancel, handleOk } from './service'

interface AmendmentMenuProps {
  reportTableRow: ReportTableRow
}

const AmendmentMenu = ({ reportTableRow }: AmendmentMenuProps) => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const [modalVisible, setModalVisible] = useState(false)
  const { budgetingScenario } = useSelector(filtersSelector)
  const amendmentRequest = useBackend('/api/companies/{companyId}/amendments')
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)

  return (
    <>
      <AmendmentForm
        balanceSheetRow={reportTableRow}
        loading={amendmentRequest.loading}
        handleOk={(form: FormInstance) => {
          if (companyId) {
            handleOk(amendmentRequest, companyId, setModalVisible, form)
          }
        }}
        handleCancel={(form: FormInstance) => {
          handleCancel(form, setModalVisible)
        }}
        modalVisible={modalVisible}
      />
      <Button
        type="default"
        disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
        size="small"
        onClick={() => {
          setModalVisible(true)
        }}
      >
        {t('financialStatementsPage:amendment')}
      </Button>
    </>
  )
}

export default AmendmentMenu
