import { BarChartOutlined } from '@ant-design/icons'
import React from 'react'
import { ContextFiltersSections } from '../../components/ContextDrawer/types'
import FinancialStatements from '../../pages/budgeting/financialStatements/FinancialStatements'
import CashFlow from '../../pages/reporting/cashFlow/CashFlow'
import KeyFigures from '../../pages/reporting/keyFigures/KeyFigures'
import { ContractProduct } from '../../types/contract/Contract'
import { Route } from '../Route'
import { isGroupCompany } from '../utils'
import CustomReportsNew from '../../pages/reporting/custom/CustomReportsNew'
import LayoutsProvider from '../../pages/reporting/custom/components/layout/LayoutContext'

const reportingRoutes: Route = {
  path: '/reporting',
  name: 'reporting',
  icon: <BarChartOutlined />,
  routes: [
    {
      path: '/reporting/financial-statements',
      name: 'financial-statements',
      component: FinancialStatements,
      authority: [
        // Tuotteena Light, raportoinnin alla, muissa Budjetoinnissa
        {
          role: [
            'company-admin',
            'company-user',
            'accountant-admin',
            'accountant-user',
            'financier-admin',
            'financier-user'
          ],
          product: [ContractProduct.LIGHT]
        },
        // Konsernin tulos ja tase toistaseks täällä
        {
          custom: isGroupCompany
        }
      ]
    },
    {
      path: '/reporting/key-figures',
      name: 'key-figures',
      component: KeyFigures,
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      }
    },
    {
      path: '/reporting/cash-flow',
      name: 'cash-flow',
      component: CashFlow,
      authority: {
        role: [
          'company-admin',
          'company-user',
          'accountant-admin',
          'accountant-user',
          'financier-admin',
          'financier-user'
        ]
      }
    },
    {
      path: '/reporting/custom',
      name: 'custom',
      component: () => (
        <LayoutsProvider>
          <CustomReportsNew />
        </LayoutsProvider>
      ),
      disabledContextdrawerSections: [
        ContextFiltersSections.budgetScenario,
        ContextFiltersSections.dimension,
        ContextFiltersSections.periodGroups,
        ContextFiltersSections.dataTypes
      ]
    }
  ]
}

export default reportingRoutes
