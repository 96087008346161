import {
  AddReallocationsPageExpandedRowKeyAction,
  ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY,
  SetReallocationsPageExpandedRowKeysAction,
  SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS
} from './types'

export const setReallocationsPageRowKeys = (keys: number[]): SetReallocationsPageExpandedRowKeysAction => ({
  type: SET_REALLOCATIONS_PAGE_EXPANDED_ROW_KEYS,
  payload: keys
})

export const addReallocationsPageRowKey = (key: number): AddReallocationsPageExpandedRowKeyAction => ({
  type: ADD_REALLOCATIONS_PAGE_EXPANDED_ROW_KEY,
  payload: key
})
