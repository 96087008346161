import React, { useContext } from 'react'
import { Form, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { ColumnProps } from 'antd/lib/table'
import SelectionTableInput from '../SelectionTableInput'
import DashboardItemFields from '../DasboardItemFieds'
import { formulasSelector } from '../../../../../../../redux/context/formulas/selectors'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from '../../DashboardItemProvider'
import { Formula } from '../../../../../../../types/formula/Formula'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'

const InternalKeyFigurePieFields = () => {
  const { t } = useTranslation()
  const formulas = useSelector(formulasSelector)
  const { companyDashboardItem } = useContext(CompanyDashboardItemContext) as CompanyDashboardContextType
  let selectableInternalKeyFigures = []
  if (companyDashboardItem?.params.representation.type === 'table') {
    selectableInternalKeyFigures = formulas
  } else {
    selectableInternalKeyFigures = formulas.filter(d => d.type === companyDashboardItem?.params.selectedType)
  }

  const columns: ColumnProps<Formula>[] = [
    {
      title: t(`global:name`),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('name')
    }
  ]

  const defaultLabel = (
    <>
      {t('dashboardPage:defaultValueOfPie')}
      <Tooltip placement="top" title={t('dashboardPage:keyFiguresInPieChartInfo2')}>
        <InfoCircleTwoTone style={{ verticalAlign: 'middle', marginLeft: 8 }} />
      </Tooltip>
    </>
  )

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('global:keyFigure')} name={['params', 'keyFigureIds']}>
        <SelectionTableInput<Formula> dataSource={selectableInternalKeyFigures} columns={columns} />
      </Form.Item>
      <Form.Item label={defaultLabel} name={['params', 'keyFigureDefaultPieIds']}>
        <SelectionTableInput<Formula> dataSource={selectableInternalKeyFigures} columns={columns} />
      </Form.Item>
    </>
  )
}

export default InternalKeyFigurePieFields
