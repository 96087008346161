import { ThunkAction } from 'redux-thunk'
import { Action } from '@reduxjs/toolkit'
import {
  SET_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  SetMultiPurposeDashboardKeyfigureStatementAction,
  ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  AddMultiPurposeDashboardFormulasAction,
  MultiPurposeDashboardParams,
  SetMultiPurposeDashboardsAction,
  SET_MULTI_PURPOSE_DASHBOARD_DASHBOARDS,
  SetMultiPurposeDashboardsActiveDashboardAction,
  SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD,
  UPDATE_MULTI_PURPOSE_DASHBOARD_DASHBOARD,
  UpdateMultiPurposeDashboardAction,
  RESET_MULTI_PURPOSE_DASHBOARD,
  ResetMultiPurposeDashboardAction,
  AddMultiPurposeDashboardAction,
  ADD_MULTI_PURPOSE_DASHBOARD_DASHBOARD,
  RESET_MULTI_PURPOSE_DASHBOARD_REPORTS,
  ResetMultiPurposeDashboardReportsAction,
  SET_MULTI_PURPOSE_DASHBOARD_ORDER,
  SetMultiPurposeDashboardOrderAction,
  DeleteMultiPurposeDashboardAction,
  DELETE_MULTI_PURPOSE_DASHBOARD,
  SetMultiPurposeDashboardAllKeyfigureStatementsAction,
  SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS,
  SetMultiPurposeDashboardFormulasAction,
  SET_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS,
  AddMultiPurposeDashboardDimensionKeyfigureStatementsAction,
  AddMultiPurposeDashboardDimensionsAction,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS,
  SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  SetMultiPurposeDashboardInternalKeyfigureStatementAction,
  SetAllMultiPurposeDashboardKeyfigureStatementAction,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  SetAllMultiPurposeDashboardInternalKeyfigureStatementAction,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
} from './types'
import { Company } from '../../../../types/company/Company'
import { Report, ReportRow } from '../../../context/reports/types'
import { Store } from '../../../types'
import { mapToGlobalKeyFigureStatement } from '../../../../utils/keyFigures'
import { Formula } from '../../../../types/formula/Formula'
import { Dimension } from '../../../../types/dimension/Dimension'

export const resetMultiPurposeDashboard = (): ResetMultiPurposeDashboardAction => ({
  type: RESET_MULTI_PURPOSE_DASHBOARD
})

export const resetMultiPurposeDashboardReports = (): ResetMultiPurposeDashboardReportsAction => ({
  type: RESET_MULTI_PURPOSE_DASHBOARD_REPORTS
})

export const addMultiPurposeDashboardFormulas = (
  companyId: Company['id'],
  formulas: Formula[]
): AddMultiPurposeDashboardFormulasAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  payload: { companyId, formulas }
})

export const addMultiPurposeDashboardDimensions = (
  companyId: Company['id'],
  dimensions: Dimension[]
): AddMultiPurposeDashboardDimensionsAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS,
  payload: { companyId, dimensions }
})

export const setMultiPurposeDashboardFormulas = (formulas: {
  [key: string]: Formula[]
}): SetMultiPurposeDashboardFormulasAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  payload: formulas
})

export const setMultiPurposeDashboardActiveDashboard = (
  payload: number | undefined
): SetMultiPurposeDashboardsActiveDashboardAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD,
  payload
})

export const setMultiPurposeDashboardDashboards = (
  payload: MultiPurposeDashboardParams[]
): SetMultiPurposeDashboardsAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_DASHBOARDS,
  payload
})

export const setMultiPurposeDashboardAndActiveKey = (
  payload: MultiPurposeDashboardParams[],
  activeDashboardId?: number
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(setMultiPurposeDashboardDashboards(payload))
    dispatch(setMultiPurposeDashboardActiveDashboard(activeDashboardId || payload?.[0].id))
  }
}

export const setMultiPurposeDashboardOder = (
  payload: { id: number; order: number }[]
): SetMultiPurposeDashboardOrderAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_ORDER,
  payload
})

export const updateMultiPurposeDashboardDashboard = (
  payload: MultiPurposeDashboardParams
): UpdateMultiPurposeDashboardAction => ({
  type: UPDATE_MULTI_PURPOSE_DASHBOARD_DASHBOARD,
  payload
})

export const addMultiPurposeDashboardDashboard = (
  payload: MultiPurposeDashboardParams
): AddMultiPurposeDashboardAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_DASHBOARD,
  payload
})

export const setMultiPurposeDashboardKeyfigureStatement = (
  id: string,
  data: Report
): SetMultiPurposeDashboardKeyfigureStatementAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  payload: { id, data }
})

export const setAllMultiPurposeDashboardKeyfigureStatement = (
  data: { dimensionKey?: string; budgetingScenarioId?: number; data: Report }[]
): SetAllMultiPurposeDashboardKeyfigureStatementAction => ({
  type: SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  payload: data
})

export const setMultiPurposeDashboardInternalKeyfigureStatement = (
  id: string,
  data: Report
): SetMultiPurposeDashboardInternalKeyfigureStatementAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  payload: { id, data }
})

export const setAllMultiPurposeDashboardInternalKeyfigureStatement = (
  data: { dimensionId: string; data: Report }[]
): SetAllMultiPurposeDashboardInternalKeyfigureStatementAction => ({
  type: SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  payload: data
})

export const setMultiPurposeDashboardAllKeyfigureStatements = (data: {
  [key: string]: ReportRow[]
}): SetMultiPurposeDashboardAllKeyfigureStatementsAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS,
  payload: data
})

export const addMultiPurposeDashboardDimensionKeyfigureStatements = (data: {
  [key: string]: ReportRow[]
}): AddMultiPurposeDashboardDimensionKeyfigureStatementsAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS,
  payload: data
})

export const deleteMultiPurposeDashboard = (payload: number): DeleteMultiPurposeDashboardAction => ({
  type: DELETE_MULTI_PURPOSE_DASHBOARD,
  payload
})

export const multiPurposeDashboardKeyfigureStatementSuccess = (
  companyId: Company['id'],
  payload: Report
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(setMultiPurposeDashboardKeyfigureStatement(companyId, mapToGlobalKeyFigureStatement(payload) as Report))
  }
}
