import { Checkbox, Form } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Permission } from '../../types/user/Permission'
import DimensionSelect from '../Dimension/DimensionSelect'

interface UserPermissionFieldsProps {
  permissions?: Permission[]
}
const UserPermissionFields: React.FC<UserPermissionFieldsProps> = ({ permissions }) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const selectedPermissions = Form.useWatch(['permissions'], form) as string[]

  if (!permissions) return null

  return (
    <>
      <Form.Item shouldUpdate label={t('usersPage:rightsToIndividualDimensions')} name="dimensions" initialValue={[]}>
        <DimensionSelect
          treeSelectProps={{
            treeCheckable: true,
            treeCheckStrictly: true,
            disabled: selectedPermissions?.includes('read-root-dimension')
          }}
        />
      </Form.Item>
      <Form.Item name="permissions">
        <Checkbox.Group>
          {permissions.map(({ label, id, hidden }) => {
            const { label: formLabel, description } = t(`permissions:${label}`, {
              returnObjects: true
            }) as { label: string; description: string }
            return (
              <Form.Item label={formLabel} style={{ width: '100%', display: hidden ? 'none' : 'block' }}>
                <Checkbox value={label} key={id}>
                  {description}
                </Checkbox>
              </Form.Item>
            )
          })}
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

export default UserPermissionFields
