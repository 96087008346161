import { SetGroupCompany, SET_GROUP_COMPANY } from './types'

const initialState = {}

const groupCompanyReducer = (state = initialState, action: SetGroupCompany) => {
  switch (action.type) {
    case SET_GROUP_COMPANY:
      return action.payload
    default:
      return state
  }
}

export default groupCompanyReducer
