import { useTranslation } from 'react-i18next'
import { Language } from '../types/user/User'

export const useDocsURL = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  switch (language) {
    case Language.fi:
      return `${process.env.REACT_APP_DOCS_BASE_URL}`
    case Language.sv:
      return `${process.env.REACT_APP_DOCS_BASE_URL}se/`
    case Language.en:
    default:
      return `${process.env.REACT_APP_DOCS_BASE_URL}en/`
  }
}

export const useWordpressURL = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  switch (language) {
    case Language.sv:
      return 'https://finadeck.fi/sv/'
    case Language.en:
      return 'https://finadeck.fi/en/'
    case Language.fi:
    default:
      return 'https://finadeck.fi/'
  }
}
