import { CompanyBasicDataActionTypes, SET_COMPANY_INFORMATION } from './types'

const initialState: any = {
  stakeholders: {
    owners: {
      privates: [],
      companies: []
    },
    beneficiaries: [],
    executiveBoardMembers: []
  }
}

const companyBasicDataReducer = (state = initialState, action: CompanyBasicDataActionTypes) => {
  switch (action.type) {
    case SET_COMPANY_INFORMATION:
      // return initialState
      return action.payload || initialState
    default:
      return state
  }
}

export default companyBasicDataReducer
