import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { requireContractProductAtLeast } from '../../../components/Misc/RestrictedProduct'
import { generateDataIndex } from '../../../components/Table/utils'
import { ContractProduct } from '../../../types/contract/Contract'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { contextContractSelector } from '../contract/selectors'
import { filtersSelector } from '../filters/selectors'
import { periodGroupLabel, periodLabel } from '../fiscalYears/utils'
import { ReportDataType, ReportDataTypeCumulatives } from '../reports/types'
import { sortedColumnGroupSelector, columnPeriodsSelector } from './selectors'
import { periodColumns } from './utils'

export interface Column {
  fiscalYear?: FiscalYear
  year?: number
  month?: number
  label: string
  dataType?: ReportDataType | ReportDataTypeCumulatives
  periodGroup?: PeriodGroup | FiscalYear
  className?: string
  cumulative?: 'cumulative' | 'diff' | 'comparativeDiff'
  expandable: boolean
  dataIndex: string
}

export const useTableColumns = (enableCumulatives?: boolean): Column[] => {
  const [tableColumns, setTableColumns] = useState<Column[]>([])
  const {
    dataTypes,
    periodGroup: periodGroupFilter,
    cumulativeFilter,
    budgetingScenario
  } = useSelector(filtersSelector)
  const contract = useSelector(contextContractSelector)
  const budgetCategoriesEnabled =
    contract?.product && requireContractProductAtLeast(contract.product, ContractProduct.BASIC)
  const periods = useSelector(columnPeriodsSelector)
  const groups = useSelector(sortedColumnGroupSelector)
  const montlhyCategoriesEnabled = true
  const forecastCursor = budgetingScenario?.forecastCursor && dayjs(budgetingScenario?.forecastCursor)

  const filteredPeriodGroupDataTypes = (group: FiscalYear | PeriodGroup) => {
    let periodGroupDataTypes: ReportDataType[] = dataTypes.filter(d => d !== ReportDataType.comparativePeriod)

    if (!budgetCategoriesEnabled || ('budgetable' in group && !group?.budgetable)) {
      periodGroupDataTypes = periodGroupDataTypes.filter(
        d => d !== ReportDataType.budget && d !== ReportDataType.forecast
      )
    }

    if (dayjs(group.startDate) > dayjs()) {
      periodGroupDataTypes = periodGroupDataTypes.filter(d => d !== ReportDataType.actuals)
    }

    return periodGroupDataTypes
  }

  const filteredPeriodDataTypes = (group: FiscalYear | PeriodGroup) => {
    let periodGroupDataTypes: ReportDataType[] = dataTypes

    if (!budgetCategoriesEnabled || ('budgetable' in group && !group?.budgetable)) {
      periodGroupDataTypes = periodGroupDataTypes.filter(
        d => d !== ReportDataType.budget && d !== ReportDataType.forecast
      )
    }

    return periodGroupDataTypes
  }

  useEffect(() => {
    if (periodGroupFilter && periods) {
      const columns: Column[] = []
      // kk data ja columnit
      for (const period of periods) {
        for (const dataType of filteredPeriodDataTypes(periodGroupFilter)) {
          columns.push(periodColumns(period, dataType, forecastCursor))
        }

        if (enableCumulatives && cumulativeFilter?.month === period.month && cumulativeFilter?.year === period.year) {
          // kumulatiivit
          for (const dataType of filteredPeriodDataTypes(periodGroupFilter)) {
            columns.push({
              label: periodLabel(period, dataType, 'cumulative'),
              year: period.year,
              month: period.month,
              dataType,
              cumulative: 'cumulative',
              className: `${dataType}-cumulative-column`,
              expandable: true,
              dataIndex: generateDataIndex(dataType, undefined, period.year, period.month, 'cumulative')
            })
          }

          if (
            filteredPeriodDataTypes(periodGroupFilter).includes(ReportDataType.actuals) &&
            filteredPeriodDataTypes(periodGroupFilter).includes(ReportDataType.budget)
          ) {
            columns.push({
              label: periodLabel(period, undefined, 'diff'),
              year: period.year,
              month: period.month,
              cumulative: 'diff',
              className: 'diff-column',
              expandable: true,
              dataIndex: generateDataIndex(undefined, undefined, period.year, period.month, 'diff')
            })
          }

          if (
            filteredPeriodDataTypes(periodGroupFilter).includes(ReportDataType.actuals) &&
            filteredPeriodDataTypes(periodGroupFilter).includes(ReportDataType.comparativePeriod)
          ) {
            columns.push({
              label: periodLabel(period, ReportDataType.comparativePeriod, 'comparativeDiff'),
              year: period.year,
              month: period.month,
              cumulative: 'comparativeDiff',
              className: 'comparative-diff-column',
              expandable: true,
              dataIndex: generateDataIndex(undefined, undefined, period.year, period.month, 'comparativeDiff')
            })
          }
        }
      }
      // console.count('PERIOD COLUMNS')
      setTableColumns(columns)
    } else {
      // group data ja columnit
      const columns: Column[] = []
      for (const group of groups) {
        if (group.id > 0 && !dataTypes.includes(ReportDataType.comparativePeriod)) {
          for (const dataType of filteredPeriodGroupDataTypes(group)) {
            columns.push({
              label: periodGroupLabel(group, dataType),
              dataType,
              className: `${dataType}-column`,
              periodGroup: group,
              expandable: montlhyCategoriesEnabled,
              dataIndex: generateDataIndex(dataType, group.id)
            })
          }
        } else if (dataTypes.includes(ReportDataType.comparativePeriod)) {
          // kvartaaleille group tason vertailukausi, normaalisti ei ole vertailusaraketta group tasolla
          // vertailukaudet tulevat negatiivisella id:llä
          let dataTypesToMap = filteredPeriodGroupDataTypes(group)
          if (
            dataTypes.includes(ReportDataType.comparativePeriod) &&
            !dataTypes.includes(ReportDataType.actuals) &&
            group.id < 0
          ) {
            // jos filttereissä VAIN comparative -> mäpätään actualit kerran, josta saadaan negatiivisen
            // id:n avulla oikeat comparativet sarakkeiksi
            dataTypesToMap = [ReportDataType.actuals]
          }
          for (const dataType of dataTypesToMap) {
            columns.push({
              label: periodGroupLabel(group, dataType),
              dataType: group.id < 0 ? ReportDataType.comparativePeriod : dataType,
              className: group.id < 0 ? `${ReportDataType.comparativePeriod}-column` : `${dataType}-column`,
              periodGroup: group,
              expandable: montlhyCategoriesEnabled,
              dataIndex: generateDataIndex(dataType, group.id)
            })
          }
        }
      }
      // console.log('GROUP COLUMNS', columns)
      setTableColumns(columns)
    }
  }, [periodGroupFilter, dataTypes, dataTypes, periods, groups])

  return tableColumns
}
