import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import { useBackend } from '../../../services/backend'
import { notificationAction } from '../../../redux/middleware/actions'
import { Loading } from '../../../components/Misc/Loading'
import { Contract } from '../../../types/contract/Contract'
import SuperUserContractTable from './components/SuperUserContractTable'
import { autoDownload } from '../../../utils/helpers'
import { AppDispatch } from '../../../redux/store'

const SuperUserContracts: React.FC = () => {
  const getRequest = useBackend(`/api/superuser/contracts`)
  const { loading } = getRequest
  const dispatch: AppDispatch = useDispatch()

  const [contracts, setContracts] = useState<Contract[]>([])
  const contentEl = useRef(null)

  const getCSVRequest = useBackend(`/api/superuser/contracts/csv`, process.env.REACT_APP_BACKEND_URL, 'arraybuffer')

  const downloadCSV = async () => {
    try {
      const response = await getCSVRequest.get({})

      autoDownload(`contracts.csv`, response)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'DOWNLOAD_ERROR' }))
    }
  }

  const request = (params?: any) => {
    getRequest
      .get({ body: { params } })
      .then(setContracts)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACTS_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(request, [])

  return (
    <>
      <Button key="csv" icon={<FileOutlined />} onClick={downloadCSV}>
        CSV
      </Button>
      <div ref={contentEl}>{loading ? <Loading /> : <SuperUserContractTable contracts={contracts} />}</div>
    </>
  )
}

export default SuperUserContracts
