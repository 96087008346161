import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Popconfirm, Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import {
  deleteSubsidiariesRequest,
  updateSubsidiarieOrdersRequest,
  updateSubsidiaryAcquisitionsRequest
} from '../../../../../../redux/context/subsidiaries/actions'
import { subsidiariesSelector } from '../../../../../../redux/context/subsidiaries/selectors'
import { Company, CompanyAcquisition } from '../../../../../../types/company/Company'
import AquisitionsModal from './AquisitionsModal'
import DraggableTableRow from '../../../../../../components/Table/DraggableTableRow'
import { formatValueByType } from '../../../../../../utils/helpers'
import { VariableType } from '../../../../../../components/Table/types'
import { AppDispatch } from '../../../../../../redux/store'
import { loadingSelector } from '../../../../../../redux/loading/selectors'

interface SubsidiariesProps {
  groupId?: string
}

export const SubsidiariesTable: React.FC<SubsidiariesProps> = ({ groupId }: SubsidiariesProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const subsidiaries = useSelector(subsidiariesSelector)
  const loading = useSelector(loadingSelector)
  const [subsidiariesDataSource, setSubsidiariesDataSource] = useState(subsidiaries)
  const [subsidiaryEdit, setSubsidiaryEdit] = useState<Company>()
  const [open, setOpen] = useState(false)

  const onUpdate = (values: Company) => {
    companyId && dispatch(updateSubsidiaryAcquisitionsRequest(companyId, values))
    setOpen(false)
  }

  const deleteSubsidiary = (subsidiary: Company) => {
    companyId && dispatch(deleteSubsidiariesRequest(companyId, subsidiary.id))
  }

  const handleEdit = (values: Company) => {
    setOpen(true)
    setSubsidiaryEdit(values)
  }

  useEffect(() => {
    setSubsidiariesDataSource(subsidiaries)
  }, [subsidiaries])

  const getCurrentAquisitionValue = (aquisitions?: CompanyAcquisition[]) => {
    if (!aquisitions) return 1
    if (aquisitions && aquisitions.length < 1) return 1

    const currentAcquisition = aquisitions?.reduce((prev, curr) => {
      return dayjs(curr.date).isAfter(dayjs(prev.date)) && dayjs(curr.date).isBefore(dayjs()) ? curr : prev
    })

    return currentAcquisition.percentage
  }

  const renderCurrentAcquisition = (aquisitions: CompanyAcquisition[]) => {
    const percentage = getCurrentAquisitionValue(aquisitions)

    return (
      formatValueByType(percentage, VariableType.percentage, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }) ?? ''
    )
  }

  const columns = [
    {
      key: 'key'
    },
    {
      title: t('global:vatId'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('global:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('global:percentage'),
      dataIndex: 'acquisitions',
      key: 'acquisitions',
      render: renderCurrentAcquisition
    },
    {
      title: t('global:actions'),
      key: 'actions',
      align: 'right' as const,
      render: (subsidiary: Company) => (
        <Space>
          <Button icon={<EditOutlined />} type="text" onClick={() => handleEdit(subsidiary)} />
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => {
              deleteSubsidiary(subsidiary)
            }}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button icon={<DeleteOutlined />} type="text" danger />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = subsidiariesDataSource.findIndex(i => i.id === active.id)
      const overIndex = subsidiariesDataSource.findIndex(i => i.id === over?.id)
      const updatedArray = arrayMove(subsidiaries, activeIndex, overIndex)
      const orderArray = updatedArray.map((row: any, index: any) => {
        return {
          companyId,
          subsidiaryId: row.id,
          order: index
        }
      })
      setSubsidiariesDataSource(updatedArray)
      companyId && dispatch(updateSubsidiarieOrdersRequest(companyId, orderArray, updatedArray))
    }
  }

  return (
    <div>
      <AquisitionsModal
        groupId={groupId}
        company={subsidiaryEdit}
        open={open}
        onUpdate={onUpdate}
        onCancel={() => {
          setOpen(false)
        }}
      />
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={subsidiariesDataSource.map(i => i.id)}
          strategy={verticalListSortingStrategy}
        >
          {subsidiaries.length > 0 && (
            <Table
              loading={loading.subsidiaries}
              dataSource={subsidiariesDataSource}
              columns={columns}
              pagination={false}
              rowKey={record => record?.id?.toString()}
              components={{
                body: {
                  row: DraggableTableRow
                }
              }}
            />
          )}
        </SortableContext>
      </DndContext>
    </div>
  )
}
