import { VariableType } from '../../../../../components/Table/types'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'

export interface Budget {
  incomeStatementRowId?: number
  accountCode?: number | string
  periodGroup?: PeriodGroup | FiscalYear
  year?: number
  month?: number
  value: number
  dataType?: string
  code?: string | number
  formulaId?: number
  name?: string
  dimensionId?: string
  modalRowTitle?: string
  variableType?: VariableType
}

export enum BudgetingMethod {
  absolute = 'absolute',
  reference = 'reference',
  driver = 'driver'
}
