import React from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { flattenObject } from '../utils'
import { multiPurposeDashboardPageSelector } from '../../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'

interface FormulaInputProps {
  mode?: 'multiple' | 'tags'
  value?: number[]
  onChange?: (value: number[]) => void
}

const MultiPurposeFormulaInput: React.FC<FormulaInputProps> = ({ mode, value, onChange }) => {
  const { formulas } = useSelector(multiPurposeDashboardPageSelector)
  const userCompanies = useSelector(currentUserSortedCompaniesSelector)
  const triggerChange = (changedValue: number[]) => {
    onChange?.(changedValue as number[])
  }
  const getValues = () => {
    const values = []
    if (value) {
      for (const val of value) {
        if (flattenObject(formulas).find(f => f.id === val)) {
          values.push(val)
        }
      }
    }
    return values
  }

  return (
    <Select
      filterOption={(input, option) => {
        const { children } = option?.props
        return [...children]?.join('').toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
      }}
      value={mode === 'multiple' ? getValues() : value}
      mode={mode}
      allowClear
      popupMatchSelectWidth={500}
      onChange={triggerChange}
      maxTagCount="responsive"
    >
      {formulas &&
        flattenObject(formulas).map(formula => (
          <Select.Option key={formula.id} value={formula.id}>
            {userCompanies.find(c => c.id === formula.companyId)?.name} {formula.name}
          </Select.Option>
        ))}
    </Select>
  )
}

export default MultiPurposeFormulaInput
