import React, { useLayoutEffect, useRef } from 'react'
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd'

const type = 'DraggableBodyRow'
interface IndexedDragObjectWithType extends DragObjectWithType {
  index: any
}

const DraggableBodyRow = ({ index, moveRow, className, style, dragDisabled, ...restProps }: any) => {
  const ref = useRef<HTMLTableRowElement>()
  const [{ isOver, dropClassName }, drop] = useDrop<IndexedDragObjectWithType, any, any>({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: item => {
      moveRow(item.index, index)
    }
  })

  const [, drag] = useDrag({
    item: { index, type },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })

  useLayoutEffect(() => {
    drag(drop(ref))
    return () => {
      drag(null)
      drop(null)
    }
  }, [drag, drop, ref])

  return !dragDisabled ? (
    <tr
      ref={ref}
      key={restProps['data-row-key']}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  ) : (
    <tr key={restProps['data-row-key']} className={`${className}`} style={{ ...style }} {...restProps} />
  )
}

export default DraggableBodyRow
