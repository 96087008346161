import { DefaultAccountMapRule } from '../../../types/accountMapRule/AccountMapRule'

export const SET_SESSION_ACCOUNT_MAP_RULES = 'SET_SESSION_ACCOUNT_MAP_RULES'
export const CREATE_SESSION_ACCOUNT_MAP_RULE = 'CREATE_SESSION_ACCOUNT_MAP_RULE'

export interface SetSessionAccountMapRulesAction {
  type: typeof SET_SESSION_ACCOUNT_MAP_RULES
  payload: DefaultAccountMapRule[]
}

export interface CreateSessionAccountMapRule {
  type: typeof CREATE_SESSION_ACCOUNT_MAP_RULE
  payload: DefaultAccountMapRule
}
