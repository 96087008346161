export type DimensionQuery = DimensionQueryAnd | DimensionQueryOr | DimensionQueryHas | DimensionQueryEquals

export interface Dimension {
  id: number
  dimensionId: string
  companyId: string
  name: string
  parentDimensionId?: string
  children?: Dimension[]
  order: number
  source?: string
  default?: boolean
}

export interface DimensionQueryAnd {
  // key
  k?: string
  // op
  o: DimensionQueryOperator.and
  // children
  c: DimensionQuery[]
}

export interface DimensionQueryOr {
  // key
  k?: string
  // op
  o: DimensionQueryOperator.or
  // children
  c: DimensionQuery[]
}

export interface DimensionQueryHas {
  // key
  k?: string
  // op
  o: DimensionQueryOperator.has
  // value
  v: string
}

export interface DimensionQueryEquals {
  // key
  k?: string
  // op
  o: DimensionQueryOperator.equals
  // value
  v: string
}

export enum DimensionQueryOperator {
  and = '&',
  or = '|',
  has = '%',
  equals = '='
}
