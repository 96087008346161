import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table } from 'antd'
import ScenarioSelect from '../../../../../../components/Scenario/ScenarioSelect'
import { subsidiariesSelector } from '../../../../../../redux/context/subsidiaries/selectors'
import { Company } from '../../../../../../types/company/Company'

interface ScenarioMapProps {
  selectedGroup?: Company
}

const ScenarioMapTable = ({ selectedGroup }: ScenarioMapProps) => {
  const { t } = useTranslation()
  const subsidiaries = useSelector(subsidiariesSelector)

  const columns = [
    {
      title: t('global:company'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (text: any, record: any) => record?.name
    },
    {
      title: t('global:scenario'),
      dataIndex: 'id',
      key: 'id',
      width: '50%',
      render: (text: any) => <ScenarioSelect companyId={text} />
    }
  ]

  return (
    <Table
      pagination={false}
      rowKey={(val: any) => val.id}
      dataSource={[selectedGroup || {}, ...(subsidiaries || [])]}
      columns={columns}
    />
  )
}

export default ScenarioMapTable
