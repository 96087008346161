import { ContextCompanyActionTypes, SET_CONTEXT_COMPANY } from './types'

const initialState = {}

const contextCompanyReducer = (state = initialState, action: ContextCompanyActionTypes) => {
  switch (action.type) {
    case SET_CONTEXT_COMPANY:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default contextCompanyReducer
