import React from 'react'
import { Input, Button, Select, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateCurrentUserRequest } from '../../../../redux/session/currentUser/actions'
import { Language, User } from '../../../../types/user/User'
import { currentUserSelector } from '../../../../redux/session/currentUser/selectors'
import { AppDispatch } from '../../../../redux/store'

const UserSettings = () => {
  const { t, i18n } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const [form] = Form.useForm<User>()
  const user = useSelector(currentUserSelector)

  const handleSubmit = (values: User) => {
    dispatch(updateCurrentUserRequest(values as User))
    i18n.changeLanguage(values.language)
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8 }
  }

  return (
    <Form
      {...formItemLayout}
      name="user"
      initialValues={user}
      form={form}
      onFinish={handleSubmit}
      className="profile-form"
      layout="vertical"
    >
      <h4>{t('usersPage:email')}</h4>
      <p>{user.email}</p>
      <h4>{t('usersPage:role')}</h4>
      <p>{t(`usersPage:${user.role.label}`)}</p>

      <Form.Item name="firstName" label={t('usersPage:first-name')}>
        <Input placeholder={t('usersPage:first-name')} />
      </Form.Item>
      <Form.Item name="lastName" label={t('usersPage:last-name')}>
        <Input placeholder={t('usersPage:last-name')} />
      </Form.Item>
      <Form.Item name="phone" label={t('usersPage:phone')}>
        <Input placeholder={t('usersPage:phone')} />
      </Form.Item>
      <Form.Item name="language" label={t('usersPage:language')}>
        <Select>
          {Object.keys(Language).map(lng => (
            <Select.Option value={lng} key={lng}>
              {t(`languages:${lng}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="position" label={t('usersPage:position')}>
        <Input placeholder={t('usersPage:position')} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          {t('global:update')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserSettings
