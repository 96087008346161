import { Dictionary } from 'lodash'

export const SET_CONTRACT_PRODUCTS = 'SET_CONTRACT_PRODUCTS'

export interface SetContractProductsAction {
  type: typeof SET_CONTRACT_PRODUCTS
  payload: Dictionary<string>
}

export type ContractProductsActionTypes = SetContractProductsAction
