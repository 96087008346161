import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import LoansPage from './LoansPage'
import { getExistingLoansSummaryRequest, resetExistingLoans } from '../../../redux/context/loans/existing/actions'
import { getExistingLoans } from '../../../redux/context/loans/selectors'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { AppDispatch } from '../../../redux/store'

const ExistingLoans = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const existingLoans = useSelector(getExistingLoans)
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)

  useEffect(() => {
    if (date !== null && companyId) {
      dispatch(
        getExistingLoansSummaryRequest(
          companyId,
          dayjs(date).endOf('month').format('YYYY-MM-DD'),
          budgetingScenario && budgetingScenario.id
        )
      )
    }
    return () => {
      dispatch(resetExistingLoans())
    }
  }, [companyId, budgetingScenario, date])

  return <LoansPage loansData={existingLoans} isExisting />
}

export default ExistingLoans
