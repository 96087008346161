import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  ADD_SESSION_CONTRACT_CHILDREN,
  ADD_SESSION_CONTRACT_USER,
  AddSessionContractChildrenAction,
  AddSessionContractUsersAction,
  DELETE_SESSION_CONTRACT_USER,
  DeleteSessionContractUsersAction,
  SET_SESSION_CONTRACT_USERS,
  SET_SESSION_CONTRACT,
  SetSessionContractAction,
  SetSessionContractUsersAction,
  UPDATE_SESSION_CONTRACT_USER,
  UpdateSessionContractUsersAction,
  UPDATE_SESSION_CONTRACT_CHILDREN,
  UpdateSessionContractChildrenAction
} from './types'

export const setSessionContract = (payload: Contract): SetSessionContractAction => ({
  type: SET_SESSION_CONTRACT,
  payload
})

export const addSessionContractUser = (user: User): AddSessionContractUsersAction => ({
  type: ADD_SESSION_CONTRACT_USER,
  payload: user
})

export const addSessionContractChildren = (contract: Contract): AddSessionContractChildrenAction => ({
  type: ADD_SESSION_CONTRACT_CHILDREN,
  payload: contract
})

export const updateSessionContractChildren = (contract: Contract): UpdateSessionContractChildrenAction => ({
  type: UPDATE_SESSION_CONTRACT_CHILDREN,
  payload: contract
})

export const updateSessionContractUser = (user: User): UpdateSessionContractUsersAction => ({
  type: UPDATE_SESSION_CONTRACT_USER,
  payload: user
})

export const deleteSessionContractUser = (user: User): DeleteSessionContractUsersAction => ({
  type: DELETE_SESSION_CONTRACT_USER,
  payload: user
})

export const setSessionContractUsers = (payload: User[]): SetSessionContractUsersAction => ({
  type: SET_SESSION_CONTRACT_USERS,
  payload
})

const setSessionContractRequestSuccess = (payload: Contract) => {
  return (dispatch: any) => {
    dispatch(setSessionContract(payload))
  }
}

export const getSessionContractRequest = (contractId: Contract['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `api/contracts/${contractId}`,
    success: setSessionContractRequestSuccess
  },
  meta: {
    type: 'SESSION_CONTRACT'
  }
})

export const getSessionContractUsersRequest = (contractId: Contract['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `api/contracts/${contractId}/users`,
    success: (payload: User[]) => setSessionContractUsers(payload)
  },
  meta: {
    type: 'SESSION_CONTRACT_USERS'
  }
})

export const createSessionContractUserRequest = (user: User, contractId: Contract['id']): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    url: `api/contracts/${contractId}/users`,
    data: user,
    success: (response: User) => addSessionContractUser(response)
  },
  meta: {
    type: 'CREATE_USER',
    notification: true
  }
})

export const updateSessionContractUserRequest = (user: User, userId: number): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `api/users/${userId}`,
    data: user,
    success: (response: User) => updateSessionContractUser(response)
  },
  meta: {
    type: 'UPDATE_USER',
    notification: true
  }
})

export const deleteSessionContractUserRequest = (userId: number): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `api/users/${userId}`,
    success: (response: User) => deleteSessionContractUser(response)
  },
  meta: {
    type: 'DELETE_USER',
    notification: true
  }
})
