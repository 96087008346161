import { createSelector } from 'reselect'
import { Company } from '../../../types/company/Company'
import { loadingSelector } from '../../loading/selectors'
import { Store } from '../../types'
import {
  currentUserSortedCompaniesSelector,
  currentUserIsAdminSelector,
  userCompaniesSelector
} from '../currentUser/selectors'

export const sessionContractSelector = (store: Store) => store.session?.contract
export const sessionContractIdSelector = createSelector(sessionContractSelector, contract => contract.id)
export const sessionLayoutSelector = createSelector(sessionContractSelector, contract => contract?.layout)
export const sessionContractUserSelector = createSelector(sessionContractSelector, contract => contract.users)
export const sessionContractProductSelector = createSelector(sessionContractSelector, contract => contract.product)
export const sessionContractChildrenSelector = createSelector(
  sessionContractSelector,
  currentUserSortedCompaniesSelector,
  currentUserIsAdminSelector,
  (contract, companies, isAdmin) => {
    if (!isAdmin) {
      return contract.children.filter(child => companies.map(company => company.id).includes(child.companyId))
    }
    return contract.children
  }
)

export const getSessionContractChildrenById = (id: string) =>
  createSelector(sessionContractChildrenSelector, children => {
    return (children.find(c => c.companyId.toString() === id) as unknown as Company) || null
  })

export const sessionContractUsersLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.sessionContractUsers
)

export const currentUserIsAdminSortedCompaniesSelector = createSelector(
  [sessionContractSelector, userCompaniesSelector, currentUserIsAdminSelector],
  (contract, userCompanies, isAdmin) => {
    const contractCompanies = contract.children.map(c => ({
      id: c.companyId,
      name: c.name,
      industryId: 0,
      settings: undefined,
      contract: c
    })) as Company[]

    const companies = isAdmin ? contractCompanies : userCompanies
    return (companies || []).sort((a: Company, b: Company) => {
      const nameA = a.name.toUpperCase() // ignore upper and lowercase
      const nameB = b.name.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    })
  }
)
