import React from 'react'
import classNames from 'classnames'
// import './_Logo.sass'
import { WhiteLabel, customerThemes } from '../../config/customerThemes'

type Props = {
  type?: WhiteLabel
  wordmark?: boolean
  style?: React.CSSProperties
}

const Logo = ({ type = 'app', wordmark, style }: Props) => {
  const logoType: WhiteLabel = type in customerThemes ? (type as WhiteLabel) : ('app' as WhiteLabel)

  const LogoComponent = customerThemes[logoType][wordmark ? 'wordmark' : 'logo']

  if (LogoComponent === null) {
    return null
  }

  if (typeof LogoComponent === 'function') {
    return LogoComponent()
  }

  return (
    <img
      src={customerThemes[logoType][wordmark ? 'wordmark' : 'logo'] as string}
      alt={logoType}
      className={classNames(wordmark ? 'wordmark' : 'logo', logoType)}
      style={style}
    />
  )
}

export default Logo
