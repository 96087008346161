import { createSelector } from 'reselect'
import { Store } from '../../types'

const permissions = (store: Store) => store.entities.permissions

export const permissionsSelector = createSelector(permissions, p => p)

export const visiblePermissionsSelector = createSelector(permissions, p => {
  // return permissions
  return p.filter(permission => !permission.hidden)
})
