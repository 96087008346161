import { Col, Select } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { fiscalYearsSelector } from '../../../redux/context/fiscalYears/selectors'
import { fiscalYearLabel } from '../../../redux/context/fiscalYears/utils'
import { balanceSheetLeavesSelector } from '../../../redux/entities/statementRows/selectors'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'

interface BalanceSheetCorrectionValue {
  id: number
  fiscalYearId?: number
  balanceSheetRowId?: number
  value?: string
}

interface BalanceSheetCorrectionInputProps {
  id: number
  value?: BalanceSheetCorrectionValue
  onChange?: (value: BalanceSheetCorrectionValue) => void
}

const BalanceSheetCorrectionInput: React.FC<BalanceSheetCorrectionInputProps> = ({
  id,
  value: balanceSheetCorrectionValue = {},
  onChange
}) => {
  const { t } = useTranslation()
  const fiscalYears = useSelector(fiscalYearsSelector)
  const balanceSheetLeaves = useSelector(balanceSheetLeavesSelector)
  const [fiscalYearId, setFiscalYearId] = useState<number>()
  const [balanceSheetRowId, setBalanceSheetRowId] = useState<number>()
  const [value, setValue] = useState<any>()

  const triggerChange = (changedValue: { fiscalYearId?: number; balanceSheetRowId?: number; value?: string }) => {
    onChange?.({ id, fiscalYearId, balanceSheetRowId, value, ...balanceSheetCorrectionValue, ...changedValue })
  }

  const onFiscalYearChange = (changedValue: number) => {
    if (!('fiscalYearId' in balanceSheetCorrectionValue)) {
      setFiscalYearId(changedValue)
    }
    triggerChange({ fiscalYearId: changedValue })
  }

  const onBalanceSheetChange = (changedValue: number) => {
    if (!('balanceSheetRowId' in balanceSheetCorrectionValue)) {
      setBalanceSheetRowId(changedValue)
    }
    triggerChange({ balanceSheetRowId: changedValue })
  }

  const onValueChange = (changedValue: any) => {
    if (!('value' in balanceSheetCorrectionValue)) {
      setValue(changedValue)
    }
    triggerChange({ value: changedValue.toString() })
  }

  return (
    <>
      <Col span={6}>
        <Select
          value={balanceSheetCorrectionValue.fiscalYearId}
          showSearch
          optionFilterProp="children"
          onChange={onFiscalYearChange}
          style={{ width: '100%' }}
        >
          {fiscalYears.map((fiscalYear: FiscalYear) => {
            return (
              <Select.Option key={fiscalYear.id} value={fiscalYear.id}>
                {fiscalYearLabel(fiscalYear)}
              </Select.Option>
            )
          })}
        </Select>
      </Col>

      <Col span={9}>
        <Select
          value={balanceSheetCorrectionValue.balanceSheetRowId}
          showSearch
          optionFilterProp="children"
          onChange={onBalanceSheetChange}
          style={{ width: '100%' }}
        >
          {balanceSheetLeaves.map((leaf: any) => {
            return (
              <Select.Option key={leaf.id} value={leaf.id}>
                {t(`balanceSheet:${leaf.id}`)}
              </Select.Option>
            )
          })}
        </Select>
      </Col>

      <Col span={7}>
        <FormattedInputNumber value={balanceSheetCorrectionValue.value} onChange={onValueChange} />
      </Col>
    </>
  )
}

export default BalanceSheetCorrectionInput
