import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'

export const SET_FISCAL_YEARS = 'SET_FISCAL_YEARS'
export const UPDATE_FISCAL_YEAR = 'UPDATE_FISCAL_YEAR'
export const ADD_FISCAL_YEAR = 'ADD_FISCAL_YEAR'
export const DELETE_FISCAL_YEAR = 'DELETE_FISCAL_YEAR'

export interface SetFiscalYearsAction {
  type: typeof SET_FISCAL_YEARS
  payload: FiscalYear[]
}

export interface UpdateFiscalYearsAction {
  type: typeof UPDATE_FISCAL_YEAR
  payload: FiscalYear
}

export interface AddFiscalYearsAction {
  type: typeof ADD_FISCAL_YEAR
  payload: FiscalYear
}

export interface DeleteFiscalYearsAction {
  type: typeof DELETE_FISCAL_YEAR
  payload: FiscalYear
}

export type FiscalYearActionTypes =
  | SetFiscalYearsAction
  | UpdateFiscalYearsAction
  | AddFiscalYearsAction
  | DeleteFiscalYearsAction
