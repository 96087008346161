import { DownOutlined, DownloadOutlined, FileExcelOutlined, FileOutlined } from '@ant-design/icons'
import { Dropdown, Button, Space, Switch, Divider } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ToolBar from '../../../components/Misc/ToolBar'
import { tableDownload } from '../../../utils/tableExport'

const ToolBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface MultiCompanyToolbarProps {
  exportName?: string
  element: React.RefObject<HTMLDivElement>
  toggleCharts: (val: boolean) => void
  toggleRowsAndColumns?: (val: boolean) => void
}

const MultiToolbar: React.FC<MultiCompanyToolbarProps> = ({
  element,
  toggleCharts,
  toggleRowsAndColumns,
  exportName
}) => {
  const { t } = useTranslation()
  return (
    <ToolBar style={{ marginBottom: 8 }}>
      <ToolBarContainer>
        <Space>
          <Switch onChange={toggleCharts} />
          <span>{t('dashboardPage:show-graphs')}</span>
          {toggleRowsAndColumns && (
            <>
              <Divider style={{ margin: 10 }} />
              <Switch onChange={toggleRowsAndColumns} />
              <span>{t('customReportPage:switchRowsAndColumns')}</span>
            </>
          )}
        </Space>

        <Dropdown
          menu={{
            items: [
              {
                icon: <FileOutlined />,
                label: 'CSV',
                key: 'csv'
              },
              {
                icon: <FileExcelOutlined />,
                label: 'XLSX',
                key: 'xlsx'
              }
            ],
            onClick: e => tableDownload(element, exportName ?? 'dashboard', e.key)
          }}
        >
          <Button icon={<DownloadOutlined />}>
            <Space>
              {t('global:download')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </ToolBarContainer>
    </ToolBar>
  )
}

export default MultiToolbar
