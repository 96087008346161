import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

export const SET_SUBSCRIBED_KEY_FIGURES = 'SET_SUBSCRIBED_KEY_FIGURES'

export type SubscribedKeyFigure = { id: number }

export interface SetSubscribedKeyFiguresAction {
  type: typeof SET_SUBSCRIBED_KEY_FIGURES
  payload: KeyFigure[]
}

export type SubscribedKeyFiguresActionTypes = SetSubscribedKeyFiguresAction
