import i18next from 'i18next'
import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { Tooltip, Typography } from 'antd'
import { ReportDataType } from '../reports/types'
import { TimePeriodOptions } from './types'
import { CustomReportCategory } from './typesCategory'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'

const getPeriodGroupLabel = (c: CustomReportCategory) => {
  switch (c.value) {
    case TimePeriodOptions.CustomPeriod:
    case TimePeriodOptions.Past12months:
    case TimePeriodOptions.FiscalyearToDate:
    case TimePeriodOptions.PreviousFiscalyear:
    case TimePeriodOptions.PreviousFiscalyearToDate:
    case TimePeriodOptions.Currentfiscalyear:
    case TimePeriodOptions.PreviousYear:
    case TimePeriodOptions.Previousyeartodate:
    case TimePeriodOptions.YearToDate:
    case TimePeriodOptions.FromToDate:
    case TimePeriodOptions.Quarter:
    case TimePeriodOptions.CumulativeFiscalyear:
      return `${dayjs(c.startDate).tz().format('MM/YY')} - ${dayjs(c.endDate).tz().format('MM/YY')}`
    case TimePeriodOptions.CustomMonth:
    case TimePeriodOptions.FiscalyearFirstMonth:
    case TimePeriodOptions.CurrentMonth:
      return `${dayjs(c.endDate).tz().format('MM/YY')}`
    case TimePeriodOptions.CurrentYear:
    case TimePeriodOptions.CustomYear:
      return `${dayjs(c.endDate).tz().format('YYYY')}`
    case TimePeriodOptions.CurrentDay:
      return `${dayjs(c.startDate).tz().format('DD/MM/YYYY')}`
    case TimePeriodOptions.CurrentWeek:
      return `W${dayjs(c.startDate).tz().format('w')}/${dayjs(c.startDate).tz().format('YYYY')}`
    default:
      return i18next.t(`customReportPage:${c.value}`)
  }
}

export const keyFigureTrans = (c: CustomReportCategory) => {
  const keyFigureArr = Array.isArray(c.value) ? c.value : [c.value]
  let transLated: string = i18next.t(`keyFigureStatement:${keyFigureArr[0]}`)
  if (keyFigureArr.length > 1) {
    transLated += ` + ${keyFigureArr.length - 1}`
  }
  return transLated
}

export const transform = (t: string, c: CustomReportCategory) => {
  let title = t
  // eka date muutos startdateen. sum funktiolla datet löytyy childrenien (dimensioiden) childreneistä
  title = title?.replace(
    '{mm/yy}',
    `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('MM/​YY')}`
  )
  title = title?.replace(
    '{mm/yyyy}',
    `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('MM/​YYYY')}`
  )
  // toka date muutos enddateen. sum funktiolla datet löytyy childrenien (dimensioiden) childreneistä
  title = title?.replace('{mm/yy}', `${dayjs(c.endDate || c.children?.[0]?.children?.[0]?.endDate).format('MM/​YY')}`)
  title = title?.replace(
    '{mm/yyyy}',
    `${dayjs(c.endDate || c.children?.[0]?.children?.[0]?.endDate).format('MM/​YYYY')}`
  )

  // pvm
  title = title?.replace(
    '{dd/mm/yyyy}',
    `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('DD/​MM/​YYYY')}`
  )

  // päivä lyhyt
  title = title?.replace('{dd}', `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('dd')}`)
  title = title?.replace('{dd}', `${dayjs(c.endDate || c.children?.[0]?.children?.[0]?.endDate).format('dd')}`)

  // päivä pitkä
  title = title?.replace('{dddd}', `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('dddd')}`)
  title = title?.replace('{dddd}', `${dayjs(c.endDate || c.children?.[0]?.children?.[0]?.endDate).format('dddd')}`)

  // viikko
  title = title?.replace('{w}', `${dayjs(c.startDate || c.children?.[0]?.children?.[0]?.startDate).format('w')}`)
  title = title?.replace('{w}', `${dayjs(c.endDate || c.children?.[0]?.children?.[0]?.endDate).format('w')}`)
  title = title?.replace('{w/yyyy}', `W${dayjs(c.startDate).format('w')}/${dayjs(c.startDate).format('YYYY')}`)

  // vuosi
  title = title?.replace('{yyyy}', `${dayjs(c.endDate).format('YYYY')}`)

  // datatype
  title = title?.replace('{dataType}', `${i18next.t(`global:${c.dataType || ReportDataType.actuals}`)}`)
  // funktio
  title = title?.replace('{function}', `${i18next.t(`customReportPage:${c.value}`)}`)
  // skenaario
  title = title?.replace('{scenarioName}', `${c.budgetingScenarioName || c.budgetingScenario?.name || ''}`)

  // tunnusluku
  if (title.includes('{keyFigure}')) {
    if (c.value === 'commonSize') {
      title = c.children?.[0] ? title?.replace('{keyFigure}', `${keyFigureTrans(c.children[0])}`) : ''
    } else {
      title = title?.replace('{keyFigure}', `${keyFigureTrans(c)}`)
    }
  }

  return title
}

const textContent = (elem: React.ReactElement | string): string => {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }
  const children = elem.props && elem.props.children
  if (children instanceof Array) {
    return children.map(textContent).join('\n')
  }
  return textContent(children)
}

export const transformTitleReverse = (t: ReactNode, keyFigures: KeyFigure[]) => {
  let title = textContent(t as React.ReactElement | string)

  const weekRegex = /\bW(0[1-9]|[1-4][0-9]|5[0-3])\/(19|20)\d{2}\b/gi
  title = title?.replaceAll(weekRegex, '{w/yyyy}')

  const dateRegex = /\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}\b/gi
  title = title?.replaceAll(dateRegex, '{dd/mm/yyyy}')

  const longYearRegex = /\b(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}\b/gi
  title = title?.replaceAll(longYearRegex, '{mm/yyyy}')

  const shortYearRegex = /\b(0[1-9]|[12][0-9]|3[01])\/([0-9]{2})\b/gi
  title = title?.replaceAll(shortYearRegex, '{mm/yy}')

  const yearRegex = /\b(19|20)\d{2}\b/gi
  title = title?.replaceAll(yearRegex, '{yyyy}')

  title = title?.replaceAll(`${i18next.t(`global:${ReportDataType.actuals}`)}`, '{dataType}')
  title = title?.replaceAll(`${i18next.t(`global:${ReportDataType.budget}`)}`, '{dataType}')
  title = title?.replaceAll(`${i18next.t(`global:${ReportDataType.forecast}`)}`, '{dataType}')

  // tunnusluku
  for (const keyFigure of keyFigures) {
    title = title?.replaceAll(i18next.t(`keyFigureStatement:${keyFigure.id}`), `{keyFigure}`)
  }

  title = title?.replaceAll(`${i18next.t('customReportPage:growth')}`, '{function}')
  title = title?.replaceAll(`${i18next.t('customReportPage:diff')}`, '{function}')

  return title
}

export const transformTitle = (c: CustomReportCategory) => {
  let transformedTitle = transform(c.title || '', c)
  if (c.type === 'function' && c.children) {
    for (const child of c.children) {
      transformedTitle += transform(` ${c.value !== 'sum' ? child?.title || '' : ''}`, child)
    }
  }
  return transformedTitle
}

export const getReferenceTitle = (c?: CustomReportCategory, categories?: CustomReportCategory[]) => {
  const path = String(c?.value).substring(2)?.split('-')
  let title = categories?.[0].title || ''
  let route = categories?.[0].children
  for (const i of path) {
    title +=
      route &&
      ((route[Number(i)]?.title && ` / ${route[Number(i)]?.title} `) ||
        ` / ${i18next.t(`customReportPage:${route[Number(i)]?.value}`)}`)
    route = route && route[Number(i)].children
  }
  return title
}

export const getCustomReportTableCategoryTitle = (
  c?: CustomReportCategory,
  categories?: CustomReportCategory[]
): string | ReactNode => {
  if (!c) return ''
  const childrenCount = c?.children?.length || 0

  if (c.title) {
    return (
      <Typography.Text style={c.title ? { whiteSpace: 'break-spaces' } : { whiteSpace: 'normal' }}>
        {transformTitle(c)}
      </Typography.Text>
    )
  }

  switch (c.type) {
    case 'periodGroup':
      return (
        <div>
          <div>{getPeriodGroupLabel(c)}</div>
          <div style={{ opacity: 0.7, fontSize: '90%' }}>
            {`${i18next.t(`global:${c.dataType || ReportDataType.actuals}`)}${
              c.budgetingScenarioName ? ` (${c.budgetingScenarioName})` : ''
            }`}
          </div>
        </div>
      )
    case 'keyFigureId': {
      return <div>{keyFigureTrans(c)}</div>
    }
    case 'formulaId':
      return <div>{c.title}</div>
    case 'reference':
      return <div>{getReferenceTitle(c, categories)}</div>
    case 'function':
      switch (c.value) {
        case 'commonSize':
          return (
            <div>
              <div>%</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
            </div>
          )
        case 'division':
          return (
            <div>
              <div>{i18next.t(`customReportPage:division`)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
            </div>
          )
        case 'sum':
          return (
            <div>
              <div>{i18next.t('customReportPage:sum')}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0]?.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1]?.children?.[0], categories)}</div>
              <div>{childrenCount > 2 && `+ ${childrenCount - 2}`}</div>
            </div>
          )
        case 'diff':
          return (
            <div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
              <div style={{ opacity: 0.7, fontSize: '90%' }}>{i18next.t(`customReportPage:${c.value}`)}</div>
            </div>
          )
        case 'div':
          return (
            <div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
              <div style={{ opacity: 0.7, fontSize: '90%' }}>{i18next.t(`customReportPage:${c.value}`)}</div>
            </div>
          )
        default:
          return (
            <div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[0], categories)}</div>
              <div>{getCustomReportTableCategoryTitle(c.children?.[1], categories)}</div>
              <div style={{ opacity: 0.7, fontSize: '90%' }}>{i18next.t(`customReportPage:${c.value}`)}</div>
            </div>
          )
      }
    default:
      return `${c.title}`
  }
}

export const getCategoryString = (c?: CustomReportCategory | null, categories?: CustomReportCategory[]): string => {
  if (!c) {
    return ''
  }

  if (c.title) {
    return transformTitle(c)
  }

  switch (c.type) {
    case 'periodGroup':
      return `${getPeriodGroupLabel(c)} ${c.budgetingScenarioName ? ` (${c.budgetingScenarioName})` : ''}`
    case 'keyFigureId': {
      const keyFigureArr: any[] = Array.isArray(c.value) ? c.value : [c.value]
      let transLated: string = i18next.t(`keyFigureStatement:${keyFigureArr[0]}`)
      if (keyFigureArr.length > 1) {
        transLated += ` + ${keyFigureArr.length - 1}`
      }
      return transLated
    }
    case 'formulaId':
      return c.title ?? ''
    case 'reference':
      return getReferenceTitle(c, categories) || ''
    case 'function':
      switch (c.value) {
        case 'division':
          return `${i18next.t(`customReportPage:${c.value}`)} ~ ${getCategoryString(
            c.children?.[1],
            categories
          )} ${getCategoryString(c.children?.[0], categories)}`
        case 'commonSize':
          return `${i18next.t(`customReportPage:${c.value}`)} ~ ${getCategoryString(
            c.children?.[1],
            categories
          )} ${getCategoryString(c.children?.[0], categories)}`
        case 'sum':
          return `${i18next.t('customReportPage:sum')} - ${getCategoryString(
            c.children?.[0],
            categories
          )} / ${getCategoryString(c.children?.[0]?.children?.[0], categories)} ~ ${getCategoryString(
            c.children?.[1],
            categories
          )} / ${getCategoryString(c.children?.[1]?.children?.[0], categories)}`
        default:
          return `${i18next.t(`customReportPage:${c.value}`)} - ${getCategoryString(
            c.children?.[0],
            categories
          )} ~ ${getCategoryString(c.children?.[1], categories)}`
      }
    default:
      return `${c.title}`
  }
}

export const getCustomReportChartTitle = (c?: CustomReportCategory, categories?: CustomReportCategory[]): any => {
  if (!c) return ''

  if (c.title) {
    return transformTitle(c)
  }

  switch (c.type) {
    case 'periodGroup':
      if (c.dataType !== ReportDataType.actuals && c.dataType) {
        return `${getPeriodGroupLabel(c)} (${i18next.t(`global:${c.dataType || ReportDataType.actuals}`)}${
          c.budgetingScenarioName ? ` - ${c.budgetingScenarioName}` : ''
        })`
      }

      return `${getPeriodGroupLabel(c)}${c.budgetingScenarioName ? `(${c.budgetingScenarioName})` : ''}`
    case 'reference':
      return getReferenceTitle(c, categories)
    case 'function':
      switch (c.value) {
        case 'division':
          return `${i18next.t(`customReportPage:${c.value}`)} ~ ${getCustomReportChartTitle(
            c.children?.[1],
            categories
          )} <br/> ${getCustomReportChartTitle(c.children?.[0], categories)}`
        case 'commonSize':
          return `${i18next.t(`customReportPage:${c.value}`)} ~ ${getCustomReportChartTitle(
            c.children?.[1],
            categories
          )} <br/> ${getCustomReportChartTitle(c.children?.[0], categories)}`
        case 'sum':
          return `${getCustomReportChartTitle(
            c.children?.[0]?.children?.[0],
            categories
          )} ~ ${getCustomReportChartTitle(c.children?.[1]?.children?.[0], categories)} <br/> ${i18next.t(
            `customReportPage:${c.value}`
          )}`
        case 'div':
        case 'diff':
          return `${getCustomReportChartTitle(c.children?.[0], categories)} ~ ${getCustomReportChartTitle(
            c.children?.[1],
            categories
          )} <br/> ${i18next.t(`customReportPage:${c.value}`)}`
        default:
          return `${getCustomReportChartTitle(c.children?.[0], categories)} ~ ${getCustomReportChartTitle(
            c.children?.[1],
            categories
          )} <br/> ${i18next.t(`customReportPage:${c.value}`)}`
      }
    case 'keyFigureId':
      return i18next.t(`keyFigureStatement:${c.value}`)
    case 'formulaId':
    default:
      return `${c.title}`
  }
}

export const scenarioTitle = (columnCategory: CustomReportCategory) => {
  const tooltipText = `${columnCategory.budgetingScenario?.name || i18next.t('global:default')}`

  if (columnCategory.budgetingScenario?.forecastCursor) {
    tooltipText.concat(
      i18next.t('global:actuals_until', {
        date: dayjs(columnCategory.budgetingScenario?.forecastCursor).format('MM/YYYY')
      })
    )
  }

  return (
    <Tooltip title={tooltipText}>
      <span style={{ color: '#0225a1' }}>{getCustomReportTableCategoryTitle(columnCategory)}</span>
    </Tooltip>
  )
}
