import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import {
  ADD_BUDGETING_SCENARIO,
  BudgetingScenariosActionTypes,
  DELETE_BUDGETING_SCENARIO,
  SET_BUDGETING_SCENARIOS,
  UPDATE_BUDGETING_SCENARIO
} from './types'

const initialState: BudgetingScenario[] = []

const budgetinScenariosReducer = (state = initialState, action: BudgetingScenariosActionTypes) => {
  switch (action.type) {
    case SET_BUDGETING_SCENARIOS:
      return [...action.payload]
    case ADD_BUDGETING_SCENARIO:
      return [...state, action.payload]
    case UPDATE_BUDGETING_SCENARIO:
      return state.map((item: BudgetingScenario) =>
        item.id !== action.payload.id ? item : { ...item, ...action.payload }
      )
    case DELETE_BUDGETING_SCENARIO:
      return state.filter((item: BudgetingScenario) => item.id !== action.payload.id)
    default:
      return state
  }
}

export default budgetinScenariosReducer
