import { User } from '../../../types/user/User'

export const filterSalesUsers = (users: User[]) => {
  return users.filter(
    f =>
      f.email === 'saku.pollanen@finadeck.fi' ||
      f.email === 'elisa.nuutinen@finadeck.fi' ||
      f.email === 'toni.miettinen@finadeck.fi'
  )
}
