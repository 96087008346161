import React, { useContext } from 'react'
import { Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { LayoutsContext, LayoutsContextType } from './LayoutContext'

const LayoutButtons = () => {
  const { t } = useTranslation()
  const { setLayoutEdit, handleLayoutSubmit } = useContext(LayoutsContext) as LayoutsContextType

  return (
    <Space>
      <Button onClick={() => setLayoutEdit(false)}>{t('global:cancel')}</Button>
      <Button onClick={() => handleLayoutSubmit()} type="primary">
        {t('global:save')}
      </Button>
    </Space>
  )
}

export default LayoutButtons
