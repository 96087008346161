import { combineReducers } from 'redux'
import existingLoansReducer from './existing/reducer'
import newLoansReducer from './new/reducer'

const loansReducer = combineReducers({
  existing: existingLoansReducer,
  new: newLoansReducer
})

export default loansReducer
