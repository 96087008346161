import { Company } from '../../../types/company/Company'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { GET, GetRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { SetPersonnelAction, SET_PERSONNEL, UpdatePersonnelAction, UPDATE_PERSONNEL } from './types'

export const setPersonnel = (personnel: KeyFigureFact[]): SetPersonnelAction => ({
  type: SET_PERSONNEL,
  payload: personnel
})

export const updatePersonnel = (personnel: KeyFigureFact): UpdatePersonnelAction => ({
  type: UPDATE_PERSONNEL,
  payload: personnel
})

export const getPersonnelRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/settings/key-figures/personnel`,
    success: (payload: KeyFigureFact[]) => setPersonnel(payload)
  },
  meta: {
    type: 'PERSONNEL'
  }
})

export const updatePersonnelRequest = (companyId: Company['id'], payload: KeyFigureFact): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    url: `/api/companies/${companyId}/settings/key-figures/personnel`,
    success: (response: KeyFigureFact) => updatePersonnel(response)
  },
  meta: {
    type: 'UPDATE_PERSONNEL',
    notification: true
  }
})
