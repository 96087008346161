import dayjs from 'dayjs'
import { FormInstance } from 'antd'
import { BackendRequest } from '../../../../../services/backend'
import { AmendmentFormData } from './types'
import { store } from '../../../../../redux/store'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { dispatchFinancialStatementRequests } from '../../service'

export const handleCancel = (form?: FormInstance, setModalVisibility?: (visible: boolean) => void) => {
  if (form !== null && form) {
    form.resetFields()
  }
  setModalVisibility && setModalVisibility(false)
}

export const handleOk = (
  amendmentRequest: BackendRequest,
  companyId: string,
  setModalVisible: (visible: boolean) => void,
  form?: FormInstance
) => {
  if (form !== null && form) {
    form.validateFields().then((amendment: AmendmentFormData) => {
      if (!amendment.id) {
        amendmentRequest
          .post({
            urlParams: { companyId },
            body: {
              data: { ...amendment, date: new Date(dayjs(amendment.date).format('YYYY-MM-DD')) }
            }
          })
          .then(() => {
            dispatchFinancialStatementRequests()
            store.dispatch(
              notificationAction({
                type: 'success',
                message: 'CREATE_AMENDMENT_SUCCESS'
              })
            )
            handleCancel(form, setModalVisible)
          })
          .catch(() => {
            store.dispatch(
              notificationAction({
                type: 'error',
                message: 'CREATE_AMENDMENT_ERROR'
              })
            )
          })
      } else {
        amendmentRequest
          .put({
            urlParams: { companyId, amendmentId: amendment.id },
            body: {
              data: { ...amendment, date: new Date(dayjs(amendment.date).format('YYYY-MM-DD')) }
            }
          })
          .then(() => {
            dispatchFinancialStatementRequests()
            store.dispatch(
              notificationAction({
                type: 'success',
                message: 'UPDATE_AMENDMENT_SUCCESS'
              })
            )
            handleCancel(form, setModalVisible)
          })
          .catch(() => {
            store.dispatch(
              notificationAction({
                type: 'error',
                message: 'UPDATE_AMENDMENT_ERROR'
              })
            )
          })
      }
    })
  }
  return null
}
