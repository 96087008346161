import { DeliverySubscription } from '../../../types/deliverySubscription/DeliverySubscription'

export const SET_DELIVERY_SUBSCRIPTIONS = 'SET_DELIVERY_SUBSCRIPTIONS'
export const ADD_DELIVERY_SUBSCRIPTION = 'ADD_DELIVERY_SUBSCRIPTION'
export const UPDATE_DELIVERY_SUBSCRIPTION = 'UPDATE_DELIVERY_SUBSCRIPTION'
export const DELETE_DELIVERY_SUBSCRIPTION = 'DELETE_DELIVERY_SUBSCRIPTION'

export interface SetDeliverySubscriptionsAction {
  type: typeof SET_DELIVERY_SUBSCRIPTIONS
  payload: DeliverySubscription[]
}

export interface AddDeliverySubscriptionAction {
  type: typeof ADD_DELIVERY_SUBSCRIPTION
  payload: DeliverySubscription
}

export interface UpdateDeliverySubscriptionAction {
  type: typeof UPDATE_DELIVERY_SUBSCRIPTION
  payload: DeliverySubscription
}

export interface DeleteDeliverySubscriptionAction {
  type: typeof DELETE_DELIVERY_SUBSCRIPTION
  payload: DeliverySubscription
}

export type DeliverySubscriptionsActionTypes =
  | SetDeliverySubscriptionsAction
  | AddDeliverySubscriptionAction
  | DeleteDeliverySubscriptionAction
  | UpdateDeliverySubscriptionAction
