interface Person {
  key: string
  firstName: string
  lastName: string
  address: string
  country: string
}

const Person = ({ key = '', firstName = '', lastName = '', address = '', country = '' }): Person => ({
  key,
  firstName,
  lastName,
  address,
  country
})

interface PersonOwner {
  key: string
  firstName: string
  lastName: string
  address: string
  country: string
  share: number
}

const PersonOwner = ({
  key = '',
  firstName = '',
  lastName = '',
  address = '',
  country = '',
  share = 0
}): PersonOwner => ({
  key,
  firstName,
  lastName,
  address,
  country,
  share
})

interface CompanyOwner {
  key: string
  name: string
  vatId: string
  address: string
  country: string
  share: number
}

const CompanyOwner = ({ key = '', name = '', vatId = '', address = '', country = '', share = 0 }): CompanyOwner => ({
  key,
  name,
  vatId,
  address,
  country,
  share
})

export const Members = {
  Person,
  PersonOwner,
  CompanyOwner
}

export type GroupMember = Person | PersonOwner | CompanyOwner

export type MemberType = 'Person' | 'PersonOwner' | 'CompanyOwner'

export default class GroupMemberFactory {
  static createInstance(instance: MemberType, data = {}) {
    const elementCreator = Members[instance]

    const element = elementCreator(data)

    return element as GroupMember
  }
}
