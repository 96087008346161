import { MetaAction, NotificationAction, ErrorAction } from './types'
import { RequestError } from '../error/types'

export const metaAction = (type: string, payload?: any): MetaAction => ({
  type,
  payload
})

export const errorAction = (type: string, payload?: RequestError): ErrorAction => ({
  type,
  payload
})

export const notificationAction = (payload: {
  type: 'success' | 'info' | 'error'
  message: string
  description?: string
  duration?: number
}): NotificationAction => ({
  type: 'NOTIFICATION',
  payload
})
