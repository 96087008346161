/* eslint-disable react/no-unused-prop-types */
import { Tooltip, Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Loading } from '../../../../../components/Misc/Loading'
import ReportTable from '../../../../../components/Table/ReportTable'
import { ReportTableRow } from '../../../../../components/Table/types'
import { ToggleShowIncomeStatementPercentageChange } from '../../../../../redux/context/filters/actions'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { Column } from '../../../../../redux/context/periodGroups/hooks'
import {
  financialStatementLoadingSelector,
  roleBasedIncomeStatementSelector,
  financialStatementErrorSelector
} from '../../../../../redux/context/reports/selectors'
import { ReportRowType, ReportDataType } from '../../../../../redux/context/reports/types'
import { CompanyDashboardItem } from '../../../../../types/dashboard/company/types'
import { RenderBudgetCell } from '../../../../budgeting/financialStatements/components/budget/BudgetCell'
import IncomeStatementCellRenderer from '../../../../budgeting/financialStatements/components/IncomeStatementCellRenderer'
import { AppDispatch } from '../../../../../redux/store'

interface IncomeStatementProps {
  item: CompanyDashboardItem
  width: number
  height: number
}

export const IncomeStatement: React.FC<IncomeStatementProps> = ({ height }: IncomeStatementProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const report = useSelector(roleBasedIncomeStatementSelector)
  const loading = useSelector(financialStatementLoadingSelector)
  const financialStatementError = useSelector(financialStatementErrorSelector)
  const { showIncomeStatemetPercentageChange } = useSelector(filtersSelector)

  if (loading) return <Loading />

  const renderCell = (value: any, reportTableRow: ReportTableRow, column: Column) => {
    switch (reportTableRow.rowType) {
      case ReportRowType.budgetMenu:
        if (column.dataType === ReportDataType.budget || column.dataType === ReportDataType.forecast) {
          return {
            children: <RenderBudgetCell budgedMenuRow={reportTableRow} column={column} value={value} />,
            props: {
              className: reportTableRow.className + column.dataType
            }
          }
        }
        return undefined
      default:
        if (value === undefined) return undefined
        return <IncomeStatementCellRenderer value={value} column={column} reportTableRow={reportTableRow} />
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <Tooltip placement="topRight" title={t('financialStatementsPage:show-percentage')}>
        <Button
          onClick={() => dispatch(ToggleShowIncomeStatementPercentageChange())}
          style={{ marginRight: 10, float: 'right' }}
          size="small"
        >
          {showIncomeStatemetPercentageChange ? t('global:currency-symbol') : '%'}
        </Button>
      </Tooltip>
      <ReportTable
        enableCumulatives
        error={financialStatementError}
        toolbar={{ csvExport: false }}
        report={report}
        yScroll={height}
        renderCell={renderCell}
        rowClassName={reportRow => classNames({ bold: reportRow.isParent })}
      />
    </>
  )
}
