import React, { useEffect, useState } from 'react'
import { Layouts } from 'react-grid-layout'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { updateCustomReport } from '../../../../../redux/context/customReports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { makeInitialLayouts, BREAKPOINTS, removeItem, Breakpoint } from '../grid.utils'
import { calculatedReportSelector } from '../../../../../redux/context/customReports/selectors'
import { CustomReportSectionsType } from '../../../../../redux/context/customReports/typesSection'

export type LayoutsContextType = {
  layouts?: Layouts
  currentBreakPoint: string
  editedLayouts?: Layouts
  layoutEdit: boolean
  onSectionRemove: (section: CustomReportSectionsType) => void
  setLayoutEdit: (edit: boolean) => void
  setCurrentBreakPoint: (breakpoint: string) => void
  handleLayoutSubmit: (newLayouts?: Layouts) => void
  setEditedLayouts: (layouts?: Layouts) => void
}

export const LayoutsContext = React.createContext<LayoutsContextType | null>(null)

const LayoutsProvider: React.FC = ({ children }) => {
  const reportRequest = useBackend('/api/companies/{companyId}/reporting/custom/reports/{reportId}')
  const calculatedReport = useSelector(calculatedReportSelector)! // TODO: calculated pitää olla että voi editaoida layout
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const [layoutEdit, setLayoutEdit] = useState<boolean>(false)
  const [layouts, setLayouts] = useState(calculatedReport?.layouts)
  const [editedLayouts, setEditedLayouts] = useState<Layouts>()
  const [currentBreakPoint, setCurrentBreakPoint] = useState('lg')

  useEffect(() => {
    const initial = makeInitialLayouts(Object.keys(BREAKPOINTS) as Breakpoint[], calculatedReport?.sections)
    const newLayouts = calculatedReport?.sections?.length ? _.merge({}, initial, calculatedReport?.layouts) : undefined
    setLayouts(newLayouts)

    // Taulukoiden headerin korkeus ei tule oikein muuten, voisi olla parempikin keino tähän
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
    return () => {
      setLayouts(undefined)
    }
  }, [calculatedReport?.id])

  const handleLayoutSubmit = () => {
    reportRequest
      .put({
        urlParams: { companyId, reportId: calculatedReport?.id },
        body: {
          data: { layouts: editedLayouts }
        }
      })
      .then(() => {
        dispatch(updateCustomReport({ layouts: editedLayouts }))
        setEditedLayouts(undefined)
        // Taulukoiden headerin korkeus ei tule oikein muuten, voisi olla parempikin keino tähän
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 500)
      })

    setLayouts(editedLayouts)
    setLayoutEdit(false)
  }

  const onSectionRemove = (section: CustomReportSectionsType) => {
    setLayouts(removeItem(section.id.toString(), layouts))
  }

  return (
    <LayoutsContext.Provider
      value={{
        layouts,
        layoutEdit,
        editedLayouts,
        currentBreakPoint,
        setCurrentBreakPoint,
        setLayoutEdit,
        onSectionRemove,
        setEditedLayouts,
        handleLayoutSubmit
      }}
    >
      {children}
    </LayoutsContext.Provider>
  )
}

export default LayoutsProvider
