import { combineReducers } from 'redux'
import {
  SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS,
  SET_COMPETITOR_SELECTIONS,
  TOGGLE_COMPETITOR_SELECTION,
  SetKeyfigureSelectionsAction,
  CompetitorSelectionActionTypes
} from './types'
import { IndustryCompany } from '../../../types/industry/Industry'

const industryAndCompetitorComparisonReducer = combineReducers({
  // Selected keyfigures is shared with both pages
  selectedKeyfigures: (state: number[] = [], action: SetKeyfigureSelectionsAction) => {
    switch (action.type) {
      case SET_INDUSTRY_COMPETITOR_KEYFIGURE_SELECTIONS:
        return action.payload
      default:
        return state
    }
  },
  selectedCompetitors: (state: IndustryCompany[] = [], action: CompetitorSelectionActionTypes) => {
    switch (action.type) {
      case TOGGLE_COMPETITOR_SELECTION:
        return state.some(c => c.id === action.payload.id)
          ? state.filter(c => c.id !== action.payload.id)
          : state.concat(action.payload)
      case SET_COMPETITOR_SELECTIONS:
        return action.payload
      default:
        return state
    }
  }
})

export default industryAndCompetitorComparisonReducer
