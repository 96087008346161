import { CompanyDashboardItemType } from '../../../../types/dashboard/company/types'
import { Authorizable } from '../../../../utils/Authorizable/types'

export enum GraphType {
  line = 'line',
  spline = 'spline',
  column = 'column',
  stackedColumn = 'stackedColumn',
  groupedColumn = 'groupedColumn'
}

export enum MenuGroup {
  FINANCIAL_STATEMENTS,
  CASH_FLOW,
  KEY_FIGURES,
  VALUATION,
  INVOICES,
  SALES
}

export enum RepresentationType {
  table = 'table',
  chart = 'chart',
  info = 'info',
  pie = 'pie'
}

export enum MonthlyComparableType {
  sameMonthLastYear = 'sameMonthLastYear',
  previousMonth = 'previousMonth',
  inthisMonthfo = 'thisMonth'
}

export interface DashboardItemPreferenceRepresentation {
  type: RepresentationType
  options?: {
    type: GraphType
    comparableDataType?: 'actual' | 'budget'
    monthlyComparableType?: MonthlyComparableType
  }
  formFields: () => JSX.Element
}

export interface DashboardItemPreference extends Authorizable {
  name: string
  menuGroup: MenuGroup
  type: CompanyDashboardItemType
  translation: string
  request?: any
  contextRequest?: any
  params: {
    representation: DashboardItemPreferenceRepresentation[]
    record?: string
    [key: string]: any
  }
}

export interface DashboardItemPreferenceSelection {
  type: CompanyDashboardItemType
  menuGroup: MenuGroup
  translation: string
  request?: any
  contextRequest?: any
  params: {
    representation: DashboardItemPreferenceRepresentation
    record?: string
    [key: string]: any
  }
}
