import { Form, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportSectionsType, SectionType } from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import SectionCascader from './SectionCascader'

interface CustomReportSectionCopyFormProps {
  handleCopy: (section: Partial<CustomReportSectionsType>) => void
  handleReset: () => void
}

const CustomReportSectionCopyForm: React.FC<CustomReportSectionCopyFormProps> = ({ handleReset, handleCopy }) => {
  const { t } = useTranslation()
  const [newSectionMode, setNewSectionMode] = useState<'new' | 'copy'>('new')
  const [columns, setColumns] = useState<CustomReportCategory[]>()
  const [rows, setRows] = useState<CustomReportVariable[]>()
  const [type, setType] = useState<SectionType>('table')

  const handleChange = () => {
    const variables: CustomReportVariable[] = rows?.map(r => ({ ...r, id: undefined })) ?? []
    handleCopy({ categories: columns, variables, type })
  }

  useEffect(() => {
    handleChange()
  }, [columns, rows, type])

  const columnChange = (value: any, data: any) => {
    setColumns(data?.[data.length - 1].categories)
    setType(data?.[data.length - 1].type)
  }

  const rowChange = (value: any, data: any) => {
    setRows(data?.[data.length - 1].variables)
  }

  return (
    <Form name="sectionCopy" layout="vertical">
      <Form.Item>
        <Radio.Group
          onChange={e => {
            e.preventDefault()
            setNewSectionMode(e.target.value)
            if (e.target.value === 0) handleReset()
            if (e.target.value === 1) handleChange()
          }}
          value={newSectionMode}
        >
          <Space direction="vertical">
            <Radio value="new">{t('contractsPage:add-new')}</Radio>
            <Radio value="copy">{t('global:copy', { what: t('global:section').toLowerCase() })}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('global:table-column', { count: 2 })}>
        <SectionCascader onChange={columnChange} disabled={newSectionMode === 'new'} />
      </Form.Item>
      <Form.Item label={t('global:table-row', { count: 2 })}>
        <SectionCascader onChange={rowChange} disabled={newSectionMode === 'new'} />
      </Form.Item>
    </Form>
  )
}

export default CustomReportSectionCopyForm
