import { DashboardOutlined } from '@ant-design/icons'
import { Button, Empty, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

interface NoDashboardsProps {
  onClick: () => void
}

const NoDashboards: React.FC<NoDashboardsProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Empty
      image={
        <Text type="secondary">
          <DashboardOutlined style={{ fontSize: '48px' }} />
        </Text>
      }
      imageStyle={{ height: 60 }}
      description={<span>{t('dashboardPage:noDashboards')}</span>}
    >
      <Button type="primary" onClick={onClick}>
        {t('dashboardPage:createNow')}
      </Button>
    </Empty>
  )
}

export default NoDashboards
