// export interface ContextFiltersSections {

// }

export enum ContextFiltersSections {
  dimension = 'dimension',
  budgetScenario = 'budgetScenario',
  periodGroups = 'periodGroups',
  dataTypes = 'dataTypes',
  emptyRows = 'emptyRows'
}
