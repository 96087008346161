import { Space, Table, TableProps } from 'antd'
import { Key, RowSelectionType, TableRowSelection } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import scrollIntoView from 'scroll-into-view'

interface SelectionTableInputProps<T> extends Pick<TableProps<T>, 'columns' | 'dataSource' | 'loading'> {
  selectionType?: RowSelectionType
  value?: number[]
  onChange?: (value: number[]) => void
}

const SelectionTableInput = <T extends {}>({
  dataSource,
  columns,
  selectionType,
  value,
  loading,
  onChange
}: SelectionTableInputProps<T>) => {
  const { t } = useTranslation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>()

  const triggerChange = (changedValue: Key[]) => {
    onChange?.(changedValue as number[])
  }

  useEffect(() => {
    // when radio take first selection
    if (selectionType === 'radio') {
      value?.[0] ? triggerChange([value?.[0]]) : triggerChange([])
    }
    // auto scroll into first selected row
    const el = document.querySelector('.ant-table-row-selected') as HTMLElement
    if (el) {
      scrollIntoView(el, {
        align: {
          top: 0
        },
        validTarget(target, parentsScrolled) {
          return parentsScrolled < 2
        }
      })
    }
  }, [selectionType])

  const onValueChange = (val: Key[]) => {
    setSelectedRowKeys(val as number[])
    triggerChange(val)
  }

  const rowSelection: TableRowSelection<T> = {
    type: selectionType || 'checkbox',
    selectedRowKeys: value || selectedRowKeys,
    onChange: onValueChange
  }

  const renderTableInfo = () => {
    const rowCount = selectedRowKeys?.length || value?.length || 0
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          <span>{rowCount > 0 ? t('global:rowsSelected', { count: rowCount }) : ''}</span>
        </Space>
      </div>
    )
  }

  return (
    <>
      <Table
        className="autoScroll"
        bordered
        loading={loading}
        scroll={{ y: 250 }}
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        pagination={false}
      />
      {selectionType === 'radio' ? null : renderTableInfo()}
    </>
  )
}

export default SelectionTableInput
