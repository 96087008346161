import { GET, GetRequestAction, REQUEST } from '../../../middleware/types'
import { ResetLoansAction, RESET_LOANS, SetLoansSummaryAction, SET_NEW_LOANS_SUMMARY } from '../types'
import { BudgetingScenario } from '../../../../types/budgetingScenario/BudgetingScenario'
import { Company } from '../../../../types/company/Company'
import { LoanSummaryReport } from '../../../../pages/budgeting/loans/types'

export const setNewLoansSummary = (summary: LoanSummaryReport): SetLoansSummaryAction => ({
  type: SET_NEW_LOANS_SUMMARY,
  payload: summary
})

export const resetNewLoans = (): ResetLoansAction => ({
  type: RESET_LOANS,
  payload: null
})

export const getNewLoansSummaryRequest = (
  companyId: Company['id'],
  date: string,
  budgetingScenarioId: BudgetingScenario['id'] | undefined
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { date, isExisting: false, budgetingScenarioId },
    url: `/api/companies/${companyId}/budgeting/loans/summary`,
    success: setNewLoansSummary
  },
  meta: {
    type: `LOANS`
  }
})
