import React from 'react'
import i18next from 'i18next'

export const CompanyLiteSteps = [
  {
    content: () => (
      <div>
        <h3>{i18next.t('tour:welcome')}</h3>
        <p color="#e5e5e5">{i18next.t('tour:tips')}</p>
      </div>
    )
  },
  {
    selector: '.settings-menu',
    content: () => (
      <div>
        <h3>{i18next.t('tour:company-settings')}</h3>
        <ol style={{ textAlign: 'left' }} color="#e5e5e5">
          <li>{i18next.t('tour:add-users')}</li>
          <li>{i18next.t('tour:apis')}</li>
          <li>{i18next.t('tour:keyfigures')}</li>
        </ol>
      </div>
    )
  },
  {
    selector: '.context-drawer-handle',
    content: () => (
      <div>
        <h3>{i18next.t('tour:magnifier')}</h3>
        <ul style={{ textAlign: 'left' }} color="#e5e5e5">
          <li>{i18next.t('tour:dimensions')}</li>
          <li>{i18next.t('tour:date-filters')}</li>
          <li>{i18next.t('tour:view-settings')}</li>
        </ul>
      </div>
    )
  },
  {
    selector: '.avatar-menu',
    content: () => (
      <div>
        <p style={{ marginBottom: 10 }}>{i18next.t('tour:user-manual')}</p>
        <p style={{ marginBottom: 10 }}>{i18next.t('tour:questions')}</p>
        <p style={{ marginBottom: 10 }}>{i18next.t('tour:greetings')}</p>
        <p>{i18next.t('tour:team-finadeck')}</p>
      </div>
    )
  }
]
