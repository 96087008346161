import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { useBackend } from '../../../../services/backend'
import { notificationAction } from '../../../../redux/middleware/actions'
import { Loading } from '../../../../components/Misc/Loading'
import SuperUserUserTable from '../../users/components/SuperUserUserTable'
import { Contract } from '../../../../types/contract/Contract'
import { SuperUserContractDelete } from './SuperUserContractDelete'
import SuperUserContractBasics from './SuperUserContractBasics'
import SuperUserContractTable from './SuperUserContractTable'
import SuperUserManagement from './SuperUserManagement'
import { AppDispatch } from '../../../../redux/store'

const SuperUserContract: React.FC = () => {
  const { id } = useParams() as any
  const getRequest = useBackend(`/api/superuser/contracts/{id}`)
  const { loading } = getRequest
  const dispatch: AppDispatch = useDispatch()

  const [contract, setContract] = useState<Contract>()

  const request = () => {
    getRequest
      .get({ urlParams: { id } })
      .then(setContract)
      .catch((e: any) => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'CONTRACT_ERROR',
            description: e.message
          })
        )
      })
  }

  useEffect(request, [id])

  const items = contract && [
    { key: 'Tab-1', label: 'Perustiedot', children: <SuperUserContractBasics contract={contract} /> },
    { key: 'Tab-2', label: 'Käyttäjät', children: <SuperUserUserTable users={contract.users} /> },
    { key: 'Tab-3', label: 'Alisopimukset', children: <SuperUserContractTable contracts={contract.children} /> },
    { key: 'Tab-4', label: 'Hallinto', children: <SuperUserManagement contract={contract} /> },
    { key: 'Tab-5', label: 'Danger-zone', children: <SuperUserContractDelete contract={contract} /> }
  ]

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        contract && (
          <>
            <Typography.Title level={3}>{contract.name}</Typography.Title>
            <Tabs defaultActiveKey="1" items={items} />
          </>
        )
      )}
    </div>
  )
}

export default SuperUserContract
