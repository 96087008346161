import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Tag } from 'antd'
import { FormulaErrorType } from '../../../../../../types/formula/Formula'

type FormulaSuggestionProps = { error: FormulaErrorType }

const FormulaSuggestion = ({ error }: FormulaSuggestionProps) => {
  const { t } = useTranslation()
  const { type, expected, received } = error

  const getSuggestion = () => {
    switch (type) {
      case 'ARGUMENT_ERROR':
        return t(`formulaError:${type}`, { count: expected as any })
      case 'SYNTAX_ERROR':
        return (
          <>
            <span>{t('formulaPage:suggestion')}</span>
            {(expected || []).map(expectation => (
              <Tag key={expectation}>{expectation}</Tag>
            ))}
          </>
        )
      case 'TYPE_ERROR':
        return (
          <>
            <span>{t('formulaPage:suggestion')}</span>
            {(expected || []).map(expectation => (
              <Tag key={expectation}>{expectation}</Tag>
            ))}
          </>
        )
      case 'LEXICAL_ERROR':
        return `${received} ${t('formulaPage:isNotValid')}`
      case 'SYMBOL_NOT_FOUND_ERROR':
        return null
      default:
        return null
    }
  }

  return getSuggestion() ? <Alert style={{ marginBottom: 8 }} message={getSuggestion()} type="info" showIcon /> : null
}

export default FormulaSuggestion
