import React, { useContext } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Input, Radio, Form } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { contractProductsSelector } from '../../../redux/entities/contractProducts/selectors'
import { ContractFormContext, ContractFormContextType } from './ContractFormContext'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}
interface ProductStepProps {
  showBackButton?: boolean
}

const ProductStep: React.FC<ProductStepProps> = ({ showBackButton = true }) => {
  const { t } = useTranslation()
  const contractProducts = useSelector(contractProductsSelector)
  const { setStep } = useContext(ContractFormContext) as ContractFormContextType

  const productInfoLink = (
    <Link to="https://finadeck.fi/hinnasto/" target="_blank" rel="noopener noreferrer">
      <InfoCircleOutlined style={{ marginLeft: 8 }} />
    </Link>
  )

  return (
    <>
      <Form.Item
        name={['contract', 'product']}
        preserve
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        label={
          <>
            {t('contractsPage:product')}
            {productInfoLink}
          </>
        }
      >
        <Radio.Group>
          {Object.values(contractProducts).map((product: string) => (
            <Radio key={product} style={radioStyle} value={product}>
              {t(`contractProduct:${product}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['contract', 'additionalInformation']}
        preserve
        label={t('contractsPage:additional-information')}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" onClick={() => setStep(1)}>
          {t('contractsPage:continue')}
        </Button>
        {showBackButton ? (
          <Button type="default" onClick={() => setStep(-1)} style={{ marginLeft: 8 }}>
            {t('contractsPage:back')}
          </Button>
        ) : null}
      </Form.Item>
    </>
  )
}

export default ProductStep
