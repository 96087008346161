import { Adjustment } from '../../../types/valuation/Valuation'

export const UPDATE_VALUATION_ADJUSTMENTS = 'UPDATE_VALUATION_ADJUSTMENTS'
export const SET_VALUATION_ADJUSTMENTS = 'SET_VALUATION_ADJUSTMENTS'

export interface SetValuationAdjustmentsAction {
  type: typeof SET_VALUATION_ADJUSTMENTS
  payload: Adjustment
}

export interface UpdateValuationAdjustmentsAction {
  type: typeof UPDATE_VALUATION_ADJUSTMENTS
  payload: Adjustment
}

export type ValuationAdjustmentsActionTypes = UpdateValuationAdjustmentsAction | SetValuationAdjustmentsAction
