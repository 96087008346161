// export const drawableKeyFigureIds = [
//   1,
//   3,
//   4,
//   5,
//   6,
//   12,
//   14,
//   15,
//   38,
//   39,
//   40,
//   44,
//   45,
//   46,
//   49,
//   50,
//   51,
//   52,
//   53,
//   54,
//   55,
//   58,
//   59,
//   60,
//   100,
//   101,
//   102,
//   103,
//   104,
//   105,
//   106,
//   107,
//   108,
//   109,
//   110,
//   111,
//   112,
//   113,
//   114,
//   133,
//   134,
//   135,
//   136,
//   137,
//   138,
//   139,
//   140
// ]

import { VariableType } from '../../../components/Table/types'

export const internalKeyFigureTypes = ['absolute', 'percentage', 'ratio'] as VariableType[]

export const budgetDriverKeyFigureTypes = ['absolute', 'percentage']
