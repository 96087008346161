import { globalKeyFigureConfig } from '../pages/dashboard/multiPurposeDashboard/config'
import { Report } from '../redux/context/reports/types'
import { Company } from '../types/company/Company'

export const mapToGlobalKeyFigureStatement = (keyFigureStatement?: Report): Report => {
  if (!keyFigureStatement) return []
  return keyFigureStatement?.map(row => {
    let foundId = null

    for (const cfg of globalKeyFigureConfig) {
      if (Object.values(cfg?.se || {}).includes(row.id)) {
        foundId = cfg?.id
      }
      if (Object.values(cfg?.fi || {}).includes(row.id)) {
        foundId = cfg?.id
      }
    }
    if (!row.group || !foundId) return row

    return {
      ...row,
      id: foundId
    }
  })
}

export const getCompanysKeyFigureId = (company?: Company, keyfigureId?: number): number | undefined => {
  if (!company || !keyfigureId) return undefined
  return globalKeyFigureConfig.find(g => g.id === keyfigureId)?.[company.country][company.form]
}

export const getCompanysKeyFigureIds = (company?: Company, keyfigures?: number[]) => {
  if (!company || !keyfigures) return []
  return keyfigures.map(k => getCompanysKeyFigureId(company, k)).filter(k => !!k) as number[]
}
