import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import React from 'react'

const { Paragraph } = Typography

const FormulaHelpTips = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h3>{t('formulaHelp:tipsHeader1')}</h3>
      <Paragraph>{t('formulaHelp:tipsText1')}</Paragraph>
      <Paragraph>{t('formulaHelp:tipsText2')}</Paragraph>
      <Paragraph>{t('formulaHelp:tipsText3')}</Paragraph>

      <h3>{t('formulaHelp:tipsHeader2')}</h3>
      <Paragraph>{t('formulaHelp:tipsText4')}</Paragraph>
      <Paragraph>{t('formulaHelp:tipsText5')}</Paragraph>
      <Paragraph>{t('formulaHelp:tipsText6')}</Paragraph>
      <Paragraph>{t('formulaHelp:tipsText7')}</Paragraph>
    </div>
  )
}

export default FormulaHelpTips
