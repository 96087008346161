import React from 'react'
import { Checkbox, DatePicker, Form, Input, Radio, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import DimensionSelect from '../../../../../../components/Dimension/DimensionSelect'
import {
  ReallocationSource,
  ReallocationType,
  SourcePeriodType
} from '../../../../../../types/reallocation/Reallocation'
import KeyFigureInput from '../../../../../../components/inputs/KeyFigureInput'
import { ReportDataType } from '../../../../../../redux/context/reports/types'
import FormulaInput from '../../../../../../components/inputs/FormulaInput'

const ReallocationFormFields = () => {
  const { t } = useTranslation()
  const form = Form.useFormInstance<ReallocationSource>()
  const typeValue = Form.useWatch(['type'], form)
  const periodType = Form.useWatch(['periodType'], form)

  return (
    <>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={t('global:description')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="dimensionId" label={t('reallocationsPage:reallocationDimension')}>
        <DimensionSelect />
      </Form.Item>

      <Form.Item
        name="periodType"
        label={t('reallocationsPage:periodType')}
        initialValue={SourcePeriodType.indefinitely}
      >
        <Radio.Group>
          <Radio value={SourcePeriodType.indefinitely}>{t('global:indefinitely')}</Radio>
          <Radio value={SourcePeriodType.fixedPeriod}>{t('global:fixedPeriod')}</Radio>
        </Radio.Group>
      </Form.Item>
      {periodType === SourcePeriodType.fixedPeriod && (
        <>
          <Form.Item
            name="startDate"
            label={t('global:startDate')}
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <DatePicker picker="month" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="endDate"
            label={t('global:endDate')}
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <DatePicker picker="month" style={{ width: '100%' }} />
          </Form.Item>
        </>
      )}
      <Form.Item label={t('global:dataType')}>
        <Form.Item noStyle name={ReportDataType.actuals} label={t('global:actuals')} valuePropName="checked">
          <Checkbox>{t('global:actuals')}</Checkbox>
        </Form.Item>
        <Form.Item noStyle name={ReportDataType.budget} label={t('global:budget')} valuePropName="checked">
          <Checkbox>{t('global:budget')}</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item initialValue={ReallocationType.fixed} name="type" label={t('global:type')}>
        <Select>
          {Object.entries(ReallocationType).map(([type]) => (
            <Select.Option key={type} value={type}>
              {t(`reallocationsPage:${type}`, type)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {typeValue === ReallocationType.keyFigureId && (
        <Form.Item name="keyFigureId" label={t('global:keyFigure')}>
          <KeyFigureInput />
        </Form.Item>
      )}
      {typeValue === ReallocationType.formulaId && (
        <Form.Item name="formulaId" label={t('global:formula')}>
          <FormulaInput />
        </Form.Item>
      )}
    </>
  )
}

export default ReallocationFormFields
