/* eslint-disable no-nested-ternary */
import { Card, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { ReportTableRow } from '../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import {
  getCashFlowExplainerRequest,
  getCashFlowStatementRequest,
  resetReports
} from '../../../redux/context/reports/actions'
import {
  cashFlowStatementLoadingSelector,
  cashFlowByKeyStatementSelector
} from '../../../redux/context/reports/selectors'
import CashFlowChartContainer from './CashFlowChartContainer'
import { renderCell } from './utils'
import { contextRequest } from '../../../redux/context/actions'
import { CashFlowStatementRepresentationType } from '../../../redux/context/reports/types'
import { cashFlowKeysSelector } from '../../../redux/session/cashFlow/selectors'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import { errorSelector } from '../../../redux/error/selectors'
import ReportTable from '../../../components/Table/ReportTable'
import { updateRowKeys } from '../../../redux/session/cashFlow/actions'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { loadingSelector } from '../../../redux/loading/selectors'
import { AppDispatch } from '../../../redux/store'
import { appLanguageSelector } from '../../../redux/app/selectors'

const CashFlow: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const loading = useSelector(cashFlowStatementLoadingSelector)
  const loadingCashFlow = useSelector(loadingSelector)
  const cashFlowSelectedKeys = useSelector(cashFlowKeysSelector)
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const language = useSelector(appLanguageSelector)

  const cashFlowStatementSelector = useSelector(cashFlowByKeyStatementSelector)
  const error = useSelector(errorSelector)

  const [cashFlowStatement, setCashFlowStatement] = useState(cashFlowStatementSelector)

  useEffect(() => {
    if (companyId) {
      dispatch(updateRowKeys([]))
      dispatch(contextRequest(getCashFlowStatementRequest))
    }

    return () => {
      dispatch(resetReports())
      dispatch(updateRowKeys([]))
    }
  }, [companyId, budgetingScenario, dimensions, periodGroups, language])

  const getNewCashFlowStatement = () => {
    setCashFlowStatement(cashFlowStatementSelector)
  }

  useEffect(getNewCashFlowStatement, [cashFlowSelectedKeys, cashFlowStatementSelector])

  const expandable = {
    expandIcon: ({ expanded, onExpand, record }: any) => {
      return !record.children ? undefined : loadingCashFlow[`cashFlowExplainer${record.id}`] ? (
        <LoadingOutlined style={{ marginRight: 12 }} />
      ) : expanded ? (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      ) : (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      )
    },
    onExpand: (exp: any, record: any) => {
      const { id } = record
      const index = cashFlowSelectedKeys.indexOf(id)
      index < 0
        ? dispatch(updateRowKeys([...cashFlowSelectedKeys, id]))
        : dispatch(updateRowKeys(cashFlowSelectedKeys.filter((k, i) => index !== i)))

      exp &&
        !record.explanation &&
        record.children.length <= 0 &&
        !record.noExplainer &&
        companyId &&
        dispatch(contextRequest(getCashFlowExplainerRequest, record as unknown as KeyFigure))
    },
    rowExpandable: () => true,
    expandedRowKeys: cashFlowSelectedKeys.map(String)
  }

  return (
    <PageHeaderWrapper
      title={t('menu:/reporting/cash-flow')}
      breadcrumb={false}
      subTitle={
        <Tooltip placement="right" title="info">
          <DocumentationLink />
        </Tooltip>
      }
      ghost={false}
    >
      <Card>
        <LoadingWrapper loading={loading}>
          <CashFlowChartContainer
            representationType={'RAHOITUSLASKELMA_INDIRECT' as CashFlowStatementRepresentationType}
            error={error?.cashFlowStatement}
          />
          <ReportTable
            report={
              cashFlowStatement
                .filter(r => !r.exclude)
                .map(row => ({
                  ...row,
                  children: row.differenceRow ? undefined : [...(row.children || [])]
                })) || []
            }
            error={error?.cashFlowStatement}
            renderCell={renderCell}
            rowKey={(reportTableRow: ReportTableRow) => String(reportTableRow.id)}
            expandable={expandable}
            rowClassName={(reportTableRow: ReportTableRow) => {
              return reportTableRow.bold ? 'bold' : ''
            }}
            toolbar={{
              csvExport: true
            }}
          />
        </LoadingWrapper>
      </Card>
    </PageHeaderWrapper>
  )
}

export default CashFlow
