import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import RestolutionApi from './RestolutionApi'
import { useDocsURL } from '../../../../../../utils/docs'
import DimensionMap from '../dimensionMap/DimensionMap'

const Restolution: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: <RestolutionApi />
    },
    {
      label: t('integrationsPage:restaurants'),
      key: 'restaurants',
      children: (
        <DimensionMap
          getDimensionsUrl="/restolution/restaurants/{companyId}"
          updateDimensionsUrl="/restolution/restaurants/{companyId}/{id}"
          dataKey="businessUnitUUID"
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#restolution`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Restolution
