/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import FormItem from 'antd/es/form/FormItem'
import { Input } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ReportTableRow, VariableType } from '../../../../../components/Table/types'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { Column } from '../../../../../redux/context/periodGroups/hooks'
import { financialStatementsPageSelector } from '../../../../../redux/pages/budgeting/financialStatements/selectors'
import { formatValueByType } from '../../../../../utils/helpers'
import { isBudgetDriverMethod } from '../../../../../components/Budgeting/BudgetingMethodBudgetDriver'
import './_BudgetCell.sass'

interface BudgetCellProps {
  budgedMenuRow: ReportTableRow
  column: Column
  value: number
}

/** Raporttitaulun budjettisolu
 */
export const RenderBudgetCell = ({ budgedMenuRow, column, value }: BudgetCellProps) => {
  const { budgetsInEdit } = useSelector(financialStatementsPageSelector)
  const form = useFormInstance()
  const { variableType } = budgedMenuRow

  const budgedMenuRowKey = budgedMenuRow.key.split('-budget-menu')[0]

  const namePath = [
    isBudgetDriverMethod(budgedMenuRow) ? 'budgetDriver' : 'budget',
    `${budgedMenuRowKey}`,
    column.label
  ]

  useEffect(() => {
    const resetForm = () => {
      // Alustetaan vain tämän rivin kentät
      const fields = [
        'variableType',
        'accountCode',
        'incomeStatementRowId',
        'code',
        'dimensionId',
        'formulaId',
        'dataType'
      ]
      form.resetFields(fields.map(name => namePath.concat(name)))
    }

    resetForm()
  }, [budgedMenuRow])

  const isBudgetDriver = () => {
    if (isBudgetDriverMethod(budgedMenuRow)) {
      return true
    }
    return false
  }

  const getDefaultValue = () => {
    const defVal = Number(String(value).replace(/\s/g, '').replace(',', '.'))
    if (value && budgedMenuRow.style?.decimals) {
      return defVal
    }
    return Math.round(defVal)
  }

  const isEditing = Object.entries(budgetsInEdit)
    .filter(([, val]) => val)
    .map(([key]) => key)
    .some(key => key === budgedMenuRow.key || key === budgedMenuRowKey)

  if (isEditing) {
    const percentInput = (
      <FormattedInputNumber
        percentage
        onFocus={event => event.currentTarget.select()}
        onClick={event => event.currentTarget.select()}
        size="small"
        step={1}
        addonAfter="%"
      />
    )

    const absoluteInput = (
      <FormattedInputNumber
        onFocus={event => event.currentTarget.select()}
        onClick={event => event.currentTarget.select()}
        style={{ width: '100%', height: '100%', padding: 0, border: 0 }}
        size="small"
      />
    )

    return (
      <>
        <FormItem
          noStyle
          name={namePath.concat('value')}
          preserve={false}
          initialValue={variableType === VariableType.percentage ? value : getDefaultValue()}
        >
          {variableType === VariableType.percentage ? percentInput : absoluteInput}
        </FormItem>
        <FormItem name={namePath.concat('variableType')} initialValue={budgedMenuRow.variableType} hidden noStyle>
          <Input />
        </FormItem>
        <FormItem name={namePath.concat('accountCode')} initialValue={budgedMenuRow.accountCode} hidden noStyle>
          <Input />
        </FormItem>
        <FormItem
          name={namePath.concat('incomeStatementRowId')}
          initialValue={budgedMenuRow.incomeStatementRowId}
          hidden
          noStyle
        >
          <Input />
        </FormItem>
        <FormItem name={namePath.concat('code')} initialValue={budgedMenuRow.code.toString()} hidden noStyle>
          <Input />
        </FormItem>
        <FormItem name={namePath.concat('dimensionId')} initialValue={budgedMenuRow.dimensionId} hidden noStyle>
          <Input />
        </FormItem>
        {isBudgetDriver() && (
          <>
            <FormItem name={namePath.concat('formulaId')} initialValue={budgedMenuRow.id} hidden noStyle>
              <Input />
            </FormItem>
            <FormItem name={namePath.concat('dataType')} initialValue={column.dataType} hidden noStyle>
              <Input />
            </FormItem>
          </>
        )}
      </>
    )
  }

  return (
    <div className="cell-wrapperds">
      <span>
        {formatValueByType(
          value,
          budgedMenuRow.variableType,
          budgedMenuRow.style?.decimals
            ? {
                maximumFractionDigits: budgedMenuRow.style?.decimals,
                minimumFractionDigits: budgedMenuRow.style?.decimals
              }
            : budgedMenuRow.variableType === VariableType.percentage
            ? { maximumFractionDigits: 2 }
            : undefined
        )}
      </span>
    </div>
  )
}
