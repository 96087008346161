import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Result, Tabs, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { VismanetApiCredentials } from '../types'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { useDocsURL } from '../../../../../utils/docs'
import Loading from '../../../../loading/Loading'
import { AppDispatch } from '../../../../../redux/store'
import DataUpdate from '../dataUpdate'

const Vismanet: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/vismanet/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setCredentials = useBackend(`/vismanet/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const dispatch: AppDispatch = useDispatch()

  const [tenantId, setTenantId] = useState<string>()
  const [authorized, setAuthorized] = useState<boolean>(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((credentials: VismanetApiCredentials) => {
        if (credentials.tenantId) {
          setAuthorized(true)
        } else {
          setTenantId('')
          setAuthorized(false)
        }
      })
      .catch((err: any) => {
        setTenantId('')
        setAuthorized(false)
        console.log(err)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const submit = () => {
    setCredentials
      .put({
        urlParams: { companyId },
        body: {
          data: {
            tenantId
          }
        }
      })
      .then((credentials: VismanetApiCredentials) => {
        if (credentials.tenantId) {
          setAuthorized(true)
        }
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR'
          })
        )
      })
  }

  const authButton = () => {
    return (
      <>
        <Button type="primary" onClick={() => setAuthorized(false)}>
          {t('integrationsPage:authenticate')}
        </Button>
        <DataUpdate page="vismanet" />
      </>
    )
  }

  if (getCredentials.loading) {
    return <Loading />
  }

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'item-1',
      children: authorized ? (
        <>
          <Result status="success" title={t('integrationsPage:authenticated')} extra={authButton()} />
        </>
      ) : (
        <Form layout="vertical">
          <Form.Item label="TenantId">
            <Input
              value={tenantId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTenantId(event.target.value)}
              suffix={getCredentials.loading && <LoadingOutlined />}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={() => submit()} loading={setCredentials.loading}>
              {t('integrationsPage:authenticate')}
            </Button>
          </Form.Item>
        </Form>
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#vismanet`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs defaultActiveKey="item-1" items={items} />
    </>
  )
}

export default Vismanet
