import React from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { KeyFigure } from '../../types/keyFigure/KeyFigure'

interface KeyFigureSelectionProps {
  keyFigureTranslation?: 'globalKeyFigureStatement' | 'keyFigureStatement'
  selectableKeyFigures: KeyFigure[] | any
  selectedKeyfigures: number[]
  onChange: (keyFigureSelections: number[]) => void
}

const KeyFigureSelection: React.FC<KeyFigureSelectionProps> = ({
  keyFigureTranslation = 'globalKeyFigureStatement',
  selectedKeyfigures,
  selectableKeyFigures,
  onChange
}) => {
  const { t } = useTranslation()

  const options = Object.entries(_.groupBy(selectableKeyFigures, 'group')).map(([groupId, groupKeyFigures]) => ({
    label: t(`keyFigurePage:${groupId}`),
    options: groupKeyFigures.map((keyF: KeyFigure) => ({
      label: t(`${keyFigureTranslation}:${keyF.id}`),
      value: keyF.id
    }))
  }))

  return (
    <Select
      style={{ width: '100%' }}
      mode="multiple"
      value={selectedKeyfigures}
      onChange={onChange}
      options={options}
    />
  )
}

export default KeyFigureSelection
