import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  getSubscribedKeyFiguresRequest,
  updateSubscribedKeyFiguresRequest
} from '../../../../../redux/context/subscribedKeyFigures/actions'
import {
  subscribedKeyFiguresLoadingSelector,
  subscribedKeyFiguresSelector
} from '../../../../../redux/context/subscribedKeyFigures/selectors'
import { SubscribedKeyFigure } from '../../../../../redux/context/subscribedKeyFigures/types'
import SubscribedKeyFiguresForm from './components/SubscribedKeyFiguresForm'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { contextRequest } from '../../../../../redux/context/actions'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../../../redux/store'

const Subscribed: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const subscribedKeyFigures = useSelector(subscribedKeyFiguresSelector)
  const { budgetingScenario, periodGroups } = useSelector(filtersSelector)
  const loading = useSelector(subscribedKeyFiguresLoadingSelector)

  useEffect(() => {
    dispatch(contextRequest(getSubscribedKeyFiguresRequest))
  }, [companyId, budgetingScenario, periodGroups, dispatch])

  const handleSave = (selectedKeyFigures: SubscribedKeyFigure[]) => {
    companyId && dispatch(updateSubscribedKeyFiguresRequest(companyId, selectedKeyFigures))
  }

  return (
    <LoadingWrapper loading={loading}>
      <SubscribedKeyFiguresForm subscribedKeyFigures={subscribedKeyFigures} handleSave={handleSave} />
    </LoadingWrapper>
  )
}

export default Subscribed
