import classNames from 'classnames'
import { useSelector } from 'react-redux'
import i18next from 'i18next'
import { BudgetingLevel, CompanySettings } from '../../../types/companySettings/CompanySettings'
import { Contract, ContractProduct } from '../../../types/contract/Contract'
import { getHumanReadableArrayText, recursiveFind } from '../../../utils/helpers'
import { Report, ReportRow, ReportRowType, ReportType } from './types'
import { Period } from '../../../types/fiscalYear/FiscalYear'
import { filtersSelector } from '../filters/selectors'
import { requireContractProductExact } from '../../../components/Misc/RestrictedProduct'
import { Dimension } from '../../../types/dimension/Dimension'
import { ActualRowType } from '../../../components/Table/hooks'
import { VariableType } from '../../../components/Table/types'
import { BudgetingMethod } from '../../../pages/budgeting/financialStatements/components/budget/types'

export const getChildrenRowClassName = (
  rowType: string,
  index: number,
  childrenLength: number,
  lastGroup = true
): string => {
  return classNames('children-row', rowType, `children-row-${index}`, {
    first: index === 0,
    last: lastGroup && childrenLength === index + 1
  })
}

const reallocationReportRows = (account: ReportRow): ReportRow[] => {
  let row: ReportRow
  if (account.reallocated) {
    type ReallocationType = 'unallocated' | 'sources' | 'targets'

    const processAllocation = (acc: ReportRow, type: ReallocationType) => {
      const translation = `financialStatementsPage:reallocation-${type}`

      return {
        key: `${acc.code}-${type}`,
        id: acc.id,
        code: acc.code.toString(),
        type: acc.type,
        rowType: ReportRowType.reallocation,
        translation,
        name: i18next.t(translation),
        actuals: acc?.[type]?.actuals,
        budgets: acc?.[type]?.budgets,
        budget: acc?.[type]?.budget,
        forecast: acc?.[type]?.forecast
      }
    }

    row = {
      key: `${account.code}-reallocation-menu`,
      id: account.id,
      type: account.type,
      rowType: ReportRowType.reallocationMenu,
      code: account.code,
      name: account.name
    }

    if (account.targets) {
      row.children = [
        processAllocation(account, 'unallocated'),
        processAllocation(account, 'sources'),
        processAllocation(account, 'targets')
      ]
    }

    return [row]
  }

  return []
}

export const budgetableIncomeStatement = (
  incomeStatement: Report,
  contract: Contract,
  settings: CompanySettings,
  dimensionList: Dimension[],
  dimensionTree?: Dimension[],
  selectedDimension?: Dimension | null,
  dimensionData?: { [rowCode: string]: { [dimensionId: string]: ReportRow[] } },
  dimensionKeys?: (string | number)[],
  budgetsInEdit?: {
    [code: string]: boolean
  },
  driversInEdit?: {
    [code: string]: boolean
  },
  dimensionRowsEnabled = true
): Report => {
  // Helpers
  const processBudgedRow = (row: ReportRow): ReportRow => {
    return {
      key: `${row.key}-budget-menu`,
      accountCode: row.accountCode,
      incomeStatementRowId: row.incomeStatementRowId,
      report: ReportType.incomeStatement,
      id: row.id,
      dimensionId: row.dimensionId,
      code: row.code,
      type: row.budgetingMethod?.method === BudgetingMethod.reference ? VariableType.percentage : VariableType.absolute,
      rowType: ReportRowType.budgetMenu,
      name: row.name,
      actuals: row.actuals,
      budgetComments: row.budgetComments,
      budgets: row.budgets,
      budget: row.budget,
      budgetingMethod: row.budgetingMethod
    }
  }

  const processBudgedDriverRows = (row: ReportRow): ReportRow[] => {
    return (
      row.budgetingMethod?.drivers?.map(driver => ({
        ...driver.formula,
        type: driver.formula?.type || VariableType.absolute,
        key: `${row.key}-${driver?.formula?.id}-budget-menu`,
        id: driver?.formula?.id || -1,
        code: `${driver?.formula?.code}`,
        rowType: ReportRowType.budgetMenu,
        method: 'driver',
        budgetingMethod: row.budgetingMethod,
        incomeStatementRowId: row.incomeStatementRowId,
        dimensionId: row.dimensionId,
        accountCode: row.accountCode,
        name: driver.name,
        budgets: driver.formula?.budget
      })) || []
    )
  }
  const processBudgedRows = (row: ReportRow): ReportRow[] => {
    if (driversInEdit?.[row.key]) {
      return processBudgedDriverRows(row)
    }
    if (budgetsInEdit?.[row.key]) {
      return [processBudgedRow(row)]
    }
    return []
  }

  const processDimensionButtonRow = (row: ReportRow): ReportRow => {
    return {
      key: `${row.code}-dimensions-menu`,
      id: row.id,
      type: row.type,
      rowType: ReportRowType.dimensionsButton,
      statementRowId: row.id,
      code: row.code,
      accountCode: row.accountCode,
      name: row.name,
      isLeafDimension:
        !!(selectedDimension?.children && selectedDimension?.children?.length < 1) || dimensionList?.length < 1
    }
  }

  const processBudgetMethodRow = (row: ReportRow, dimensionId: string | null) => {
    const driverDimensionData = row.budgetingMethod?.dimensions?.find(b => b?.dimensionId === dimensionId) || {
      drivers: []
    }
    return { ...row.budgetingMethod, ...driverDimensionData }
  }

  const processDimensionTree = (row: ReportRow, dt?: Dimension[], budgetable?: boolean): ReportRow[] => {
    if (!dimensionKeys?.includes(row.accountCode || row.incomeStatementRowId) || dt === undefined) return []

    const processDimensionRow = ({ children, ...d }: Dimension): ReportRow[] => {
      const dimensionId = d.dimensionId || null

      const prosessedDimension = {
        ...d,
        ...dimensionData?.[row.accountCode || row.incomeStatementRowId]?.[d.dimensionId],
        rowType: budgetable ? ReportRowType.budgetableRow : ReportRowType.dimension,
        key: `${row.key}-${d.dimensionId}`,
        name: d.name,
        title: d.name,
        statementRowId: row.id,
        budgetingMethod: processBudgetMethodRow(row, dimensionId),
        actualRowType: ActualRowType.dimension,
        code: `${row.code}`,
        accountCode: row.accountCode,
        incomeStatementRowId: row.incomeStatementRowId,
        type: row.type,
        children: (children || []).length > 0 ? children?.flatMap(processDimensionRow) : undefined // recursive call
      } as ReportRow

      return [
        prosessedDimension, // Dimension
        ...processBudgedRows(prosessedDimension)
      ]
    }
    return dt.flatMap(processDimensionRow)
  }

  const addDimensionRows = (row: ReportRow, budgetable?: boolean) => {
    const dimensionChildren = !selectedDimension?.dimensionId
      ? dimensionTree
      : recursiveFind(dimensionTree, selectedDimension?.dimensionId, 'dimensionId')?.children

    if (!dimensionChildren) return []

    const processedDimensions = processDimensionTree(row, dimensionChildren, budgetable)

    return [
      processDimensionButtonRow(row), // Dimension button
      ...processedDimensions // Dimensions
    ]
  }

  const processAccountRow = (account: ReportRow): ReportRow[] => {
    const processedAccountRow = {
      ...account,
      key: account.code.toString(),
      dimensionId: selectedDimension ? selectedDimension.dimensionId : undefined,
      accountCode: account.code,
      budgetingMethod: processBudgetMethodRow(account, selectedDimension?.dimensionId || null),
      rowType: account.budgetable ? ReportRowType.budgetableRow : ReportRowType.account
    } as ReportRow

    const children = [
      ...(dimensionRowsEnabled ? addDimensionRows(processedAccountRow, true) : []), // Dimension button
      ...reallocationReportRows(processedAccountRow)
    ] as ReportRow[]

    return [
      { ...processedAccountRow, children }, // account
      ...processBudgedRows(processedAccountRow)
    ]
  }

  // Amendment Counterparts
  const processAmendmentsCounterparts = (row: ReportRow): ReportRow[] => {
    if (!row.amendmentCounterParts) return []
    return row.amendmentCounterParts
      ?.map((amendmentCounterPart, index) => ({
        amendmentCounterPart,
        key: `${row.code}-amendment-counter-part-${amendmentCounterPart.id}`,
        id: amendmentCounterPart.id,
        className: getChildrenRowClassName(
          'amendment-counter-part',
          index,
          row?.amendmentCounterParts?.length || 0,
          false
        ),
        type: row.type,
        code: row.code,
        rowType: ReportRowType.amendmentCounterPart,
        name: amendmentCounterPart.description
      }))
      .sort(
        (n1: ReportRow, n2: ReportRow) =>
          new Date(n2.amendmentCounterPart.date).getTime() - new Date(n1.amendmentCounterPart.date).getTime()
      )
  }

  const processAmendmentCounterPartsMenuRow = (row: ReportRow): ReportRow[] => {
    if (!row.amendmentCounterParts) return []
    return [
      {
        key: `${row.code}-amendment-counter-part-menu`,
        id: row.id,
        type: row.type,
        className: 'budgetable-row',
        rowType: ReportRowType.amendmentCounterPartMenu,
        code: row.code,
        name: row.name,
        children: processAmendmentsCounterparts(row)
      }
    ]
  }

  // BudgetingLevel.statementRow
  const processStatementRow = (row: ReportRow) => {
    if (!row.budgetable) {
      return {
        ...row,
        children: undefined
      }
    }

    const processedRow = {
      ...row,
      key: row.code.toString(),
      incomeStatementRowId: row.id,
      dimensionId: selectedDimension ? selectedDimension.dimensionId : undefined,
      rowType: row.budgetable ? ReportRowType.budgetableRow : ReportRowType.statementRow
    } as ReportRow

    const children = [
      ...(dimensionRowsEnabled ? addDimensionRows(processedRow, true) : []), // Dimensions
      ...processAmendmentCounterPartsMenuRow(processedRow) // Amendment counterparts
    ] as ReportRow[]

    if (processedRow?.children?.length === 0) {
      delete processedRow.children
    }

    return [{ ...processedRow, children }, ...processBudgedRows(processedRow)]
  }

  // BudgetingLevel.account
  const processIncomestatementWithAccountsRow = (row: ReportRow): ReportRow | ReportRow[] => {
    if (!row.budgetable) {
      return {
        ...row,
        key: row.code.toString(),
        children: row.children?.length === 0 ? undefined : row.children
      }
    }

    const accounts = row?.children || []
    const processedRow = {
      ...row,
      key: row.code.toString(),
      incomeStatementRowId: row.id,
      statementRowId: row.id,
      dimensionId: selectedDimension ? selectedDimension.dimensionId : undefined
    } as ReportRow

    const children = [
      ...addDimensionRows(processedRow),
      ...accounts.flatMap(processAccountRow), // Accounts
      ...processAmendmentCounterPartsMenuRow(processedRow)
    ]

    if (processedRow?.children?.length === 0) {
      delete processedRow.children
    }

    return { ...processedRow, children }
  }

  // Main logic
  if (!settings.budgeting) {
    return incomeStatement
  }

  const requiredProducts = [ContractProduct.BASIC, ContractProduct.PRO]

  if (requiredProducts.some(product => requireContractProductExact(contract.product, product))) {
    if (settings.budgeting.level === BudgetingLevel.statementRow) {
      return incomeStatement.flatMap(processStatementRow)
    }
    if (settings.budgeting.level === BudgetingLevel.account) {
      return incomeStatement.flatMap(processIncomestatementWithAccountsRow)
    }
  }

  return incomeStatement.flatMap(processIncomestatementWithAccountsRow)
}

export const budgetableBalanceSheet = (balanceSheet: Report, contract: Contract) => {
  // Investments
  const processInvestmentMenuRow = (row: ReportRow): ReportRow[] => {
    if (!row.investable) return []
    return [
      {
        key: `${row.code}-investment-menu`,
        id: row.id,
        type: row.type,
        rowType: ReportRowType.investmentMenu,
        className: 'budgetable-row',
        code: row.code,
        name: row.name,
        depreciable: row.depreciable,
        deprecationPlan: row.deprecationPlan
      }
    ]
  }

  const processInvestments = (row: ReportRow): ReportRow[] => {
    if (!row.investments) return []
    return row.investments
      ?.map((investment, index) => ({
        investment,
        key: `investment-${investment.id}`,
        id: investment.id,
        type: row.type,
        className: getChildrenRowClassName('investment', +index, row?.investments?.length || 0),
        code: row.code,
        rowType: ReportRowType.investment,
        name: investment.name || getHumanReadableArrayText(investment.purposes.map(purpose => purpose.name))
      }))
      .sort((n1: ReportRow, n2: ReportRow) => {
        return new Date(n2.investment.date).getTime() - new Date(n1.investment.date).getTime()
      })
  }

  // Amendments
  const processAmendments = (row: ReportRow): ReportRow[] => {
    if (!row.amendments) return []
    return row.amendments
      ?.map((amendment, index) => ({
        amendment,
        key: `${row.code}-amendment-${amendment.id}`,
        id: amendment.id,
        className: getChildrenRowClassName('amendment', index, row?.amendments?.length || 0),
        type: row.type,
        code: row.code,
        rowType: ReportRowType.amendment,
        name: amendment.description,
        amendmentCounterPartRowIds: row.amendmentCounterPartRowIds
      }))
      .sort((n1: ReportRow, n2: ReportRow) => {
        return new Date(n2.amendment.date).getTime() - new Date(n1.amendment.date).getTime()
      })
  }

  const processAmendmentMenuRow = (row: ReportRow, balanceSheetRow: ReportRow): ReportRow[] => {
    if (!balanceSheetRow.amendable) return []
    return [
      {
        key: `${row.code}-amendment-menu`,
        id: row.id,
        type: row.type,
        className: 'budgetable-row',
        rowType: ReportRowType.amendmentMenu,
        code: row.code,
        accountCode: row.code,
        balanceSheetRowId: balanceSheetRow.id,
        name: row.name,
        amendmentCounterPartRowIds: row.amendmentCounterPartRowIds,
        children: processAmendments(row)
      }
    ]
  }

  // Amendment Counterparts
  const processAmendmentsCounterparts = (row: ReportRow): ReportRow[] => {
    if (!row.amendmentCounterParts) return []
    return row.amendmentCounterParts
      ?.map((amendmentCounterPart, index) => ({
        amendmentCounterPart,
        key: `${row.code}-amendment-counter-part-${amendmentCounterPart.id}`,
        id: amendmentCounterPart.id,
        className: getChildrenRowClassName(
          'amendment-counter-part',
          index,
          row?.amendmentCounterParts?.length || 0,
          false
        ),
        type: row.type,
        code: row.code,
        rowType: ReportRowType.amendmentCounterPart,
        name: amendmentCounterPart.description
      }))
      .sort(
        (n1: ReportRow, n2: ReportRow) =>
          new Date(n2.amendmentCounterPart.date).getTime() - new Date(n1.amendmentCounterPart.date).getTime()
      )
  }

  const processAmendmentCounterPartsMenuRow = (row: ReportRow): ReportRow[] => {
    if (!row.amendmentCounterParts) return []
    return [
      {
        key: `${row.code}-amendment-counter-part-menu`,
        id: row.id,
        type: row.type,
        className: 'budgetable-row',
        rowType: ReportRowType.amendmentCounterPartMenu,
        code: row.code,
        name: row.name,
        children: processAmendmentsCounterparts(row)
      }
    ]
  }

  // Fundings
  const processFundings = (row: ReportRow): ReportRow[] => {
    if (!row.fundings) return []
    return row.fundings
      ?.map((investmentFunding, index) => ({
        investmentFunding,
        key: `${row.code}-investment-funding-${investmentFunding.id}`,
        id: investmentFunding.investment?.id || investmentFunding.id,
        className: getChildrenRowClassName('investment-funding', index, row?.fundings?.length || 0),
        type: row.type,
        code: row.code,
        rowType: ReportRowType.investmentFunding,
        name: investmentFunding.investment?.name ? investmentFunding.investment?.name : ''
      }))
      .sort((n1: ReportRow, n2: ReportRow) => {
        return (
          new Date(`${n2.investmentFunding.year}-${n2.investmentFunding.month}`).getTime() -
          new Date(`${n1.investmentFunding.year}-${n1.investmentFunding.month}`).getTime()
        )
      })
  }

  const processFundingsMenuRow = (row: ReportRow): ReportRow[] => {
    if (!row.amendmentCounterParts) return []
    return [
      {
        key: `${row.code}-investment-funding-menu`,
        id: row.id,
        type: row.type,
        className: 'budgetable-row',
        rowType: ReportRowType.investmentFundingMenu,
        code: row.code,
        name: row.name,
        children: processFundings(row)
      }
    ]
  }

  // Childrens (accounts)
  const processProChildrens = (row: ReportRow): ReportRow[] => {
    if (!row.children) return []
    return row.children.map(child => ({
      ...child,
      key: child.code.toString(),
      children: [
        ...processInvestments(child),
        ...processInvestmentMenuRow(child), // Investment Menu (Investointi / Divestointi / (poisto))
        ...processAmendmentMenuRow(child, row), // Amendment Menu (Lisäys / vähennys)
        ...processAmendmentCounterPartsMenuRow(child)
      ]
    }))
  }

  const processBasicChildrens = (row: ReportRow): ReportRow[] => {
    if (!row.children) return []
    return row.children.map(child => ({
      ...child,
      key: child.code.toString(),
      children: [
        ...processInvestments(child), // investments
        ...processAmendmentMenuRow(child, row), // Amendment Menu (Lisäys / vähennys)
        ...processAmendmentCounterPartsMenuRow(child)
      ]
    }))
  }

  // PRO
  const processProBalanceSheet = (row: ReportRow): ReportRow => {
    return {
      ...row,
      key: row.code.toString(),
      children: [...processProChildrens(row), ...processInvestments(row), ...processFundingsMenuRow(row)]
    }
  }

  // BASIC
  const processBasicBalanceSheet = (row: ReportRow): ReportRow => {
    return {
      ...row,
      key: row.code.toString(),
      children: [
        ...processBasicChildrens(row),
        ...processInvestmentMenuRow(row), // Investment Menu (Investointi / Divestointi / (poisto))
        ...processInvestments(row),
        ...processFundingsMenuRow(row)
      ]
    }
  }

  // LIGHT
  const processLightBalanceSheet = (row: ReportRow): ReportRow => {
    return {
      ...row,
      key: row.code.toString()
    }
  }

  // Main logic
  if (requireContractProductExact(contract.product, ContractProduct.BASIC)) {
    return balanceSheet.map(processBasicBalanceSheet)
  }
  if (requireContractProductExact(contract.product, ContractProduct.PRO)) {
    return balanceSheet.map(processProBalanceSheet)
  }
  if (requireContractProductExact(contract.product, ContractProduct.LIGHT)) {
    return balanceSheet.map(processLightBalanceSheet)
  }

  return balanceSheet
}

export const usePeriods = (): Period[] => {
  const { fiscalYear } = useSelector(filtersSelector)
  if (!fiscalYear) return []

  const startIndex = 0
  const endIndex = fiscalYear.periods.length
  return fiscalYear.periods.slice(startIndex, endIndex)
}

export const cashFlowRecurseExplainer = (cashFlowStatement?: any, payload?: any) => {
  function recurse(cashFlowReportStatementRows: Report) {
    const finalReport = []
    for (const child of cashFlowReportStatementRows) {
      if (child.id === payload.keyFigure.id) {
        child.children = payload.explainer
      } else if (child.children) {
        recurse(child.children)
      }

      finalReport.push(child)
    }

    return finalReport
  }

  return cashFlowStatement && recurse(cashFlowStatement)
}
