import { CompanyDashboard } from '../../../../types/dashboard/company/types'
import {
  CompanyDashboardActionTypes,
  SET_COMPANY_DASHBOARD,
  SET_COMPANY_DASHBOARD_LAYOUTS,
  SET_COMPANY_DASHBOARD_ITEM,
  DELETE_COMPANY_DASHBOARD_ITEM,
  UPDATE_COMPANY_DASHBOARD_ITEM,
  ADD_COMPANY_DASHBOARD,
  DELETE_COMPANY_DASHBOARD,
  UPDATE_COMPANY_DASHBOARD
} from './types'

const initialState: CompanyDashboard[] = [{ key: 0, name: 'Oletus', id: 0, items: [], layouts: {} }]

const companyDashboardReducer = (state = initialState, action: CompanyDashboardActionTypes) => {
  switch (action.type) {
    case SET_COMPANY_DASHBOARD:
      return action.payload
    case ADD_COMPANY_DASHBOARD:
      return [...state, action.payload]
    case UPDATE_COMPANY_DASHBOARD:
      return state.map((dashboad: CompanyDashboard) => {
        if (dashboad.id === action.payload.id) {
          return { ...dashboad, ...action.payload }
        }
        return dashboad
      })

    case SET_COMPANY_DASHBOARD_ITEM:
      return state.map((stateItem: CompanyDashboard) => {
        return stateItem.id === action.payload.dashboardId
          ? { ...stateItem, items: [...stateItem.items, action.payload.companyDashboardItem] }
          : stateItem
      })
    case DELETE_COMPANY_DASHBOARD_ITEM:
      return state.map((stateItem: CompanyDashboard) =>
        stateItem.id === action.payload.dashboardId
          ? { ...stateItem, items: stateItem.items.filter(item => item.id !== action.payload.companyDashboardItem.id) }
          : stateItem
      )
    case UPDATE_COMPANY_DASHBOARD_ITEM:
      return state.map((stateItem: CompanyDashboard) =>
        stateItem.id === action.payload.dashboardId
          ? {
              ...stateItem,
              items: stateItem.items.map(item =>
                item.id !== action.payload.companyDashboardItem.id
                  ? item
                  : { ...item, ...action.payload.companyDashboardItem }
              )
            }
          : stateItem
      )
    case SET_COMPANY_DASHBOARD_LAYOUTS:
      return state.map((stateItem: CompanyDashboard) =>
        stateItem.id === action.payload.dashboardId
          ? {
              ...stateItem,
              layouts: action.payload.layouts
            }
          : stateItem
      )
    case DELETE_COMPANY_DASHBOARD:
      return state.filter((item: CompanyDashboard) => item.id !== action.payload.dashboardId)
    default:
      return state
  }
}

export default companyDashboardReducer
