import { GetRequestAction, GET, INTEGRATION_REQUEST } from '../../middleware/types'
import { Company } from '../../../types/company/Company'
import { Customer } from '../../../types/customer/customer'
import { SetCustomersAction, SET_CUSTOMERS } from './types'

export const setCustomers = (customers: Customer[]): SetCustomersAction => ({
  type: SET_CUSTOMERS,
  payload: customers
})

export const getCustomersRequest = (companyId: Company['id']): GetRequestAction => ({
  type: INTEGRATION_REQUEST,
  payload: {
    method: GET,
    params: { companyId },
    url: `/reporting/customers/accounting`,
    success: setCustomers
  },
  meta: {
    type: 'CUSTOMERS'
  }
})
