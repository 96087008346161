import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useDocsURL } from '../../../../../utils/docs'

const Maestro: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'item-1',
      children: (
        <a href={`${docsURL}manual/businesses/settings/company#maestro`} target="_blank" rel="noopener noreferrer">
          {t('integrationsPage:maestro-info')}
        </a>
      )
    }, // remember to pass the key prop
    {
      label: t('integrationsPage:file-upload'),
      key: 'item-2',
      children: (
        <FileUpload
          actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/maestro-csv/uploads/${companyId}`}
          taskRequestUrl="/maestro-csv/tasks/{companyId}"
          uploadText={t('integrationsPage:csv-info')}
        />
      )
    }
  ]

  return <Tabs defaultActiveKey="1" items={items} />
}

export default Maestro
