import { FormInstance } from 'antd'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { store } from '../../../../../redux/store'
import { BackendRequest } from '../../../../../services/backend'
import { dispatchFinancialStatementRequests } from '../../service'
import { DeprecationPlanFormData } from './types'
import { parseDeprecationPlan } from './utils'

// TODO: refactor
export const handleDeprecationOk = (
  form: FormInstance,
  createDeprecationPlanRequest: BackendRequest,
  updateDeprecationPlanRequest: BackendRequest,
  companyId: string,
  setModalVisibilityDeprecation: (value: boolean) => void
) => {
  form.validateFields().then((fieldsValues: DeprecationPlanFormData) => {
    if (fieldsValues.delete) {
      updateDeprecationPlanRequest
        .delete({
          urlParams: { companyId, deprecationPlanId: fieldsValues.id }
        })
        .then(() => {
          setModalVisibilityDeprecation(true)
          store.dispatch(
            notificationAction({
              type: 'success',
              message: 'DELETE_DEPRECATION_PLAN_SUCCESS'
            })
          )
          dispatchFinancialStatementRequests()
        })
        .catch((e: Error) => {
          store.dispatch(
            notificationAction({
              type: 'error',
              message: 'DELETE_DEPRECATION_PLAN_ERROR',
              description: e.message
            })
          )
        })
    } else if (fieldsValues.id) {
      const values = parseDeprecationPlan(fieldsValues)
      updateDeprecationPlanRequest
        .put({
          urlParams: { companyId, deprecationPlanId: fieldsValues.id },
          body: { data: values }
        })
        .then(() => {
          store.dispatch(
            notificationAction({
              type: 'success',
              message: 'UPDATE_DEPRECATION_PLAN_SUCCESS'
            })
          )
          dispatchFinancialStatementRequests()
          setModalVisibilityDeprecation(false)
        })
        .catch((e: Error) => {
          store.dispatch(
            notificationAction({
              type: 'error',
              message: 'UPDATE_DEPRECATION_PLAN_ERROR',
              description: e.message
            })
          )
        })
    } else if (!fieldsValues.id) {
      const values = parseDeprecationPlan(fieldsValues)

      createDeprecationPlanRequest
        .post({
          urlParams: { companyId },
          body: { data: values }
        })
        .then(() => {
          store.dispatch(
            notificationAction({
              type: 'success',
              message: 'CREATE_DEPRECATION_PLAN_SUCCESS'
            })
          )
          dispatchFinancialStatementRequests()
          setModalVisibilityDeprecation(false)
        })
        .catch((e: Error) => {
          store.dispatch(
            notificationAction({
              type: 'error',
              message: 'CREATE_DEPRECATION_PLAN_ERROR',
              description: e.message
            })
          )
        })
    }
  })
}
