import { Company } from '../../../types/company/Company'
import { GET, GetRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { ContextRequestParams } from '../actions'
import { SetCompanyInformationAction, SET_COMPANY_INFORMATION } from './types'

export const setCompanyInformation = (data: any): SetCompanyInformationAction => ({
  type: SET_COMPANY_INFORMATION,
  payload: data
})

export const getCompanyImformationRequest = ({ companyId }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/information`,
    success: setCompanyInformation
  },
  meta: {
    type: 'COMPANY_INFORMATION'
  }
})

export const updateCompanyInformationRequest = (companyId: Company['id'], data: any): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/information/stakeholders`,
    success: setCompanyInformation
  },
  meta: {
    type: 'COMPANY_INFORMATION'
  }
})
