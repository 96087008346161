import React from 'react'
import { ReactComponent as Dash } from '../../../assets/line-icons/Dash.svg'
import { ReactComponent as DashDot } from '../../../assets/line-icons/DashDot.svg'
import { ReactComponent as Dot } from '../../../assets/line-icons/Dot.svg'
import { ReactComponent as LongDash } from '../../../assets/line-icons/LongDash.svg'
import { ReactComponent as LongDashDot } from '../../../assets/line-icons/LongDashDot.svg'
import { ReactComponent as LongDashDotDot } from '../../../assets/line-icons/LongDashDotDot.svg'
import { ReactComponent as ShortDash } from '../../../assets/line-icons/ShortDash.svg'
import { ReactComponent as ShortDashDot } from '../../../assets/line-icons/ShortDashDot.svg'
import { ReactComponent as ShortDashDotDot } from '../../../assets/line-icons/ShortDashDotDot.svg'
import { ReactComponent as ShortDot } from '../../../assets/line-icons/ShortDot.svg'
import { ReactComponent as Solid } from '../../../assets/line-icons/Solid.svg'
// import Solid from '../../../assets/line-icons/Solid.svg'

const dashIcons = {
  Dash,
  DashDot,
  Dot,
  LongDash,
  LongDashDot,
  LongDashDotDot,
  ShortDash,
  ShortDashDot,
  ShortDashDotDot,
  ShortDot,
  Solid
}

type DashType = keyof typeof dashIcons

const iconProps = {
  style: {
    height: 18
  },
  className: 'anticon'
}

interface LineIconProps {
  type?: DashType
}

const LineIcon: React.FC<LineIconProps> = ({ type = 'Solid' }) => {
  return <span>{React.createElement(dashIcons[type], iconProps)}</span>
}

export default LineIcon
