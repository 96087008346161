export interface Task {
  id: number
  companyId: string
  name: string
  description: string
  application: TaskApplication
  type: TaskType
  status: TaskStatus
  startTime: Date
  endTime: Date
  errors?: { [key: string]: any }[]
  artefact?: { [key: string]: any }
  parentId?: number
  parent?: Task
  children?: Task[]
}

export enum TaskStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export enum TaskType {
  ACCOUNTS = 'ACCOUNTS',
  ACTUALS = 'ACTUALS',
  API = 'API',
  BUSINESS_PARTNERS = 'BUSINESS_PARTNERS',
  CUSTOMERS = 'CUSTOMERS',
  DAILY = 'DAILY',
  DIMENSIONS = 'DIMENSIONS',
  EMAIL = 'EMAIL',
  ENTRIES = 'ENTRIES',
  FISCAL_YEARS = 'FISCAL_YEARS',
  GET_AUTH = 'GET_AUTH',
  INITIAL = 'INITIAL',
  INVOICES = 'INVOICES',
  PRODUCTS = 'PRODUCTS',
  RECEIPTS = 'RECEIPTS',
  RESTAURANTS = 'RESTAURANTS',
  SIE = 'SIE',
  SUPPLIERS = 'SUPPLIERS',
  UPLOAD = 'UPLOAD',
  VERIFICATIONS = 'VERIFICATIONS',
  VOUCHERS = 'VOUCHERS'
}

export enum TaskApplication {
  AC_PANTHER = 'AC_PANTHER',
  BRIOX = 'BRIOX',
  ECONIA = 'ECONIA',
  EFINA = 'EFINA',
  EXCEL = 'EXCEL',
  EXCHANGE_RATES = 'EXCHANGE_RATES',
  FENNOA = 'FENNOA',
  FIVALDI = 'FIVALDI',
  FORTNOX = 'FORTNOX',
  HEEROS = 'HEEROS',
  KIHO = 'KIHO',
  KOTIPIZZA = 'KOTIPIZZA',
  MAESTRO = 'MAESTRO',
  MAPON = 'MAPON',
  MARAPLAN = 'MARAPLAN',
  NETVISOR = 'NETVISOR',
  PROCOUNTOR = 'PROCOUNTOR',
  RESTOLUTION = 'RESTOLUTION',
  SIE = 'SIE',
  SONET = 'SONET',
  TALENOM = 'TALENOM',
  LOGISYSTEMS = 'LOGISYSTEMS',
  L7 = 'L7',
  VISMANET = 'VISMANET',
  XERO = 'XERO',
  TULOPOS = 'TULOPOS',
  OSCAR = 'OSCAR',
  HRSUUNTI = 'HRSUUNTI',
  FINADECK = 'FINADECK'
}
