import { Tabs, TabsProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PDFReports from './PDFReports'
import GraphColorSettings from './GraphColorSettings'

const AppearanceSettings: React.FC = () => {
  const { t } = useTranslation()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Logo',
      children: <PDFReports />
    },
    {
      key: '2',
      label: t('global:colorPalette'),
      children: <GraphColorSettings />
    }
  ]

  return <Tabs defaultActiveKey="1" items={items} />
}

export default AppearanceSettings
