import { Account } from '../../../types/account/Account'

export const SET_COMPANY_ACCOUNTS = 'SET_COMPANY_ACCOUNTS'
export const ADD_COMPANY_ACCOUNT = 'ADD_COMPANY_ACCOUNT'
export const UPDATE_COMPANY_ACCOUNT = 'UPDATE_COMPANY_ACCOUNT'
export const DELETE_COMPANY_ACCOUNT = 'DELETE_COMPANY_ACCOUNT'

export interface SetAccountsAction {
  type: typeof SET_COMPANY_ACCOUNTS
  payload: Account[]
}

export interface AddAccountAction {
  type: typeof ADD_COMPANY_ACCOUNT
  payload: Account
}

export interface UpdateAccountAction {
  type: typeof UPDATE_COMPANY_ACCOUNT
  payload: Account
}

export interface DeleteAccountAction {
  type: typeof DELETE_COMPANY_ACCOUNT
  payload: Account
}

export type AccountsActionTypes = SetAccountsAction | AddAccountAction | UpdateAccountAction | DeleteAccountAction
