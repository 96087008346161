import { SetCompetitorComparisonData, SET_COMPETITOR_COMPARISON_DATA } from './types'
import { CompetitorComparison } from '../../../types/industry/Industry'

const initialState: CompetitorComparison = {
  companyData: [],
  comparisonData: []
}

const competitorComparisonReducer = (state = initialState, action: SetCompetitorComparisonData) => {
  switch (action.type) {
    case SET_COMPETITOR_COMPARISON_DATA:
      return action.payload
    default:
      return state
  }
}

export default competitorComparisonReducer
