import { Company } from '../../../types/company/Company'
import { Dimension } from '../../../types/dimension/Dimension'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PUT,
  PostRequestAction,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import {
  SetDimensionsAction,
  SET_DIMENSIONS,
  UpdateDimensionsAction,
  UPDATE_DIMENSIONS,
  DELETE_DIMENSION,
  DeleteDimensionAction,
  AddDimensionAction,
  ADD_DIMENSION,
  UPDATE_DIMENSION_NAME,
  UpdateDimensionNameAction
} from './types'

export const setDimensions = (dimensions: Dimension[]): SetDimensionsAction => ({
  type: SET_DIMENSIONS,
  payload: dimensions
})

export const updateDimensions = (dimensions: Dimension[]): UpdateDimensionsAction => ({
  type: UPDATE_DIMENSIONS,
  payload: dimensions
})

export const updateDimensionName = (dimension: Dimension): UpdateDimensionNameAction => ({
  type: UPDATE_DIMENSION_NAME,
  payload: dimension
})

export const deleteDimension = (dimension: Dimension): DeleteDimensionAction => ({
  type: DELETE_DIMENSION,
  payload: dimension
})

export const addDimension = (dimension: Dimension): AddDimensionAction => ({
  type: ADD_DIMENSION,
  payload: dimension
})

export const getDimensionsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/accounting/dimensions`,
    success: (payload: Dimension[]) => setDimensions(payload)
  },
  meta: {
    type: 'DIMENSIONS'
  }
})

export const updateDimensionsRequest = (companyId: Company['id'], data: Dimension[]): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data,
    url: `/api/companies/${companyId}/accounting/dimensions`,
    success: (payload: Dimension[]) => updateDimensions(payload)
  },
  meta: {
    type: 'DIMENSIONS'
  }
})

export const addDimensionsRequest = (companyId: Company['id'], data: Dimension): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data,
    url: `/api/companies/${companyId}/accounting/dimensions`,
    success: (payload: Dimension) => addDimension(payload)
  },
  meta: {
    type: 'DIMENSIONS'
  }
})

export const deleteDimensionsRequest = (companyId: Company['id'], id: string): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/accounting/dimensions/${id}`,
    success: (payload: Dimension) => deleteDimension(payload)
  },
  meta: {
    type: 'DIMENSIONS'
  }
})
