import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import FileUpload from '../../../../../components/FileUpload/FileUpload'

const VismaL7: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)

  return (
    <FileUpload
      actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/l7/uploads/${companyId}`}
      taskRequestUrl="/l7/tasks/{companyId}"
      uploadText={t('integrationsPage:excel-info')}
    />
  )
}

export default VismaL7
