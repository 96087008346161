import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocsURL } from '../../../../../../utils/docs'
import TamigoApi from './TamigoApi'
import DimensionMap from '../dimensionMap/DimensionMap'

const Tamigo: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const items = [
    {
      label: t('integrationsPage:api'),
      key: 'api',
      children: <TamigoApi />
    },
    {
      label: t('integrationsPage:department'),
      key: 'department',
      children: (
        <DimensionMap
          getDimensionsUrl="/tamigo/dimensions/{companyId}"
          updateDimensionsUrl="/tamigo/dimensions/{companyId}/{id}"
          dataKey="DepartmentId"
        />
      )
    }
  ]

  return (
    <>
      <a href={`${docsURL}manual/businesses/settings/company#tamigo`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Tabs items={items} destroyInactiveTabPane />
    </>
  )
}

export default Tamigo
