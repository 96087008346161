export default [
  {
    id: 1,
    title: 'incomeStatementSelection1'
  },
  {
    id: 2,
    title: 'incomeStatementSelection2'
  },
  {
    id: 3,
    title: 'incomeStatementSelection3'
  },
  {
    id: 4,
    title: 'incomeStatementSelection4'
  },
  {
    id: 5,
    title: 'incomeStatementSelection5'
  },
  {
    id: 6,
    title: 'incomeStatementSelection6'
  },
  {
    id: 7,
    title: 'incomeStatementSelection7'
  },
  {
    id: 8,
    title: 'incomeStatementSelection8'
  }
] as { id: number; title: string }[]
