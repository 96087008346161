import { ReportTableRow } from '../../../components/Table/types'
import { BudgetCommentActionTypes } from '../budgetComment/types'

export const SET_SELECTED_ROW_DIMENSION_KEY = 'SET_SELECTED_ROW_DIMENSION_KEY'
export const SET_ROW_DIMENSION_DATA = 'SET_ROW_DIMENSION_DATA'
export const EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA = 'EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA'
export const SET_ALL_DIMENSIONS_ROW_DATA = 'SET_ALL_DIMENSIONS_ROW_DATA'

export interface SetSelectedRowDimensionKeyAction {
  type: typeof SET_SELECTED_ROW_DIMENSION_KEY
  payload: ReportTableRow
}

export interface EmptySelectedRowDimensionKeysAndDataAction {
  type: typeof EMPTY_SELECTED_ROW_DIMENSION_KEYS_AND_DATA
}
export interface SetAllDimensionsRowDataAction {
  type: typeof SET_ALL_DIMENSIONS_ROW_DATA
  payload: { key: string; data: Partial<ReportTableRow>[] }
}

export type IncomeStatementDimensionsDataActionTypes =
  | SetSelectedRowDimensionKeyAction
  | EmptySelectedRowDimensionKeysAndDataAction
  | SetAllDimensionsRowDataAction
  | BudgetCommentActionTypes
